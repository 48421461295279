import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateWidnowSizes } from './modules/core/actions/core.action.creators';

const WindowDimensionsProvider = ({ children }) => {
  const dispatch = useDispatch();

  // window resizing
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // componentWillUnmount
  useEffect(() => {
    return () => window.removeEventListener('resize', updateWindowDimensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateWindowDimensions = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = !!/Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(navigator.userAgent || '');
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
    const orientation = (window.screen.orientation || {}).type || window.screen.mozOrientation || window.screen.msOrientation;
    const formatOrientation = orientation === 'landscape-primary' ? true : false;
    const isLandscape = formatOrientation !== undefined ? formatOrientation : window.innerHeight < window.innerWidth ? true : false;

    const newSizes = {
      isMobile: window.innerWidth < 640 || isMobile,
      isMobileDataTableView: isMobile,
      //isMobileDataTableView: window.innerWidth <= 1024 && isMobile,
      isTablet,

      isLandscape,
      isMobileOnly: isMobile && !isTablet,
      showNewTeleplanButtons: (isMobile && !isTablet) || (isTablet && !isLandscape),

      // Cypress tests only - mobile view
      // isLandscape: false,
      // isMobileOnly: true,

      width: window.innerWidth,
      height: window.innerHeight,
      clientWidth: document.documentElement.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      isXL: !isMobile && document.documentElement.clientWidth > 1600,
      iPadProInLandscape: isTablet && isLandscape && document.documentElement.clientWidth > 1200,
      iPadInPortrait: isMobile && !isLandscape && document.documentElement.clientWidth < 1024 ? true : false //only iPad (not iPad Pro). Screen resolution: 768 x 1024
    };

    dispatch(updateWidnowSizes(newSizes));
  };

  return <>{children}</>;
};

export default WindowDimensionsProvider;
