import React from 'react';
import { Button } from 'primereact/button';
import ChipsList from './ChipsList';
import ChipsListSkeleton from './ChipsListSkeleton';
import { t } from '../../../../service/localization/i18n';

// interface IItem {
//   value: string,
//   label: string,
//   order?: number,
//   isWeekend?: boolean
// }

// interface IProps {
//   name: string, // Name of the code input that chip relates to
//   itemsList: IItem[],
//   onClick: (item: IItem) => void

//   isSelected?: () => void // Callback to check if item is selected
//   isFetching?: boolean // Fetching items list data
//   disabled?: boolean
//   labelKey?: string // Key for label value
//   tooltipKey?: string // Key for tooltip value
//   onOpenCatalog?: () => void // Define for action on "Open catalog" button click. Also defines to show "Open catalog" button
//   additionalChipsContent?: JSX | () => JSX // Define JSX element that will be displayed after chips list
// }

const QuickPickChipsList = (props) => {
  const additionalChipsContent = typeof props.additionalChipsContent === 'function' ? props.additionalChipsContent() : props.additionalChipsContent;

  return (
    <div className="quick_pick_items_list">
      {props.isFetching && <ChipsListSkeleton />}

      {!props.isFetching && Array.isArray(props.itemsList) && (
        <ChipsList
          name={props.name}
          labelKey={props.labelKey}
          tooltipKey={props.tooltipKey}
          itemsList={props.itemsList}
          isSelected={props.isSelected}
          disabled={props.disabled}
          onClick={props.onClick}
        />
      )}

      {props.onOpenCatalog && (
        <Button
          id={`${props.name}_viewMore`}
          type="button"
          className="p-button-text p-button-info p-button-rounded py-1 px-2"
          label={t('Open_Catalog')}
          iconPos="right"
          disabled={props.disabled}
          onClick={props.onOpenCatalog}
        />
      )}

      {props.additionalChipsContent && additionalChipsContent}
    </div>
  );
};

export default QuickPickChipsList;
