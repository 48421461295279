export const calculateGroupTotal = (records) => {
  let total = 0;

  if (Array.isArray(records)) {
    for (let item of records) {
      // Convert billed to a number and treat non-numeric values as 0
      const billedValue = typeof item.billed === 'number' ? item.billed : parseFloat(item.billed) || 0;
      total += billedValue;
    }
  }

  // Fix floating-point precision issues by rounding to two decimal places
  return (Math.round(total * 100) / 100).toFixed(2);
};
