import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import CreateGroupTemplate from '../../views/TeleplanGroup/Layouts/CreateGroupTemplate';
import CircularProgress from '../../../../../../components/Misc/Loader/CircularProgress/CircularProgress';

import {
  deleteGroupInvoicesTemplate,
  getGroupInvoicesTemplates,
  getInvoicesCatalogMostRecentCodes,
  updateInvoicesQuickPickCodesList
} from '../../../../actions/claims.action.creators';
import { setToastMessage } from '../../../../../core/actions/core.action.creators';
import { defaultGuid } from '../../../../../config/defaultValuesConfig';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { inputs } from '../../helpers/inputs';
import { icons } from '../../../../../config/stylesConfig';
import { commonInputs } from '../../../../../config/commonInputsConfig';
import { getCategoryType } from '../../../../helpers/getCategoryType';
import { indexOf, sortBy } from 'lodash';
import { addMissingCodesToRecentList } from '../../helpers/updateRecentCodesList';
import { patientFullNameWithAge } from '../../../../../utils/patientFullName';
import { getDefaultValues } from '../../helpers/defaultValues';
import { t } from '../../../../../../service/localization/i18n';
import moment from 'moment';

const GroupTemplateListDialog = () => {
  const dispatch = useDispatch();
  const { watch, reset, localState, setLocalState, setValue } = useFormContext();
  const [replaceAllEnteredDataDialog, setReplaceAllEnteredDataDialog] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [deleteTemplate, setDeleteTemplate] = useState({ showDialog: false, rowData: {} });
  const [editTemplateData, setEditTemplateData] = useState({});
  const invoiceType = watch(inputs.payor.name);
  const loadingData = localState.gettingGroupInvoicesTemplate || localState.delettingGroupInvoicesTemplate;

  const actionsBodyTemplate = (rowData) => {
    if (rowData.TemplateGuid === defaultGuid) return null;

    return (
      <div className="flex justify-content-end buttonsGap">
        <Button
          className="p-button-text p-button-rounded"
          icon={icons.edit}
          onClick={() => {
            setEditTemplateData(rowData);
            setLocalState((prevState) => ({ ...prevState, editGroupTemplateDialog: true }));
          }}
        />
        <Button className="p-button-text p-button-rounded" icon={icons.delete} onClick={() => setDeleteTemplate({ showDialog: true, rowData })} />
      </div>
    );
  };

  function fetchRecentCodesForPatient(patientGuid) {
    return new Promise((resolve) => {
      dispatch(
        getInvoicesCatalogMostRecentCodes({
          patientGuid: patientGuid,
          updateRedux: false,
          loading: false,
          callback: (mostRecentCodes) => {
            // Resolve the promise with the fetched data
            resolve(mostRecentCodes);
          },
          errorCallback: (error) => {
            dispatch(setToastMessage({ type: 'error', message: error }));
          }
        })
      );
    });
  }

  const onSelect = () => {
    const isPatientsSelected = watch(inputs.patient.name)?.length;
    if (isPatientsSelected) return setReplaceAllEnteredDataDialog(true);
    onSelectTemplate();
  };

  const onSelectTemplate = () => {
    if (selectedTemplate?.length === 1) {
      onSelectOneTemplate();
    }

    if (selectedTemplate?.length > 1) {
      onSelectMultipleTemplates();
    }
  };

  const onSelectMultipleTemplates = () => {
    if (replaceAllEnteredDataDialog) {
      onHideReplaceAllEnteredDataDialog();
    }

    // Reduce over selectedTemplate to gather valid and deleted patients separately.
    const { validPatients, deletedPatients } = selectedTemplate.reduce(
      (acc, template) => {
        const patients = template.FirstStep[inputs.patient.name] || [];
        patients.forEach((patient) => {
          if (patient.Status === -1) {
            acc.deletedPatients.push(patient);
          } else {
            acc.validPatients.push(patient);
          }
        });
        return acc;
      },
      { validPatients: [], deletedPatients: [] }
    );

    // Notify if there are any deleted patients.
    if (deletedPatients.length > 0) {
      const deletedPatientsNames = deletedPatients.map((patient) => patientFullNameWithAge(patient)).join(', ');
      dispatch(
        setToastMessage({
          type: 'warn',
          message: `${deletedPatientsNames} have been deleted and will not be displayed in the template.`
        })
      );
    }

    // Deduplicate valid patients by PatientGuid.
    const uniquePatients = Array.from(new Map(validPatients.map((patient) => [patient.PatientGuid, patient])).values());

    // Merge existing form values with the updated patient list.
    const firstStep = {
      ...getDefaultValues({ isNew: true, isGroup: true }),
      [inputs.patient.name]: uniquePatients
    };

    // Update form state and trigger revalidation.
    reset(firstStep);
    setValue(commonInputs.fantom.name, `${Math.random()}`, { shouldDirty: true });

    // Update local state and reset the selected template.
    setLocalState((prevState) => ({
      ...prevState,
      groupTemplateListDialog: false
    }));
    setSelectedTemplate(null);
  };

  const onSelectOneTemplate = () => {
    if (replaceAllEnteredDataDialog) onHideReplaceAllEnteredDataDialog();

    const template = selectedTemplate?.[0];
    const patients = template.FirstStep[inputs.patient.name];
    const validPatients = patients?.filter((i) => i.Status !== -1); // WIN-545 - filter deleted patients
    const patientsList = patients?.filter((i) => i.Status !== -1);
    const feeCodes = template.FirstStep[inputs.feeCodes.codeType];

    // WIN-545 - filter deleted patients
    const filteredFirstStep = { ...template.FirstStep, [inputs.patient.name]: patientsList };
    // WIN-545 - filter deleted patients
    const filteredRecords = template.Records.filter((i) => {
      const patient = i[inputs.patient.name][0];
      return patient.PatientGuid !== defaultGuid;
    });

    // WIN-545 - filter deleted patients
    if (patients?.some((i) => i.Status === -1)) {
      const deletedPatients = patients?.filter((i) => i.Status === -1);
      const deletedPatientsNames = deletedPatients?.map((i) => patientFullNameWithAge(i))?.join(', ');

      dispatch(
        setToastMessage({
          type: 'warn',
          message: `${deletedPatientsNames} have been deleted and will not be displayed in the template.`
        })
      );
    }

    // Create an array of promises to fetch recent codes for each patient in patients
    const allPromises = validPatients?.map((i) => {
      const patientGuid = i?.PatientGuid;
      return fetchRecentCodesForPatient(patientGuid);
    });

    setLocalState((prevState) => ({ ...prevState, gettingRecentCodes: true }));

    // Use Promise.all to wait for all the recent codes requests
    Promise.all(allPromises)
      .then((results) => {
        // Sort recent codes response in the same order as patients selected on the FirstStep
        const sortedResults = sortBy(results, (item) => {
          return indexOf(
            patientsList.map((p) => p.PatientGuid),
            item.patientGuid
          );
        });

        // Update invoices quick pick list for the first step
        dispatch(updateInvoicesQuickPickCodesList(sortedResults[0]));

        // Create a list of patients with their corresponding recent codes
        const listOfPatients = sortedResults?.map((i) => ({
          [inputs.patientGuid.name]: i.patientGuid,
          [inputs.recentCodes.name]: i
        }));

        // Update records quick pick list for Dx and Referral steps
        setLocalState((prevState) => ({ ...prevState, listOfPatients, gettingRecentCodes: false }));
      })
      .catch((error) => {
        setLocalState((prevState) => ({ ...prevState, gettingRecentCodes: false }));
        dispatch(setToastMessage({ type: 'error', message: error }));
      });

    // Update form state with the selected template's FirstStep
    reset(filteredFirstStep);

    // Set dirty screen to trigger revalidation
    setValue(commonInputs.fantom.name, `${Math.random()}`, { shouldDirty: true });

    // Add fee code to quick pick list if it's missing
    const feeType = getCategoryType(inputs.feeCodes.codeType, invoiceType);
    const selectedCodes = { [feeType]: feeCodes };
    addMissingCodesToRecentList({ selectedCodes });

    // Update local state and selectedTemplate after processing
    setLocalState((prevState) => ({
      ...prevState,
      groupTemplateListDialog: false,
      groupRecords: filteredRecords || [],
      selectedTemplate: template
    }));
    setSelectedTemplate(null);
  };

  const onHideReplaceAllEnteredDataDialog = () => {
    setReplaceAllEnteredDataDialog(false);
  };

  const replaceAllEnteredDataDialogFooter = () => {
    return (
      <>
        <Button id={elementIDs.dialogAccept} label={t('Yes')} onClick={onSelectTemplate} autoFocus />
        <Button className="p-button-outlined" id={elementIDs.dialogClose} label={t('No')} onClick={onHideReplaceAllEnteredDataDialog} />
      </>
    );
  };

  const onHideConfirmDeleteDialog = () => {
    setDeleteTemplate({ showDialog: false, rowData: {} });
  };

  const onDeleteTemplate = async () => {
    setLocalState((prevState) => ({ ...prevState, delettingGroupInvoicesTemplate: true }));
    const result = await deleteGroupInvoicesTemplate(deleteTemplate.rowData.TemplateGuid, deleteTemplate.rowData.DoctorGuid);
    setLocalState((prevState) => ({ ...prevState, delettingGroupInvoicesTemplate: false }));
    if (result) {
      // Close delete confirm dialog
      setDeleteTemplate({ showDialog: false, rowData: {} });

      // Update templates list
      setLocalState((prevState) => ({ ...prevState, gettingGroupInvoicesTemplate: true }));
      const groupTemplate = await getGroupInvoicesTemplates(watch(inputs.practitioner.name));
      setLocalState((prevState) => ({ ...prevState, gettingGroupInvoicesTemplate: false }));
      if (groupTemplate) setLocalState((prevState) => ({ ...prevState, templatesList: groupTemplate }));
    }
  };

  const footerLayout = () => {
    return (
      <>
        <Button id={elementIDs.dialogAccept} label={t('Yes')} onClick={onDeleteTemplate} autoFocus />
        <Button className="p-button-outlined" id={elementIDs.dialogClose} label={t('No')} onClick={onHideConfirmDeleteDialog} />
      </>
    );
  };

  const onHideDialog = () => {
    setLocalState((prevState) => ({ ...prevState, groupTemplateListDialog: false }));
    setSelectedTemplate(null);
  };

  const onHideEditTemplateDialog = () => {
    setLocalState((prevState) => ({ ...prevState, editGroupTemplateDialog: false }));
  };

  const batchTemplateDialogFooter = () => {
    return (
      <div className="buttonGap">
        <Button id={elementIDs.dialogClose} label={t('Select')} disabled={!selectedTemplate} onClick={onSelect} />
        <Button id={elementIDs.dialogClose} className="p-button-outlined" label={t('Cancel')} onClick={onHideDialog} />
      </div>
    );
  };

  const dateCreatedBody = (rowData) => {
    return <div>{moment(moment(rowData.DateCreated).toDate()).format('MM/DD/YYYY')}</div>;
  };

  const descriptionBody = (rowData) => {
    return (
      <div>{rowData.Description}</div>
      // <div style={{ width: '750px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
      //   {rowData.Description}
      // </div>
    );
  };

  return (
    <>
      <Dialog
        id={elementIDs.groupTemplateListDialog}
        header={t('Batch_Templates')}
        visible={localState.groupTemplateListDialog}
        breakpoints={{ '1366px': '95vw', '960px': '95vw' }}
        style={{ width: '75vw' }}
        onHide={onHideDialog}
        footer={batchTemplateDialogFooter}
      >
        {loadingData && <CircularProgress />}

        <DataTable
          //value={list}
          value={localState.templatesList}
          responsiveLayout="scroll"
          sortField="DateCreated"
          sortOrder={-1}
          selectionMode="checkbox"
          selection={selectedTemplate}
          emptyMessage={loadingData ? `${t('Loading')}...` : t('No_records_found')}
          onSelectionChange={(e) => setSelectedTemplate(e.value)}
        >
          <Column selectionMode="multiple" headerStyle={{ width: '3em' }} style={{ height: '4em' }} />
          <Column field="DateCreated" body={dateCreatedBody} header="Created" sortable headerStyle={{ width: '7.5em' }} />
          <Column field="Name" header="Name" sortable style={{ maxWidth: '26em', minWidth: '16em' }} />
          <Column field="Description" body={descriptionBody} header="Description" sortable />
          <Column body={actionsBodyTemplate} style={{ minWidth: '8em' }} frozen alignFrozen="right" />
        </DataTable>
      </Dialog>

      {/* DELETE GROUP TEMPLATE CONFIRM DIALOG */}
      <Dialog
        id={elementIDs.deleteGroupTemplateConfirm}
        header={t('Warning')}
        visible={deleteTemplate.showDialog}
        breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
        style={{ width: '30vw' }}
        footer={footerLayout()}
        onHide={onHideConfirmDeleteDialog}
      >
        {String.format(t('Are_you_sure_you_want_to_delete_this_template'), deleteTemplate.rowData.Name)}
      </Dialog>

      {/* EDIT TEMPLATE DIALOG */}
      <Dialog
        className="createGroupTemplateDialog"
        id={elementIDs.editGroupTemplateDialog}
        header={t('Edit_Template')}
        visible={localState.editGroupTemplateDialog}
        breakpoints={{ '1366px': '60vw', '960px': '70vw', '768px': '95vw' }}
        style={{ width: '45vw' }}
        onHide={onHideEditTemplateDialog}
      >
        <CreateGroupTemplate defaultValues={editTemplateData} option="edit" />
      </Dialog>

      {/* REPLACE ALL ENTERED DATA WARN DIALOG */}
      <Dialog
        id={elementIDs.replaceAllEnteredDataWarnDialog}
        header={t('Warning')}
        visible={replaceAllEnteredDataDialog}
        breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
        style={{ width: '30vw' }}
        footer={replaceAllEnteredDataDialogFooter()}
        onHide={onHideReplaceAllEnteredDataDialog}
      >
        {t('We_will_pre_fill_the_patients_list_using_the_selected_template')}
      </Dialog>
    </>
  );
};

export default GroupTemplateListDialog;
