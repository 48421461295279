export const practitionerTypes = [
  { label: 'Physician', value: 'Physician' },
  { label: 'Nurse', value: 'Nurse' },
  { label: 'Optometrist', value: 'Optometrist' },
  { label: 'Acupuncturist', value: 'Acupuncturist' },
  { label: 'Midwife', value: 'Midwife' },
  { label: 'Chiropractor', value: 'Chiropractor' },
  { label: 'Physiotherapist', value: 'Physiotherapist' },
  { label: 'RMT', value: 'RMT' },
  { label: 'Other', value: 'Other' }
];

export const practiceTypes = [
  'General Practitioner',
  'Critical Care',
  'Emergency Medicine',
  'Family Medicine',
  'Anesthesiology',
  'Dermatology',
  'Ophthalmology',
  'Otolaryngology',
  'Internal Medicine',
  'Cardiology',
  'Clinical Immunology and Allergy',
  'Endocrinology and Metabolism',
  'Gastroenterology',
  'Geriatric Medicine',
  'Hematology and Oncology',
  'Infectious Diseases',
  'Nephrology',
  'Occupational Medicine',
  'Respirology',
  'Rheumatology',
  'Neurology',
  'Neurosurgery',
  'Obstetrics and Gynecology',
  'Orthopaedics',
  'Pediatrics',
  'Psychiatry',
  'Physical Medicine and Rehabilitation',
  'Plastic Surgery',
  'General Surgery',
  'Vascular Surgery',
  'Cardiac Surgery',
  'Thoracic Surgery',
  'Urology',
  'Diagnostic Radiology',
  'Palliative Medicine',
  'Diagnostic Ultrasound',
  'Laboratory Medicine',
  'Nuclear Medicine',
  'Surgical Assists',
  'Consultant Specialist'
];

export const licencingsBodies = [
  {
    label: 'College of Physicians and Surgeons of British Columbia (CPSBC)',
    value: 'College of Physicians and Surgeons of British Columbia (CPSBC)'
  },
  { label: 'College of Physicians and Surgeons of Alberta (CPSA)', value: 'College of Physicians and Surgeons of Alberta (CPSA)' },
  { label: 'College of Physicians and Surgeons of Saskatchewan (CPSS)', value: 'College of Physicians and Surgeons of Saskatchewan (CPSS)' },
  { label: 'College of Physicians and Surgeons of Manitoba (CPSM)', value: 'College of Physicians and Surgeons of Manitoba (CPSM)' },
  { label: 'College of Physicians and Surgeons of Ontario (CPSO)', value: 'College of Physicians and Surgeons of Ontario (CPSO)' },
  { label: 'Collège des médecins du Québec (CMQ)', value: 'Collège des médecins du Québec (CMQ)' },
  { label: 'College of Physicians and Surgeons of New Brunswick (CPSNB)', value: 'College of Physicians and Surgeons of New Brunswick (CPSNB)' },
  { label: 'College of Physicians and Surgeons of Nova Scotia (CPSNS)', value: 'College of Physicians and Surgeons of Nova Scotia (CPSNS)' },
  {
    label: 'College of Physicians and Surgeons of Prince Edward Island (CPSPEI)',
    value: 'College of Physicians and Surgeons of Prince Edward Island (CPSPEI)'
  },
  {
    label: 'College of Physicians and Surgeons of Newfoundland and Labrador (CPSNL)',
    value: 'College of Physicians and Surgeons of Newfoundland and Labrador (CPSNL)'
  },
  { label: 'Medical Licensing Authority of Nunavut', value: 'Medical Licensing Authority of Nunavut' },
  {
    label: 'Department of Health and Social Services, Government of the Northwest Territories',
    value: 'Department of Health and Social Services, Government of the Northwest Territories'
  },
  { label: 'Department of Health, Government of Yukon', value: 'Department of Health, Government of Yukon' },
  { label: 'Other', value: 'Other' }
];

export const roles = ['Practitioner', 'Doctor', 'MOA', 'Owner', 'Employee', 'Other'];
export const bureauRoles = ['Owner', 'Employee', 'Other'];

export const clinicTypes = [
  { label: 'Professional Corporation', value: 'professionalCorporation' },
  { label: 'Corporation', value: 'corporation' },
  { label: 'Sole Proprietorship', value: 'soleProprietorship' },
  { label: 'Partnership', value: 'partnership' },
  { label: 'Cooperative', value: 'cooperative' },
  { label: 'Limited Liability Partnership (LLP)', value: 'llp' },
  { label: 'Non-Profit Organization', value: 'nonProfitOrganization' },
  { label: 'Don’t know', value: 'dontKnow' }
];

export const bureauTypes = [
  { label: 'Sole Proprietorship', value: 'soleProprietorship' },
  { label: 'Partnership', value: 'partnership' },
  { label: 'Corporation', value: 'corporation' },
  { label: 'Cooperative', value: 'cooperative' },
  { label: 'Limited Liability Partnership (LLP)', value: 'llp' },
  { label: 'Non-Profit Organization', value: 'nonProfitOrganization' },
  { label: 'Professional Corporation', value: 'professionalCorporation' },
  { label: 'Don’t know', value: 'dontKnow' }
];
