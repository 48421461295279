import { useDispatch, useSelector } from 'react-redux';
import { updatePreference } from '../../../helpers/updatePreference';
import { savePreferences } from '../../../actions/preferences.action.creators';
import { getPresetPrefs } from '../../../../claims/helpers/getDefaultPrefs';
import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { defaultGuid } from '../../../../config/defaultValuesConfig';
import { getPayeeOptions } from '../../../../utils/getPayeeInfo';

export const usePrivateInvoicePrefs = () => {
  const dispatch = useDispatch();
  const { currentUserPrefs } = useSelector((state) => state.preferences);
  const payeeOptions = getPayeeOptions();
  const defaultPrefs = currentUserPrefs?.prefs?.find((i) => i.label === 'default');

  const savePrefs = (value, prefsCode) => {
    const updatedDisplayPrefs = updatePreference(defaultPrefs, value, prefsCode);
    dispatch(savePreferences(currentUserPrefs?.UserGuid, updatedDisplayPrefs));
  };

  const getPreferenceValue = (prefsCode) => {
    return defaultPrefs?.content?.find((i) => i.label === prefsCode);
  };

  const isLocked = (value, defaultCode) => {
    const prefs = defaultPrefs?.content;
    return getPresetPrefs(prefs, defaultCode)?.toUpperCase() === value?.toUpperCase();
  };

  const getPayeePrefs = (prefCode) => {
    const prefsValue = getPreferenceValue(prefCode) || defaultGuid;
    return payeeOptions.find((option) => option.id === prefsValue?.value);
  };

  const getPayeePrefsId = () => {
    return getPayeePrefs(prefsCodes.defaultPayTo);
  };

  const getPayeePrefsAddress = () => {
    return getPayeePrefs(prefsCodes.privateInvoiceAddress);
  };

  const getPayeePrefsEmail = () => {
    return getPayeePrefs(prefsCodes.privateInvoiceEmail);
  };

  const getPayeePrefsPhone = () => {
    return getPayeePrefs(prefsCodes.privateInvoicePhone);
  };

  return {
    savePrefs,
    getPreferenceValue,
    isLocked,
    payeePrefs: getPayeePrefsId(),
    payeePrefsAddress: getPayeePrefsAddress(),
    payeePrefsEmail: getPayeePrefsEmail(),
    payeePrefsPhone: getPayeePrefsPhone()
  };
};
