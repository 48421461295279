import { useSelector } from 'react-redux';
import withAuthCondition from './withAuthCondition';

const withRequirePrivacy = (Component) => {
  return withAuthCondition(Component, () => {
    const { sessionGuid } = useSelector((state) => state.registration.registrationFormState.initialFormState);
    return Boolean(sessionGuid);
  });
};
export default withRequirePrivacy;
