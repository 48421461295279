import { store } from '../../../../store';
import { setToastMessage } from '../../../core/actions/core.action.creators';

export const pinNotMatchToast = () => {
  store.dispatch(
    setToastMessage({
      message: `PINs don't match. Try again.`,
      type: 'error',
      lifeTime: 4000
    })
  );
};
