import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';

import { Button } from 'primereact/button';
import CircularProgress from '../../../components/Misc/Loader/CircularProgress/CircularProgress';
import DeleteDialog from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import NoteCard from '../../../components/Widgets/Notes/NoteCard/NoteCard';
import MailsLayout from './MailsLayout';
import Reply from './Reply';
import Edit from './Edit';

import { routes } from '../../../routes/routes';
import { decodeText } from '../../utils/decodeText';
import { trimLeftZeros } from '../../utils/trimLeftZeros';

import './NoteDetails.scss';
import { Menu } from 'primereact/menu';

const NoteDetails = ({
  isReply,
  isEdit,
  loading,
  note,
  notes,
  onSaveReply,
  onEditNote,
  fetchNotes,
  onDeleteNote,
  inboxType,
  rowActionsMenuItems,
  setNoteDetails,
  onReplyClick,
  onViewVlaimProfile
}) => {
  const menuRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [showDialog, setShowDialog] = useState(false);
  const [noteRecord, setNoteRecord] = useState(note);
  const [contentFirst, setContentFirst] = useState(0);
  const [direction, setDirection] = useState();
  const currentNote = contentFirst + 1;
  const noteIndex = notes?.notesList?.findIndex((item) => item.NoteGuid === noteRecord.NoteGuid);
  const viewMode = !isReply && !isEdit;

  useEffect(() => {
    setNoteRecord(note);
  }, [note]);

  useEffect(() => {
    if (noteIndex === -1) {
      if (direction === 'nextPage' || direction === 'firstPage') {
        setNoteRecord(notes?.notesList?.[0]);
        dispatch(setNoteDetails(notes?.notesList?.[0]));
      } else if (direction === 'prevPage' || direction === 'lastPage') {
        setNoteRecord(notes?.notesList?.[notes?.notesList?.length - 1]);
        dispatch(setNoteDetails(notes?.notesList?.length - 1));
      }
    }
  }, [noteIndex, dispatch]);

  useEffect(() => {
    let offset = 0;
    const note = notes?.notesList?.find((i) => i.NoteGuid === noteRecord?.NoteGuid);
    if (notes?.page > 1) {
      offset = (notes?.page - 1) * notes?.pageSize;
    }

    const _contentFirst = offset + notes?.notesList?.indexOf(note);

    setContentFirst(_contentFirst);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteRecord?.NoteGuid, notes?.notesList, notes?.page, notes?.pageSize]);

  const getOnePage = async (dir) => {
    let newPage = '';
    if (dir === 'nextPage') newPage = notes?.page + 1;
    if (dir === 'prevPage') newPage = notes?.page - 1;
    if (dir === 'firstPage') newPage = 1;
    if (dir === 'lastPage') newPage = notes?.totalPages;
    fetchNotes({ page: newPage });
  };

  const navigateOnPage = (dir) => {
    setDirection(dir);

    if (dir === 'nextPage') {
      if (notes?.notesList?.length === noteIndex + 1) {
        getOnePage(dir);
      } else {
        const nextNote = notes.notesList?.[noteIndex + 1];
        setNoteRecord(nextNote);
        dispatch(setNoteDetails(nextNote));
      }
    }

    if (dir === 'prevPage') {
      if (noteIndex === 0) {
        getOnePage(dir);
      } else {
        const nextNote = notes?.notesList?.find((i, index) => index === noteIndex - 1);
        setNoteRecord(nextNote);
        dispatch(setNoteDetails(nextNote));
      }
    }

    if (dir === 'firstPage') {
      if (notes?.page === 1) {
        setNoteRecord(notes?.notesList?.[0]);
        dispatch(setNoteDetails(notes?.notesList?.[0]));
      } else {
        getOnePage(dir);
      }
    }

    if (dir === 'lastPage') {
      if (notes?.page === notes?.totalPages) {
        setNoteRecord(notes?.notesList?.[notes?.notesList?.length - 1]);
        dispatch(setNoteDetails(notes?.notesList?.[notes?.notesList?.length - 1]));
      } else {
        getOnePage(dir);
      }
    }
  };

  const handleDelete = async () => {
    await onDeleteNote(noteRecord.NoteGuid);
    setShowDialog(false);
  };

  const rowActionButtonsMenuModel = (rowData) => {
    const actionProps = {
      rowData,
      history,
      isView: true,
      onDelete: () => setShowDialog(true)
    };
    return rowActionsMenuItems(actionProps);
  };

  const onNavigateBack = () => {
    history.goBack();
  };

  const onClose = () => {
    history.goBack();
  };

  const paginator = (
    <div className="hidden sm:flex p-0">
      <div className="flex justify-content-center w-full p-0">
        <div className="p-paginator p-component">
          <Button
            className={contentFirst === 0 ? 'p-paginator-prev p-paginator-element p-link p-disabled' : 'p-paginator-first p-paginator-element p-link'}
            icon="p-paginator-icon pi pi-angle-double-left"
            onClick={() => navigateOnPage('firstPage')}
            disabled={loading}
          />
          <Button
            className={contentFirst === 0 ? 'p-paginator-prev p-paginator-element p-link p-disabled' : 'p-paginator-prev p-paginator-element p-link'}
            icon="p-paginator-icon pi pi-angle-left"
            onClick={() => navigateOnPage('prevPage')}
            disabled={loading}
          />
          <span className="p-paginator-current">{`${currentNote} of ${notes?.totalRecords}`}</span>
          <Button
            className={
              notes?.page === notes?.totalPages && noteIndex === notes?.notesList?.length - 1
                ? 'p-paginator-next p-paginator-element p-link p-disabled'
                : 'p-paginator-next p-paginator-element p-link'
            }
            icon="p-paginator-icon pi pi-angle-right"
            onClick={() => navigateOnPage('nextPage')}
            disabled={loading}
          />
          <Button
            className={
              notes?.page === notes?.totalPages && noteIndex === notes?.notesList?.length - 1
                ? 'p-paginator-last p-paginator-element p-link p-disabled'
                : 'p-paginator-last p-paginator-element p-link'
            }
            icon="p-paginator-icon pi pi-angle-double-right"
            onClick={() => navigateOnPage('lastPage')}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <MailsLayout>
        <>
          {notes.isLoadingNotes || (loading && <CircularProgress />)}
          <div id="memoDetails" className="flex align-items-center justify-content-between md:justify-content-start gap-3 py-4 md:py-1">
            <div className="">
              <Button type="button" icon="pi pi-arrow-left" text rounded className="p-button-plain" onClick={onNavigateBack} />
            </div>
            {paginator}

            <div>
              <Menu model={rowActionButtonsMenuModel(noteRecord)} popup ref={menuRef} id="note_action" />
              <Button
                className="p-button-rounded p-button-text"
                icon="pi pi-ellipsis-v"
                onClick={(event) => menuRef.current.toggle(event)}
                aria-controls="note_action"
                aria-haspopup
              />
            </div>
          </div>

          <div className="flex flex-column gap-3">
            <div className="flex flex-column gap-2">
              <div className="flex gap-2">
                <div>{`Patient:`}</div>
                {viewMode ? (
                  <Button
                    type="button"
                    className="p-0"
                    link
                    label={noteRecord?.PatientName}
                    onClick={() => history.push(`${routes.patientDetails.path}/${noteRecord?.PatientGuid}`)}
                  />
                ) : (
                  <div>{noteRecord?.PatientName}</div>
                )}
              </div>

              {noteRecord?.OfficeNumber && (
                <div className="flex gap-2">
                  <div>{`Claim #`}</div>
                  {viewMode ? (
                    <Button
                      type="button"
                      className="p-0"
                      link
                      label={`${trimLeftZeros(noteRecord?.OfficeNumber)} (o)`}
                      onClick={() => onViewVlaimProfile(noteRecord, history)}
                    />
                  ) : (
                    <div>{`${trimLeftZeros(noteRecord?.OfficeNumber)} (o)`}</div>
                  )}
                </div>
              )}
            </div>

            {/* Reply form */}
            {isReply && <Reply note={noteRecord} onReply={onSaveReply} />}

            {/* Edit form */}
            {isEdit && <Edit note={noteRecord} onEditNote={onEditNote} />}

            {/* View */}
            {viewMode && (
              <div>
                <div className="comments-wrap">
                  <NoteCard hideCard note={noteRecord} renderMenu={false} menuModel={rowActionButtonsMenuModel(noteRecord)} />
                </div>

                <div className="flex py-3">
                  <div className="flex gap-3 align-self-center">
                    <Button type="button" label="Reply" onClick={onReplyClick} disabled={loading}></Button>
                    <Button type="button" outlined label="Close" onClick={onClose}></Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>

        <DeleteDialog
          visible={showDialog}
          message={`Do you really want to delete '${decodeText(noteRecord?.Title)}' note?`}
          onHide={() => setShowDialog(false)}
          accept={handleDelete}
        />
      </MailsLayout>
    </div>
  );
};

export default NoteDetails;
