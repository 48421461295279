import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Divider } from 'primereact/divider';
import CommonCard from '../../../../common/components/CommonCard';
import { email_types, phone_types } from '../../../../config/constants';
import { useHistory } from 'react-router-dom';
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber';
import { t } from '../../../../../service/localization/i18n';
import classes from './../PatientDetails.module.scss';
import cx from 'classnames';
import { editPatientAction, setActiveTabIndexForPatientForm } from '../../../actions/patients.action.creators';
import { elementIDs } from '../../../../config/elementIDsConfig';
import VerifyAccessWordDialog from './VerifyAccessWordDialog';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { Dialog } from 'primereact/dialog';
import Representative from '../../Representative';
import { patientFullName } from '../../../../utils/patientFullName';
import { Patient } from '../../../../../components/Inputs';
import { defaultCountryCode, defaultGuid } from '../../../../config/defaultValuesConfig';
import { editPatientProfile } from '../../Patients/helpers/rowActionsMenuItems';
import { addressTypes } from '../../../../config/dropdownListsConfig';
import { formatZipCode } from '../../../../utils/formatZipCode';
import { getCountryByCode, getRegionByCode } from '../../../../utils/getAddressData';

const InvoicesCard = ({ patient_details, representative, setRepresentative, showSkeleton, gettingRepresentative }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile, clientWidth } = useSelector((state) => state.core.window);

  const [showVerifyWordDialog, setShowVerifyWordDialog] = useState(false);
  const [showRepresentativeDialog, setShowRepresentativeDialog] = useState(false);
  const isRepresentative = representative?.RepresentativeGuid;

  const onEditPatient = () => {
    isMobile && dispatch(setActiveTabIndexForPatientForm(1));
    editPatientProfile(patient_details, history);
  };

  const onAddRepresentative = () => {
    setShowRepresentativeDialog(true);
  };

  const onDeleteRepresentative = async () => {
    dispatch(editPatientAction({ ...patient_details, RepresentativeGuid: defaultGuid }));
    setRepresentative(null);
  };

  const onCloseRepresentative = () => {
    setShowRepresentativeDialog(false);
  };

  const onRepresentativeSuccessCreate = (data) => {
    setRepresentative(data);
    onCloseRepresentative();
  };

  const onRepresentativeSuccessEdit = (data) => {
    setRepresentative(data);
    onCloseRepresentative();
  };

  const emailTypeTooltip = email_types.find((i) => i.value === patient_details.EmailType)?.label;
  const phoneTypeTooltip = phone_types.find((i) => i.value === patient_details.PhoneType)?.label;
  const addressTypeTooltip = addressTypes.find((i) => i.value === patient_details.AddressType)?.label;

  const getContactsLayout = (data) => {
    return (
      <>
        {!data?.Email && !data?.Phone ? (
          <div className="flex align-items-center col-11 p-0">{t('No_email_or_phone_number')}</div>
        ) : (
          <div className="col-12 p-0">
            {data?.Email && (
              <div className="flex col-12 p-0 medium-size-text gap-2 mb-1">
                {/* <div className={cx('col-1 p-0', classes.iconContainer)}>
                  <i className="pi pi-envelope mr-4" />
                </div> */}
                <div className="flex flex-column col-11 p-0">
                  {data?.EmailType ? (
                    <div className="flex">
                      <Tooltip target=".emailType" />
                      <span id={elementIDs.patientEmail} className="mr-2">
                        {data?.Email}
                      </span>
                      <span id={elementIDs.patientEmailType} className={cx('emailType', classes.typeWrap)} data-pr-tooltip={emailTypeTooltip}>
                        {`(${data?.EmailType.toLowerCase()})`}
                      </span>
                    </div>
                  ) : (
                    <span id={elementIDs.patientEmail}>{data?.Email}</span>
                  )}
                </div>
              </div>
            )}

            {data?.Phone && (
              <div className="flex col-12 p-0 medium-size-text gap-2">
                {/* <div className={cx('col-1 p-0', classes.iconContainer)}>
                  <i className="pi pi-phone mr-4" />
                </div> */}
                <div className="flex flex-column col-11 p-0">
                  {data?.PhoneType ? (
                    <div className="flex">
                      <Tooltip target=".phoneType" />
                      <span id={elementIDs.patientPhone} className="mr-2">
                        {formatPhoneNumber(data?.Phone)}
                      </span>
                      <span id={elementIDs.patientPhoneType} className={cx('phoneType', classes.typeWrap)} data-pr-tooltip={phoneTypeTooltip}>
                        {`(${data?.PhoneType.toLowerCase()})`}
                      </span>
                    </div>
                  ) : (
                    <span id={elementIDs.patientPhone}>{formatPhoneNumber(data?.Phone)}</span>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const getAddressLayout = (data) => {
    const region = getRegionByCode(data?.Province, data?.Country);
    const isCanada = data?.Country === defaultCountryCode;
    const country = isCanada ? '' : getCountryByCode(data?.Country)?.label;
    const province = isCanada || data?.Country === 'US' ? region?.value : region.label;

    return (
      <>
        {!data?.AddressLine?.trim() && !data?.City?.trim() ? (
          <div className="flex align-items-center col-11 p-0">{t('No_address')}</div>
        ) : (
          <div className="col-12 p-0">
            {(data?.AddressLine || data?.City) && (
              <div className="flex col-12 p-0 medium-size-text gap-2">
                {/* <div className={cx('col-1 p-0', classes.iconContainer)}>
                  <Tooltip target=".addressType" />
                  {data?.AddressType === 'M' ? (
                    <i className={cx('pi pi-envelope mr-4 addressType', classes.typeWrap)} data-pr-tooltip={addressTypeTooltip} />
                  ) : (
                    <i className={cx('pi pi-home mr-4 addressType', classes.typeWrap)} data-pr-tooltip={addressTypeTooltip} />
                  )}
                </div> */}

                <div className="flex flex-column col-11 p-0">
                  <span id={elementIDs.patientAddressLine}>{data?.AddressLine}</span>

                  <div className="flex gap-2">
                    {(data?.City || data?.Province) && (
                      <div className="mb-1">
                        {data?.City && data?.Province ? (
                          <span id={elementIDs.patientCity}>{`${data?.City}, ${data?.Province && province}`}</span>
                        ) : (
                          <>
                            {/* <i className="pi pi-home mr-4" /> */}
                            <span id={elementIDs.patientCity}>{data?.Province && province}</span>
                          </>
                        )}
                      </div>
                    )}

                    {data?.Zip && (
                      <div id={elementIDs.patientZip} className="mb-1">
                        {formatZipCode(data?.Zip, data?.Country)}
                      </div>
                    )}
                  </div>

                  {country && <div>{country}</div>}
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const mrp = patient_details?.MRPNumber ? `${patient_details?.MRPName} #${patient_details?.MRPNumber}` : t('N_A');
  const facilityLayout = (
    <div className="flex flex-column gap-1">
      <div>{`${t('Facility')}: ${patient_details?.Facility || t('N_A')}`}</div>
      <div>{`${t('MRP')}: ${mrp}`}</div>
    </div>
  );

  const footerContent = () => {
    return (
      patient_details?.[commonInputs.accessWord.name] && (
        <Button className="p-button-text w-auto" label="Verify Patient Access Word" onClick={() => setShowVerifyWordDialog(true)} />
      )
    );
  };

  return (
    <>
      <CommonCard title={t('Contact_Info')} footerContent={footerContent}>
        <div className="flex flex-column sm:flex-row">
          <div className="flex flex-column gap-3 w-full">
            <div className="flex align-items-center gap-3">
              <div className="font-bold">{t('Patient.1')}</div>
              <Button
                text
                rounded
                icon="pi pi-pencil"
                tooltip="Edit patient"
                tooltipOptions={{ position: 'top', showDelay: 500 }}
                onClick={() => onEditPatient()}
              />
            </div>

            {/* PATIENT CONTACT INFO */}
            <div className="flex col-12 p-0">
              {showSkeleton ? (
                <div className="flex flex-column">
                  <Skeleton width="8rem" />
                  <Skeleton className="mt-2" width="8rem" />
                </div>
              ) : (
                patient_details && getContactsLayout(patient_details)
              )}
            </div>

            {/* PATIENT ADDRESS */}
            <div className="flex col-12 p-0">
              {showSkeleton ? (
                <div className="flex flex-column">
                  <Skeleton width="8rem" />
                  <Skeleton className="mt-2" width="8rem" />
                </div>
              ) : (
                patient_details && getAddressLayout(patient_details)
              )}
            </div>

            <div className="flex">
              <div className="flex col-12 p-0">
                {showSkeleton ? (
                  <div className="flex flex-column">
                    <Skeleton width="8rem" />
                    <Skeleton className="mt-2" width="8rem" />
                  </div>
                ) : (
                  facilityLayout
                )}
              </div>
            </div>

            {patient_details?.Comment && <div className="flex">{patient_details.Comment}</div>}
          </div>

          <Divider className={classes.verticalDevider} layout={clientWidth > 556 ? 'vertical' : 'horizontal'} />

          <div className="flex flex-column gap-3 w-full">
            <div className="flex align-items-center gap-3" style={{ height: '32px' }}>
              <div className="font-bold">{t('Representative')}</div>
              {isRepresentative && (
                <div className="flex gap-3">
                  <Button
                    text
                    rounded
                    icon="pi pi-pencil"
                    tooltip="Edit representative"
                    tooltipOptions={{ position: 'top', showDelay: 500 }}
                    onClick={onAddRepresentative}
                  />
                  <Button
                    text
                    rounded
                    icon="pi pi-trash"
                    tooltip="Delete representative"
                    tooltipOptions={{ position: 'top', showDelay: 500 }}
                    onClick={onDeleteRepresentative}
                  />
                </div>
              )}
            </div>

            {gettingRepresentative ? (
              <>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </>
            ) : (
              <>
                {isRepresentative ? (
                  <>
                    {/* Representative name */}
                    <div className="line-height-2">
                      {patientFullName(representative) && <div>{patientFullName(representative)}</div>}
                      {representative?.Relationship && <div>{representative?.Relationship}</div>}
                      {representative?.CompanyName && <div>{representative?.CompanyName}</div>}
                    </div>

                    {/* REPRESENTATIVE CONTACT INFO */}
                    <div className="flex col-12 p-0">
                      {showSkeleton ? (
                        <div className="flex flex-column">
                          <Skeleton width="8rem" />
                          <Skeleton className="mt-2" width="8rem" />
                        </div>
                      ) : (
                        representative && getContactsLayout(representative)
                      )}
                    </div>

                    {/* REPRESENTATIVE ADDRESS */}
                    <div className="flex col-12 p-0">
                      {showSkeleton ? (
                        <div className="flex flex-column">
                          <Skeleton width="8rem" />
                          <Skeleton className="mt-2" width="8rem" />
                        </div>
                      ) : (
                        representative && getAddressLayout(representative)
                      )}
                    </div>

                    {representative?.Comment && <div>{representative?.Comment}</div>}
                  </>
                ) : (
                  <div className="flex flex-column gap-3">
                    <span>No representative provided</span>
                    <Patient
                      name="representative"
                      value={representative}
                      hideLabel
                      searchForRepresentative
                      showAddPatientButton
                      newPatientButtonTooltip="Create new Representative"
                      placeholder="Search by Name, PHN"
                      inputWrapStyles={{ height: 'auto' }}
                      onChange={(e) => onRepresentativeSuccessCreate(e.value)}
                      onAddClick={onAddRepresentative}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </CommonCard>

      <VerifyAccessWordDialog
        patientGuid={patient_details.PatientGuid}
        visible={showVerifyWordDialog}
        onHide={() => setShowVerifyWordDialog(false)}
      />

      <Dialog
        className="w-full"
        contentClassName="pb-0"
        header={
          <div className="flex flex-column gap-2 text-center">
            <div className="text-lg md:text-2xl font-bold">{representative ? 'Edit Guardian/Representative' : 'Create Guardian/Representative'}</div>
            <div className="text-md md:text-lg font-semibold">{`Patient: ${patientFullName(patient_details)}`}</div>
          </div>
        }
        visible={showRepresentativeDialog}
        style={{ maxWidth: '530px' }}
        onHide={onCloseRepresentative}
      >
        <Representative
          patient={patient_details}
          representative={representative}
          onClose={onCloseRepresentative}
          onSuccessCreate={onRepresentativeSuccessCreate}
          onSuccessEdit={onRepresentativeSuccessEdit}
        />
      </Dialog>
    </>
  );
};

export default InvoicesCard;
