import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputWrapper } from '../../../Inputs';
import ControlledCountryPicker from '../../../Inputs/CountryPicker/ControlledCountryPicker';
import { inputWrapperStyles } from '../helpers/styles';
import { t } from '../../../../service/localization/i18n';

const Country = () => {
  const name = 'Country';
  const {
    formState: { errors }
  } = useFormContext();

  return (
    <InputWrapper name={name} label={t('Country')} errorMessage={errors[name]?.message} style={inputWrapperStyles}>
      <ControlledCountryPicker />
    </InputWrapper>
  );
};

export default Country;
