import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import DeleteConfirmation from '../../common/components/DeleteConfirmation.js';

import { deletePayment, getClaimInfo, setPaymentDetails } from '../actions/claims.action.creators';
import { setToastMessage } from '../../core/actions/core.action.creators';
import { currencyFormat } from '../../utils/currencyFormat';
import { paymentMethodOptions } from '../components/ReceivePayment/helpers/paymentMethodOptions.js';
import { routes } from '../../../routes/routes';
import { icons } from '../../config/stylesConfig';
import { t } from '../../../service/localization/i18n';
import moment from 'moment';

const PaymenstDialog = ({ data, showPaymentsDialog, setShowPaymentsDialog, paymentCount, onDeletePayment }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { gettingClaimInfo } = useSelector((state) => state.claims);
  const [deleteDialog, setDeleteDialog] = useState({ visible: false, rowData: {} });

  const [tableData, setTableData] = useState([]);

  const itemsNumber = Array.from({ length: paymentCount ? paymentCount : data?.Payments?.length }, () => 'item');

  // Set initial table data
  useEffect(() => {
    setTableData(data.Payments);
  }, [data.Payments]);

  const paymentsDialogFooter = <Button label={t('Close')} onClick={() => setShowPaymentsDialog(false)} />;

  const paymentDateTemplate = (rowData) => {
    if (gettingClaimInfo) return <Skeleton className="my-2" width="80px" />;

    return (
      <Button
        className="p-button-link p-0 my-1"
        label={moment(rowData.PaymentDate).format('MM/DD/YYYY')}
        onClick={() => {
          dispatch(setPaymentDetails(data));
          history.push(`${routes.editPayment.path}/${rowData.PaymentGuid}`);
        }}
      />
    );
  };

  const feePaidTemplate = (rowData) => {
    if (gettingClaimInfo) return <Skeleton className="my-2" width="80px" />;
    return currencyFormat(rowData.FeePaid);
  };

  const paymentMethodTemplate = (rowData) => {
    if (gettingClaimInfo) return <Skeleton className="my-2" width="80px" />;
    const paymentMethod = paymentMethodOptions.find((x) => x.value === rowData.PaymentMethod || x.label === rowData.PaymentMethod);
    if (paymentMethod) return paymentMethod.label;
    return rowData.PaymentMethod;
  };

  const actionTemplate = (rowData) => {
    if (gettingClaimInfo) return <div style={{ width: '31px', height: '31px' }}></div>;
    return <Button className="p-button-rounded p-button-text" icon={icons.delete} onClick={() => setDeleteDialog({ visible: true, rowData })} />;
  };

  const handleDeletePayment = () => {
    dispatch(
      deletePayment(deleteDialog.rowData.PaymentGuid, () => {
        dispatch(setToastMessage({ type: 'success', message: t('The_record_was_successfully_deleted') }));
        dispatch(
          getClaimInfo(deleteDialog.rowData.InvoiceGuid, (responseData) => {
            setTableData(responseData.Payments);
            onDeletePayment && onDeletePayment(responseData);
          })
        );
        setDeleteDialog({ visible: false, rowData: {} });
      })
    );
  };

  return (
    <>
      <Dialog
        header={t('Payments.1')}
        visible={showPaymentsDialog}
        breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
        style={{ width: '30vw' }}
        footer={paymentsDialogFooter}
        onHide={() => setShowPaymentsDialog(false)}
      >
        <DataTable value={gettingClaimInfo ? itemsNumber : tableData} responsiveLayout="scroll" emptyMessage={t('No_Payments')}>
          <Column field="PaymentDate" header={t('Date')} body={paymentDateTemplate} style={{ width: '25%', height: '55px' }} />
          <Column field="FeePaid" header={t('Amount_applied')} body={feePaidTemplate} style={{ width: '32%', height: '55px' }} />
          <Column field="PaymentMethod" header={t('Payment_Method')} body={paymentMethodTemplate} style={{ width: '33%', height: '55px' }} />
          <Column body={actionTemplate} />
        </DataTable>
      </Dialog>

      <DeleteConfirmation
        visible={deleteDialog.visible}
        header={t('Delete_Claim')}
        accept={handleDeletePayment}
        message={'Are you sure you want to delete this payment?'}
        reject={() => setDeleteDialog({ visible: false, rowData: {} })}
        onHide={() => setDeleteDialog({ visible: false, rowData: {} })}
      />
    </>
  );
};

export default PaymenstDialog;
