import React from 'react';
import { useFormContext } from 'react-hook-form';
import ControlledZip from '../../../../../../../components/Inputs/Zip/ControlledZip';
import { InputWrapper } from '../../../../../../../components/Inputs';
import { inputs } from '../../../helpers/inputs';

const Zip = () => {
  const name = inputs.zip.name;
  const { errors } = useFormContext();

  return (
    <InputWrapper name={name} label="Zip" errorMessage={errors[name]?.message}>
      <ControlledZip id={name} />
    </InputWrapper>
  );
};

export default Zip;
