import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { routes } from '../../../../routes/routes';
import { instructionIds } from '../../../instructions/helpers/instructionIds';
import { localStorageKeys } from '../../../config/localStorageKeysConfig';
import { useSelector } from 'react-redux';

const LabelsAlert = () => {
  const history = useHistory();
  const { isMobile } = useSelector((state) => state.core.window);
  const [isDismissed, setIsDismissed] = useState(() => {
    return localStorage.getItem(localStorageKeys.labelsAlertDismissed) === 'true';
  });

  const handleClose = () => {
    setIsDismissed(true);
    localStorage.setItem(localStorageKeys.labelsAlertDismissed, 'true');
  };

  if (isDismissed && isMobile) {
    return null;
  }

  return (
    <Message
      severity="info"
      className="md:mb-2"
      content={
        <div className="flex justify-content-between gap-2 w-full">
          <div></div>
          <div className="align-items-center gap-2 text-lg m-auto">
            Snap the label quicker than ever, create a patient, and enjoy the app.
            <span className="p-0 text-blue-700 underline ml-2" onClick={() => history.push(`${routes.instructions.path}/${instructionIds.labels}`)}>
              Learn more
            </span>
          </div>

          {isMobile && <Button rounded text icon="pi pi-times" onClick={handleClose} />}
        </div>
      }
    />
  );
};

export default LabelsAlert;
