import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import CommonInputField from '../../../common/components/CommonInputField';
import CountryPicker from '../../../../components/Inputs/CountryPicker';
import AddressType from '../../../../components/Inputs/AddressType';
import ProvincePicker from '../../../../components/Inputs/Province/index';
import Zip from '../../../../components/Inputs/Zip/index';
import Phone from '../../../../components/Inputs/Phone';

import { email_types, phone_types } from '../../../config/constants';
import { toTitleCase } from '../../../utils/toTitleCase';
import { dropdownScrollHeight } from '../../../config/stylesConfig';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

const Address = ({
  formik,
  showContacts = true,
  showAddressType = true,
  showAddressLine = true,
  isFormFieldValid,
  getFormErrorMessage,
  inputFieldClassName
}) => {
  return (
    <>
      <CommonInputField className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'} label={t('Address_Type')}>
        <AddressType
          inputId="AddressTypeInput"
          id="AddressType"
          name="AddressType"
          value={formik.values.AddressType}
          onChange={formik.handleChange}
        />
      </CommonInputField>

      <CommonInputField
        className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
        label={t('Country')}
        errorMessage={getFormErrorMessage('Country')}
      >
        <CountryPicker
          className={cx({ 'p-invalid': isFormFieldValid('Country') })}
          id="Country"
          name="Country"
          scrollHeight={dropdownScrollHeight()}
          value={formik.values.Country}
          onChange={(country) => {
            formik.handleChange({ target: { name: 'Country', value: country.value } });
            formik.setFieldValue('Province', '');
            formik.setFieldValue('Zip', '');
          }}
        />
      </CommonInputField>

      {/* Address */}
      {showAddressLine && (
        <CommonInputField
          className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
          label={t('Street Address')}
          required={formik.values.PHNProvince !== 'BC'}
        >
          <InputText
            id="AddressLine"
            name="AddressLine"
            value={formik.values.AddressLine}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldValue('AddressLine', toTitleCase(e.target.value));
            }}
          />
        </CommonInputField>
      )}
      <CommonInputField
        className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
        label={t('City')}
        required={formik.values.PHNProvince !== 'BC'}
      >
        <InputText
          id="City"
          name="City"
          value={formik.values.City}
          onChange={(e) => {
            formik.handleChange(e);
            formik.setFieldValue('City', toTitleCase(e.target.value));
          }}
        />
      </CommonInputField>

      <CommonInputField
        className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
        label="Province/State"
        required={formik.values.PHNProvince !== 'BC'}
      >
        <ProvincePicker
          className={cx({ 'p-invalid': isFormFieldValid('Province') })}
          id="Province"
          name="Province"
          country={formik.values.Country}
          value={formik.values.Province}
          onChange={formik.handleChange}
        />
      </CommonInputField>

      <CommonInputField
        className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
        label={t('Postal_Code')}
        errorMessage={getFormErrorMessage('Zip')}
      >
        <Zip
          id="Zip"
          name="Zip"
          value={formik.values.Zip}
          country={formik.values.Country}
          onChange={(value) => {
            formik.handleChange({ target: { name: 'Zip', value } });
          }}
        />
      </CommonInputField>

      <CommonInputField className={inputFieldClassName ? inputFieldClassName : 'col-12 py-0 px-2 mb-1'} label={t('FacilityName')}>
        <InputText
          id="Facility"
          name="Facility"
          value={formik.values.Facility}
          onChange={(e) => {
            formik.handleChange(e);
          }}
        />
      </CommonInputField>

      {/* Contacts */}
      {showContacts && (
        <>
          <CommonInputField
            className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
            label={t('Email')}
            errorMessage={getFormErrorMessage('Email')}
          >
            <InputText
              className={cx({
                'p-invalid': isFormFieldValid('Email')
              })}
              id="Email"
              name="Email"
              placeholder="mail@example.com"
              value={formik.values.Email}
              onChange={formik.handleChange}
            />
          </CommonInputField>

          <CommonInputField className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'} label={t('Email Type')}>
            <Dropdown
              inputId="EmailTypeInput"
              id="EmailType"
              name="EmailType"
              options={email_types}
              value={formik.values.EmailType}
              onChange={formik.handleChange}
            />
          </CommonInputField>

          <CommonInputField className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'} label={t('Phone')}>
            <Phone id="Phone" name="Phone" value={formik.values.Phone} onChange={formik.handleChange} />
          </CommonInputField>

          <CommonInputField className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'} label={t('Phone Type')}>
            <Dropdown
              inputId="PhoneTypeInput"
              id="PhoneType"
              name="PhoneType"
              options={phone_types}
              value={formik.values.PhoneType}
              onChange={formik.handleChange}
            />
          </CommonInputField>
        </>
      )}
    </>
  );
};

export default Address;
