import React, { useState, useEffect, useRef, Profiler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import PrimeReact from 'primereact/api';
import AppMenu from './AppMenu';
import AppTopbar from './AppTopbar';

import { BlockUI } from 'primereact/blockui';
import { routes } from './routes/routes';
import { mainMenu } from './modules/config/mainMenu';
import { switchSpeciality } from './modules/auth/actions/auth.actions.creators';
import { onThemeChange } from './modules/utils/onThemeChange';
import {
  updateWidnowSizes,
  setLayoutMode,
  setToastMessage,
  setShowDeletePinDialog,
  setShowUpdateAppDialog
} from './modules/core/actions/core.action.creators';

import cx from 'classnames';
import classes from './styles/App.module.scss';

import { t } from './service/localization/i18n';
import { getTotalOutstanding } from './modules/utils/getTotalOutstanding';
import { setShowTotalOutstandingDialog } from './modules/claims/actions/claims.action.creators';
import { getTotalUnreadNotes } from './modules/notes/actions/actions';
import { localStorageKeys } from './modules/config/localStorageKeysConfig';
import { useAuth } from './AuthProvider';

import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './App.scss';
import './service/localization/i18n';
import { getMetadata } from './modules/utils/getMetadata';

const PrivateRoutesLayout = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const { layoutMode, theme, showUpdateAppDialog } = useSelector((state) => state.core);
  const { isAuthenticated, logout } = useAuth();

  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [mobileMenuActive, setMobileMenuActive] = useState(null);
  const [topbarMenuActive, setTopbarMenuActive] = useState(null);
  const [menuActive, setMenuActive] = useState(false);
  const [darkMenu, setDarkMenu] = useState(false);
  const [blockUI, setBlockUI] = useState(false);
  const [inputStyle, setInputStyle] = useState('outlined');
  const [colorMode, setColorMode] = useState('light');
  const sidebar = useRef(null);
  const isDashboard = location.pathname === routes.dashboard.path;

  PrimeReact.ripple = true;

  let menuClick;
  let topbarMenuButtonClick;
  let menuButtonClick;
  let topbarMenuClick;

  // Check for updates only if the app is running as a PWA, the dialog is not shown and the user is on the dashboard
  useEffect(() => {
    checkForUpdates();
    const interval = setInterval(checkForUpdates, 120000); // Check for updates every 120 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    isAuthenticated && getTotalUnreadNotes();
  }, [isAuthenticated]);

  //=======SET THEME COLOR=======//
  // Update theme if redux value is changed. The initial redux value is taken from the local state. Works with 2nd useEffect below
  useEffect(() => {
    onThemeChange(theme, colorMode);
  }, [theme, colorMode]);
  //=================================================//

  const onWrapperClick = (event) => {
    if (!menuClick && !menuButtonClick && mobileMenuActive) {
      setMobileMenuActive(false);
    }

    if (!topbarMenuClick && !topbarMenuButtonClick) {
      setActiveTopbarItem(null);
      setTopbarMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal() || isOverlay()) {
        setMenuActive(false);
      }
    }

    menuClick = false;
    menuButtonClick = false;
    topbarMenuClick = false;
    topbarMenuButtonClick = false;
  };

  const onTopbarItemClick = async (event) => {
    topbarMenuClick = true;

    if (activeTopbarItem === event.item) {
      setActiveTopbarItem(null);
    } else {
      setActiveTopbarItem(event.item);
      event.originalEvent.preventDefault();

      // if (event.item === 'change_lng') {
      //   const lng = (i18n.language || 'en') === 'en' ? 'ru' : 'en';

      //   i18n.changeLanguage(lng).then(() => {
      //     this.setState({ menu: [...createMenuSerenity(this.state.user, logout)] });
      //   })
      // }

      if (event.item === 'audit') {
        history.push('/audit');
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item === 'billing') {
        history.push('/billing');
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item === 'clinic') {
        history.push('/clinic');
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item === 'insurers') {
        history.push('/insurers');
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item === 'teleplan') {
        history.push('/teleplan');
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item.label === 'Profile') {
        history.push('/profile');
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item.label === 'Settings') {
        history.push('/settings');
      }
      if (event.item === 'inbox') {
        history.push(routes.daysheet.path);
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item.label === routes.userPreferences.title) {
        history.push(`${routes.userPreferences.path}`);
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item.label === routes.clinicPreferences.title) {
        history.push(`${routes.clinicPreferences.path}`);
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item.label === routes.instructions.title) {
        history.push(`${routes.instructions.path}`);
        setTopbarMenuActive((prevState) => !prevState);
      }

      const specs = user.Specialties?.split('|');
      if (specs?.indexOf(event.item.data) > -1) {
        try {
          setBlockUI(true);
          await switchSpeciality(event.item.data);
          setBlockUI(false);
        } catch (error) {
          dispatch(
            setToastMessage({
              type: 'error',
              lifeTime: 1000,
              message: t('Something_went_wrong')
            })
          );
        }
      }
      if (event.item.label === t('Change_password')) {
        history.push(`${routes.changePassword.path}`);
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item.scope === 'create-pin') {
        history.push(`${routes.createPin.path}`);
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item.scope === 'edit-pin') {
        history.push(`${routes.editPin.path}`);
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item.scope === 'remove-pin') {
        dispatch(setShowDeletePinDialog(true));
        setTopbarMenuActive((prevState) => !prevState);
      }
      if (event.item.label === 'Logout') {
        const totalOutstanding = getTotalOutstanding();
        if (totalOutstanding > 0) {
          dispatch(setShowTotalOutstandingDialog({ showDialog: true }));
        } else logout();
      }
    }
  };

  const onMenuButtonClick = (event) => {
    menuButtonClick = true;
    setMobileMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onTopbarMobileMenuButtonClick = (event) => {
    topbarMenuButtonClick = true;
    setTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onSidebarClick = (event) => {
    menuClick = true;
  };

  const onSidebarMouseLeave = (event) => {
    if (layoutMode !== 'horziontal') {
      setMenuActive(false);
    }
  };

  const onRootMenuItemClick = (event) => {
    setMenuActive((prevState) => !prevState);
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items && isHorizontal()) {
      setMenuActive(false);
    }

    if (!event.item.items && isMobile()) {
      setMobileMenuActive(false);
      setMenuActive(false);
      onSidebarMouseLeave();
    }
  };

  const onToggleMenuClick = () => {
    dispatch(setLayoutMode(layoutMode !== 'static' ? 'static' : 'overlay'));
  };

  const isMobile = () => {
    return !!/Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(navigator.userAgent || '');
  };

  const checkForUpdates = async () => {
    // Check for updates only if the app is running as a PWA, the dialog is not shown, and the user is on the dashboard
    if (isPWADetected() && !showUpdateAppDialog && isDashboard) {
      try {
        const metadata = await getMetadata();
        if (!metadata) throw new Error('Metadata fetch returned null');

        const cachedVersion = localStorage.getItem(localStorageKeys.appVersion) || '';
        const version = `${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`;

        if (version !== cachedVersion) {
          localStorage.setItem(localStorageKeys.appVersion, version);
          dispatch(setShowUpdateAppDialog(true));
        }
      } catch (error) {
        console.error('Error checking for updates:', error);
        localStorage.setItem(localStorageKeys.appVersion, '1.0.0');
      }
    }
  };

  const registerWorker = () => {
    if ('serviceWorker' in navigator) {
      const timestamp = new Date().getTime().valueOf();
      navigator.serviceWorker.register(`/service-worker.js?v=${timestamp}`).then((registration) => {});
    }
  };

  const isPWADetected = () => {
    return window.matchMedia('(display-mode: standalone)').matches || navigator.standalone;
  };

  const isMobileDetected = () => {
    const isMobileDevice = !!/Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(navigator.userAgent || '');
    return isMobileDevice || isPWADetected();
  };

  const isTablet = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
  };

  const isMobileOnly = () => {
    return isMobileDetected() && !isTablet();
  };

  const clientWidth = () => {
    return document.documentElement.clientWidth;
  };

  const isOverlay = () => {
    return layoutMode === 'overlay';
  };

  const isHorizontal = () => {
    return layoutMode === 'horizontal';
  };

  let wrapperClass = cx('layout-wrapper h-full', {
    'layout-wrapper-static': layoutMode === 'static',
    'layout-wrapper-active': mobileMenuActive,
    'layout-menu-horizontal': layoutMode === 'horizontal',
    'p-input-filled': inputStyle !== 'filled',
    'p-ripple-disabled': true

    // default theme values
    // 'p-input-filled': inputStyle === 'filled',
    // 'p-ripple-disabled': !ripple
  });

  let sidebarClassName = cx('layout-sidebar', {
    'layout-sidebar-dark': darkMenu
  });

  registerWorker();

  return (
    <BlockUI blocked={blockUI} containerClassName="h-full">
      <div className={wrapperClass}>
        <Profiler id="rrofilerId">
          <div onClick={onWrapperClick}>
            <div tabIndex={-1} ref={sidebar} className={sidebarClassName} onClick={onSidebarClick} onMouseLeave={onSidebarMouseLeave}>
              <div
                className={cx(
                  'sidebar-logo flex align-items-center pt-0',
                  isMobileOnly() ? classes.logoRootMobile : classes.logoRoot,
                  layoutMode === 'overlay' && !isMobileOnly() ? 'sidebar-logo justify-content-end pr-2' : 'justify-content-start'
                )}
              >
                <div>
                  <img
                    className={cx('mobile-logo-alt', isMobileOnly() ? classes.logoMobile : classes.logo)}
                    alt="logo"
                    src={'assets/layout/images/menu-logo.png'}
                  />
                </div>

                <div
                  className={
                    layoutMode === 'overlay' && (!isMobile() || (isTablet() && clientWidth() > 1025)) ? classes.logoWrap : classes.logoWrapHidden
                  }
                >
                  <img
                    className={cx('mobile-logo-alt ml-4', isMobileOnly() ? classes.logoMobile : classes.logo)}
                    alt="logo"
                    src={'assets/layout/images/logo.png'}
                  />
                </div>
              </div>

              <div className="layout-menu-container">
                <AppMenu
                  model={mainMenu}
                  layoutMode={layoutMode}
                  active={menuActive}
                  onMenuItemClick={onMenuItemClick}
                  onRootMenuItemClick={onRootMenuItemClick}
                />
              </div>
            </div>

            <div className="layout-main">
              <AppTopbar
                layoutMode={layoutMode}
                topbarMenuActive={topbarMenuActive}
                activeTopbarItem={activeTopbarItem}
                onTopbarItemClick={onTopbarItemClick}
                onMenuButtonClick={onMenuButtonClick}
                onToggleMenuClick={onToggleMenuClick}
                onTopbarMobileMenuButtonClick={onTopbarMobileMenuButtonClick}
              />

              <div className={cx('layout-content pl-0 pr-0 pb-0', isMobileOnly() ? classes.mainContentMobile : classes.mainContent)}>{children}</div>
              {mobileMenuActive && <div className="layout-main-mask"></div>}
            </div>
          </div>
        </Profiler>
      </div>
    </BlockUI>
  );
};

export default PrivateRoutesLayout;
