import React, { useState, useEffect, useRef, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { TabPanel, TabView } from 'primereact/tabview';

import Profile from './Profile/Profile';
import PatientNotes from './Notes/PatientNotes';
import ClaimView from '../../../claims/components/ClaimView';
import CustomAvatar from '../../../common/components/CustomAvatar';
import DeleteConfirmation from '../../../common/components/DeleteConfirmation';
import CommonConfirmDialog from '../../../common/components/CommonConfirmDialog';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';
import PatientPolicies from '../../../../components/Widgets/PatientPolicies/PatientPolicies';

import { formatPHN } from '../../../utils/formatPHN';
import { formatPID } from '../../../utils/formatPID.js';
import { patientDetailsTabs } from './helpers/patientDetailsTabs';
import { routes } from '../../../../routes/routes';
import {
  getPatientInfo,
  setActiveTab,
  setPatientInfo,
  setEligibility,
  setPatientFilters,
  deletePatient,
  getPatientNotes,
  getRepresentative,
  setActiveTabIndexForPatientForm
} from '../../actions/patients.action.creators';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { elementIDs } from '../../../config/elementIDsConfig';
import { onTabChange } from './helpers/onTabChange';
import { patientNotesInitState } from './helpers/patientNotesInitState';
import { setClaimInfo, setDefaultClaimsTableValues } from '../../../claims/actions/claims.action.creators';
import { isEmpty, isEqual } from 'lodash';
import { showPolicy } from '../../../config/specialitiesConfig';
import { patientFullName } from '../../../utils/patientFullName';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';
import moment from 'moment';
import { defaultGuid } from '../../../config/defaultValuesConfig.js';
import LabelDialog from './Profile/LabelDialog.js';
import { editPatientProfile } from '../Patients/helpers/rowActionsMenuItems.js';

const PatientDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const claimsData = useSelector((state) => ({
    claimsPage: state.claims.page,
    isFetchingClaims: state.claims.isFetchingClaims,
    totalClaimsRecords: state.claims.totalRecords || 0,
    totalClaimsRecordsAlter: state.claims.totalRecordsAlter || 0
  }));
  const { filters, activeTab, eligibility, patient_details, isDeletingPatient, isFetchingPatients } = useSelector((state) => state.patients);
  const { isMobile, isMobileOnly } = useSelector((state) => state.core.window);
  const [deletePatientConfirm, setDeletePatientConfirm] = useState(false);
  const [gettingRepresentative, setGettingRepresentative] = useState(false);
  const [deletePatientConfirmWithRecords, setDeletePatientConfirmWithRecords] = useState(false);
  const [cannotDeleteRecordDialog, setCannotDeleteRecordDialog] = useState(false);
  const [patientDataForAction, setPatientDataForAction] = useState({});
  const [representative, setRepresentative] = useState(null);
  const [notes, setNotes] = useState(patientNotesInitState);
  const menuRef = useRef(null);
  const showSkeleton = isFetchingPatients || isEmpty(patient_details);
  const [isLabelDialogVisible, setIsLabelDialogVisible] = useState(false); // State to control the dialog visibility
  const handleOpenLabelDialog = () => setIsLabelDialogVisible(true);
  const handleClosLabeleDialog = () => setIsLabelDialogVisible(false);

  const fetchNotes = async ({ page, pageSize, resetNotesList } = {}) => {
    const pageSizeValue = pageSize > 0 ? pageSize : notes.pageSize;
    setNotes((prevState) => ({ ...prevState, isLoadingNotes: true }));
    const result = await getPatientNotes({ pageSize: pageSizeValue, page: page || notes.page, patientGuid: id });
    setNotes((prevState) => ({ ...prevState, isLoadingNotes: false }));
    if (result) {
      const newNotesList = result.notesList && Array.isArray(result.notesList) ? result.notesList : [];
      setNotes((prevState) => ({
        ...result,
        pageSize: notes.pageSize,
        notesList: resetNotesList ? newNotesList : [...prevState.notesList, ...newNotesList]
      }));
    }
  };

  const fetchRepresentativeInfo = async (representativeGuid) => {
    setGettingRepresentative(true);
    const result = await getRepresentative(representativeGuid);
    setGettingRepresentative(false);
    setRepresentative(result);
  };

  const onScroll = ({ page } = {}) => fetchNotes({ page });

  const searchForNotes = async ({ freetext } = {}) => {
    setNotes((prevState) => ({ ...prevState, isLoadingNotes: true }));
    const result = await getPatientNotes({ pageSize: notes.pageSize, page: 1, patientGuid: id, freetext });
    setNotes((prevState) => ({ ...prevState, isLoadingNotes: false }));
    if (result) {
      const newNotesList = result.notesList && Array.isArray(result.notesList) ? result.notesList : [];
      setNotes({
        ...result,
        pageSize: notes.pageSize,
        notesList: newNotesList
      });
    }
  };

  useEffect(() => {
    //hack
    //CMO-463 - Tablet -> Create patient -> Open patient's profile -> screen is scrolled down and patient's name is not visible
    // window[`scrollTo`]({ top: 0 });

    dispatch(getPatientInfo({ id }));
    fetchNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      patient_details.RepresentativeGuid &&
      patient_details.RepresentativeGuid !== defaultGuid &&
      patient_details.RepresentativeGuid !== representative?.PatientGuid
    )
      fetchRepresentativeInfo(patient_details.RepresentativeGuid);
  }, [patient_details.RepresentativeGuid]);

  //scroll up page when changing tabs
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [activeTab]);

  const closeDetails = () => {
    history.push(routes.patients.path);
    // history.goBack(); // [KS] - Cannot be used because of CMO-1444 - Open patient's profile after creating a claim->Delete last created claim->Close patient profile->skeleton and hanged program
    dispatch(setActiveTab(patientDetailsTabs.profile));

    //[KS] set default values for data table
    //CMO-1049 - Patient - data table show only 3 records
    dispatch(setDefaultClaimsTableValues());

    //[KS] reset highlighted invoice
    //CMO-617 - Patient details - page autoscrolled down
    dispatch(setClaimInfo({}));

    //be sure to reset patient information for correct rendering next time
    dispatch(setPatientInfo({}));
    dispatch(setEligibility({}));

    // [KS] CMO-1429 - Clear Find patient keyword after the user closes patient's profile
    filters?.freetext && dispatch(setPatientFilters({ ...filters, freetext: '' }));
  };

  const showOverlay = (e) => {
    menuRef.current.toggle(e);
  };

  const editPatientsName = () => {
    !isEmpty(eligibility) && dispatch(setEligibility({}));
    isMobile && dispatch(setActiveTabIndexForPatientForm(0));
    editPatientProfile(patient_details, history);
  };

  const onDeletePatient = (patientData) => {
    setPatientDataForAction(patientData);
    setDeletePatientConfirm(true);
  };

  const onAcceptDeletePatient = () => {
    dispatch(
      deletePatient(
        patientDataForAction.PatientGuid,
        false,
        (results) => {
          if (results?.data?.ResponseCode !== 204) {
            if (results?.data?.ResponseCode === 400) setCannotDeleteRecordDialog(true);
            if (results?.data?.ResponseCode === 423) setDeletePatientConfirmWithRecords(true);
          } else {
            closeDetails();
            dispatch(setToastMessage({ type: 'success', message: t('Patient_deleted_successfully') }));
          }
        },
        false
      )
    );
  };

  const onAcceptDeletePatientWithRecords = () => {
    dispatch(deletePatient(patientDataForAction.PatientGuid, true, () => closeDetails(), false));
  };

  const threeDotsMenu = [
    {
      label: t('Edit profile'),
      command: () => editPatientsName(),
      template: (item, options) => {
        return (
          <div id={elementIDs.editProfile} className={options.className} target={item.target} onClick={options.onClick}>
            <span className="medium-size-text">{item.label}</span>
          </div>
        );
      }
    },
    ...(patient_details?.LabelImages?.length > 0
      ? [
          {
            label: t('View Label'),
            command: () => handleOpenLabelDialog(),
            template: (item, options) => {
              return (
                <div id="viewLabel" className={options.className} target={item.target} onClick={options.onClick}>
                  <span className="medium-size-text">{item.label}</span>
                </div>
              );
            }
          }
        ]
      : []),

    {
      label: t('Delete'),
      command: () => onDeletePatient(patient_details),
      template: (item, options) => {
        return (
          <div id={elementIDs.deletePatient} className={options.className} target={item.target} onClick={options.onClick}>
            <span className="medium-size-text">{item.label}</span>
          </div>
        );
      }
    }
  ];

  const avatarLetter = `${patient_details.LastName?.charAt(0).toUpperCase()}${patient_details.FirstName?.charAt(0).toUpperCase()}`;

  const header = () => {
    return (
      <div className="flex gap-3 p-2 mb-3">
        <div className="flex p-0 align-items-start">
          <div className="flex align-items-center mr-3">
            {showSkeleton ? <Skeleton size="3rem" /> : <CustomAvatar id={patient_details.PatientGuid} label={avatarLetter} />}
          </div>

          <div className="flex flex-column justify-content-center">
            <div
              id={elementIDs.patientName}
              className={cx('flex align-items-center mb-1 font-bold', isMobileOnly ? 'medium-size-text' : 'large-text')}
              style={{ lineHeight: '18px' }}
            >
              {showSkeleton ? (
                <Skeleton width="10em" />
              ) : (
                <div className="flex align-items-center gap-3">
                  {patientFullName(patient_details)}
                  {/* {patient_details.FullName?.trim()}
                  {patient_details?.LfpPanel && <LFPBadge />} */}
                  {/* {patient_details?.IsDeceased && <DeceasedBadge />} */}
                </div>
              )}
            </div>
            <div id={elementIDs.patientDOB} className="mb-1">
              {showSkeleton ? <Skeleton className="mt-1" width="10em" /> : fullNameWithPHN()}
            </div>
          </div>
        </div>

        <div className="flex align-items-start justify-content-end p-0">
          <>
            <Button
              id={elementIDs.threeDotsButton}
              className="p-button-text p-button-rounded"
              icon="pi pi-ellipsis-v"
              aria-controls="popup_menu"
              onClick={showOverlay}
            />
            <Menu
              model={threeDotsMenu}
              popup
              ref={menuRef}
              id="popup_menu"
              viewportHeight={190}
              menuWidth={175}
              appendTo={document.body}
              baseZIndex={1000000}
            />

            <Button
              id={elementIDs.threeDotsButton}
              className="p-button-text p-button-rounded ml-2"
              icon="pi pi-times"
              onClick={() => history.goBack()} // WIN-283 - Memos->open memo->open patient proflie->close->come back to memos
              // onClick={closeDetails}
            />
          </>
        </div>
        {/* {patient_details?.LabelImages?.length > 0 && (
        <div className="ml-auto flex align-items">
          <Button
            id="imageButton"
            className="p-button-text p-button-rounded"
            icon="pi pi-image"
            onClick={handleOpenLabelDialog}
            tooltip="View Label"
            tooltipOptions={{ position: 'top' }}
          />
        </div>
      )} */}
      </div>
    );
  };

  const fullNameRegular = `${patient_details.DOBText}${
    patient_details.DateDeceased ? ` - ${moment(patient_details.DateDeceased).format('MMM D, YYYY')}` : ''
  } (${patient_details.Age} ${patient_details.Sex})`;

  const newbornName = `${moment(patient_details.NewbornBD).format('MMM D, YYYY')} (0 ${patient_details.Sex})`;

  const fullNameWithPHN = () => {
    const fullName = patient_details.IsNewborn ? newbornName : fullNameRegular;

    if (patient_details.PHN?.length > 0 && patient_details.PHN !== 'N/A') {
      return (
        <div className="flex flex-column">
          {fullName}
          <span id={elementIDs.patientPHN}>{`${patient_details.PHNProvince} ${formatPHN(patient_details.PHN, patient_details.PHNProvince)}`}</span>
          <span> PID: {formatPID(patient_details.PatientID)}</span>
        </div>
      );
    }

    return (
      <div className="flex flex-column">
        {fullName}
        {patient_details ? <span> PID: {formatPID(patient_details.PatientID)}</span> : <span> PID: Not available</span>}
      </div>
    );
  };

  const handleTabChange = (index) => {
    if (notes?.notesList?.length) setNotes(patientNotesInitState);
    if (index === 3) fetchNotes();
    onTabChange(index);
  };

  return (
    <div className="flex col-12 p-0 justify-content-center">
      <div id={elementIDs.patientProfile} className="containerXL subMenuRoot mt-4 px-3 w-full">
        {(isDeletingPatient || notes.isLoadingNotes) && <CircularProgress />}

        {/* HEADER */}
        {header()}

        {/* CONTENT */}
        <TabView activeIndex={activeTab} onTabChange={(e) => handleTabChange(e.index)}>
          <TabPanel header={t('Profile')} headerClassName="extra-large-text">
            <Profile
              isFetchingClaims={claimsData.isFetchingClaims}
              claimsPage={claimsData.claimsPage}
              patient_details={patient_details}
              representative={representative}
              setRepresentative={setRepresentative}
              showSkeleton={showSkeleton}
              isMobileOnly={isMobileOnly}
              gettingRepresentative={gettingRepresentative}
            />
          </TabPanel>

          <TabPanel header={t('Money')} headerClassName="extra-large-text">
            <div className="mt-3">
              <ClaimView id="claims" showInCard={false} showOnPatientDetailsScreen={true} patientDetails={{ ...patient_details, PatientGuid: id }} />
            </div>
          </TabPanel>

          {showPolicy() && (
            <TabPanel header={t('Policies')} headerClassName="extra-large-text">
              <div className="mt-3">
                <PatientPolicies patient={patient_details} />
              </div>
            </TabPanel>
          )}

          <TabPanel header={t('Notes')} headerClassName="extra-large-text">
            <div className="pt-3">
              <PatientNotes
                entityGuid={id}
                notes={notes}
                setNotes={setNotes}
                fetchNotes={fetchNotes}
                searchForNotes={searchForNotes}
                onScroll={onScroll}
              />
            </div>
          </TabPanel>
        </TabView>

        <DeleteConfirmation
          visible={deletePatientConfirm}
          header={t('Delete_record')}
          message={String.format(t('Are_you_sure_you_want_to_delete_patient'), patientDataForAction.Summary)}
          accept={onAcceptDeletePatient}
          onHide={() => setDeletePatientConfirm(false)}
          reject={() => setDeletePatientConfirm(false)}
        />

        <DeleteConfirmation
          visible={deletePatientConfirmWithRecords}
          header={t('Delete_record')}
          message={t('This_profile_has_some_outstanding_claims_They_will_also_be_deleted')}
          accept={onAcceptDeletePatientWithRecords}
          onHide={() => setDeletePatientConfirmWithRecords(false)}
          reject={() => setDeletePatientConfirmWithRecords(false)}
        />

        <CommonConfirmDialog
          visible={cannotDeleteRecordDialog}
          multipleButtons={false}
          header={t('Delete_record')}
          bodyText={t('This_record_cannot_be_deleted_because_it_has_a_billing_history')}
          accept={() => setCannotDeleteRecordDialog(false)}
          reject={() => setCannotDeleteRecordDialog(false)}
        />

        <LabelDialog patient_details={patient_details} isDialogVisible={isLabelDialogVisible} onDialogHide={handleClosLabeleDialog} />
      </div>
    </div>
  );
};

export default memo(PatientDetails, isEqual);
