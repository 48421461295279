import React from 'react';
import ErrorLayout from './ErrorLayout';
import DescriptionLayout from './DescriptionLayout';
import FooterContentLayout from './FooterContentLayout';
import InputWithLabelLayout from './InputWithLabelLayout';
import InputCharacterCounter from '../../Misc/InputCharacterCounter/InputCharacterCounter';
import cx from 'classnames';

// interface IInputWrapper {
//   name: string,
//   label: string,
//   children: React.ReactElement,

//   isLocked?: boolean,
//   required?: boolean,
//   hideLabel?: boolean,
//   className?: string,
//   showCounter?: boolean, // Define "showCounter" to display input character counter
//   inputValue?: string, // Define "inputValue" to display input character counter
//   countLimit?: nnumber, // Define "countLimit" to display input character counter
//   dir?: 'row' | 'column',
//   errorMessage?: string | null,
//   description?: string | JSX.Element,
//   lableContent?: () => JSX.Element | JSX.Element
//   labelOptionalContent?: () => JSX.Element | JSX.Element
//   footerContent?: () => JSX.Element | JSX.Element
//   onSavePrefs?: () => void
//   labelStyle?: React.CSSProperties
//   style?: React.CSSProperties
// }

const InputWrapper = (props) => {
  return (
    <div
      id={`${props.name}-input-wrap`}
      className={cx('flex flex-column', props.className || '')}
      style={props.style || { height: '90px', width: '100%' }}
    >
      <InputWithLabelLayout {...props} />

      <div className={cx('flex justify-content-between mt-1', props.errorMessage && props.description ? 'flex-column' : '')}>
        <ErrorLayout {...props} />
        <DescriptionLayout {...props} />
        <FooterContentLayout {...props} />
        <InputCharacterCounter text={props.inputValue} countLimit={props.countLimit} showCounter={props.showCounter} />
      </div>
    </div>
  );
};

export default InputWrapper;
