import { t } from '../../service/localization/i18n';
import { allCountries } from 'country-region-data';

export const genderList = [
  { label: t('Male.2'), value: 'M' },
  { label: t('Female.2'), value: 'F' }
];

export const addressTypes = [
  { label: t('Mailing'), value: 'M' },
  { label: t('Residence'), value: 'R' },
  { label: t('Facility'), value: 'F' }
];

export const countries = (allCountries || [])
  .map(([countryName, countrySlug, regions]) => ({
    label: countryName,
    value: countrySlug,
    regions: (regions || []).map(([regionName, regionSlug]) => ({
      label: regionName,
      value: countrySlug === 'CA' && regionSlug === 'NL' ? 'NF' : regionSlug // ✅ Change NF → NF for Canada
    }))
  }))
  .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically

export const canadaProvincesList = countries.find((country) => country.value === 'CA')?.regions || [];
export const canadaProvincesListWithInstituonal = [...canadaProvincesList, { label: t('Institutional'), value: 'Institutional' }];
