import withAuthCondition from './withAuthCondition';
import { sessionStorageKeys } from '../config/localStorageKeysConfig';
import { getFromSessionStorage } from '../utils/getFromSessionStorage';

const withBureauUser = (Component) => {
  return withAuthCondition(Component, () => {
    const bureauUser = getFromSessionStorage(sessionStorageKeys.bureauUser);
    return Boolean(bureauUser && bureauUser.DCNGuid && bureauUser.DoctorGuid && bureauUser.ImpersonateKey);
  });
};
export default withBureauUser;
