import { additional15PercentCodes, syncTimeWithLevelCodesList } from './codesCheckList';

export const isLevelCode = (feeCode = '') => {
  return syncTimeWithLevelCodesList.levelCodes.includes(feeCode);
};

export const isAdditionalLevelCode = (feeCode = '') => {
  return additional15PercentCodes.levelCodes.includes(feeCode);
};

export const isAdditionalPercentCode = (feeCode = '') => {
  return additional15PercentCodes.additionalCodes.includes(feeCode);
};
