import actions from './auth.action.types';
import axios from 'axios';
import qs from 'qs';
import { getApiBase, getRedirectUrl, setAuthHeader } from '../../../config';
import { closeToastMessage, setAppMode, setToastMessage } from '../../core/actions/core.action.creators';
import { getPrefs, setPrefs } from '../../preferences/actions/preferences.action.creators';
import { setCatalogCategories, getCatalogCategories } from '../../claims/actions/claims.action.creators';
import { localStorageKeys } from '../../config/localStorageKeysConfig';
import { getFromLocalStorage } from '../../utils/getFromLocalStorage';
import {
  getClinicInfo,
  setClinicInfo,
  getMembers,
  setMembers,
  setLocations,
  getLocations,
  getFilters,
  setFilters,
  setRooms
} from '../../clinic/actions/clinic.action.creators';
import { store } from '../../../store';
import { t } from '../../../service/localization/i18n';
import { getPromiseAll } from '../../utils/getPromiseAll';
import { sendLogout } from '../../../service/ClinicService';
import { userInitialState } from '../reducers/auth.reducer';
import { getTermsOfUse } from '../../contentful/apiRequests';

const restrictedDCNGuid = '5597c5ad-f9aa-48ba-af19-dde81ab6c99a'; //need to change

const detectMode = () => {
  const hostname = document.location.hostname;
  if (process.env.REACT_APP_USERDOMAIN === 'demo') return 'demo';
  if (process.env.REACT_APP_USERDOMAIN === 'VB') return 'local';
  if (hostname.indexOf('claimmanager.ca') > -1) return 'live';
  if (hostname.indexOf('heroku') > -1) return 'demo';
  if (hostname.indexOf('nopaperwork') > -1) return 'beta';
  return 'demo';
};

const formatList = (results) => {
  return {
    type: actions.FETCH_USERS_SUCCESS,
    results
  };
};

const formatDetails = (results) => {
  return {
    type: actions.UPDATE_USER_SUCCESS,
    results: results
  };
};

export const register = (user) => (dispatch) => {
  axios
    .post(`${getApiBase()}/users`, qs.stringify(user))
    .then(() => {
      return dispatch({
        type: actions.REGISTER_SUCCESS,
        results: { status: 'Successfully created account. Please login.' }
      });
    })
    .catch((reason) => {
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const signIn = (data) => {
  const { username, password, step } = data;
  //const currentState = store.getState();
  //setAuthHeader(currentState);

  // Temporary solution for VER-860 to clear batch form state. Should be removed in the future
  localStorage.removeItem(localStorageKeys.batchFormState);
  if (step === 1) {
    return signInStepOne(data);
  } else if (step === 2) {
    return signInStepTwo(data);
  } else {
    return axios
      .post(`${getApiBase()}/users/login?step=${step}`, { username, password })
      .then((results) => {
        return results.data;
      })
      .catch((error) => {
        store.dispatch(
          setToastMessage({
            type: 'error',
            lifeTime: 10000,
            title: 'Please try again',
            message: "You've entered an invalid username / password combination."
          })
        );
      });
  }
};

const signInStepOne = (data) => {
  const { username, password } = data;
  return axios
    .post(`${getApiBase()}/users/login/first`, { username, password })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          title: 'Please try again',
          message: "You've entered an invalid username / password combination."
        })
      );
    });
};

const signInStepTwo = (data) => {
  const { username, password } = data;
  return axios
    .post(`${getApiBase()}/users/login/next`, { username, password })
    .then((results) => {
      if (results.data.DCNGuid === restrictedDCNGuid) {
        results.data.restricted = true;
      }
      return results.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        throw new Error('InvalidCredentials'); // Explicitly throw an error for 404
      }
      throw error; // Rethrow other errors
    });
};

export const signInWithPinCode = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);

  return axios
    .post(`${getApiBase()}/users/login/${payload.DeviceGuid}`, payload)
    .then((results) => {
      if (results.data.DCNGuid === restrictedDCNGuid) {
        results.data.restricted = true;
      }
      return results.data;
    })
    .catch((error) => {
      console.log('!!!!signInWithPinCode', error);
    });
};

export const devSignIn = async (data) => {
  try {
    const { username, password } = data;

    if (username.indexOf('demo') === 0 && password === '') {
      const DCNGuid = '1bec2050-d5a2-4a6a-a476-8f7a6424ace0';
      const UserGuid = '54b5bc29-24c9-482f-88c8-4f4b101bb803';
      const hackSpec = username.replace('demo', '');
      const Speciality = !hackSpec.length ? 0 : hackSpec;

      await getUserProfileData({ DCNGuid, UserGuid, Speciality });

      return store.dispatch({
        type: actions.LOGIN_SUCCESS,
        results: {
          DCNGuid,
          UserGuid,
          // [VB] change JwtToken to any random string to test 401 error from Back End
          // token below is for DCNGuid = '1bec2050-d5a2-4a6a-a476-8f7a6424ace0';
          // Back End does not check for expiry time yet
          JwtToken:
            'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL3NlY3VyZS5jbGFpbW1hbmFnZXIuY2EiLCJzdWIiOiIxNTQyODc1Zi02YjMwLTRhZmYtYmQ1OS00YzkwMTZkMzM4MzAiLCJkY24iOiIxYmVjMjA1MC1kNWEyLTRhNmEtYTQ3Ni04ZjdhNjQyNGFjZTAiLCJzY29wZSI6bnVsbCwiYXVkIjoiaHR0cHM6Ly9zZWN1cmUuY2xhaW1tYW5hZ2VyLmNhIiwiaWF0IjoxNjI1Mzk4MjU3LjIwMzc4OTIsImV4cCI6MTYyNTQ4NDY1Ni4zOTI2ODU3LCJqdGkiOiI1YzAxNDI1YS1lYmNiLTRjZWMtOWQxNi0xYjQ3MDdmZmYyN2EifQ.T5fky1io1PKBycpfYEOgDEUGgdcpqfm7YNn9N580TKE',
          decoded: { scope: ['d', 'p', 'c', 's', 'r', 'u'] },
          FirstName: 'Kelsey',
          LastName: 'Simpson',
          LoginName: 'demo',
          Role: 'doc',
          Speciality,
          Specialties: '44|18|0|80',
          canBillICBC: true,
          canBillMSP: true,
          canBillTEC: false,
          canBillWCB: true
        }
      });
    }
  } catch (error) {
    console.log('!!!!devSignIn', error);
  }
};

export const getUserProfileData = async (data) => {
  try {
    // const decoded = jwtDecode( results.data.JwtToken);
    data.decoded = { scope: ['d', 'p', 'c', 's', 'r', 'u'] };
    // hack until backend provide correct role
    data.Role = data.Role ? data.Role : data.PractitionerNumber === '0' ? 'rcp' : 'doc';
    const dcnguid = data.DCNGuid;
    const userGuid = data.UserGuid;
    const speciality = data.Speciality;
    const key = localStorageKeys.invoicesCatalogCategories;

    let clinicInfo = await getClinicInfo(dcnguid);
    const requests = {
      //clinicInfo: getClinicInfo(dcnguid),
      members: getMembers(dcnguid),
      locations: getLocations(dcnguid),
      filters: getFilters(dcnguid),
      prefs: getPrefs(dcnguid, userGuid),
      catalogCategories: getCatalogCategories(speciality)
    };

    // Execute all requests concurrently and merge results into a single object
    const results = await getPromiseAll(requests);

    // Update redux
    if (clinicInfo) {
      setClinicInfo(clinicInfo);
    } else {
      // retry to get clinic info
      clinicInfo = await getClinicInfo(dcnguid);
      setClinicInfo(clinicInfo);
    }
    setMembers(results.members);
    setLocations(results.locations?.locations);
    setRooms(results.locations?.rooms);
    setFilters(results.filters);
    setPrefs(results.prefs);
    setCatalogCategories(results.catalogCategories);
    getFromLocalStorage(localStorageKeys.serviseDate) !== null && localStorage.setItem(localStorageKeys.serviseDate, JSON.stringify(null)); // [KS] CMO-1423 - Persist service date during session

    // [KS] Set catalog categories to local storage
    localStorage.setItem(key, JSON.stringify({ ...results.catalogCategories, speciality }));
    return true;
  } catch (error) {
    console.log('!!!!getUserProfileData', error);
    store.dispatch(
      setToastMessage({
        type: 'error',
        lifeTime: 10000,
        message: t('Something_went_wrong')
      })
    );
    return false;
  }
};

export const updateUser = (data) => (dispatch) => {
  dispatch({ type: actions.LOGIN_SUCCESS, results: data });
};

export const switchSpeciality = async (speciality) => {
  try {
    const currentState = store.getState();
    setAuthHeader(currentState);
    const user = currentState?.user?.details;
    const DCNGuid = user?.DCNGuid || '';
    const UserGuid = user?.UserGuid || '';
    const url = `${getApiBase()}/users/${DCNGuid}/${UserGuid}/spec`;
    const specData = { value: speciality };
    const result = await axios.put(url, specData);
    await getUserProfileData({ ...user, ...result.data });
  } catch (error) {
    console.log('!!!switchSpeciality', error);
  }
};

// export const backdoor = (params) => (dispatch) => {
//   axios.post(`${getApiBase('live')}/users/impersonate`, qs.stringify(params))
//     .then((results) => {
//       // const decoded = jwtDecode( results.data.JwtToken);
//       results.data.decoded = { scope: ['d', 'p', 'c', 's', 'r', 'u'] };
//       const appmode = 'live';
//       const ro = params['ro'] ? true : false;

//       dispatch(setAppMode(appmode, !ro, 'code'));
//       getUserProfileData(results.data);
//     })
//     .catch(reason => {
//       dispatch(setToastMessage({ type: "error", message: reason?.response?.data?.ExceptionMessage }));
//     })
// };

export const backdoor = (params) => {
  return axios
    .post(`${getApiBase('live')}/users/impersonate`, qs.stringify(params))
    .then((results) => {
      if(results.data.DCNGuid === restrictedDCNGuid){
        results.data.restricted = true;
      }
      return results.data;
    })
    .catch((reason) => {
      console.log('!!!!backdoor', reason);
      throw reason.status;
    });
};

export const redirect = async (params) => {
  const appmode = detectMode();

  return axios
    .post(`${getApiBase(appmode)}/users/sso`, qs.stringify(params))
    .then((results) => {
      if(results.data.DCNGuid === restrictedDCNGuid){
        results.data.restricted = true;
      }
      // store.dispatch({ type: actions.REDIRECT_REQUEST, results: false });

      // const decoded = jwtDecode( results.data.JwtToken);
      results.data.decoded = { scope: ['d', 'p', 'c', 's', 'r', 'u'] };
      const ro = params['ro'] ? true : false;
      // store.dispatch(setAppMode(appmode, !ro, 'code'));
      return results.data;
    })
    .catch((reason) => {
      window.location.href = window.location.protocol + '//' + window.location.host;
    });
};

export const bureauVerify = (code) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const user = currentState?.user?.details;
  const toastMessage = currentState.core.toastMessage.message;
  const appmode = detectMode();

  store.dispatch({ type: actions.USER_VERIFY, results: true });

  const payload = {
    DCNGuid: user.DCNGuid,
    DoctorGuid: user.DoctorGuid,
    Code: code
  };

  return axios
    .post(`${getApiBase(appmode)}/users/${user.DCNGuid}/${user.DoctorGuid}/mfa`, payload)
    .then(() => {
      store.dispatch({ type: actions.USER_VERIFY, results: false });
      toastMessage && store.dispatch(closeToastMessage(true));
      return true;
    })
    .catch((error) => {
      store.dispatch({ type: actions.USER_VERIFY, results: false });
      const status = error?.response?.status;

      if (status === 404) {
        store.dispatch(
          setToastMessage({
            type: 'error',
            lifeTime: 10000,
            title: 'Wrong code',
            message: t('Please_enter_the_code_you_received_in_the_email')
          })
        );
      } else {
        store.dispatch(
          setToastMessage({
            type: 'error',
            lifeTime: 5000,
            message: t('Something_went_wrong')
          })
        );
      }

      return undefined;
    });
};

export const loginAsAffiliate = (params) => {
  const appmode = detectMode();

  return axios
    .post(`${getApiBase(appmode)}/users/sso`, qs.stringify(params))
    .then((results) => {
      return results.data;
    })
    .catch((reason) => {
      console.log('!!!!loginAsAffiliate', reason);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const onSwitchToOldDesign = () => {
  const mode = document.location.hostname.indexOf('claimmanager') > -1 ? 'live' : 'demo';
  const redirect = getRedirectUrl(mode);
  sendLogout();
  setTimeout(() => {
    document.location.href = redirect;
  }, 500);
};

export const logout = () => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid || '';
  const url = `${getApiBase()}/users/${DCNGuid}/logout/`;

  return axios
    .post(url, currentState.user.details)
    .then(() => {
      return true;
    })
    .catch((reason) => {
      return null; //dispatch({ error: reason });
    });
};

export const getAffiliates = (user, appmode) => (dispatch, state) => {
  setAuthHeader(state);
  const url = `${getApiBase(appmode)}/users/${user.DCNGuid}/${user.DoctorGuid}/affiliates`;

  dispatch({ type: actions.GETTING_AFFILIATES, results: true });

  axios
    .get(url)
    .then((results) => {
      dispatch({ type: actions.GETTING_AFFILIATES, results: false });
      return dispatch({ type: actions.GET_AFFILIATES, results });
    })
    .catch((reason) => {
      dispatch({ type: actions.GETTING_AFFILIATES, results: false });
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

// clinic administration
export const getUsers = (dcnguid, filter, sort, page, pagesize) => (dispatch, state) => {
  setAuthHeader(state);
  const url = `${getApiBase()}/users/${dcnguid}` + '/' + (page ? page : '1') + '/10/' + (filter ? filter : '');
  axios
    .get(url)
    .then((results) => {
      return dispatch(formatList(results));
    })
    .catch((reason) => {
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const getUserRole = (user) => (dispatch, state) => {
  setAuthHeader(state);
  const url = `${getApiBase()}/users/${user.dcnguid}/${user.id}`;
  axios
    .get(url)
    .then((results) => {
      return dispatch(formatList(results));
    })
    .catch((reason) => {
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const editUserRole = (user) => (dispatch, state) => {
  setAuthHeader(state);
  const url = `${getApiBase()}/users/${user.dcnguid}/${user.id}`;
  const body = Object.assign({}, user);
  delete body.PayeeNumbers;
  axios
    .put(url, JSON.stringify(body))
    .then((results) => {
      return dispatch(formatList(results));
    })
    .catch((reason) => {
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const editUser = (dcnguid, id, user) => (dispatch, state) => {
  setAuthHeader(state);
  const body = Object.assign({}, user);
  delete body.PayeeNumbers;
  axios
    .put(`${getApiBase()}/users/${user.dcnguid}/${user.id}`, qs.stringify(body))
    .then((results) => {
      return dispatch(formatDetails(results));
    })
    .catch((reason) => {
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

// browser actions
export const updateUserField = (kvp) => (dispatch, state) => {
  dispatch({
    type: actions.UPDATE_USER_FIELD,
    results: kvp
  });
};

export const setRegisterType = (view) => (dispatch) => {
  dispatch({ type: actions.REGISTER_TYPE, results: view });
};

export const setForgotPassword = (status, name) => (dispatch) => {
  dispatch({ type: actions.FORGOT_PASSWORD, results: { passwordStatus: status, userName: name } });
};

export const getTermsOfUseAction = () => async (dispatch) => {
  try {
    const results = await getTermsOfUse();
    return dispatch({ type: actions.TERMS_OF_USE, results });
  } catch (reason) {
    console.log('!!!!getTermsOfUseAction', reason);
  }
};

export const getSecretQuestions =
  (query = '') =>
  (dispatch, state) => {
    setAuthHeader(state);

    axios
      .get(`${getApiBase()}/lookup/questions/?q=${query}`)
      .then((results) => {
        dispatch({ type: actions.SET_SECRET_QUESTIONS, results: results.data });
      })
      .catch((reason) => {
        console.log('!!!!getSecretQuestions', reason);
        store.dispatch(setToastMessage({ type: 'error', message: 'Opps, something went wrong' }));
      });
  };

export const getLicencingsBodiesListAction =
  (query = '') =>
  (dispatch, state) => {
    setAuthHeader(state);

    axios
      .get(`${getApiBase()}/lookup/licensors/?q=${query}`)
      .then((results) => {
        const updatedResults = [...results.data, { label: 'Other', value: 'other' }]; // Append "Other" option to the list
        dispatch({ type: actions.SET_LICENCINGS_BODIES_LIST, results: updatedResults });
      })
      .catch((reason) => {
        console.log('!!!!getLicencingsBodiesList', reason);
        store.dispatch(setToastMessage({ type: 'error', message: 'Opps, something went wrong' }));
      });
  };

export const getPracticeTypeListAction =
  (query = '') =>
  (dispatch, state) => {
    setAuthHeader(state);

    axios
      .get(`${getApiBase()}/lookup/specialties/?q=${query}`)
      .then((results) => {
        dispatch({ type: actions.SET_PRACTICE_TYPE_LIST, results: results.data });
      })
      .catch((reason) => {
        console.log('!!!!getPracticeTypeListAction', reason);
        store.dispatch(setToastMessage({ type: 'error', message: 'Opps, something went wrong' }));
      });
  };

export const updateRegistrationFormState = (data) => (dispatch) => {
  dispatch({ type: actions.UDATE_REGISTRATION_FORM_STATE, results: data || userInitialState.registrationFormState });
};
