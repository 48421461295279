import React from 'react';

const UserNameContent = ({ userName, isDirty }) => {
  const rules = {
    nameLength: /^.{8,50}$/.test(userName),
    letter: /\w*[a-zA-Z]\w*/.test(userName),
    validChars: /^[A-Za-z0-9_-]+$/.test(userName),
    noSpacesOrSpecialChars: /^[^\s!@#$%^&*(),.?":{}|<>]+$/.test(userName)
  };

  const getClassName = (rule) => {
    const commonClasses = 'flex gap-2';
    if (!isDirty) return commonClasses; // Default color
    return rules[rule] ? `${commonClasses} text-green-500` : `${commonClasses} p-error`; // Green if rule is valid, red if invalid
  };

  const getIcon = (rule) => {
    if (!isDirty) return 'pi pi-circle-fill x-small-text'; // Default color
    return rules[rule] ? 'pi pi-check-circle' : 'pi pi-times-circle'; // Green if rule is valid, red if invalid
  };

  const ruleItem = ({ rule, description, longText }) => (
    <div className={getClassName(rule)}>
      <div className={`flex justify-content-center align-items-center w-1rem h-1rem ${longText}`}>
        <i className={getIcon(rule)} />
      </div>

      <span>{description}</span>
    </div>
  );

  return (
    <div>
      <div className="m-0 my-4 text-800 w-full flex justify-content-center align-items-center font-bold text-3xl">Create a User Name</div>
      <div className="flex line-height-3 text-800 text-lg pb-2">User name rules:</div>

      <div className="flex flex-column gap-2">
        {ruleItem({ rule: 'nameLength', description: 'Your username must be between 8 and 50 characters.' })}
        {ruleItem({ rule: 'letter', description: 'The name must contain at least one letter.' })}
        {ruleItem({
          longText: 'px-2',
          rule: 'validChars',
          description: 'Only Latin letters (A-Z, a-z), numbers (0-9), underscores (_), and hyphens (-) are allowed.'
        })}
        {ruleItem({ rule: 'noSpacesOrSpecialChars', description: 'No spaces or special characters.' })}
      </div>
    </div>
  );
};

export default UserNameContent;
