import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';
import { InputWrapper } from '../../../../components/Inputs';
import { forgotPasswordStep, phoneVerificationAuth, resendPhoneCodeAuth } from '../../../../service/Lookup';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { routes } from '../../../../routes/routes';
import { useAuth } from '../../../../AuthProvider';
import { resetState } from '../../../../store';
import { expiredPasswordStatus } from '../../../config/userStatuses';
import { useTimer } from '../../../hooks/useTimer';
import { useAuthVerifications } from '../../hooks/useAuthVerifications';

const InputPhoneActivation = ({ setVisibleChangePhone }) => {
  const user = useSelector((state) => state.user.details);
  const history = useHistory();
  const dispatch = useDispatch();
  const { login } = useAuth();
  const { termsNotAccepted } = useAuthVerifications();
  const { forgotPassword } = useSelector((state) => state.user);
  const inputLength = 6;

  const TIMER_INITIAL_VALUE = 180000; // (180000 millisecunds - 3 minutes)
  const { formattedTime, startTimer, active } = useTimer({ duration: TIMER_INITIAL_VALUE });

  const {
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setError,
    reset,
    formState
  } = useForm({
    defaultValues: {
      code: ''
    }
  });

  const onSubmit = async (data) => {
    const payload = {
      DCNGuid: user.DCNGuid,
      DoctorGuid: user.DoctorGuid,
      Code: data.code,
      SendText: 1,
      SendEmail: 0
    };
    try {
      if (forgotPassword.passwordStatus) {
        const payload = {
          SessionGuid: user.SessionGuid,
          Email: user.Email,
          Code: data.code,
          SendText: 1
        };

        await forgotPasswordStep(payload, 2);
        dispatch(resetState());
        return;
      }

      await phoneVerificationAuth(payload);

      if (user.UserStatus === expiredPasswordStatus) {
        return history.replace(routes.passwordUpdate.path);
      }

      if (!user.TermsAccepted) {
        return termsNotAccepted();
      }

      login(user);
    } catch (error) {
      setError('code', { type: 'custom', message: 'You entered an incorrect verification code. Try again.' });
    }
  };

  const onClose = () => {
    dispatch(resetState());
    history.push(routes.signIn.path);
  };

  const resendCode = async () => {
    const payload = {
      DCNGuid: user.DCNGuid,
      DoctorGuid: user.DoctorGuid,
      SendText: 1,
      SendEmail: 0
    };
    resendPhoneCodeAuth(payload);
    clearErrors('code');
    reset({ code: '' });

    startTimer();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="code"
          control={control}
          rules={{
            required: t('Mandatory_field.1'),
            minLength: {
              value: inputLength,
              message: 'Minimum length is 6 characters'
            }
          }}
          render={({ field, fieldState }) => (
            <InputWrapper name="code" label="Code" required errorMessage={errors?.code?.message}>
              <InputText
                id={field.name}
                {...field}
                autoFocus
                keyfilter="int"
                maxLength={inputLength}
                className={cx({ 'p-invalid': fieldState.error })}
              />
            </InputWrapper>
          )}
        />
        <div className="flex gap-3 mt-2 mb-4 w-full">
          <Button id="submit" className="w-6 h-3rem" label={t('Submit')} type="submit" loading={formState.isSubmitting} />
          <Button className="p-button-outlined w-6 h-3rem" onClick={onClose} label="Close" type="button" />
        </div>

        {user.AnswerStatus !== 202 && (
          <div className="my-6">
            {!active ? (
              <div className="flex w-12 mb-3 text-800 text-base align-items-center">
                <span>Haven't received the text?</span>
                <Button
                  link
                  label="Resend the code"
                  type="button"
                  onClick={resendCode}
                  style={{ width: '130px', color: '#607D8B', fontSize: '1rem' }}
                />
              </div>
            ) : (
              <p className="w-12 pt-2 mb-3 text-800 text-base">
                {`Haven't received the text? Resend the code in a `}
                <span style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>{formattedTime}</span>.
              </p>
            )}
            {user.UserStatus !== expiredPasswordStatus &&
              (!active ? (
                <div className="text-800">
                  <Button
                    link
                    label="Change phone"
                    type="button"
                    onClick={() => setVisibleChangePhone(false)}
                    style={{ textAlign: 'left', width: '180px', paddingBottom: '2px', paddingLeft: '0px', color: '#607D8B', fontSize: '1rem' }}
                  />
                </div>
              ) : (
                <p className="text-base h-full mt-4" style={{ color: 'var(--primary-color)' }}>
                  Change phone number
                </p>
              ))}
          </div>
        )}
      </form>
    </>
  );
};

export default InputPhoneActivation;
