import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const LabelDialog = ({ patient_details, isDialogVisible, onDialogHide }) => {
  // Check if LabelImages exist and get the first image URL
  const labelImageUrl = patient_details?.LabelImages?.[0]?.FileUrl;

  console.log('labelImageUrl', labelImageUrl);

  return (
    <>
      <Dialog
        header="Uploaded Label"
        visible={isDialogVisible}
        style={{ width: '70vw', maxWidth: '800px' }}
        headerStyle={{
          textAlign: 'center'
        }}
        onHide={onDialogHide}
        breakpoints={{
          '960px': '90vw',
          '640px': '100vw'
        }}
      >
        <div className="flex justify-content-center pb-6">
          <img
            src={labelImageUrl}
            style={{
              maxWidth: '100%',
              maxHeight: '600px',
              objectFit: 'contain',
              margin: '0'
            }}
          />
        </div>
        <div className="flex justify-content-center">
          <Button label="Close" onClick={onDialogHide} />
        </div>
      </Dialog>
    </>
  );
};

// // PropTypes to enforce the expected shape of the props
// LabelDialog.propTypes = {
//   patient_details: PropTypes.object.isRequired,
// };

export default LabelDialog;
