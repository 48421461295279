import cashFlowActions from '../actions/cashFlow.action.types';
import { getLastXDaysRange } from '../../utils/getDate';

const defaultQuery = {
  PeriodFrom: getLastXDaysRange(30).from, // Show data from the last 30 days
  PeriodTo: getLastXDaysRange(30).to,
  Period: '7',
  PeriodText: 'All',
  PayeeNumber: 'All',
  SubmissionPeriodText: 'All',
  RemittancePeriodText: 'All',
  InvoiceType: 'All'
};

export const cashFlowRequestParams = {
  patientGuid: undefined,
  type: 0, // 0 - custom filters, 1 - day , 2 - week, 3 - month, 4 - year
  page: 1,
  pageSize: 20,
  sortby: 'LastName_asc',
  query: defaultQuery
};

export const cashFlowInitialState = {
  requestParams: cashFlowRequestParams,
  data: null,
  cashFlowLoading: false,
  dialogs: {
    claimsDialog: { visible: false, requestParams: cashFlowRequestParams, billedCount: 0, periodFrom: '' }
  }
};

export default function cashFlowReducer(state = cashFlowInitialState, action) {
  const { results } = action;

  switch (action.type) {
    case cashFlowActions.SET_CASH_FLOW_DATA:
      return {
        ...state,
        data: results
      };

    case cashFlowActions.SET_QUERY:
      return {
        ...state,
        requestParams: { ...state.requestParams, query: results }
      };

    case cashFlowActions.RESET_CASH_FLOW_STATE:
      return cashFlowInitialState;

    case cashFlowActions.SET_CASH_FLOW_LOADING:
      return {
        ...state,
        cashFlowLoading: results
      };

    case cashFlowActions.SET_CLAIMS_DIALOG_VISIBILITY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          claimsDialog: results
        }
      };

    default:
      return state;
  }
}
