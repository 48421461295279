import React, { useState } from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { clinicTypes } from '../helpers/defaultValues';
import { clinicTypeValidation } from '../helpers/validation';

const ClinicOrganizationType = () => {
  const name = 'clinicType';
  const ref = React.createRef();
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const errorMessage = errors[name]?.message;

  const clinicNames = () => clinicTypes.map((item) => item.label);
  const defaultClinicNames = clinicNames();
  const [clinicTypesNames, setClinicTypesNames] = useState(clinicNames);

  const onClinicTypeSearch = (event) => {
    const query = event.query;
    if (query.trim() === '') {
      setClinicTypesNames(clinicNames);
    } else {
      const filteredTypes = defaultClinicNames.filter((type) => type.toLowerCase().includes(query.toLowerCase()));
      setClinicTypesNames(filteredTypes);
    }
  };

  return (
    <>
      <InputWrapper name={name} label="Clinic Organizational type" required errorMessage={errorMessage}>
        <Controller
          name={name}
          control={control}
          rules={{ validate: clinicTypeValidation }}
          render={({ field }) => (
            <AutoComplete
              {...field}
              data-testid="clinicType"
              ref={ref}
              suggestions={clinicTypesNames}
              dropdown
              // forceSelection
              completeMethod={onClinicTypeSearch}
              onFocus={(e) => {
                ref.current?.search(e, '', 'dropdown');
              }}
              className={classNames({ 'p-invalid': errors[name] })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default ClinicOrganizationType;
