import moment from 'moment';
import { HOLIDAYS } from '../../config';

export const formatDate = (date, format = false, formatTo = true) => {
  const formatToValue = formatTo ? 'MMM D, YYYY' : 'ddd, MMM D, YYYY';

  if (format) {
    return moment(date, format).format(formatToValue);
  } else {
    return moment(date).format(formatToValue);
  }
};

export function formatDatewithMinutes(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export const fixDate = (originalDate) => {
  const offset = 1000 * new Date(originalDate).getTimezoneOffset() * 60;
  const adjusted = new Date(originalDate).getTime() + offset;
  const format = new Date(adjusted);
  return format; 
};

export const convertToLocalTime = (date, format = 'YYYY/MM/DD hh:mm A') => {
  return moment.utc(date).local().format(format);
};

export const checkDateDiff = (date) => {
  const dateOne = moment(date).endOf('day'); // set time component to end of day
  const dateTwo = moment().endOf('day'); // set time component to end of day
  return dateOne.diff(dateTwo, 'days');
};

export const isWeekendOrHoliday = (date) => {
  const momentDate = moment(date);

  // Check if the date is a weekend
  const isWeekend = momentDate.day() === 0 || momentDate.day() === 6;

  // Check if the date is a holiday
  const holidays = process.env.REACT_APP_CYPRESS ? [] : HOLIDAYS.flat();
  const isHoliday = holidays.includes(momentDate.format('MM/DD/YYYY'));

  return isWeekend || isHoliday;
};
