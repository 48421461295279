import { useEffect, useState } from 'react';
import { useCatalogsContext } from './useCatalogsContext';

export const useCatalogContentSizeMobile = () => {
  const { catalogsState, slideMenuRef, sideBarRef } = useCatalogsContext();
  const [scrollableTableHeight, setScrollableTableHeight] = useState(450);
  const [menuSizes, setMenuSizes] = useState({ menuHeight: 100, menuWidth: 350 });
  const currentCategory = catalogsState.currentCategory;
  const sideBarCurrentHeight = sideBarRef?.current?.getElement()?.offsetHeight;
  const mobileFooter = 40;
  const invoicesCatalogsTabs = 43;
  const invoicesCatalogsChips = 46;
  const sidebarHeader = 47;
  const breadcrumbHeight = 35;

  // set data table and slide menu height on mobile view when adding/removing chips
  useEffect(() => {
    if (catalogsState.activeTab?.tabLayout) {
      const height = sideBarCurrentHeight - sidebarHeader - invoicesCatalogsChips;
      setScrollableTableHeight(height);
    } else {
      setScrollableTableHeight(mobileCatalogsContentHeight());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideBarCurrentHeight]);

  // set slide menu height/width
  useEffect(() => {
    setMenuSizes({
      menuHeight: mobileCatalogsContentHeight(),
      menuWidth: slideMenuRef?.current?.slideMenuContent?.clientWidth
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory, slideMenuRef?.current?.slideMenuContent?.clientWidth]);

  const mobileCatalogsContentHeight = () => {
    const chipHeight = invoicesCatalogsChips;
    const height = (sideBarCurrentHeight || 0) - sidebarHeader - invoicesCatalogsTabs - chipHeight - breadcrumbHeight;
    return height;
  };

  return {
    mobileFooter,
    scrollableTableHeight,
    invoicesCatalogsChips,
    menuWidth: menuSizes.menuWidth,
    menuHeight: menuSizes.menuHeight
  };
};
