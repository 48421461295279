import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import PrivateInvoiceSend from '../components/Layouts/Send/PrivateInvoice/PrivateInvoiceSend';
import ClaimService from '../service/ClaimService';
import { routes } from '../routes/routes';
import classes from './PrintInvoice.module.scss';
import cx from 'classnames';

export const PrintInvoicePage = () => {
  const [data, setData] = useState();
  const location = useLocation();
  const shouldRender = location.pathname.includes(routes.printPDF.path);

  useEffect(() => {
    if (shouldRender) {
      const pathname = document.location.href;
      if (pathname && pathname.indexOf('/') > -1) {
        console.log('inside PrintInvoicePage');
        const path = pathname.replace('#/', '').split('/');
        const invoice_id = path.at(-1);
        const dcn = path.at(-2);
        if (invoice_id?.length > 0 && dcn?.length > 0) {
          ClaimService.getClaimInfoNoState(dcn, invoice_id).then((results) => {
            setData(results.data);
          });
        } else {
          console.log(path);
        }
      }
    }
  }, [shouldRender]);

  if (!shouldRender) return null;

  return <div className={cx('p-3', classes.root)}>{data && <PrivateInvoiceSend data={data} />}</div>;
};
