import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import Context from '../../../../../Context';
import CommonConfirmDialog from '../../../../common/components/CommonConfirmDialog';
import DeleteConfirmation from '../../../../common/components/DeleteConfirmation';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { inputs } from '../claimDetailsHelpers/inputs';
import { createPrivateRecord, deleteClaim, setClaimInfo, updatePrivateRecord } from '../../../actions/claims.action.creators';
import { privateBlankRecord } from '../claimDetailsHelpers/newClaimBlankRecord';
import { resetPrivateForm } from '../claimDetailsHelpers/resetPrivateForm';
import { multipleDoctorGuid } from '../../../../config/defaultValuesConfig';
import { elementIDs } from '../../../../config/elementIDsConfig';
import { onInvoiceClick } from '../../../helpers/rowActions';
import { t } from '../../../../../service/localization/i18n';
import cx from 'classnames';
import { SplitButton } from 'primereact/splitbutton';
import { setToastMessage } from '../../../../core/actions/core.action.creators';

const ReviewPanelActionButtons = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { privateRecord, setStep, isNew, isDirty, validateForm, setIsPractitionerIsEmpty, deleteInvoiceCallback, isInvoicePatientForBalance } =
    useContext(Context);
  const { creatingPrivateRecord, isFetchingClaims } = useSelector((state) => state.claims);

  const [visible, setVisible] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onCreate = ({ saveAndClose = false } = {}) => {
    if (privateRecord?.ServicesList?.some((i) => !i?.doctorGuid || i?.doctorGuid === '-1' || i?.doctorGuid === '-2')) {
      return dispatch(setToastMessage({ message: 'Please, select a practitioner for all services', type: 'error' }));
    }

    const requestType = isNew ? 'create' : 'save';
    const updatedClaimRecord = {
      ...privateRecord,
      DoctorGuid: privateRecord?.DoctorGuid === multipleDoctorGuid ? privateRecord?.DCNGuid : privateRecord?.DoctorGuid,
      PatientList: Array.isArray(privateRecord[commonInputs.patients.descriptionName])
        ? privateRecord[commonInputs.patients.descriptionName]?.map((i) => i?.PatientGuid)
        : [privateRecord[commonInputs.patients.descriptionName]?.PatientGuid],
      PayToGuid: privateRecord[inputs().payTo.name]?.DoctorGuid
        ? privateRecord[inputs().payTo.name]?.DoctorGuid
        : privateRecord[inputs().payTo.name]?.DCNGuid,
      BillToGuid: privateRecord[inputs().billTo.name][0]?.PatientGuid
    };

    delete updatedClaimRecord[inputs().payTo.name];
    delete updatedClaimRecord[inputs().billTo.name];
    delete updatedClaimRecord[commonInputs.patients.descriptionName];
    delete updatedClaimRecord[inputs().privateService.name];
    delete updatedClaimRecord.PatientDetails;
    delete updatedClaimRecord.CurrentUserPrefs;
    delete updatedClaimRecord.DateCreated;
    delete updatedClaimRecord.DateUpdated;
    delete updatedClaimRecord.SDate;
    delete updatedClaimRecord.FeePaid;
    delete updatedClaimRecord.FeeAmount;
    delete updatedClaimRecord.NoOfServiceUnits;

    setIsPractitionerIsEmpty(false);
    dispatch(
      createPrivateRecord(updatedClaimRecord, requestType, (responceData) => {
        const formattedRecord = privateBlankRecord({
          privateRecord: {
            ...responceData,
            ServicesList: updatedClaimRecord.ServicesList // CMO-2424 - Private invoice->Service/product change order on the invoice
          },
          responceUpdate: true
        });
        const patientDetails = {
          ...formattedRecord?.Patients[0],
          LastPrivateGuid: formattedRecord?.InvoiceGuid
        };

        dispatch(
          updatePrivateRecord(
            {
              ...formattedRecord,
              Payments: responceData.Payments,
              PatientDetails: patientDetails,
              BillToGuid: responceData.BillToGuid,
              PatientGuid: responceData.PatientGuid
            },
            true
          )
        );

        if (saveAndClose) {
          history.goBack();
        } else {
          setStep(2);
        }
        dispatch(setClaimInfo(formattedRecord)); // highlight line in the data table
      })
    );
  };

  const onCancel = () => {
    if (isDirty) history.goBack();
    if (!isDirty) {
      resetPrivateForm();
      history.goBack();
    }
  };

  const onDelete = () => {
    dispatch(deleteClaim({ invoiceGuid: privateRecord?.InvoiceGuid, callback: deleteInvoiceCallback }));
  };

  const deleteButton = !isNew && privateRecord?.Status !== 2 && !isInvoicePatientForBalance && (
    <Button id={elementIDs.delete} outlined label={t('Delete')} loading={isFetchingClaims} onClick={() => setShowDeleteConfirmation(true)} />
  );

  const createButtonItems = () => {
    const items = [
      {
        label: 'Save & Close',
        command: () => validateForm(() => onCreate({ saveAndClose: true }))
      }
    ];
    return items;
  };

  const createButton = (
    <SplitButton
      className="w-full sm:w-auto"
      label="Save & View Invoice"
      onClick={() => validateForm(onCreate)}
      loading={creatingPrivateRecord}
      disabled={isFetchingClaims}
      model={createButtonItems()}
    />
  );

  return (
    <>
      <div className={cx('flex align-items-center justify-content-center w-full lg:w-30rem  stickyActionButtons')}>
        {!isDirty && (
          <div className="flex w-full justify-content-center sm:justify-content-start gap-2">
            {!isNew && <Button label="View Invoice" className="w-full sm:w-auto" onClick={() => onInvoiceClick(privateRecord, history, true)} />}
            <Button id={elementIDs.close} className={isNew ? 'w-full' : ''} outlined label={t('Close')} onClick={onCancel} />
            {deleteButton}
          </div>
        )}

        {isDirty && (
          <div className="flex w-full justify-content-center sm:justify-content-start gap-2">
            {createButton}
            <Button id={elementIDs.cancel} className="w-10rem sm:w-auto" outlined label={t('Cancel')} onClick={onCancel} />
            {deleteButton}
          </div>
        )}
      </div>

      <CommonConfirmDialog
        visible={visible}
        body={t('You_made_some_changes_to_this_screen')}
        accept={() => setStep(0)}
        reject={() => setVisible(false)}
      />

      <DeleteConfirmation
        visible={showDeleteConfirmation}
        message={t('Are_you_sure_delete_this_invoice')}
        header={t('Delete_Claim')}
        icon="pi pi-exclamation-triangle"
        acceptLabel={t('Yes')}
        rejectLabel={t('No')}
        accept={onDelete}
        reject={() => setShowDeleteConfirmation(false)}
        onHide={() => setShowDeleteConfirmation(false)}
        baseZIndex={1000}
      />
    </>
  );
};

export default ReviewPanelActionButtons;
