import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useAuth } from '../../../AuthProvider';
import { isDevelopment } from '../../../config';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { stringFormat } from '../../../modules/utils/stringFormat';
import { t } from '../../../service/localization/i18n';

const SessionIdle = () => {
  const { logout } = useAuth();
  const [remaining, setRemaining] = useState(0);
  const [open, setOpen] = useState(false);
  const { isMobile } = useSelector((state) => state.core.window);

  // Calculate timeout based on device type
  // const timeout = isDevelopment ? 10 * 60 * 60 * 1000 : isMobile ? 3 * 60 * 60 * 1000 : 15 * 60 * 1000; // 3 hours for mobile or 15 minute for desktop
  const timeout = isDevelopment ? 10 * 60 * 60 * 1000 : isMobile ? 30 * 60 * 1000 : 15 * 60 * 1000; // 30 min for mobile or 15 minute for desktop

  // Adjust promptBeforeIdle to be less than timeout
  const promptBeforeIdle = 59 * 1000; // 60 seconds

  const timeoutMinutes = timeout / 60 / 1000;
  const promptBeforeIdleMinutes = Math.round(promptBeforeIdle / 60 / 1000);

  const onIdle = () => {
    setOpen(false);
    logout();
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    key: timeout
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  const handleStillHere = () => {
    activate();
  };

  return (
    <>
      <Dialog
        id={elementIDs.sessionIdleDialog}
        header={t('Inactive_session')}
        visible={open}
        breakpoints={{ '768px': '95vw' }}
        style={{ width: '25vw' }}
        modal
        baseZIndex={1000000}
        onHide={handleStillHere}
      >
        <h6>{stringFormat(t('Logging_out_in_x_seconds'), remaining)}</h6>
        <p>{stringFormat(t('Inactive_session_text'), timeoutMinutes, promptBeforeIdleMinutes)}</p>
        <div className="flex justify-content-center gap-2">
          <Button className="w-full" label={t('Stay_connected')} onClick={handleStillHere} />
          <Button className="w-full" label={t('Logout_now')} onClick={() => logout()} outlined />
        </div>
      </Dialog>
    </>
  );
};

export default SessionIdle;
