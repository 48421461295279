import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';
import { saveContactData, saveRegistrationData } from '../../../../service/Lookup';
import { updateRegistrationFormState } from '../../actions/registration.actions.creators';
import { store } from '../../../../store';
import SecretQuestionsBody from '../../../../components/SecretQuestions/SecretQuestionsBody';
import { setToastMessage } from '../../../core/actions/core.action.creators';

const SecretQuestions = () => {
  const dispatch = useDispatch();
  const registrationState = useSelector((state) => state.registration.registrationFormState);
  const secretQuestions = useSelector((state) => state.user?.secretQuestions);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      question1: '',
      question2: '',
      question3: '',
      answer1: '',
      answer2: '',
      answer3: '',
      secretQuestions: secretQuestions
    }
  });
  const { handleSubmit, getValues } = methods;
  const selectedQuestionsList = getValues(['question1', 'question2', 'question3']);
  const selectedAnswersList = getValues(['answer1', 'answer2', 'answer3']);

  const onSubmit = async () => {
    const phone = false;
    const dataSubmitted = registrationState.initialFormState;
    const formatedPhoneNumber = dataSubmitted.phoneNumber?.replace(/[^0-9]/g, '');

    const selectedQuestionsWithKeys = () => secretQuestions.filter((question) => selectedQuestionsList.includes(question.label));

    // This array contains objects with value=keys of question and label=answers of User.
    const selectedAnswersWithKeys = () => {
      let fullAnswers = [];
      selectedAnswersList.map((answer, index) => {
        fullAnswers[index] = { value: selectedQuestionsWithKeys()[index].value, label: answer };
      });
      return fullAnswers;
    };

    const dataToSend = {
      ...registrationState.initialFormState,
      SecurityQuestions: selectedAnswersWithKeys(),
      ReferenceCode: registrationState.ReferenceCode
    };
    try {
      await saveRegistrationData(dataToSend); // this function sends reqest to BE for saving User's data includes SequrityAnswers

      const body = {
        SessionGuid: registrationState.initialFormState.sessionGuid,
        Email: dataSubmitted.email,
        Phone: formatedPhoneNumber,
        Code: '',
        SendText: phone ? 1 : 0,
        SendEmail: phone ? 0 : 1,
        WhenCreated: new Date().toISOString()
      };

      saveContactData(body); // this function sends reqest to BE for getting the code verification to Use's Email`

      dispatch(
        updateRegistrationFormState({
          ...registrationState,
          type: 'register-step-2'
        })
      );
    } catch {
      store.dispatch(setToastMessage({ type: 'error', message: 'Opps, something went wrong' }));
    }
  };

  return (
    <FormProvider {...methods}>
      <AuthFormWrap style={{ maxWidth: '550px' }}>
        <div className="m-0 my-4 text-800 w-full text-center align-items-center font-bold text-3xl">Secure Your Account</div>
        <div className="flex flex-column gap-6">
          <span className="line-height-3">
            Please select three security questions from the list and provide answers to enhance your account's security. These will be used for
            identity verification if you need to recover your access.
          </span>

          <form onSubmit={handleSubmit(onSubmit)}>
            <SecretQuestionsBody />
          </form>
        </div>
      </AuthFormWrap>
    </FormProvider>
  );
};

export default SecretQuestions;
