import actions from './claims.action.types';
import { store } from '../../../store';
import { location_codes } from '../../config/constants';
import { inputs } from '../components/TeleplanInvoice/helpers/inputs';
import { commonInputs } from '../../config/commonInputsConfig';
import { recentServiceDates } from '../helpers/recentServiceDates';
import { formatWCBClaims } from '../helpers/formatWSBCClaimReport';
import { updateQuickPickCodesList } from '../helpers/updateQuickPickCodesList';
import feeCodes from '../../../mocks/db/claims/codesForAutocomplete/feeCodes.json';
import dxCodes from '../../../mocks/db/claims/codesForAutocomplete/dxCodes.json';
import aoiCodes from '../../../mocks/db/claims/codesForAutocomplete/aoiCodes.json';
import noiCodes from '../../../mocks/db/claims/codesForAutocomplete/noiCodes.json';
import referralCodes from '../../../mocks/db/claims/codesForAutocomplete/referralCodes.json';
import patientsForAutocomplete from '../../../mocks/db/patients/patientsForAutocomplete.json';
import claimsDataForCypressTest from '../../../mocks/db/claims/patientData/claimsDataForCypressTest.json';
import claimsDataForCypressWithphn from '../../../mocks/db/claims/patientData/claimsDataForCypressWithphn.json';
import createBasicClaimRecords from '../../../mocks/db/claims/createNewClaim/records/createBasicClaimRecords.json';
import basicClaimData from '../../../mocks/db/claims/createNewClaim/claimDataForRequest/basicClaimData.json';
import firstVisitClaimData from '../../../mocks/db/claims/createNewClaim/claimDataForRequest/firstVisitClaimData.json';
import firstVisitClaimDataWithIssues from '../../../mocks/db/claims/createNewClaim/claimDataForRequest/firstVisitClaimDataWithIssues.json';
import firstVisitMSPClaimDataResponse from '../../../mocks/db/claims/createNewClaim/claimDataForResponse/firstVisitMSPClaimDataResponse.json';
import firstVisitMSPClaimDataWithIssuesResponse from '../../../mocks/db/claims/createNewClaim/claimDataForResponse/firstVisitMSPClaimDataWithIssuesResponse.json';
import fullFilledWSBCClaimData from '../../../mocks/db/claims/createNewClaim/claimDataForRequest/fullFilledWSBCClaimData.json';
import fullFilledWSBCClaimDataWithIssues from '../../../mocks/db/claims/createNewClaim/claimDataForRequest/fullFilledWSBCClaimDataWithIssues.json';
import fullFilledWSBCClaimRecords from '../../../mocks/db/claims/createNewClaim/records/fullFilledWSBCClaimRecords.json';
import reasonFor01080ClaimRecords from '../../../mocks/db/claims/createNewClaim/records/reasonFor01080ClaimRecords.json';
import bmiCommentClaimRecords from '../../../mocks/db/claims/createNewClaim/records/bmiCommentClaimRecords.json';
import firstVisitClaimRecords from '../../../mocks/db/claims/createNewClaim/records/firstVisitClaimRecords.json';
import fullFilledWSBCInvoiceDataResponse from '../../../mocks/db/claims/createNewClaim/claimDataForResponse/fullFilledWSBCInvoiceDataResponse.json';
import fullFilledWSBCInvoiceDataWithIssuesResponse from '../../../mocks/db/claims/createNewClaim/claimDataForResponse/fullFilledWSBCInvoiceDataWithIssuesResponse.json';
import mostRecentCodes from '../../../mocks/db/claims/mostRecentCodes/mostRecentCodes.json';
import mostRecentCodesForCypressTest from '../../../mocks/db/claims/mostRecentCodes/mostRecentCodesForCypressTest.json';
import mostRecentCodesForCypressWithphn from '../../../mocks/db/claims/mostRecentCodes/mostRecentCodesForCypressWithphn.json';
import mostRecentCodesForCorVisit from '../../../mocks/db/claims/mostRecentCodes/mostRecentCodesForCorVisit.json';
import wsbcOutstandingInvoiceInfo from '../../../mocks/db/claims/editTeleplanClaim/wsbcInvoice/wsbcOutstandingInvoiceInfo.json';
import wsbcOutstandingInvoiceInfoWithIssues from '../../../mocks/db/claims/editTeleplanClaim/wsbcInvoice/wsbcOutstandingInvoiceInfoWithIssues.json';
import editedWSBCOutstandingInvoiceInfo from '../../../mocks/db/claims/editTeleplanClaim/wsbcInvoice/editedWSBCOutstandingInvoiceInfo.json';
import editedWSBCOutstandingInvoiceInfoWithIssues from '../../../mocks/db/claims/editTeleplanClaim/wsbcInvoice/editedWSBCOutstandingInvoiceInfoWithIssues.json';
import wsbcDeclinedInvoiceInfo from '../../../mocks/db/claims/editTeleplanClaim/wsbcInvoice/wsbcDeclinedInvoiceInfo.json';
import mspOutstandingClaim from '../../../mocks/db/claims/editTeleplanClaim/mspInvoice/mspOutstandingClaim.json';
import mspDraftClaim from '../../../mocks/db/claims/editTeleplanClaim/mspInvoice/mspDraftClaim.json';
import { setClean } from '../../core/actions/core.action.creators';
import { localStorageKeys } from '../../config/localStorageKeysConfig';
import { t } from '../../../service/localization/i18n';
import { filter } from 'lodash';
import { decodeText } from '../../utils/decodeText';

export const formatRecentCodesForUser = (responseData) => {
  const speciality = store.getState().user?.details?.Speciality;
  const serviceDates = process.env.REACT_APP_CYPRESS ? responseData.serviceDate : recentServiceDates(responseData.visits || []);

  const updatedResponseData = {
    ...updateQuickPickCodesList(responseData, speciality),
    serviceDate: serviceDates,
    locationCode: location_codes
  };

  return updatedResponseData;
};

export const getMostRecentCodesResponseAction = (patientGuid, responseData, callback, updateRedux) => {
  const speciality = store.getState().user?.details?.Speciality;
  const serviceDates = process.env.REACT_APP_CYPRESS ? responseData.serviceDate : recentServiceDates(responseData.visits || []);

  const updatedResponseData = {
    ...updateQuickPickCodesList(responseData, speciality),
    serviceDate: serviceDates,
    locationCode: location_codes,
    patientGuid
  };

  updateRedux &&
    store.dispatch({
      type: actions.GET_INVOICES_CATALOG_MOST_RECENT_CODES,
      results: updatedResponseData
    });

  callback && callback(updatedResponseData);
};

export const getMostRecentCodesResponseActionForTest = (patientGuid = '', callback, updateRedux) => {
  const mostRecent = getMostRecentCodesForTest(patientGuid);
  return getMostRecentCodesResponseAction(patientGuid, mostRecent, callback, updateRedux);
};

export const getMostRecentCodesForTest = (patientGuid = '') => {
  const mostRecentCodesList = {
    '4329b102-5196-4531-8bd6-d983b1c9d2a9': mostRecentCodesForCypressTest,
    '47c26601-7781-4a94-acd2-7be532431acb': mostRecentCodesForCypressWithphn,
    '324323b5-221f-4657-b8f2-887879ed6630': mostRecentCodesForCorVisit
  };

  const mostRecent = mostRecentCodesList[patientGuid] || mostRecentCodes;
  return mostRecent;
};

export const getAllInvoicesCatalogCategoriesResponseAction = (categories) => {
  return store.dispatch({
    type: actions.GET_ALL_CATALOG_CATEGORIES,
    results: categories
  });
};

export const getCodesByTypeResponceAction = (codeType, query, isGetInitForEdit) => {
  switch (codeType) {
    case commonInputs.feeCodes.codeType:
      const feeCodesList = isGetInitForEdit ? filter(feeCodes, { value: query }) : feeCodes;
      return Promise.resolve(feeCodesList);

    case commonInputs.icd9.codeType:
      const dxCodesList = isGetInitForEdit ? filter(dxCodes, { value: query }) : dxCodes;
      return Promise.resolve(dxCodesList);

    case commonInputs.aoi.codeType:
      const aoiCodesList = isGetInitForEdit ? filter(aoiCodes, { value: query }) : aoiCodes;
      return Promise.resolve(aoiCodesList);

    case commonInputs.noi.codeType:
      const noiCodesList = isGetInitForEdit ? filter(noiCodes, { value: query }) : noiCodes;
      return Promise.resolve(noiCodesList);

    case commonInputs.referral.codeType:
      const referralCodesList = isGetInitForEdit ? filter(referralCodes, { value: query }) : referralCodes;
      return Promise.resolve(referralCodesList);

    default:
      return Promise.resolve([]);
  }
};

export const getPatientClaimDataResponceAction = (responseData, callback) => {
  const formattedResponse = formatWCBClaims(responseData);

  store.dispatch({
    type: actions.SET_CLAIMS_DATA,
    results: formattedResponse
  });

  callback && callback(formattedResponse);
};

export const getPatientClaimDataResponceActionForTests = (patientId, callback) => {
  const patientGuid = Array.isArray(patientId) ? patientId[0] : patientId;
  const id = patientGuid.toUpperCase();
  const cypressTestPatient = patientsForAutocomplete[0].PatientGuid.toUpperCase();
  const cypressWithphnPatient = patientsForAutocomplete[1].PatientGuid.toUpperCase();

  switch (id) {
    case cypressTestPatient:
      return getPatientClaimDataResponceAction(claimsDataForCypressTest, callback);

    case cypressWithphnPatient:
      return getPatientClaimDataResponceAction(claimsDataForCypressWithphn, callback);

    default:
      return getPatientClaimDataResponceAction({}, callback);
  }
};

export const getPatientClaimDataForTests = (patientId) => {
  const patientGuid = Array.isArray(patientId) ? patientId[0] : patientId;
  const id = patientGuid.toUpperCase();
  const cypressTestPatient = patientsForAutocomplete[0].PatientGuid.toUpperCase();
  const cypressWithphnPatient = patientsForAutocomplete[1].PatientGuid.toUpperCase();

  switch (id) {
    case cypressTestPatient:
      return formatWCBClaims(claimsDataForCypressTest);

    case cypressWithphnPatient:
      return formatWCBClaims(claimsDataForCypressWithphn);

    default:
      return {};
  }
};

export const getBatchRecordsResponseAction = (responseData, callback) => {
  const newList = responseData.map((i) => {
    return {
      ...i,
      to_time: i.to_time === 0 ? '' : i.to_time,
      from_time: i.from_time === 0 ? '' : i.from_time,
      total_time: i.total_time === 0 ? '' : i.total_time
    };
  });

  callback && callback(newList);
};

export const getBatchRecordsResponseActionForTests = (claimRecord, callback) => {
  // Condition for basic records list
  if (decodeText(claimRecord[inputs.comment.name]) === basicClaimData[inputs.comment.name]) {
    return callback && callback(createBasicClaimRecords);
  }

  // Condition for full filled records list
  if (decodeText(claimRecord[inputs.comment.name]) === fullFilledWSBCClaimData[inputs.comment.name]) {
    return callback && callback(fullFilledWSBCClaimRecords);
  }

  // Condition for checking BMI comment
  if (decodeText(claimRecord[inputs.comment.name]) === 'Comment for BMI on step 2') {
    return callback && callback(bmiCommentClaimRecords);
  }

  // Condition for checking reason for 01080 code
  if (decodeText(claimRecord[inputs.comment.name]) === t('Reason_for_01080')) {
    return callback && callback(reasonFor01080ClaimRecords);
  }

  // Condition for checking reason for 01080 code
  if (decodeText(claimRecord[inputs.comment.name]) === firstVisitClaimData[inputs.comment.name]) {
    return callback && callback(firstVisitClaimRecords);
  }

  return callback && callback(fullFilledWSBCClaimRecords);
};

export const saveBatchRecordResponseAction = (responseData, callback) => {
  // [KS] CMO-1322 - Dynamic response for submitting invoices
  if (responseData?.Issues) {
    // if error
    callback && callback({ ...responseData, error: true });
  } else {
    callback && callback({ recordsList: responseData.claimList, error: false });
  }
};

export const saveBatchRecordResponseActionForTests = (claimRecord, callback) => {
  // Condition for full filled records list
  if (decodeText(claimRecord[inputs.comment.name]) === fullFilledWSBCClaimData[inputs.comment.name]) {
    return saveBatchRecordResponseAction(fullFilledWSBCInvoiceDataResponse, callback);
  }

  // Condition for full filled records list with Issues
  if (decodeText(claimRecord[inputs.comment.name]) === fullFilledWSBCClaimDataWithIssues[inputs.comment.name]) {
    return saveBatchRecordResponseAction(fullFilledWSBCInvoiceDataWithIssuesResponse, callback);
  }

  // Condition for first visit
  if (decodeText(claimRecord[inputs.comment.name]) === firstVisitClaimData[inputs.comment.name]) {
    return saveBatchRecordResponseAction(firstVisitMSPClaimDataResponse, callback);
  }

  // Condition for first visit with Issues
  if (decodeText(claimRecord[inputs.comment.name]) === firstVisitClaimDataWithIssues[inputs.comment.name]) {
    return saveBatchRecordResponseAction(firstVisitMSPClaimDataWithIssuesResponse, callback);
  }

  return saveBatchRecordResponseAction(fullFilledWSBCInvoiceDataResponse, callback);
};

export const getClaimInfoResponseAction = (claimRecord, callback) => {
  callback && callback(claimRecord);
};

export const getClaimInfoResponseActionForTests = (invoiceGuid, callback) => {
  const claimRecords = {
    '0036b25d-fd4b-440b-a200-fd142aa9b672-with-issues': wsbcOutstandingInvoiceInfoWithIssues,
    '0036b25d-fd4b-440b-a200-fd142aa9b672': wsbcOutstandingInvoiceInfo,
    'ddd67f2b-8b0f-4378-ae92-32b002d768f7': wsbcDeclinedInvoiceInfo,
    '93e708f4-6954-43a6-8b27-d35cd73a0cc3': mspOutstandingClaim,
    '89dc9d02-c8eb-40b5-afcd-e7761c12ff7f': mspDraftClaim
  };

  const claimRecord = claimRecords[invoiceGuid] || wsbcOutstandingInvoiceInfo;
  getClaimInfoResponseAction(claimRecord, callback);
};

export const saveClaimRecordResponseAction = (claimRecord, callback) => {
  const isMobileOnly = store.getState().core.window.isMobileOnly;
  const claims = store.getState().claims;

  // [KS] CMO-1322 - Dynamic response for submitting invoices
  if (claimRecord?.Issues) {
    // if error
    callback &&
      callback({
        ...claimRecord,
        error: true
      });
  } else {
    const newClaimList = claims.claim_list?.map((i) => (i.InvoiceGuid === claimRecord.InvoiceGuid ? claimRecord : i));
    store.dispatch(setClean());
    callback &&
      callback({
        data: claimRecord,
        typeForClaims: actions.SET_CLAIM_LIST,
        newClaimList,
        isMobileOnly
      });
  }
};

export const saveClaimRecordResponseActionForTests = (invoiceGuid, callback) => {
  const claimRecords = {
    '0036b25d-fd4b-440b-a200-fd142aa9b672': editedWSBCOutstandingInvoiceInfo,
    '0036b25d-fd4b-440b-a200-fd142aa9b672-with-issues': editedWSBCOutstandingInvoiceInfoWithIssues
  };

  const claimRecord = claimRecords[invoiceGuid] || editedWSBCOutstandingInvoiceInfo;
  saveClaimRecordResponseAction(claimRecord, callback);
};
