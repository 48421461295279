import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';

import { routes } from '../../../../routes/routes';
import { saveRegistrationData } from '../../../../service/Lookup';
import Privacy from '../../../../components/Privacy';

const RegisterPrivacy = () => {
  const history = useHistory();
  const registrationState = useSelector((state) => state.registration.registrationFormState);

  const onAccept = async () => {
    // if "the New User" has confirmed the "User Terms", we send the flag {termsAccepted: true} to the server
    const dataToSend = { ...registrationState.initialFormState, termsAccepted: true, wizardStep: 6, ReferenceCode: registrationState.ReferenceCode };
    saveRegistrationData(dataToSend);
    history.replace(routes.registerPrivacyAccept.path);
  };

  const onCancel = () => {
    history.replace(routes.registerPrivacyDeny.path);
  };

  return <Privacy onAccept={onAccept} onCancel={onCancel} />;
};

export default RegisterPrivacy;
