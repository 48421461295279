import { useFormContext } from 'react-hook-form';
import { t } from '../../../../service/localization/i18n';

export const useValidation = () => {
  const { getValues } = useFormContext();

  const isPerson = Number(getValues('ContactType')) === 1;
  const isCompany = Number(getValues('ContactType')) === 2;

  const firstNameValidation = (value) => {
    if (isPerson) {
      // If `isPerson`, FirstName must be required.
      return value ? true : t('Mandatory_field.1');
    }
    if (isCompany) {
      // If `isCompany`, at least one of the fields must be filled.
      const anyFieldFilled = ['FirstName', 'LastName', 'CompanyName'].some((field) => getValues(field));
      return anyFieldFilled ? true : 'Enter First Name, Last Name, or Company Name';
    }
    return true; // If neither condition applies, return true (valid)
  };

  const lastNameValidation = (value) => {
    if (isPerson) {
      return value ? true : t('Mandatory_field.1');
    }
    if (isCompany) {
      const anyFieldFilled = ['FirstName', 'LastName', 'CompanyName'].some((field) => getValues(field));
      return anyFieldFilled ? true : 'Enter First Name, Last Name, or Company Name';
    }
    return true;
  };

  const companyNameValidation = () => {
    if (isCompany) {
      const anyFieldFilled = ['FirstName', 'LastName', 'CompanyName'].some((field) => getValues(field));
      return anyFieldFilled ? true : 'Enter First Name, Last Name, or Company Name';
    }
    return true; // Not required for "isPerson"
  };

  const countryValidation = (value) => {
    return value ? true : t('Mandatory_field.1');
  };

  return {
    isPerson,
    isCompany,
    countryValidation,
    firstNameValidation,
    lastNameValidation,
    companyNameValidation
  };
};
