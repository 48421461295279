import React from 'react';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import cx from 'classnames';

const PatientFormCard = ({
  title,
  titleClassName,
  children,
  rootClassName,
  iconTooltip,
  footerButtonLable,
  footerButtonOnClick,
  footerContent,
  headerContentLeft,
  spacer,
  showFooter = false,
  isFetching = false,
  showTitleIcon = false,
  showForm = true
}) => {
  return (
    <div
      className={cx('col-12 p-0', rootClassName || '')}
      style={{
        visibility: showForm ? 'visible' : 'hidden',
        position: showForm ? 'relative' : 'absolute'
      }}
    >
      <div className={'card p-fluid p-3 sm:p-4 m-0'}>
        <div className="flex align-items-center justify-content-between pb-4">
          {/* Left Content */}
          <div className="flex" style={{ justifyContent: 'flex-start', flex: 1 }}>
            {headerContentLeft}
          </div>

          {/* Center: Title */}
          <div className="flex-grow-1 text-center">
            <Tooltip target=".checkMSPQuestion" position="top" />
            <div className="text-xl font-bold">{title}</div>
            {showTitleIcon && <i className="pi pi-question-circle cursor-pointer ml-3 checkMSPQuestion" data-pr-tooltip={iconTooltip || ''} />}
          </div>

          {/* Empty Space */}
          <div className="flex" style={{ flex: 1 }} />
        </div>

        {/* Body */}
        <div>{children}</div>

        {/* Footer */}
        {showFooter && (
          <div className={cx('flex justify-content-end p-3')}>
            <Button
              className="p-button-text"
              disabled={isFetching}
              label={footerButtonLable || ''}
              style={{ width: 'max-content' }}
              onClick={footerButtonOnClick || undefined}
            />
          </div>
        )}

        {footerContent?.() && <div className={cx('flex justify-content-end p-3')}>{footerContent()}</div>}
      </div>
    </div>
  );
};

export default PatientFormCard;
