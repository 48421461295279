import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputMask } from 'primereact/inputmask';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import cx from 'classnames';

const Phone = () => {
  const name = inputs.phone.name;
  const { errors, control } = useFormContext();

  const phoneInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.phone.label}>
        <InputMask
          ref={field.ref}
          className={cx({ 'p-invalid': errors[name]?.message })}
          id={field.name}
          value={field.value}
          inputMode="numeric"
          mask="(999) 999-9999"
          placeholder={inputs.phone.placeholder}
          onChange={(e) => field.onChange(e.value)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => phoneInput(field)} />;
};

export default Phone;
