import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { TIMEZONES } from '../../../config/enums';
import { useTimer } from '../../../hooks/useTimer';
import moment from 'moment';

const TimelineContent = ({ item, closeOffDeadline, currentDate, isCloseOff, hours, loading }) => {
  const PST_TIMEZONE = TIMEZONES.PST;

  const deadline = isCloseOff
    ? moment.tz(item.date, moment.ISO_8601, PST_TIMEZONE).set(closeOffDeadline)
    : moment.tz(item.date, moment.ISO_8601, PST_TIMEZONE).endOf('day');

  const now = moment().tz(PST_TIMEZONE);
  const isToday = moment(item.date).isSame(currentDate, 'day');
  const duration = moment.duration(deadline.diff(now));
  const hoursLeft = Math.floor(duration.asHours());
  const minutesLeft = duration.minutes();
  const dayText = item.daysAway === 1 ? 'day' : 'days';
  const notCloseOffUpcomingEvent = !isToday && !isCloseOff && item.daysAway !== undefined;

  const countdownDuration = deadline.diff(now);

  const { formattedTime, active } = useTimer({
    duration: countdownDuration,
    startTime: now
  });

  if (loading) {
    return <Skeleton height="28px" width="78px" />;
  }

  return (
    <div className="flex flex-column gap-1 h-2rem">
      <div className="flex flex-column sm:flex-row">
        <span className="font-bold">{moment(item.date).format('MMM D, YYYY')}</span>
        {isCloseOff && (
          <>
            <div className="hidden sm:flex">{`, 7 pm PST`}</div>
            <div className="flex sm:hidden">{`7 pm PST`}</div>
          </>
        )}
      </div>
      {isToday && isCloseOff && (
        <div className="flex flex-column sm:flex-row gap-1">
          <span className="p-error">Today</span>
          {/* <span>{hoursLeft > 0 && hoursLeft < hours && ` in ${hoursLeft} hrs ${minutesLeft} mins`}</span> */}
          {hoursLeft > 0 && hoursLeft <= hours && <span> in {active ? formattedTime : `${hoursLeft} hrs ${minutesLeft} mins`}</span>}
        </div>
      )}

      {/* Close-off timeline */}
      {isCloseOff && item.daysAway > 1 && <div className="font-bold text-green-500">{`${item.daysAway} ${dayText} away`}</div>}
      {isCloseOff && item.daysAway === 1 && <div className="font-bold text-green-500">Tomorrow</div>}

      {/* Remittance and Payment timelines */}
      {!isCloseOff && isToday && <div className="text-green-500 font-bold">Today</div>}
      {notCloseOffUpcomingEvent && item.daysAway > 1 && <div className="font-bold text-green-500">{`${item.daysAway} ${dayText} away`}</div>}
      {notCloseOffUpcomingEvent && item.daysAway === 1 && <div className="font-bold text-green-500">Tomorrow</div>}
    </div>
  );
};

export default TimelineContent;
