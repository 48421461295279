import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

const BureauName = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <InputWrapper name="bureauName" label="Billing Bureau Company Name" required errorMessage={errors.bureauName?.message}>
        <Controller
          name="bureauName"
          control={control}
          rules={{ required: 'Bureau name is required' }}
          render={({ field }) => (
            <InputText {...field} id="bureauName" data-testid="bureauName" className={classNames({ 'p-invalid': errors.bureauName })} />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default BureauName;
