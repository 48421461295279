import { Button } from 'primereact/button';
import SafeHtmlContent from '../../../components/Misc/SafeHTMLContent/SafeHTMLContent';
import ClaimService from '../../../service/ClaimService';
import { t } from '../../../service/localization/i18n';
import { store } from '../../../store';
import { setToastMessage } from '../../core/actions/core.action.creators';

export const warnToastBasicLifeTime = 25000;

export const commonToast = (ref, message) => {
  let basicTitile;
  let basicLifeTime;
  switch (message?.type) {
    case 'success':
      basicTitile = t('Success');
      basicLifeTime = 10000;
      break;

    case 'warn':
      basicTitile = t('Warning');
      basicLifeTime = warnToastBasicLifeTime;
      break;

    case 'info':
      basicTitile = t('Info');
      basicLifeTime = 20000;
      break;

    case 'error':
      basicTitile = t('Error');
      basicLifeTime = 60000;
      break;

    default:
      break;
  }

  ref.current.show({
    sticky: message?.lifeTime === 'sticky',
    life: message?.lifeTime ? message?.lifeTime : basicLifeTime,
    severity: message?.type,
    summary: message?.title ? message?.title : basicTitile,

    detail: typeof message?.message === 'string' ? <SafeHtmlContent htmlContent={message?.message} /> : message?.message
  });
};

export const dataExportToast = (data) => {
  const persitsReport = async (fileName) => {
    const result = await ClaimService.shareReport(fileName);
    if (result) store?.dispatch(setToastMessage({ type: 'success', lifeTime: 10000, message: 'Report shared', position: 'top-right' }));
    return false;
  };

  return {
    type: 'info',
    title: 'You data export is ready',
    position: 'top-right',
    lifeTime: 100000,
    message: (
      <div>
        <a href={data.LinkToCsv} className="underline" target="_blank" rel="noreferrer">
          Report (CSV)
        </a>
        <br />
        <a href={data.LinkToXls} className="underline" target="_blank" rel="noreferrer">
          Report (Excel)
        </a>
        <br />
        <Button
          className="p-0 underline"
          link
          onClick={(event) => {
            event.preventDefault();
            persitsReport(data.LinkToShare);
          }}
        >
          Share on <strong>Daysheet Uploads</strong>
        </Button>
      </div>
    )
  };
};
