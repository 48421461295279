import { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { referralCreditsDefaultSortBy } from '../../helpers/defaultValues';
import TableCard from '../../../../components/Wraps/PageWrap/TableCard';
import ReferralSocialSharing from '../ReferralSocialSharing';
import CreditRowTemplate from './CreditRowTemplate';
import CreditStatusRowTemplate from './CreditStatusRowTemplate';
import ReferredStatusRowTemplate from './ReferredStatusRowTemplate';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';

const ReferralCreditsTable = ({ data, loading, fetchData }) => {
  const dt = useRef(null);

  const [sortField, setSortField] = useState(referralCreditsDefaultSortBy.sortField);
  const [sortOrder, setSortOrder] = useState(referralCreditsDefaultSortBy.sortOrder);
  const [selectedRow, setSelectedRow] = useState(referralCreditsDefaultSortBy.sortOrder);

  const onPage = (e) => {
    const currentPage = (e.page ?? 0) + 1;
    fetchData({ page: currentPage, pageSize: data?.pageSize, sortby: data?.sortby, query: data?.query });
  };

  const onSort = (e) => {
    if (e.sortField === null) {
      setSortField(referralCreditsDefaultSortBy.sortField);
    } else {
      setSortField(e.sortField);
    }
    setSortOrder(e.sortOrder);
    fetchData({ page: 1, pageSize: data?.pageSize, query: data?.query, sortby: `${e.sortField}_${e.sortOrder === 1 ? 'asc' : 'desc'}` });
  };

  return (
    <TableCard height={'calc(100vh - 140px)'}>
      {loading && <CircularProgress />}
      <DataTable
        lazy
        ref={dt}
        value={data?.userList || []}
        totalRecords={data?.totalRecords || 0}
        scrollable
        onSort={onSort}
        onPage={onPage}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate={`${data?.first || 0} to ${data?.last || 0} of {totalRecords}`}
        scrollHeight="flex"
        filterDisplay="menu"
        paginator
        selectionMode="single"
        selection={selectedRow}
        onSelectionChange={(e) => setSelectedRow(e.value)}
        header={<ReferralSocialSharing />}
        first={data?.first}
        rows={20}
        // loading={loading}
        sortOrder={sortOrder}
        sortField={sortField}
        pt={{
          header: {
            className: 'md:pb-0'
          }
        }}
      >
        <Column
          // sortable // Sorting not supported atm
          field="FirstName"
          header="Name"
          style={{ minWidth: '250px', maxWidth: '250px' }}
          body={(rowData) => {
            const first = rowData?.FirstName || '';
            const last = rowData?.LastName || '';
            return `${first} ${last}`;
          }}
        />
        <Column
          // sortable // Sorting not supported atm
          field="CreditStatus"
          header="Credit Status"
          style={{ minWidth: '140px', maxWidth: '140px' }}
          body={(rowData) => <CreditStatusRowTemplate rowData={rowData} />}
        />
        <Column
          // sortable // Sorting not supported atm
          field="Credit"
          header="Credits"
          style={{ minWidth: '140px', maxWidth: '140px' }}
          body={(rowData) => <CreditRowTemplate rowData={rowData} />}
        />
        <Column
          // sortable // Sorting not supported atm
          // previous field="ReferredStatus"
          field="CreditType"
          header="Type"
          style={{ minWidth: '140px', maxWidth: '140px' }}
          body={(rowData) => <ReferredStatusRowTemplate rowData={rowData} />}
        />
      </DataTable>
    </TableCard>
  );
};

export default ReferralCreditsTable;
