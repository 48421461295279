import React from 'react';
import EmergencyTooltipLayout from '../Layouts/EmergencyTooltipLayout';
import TravelTooltipLayout from '../Layouts/TravelTooltipLayout';
import CCFPPTooltipLayout from '../Layouts/CCFPPTooltipLayout';
import { Dialog } from 'primereact/dialog';
import { useFormContext } from 'react-hook-form';
import { Button } from 'primereact/button';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';
import { Divider } from 'primereact/divider';

const OOFHPDescriptionDialog = () => {
  const { localState, setLocalState } = useFormContext();

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      oofhpDescriptionDialog: false
    }));
  };

  const footer = () => {
    return <Button id={elementIDs.dialogClose} label={t('Close')} onClick={onHide} autoFocus />;
  };

  return (
    <Dialog header="Description" visible={localState.oofhpDescriptionDialog} style={{ maxWidth: '500px' }} footer={footer()} onHide={() => onHide()}>
      <div className="flex flex-column">
        <div>
          <Divider align="center">
            <div className="inline-flex align-items-center">
              <b className="text-xl">OOFHP</b>
            </div>
          </Divider>
          <EmergencyTooltipLayout />
        </div>

        <div>
          <Divider align="center">
            <div className="inline-flex align-items-center">
              <b className="text-xl">Call Out</b>
            </div>
          </Divider>
          <TravelTooltipLayout />
        </div>

        <div>
          <Divider align="center">
            <div className="inline-flex align-items-center">
              <b className="text-xl">CCFPP</b>
            </div>
          </Divider>
          <CCFPPTooltipLayout />
        </div>
      </div>
    </Dialog>
  );
};

export default OOFHPDescriptionDialog;
