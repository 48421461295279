import React, { useState } from 'react';
import PayeeAddress from '../../../../../components/Inputs/Payee/PayeeAddress';
import { usePrivateInvoicePrefs } from '../hooks/usePrivateInvoicePrefs';

const PrivateInvoiceAddress = () => {
  const { payeePrefsAddress } = usePrivateInvoicePrefs();
  const [value, setValue] = useState(payeePrefsAddress);

  const onChange = (e) => {
    setValue(e.value);
  };

  return <PayeeAddress value={value} savePrefsOnChange onChange={onChange} />;
};

export default PrivateInvoiceAddress;
