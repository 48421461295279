import { store } from '../../../../../store';
import { inputs } from './inputs';
import { NA_DoctorGuid, defaultGuid, serviceDefaultValues } from '../../../../config/defaultValuesConfig';
import { getServiceDate } from '../../../../utils/getDate';
import { find, isEmpty } from 'lodash';
import {
  getInitPractitionerGuid,
  getPractitionerByGuid,
  getPractitionerIdFromUserPrefs,
  getPractitionersList
} from '../../../../utils/getPractitioner';
import { getPresetPrefs } from '../../../helpers/getDefaultPrefs';
import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { t } from '../../../../../service/localization/i18n';

export const serviceInitValues = (service = {}) => {
  const initPractitionerGuid = getPractitionerIdFromUserPrefs();

  // Default values
  if (isEmpty(service)) {
    return {
      ...serviceDefaultValues,
      doctorGuid: initPractitionerGuid,
      lessMSP: 0
    };
  }

  // Prefilled values
  return {
    ...serviceDefaultValues,
    ...service,
    doctorGuid: initPractitionerGuid,
    [inputs.amount.name]: service[inputs.amount.name] || 0,
    [inputs.lessMSP.name]: service[inputs.amount.name] || 0
  };
};

export const getDefaultValues = ({ initValues = {}, speciality, practitionerPrefs } = {}) => {
  const clinic = store.getState().clinic.details;
  const practitioners = store.getState().clinic.members;
  const services = initValues?.[inputs.services.name];
  const payee = initValues?.[inputs.payee.name];
  const practitionerId = initValues[inputs.practitioner.name];
  const serviceInit = serviceInitValues();
  const initServices = services && Array.isArray(services) ? services : [serviceInit];
  const initPractitionerGuid = initPractitionerId(practitionerId);
  const currentPractitioner = find(practitioners, (i) => i.DoctorGuid?.toUpperCase() === initPractitionerGuid?.toUpperCase());
  const currentSpeciality = speciality ?? Number(currentPractitioner?.Speciality);

  const commonValues = {
    [inputs.dcnguid.name]: clinic.DCNGuid,
    [inputs.status.name]: 0,

    [inputs.practitioner.name]: initPractitionerGuid,
    [inputs.speciality.name]: currentSpeciality,

    [inputs.patient.name]: [],
    [inputs.serviceDate.name]: serviseDateInit(),
    [inputs.location.name]: emptyLocation,
    [inputs.payee.name]: initPayee({ practitionerPrefs, payee }),
    [inputs.primaryPolicy.name]: {},
    [inputs.secondaryPolicy.name]: {},

    [inputs.services.name]: initServices,

    [inputs.accidentType.name]: 'N/A',
    [inputs.accidentDate.name]: null,
    [inputs.prescriberType.name]: 'N/A',
    [inputs.firstName.name]: '',
    [inputs.lastName.name]: '',
    [inputs.authorizationForm.name]: false,
    [inputs.termsAndConditions.name]: false,
    [inputs.sendNow.name]: false
  };

  return { ...commonValues, ...initValues };
};

export const emptyLocation = { LocationName: t('N_A'), RowGuid: defaultGuid };

export const initPractitionerId = (id) => {
  if (id) return id;

  const initPractitionerGuid = getInitPractitionerGuid();
  const practitioner = getPractitionerByGuid(initPractitionerGuid, { naOption: true });
  const isTEC = practitioner?.canBillTEC;
  const isNA = initPractitionerGuid === NA_DoctorGuid;

  // CMO-3243 - [eClaims] do not display doctors in eClaims practitioner field
  if (isTEC || isNA) {
    return initPractitionerGuid;
  } else {
    const practitioenrs = getPractitionersList({ naOption: true });
    const canBillTECPractioner = practitioenrs.find((i) => i.canBillTEC);
    return canBillTECPractioner ? canBillTECPractioner.DoctorGuid : NA_DoctorGuid;
  }
};

export const initPayee = ({ practitionerPrefs, payee }) => {
  if (payee) return payee;
  const prefs = practitionerPrefs?.content;
  const defaultValue = 'N/A';
  const prefsValue = getPresetPrefs(prefs, prefsCodes.eClaimPayTo);
  return prefsValue || defaultValue;
};

const serviseDateInit = () => {
  const date = getServiceDate();
  return [date];
};

export const generateServiceValue = (service) => {
  return `${service.category?.toLowerCase()}_${service.label?.toLowerCase()}_${service.text?.toLowerCase()}`;
};
