import React, { useContext } from 'react';
import AddressLayout from '../../../../components/Layouts/AddressLayout';
import EditPayToAddress from './EditPayToAddress';
import Context from '../../../../Context';
import { inputs } from './claimDetailsHelpers/inputs';
import { getPractitionerByGuid } from '../../../utils/getPractitioner';
import { patientFullName } from '../../../utils/patientFullName';

const PayToAddress = ({ showAddressDialog, setShowAddressDialog }) => {
  const { privateRecord } = useContext(Context);
  const payeeInfo = privateRecord[inputs().payeeInfo.name];

  const getData = (name) => {
    return payeeInfo?.find((i) => i.id === name)?.label;
  };

  const payeeGuid = payeeInfo?.find((i) => i.id === 'payee')?.value;
  const payee = getPractitionerByGuid(payeeGuid);
  const address = getData('address');
  const email = getData('email');
  const phone = getData('phone');

  const parts = address?.split(',')?.map((part) => part.trim());

  // Map the parts back to the object
  const addressData = {
    ...payee,
    AddressLine: parts?.[0] || undefined,
    City: parts?.[1] || undefined,
    Province: parts?.[2] || undefined,
    Zip: parts?.[3] || undefined,
    Email: email,
    Phone: phone
  };

  return (
    <div className="flex flex-column line-height-3">
      <div className="flex col-12 p-0 m-0">{patientFullName(addressData)}</div>
      {addressData?.Comment && <div className="m-0">{addressData?.Comment}</div>}
      {addressData?.Facility && <div className="p-0 m-0">{addressData?.Facility}</div>}
      {addressData?.CompanyName && <div className="p-0 m-0">{addressData?.CompanyName}</div>}
      <AddressLayout data={addressData} />
      <EditPayToAddress showAddressDialog={showAddressDialog} setShowAddressDialog={setShowAddressDialog} />
    </div>
  );
};

export default PayToAddress;
