import React from 'react';
import { useFormContext } from 'react-hook-form';
import { PractitionerWithFormControl } from '../../../../../../../components/Inputs';
import { NA_DoctorGuid } from '../../../../../../config/defaultValuesConfig';
import { usePractitioner } from '../../../hooks/usePractitioner';
import { inputs } from '../../../helpers/inputs';

const Practitioner = () => {
  const { control, isNew, watch, firstVisit } = useFormContext();
  const { onChangePractitioner, onBlur, onSelect } = usePractitioner();
  const currentPractitionerGuid = watch(inputs.practitioner.name);

  return (
    <PractitionerWithFormControl
      control={control}
      naOption={isNew}
      registeredOnly
      autoFocus={!firstVisit && currentPractitionerGuid === NA_DoctorGuid}
      onChange={(e, field) => onChangePractitioner(e, field)}
      onSelect={onSelect}
      onBlur={onBlur}
    />
  );
};

export default Practitioner;
