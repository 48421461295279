import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { t } from './service/localization/i18n';

const Cookies = () => {
  if (process.env.REACT_APP_CYPRESS) return null;

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="claimCookieConsent"
      style={{ background: '#333' }}
      buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
      expires={150}
    >
      {t('Cookies_text')}
    </CookieConsent>
  );
};

export default Cookies;
