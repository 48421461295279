import { defaultProvinceCode } from '../../../config/defaultValuesConfig';

export const practitionerDefaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  repeatEmail: '',
  phoneNumber: '',
  practitionerType: '',
  CollegeLicenceNumber: '',
  licensingBody: '',
  practiceType: [],
  streetAddress: '',
  city: '',
  Province: defaultProvinceCode,
  Zip: '',
  mspPractitionerNumber: '',
  mspPayeeNumber: '',
  teleplanDataCenterNumber: '',
  hearAboutUs: '',
  password: '',
  confirmPassword: '',
  userType: 'Practitioner',
  emailVerified: false,
  phoneVerified: false,
  termsAccepted: false
};
