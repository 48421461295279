import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';
import { InputWrapper } from '../../../../components/Inputs';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

import { backdoor } from '../../actions/auth.actions.creators';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';
import { useAuth } from '../../../../AuthProvider';
import { getAdminUrlParams } from '../../../utils/getLink';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { store } from '../../../../store';

const Admin = () => {
  const { login } = useAuth();
  const location = useLocation();
  const [params, setParams] = useState({});

  const { control, formState, handleSubmit, reset, setError } = useForm({
    defaultValues: { password: '', adminCode: '' }
  });

  useEffect(() => {
    const extractedParams = getAdminUrlParams(location.search);
    setParams(extractedParams);

    if (extractedParams?.AdminCode) {
      reset({ adminCode: extractedParams.AdminCode, password: '' });
    }
  }, [location.search]);

  const passwordErrorMessage = formState.errors?.password?.message;

  const onSubmit = async (data) => {
    try {
      const payload = { ...params, Password: data.password };
      const user = await backdoor(payload);

      login(user);
    } catch (error) {
      console.error('!!!Error during backdoor logining', error);
      if (error === 403) {
        setError('password', {
          type: 'manual',
          message: 'You entered an incorrect password. Try again.'
        });
        return;
      }
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    }
  };

  return (
    <AuthFormWrap>
      <form onSubmit={handleSubmit(onSubmit)} name="Admin">
        <div className="flex flex-column gap-3">
          <Controller
            name="adminCode"
            control={control}
            render={({ field }) => (
              <InputWrapper className="h-auto" name="adminCode" label="Temporary Code">
                <InputText {...field} alt="Temporary Code" type="text" id={elementIDs.usernameInput} disabled />
              </InputWrapper>
            )}
          />

          <Controller
            name="password"
            control={control}
            rules={{ required: t('Mandatory_field.1') }}
            render={({ field }) => (
              <InputWrapper className="h-auto" name="password" label="Admin Password" errorMessage={passwordErrorMessage}>
                <Password
                  className={passwordErrorMessage ? 'p-invalid' : ''}
                  inputClassName="w-full"
                  id={elementIDs.passwordInput}
                  value={field.value}
                  alt="Admin Password"
                  inputRef={field.ref}
                  feedback={false}
                  toggleMask
                  onChange={(e) => field.onChange(e)}
                />
              </InputWrapper>
            )}
          />

          <Button id={elementIDs.logInButton} type="submit" className="h-3rem mt-3" label={t('Login')} loading={formState.isSubmitting} />
        </div>
      </form>
    </AuthFormWrap>
  );
};

export default Admin;
