import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { setShowUpdateAppDialog } from '../../../modules/core/actions/core.action.creators';

const ApplicationUpdateDialog = () => {
  const dispatch = useDispatch();
  const { showUpdateAppDialog } = useSelector((state) => state.core);

  const onAccept = () => {
    dispatch(setShowUpdateAppDialog(false));
    window.location.reload();
  };

  const footer = <Button label="OK" onClick={onAccept} />;

  return (
    <Dialog header="Application Update" footer={footer} visible={showUpdateAppDialog} style={{ width: '500px' }} onHide={onAccept}>
      <span>Your application will be updated to reflect the recent changes. You may be logged out temporarily to apply these updates.</span>
    </Dialog>
  );
};

export default ApplicationUpdateDialog;
