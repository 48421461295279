import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getLegacyUrlParams } from '../utils/getLink';
import withAuthCondition from './withAuthCondition';

const withRedirectLegacy = (Component) => {
  return withAuthCondition(Component, () => {
    const location = useLocation();
    const params = getLegacyUrlParams(location.search);
    return Boolean(params?.DCNGuid && params?.UserGuid && params?.key && params?.source);
  });
};
export default withRedirectLegacy;
