import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import EClaimCatalogs from '../EClaimCatalogs/Catalogs';
import { localStateInitValues } from '../../helpers/initialState';

const CatalogsDialog = () => {
  const { localState, setLocalState } = useFormContext();

  const onHide = () => {
    setLocalState((prevState) => ({ ...prevState, catalogsDialog: localStateInitValues.catalogsDialog }));
  };

  return (
    <Dialog
      className="eclaim_catalogs_dialog"
      showHeader={false}
      visible={localState.catalogsDialog.showDialog}
      style={{ width: '50vw', height: '100%' }}
      breakpoints={{ '1600px': '60vw', '1366px': '70vw', '1024px': '95vw' }}
      baseZIndex={1000000}
      dismissableMask
      onHide={onHide}
    >
      <EClaimCatalogs />
    </Dialog>
  );
};

export default CatalogsDialog;
