import { defaultProvinceCode } from '../../../config/defaultValuesConfig';

export const clinicDefaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  repeatEmail: '',
  phoneNumber: '',
  clinicRole: [],
  practitionerName: '',
  clinicType: '',
  clinicName: '',
  streetAddress: '',
  city: '',
  Province: defaultProvinceCode,
  Zip: '',
  clinicWebsite: '',
  clinicBusinessNumber: '',
  teleplanDataCenterNumber: '',
  hearAboutUs: '',
  password: '',
  confirmPassword: '',
  attachedPractitioners: [],
  userType: 'Clinic',
  emailVerified: false,
  phoneVerified: false,
  termsAccepted: false
};
