import { store } from '../../../../../store';
import { updatePrivateRecord } from '../../../actions/claims.action.creators';
import { inputs } from './inputs';

export const onPayToUpdate = (data) => {
  store.dispatch(updatePrivateRecord(data));
};

export const onBillToUpdate = (value) => {
  store.dispatch(updatePrivateRecord({ [inputs().billTo.name]: value || [] }));
};
