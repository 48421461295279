import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputWrapper } from '..';
import { Controller } from 'react-hook-form';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { t } from '../../../service/localization/i18n';
import { physicianCollegesList } from '../../../modules/registration/components/helpers/physicianCollegesList';

import { useSelector } from 'react-redux';

// interface = {
//   name: string
// }

// this Component should be used only by useFormContext and wrapped in react-hoock-form <FormProvider> for gettig of useFormContext

const LicencingsBodies = ({ name }) => {
  const ref = React.createRef();
  const licencingsBodiesList = useSelector((state) => state.user.licencingsBodiesList);

  const [isSuggestionsFound, setIsSuggestionsFound] = useState(true);
  const formatedLicencingsBodiesList = () => licencingsBodiesList?.map((item) => item.label);

  const {
    control,
    formState: { errors },
    trigger,
    clearErrors,
    watch
  } = useFormContext();

  const collegesList = () => physicianCollegesList.map((item) => item);
  const phCollegesList = collegesList();
  const practitionerType = watch('practitionerType');
  const isPhysician = practitionerType === 'Physician';
  const defaultLicencingsBodiesList = isPhysician ? phCollegesList : formatedLicencingsBodiesList();
  const [suggestions, setSuggestions] = useState(defaultLicencingsBodiesList);

  const onSearch = (event) => {
    if (event.query.trim() === '') {
      setIsSuggestionsFound(true);
      isPhysician ? setSuggestions(defaultLicencingsBodiesList) : setSuggestions(formatedLicencingsBodiesList);
    } else {
      const filtered = defaultLicencingsBodiesList.filter((item) => {
        return item.toLowerCase().includes(event.query.toLowerCase());
      });
      if (filtered.length) {
        !isSuggestionsFound && setIsSuggestionsFound(true);
      } else {
        isSuggestionsFound && setIsSuggestionsFound(false);
      }
      setSuggestions(filtered);
    }
  };

  return (
    <>
      <InputWrapper name="licensingBody" label="Licensing Body" required errorMessage={errors.licensingBody?.message}>
        <Controller
          name="licensingBody"
          control={control}
          rules={{ required: 'Licensing Body is required' }}
          render={({ field }) => (
            <AutoComplete
              ref={ref}
              data-testid="licensingBody-testid"
              inputRef={field.ref}
              value={field.value}
              suggestions={suggestions}
              dropdown
              forceSelection
              completeMethod={onSearch}
              className={classNames({ 'p-invalid': errors.licensingBody })}
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(value);
                clearErrors(name);
                !value && !isSuggestionsFound && setIsSuggestionsFound(true);
              }}
              onFocus={(e) => {
                ref.current?.search(e, '', 'dropdown');
              }}
              onBlur={() => trigger(name)}
            />
          )}
        />
        {!isSuggestionsFound && <span className="p-error text-sm">{t('No_results_found')}</span>}
      </InputWrapper>
    </>
  );
};

export default LicencingsBodies;
