export const physicianCollegesList = [
  'College of Physicians and Surgeons of British Columbia (CPSBC)',
  'College of Physicians and Surgeons of Alberta (CPSA)',
  'College of Physicians and Surgeons of Saskatchewan (CPSS)',
  'College of Physicians and Surgeons of Manitoba (CPSM)',
  'College of Physicians and Surgeons of Ontario (CPSO)',
  'Collège des médecins du Québec (CMQ)',
  'College of Physicians and Surgeons of New Brunswick (CPSNB)',
  'College of Physicians and Surgeons of Nova Scotia (CPSNS)',
  'College of Physicians and Surgeons of Prince Edward Island (CPSPEI)',
  'College of Physicians and Surgeons of Newfoundland and Labrador (CPSNL)',
  'Yukon Medical Council',
  'Northwest Territories Medical Association',
  'Nunavut Medical Registration',
  'Other'
];
