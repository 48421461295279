import React from 'react';
import { useSelector } from 'react-redux';
import PrivacyDeny from '../../../../components/Privacy/PrivacyDeny';

const RegisterPrivacyDeny = () => {
  const user = useSelector((state) => state.registration.registrationFormState);
  const firstName = user.initialFormState.firstName;
  const lastName = user.initialFormState.lastName;
  const userName = `${firstName} ${lastName}`;
  const email = user.initialFormState.email;

  return <PrivacyDeny userName={userName} email={email} />;
};

export default RegisterPrivacyDeny;
