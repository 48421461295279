import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import TablePaginator from '../../../components/Paginators/TablePaginator';
import CircularProgress from '../../../components/Misc/Loader/CircularProgress/CircularProgress';
import PreviewDialog from './PreviewDialog';
import RemittancesDialog from './RemittancesDialog';
import { formatDatewithMinutes } from '../../utils/formatDate';
import { getRemittanceLinkWithRow, getRemittanceLinkByType } from '../../utils/getLink';
import { currencyFormat } from '../../utils/currencyFormat';
import { updateMessageStatus } from '../../claims/actions/claims.action.creators';

import '../../mails/components/NoteTable/NoteTable.scss';

const RemTable = ({ remittances, page, pageSize, totalRecords, onPageChange, loading, fetchData }) => {
  const [selected, setSelectedRow] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');

  const first = (page - 1) * pageSize;

  const rowClassName = (rowData) => {
    return {
      'font-bold': rowData.Status === '0',
      'cursor-pointer': true
    };
  };

  const dateBodyTemplate = (remittance) => {
    return (
      <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
        {formatDatewithMinutes(remittance?.SDate)}
      </div>
    );
  };

  const refusedBodyTemplate = (remittance) => {
    return (
      <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
        {remittance?.NumC}
      </div>
    );
  };

  const acceptedBodyTemplate = (remittance) => {
    return (
      <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
        {remittance?.NumS}
      </div>
    );
  };

  const holdBodyTemplate = (remittance) => {
    return (
      <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
        {remittance?.NumH}
      </div>
    );
  };

  const checkBodyTemplate = (remittance) => {
    return (
      <div className="w-full flex justify-content-end py-3" style={{ height: '55px', paddingRight: '4em', textAlign: 'right' }}>
        {currencyFormat(remittance?.CheckAmount)}
      </div>
    );
  };

  const commentsBodyTemplate = (remittance) => {
    return (
      <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
        {remittance?.Description}
      </div>
    );
  };

  const onRemmittanceReportClick = async (url, remittance) => {
    await updateMessageStatus({ ...remittance, Status: 1 });
    setPreviewUrl(url);
    setPreviewVisible(true);
    await fetchData(page, pageSize);
  };

  const viewBodyTemplate = (remittance) => {
    const url = getRemittanceLinkWithRow(remittance, privacy);
    return (
      <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded"
          tooltip="Remittance report in Teleplan format"
          tooltipOptions={{ position: 'top' }}
          onClick={() => onRemmittanceReportClick(url, remittance)}
        />
      </div>
    );
  };

  const filterBodyTemplate = (remittance) => {
    return (
      <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
        <RemittancesDialog remittance={remittance} privacy={privacy} />
      </div>
    );
  };

  const typeBodyTemplate = (remittance) => {
    const url = getRemittanceLinkByType(remittance, privacy);
    return (
      <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded"
          tooltip="Remittance report in Teleplan format"
          tooltipOptions={{ position: 'top' }}
          onClick={() => onRemmittanceReportClick(url, remittance)}
        />
      </div>
    );
  };

  return (
    <div className="memos-table-wrap">
      {loading && <CircularProgress />}
      <DataTable
        lazy
        value={remittances}
        tableStyle={{ minWidth: '50rem' }}
        size="small"
        scrollable
        scrollHeight="flex"
        rows={pageSize}
        first={first}
        filterDisplay="menu"
        selectionMode="single"
        selection={selected}
        onSelectionChange={(e) => setSelectedRow(e.value)}
        rowClassName={rowClassName}
        emptyMessage={!remittances.length && loading ? 'Loading...' : 'No remittances found'}
        footer={
          <TablePaginator
            first={first || 0}
            rows={pageSize || 0}
            totalRecords={totalRecords || 0}
            onPageChange={onPageChange}
            leftContent={<></>}
            rightContent={
              <div className="flex align-items-center gap-3">
                <label htmlFor="privacy">Privacy</label>
                <InputSwitch inputId="privacy" checked={privacy} onChange={(e) => setPrivacy(e.value)} />
              </div>
            }
          />
        }
      >
        <Column
          className="memos-column-content"
          field="Date"
          header="Date"
          style={{ minWidth: '150px', maxWidth: '150px' }}
          body={(remittance) => dateBodyTemplate(remittance)}
        />

        <Column
          className="memos-column-content"
          field="Refused"
          style={{ minWidth: '80px', maxWidth: '80px' }}
          body={(remittance) => refusedBodyTemplate(remittance)}
          header="Refused"
        />
        <Column
          className="memos-column-content"
          field="Accepted"
          style={{ minWidth: '80px', maxWidth: '80px' }}
          body={(remittance) => acceptedBodyTemplate(remittance)}
          header="Accepted"
        />
        <Column
          className="memos-column-content"
          field="On Hold"
          style={{ minWidth: '80px', maxWidth: '80px' }}
          body={(remittance) => holdBodyTemplate(remittance)}
          header="On Hold"
        />
        <Column
          className="memos-column-content"
          field="Check"
          style={{ minWidth: '120px', maxWidth: '120px', textAlign: 'right', paddingLeft: '2em' }}
          body={(remittance) => checkBodyTemplate(remittance)}
          header="Check"
        />
        <Column
          className="memos-column-content"
          field="Comments"
          style={{ minWidth: '200px', maxWidth: '200px' }}
          body={(remittance) => commentsBodyTemplate(remittance)}
          header="Comments"
        />
        <Column
          className="memos-column-content"
          field="Original"
          style={{ minWidth: '80px', maxWidth: '80px' }}
          body={(remittance) => viewBodyTemplate(remittance)}
          header="Original"
        />
        <Column
          className="memos-column-content"
          field="By Provider"
          style={{ minWidth: '80px', maxWidth: '80px' }}
          body={(remittance) => filterBodyTemplate(remittance)}
          header="By Provider"
        />
        <Column
          className="memos-column-content"
          field="By Status"
          style={{ minWidth: '80px', maxWidth: '80px' }}
          body={(remittance) => typeBodyTemplate(remittance)}
          header="By Status"
        />
      </DataTable>
      {/* Preview Dialog */}
      <PreviewDialog url={previewUrl} visible={previewVisible} onHide={() => setPreviewVisible(false)} />
    </div>
  );
};

export default RemTable;
