import React, { useMemo } from 'react';
import RepresentativeForm from '../../../../components/Widgets/Representative/index';

const Representative = ({ patient, representative, onClose, onSuccessCreate, onSuccessEdit }) => {
  const initialState = useMemo(() => {
    if (representative?.RepresentativeGuid) {
      return {
        ...representative,
        formState: {
          isNew: false
        }
      };
    } else return undefined;
  }, [representative]);

  return (
    <RepresentativeForm
      patient={patient}
      initialState={initialState}
      onSuccessCreate={onSuccessCreate}
      onSuccessEdit={onSuccessEdit}
      onClose={onClose}
      autoFocus
    />
  );
};

export default Representative;
