import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import { HubConnectionBuilder } from "@microsoft/signalr";

import { Chip } from 'primereact/chip';
import { Skeleton } from 'primereact/skeleton';
import { Patient } from '../../../../../../../components/Inputs';

import { setShowNewPatientSuccessPage } from '../../../../../../patients/actions/patients.action.creators';
import { setPatientPickerQueryValue, updateTeleplanInvoice } from '../../../../../actions/claims.action.creators';
import { inputs } from '../../../helpers/inputs';
import { routes } from '../../../../../../../routes/routes';
import { usePatient } from '../../../hooks/usePatient';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { uniqBy } from 'lodash';
import { patientFullNameWithAge } from '../../../../../../utils/patientFullName';

const PatientPicker = ({ hideLabel, hideSelectedValues = false, inputWrapStyles, onChange }) => {
  const name = inputs.patient.name;
  const dispatch = useDispatch();
  const history = useHistory();
  const { gettingWSBCReport } = useSelector((state) => state.claims);
  const { isMobile } = useSelector((state) => state.core.window);
  const { control, errors, watch, isNew, firstVisit, localState, setLocalState } = useFormContext();
  const { onPatientChange } = usePatient();
  const DoctorGuid = useSelector((state) => state.user.details.DoctorGuid);

  const userId = DoctorGuid;


  // useEffect(() => {
  //   // Set up SignalR connection
  //   const connection = new HubConnectionBuilder()
  //     .withUrl("http://localhost:5139/syncHub") // Adjust URL as needed
  //     .withAutomaticReconnect()
  //     .configureLogging("debug")
  //     .build();

  //   connection
  //     .start()
  //     .then(() => {
  //       console.log("Connected to SignalR for PatientPicker");

  //       // Register the user ID with the backend
  //       connection.invoke("Register", userId)
  //         .then(() => console.log("User registered:", userId))
  //         .catch(err => console.error("Failed to register user:", err));
  //     })
  //     .catch(err => console.error("Error connecting to SignalR:", err));

      
  //   connection.on("OpenBatchScreen", (patientsList) => {
  //     console.log("OpenBatchScreen event received in PatientPicker:", patientsList);
  //     if (patientsList && patientsList.length > 0) {
  //       const currentPatients = watch(inputs.patient.name) || []; // Get the current patients
  //       const newPatientsList = [...currentPatients, ...patientsList]; // Append the new patients
  //       const uniquePatientsList = uniqBy(newPatientsList, inputs.patientGuid.name); // Ensure uniqueness
    
  //       handleChange(uniquePatientsList); // Pass the updated list to handleChange
  //     }    
  //   });

  //   return () => {
  //     connection.stop().then(() => console.log("SignalR connection stopped"));
  //   };
  // }, []); // Empty dependency array to ensure it only runs once


  const handleChange = (patients) => {
    let value = [];
    console.log('Patients:', patients);
    if (Array.isArray(patients)) {
      if (hideSelectedValues) value = [...watch(inputs.patient.name), ...patients];
      if (!hideSelectedValues) value = patients;
    } else {
      value = [...watch(inputs.patient.name), patients];
    }

    // Do not allow to add duplicate patients because of:
    // When a user adds the same patient from the teleplan group on any step except the first one (e.g., step 2 screen), the system duplicates the patient in the PatientPicker on the first step, leading to various issues.
    // Also by default primereact AutoComplete is prevents to add duplicate items. But in the case, that described upper it is possible, because we use PatientPicker as search and do not display selected itemd in the PatientPicker. Therefore primereact AutoComplete "think" that we didnt add any patient and allows to add same patient again.
    const uniquePatientsList = uniqBy(value, inputs.patientGuid.name);

    onChange && onChange(uniquePatientsList);

    // const value = Array.isArray(patients) ? patients : [...watch(inputs.patient.name), patients];
    onPatientChange(uniquePatientsList);
  };

  const onAddClick = (e) => {
    dispatch(setPatientPickerQueryValue(e.query)); // CMO-1365 - New Teleplan claim->Patient field->type in PHN->No matches->Carry PHN from Patient field into PHN field on the Add patient screen

    setLocalState((prevState) => ({ ...prevState, resetTeleplanInvoice: false })); // CMO-2317 - Cach is not cleaned on new Teleplan when the new patient is created from that screen

    dispatch(updateTeleplanInvoice({ formState: watch(), localState: { ...localState, resetTeleplanInvoice: false } }));
    dispatch(setShowNewPatientSuccessPage({ showSuccessPage: false, from: 'teleplanClaim' }));
    history.push(`${routes.createPatient.path}/new`);
  };

  const editLayout = (field) => {
    if (gettingWSBCReport) return <Skeleton width="100%" height="1.4rem" />;
    const patientName = field.value?.length ? patientFullNameWithAge(field.value?.[0]) : '';
    if (!patientName) return null;

    return (
      <div id={`${elementIDs.patientChip}_${field.value[0].PatientGuid?.toUpperCase()?.replaceAll('-', '')}`}>
        <Chip className="mr-2" label={patientName} removable={isNew} icon="pi pi-user" />
      </div>
    );
  };

  const patientInput = (field) => {
    if (!isNew) {
      return (
        <div id={field.name} className="pb-4 pt-2 font-bold text-lg">
          {editLayout(field)}
        </div>
      );
    }

    return (
      <Patient
        name={field.name}
        ref={field.ref}
        required
        multiple
        hideLabel={hideLabel}
        forceSelection={false}
        autoFocus={!firstVisit && !isMobile}
        disabled={!isNew}
        showAddPatientButton={isNew}
        isInvalid={!!errors[name]?.message}
        errorMessage={errors[name]?.message}
        value={hideSelectedValues ? [] : field.value}
        onChange={(e) => handleChange(e.value)}
        onAddClick={onAddClick} // Comment to show dialog for adding new patient
        inputWrapStyles={inputWrapStyles}
      />
    );
  };

  if (firstVisit) return null;

  return <Controller name={name} control={control} render={({ field }) => patientInput(field)} />;
};

export default PatientPicker;
