import React, { useMemo } from 'react';
import Dropdown from '../../PrimeReactComponents/Dropdown';
import { countries } from '../../../modules/config/dropdownListsConfig';
import './index.scss';

/**
 * CountryPicker component renders a dropdown for selecting a country with flag icons.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.options - The list of country options to display in the dropdown.
 * @param {string} props.placeholder - The placeholder text to display when no country is selected.
 * @param {Function} props.onChange - The callback function to handle the change event when a country is selected.
 * @param {string} props.value - The currently selected country value. Example: 'CA'
 *
 * @returns {JSX.Element} The CountryPicker component.
 */
const CountryPicker = (props) => {
  const countryOptions = useMemo(() => countries, []);

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.label}
            src={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png`}
            className="mr-2"
            style={{ width: '18px', height: '12px' }}
            onError={(e) => (e.target.style.display = 'none')} // Hide image if not found
          />
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => (
    <div className="flex align-items-center" style={{ width: '330px' }}>
      <img
        alt={option.label}
        src={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png`}
        className="mr-2"
        style={{ width: '18px', height: '12px' }}
        onError={(e) => (e.target.style.display = 'none')} // Hide image if not found
      />
      <div>{option.label}</div>
    </div>
  );

  return (
    <Dropdown
      {...props}
      options={countryOptions}
      placeholder="Select a Country"
      filter
      valueTemplate={selectedCountryTemplate}
      itemTemplate={countryOptionTemplate}
    />
  );
};

export default CountryPicker;
