import React from 'react';

const TeleplanPrintMessageBody = ({ message }) => {
  // Prepare formatted message
  const messageLines = message?.trim()?.split('\n');
  // Utility function: Clean colons
  const cleanColons = (text) => text?.replace(/:\s+/g, ': ');
  // Utility function: Add line breaks
  const addLineBreaks = (lines) => lines?.map((line) => line.trim()).join('<br />');
  const formattedMessage = cleanColons(addLineBreaks(messageLines));

  return <p dangerouslySetInnerHTML={{ __html: formattedMessage }} />;
};

export default TeleplanPrintMessageBody;
