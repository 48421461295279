import cx from 'classnames';
import { isCodeRecentlyUsed } from '../utils/isCodeRecentlyUsed';
import { store } from '../../store';

export const colors = {
  success: '#689F38',
  successSecondary: '#7ccb37',
  info: '#2196F3',
  infoDark: '#123fd3',
  warning: '#FBC02D',
  help: '#9C27B0',
  danger: '#D32F2F',
  gray: '#a0a0a0',
  hoverGray: '#f5f5f5',
  customMarkerDisabled: '#a0a0a0',
  billed: '#4547a9'
};

export const statusColors = {
  '-1': colors.gray,
  '-3': colors.gray,
  '-4': colors.gray,
  0: colors.warning,
  1: colors.info,
  2: colors.success,
  3: colors.danger,
  4: colors.help,
  6: colors.danger,
  9: colors.gray,
  10: colors.danger,
  17: colors.danger,
  20: colors.gray,
  21: colors.successSecondary,
  27: colors.success,
  104: colors.customMarkerDisabled,
  200: colors.customMarkerDisabled,
  201: colors.info, // Info color for status 201
  202: colors.customMarkerDisabled,
  203: colors.infoDark, // Darker info color for status 203
  666: colors.success
};

export const catalogPatientChipsWrap = 50;
export const catalogSelectedCodesChipsWrap = 45;
export const quickPickChipCommonClass = 'p-button-rounded py-1 px-2';
export const quickPickCodeChipCommonClass = (isSelected, item) => {
  return cx(
    quickPickChipCommonClass,
    isSelected ? 'chipItemSelected' : isCodeRecentlyUsed(item?.order) ? 'p-button-outlined' : 'p-button-text',
    item?.isWeekend ? 'p-button-danger' : ''
  );
};

export const defaultLayoutMode = 'overlay'; //'overlay' or 'static'
export const mainHeaderHeight = 45; // should be equal with $mainHeader in styles/stylesVariables.scss
export const dropdownScrollHeight = () => {
  const clientHeight = window.innerHeight;

  if (clientHeight < 650) return 200;
  if (clientHeight < 700) return 250;
  if (clientHeight < 800) return 300;
  if (clientHeight < 900) return 350;

  return 450;
};

export const catalogsCodesTooltipOptions = (isMobile) => ({
  position: isMobile ? 'left' : 'top',
  showDelay: 1000,
  className: 'w-12rem lg:w-20rem'
});

export const icons = {
  debit: 'pi pi-replay',
  delete: 'pi pi-trash',
  info: 'pi pi-info-circle',
  warn: 'pi pi-exclamation-triangle',
  sortAmountDown: 'pi pi-sort-amount-down',
  ellipsisV: 'pi pi-ellipsis-v',
  claimViewDoctorIcon: 'pi pi-briefcase',
  edit: 'pi pi-pencil',
  history: 'pi pi-history',
  archived: 'pi pi-inbox',
  invoice: 'pi pi-file',
  teleplan: 'pi pi-credit-card',
  firstVisit: 'pi pi-download',
  print: 'pi pi-print',
  save: 'pi pi-save',
  private: 'pi pi-id-card',
  submitOutstandingInvoice: 'pi pi-send',
  updatePHN: 'pi pi-undo',
  link: 'pi pi-link',
  email: 'pi pi-envelope',
  add: 'pi pi-plus',

  //main nav menu
  dashboard: 'pi pi-home',
  overview: 'pi pi-desktop',
  schedule: 'pi pi-calendar-plus',
  patient: 'pi pi-user',
  dollar: 'pi pi-dollar',
  money: 'pi pi-money-bill',
  reports: 'pi pi-chart-line',
  charts: 'pi pi-chart-line',
  support: 'pi pi-whatsapp',
  stuff: 'pi pi-id-card',
  legacy: 'pi pi-external-link'
};
