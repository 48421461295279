import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Zip from './index';
import cx from 'classnames';
import { validateCanadianZip } from '../../../modules/regex/regex';
import { defaultCountryCode } from '../../../modules/config/defaultValuesConfig';
import { t } from '../../../service/localization/i18n';

/**
 * ControlledZip component for handling zip code input with validation.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.name='Zip'] - The name of the input field.
 * @param {boolean} [props.required=false] - Flag to indicate if the field is required.
 * @param {...Object} props - Additional properties passed to the component.
 *
 * @returns {JSX.Element} The ControlledZip component.
 *
 * @example
 * <ControlledZip name="PostalCode" required />
 *
 * @component
 */
const ControlledZip = ({ name = 'Zip', required = false, ...props }) => {
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext();

  const errorMessage = errors[name]?.message;
  const country = watch('Country') || defaultCountryCode;
  const province = watch('Province');

  const zipValidation = (zip) => {
    if (required && (!zip || zip.trim() === '')) {
      return t('Mandatory_field.1');
    }

    if (!zip || !province || province === 'N/A' || country !== defaultCountryCode) {
      return true; // Skip format validation if conditions are met
    }

    return validateCanadianZip.test(zip) ? true : 'Invalid postal code format. Expected format: A1A 1A1';
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: zipValidation
      }}
      render={({ field }) => <Zip {...field} {...props} id={name} country={country} className={cx({ 'p-invalid': errorMessage })} />}
    />
  );
};

export default ControlledZip;
