import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from '../../../config/enums';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';

import { useAuth } from '../../../../AuthProvider';
import { getAffiliates, loginAsAffiliate } from '../../actions/auth.actions.creators';
import { getSpeciality } from '../../../config/specialitiesConfig';
import { t } from '../../../../service/localization/i18n';
import { sessionStorageKeys } from '../../../config/localStorageKeysConfig';
import { getFromSessionStorage } from '../../../utils/getFromSessionStorage';
import { Button } from 'primereact/button';

const Affiliates = () => {
  const dispatch = useDispatch();
  const { login, logout } = useAuth();
  const [selectedRow, setSelectedRow] = useState(null);
  const [loginingAsAffiliate, setLoginingAsAffiliate] = useState(false);
  const [filters, setFilters] = useState({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const { affiliates, gettingAffiliates } = useSelector((state) => state.user);
  const emptyMessage = gettingAffiliates ? `${t('Loading')}...` : t('No_data_found');
  const bureauUser = getFromSessionStorage(sessionStorageKeys.bureauUser);

  // Track if a row was selected (without triggering re-renders)
  const rowSelectedRef = useRef(false);

  useEffect(() => {
    if (!affiliates || !affiliates?.length) {
      dispatch(getAffiliates(bureauUser));
    }

    // Clear session storage if no row was selected
    return () => {
      sessionStorage.removeItem(sessionStorageKeys.bureauUser);
    };
  }, []); // Only runs on mount & unmount

  const handleChangeUser = async (rowData) => {
    const params = {
      DCNGuid: rowData.DCNGuid,
      UserGuid: rowData.DoctorGuid,
      Key: rowData.ImpersonateKey,
      ServiceGuid: bureauUser.DCNGuid
    };

    setLoginingAsAffiliate(true);
    const user = await loginAsAffiliate(params);
    setLoginingAsAffiliate(false);

    if (user) {
      login(user, { callback: () => sessionStorage.setItem(sessionStorageKeys.bureauUser, JSON.stringify(bureauUser)) });
    }
  };

  const onRowSelect = (rowData) => {
    rowSelectedRef.current = true; // Prevent session storage clearing
    setSelectedRow(rowData);
    handleChangeUser(rowData);
  };

  const nameTemplate = (rowData) => {
    return `${rowData['LastName']}, ${rowData['FirstName']}`;
  };

  const specTemplate = (rowData) => {
    const spec = rowData['Speciality'];
    const specDetails = getSpeciality(spec) || { label: '' };
    return `${specDetails.label.toUpperCase()}`;
  };

  const onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const tableHeader = () => {
    return (
      <div className="flex align-items-center justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText style={{ minWidth: '300px' }} value={globalFilterValue} onChange={onGlobalFilterChange2} placeholder="Keyword Search" />
        </span>

        <Button type="button" label={t('Logout')} onClick={logout} />
      </div>
    );
  };

  return (
    <div className="login-body">
      <div className="flex justify-content-center align-items-center p-3 h-screen">
        <div className="login-panel m-0 h-auto w-full" style={{ maxWidth: '800px', position: 'unset' }}>
          <div className="login-panel-header login-panel-header-text text-white text-xl">Please select Data Center and user</div>
          <div className="px-3 pb-3" style={{ maxHeight: '90vh' }}>
            {loginingAsAffiliate && <CircularProgress />}
            <div style={{ height: 'calc(100vh - 105px)' }}>
              <DataTable
                data-testid="affiliates-table"
                value={affiliates?.length ? affiliates : []}
                scrollable
                scrollHeight="flex"
                responsiveLayout="scroll"
                emptyMessage={emptyMessage}
                selectionMode="single"
                selection={selectedRow}
                onSelectionChange={(e) => onRowSelect(e.value)}
                sortField="LastName"
                sortOrder={1}
                header={tableHeader}
                filters={filters}
                globalFilterFields={['DataCenterNumber', 'PractitionerNumber', 'LastName', 'FirstName']}
              >
                <Column
                  field="DataCenterNumber"
                  header={t('DataCenterNumber')}
                  sortable
                  style={{ minWidth: '205px', maxWidth: '300px', height: '55px' }}
                />

                <Column
                  field="LastName"
                  header={t('Name')}
                  sortable
                  body={nameTemplate}
                  style={{ minWidth: '350px', maxWidth: '350px', height: '55px' }}
                />

                <Column
                  field="Speciality"
                  header={t('Speciality')}
                  sortable
                  style={{ minWidth: '205px', maxWidth: '300px', height: '55px' }}
                  body={specTemplate}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Affiliates;
