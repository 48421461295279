import { useEffect, useCallback, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setLoading, setToastMessage } from '../../../core/actions/core.action.creators';
import { getEligibility, setEligibility } from '../../actions/patients.action.creators';
import { getEveryFirstLetterOfString } from '../../../utils/getEveryFirstLetterOfString';
import { setErrorMessageAutofill } from '../../actions/patients.action.creators';

const useAutofill = (formik, setLoading ,setShowImage, extractedText, autofillTriggered, setAutofillTriggered, checkPatientDuplicate, setNameFromEligibility, setNameFromPicture, setShowPHNDuplicateDialogAction, setRemoveFocusPhn, setSuggestedNames, imageIndex, phnRef, setValue, watchValues, setLocalState ) => {
    const dispatch = useDispatch();

  const values = watchValues || formik.values; // Prioritize watchValues if available

    const autofillErrors = useSelector((state) => state?.patients?.autofillError || []);

    const errorAutofillMessage = autofillErrors[imageIndex]?.message || '';
  
    function extractPotentialNames(extractedText) {
      const lines = extractedText.split('\n');
      const names = [];
    
      let skipNextLine = false; // Flag to handle moving to the next line for continuation
  
      lines.forEach((line, index) => {
          if (skipNextLine) {
              skipNextLine = false; // Reset the flag as this is the continuation
              return;
          }
  
          if (line.includes(',')) {
              const [lastPart, firstPart] = line.split(',');
  
              // Extract last name until the first non-letter and non-space character
              let lastNameMatch = lastPart.match(/^[a-zA-Z\s]+/);
              let lastName = lastNameMatch ? lastNameMatch[0].trim() : '';
  
              // Extract first name until the first non-letter and non-space character
              let firstNameMatch = firstPart.match(/^[a-zA-Z\s]+/);
              let firstName = firstNameMatch ? firstNameMatch[0].trim() : '';
  
              // If no valid first name, check the next line
              if (!firstName && index + 1 < lines.length) {
                  const nextLine = lines[index + 1];
                  const nextFirstNameMatch = nextLine.match(/^[a-zA-Z\s]+/);
                  console.log("lastName", lastName);
                  firstName = nextFirstNameMatch ? nextFirstNameMatch[0].trim() : '';
                  skipNextLine = true; // Mark to skip the next line
              }
  
              if (lastName && firstName) {
                  names.push({ firstName, lastName });
                  firstName = '';
                  lastName = '';
              }
          }
      });
  
      return names;
  }
  

    const handleAutofillLogic = useCallback(() => {
      if (!autofillTriggered) return;
  
      console.log("Formik Values After Autofill:", values);
  
      const extractedTextLower = extractedText?.toLowerCase() || '';
      const phn = values.PHN?.replaceAll(' ', '');
      const dob = values.BirthDay ? moment(values.BirthDay).format('MM/DD/YYYY') : null;
      const province = values.PHNProvince;
      setNameFromEligibility('');
      setNameFromPicture('');

    const combinedName = extractedTextLower.toLowerCase().trim();

    if (phn) {
      checkPatientDuplicate({
        patientData: values,
        callback: (duplicateData) => {
          if (duplicateData && duplicateData.length > 0) {
            const duplicatePatient = duplicateData[0];

              console.log("Duplicate Patient Data:", duplicatePatient);

              dispatch(setErrorMessageAutofill({ index: imageIndex, severity: 'warn', message: `PHN already exists in the system.`, formData: { ...formik?.values || {} } }));

  
              const updatedValues = {
                ...values,
                FirstName: duplicatePatient.FirstName || '',
                LastName: duplicatePatient.LastName || '',
                BirthDay: duplicatePatient.BirthDay 
                ? moment(duplicatePatient.BirthDay, moment.ISO_8601).isValid() 
                    ? moment(duplicatePatient.BirthDay).format('MM/DD/YYYY') 
                    : '' 
                : '',
              Sex: duplicatePatient.Sex || '',
              FullName: `${duplicatePatient.LastName}, ${duplicatePatient.FirstName}`
            };

              // Check if setValue exists, use it; otherwise, use formik.setValues
              if (setValue) {
                Object.keys(updatedValues).forEach((key) => setValue(key, updatedValues[key],{ shouldDirty: true }));
              } else {
                formik.setValues(updatedValues);
              }
              if(setShowPHNDuplicateDialogAction){
                setRemoveFocusPhn(true);
                setShowPHNDuplicateDialogAction(true);
              }

            } else if (dob && province === 'BC') {
                const params = { PHN: phn, DOB: dob };
                dispatch(getEligibility(params, (responseData) => {
                  console.log("Eligibility Response Data:", responseData);

                  if (setLocalState) {
                    setLocalState((prevState) => ({
                      ...prevState,
                      eligibility: responseData.Parsed // Update eligibility state
                    }));
                  }

                  if (errorAutofillMessage) {
                    setShowImage(true);
                    if (!setValue) {
                      formik.handleSubmit();
                    }
                  }

                  dispatch(setEligibility(responseData.Parsed));

                  let firstName = responseData.Parsed.FIRST_NAME?.trim() || '';
                  let lastName = responseData.Parsed.LAST_NAME?.trim() || '';
                  setNameFromEligibility(`${lastName.trim()}, ${firstName.trim()}`);
                  const gender = getEveryFirstLetterOfString(responseData.Parsed.GENDER || '');

                  const firstNameLower = firstName.toLowerCase();
                  const lastNameLower = lastName.toLowerCase();

                  const adjustNameSegments = (extractedText, eligibilityFirstName, eligibilityLastName) => {
                    // Split the extracted text and eligibility names into words
                    const extractedSegments = extractedText.split(/\s+/);
                    const firstNameTokens = eligibilityFirstName.toLowerCase().split(/\s+/);
                    const lastNameTokens = eligibilityLastName.toLowerCase().split(/\s+/);

                    // Find the first appearance of any token from the eligibility names
                    const lowerSegments = extractedSegments.map((segment) => segment.toLowerCase());
                    const firstNameStartIndex = lowerSegments.findIndex((segment) => firstNameTokens.includes(segment));
                    const lastNameStartIndex = lowerSegments.findIndex((segment) => lastNameTokens.includes(segment));

                    // Determine the starting point for the window
                    const startIndex = Math.min(
                      firstNameStartIndex !== -1 ? firstNameStartIndex : extractedSegments.length,
                      lastNameStartIndex !== -1 ? lastNameStartIndex : extractedSegments.length
                    );

                    // Extract the window starting from the first relevant occurrence
                    const windowSegments = extractedSegments.slice(startIndex, startIndex + firstNameTokens.length + lastNameTokens.length + 5);

                    // Filter the window to include only relevant segments
                    const filteredSegments = windowSegments.filter((segment) => {
                      const lowerSegment = segment.toLowerCase();
                      return firstNameTokens.includes(lowerSegment) || lastNameTokens.includes(lowerSegment);
                    });

                    // Identify order of first and last names in the filtered window
                    const firstNameIndices = filteredSegments
                      .map((segment, index) => (firstNameTokens.includes(segment.toLowerCase()) ? index : -1))
                      .filter((index) => index !== -1);

                    const lastNameIndices = filteredSegments
                      .map((segment, index) => (lastNameTokens.includes(segment.toLowerCase()) ? index : -1))
                      .filter((index) => index !== -1);

                    const firstNameStart = Math.min(...firstNameIndices, filteredSegments.length);
                    const lastNameStart = Math.min(...lastNameIndices, filteredSegments.length);

                    let potentialFirstName = [];
                    let potentialLastName = [];

                    if (lastNameStart < firstNameStart) {
                      // Last name appears first
                      potentialLastName = filteredSegments.slice(0, firstNameStart);
                      potentialFirstName = filteredSegments.slice(firstNameStart);
                    } else {
                      // First name appears first or names are interleaved
                      potentialFirstName = filteredSegments.slice(0, lastNameStart);
                      potentialLastName = filteredSegments.slice(lastNameStart);
                    }

                    // Reconstruct names
                    const adjustedFirstName = potentialFirstName.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

                    const adjustedLastName = potentialLastName.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

                    return { adjustedFirstName, adjustedLastName };
                  }; // Usage in the main function
                  // const { adjustedFirstName, adjustedLastName } = adjustNameSegments(extractedText, firstNameLower, lastNameLower);
                  // console.log("TETSING NEW APPROACH: ", `${adjustedLastName.trim()}, ${adjustedFirstName.trim()}`);

                  const nameSegments = firstNameLower.trim().split(/\s+/); // Split first name into words
                  let bestMatchIndex = -1; // To track the longest matching index
                  let bestMatchSegment = ''; // To store the best matching word

                  // Iterate through each segment of the first name
                  nameSegments.forEach((segment) => {
                    const currentIndex = combinedName.indexOf(segment);
                    if (currentIndex > bestMatchIndex) {
                      bestMatchIndex = currentIndex; // Update to the longest index
                      bestMatchSegment = segment; // Update the best matching segment
                    }
                  });

                  const lastNameIndex = combinedName.indexOf(lastNameLower);
                  console.log('Best Match Segment in First Name:', bestMatchSegment);
                  console.log('Best Match Index in First Name:', bestMatchIndex);
                  console.log('Last Name Index:', lastNameIndex);

                  if (bestMatchIndex !== -1 && lastNameIndex !== -1 && lastNameIndex < bestMatchIndex) {
                    const textBetweenFirstLast = combinedName.substring(bestMatchIndex + bestMatchSegment.length, lastNameIndex).trim();
                    const textBetweenLastFirst = combinedName.substring(lastNameIndex + lastNameLower.length, bestMatchIndex).trim();
                    console.log('Text Between First and Last:', textBetweenFirstLast);
                    console.log('Text Between Last and First:', textBetweenLastFirst);

                    if (textBetweenFirstLast.length > textBetweenLastFirst.length) {
                      const splitText = textBetweenFirstLast.split(/[\n,]+/, 2);

                      let extractedLastName = splitText[0]?.replace(/[^a-zA-Z\s-]/g, ''); // Allow dashes
                      let extractedFirstName = splitText[1]?.replace(/[^a-zA-Z\s-]/g, ''); // Allow dashes

                      console.log('Part 1:', extractedLastName);
                      console.log('Part 2:', extractedFirstName);

                      if (extractedFirstName && extractedLastName) {
                        firstName = extractedFirstName.toUpperCase() || firstName;
                        lastName = extractedLastName.toUpperCase() || lastName;
                        setNameFromPicture(`${lastName.trim()}, ${firstName.trim()}`);
                      } else {
                        const { adjustedFirstName, adjustedLastName } = adjustNameSegments(extractedText, firstNameLower, lastNameLower);
                        console.log('TETSING NEW APPROACH: ', `${adjustedLastName.trim()}, ${adjustedFirstName.trim()}`);
                        if (adjustedFirstName && adjustedLastName) {
                          firstName = adjustedFirstName.toUpperCase() || firstName;
                          lastName = adjustedLastName.toUpperCase() || lastName;
                          setNameFromPicture(`${lastName.trim()}, ${firstName.trim()}`);
                        }
                      }
                    }
                  } else if (bestMatchIndex !== -1 && lastNameIndex !== -1 && lastNameIndex > bestMatchIndex) {
                    // New logic: First Name appears before Last Name

                    const { adjustedFirstName, adjustedLastName } = adjustNameSegments(extractedText, firstNameLower, lastNameLower);
                    console.log('TETSING NEW APPROACH: ', `${adjustedLastName.trim()}, ${adjustedFirstName.trim()}`);
                    if (adjustedFirstName && adjustedLastName) {
                      firstName = adjustedFirstName.toUpperCase() || firstName;
                      lastName = adjustedLastName.toUpperCase() || lastName;
                      setNameFromPicture(`${lastName.trim()}, ${firstName.trim()}`);
                    }

                    // console.log("Handling case where First Name appears before Last Name.");

                    // // const textBetweenFirstLast = combinedName.substring(
                    // //     bestMatchIndex + bestMatchSegment.length,
                    // //     lastNameIndex
                    // // ).trim();

                    // // console.log("Text Between First and Last:", textBetweenFirstLast);

                    // const firstNameSegments = firstNameLower.split(/\s+/);
                    // const lastNameSegments = lastNameLower.split(/\s+/);
                    // let firstNameStartIndex = -1;
                    // let firstNameEndIndex = -1;

                    // firstNameSegments.forEach((segment) => {
                    //   const index = extractedText.indexOf(segment); // Find the index of the segment in combinedName
                    //   if (index !== -1) {
                    //       if (firstNameStartIndex === -1 || index < firstNameStartIndex) {
                    //           firstNameStartIndex = index; // Update the earliest start index
                    //           firstNameEndIndex = index + segment.length; // Set the end index for this segment
                    //       }
                    //   }
                    // });

                        // console.log("First Name Start Index:", firstNameStartIndex);
                        // const firstToLast = extractedText.substring(firstNameStartIndex, lastNameIndex).trim();
                        // console.log("First to Last TEST:", firstToLast);
                        // const firstNameSplit = firstToLast.split(/\s+/);
                        // console.log("First Name Split:", firstNameSplit);
                        // console.log("First Name eligbility", firstNameLower.toUpperCase());

                    
                        // let matchedFirstNameWords = [];
                        // let matchedLastNameWords = [];
                    
                        // // Match words in the first name
                        // firstNameSplit.forEach((word) => {
                        //     if (firstNameLower.toUpperCase().includes(word)) {
                        //         matchedFirstNameWords.push(word);
                        //     }
                        // });
                    
                        // // Match words in the last name
                        // lastNameSegments.forEach((word) => {
                        //     if (combinedName.includes(word)) {
                        //         matchedLastNameWords.push(word);
                        //     }
                        // });
                    
                        // console.log("Matched First Name Words:", matchedFirstNameWords);
                        // console.log("Matched Last Name Words:", matchedLastNameWords);
                    
                        // // Construct the new name using only matched words
                        // const updatedFirstName = matchedFirstNameWords
                        //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize words
                        //     .join(' ');
                    
                        // const updatedLastName = matchedLastNameWords
                        //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize words
                        //     .join(' ');
                    
                        // const updatedFullName = `${updatedLastName}, ${updatedFirstName}`.trim();
                    
                        // console.log("Updated Full Name:", updatedFullName);
                    
                        // if (updatedFirstName && updatedLastName) {
                        //     firstName = updatedFirstName.toUpperCase() || firstName;
                        //     lastName = updatedLastName.toUpperCase() || lastName;
                        //     setNameFromPicture(updatedFullName.toUpperCase());
                        // }
                      }
                    
                    
                        else {
                        dispatch(setErrorMessageAutofill({
                            index: imageIndex,
                            severity: 'warn',
                            message: `First name or last name not found in the image. Please verify the autofill data.`,
                            formData: { ...formik?.values || {} }
                        }));
                    }
                    
                const updatedValues = {
                  ...values,
                  FirstName: firstName,
                  LastName: lastName,
                  Sex: gender,
                  FullName: `${lastName}, ${firstName}`,
                  Summary: `${lastName}, ${firstName} (${values.Age}${gender})`,
                };

                  // Check if setValue exists, use it; otherwise, use formik.setValues
                  if (setValue) {
                    Object.keys(updatedValues).forEach((key) => setValue(key, updatedValues[key], { shouldDirty: true }));
                  } else {
                    formik.setValues(updatedValues);
                    formik.handleSubmit();
                    console.log('SUBMIT NUMBER ONE ');
                  }

                setLoading(false); 
                // setShowForm(true);
              },true, setShowImage, formik, imageIndex));
            }
            else if(province !== 'BC'){
              dispatch(setErrorMessageAutofill({
                  index: imageIndex,
                  severity: 'warn',
                  message: `Name not recognized. Please enter it manually. Please, verify the province.`,
                  formData: { ...formik?.values || {} }
              }));
              setLoading(false); 
              setShowImage(true);
              if(!setValue){
                formik.handleSubmit(); 
              }
          }
          else{
            if(!setValue){
              formik.handleSubmit();
            }
          }
        }
      });
    } else {
      if (!setValue) {
        formik.handleSubmit(); // Only submit if setValue is not available
        console.log('SUBMIT NUMBER TWO ');
        if (!phn && phnRef?.current) {
          setTimeout(() => {
            console.log('Setting Focus to PHN Input');
            phnRef.current.focus();
          }, 0);
        }
      }
    }

    // if (!setValue) {
    //   formik.handleSubmit(); // Only submit if setValue is not available
    //   console.log("SUBMIT NUMBER TWO ");
    //   if (!phn && phnRef?.current) {
    //     setTimeout(() => {
    //       console.log("Setting Focus to PHN Input");
    //       phnRef.current.focus();
    //     }, 0);
    //   }
    // }
    const names = extractPotentialNames(extractedText);
    setSuggestedNames(names);

    console.log('Suggested Names:', names);

    setAutofillTriggered(false);
  }, [autofillTriggered, checkPatientDuplicate, dispatch, extractedText, formik, setAutofillTriggered, setValue, values]);

  useEffect(() => {
    handleAutofillLogic();
  }, [handleAutofillLogic]);
};

export default useAutofill;
