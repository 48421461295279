import { useState, useRef } from 'react';
import { useInterval } from 'primereact/hooks';
import { TIMEZONES } from '../config/enums';
import moment from 'moment-timezone';

/**
 * useTimer
 *
 * A countdown timer hook that:
 * - Supports a `startTime` from the backend (e.g., last login time).
 * - Allows manual `startTimer()` calls (e.g., for "Send Code" buttons).
 * - Ensures all calculations are done in PST (ignores local system time).
 * - Computes remaining time correctly based on `startTime + duration`.
 *
 * @param {Object} options - Configuration options for the timer.
 * @param {number} options.duration - Countdown duration in milliseconds.
 * @param {string|Date|number} [options.startTime] - The starting time (ISO string, Date, or epoch ms).
 * @param {Function} [options.onComplete] - Callback triggered when countdown reaches 0.
 * @param {boolean} [options.autoReset=false] - If true, restarts the timer automatically after reaching 0.
 * @param {string} [options.timezone=TIMEZONES.PST] - The timezone used for calculations (default: PST).
 *
 * @returns {Object} { timeLeft, formattedTime, startTimer, active }
 */
export function useTimer({
  duration,
  startTime,
  onComplete,
  autoReset = false,
  timezone = TIMEZONES.PST // Ensure all calculations stay in PST
}) {
  // Get the current time in PST (not the user's local timezone)
  const now = moment().tz(timezone).valueOf();

  // Convert `startTime` (if provided) into PST timezone
  const parsedStartTime = startTime
    ? moment.tz(startTime, timezone).valueOf() // Ensures `startTime` is correctly converted to PST
    : null;

  // Calculate the expected end time of the countdown
  const endTime = parsedStartTime ? parsedStartTime + duration : null;

  // Compute the initial time left (ensure it’s non-negative)
  const initialTimeLeft = endTime ? Math.max(endTime - now, 0) : 0;

  // Timer state: remaining time and whether the timer is active
  const [timeLeft, setTimeLeft] = useState(initialTimeLeft);
  const [active, setActive] = useState(initialTimeLeft > 0); // Active only if time remains

  // 🛠 Refs to store start and end times (prevent unnecessary re-renders)
  const startTimeRef = useRef(parsedStartTime);
  const endTimeRef = useRef(endTime);

  /**
   * useInterval: Updates the timer every second
   */
  useInterval(
    () => {
      if (!active) return; // Stop if the timer is inactive

      const currentTime = moment().tz(timezone).valueOf(); // Always get the current PST time
      const remaining = endTimeRef.current - currentTime; // Calculate remaining time

      if (remaining <= 0) {
        // Timer has finished
        setTimeLeft(0);
        setActive(false);
        if (onComplete) onComplete(); // Trigger completion callback
        if (autoReset) startTimer(); // Restart timer if autoReset is enabled
      } else {
        setTimeLeft(remaining); // Update remaining time
      }
    },
    active ? 1000 : null // Run every 1 second if active, otherwise stop
  );

  /**
   * startTimer: Manually start or restart the timer
   * This is useful for cases like "Send Code" buttons, where
   * a user action should trigger the countdown.
   */
  const startTimer = () => {
    const currentTime = moment().tz(timezone).valueOf(); // Get current PST time
    startTimeRef.current = currentTime;
    endTimeRef.current = currentTime + duration;
    setTimeLeft(duration);
    setActive(true);
  };

  // Format the remaining time into a user-friendly format
  const formattedTime = formatTime(timeLeft);

  return { timeLeft, formattedTime, startTimer, active };
}

/**
 * formatTime
 *
 * Converts milliseconds into a human-friendly time format:
 * - "1d 04:10:15" if days are present.
 * - "HH:mm:ss" if hours exist.
 * - "mm:ss" if only minutes and seconds remain.
 *
 * @param {number} milliseconds - The remaining time in milliseconds.
 * @returns {string} - The formatted time string.
 */
const formatTime = (milliseconds) => {
  const durationMoment = moment.duration(milliseconds);
  const days = Math.floor(durationMoment.asDays());
  const hours = durationMoment.hours();
  const minutes = durationMoment.minutes();
  const seconds = durationMoment.seconds();

  if (days > 0) {
    return `${days}d ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } else if (hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } else {
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
};
