import { useSelector } from 'react-redux';
import withAuthCondition from './withAuthCondition';
import { passwRecoveryTemporaryBlocked, userLockedStatus } from '../config/userStatuses';

const withRequirePasswordRecovery = (Component) => {
  return withAuthCondition(Component, () => {
    const user = useSelector((state) => state?.user?.details);
    return Boolean(user?.SessionGuid || user?.UserStatus === passwRecoveryTemporaryBlocked || user?.UserStatus === userLockedStatus);
  });
};
export default withRequirePasswordRecovery;
