import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Footer from './Footer';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import './Registration.scss';
import PrivacyTabs from './PrivacyTabs';
import { getTermsOfUseAction } from '../../modules/auth/actions/auth.actions.creators';

const Privacy = ({ onAccept, onCancel }) => {
  const [scrollStatus, setScrollStatus] = useState(false);
  const [checkBoxStatus, setCheckBoxStatus] = useState(false);
  const [visibleAccept, setVisibleAccept] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTermsOfUseAction());
  }, []);

  const handleScroll = (e) => {
    if (!scrollStatus) {
      const scrollPosition = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      setScrollStatus(scrollPosition); // set scroll position to true if user has scrolled to the bottom
    }
  };

  const getMessage = () => {
    let message = '';
    if (!scrollStatus) {
      message = 'Please scroll down the page to read the Agreement.';
    }
    if (!checkBoxStatus) {
      message =
        "You must agree to the Acclaim’s Agreement to successfully complete the account opening. Please click on the checkbox below, then click on the 'Accept' button.";
    }

    return message;
  };

  return (
    <div id="privacy-body" className="flex flex-column justify-content-center align-items-center " style={{ background: '#e6e6e6' }}>
      <div
        id="privacy-box"
        className="flex flex-column justify-content-center align-items-center h-screen gap-4 w-full "
        style={{ maxWidth: '800px' }}
      >
        <PrivacyTabs handleScroll={handleScroll} />

        <hr className="w-full text-500" />
        <Footer
          onAccept={onAccept}
          setVisibleAccept={setVisibleAccept}
          setVisibleCancel={setVisibleCancel}
          checkBoxStatus={checkBoxStatus}
          setCheckBoxStatus={setCheckBoxStatus}
          scrollStatus={scrollStatus}
        />

        <Dialog
          pt={{ closeButton: { className: 'hidden' } }}
          header={
            <div className="flex justify-content-between align-items-center">
              <span>Warning</span>
              <Button
                icon="pi pi-times"
                data-testid="iconCloseDialog"
                className="p-button-text p-button-rounded"
                onClick={() => {
                  setVisibleAccept(false);
                  setScrollStatus(true);
                }}
              />
            </div>
          }
          footer={
            <Button
              data-testid="buttonCloseDialog"
              label="Close"
              onClick={() => {
                setVisibleAccept(false);
                setScrollStatus(true);
              }}
            />
          }
          visible={visibleAccept}
          style={{ width: '30vw' }}
          onHide={() => setVisibleAccept(false)}
        >
          <Message severity="Secondary Message" text={getMessage()} />
        </Dialog>

        <Dialog
          className="line-height-3 text-center"
          header={'Warning'}
          footer={
            <>
              <Button label="No" onClick={() => setVisibleCancel(false)} />
              <Button
                label="Yes"
                className="p-button-outlined"
                onClick={() => {
                  setVisibleCancel(false);
                  onCancel();
                }}
              />
            </>
          }
          visible={visibleCancel}
          style={{ width: '30vw' }}
          onHide={() => {
            if (!visibleCancel) return;
            setVisibleCancel(false);
          }}
        >
          Are you sure you want to cancel?
        </Dialog>
      </div>
    </div>
  );
};

export default Privacy;
