import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputTextarea } from 'primereact/inputtextarea';
import DOMPurify from 'dompurify';

const HearAboutUs = () => {
  const {
    control,
    formState: { errors },
    clearErrors,
    setError,
    setValue
  } = useFormContext();

  const formatLink = (inputText) => {
    const linkRegex = /(?:https?:\/\/|www\.)\S+/g;
    const links = inputText.match(linkRegex);

    if (links) {
      links.forEach((link) => {
        const modifiedLink = link.replace(/^(https?:\/\/www\.)/, '');
        inputText = inputText.replace(link, modifiedLink);
      });
    }
    setValue('hearAboutUs', inputText);
  };

  return (
    <>
      <Controller
        name="hearAboutUs"
        control={control}
        rules={{ maxLength: { value: 150, message: 'Max length is 150 characters' } }}
        render={({ field }) => (
          <InputWrapper
            name="hearAboutUs"
            label="How did you hear about us"
            errorMessage={errors?.hearAboutUs?.message}
            style={{ height: '120px', width: '100%', marginBottom: '1rem' }}
          >
            <InputTextarea
              {...field}
              id="hearAboutUs"
              data-testid="hearAboutUs-testid"
              style={{ minHeight: '75px', width: '100%' }}
              autoResize
              onChange={(e) => {
                const value = e.target.value;
                const sanitizedContent = DOMPurify.sanitize(value);
                field.onChange(sanitizedContent);
                if (value.length > 150) {
                  setError('hearAboutUs', {
                    type: 'manual',
                    message: 'Max length is 150 characters'
                  });
                } else {
                  clearErrors('hearAboutUs');
                }
              }}
              onBlur={() => {
                formatLink(field.value);
              }}
            />
          </InputWrapper>
        )}
      />
    </>
  );
};

export default HearAboutUs;
