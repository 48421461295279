// interface {
//   feeCodes: {
//     base_codes: string,
//     rate: number,
//     min_per_unit: number,
//     max_unit: number,
//     portion: number
//   }[],
//   records: {
//     base_codes: string,
//     rate: number,
//     min_per_unit: number,
//     max_unit: number,
//     portion: number
// }[]
// }

export const calculateSurgicalAssist = ({ feeCodes, records }) => {
  if (!feeCodes || !Array.isArray(feeCodes)) return 0; // Handle missing or invalid feeCodes
  if (!records || !Array.isArray(records)) return 0; // Handle missing or invalid records

  const code13003 = feeCodes.find((i) => i.value === '13003');
  if (!code13003 || !code13003.base_codes) return 0; // Handle missing '13003' or missing base_codes

  const baseCodesOnly = code13003.base_codes.split(',');
  const baseCodes = records.filter((i) => baseCodesOnly.some((x) => x === i.fee_code));
  const sumOfFees = baseCodes.reduce((acc, current) => acc + (current.billed || 0), 0); // Handle missing 'billed'
  const amountFor13003 = sumOfFees * (code13003.rate / 100);

  // Round to two decimal places
  const roundedAmountFor13003 = Math.round(amountFor13003 * 100) / 100;

  // Enforce the maximum amount limit of 500
  return Math.min(roundedAmountFor13003, 500);
};
