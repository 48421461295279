import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { getReferralCode } from '../../actions/referralCredits.action.creators';
import { prefsCodes } from '../../../config/prefsCodesConfig';
import { getPresetPrefs } from '../../../claims/helpers/getDefaultPrefs';
import './ReferralSocialSharing.scss';

const ReferralSocialSharing = () => {
  const user = useSelector((state) => state.user.details);
  const preferences = useSelector((state) => state.preferences.currentUserPrefs);

  const [copied, setCopied] = useState(false);
  const [gettingReferral, setGettingReferral] = useState(false);
  const [referralLink, setReferralLink] = useState('');
  const [telegramReferralLink, setTelegramReferralLink] = useState('');
  const message = `Hello Colleague, I've been using Claim Manager to simplify my billing, and it's been a game-changer! Use my referral to get 2 months FREE—try it out before the offer expires!`;

  const getReferralLink = (code) => {
    const baseUrl = window.location.origin; // Dynamically get the deployment host
    const path = '/#/registration'; // Ensure the correct path is always used
    const link = `${baseUrl}${path}?referralCode=${code}`;
    const shareTelegramPageUrl = `${'https://join.claimmanager.ca/'}${code}`;

    setReferralLink(link);
    setTelegramReferralLink(shareTelegramPageUrl); // Telegram Referral link
    return link;
  };

  // Fetch the referral link when component mounts
  useEffect(() => {
    const fetchReferralCode = async () => {
      setGettingReferral(true);
      const code = await getReferralCode();
      setGettingReferral(false);
      if (code) {
        getReferralLink(code);
      }
    };

    const defaultPrefs = preferences?.prefs?.find((i) => i.label === 'default');
    const prefsCode = getPresetPrefs(defaultPrefs?.content, prefsCodes.referralCode);

    if (prefsCode) {
      getReferralLink(prefsCode);
    } else {
      fetchReferralCode();
    }
  }, []);

  const shareOnSocialMedia = (platform) => {
    const urls = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`,
      linkedin: `https://www.linkedin.com/shareArticle?mini=true&text=${encodeURIComponent(message + '\n\n' + referralLink + '\n')}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(message + '\n\n' + referralLink)}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(referralLink + '\n\n' + message)}`,
      telegram: `https://t.me/share/url?url=${encodeURIComponent(telegramReferralLink + '\n\n' + message)}&text=${encodeURIComponent('')}`
    };

    const shareUrl = urls[platform];

    if (shareUrl) {
      window.open(shareUrl, '_blank');
      console.log(shareUrl);
    } else {
      console.error('Invalid platform:', platform);
    }
  };

  // Copy referral link & message to clipboard
  const handleCopy = () => {
    navigator.clipboard.writeText(message + '\n\n' + referralLink);
    setCopied(true);

    // Hide the "Copied!" button after a short delay
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  // Email share action
  const onEmailShare = () => {
    const subject = 'Get 2 Free Months on Me—Try Claim Manager for MSP Direct Billing!';
    const body = `Hi colleague(s),
  
  I recently switched to Claim Manager for MSP billing, and it's made a noticeable difference. The billing has become more exact, there are fewer claim rejections, and data entry is faster—overall, it's streamlined my practice beautifully. Given our similar needs, you might experience these benefits too.
  
  They're offering two free months if you sign up through a referral.
  
  Just use this link ${referralLink} to get started, and enjoy the free months which will be automatically credited to your account.
  
  Let me know if you have any questions!
  
  Cheers, 
  ${user?.LastName}, ${user?.FirstName}`;

    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const copyLinkButton = (
    <>
      <Button className="block sm:hidden" rounded icon="pi pi-copy" disabled={!referralLink || gettingReferral} onClick={handleCopy} />
      <Button className="hidden sm:block w-auto" text label="Copy link" disabled={!referralLink || gettingReferral} onClick={handleCopy} />
    </>
  );

  const copiedMessage = (
    <>
      <Button
        className="block sm:hidden p-button-success"
        rounded
        icon="pi pi-check"
        tooltip="Copied!"
        tooltipOptions={{ position: 'top' }}
        disabled={!referralLink || gettingReferral}
      />
      <div
        className="hidden sm:block text-center p-2 font-normal"
        style={{ width: '79px', height: '33px' }}
        disabled={!referralLink || gettingReferral}
      >
        Copied!
      </div>
    </>
  );

  return (
    <div className="flex flex-wrap gap-3">
      <Button
        className="p-button-rounded p-button-facebook"
        icon="pi pi-facebook"
        tooltip="Facebook"
        tooltipOptions={{ position: 'top', showDelay: 1000 }}
        disabled={!referralLink || gettingReferral}
        onClick={() => shareOnSocialMedia('facebook')}
      />
      <Button
        className="p-button-rounded p-button-linkedin"
        icon="pi pi-linkedin"
        tooltip="LinkedIn"
        tooltipOptions={{ position: 'top', showDelay: 1000 }}
        disabled={!referralLink || gettingReferral}
        onClick={() => shareOnSocialMedia('linkedin')}
      />
      <Button
        className="p-button-rounded p-button-twitter"
        icon="pi pi-twitter"
        tooltip="X (formerly Twitter)"
        tooltipOptions={{ position: 'top', showDelay: 1000 }}
        disabled={!referralLink || gettingReferral}
        onClick={() => shareOnSocialMedia('twitter')}
      />
      <Button
        className="p-button-rounded p-button-success"
        icon="pi pi-whatsapp"
        tooltip="WhatsApp"
        tooltipOptions={{ position: 'top', showDelay: 1000 }}
        disabled={!referralLink || gettingReferral}
        onClick={() => shareOnSocialMedia('whatsapp')}
      />
      <Button
        className="p-button-rounded p-button-telegram"
        icon="pi pi-telegram"
        tooltip="Telegram"
        tooltipOptions={{ position: 'top', showDelay: 1000 }}
        disabled={!referralLink || gettingReferral}
        onClick={() => shareOnSocialMedia('telegram')}
      />
      <Button
        className="p-button-rounded p-button-email"
        icon="pi pi-envelope"
        tooltip="Email"
        tooltipOptions={{ position: 'top', showDelay: 1000 }}
        disabled={!referralLink || gettingReferral}
        onClick={onEmailShare}
      />

      {copied ? copiedMessage : copyLinkButton}
    </div>
  );
};

export default ReferralSocialSharing;
