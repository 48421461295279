import { store } from '../../store';
import { getDefaultPrefs } from '../claims/helpers/getDefaultPrefs';
import { defaultGuid } from '../config/defaultValuesConfig';
import { prefsCodes } from '../config/prefsCodesConfig';
import { getPractitionerNameWithSpec } from './getPractitioner';

export const getPayToValue = ({ privateRecord, prefsGuid } = {}) => {
  const payTo = privateRecord?.PayeeInfo || {};
  const payeeOptions = getPayeeOptions();
  const { DoctorGuid, DCNGuid } = payTo;
  const currentUserPrefs = store.getState().preferences.currentUserPrefs;
  const userDefaultPrefs = currentUserPrefs?.prefs?.find((pref) => pref.label === 'default');

  // Retrieve user preferences
  const preferences = userDefaultPrefs?.content || [];
  const payToPrefsGuid = prefsGuid ?? getDefaultPrefs(preferences, prefsCodes.defaultPayTo) ?? DoctorGuid ?? DCNGuid;
  const payeeOption = payeeOptions.find((option) => option.id === payToPrefsGuid) || getDefaultPayeeOption(prefsCodes.defaultPayTo);
  return payeeOption?.data;
};

export const processPayeeData = (type, selectedValue) => {
  switch (type) {
    case 'payee':
      const clinic = store.getState().clinic.details;
      return selectedValue.id === clinic?.DCNGuid
        ? clinic.ClinicName
        : `${selectedValue?.data?.LastName || ''}, ${selectedValue?.data?.FirstName || ''} ${selectedValue?.data?.MidName || ''}`.trim();

    case 'address':
      return [selectedValue?.data?.AddressLine, selectedValue?.data?.City, selectedValue?.data?.Province, selectedValue?.data?.Zip]
        .filter(Boolean) // Remove undefined or empty values
        .join(', '); // Join with a comma and space

    case 'email':
      return selectedValue?.data?.Email || '';

    case 'phone':
      return selectedValue?.data?.Phone || '';

    default:
      return selectedValue.data;
  }
};

export const getPayeeInfoInitValues = ({ privateRecord } = {}) => {
  if (privateRecord) return privateRecord.PayeeInfo;

  const payeeOption = getDefaultPayeeOption(prefsCodes.defaultPayTo);
  const emailOption = getDefaultPayeeOption(prefsCodes.privateInvoiceEmail);
  const phoneOption = getDefaultPayeeOption(prefsCodes.privateInvoicePhone);
  const addressOption = getDefaultPayeeOption(prefsCodes.privateInvoiceAddress);

  return [
    { id: 'payee', value: payeeOption.id, label: processPayeeData('payee', payeeOption) },
    { id: 'email', value: emailOption.id, label: processPayeeData('email', emailOption) },
    { id: 'phone', value: phoneOption.id, label: processPayeeData('phone', phoneOption) },
    { id: 'address', value: addressOption.id, label: processPayeeData('address', addressOption) }
  ];
};

export const getPayeeOptions = (options = []) => {
  const clinic = store.getState().clinic.details;
  const members = store.getState().clinic.members;
  const noneOption = { name: 'N/A', data: null, id: defaultGuid };
  const clinicOption = { name: clinic.ClinicName, data: clinic, id: clinic?.DCNGuid };

  const practitioners =
    members?.map((member) => ({
      name: getPractitionerNameWithSpec(member),
      data: member,
      id: member.UserGuid
    })) || [];

  return [noneOption, clinicOption, ...practitioners, ...options];
};

export const getDefaultPayeeOption = (preferenceCode) => {
  const payeeOptions = getPayeeOptions();
  const clinic = store.getState().clinic.details;
  const currentUserPrefs = store.getState().preferences.currentUserPrefs;
  const userDefaultPrefs = currentUserPrefs?.prefs?.find((pref) => pref.label === 'default');
  const preferences = userDefaultPrefs?.content || [];
  const payToPrefsGuid = getDefaultPrefs(preferences, preferenceCode);
  return payeeOptions.find((option) => option.id === payToPrefsGuid) || payeeOptions.find((option) => option.id === clinic?.DCNGuid);
};
