import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../modules/core/actions/core.action.creators";

const RestrictedRoute = ({ component: Component, children, ...rest }) => {
  const isRestricted = useSelector((state) => state.user?.details?.restricted);
  const dispatch = useDispatch();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isRestricted) {
          dispatch(
            setToastMessage({
              type: "error",
              message: "You are not authorized to view this page.",
              lifeTime: 5000,
            })
          );
          return <Redirect to="/" />;
        }

        return Component ? <Component {...props} /> : children;
      }}
    />
  );
};

export default RestrictedRoute;
