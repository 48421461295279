import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CountryPicker from './index';
import { dropdownScrollHeight } from '../../../modules/config/stylesConfig';
import { t } from '../../../service/localization/i18n';
import cx from 'classnames';

/**
 * ControlledCountryPicker component renders a country picker input field
 * that is controlled by react-hook-form's Controller.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {function} [props.onChange] - Optional callback function to handle change events.
 *
 * @returns {JSX.Element} The rendered ControlledCountryPicker component.
 *
 * @example
 * <ControlledCountryPicker onChange={(country) => console.log(country)} />
 *
 */
const ControlledCountryPicker = (props) => {
  const name = 'Country';
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors
  } = useFormContext();

  const countryValidation = (value) => {
    return value ? true : t('Mandatory_field.1');
  };

  const onChange = (country, field) => {
    if (props.onChange) {
      props.onChange(country);
    } else {
      field.onChange(country.value);
      setValue('Province', '');
      setValue('Zip', '');
      clearErrors(['Province', 'Zip']);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: countryValidation
      }}
      render={({ field }) => (
        <CountryPicker
          {...field}
          {...props}
          className={cx({ 'p-invalid': errors[name]?.message })}
          scrollHeight={dropdownScrollHeight()}
          onChange={(country) => onChange(country, field)}
        />
      )}
    />
  );
};

export default ControlledCountryPicker;
