import React from 'react';
import { Button } from 'primereact/button';
import { elementIDs } from '../../../../modules/config/elementIDsConfig';
import { isCodeRecentlyUsed } from '../../../../modules/utils/isCodeRecentlyUsed';
import cx from 'classnames';

// interface IChipItem {
//   value: string,
//   label: string,
//   order?: number,
//   isWeekend?: boolean
// }

// interface IProps {
//   item: IChipItem,
//   onClick: (item: IChipItem) => void

//   id?: string
//   name?: string, // Name of the code input that chip relates to
//   isSelected?: boolean
//   disabled?: boolean
//   label?: string // Chip label
//   tooltip?: string // Chip tooltip
// }

const CodeChip = (props) => {
  const value = props.item.value;
  const label = props.item.label;

  const className = cx(
    `${props.name}_${elementIDs.quickPickCodeItem}`, // this class needs for cypress tests only
    'p-button-rounded py-1 px-2',
    props.isSelected ? 'chipItemSelected' : isCodeRecentlyUsed(props.item?.order) ? 'p-button-outlined' : 'p-button-text',
    props.item?.isWeekend ? 'p-button-danger' : ''
  );

  return (
    <Button
      id={props.id}
      className={className}
      type="button"
      label={props.label || value}
      tooltip={props.tooltip || label}
      disabled={props.disabled}
      tooltipOptions={{ position: 'top', showDelay: 1000 }}
      onClick={() => props.onClick(props.item)}
    />
  );
};

export default CodeChip;
