import React from 'react';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import { currencyFormat } from '../../../../utils/currencyFormat';
import { calculateGroupTotal } from './calculateTotal';
import { t } from '../../../../../service/localization/i18n';
import AddServiceButton from '../actionButtons/AddServiceButton';

export const footerGroup = (records, isMultiplePractitioners) => {
  const amount = records?.map((i) => i.feeAmount);
  const total = records?.map((i) => i.total);

  return (
    <ColumnGroup>
      <Row>
        <Column />
        <Column footer={<AddServiceButton />} />
        <Column />
        {isMultiplePractitioners && <Column />}
        <Column footer={`${t('Total')}:`} />
        <Column footer={currencyFormat(calculateGroupTotal(amount))} />
        <Column />
        <Column />
        <Column footer={currencyFormat(calculateGroupTotal(total))} />
        <Column />
      </Row>
    </ColumnGroup>
  );
};
