import React from 'react';
import AddressLayout from '../../../AddressLayout';
import { Skeleton } from 'primereact/skeleton';
import { patientFullName } from '../../../../../modules/utils/patientFullName';
import { elementIDs } from '../../../../../modules/config/elementIDsConfig';
import { t } from '../../../../../service/localization/i18n';

const BillToSection = ({ data, isFetching }) => {
  const billTo = data?.BillTo && Array.isArray(data.BillTo) ? data.BillTo[0] : data?.BillTo;
  const { Comment, Facility, CompanyName } = billTo || {};

  const isRepresentative = data?.BillToGuid !== data?.PatientGuid;

  // c/o (care of) logic
  const billToName = patientFullName(billTo);
  const c_o = billToName ? `c/o ${billToName}` : '';

  return (
    <div className="pb-2">
      <div className="bill-to">{t('Bill_To').toUpperCase()}</div>

      {isFetching ? (
        <>
          <Skeleton width="10rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
        </>
      ) : (
        <div id={elementIDs.billToSection} className="line-height-3">
          <div className="flex col-12 p-0 m-0 font-bold">{patientFullName(data?.PatientDetails)}</div>

          {isRepresentative && c_o && <div className="m-0 font-bold">{c_o}</div>}
          {Comment && <div className="m-0">{Comment}</div>}
          {Facility && <div className="p-0 m-0">{Facility}</div>}
          {CompanyName && <div className="p-0 m-0">{CompanyName}</div>}

          <AddressLayout data={billTo} />
        </div>
      )}
    </div>
  );
};

export default BillToSection;
