import actionTypes from '../actions/registration.action.types';

export const registrationInitialState = {
  registrationFormState: {
    firstStepType: '',
    type: 'register-type',
    initialFormState: {}
  }
};

const registrationReducer = (state = registrationInitialState, action) => {
  const { results } = action;
  switch (action.type) {
    case actionTypes.UDATE_REGISTRATION_FORM_STATE:
      return { ...state, registrationFormState: results };

    default:
      return state;
  }
};

export default registrationReducer;
