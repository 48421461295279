import React from 'react';
import { useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import TeleplanPrintMessageBody from '../../../components/Layouts/Print/TeleplanMessage/TeleplanPrintMessageBody';
import TeleplanMessageHeader from '../../../components/Layouts/Print/TeleplanMessage/TeleplanMessageHeader';
import { setPrintData } from '../../core/actions/core.action.creators';
import { printTypes } from '../../../components/Layouts/Print/helpers/printTypes';

const MessagesDialog = ({ record, ...props }) => {
  const dispatch = useDispatch();

  const handlePrint = async () => {
    const printType = printTypes().teleplanMessage.type;
    await dispatch(setPrintData(printType, record));
    props.onHide();
    window.print();
  };

  const header = <TeleplanMessageHeader date={record?.MessageDate} />;

  const footer = (
    <>
      <Button label="Print" onClick={handlePrint} className="p-button-primary" />
      <Button type="button" label="Close" outlined onClick={props.onHide} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog {...props} header={header} style={{ maxWidth: '700px', width: '100%' }} dismissableMask footer={footer}>
      <TeleplanPrintMessageBody message={record?.MessageLine} />
    </Dialog>
  );
};

export default MessagesDialog;
