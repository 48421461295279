import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import withAuthCondition from './withAuthCondition';

const withRequireIdParams = (Component) => {
  return withAuthCondition(Component, () => {
    const { sessionGuid } = useParams();
    return Boolean(sessionGuid);
  });
};
export default withRequireIdParams;
