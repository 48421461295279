import { routes } from './routes';

export const isCurrentPage = (routeKey) => {
  if (!routes[routeKey]) return false; // Ensure route exists

  const currentHash = window.location.hash.replace(/^#/, ''); // Remove '#' from the start

  if (routeKey === 'dashboard') {
    return currentHash === '' || currentHash === routes[routeKey].path; // Match empty hash or "/dashboard"
  }

  return currentHash.startsWith(routes[routeKey].path);
};

// Wrapper functions
export const isDashboardPage = () => isCurrentPage('dashboard');
export const isMoneyPage = () => isCurrentPage('invoices');
export const isPatientProfilePage = () => isCurrentPage('patientDetails');
