import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { routes } from '../../../routes/routes';
import { resendPhoneCodeAuth } from '../../../service/Lookup';

export const useAuthVerifications = () => {
  const history = useHistory();

  const noSecretQuestions = () => {
    history.replace(routes.createAuthSecretQuestions.path);
  };

  const phoneNotVerified = (user) => {
    const payload = {
      DCNGuid: user.DCNGuid,
      DoctorGuid: user.DoctorGuid,
      SendText: 1,
      SendEmail: 0
    };

    resendPhoneCodeAuth(payload);
    history.push(routes.verificationPhone.path);
  };

  const needMFA = (user) => {
    const payload = {
      DCNGuid: user.DCNGuid,
      DoctorGuid: user.DoctorGuid,
      SendText: 0,
      SendEmail: 1
    };

    resendPhoneCodeAuth(payload);
    history.push(routes.verificationPhone.path);
  };

  const termsNotAccepted = () => {
    history.push(routes.authPrivacy.path);
  };

  return { phoneNotVerified, termsNotAccepted, noSecretQuestions, needMFA };
};
