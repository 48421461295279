/**
 * This reducer provides formatted data..
 * PatientList supports two modes: appening page of results (for autisuggestion) or overwriting (for grid)
 */

import actionTypes from '../actions/patients.action.types';
import { patientDetailsTabs } from '../components/PatientDetails/helpers/patientDetailsTabs';
import { localStorageKeys } from '../../config/localStorageKeysConfig';
import { getFromLocalStorage } from '../../utils/getFromLocalStorage';
import { defaultPageSize, patientsDefaultSortBy, patientsDefaultFilters } from '../../config/defaultValuesConfig';
import { formatPatientDetails } from '../helpers/formatPatientDetails';

export const patientsInitialState = {
  patient_list: [],
  complete_list: [],
  patient_details: {},
  patientForHighlight: {},
  totalPages: 0,
  totalRecords: 0,
  first: 0,
  page: 0,
  pageSize: getFromLocalStorage(localStorageKeys.patientsPageSize, defaultPageSize),
  filters: patientsDefaultFilters,
  query: '',
  sortby: patientsDefaultSortBy.sortOrder,
  activeTab: patientDetailsTabs.profile,
  eligibility: {},
  error: null,
  patientFormActiveTabIndex: 0,
  autocomplete: false,
  isCreatingPatient: false,
  isSavingPatientDetails: false,
  isFetchingPatients: false,
  isFetchingPatientsWithMissingData: false,
  isFetchingPHNValidation: false,
  isFetchingPatientEligibility: false,
  checkEligibilityOnly: false,
  checkNameAndEligibility: false,
  isUpdatingPHN: false,
  isDeletingPatient: false,
  checkingPatientDuplicates: false,
  showNewPatientSuccessPage: { showSuccessPage: true, from: '' },
  uploadedFile: null,
  autofillError: [], 
};

export default function patientsReducer(state = patientsInitialState, action) {
  const { results } = action;
  const onePage = results ? results.data : {};

  switch (action.type) {
    case actionTypes.GET_PATIENTS:
      return {
        ...state,
        ...results
      };

    case actionTypes.IS_FETCHING_PATIENTS:
      return {
        ...state,
        isFetchingPatients: results
      };

    case actionTypes.IS_FETCHING_PATIENTS_WITH_MISSING_DATA:
      return {
        ...state,
        isFetchingPatientsWithMissingData: results
      };

    case actionTypes.UPDATE_FILTERS:
      return {
        ...state,
        filters: results
      };

    case actionTypes.SET_PATIENT_ID_FOR_HIGHTLIGHT_IN_TABLE:
      return {
        ...state,
        patientForHighlight: results
      };

    case actionTypes.SET_PATIENT_INFO:
      return {
        ...state,
        patient_details: results
      };

    case actionTypes.IS_DELETING_PATIENT:
      return {
        ...state,
        isDeletingPatient: results
      };

    case actionTypes.FETCH_PATIENT_DETAILS_SUCCESS:
      let details = results.data;
      if (results?.data?.length) details = results.data[0];
      return {
        ...state,
        patient_details: formatPatientDetails(details)
      };

    case actionTypes.PATIENT_FILTERS:
      return {
        ...state,
        filters: results
      };

    case actionTypes.SETUP_PATIENTS_AUTOSUGGEST:
      return {
        ...state,
        autocomplete: results
      };

    // notes
    case actionTypes.FETCH_PATIENT_NOTES_SUCCESS:
      return {
        ...state,
        notes_4_patient: {
          PatientGuid: onePage.PatientGuid,
          note_list: onePage.notesList,
          page: onePage.page,
          first: (onePage.page - 1) * onePage.pageSize + 1,
          totalPages: onePage.totalPages,
          totalRecords: onePage.totalRecords,
          pageSize: onePage.pageSize,
          query: onePage.query
        }
      };

    case actionTypes.SET_ACTIVE_PATIENT_TAB:
      return {
        ...state,
        activeTab: results
      };

    case actionTypes.IS_UPDATING_PHN:
      return {
        ...state,
        isUpdatingPHN: results
      };

    case actionTypes.IS_CREATING_PATIENT:
      return {
        ...state,
        isCreatingPatient: results
      };

    case actionTypes.SAVING_PATIENT_DETAILS:
      return {
        ...state,
        isSavingPatientDetails: results
      };

    case actionTypes.SHOW_NEW_PATIENT_SUCCESS_PAGE:
      return {
        ...state,
        showNewPatientSuccessPage: results
      };

    case actionTypes.SET_PATIENT_LIST:
      return {
        ...state,
        patient_list: results
      };

    case actionTypes.SET_PATIENTS_COMPLETE_LIST:
      return {
        ...state,
        complete_list: results
      };

    case actionTypes.EDIT_PATIENT_SUCCESS:
      return {
        ...state,
        patient_details: formatPatientDetails(results)
      };

    case actionTypes.SET_ELIGIBILITY:
      return {
        ...state,
        eligibility: results
      };

    case actionTypes.SET_PATIENTS_DEFAULT_TABLE_VALUES:
      return {
        ...state,
        page: 0,
        pageSize: getFromLocalStorage(localStorageKeys.patientsPageSize, defaultPageSize),
        sortby: patientsDefaultSortBy.sortOrder,
        filters: patientsDefaultFilters,
        complete_list: []
      };

    case actionTypes.SET_PATIENTS_TOTAL_RECORDS:
      return {
        ...state,
        totalRecords: results
      };

    case actionTypes.IS_FETCHING_PATIENT_ELIGIBILITY:
      return {
        ...state,
        isFetchingPatientEligibility: results
      };

    case actionTypes.CHECK_ELIGIBILITY_ONLY:
      return {
        ...state,
        checkEligibilityOnly: results
      };

    case actionTypes.CHECK_NAME_AND_ELIGIBILITY:
      return {
        ...state,
        checkNameAndEligibility: results
      };

    case actionTypes.IS_FETCHING_PHN_VALIDATION:
      return {
        ...state,
        isFetchingPHNValidation: results
      };

    case actionTypes.PATIENTS_DUPLICATE_CHECKING:
      return {
        ...state,
        checkingPatientDuplicates: results
      };

    case actionTypes.PATIENT_FORM_ACTIVE_TAB_INDEX:
      return {
        ...state,
        patientFormActiveTabIndex: results
      };

    case actionTypes.SET_UPLOADED_FILE:
      return {
        ...state,
        uploadedFile: action.payload || null
      };

      case actionTypes.SET_ERROR_MESSAGE_AUTOFILL: {
        const { index, results } = action.payload || {}; // Safely destructure payload
        
        if (index === null) {
          // Clear all errors
          return {
            ...state,
            autofillError: [],
          };
        }
  
        const updatedErrors = [...state.autofillError]; // Copy the existing errors array
  
        // If `results` is defined, update the specific index
        if (results) {
          updatedErrors[index] = {
            severity: results.severity,
            message: results.message,
            originalMSPErrorMessage: results.originalMSPErrorMessage,
            formData: results.formData,
          };
        }
  
        return {
          ...state,
          autofillError: updatedErrors, // Replace the old array with the updated one
        };
      }
        

    default:
      return state;
  }
}
