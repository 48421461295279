import React from 'react';
import { useSelector } from 'react-redux';
import { useWatch, useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

import CCFPPTooltipLayout from '../../../Layouts/CCFPPTooltipLayout';
import TravelTooltipLayout from '../../../Layouts/TravelTooltipLayout';
import EmergencyTooltipLayout from '../../../Layouts/EmergencyTooltipLayout';

import { inputs } from '../../../../helpers/inputs';
import { invoiceTypes } from '../../../../helpers/defaultValues';
import { hideEmergency } from '../../../../helpers/inputsConditions';
import { elementIDs } from '../../../../../../../config/elementIDsConfig';
import { t } from '../../../../../../../../service/localization/i18n';
import cx from 'classnames';

const OutOfOfficeHoursPremiums = () => {
  const { isMobile } = useSelector((state) => state.core.window);
  const { control, setValue, watch, errors, clearErrors, isNew, isGroup } = useFormContext();
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const isWSBC = invoiceType === invoiceTypes.wsbc;
  const showCCFPP = Number(speciality) !== 18;
  const hideEmergencySection = hideEmergency({ isGroup, isNew, speciality, invoiceType });
  const tooltipShowDelay = 1000;

  const parseValue = () => {
    if (watch(inputs.travel.name)) return 'travel';
    if (watch(inputs.ccfpp.name)) return 'ccfpp';
    if (watch(inputs.emergency.name)) return 'emergency';
  };

  const handleEmergency = () => {
    const value = !watch(inputs.emergency.name);
    setValue(inputs.emergency.name, value, { shouldDirty: true });

    if (!value) {
      // Reset Immediate Travel
      setValue(inputs.travel.name, false);
      // Reset CCFPP
      setValue(inputs.ccfpp.name, false);
      // Reset call time
      setValue(inputs.callTime.name, '');
    }
  };

  const handleImmediateTravel = () => {
    setValue(inputs.travel.name, !watch(inputs.travel.name), { shouldDirty: true });

    // Clear error message for time inputs
    errors[inputs.callTime.name] && clearErrors(inputs.callTime.name);

    // Set Emergency
    if (!watch(inputs.emergency.name)) setValue(inputs.emergency.name, true);

    // Reset CCFPP
    if (watch(inputs.ccfpp.name)) setValue(inputs.ccfpp.name, false);

    // Reset call time
    if (watch(inputs.callTime.name)) setValue(inputs.callTime.name, '');
  };

  const handleCCFPP = () => {
    setValue(inputs.ccfpp.name, !watch(inputs.ccfpp.name), { shouldDirty: true });

    // Set Emergency
    if (!watch(inputs.emergency.name)) setValue(inputs.emergency.name, true);

    // Reset Immediate Travel
    if (watch(inputs.travel.name)) setValue(inputs.travel.name, false);
  };

  const ccfppButton = () => {
    if (!showCCFPP) return null;

    return (
      <>
        {!isMobile && (
          <Tooltip target={`.ccfpp_tooltip`} showDelay={tooltipShowDelay}>
            <div style={{ maxWidth: '250px' }}>
              <CCFPPTooltipLayout />
            </div>
          </Tooltip>
        )}
        <Button
          id={elementIDs.ccfppSwitch}
          icon={parseValue() === 'ccfpp' ? 'pi pi-check' : 'pi pi-times'}
          iconPos="right"
          className={cx('ccfpp_tooltip', parseValue() === 'ccfpp' ? 'p-button-success' : 'p-button-outlined')}
          data-pr-position="top"
          type="button"
          label={t('CCFPP')}
          onClick={handleCCFPP}
        />
      </>
    );
  };

  // [KS] CMO-1239 - Hide Emergency fields for midwives
  // CMO-1488 - Hidden fields and default Fee code for specialty 44
  if (hideEmergencySection) return null;

  const emergencyActive = parseValue() === 'emergency' || parseValue() === 'travel' || parseValue() === 'ccfpp';

  return (
    <div id={elementIDs.emergencySectionWrap} style={{ minWidth: '318px' }}>
      <div className="p-buttonset flex w-full emergency_buttonset">
        {!isMobile && (
          <Tooltip target={`.emergency_tooltip`} showDelay={tooltipShowDelay}>
            <div style={{ maxWidth: '250px' }}>
              <EmergencyTooltipLayout />
            </div>
          </Tooltip>
        )}
        <Button
          id={elementIDs.emergencySwitch}
          icon={emergencyActive ? 'pi pi-check' : 'pi pi-times'}
          iconPos="right"
          className={cx('emergency_tooltip', emergencyActive ? 'p-button-success' : 'p-button-outlined')}
          data-pr-position="top"
          type="button"
          label={isWSBC ? t('Expedited') : t('OOFHP')}
          onClick={handleEmergency}
        />

        {!isMobile && (
          <Tooltip target={`.travel_tooltip`} showDelay={tooltipShowDelay}>
            <div style={{ maxWidth: '250px' }}>
              <TravelTooltipLayout />
            </div>
          </Tooltip>
        )}
        <Button
          id={elementIDs.travelSwitch}
          icon={parseValue() === 'travel' ? 'pi pi-check' : 'pi pi-times'}
          iconPos="right"
          className={cx('travel_tooltip', parseValue() === 'travel' ? 'p-button-success' : 'p-button-outlined')}
          data-pr-position="top"
          type="button"
          label={t('Call Out')}
          onClick={handleImmediateTravel}
        />

        {ccfppButton()}
      </div>
    </div>
  );
};

export default OutOfOfficeHoursPremiums;
