import { createRepresentative, editPatient, editRepresentative } from '../../../../modules/patients/actions/patients.action.creators';
import { getRepresentativeDefaultValues } from './defaultValues';

export const onRepresentativeSubmit = async (data, { patient, onSuccessCreate, onSuccessEdit }) => {
  if (data.formState.isNew) {
    // Create new representative
    delete data.Payer;
    delete data.formState;

    // Create new representative
    const template = getRepresentativeDefaultValues();
    const representativeData = Object.keys(template).reduce((acc, key) => {
      // If the form provided a value for this key, use it; otherwise, use the default
      acc[key] = key in data ? data[key] : template[key];
      return acc;
    }, {});

    delete representativeData.PatientGuid;
    delete representativeData.RepresentativeGuid;

    const newRepresentative = await createRepresentative(representativeData);
    newRepresentative.PatientGuid && (await editPatient({ ...patient, RepresentativeGuid: newRepresentative.PatientGuid }));
    onSuccessCreate?.(newRepresentative);
  } else {
    // Edit existing representative
    delete data.Payer;
    delete data.formState;
    const updatedRepresentative = await editRepresentative(data);
    onSuccessEdit?.(updatedRepresentative);
  }
};
