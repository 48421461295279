import React from 'react';
import LabelLayout from './LabelLayout';

const InputWithLabelLayout = (props) => {
  if (props.dir && props.dir === 'row') {
    return (
      <div className={'flex flex-row align-items-center'}>
        <div className="flex justify-content-between align-items-end">
          <LabelLayout {...props} />
        </div>

        {/* Input */}
        {props.children}
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-content-between">
        <LabelLayout {...props} />
      </div>

      {/* Input */}
      {props.children}
    </>
  );
};

export default InputWithLabelLayout;
