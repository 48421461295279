import React from 'react';
import { InputWrapper, Patient } from '../../../../components/Inputs';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';

export const patientFilterTemplate = (patientValue, setPatientValue, options) => (
  <Patient
    autoFocus
    name="patient"
    placeholder="Enter patient details"
    forceSelection={false}
    showAddPatientButton={false}
    value={patientValue}
    multiple={false}
    onChange={(e) => {
      setPatientValue(e.target.value);
      options.filterCallback(e.value, options.index);
    }}
  />
);

export const authorRowFilterTemplate = (memberValue, setMemberValue, memberItems, onMemberSearch, options) => (
  <InputWrapper label="User">
    <AutoComplete
      autoFocus
      inputId="userInput"
      value={memberValue}
      suggestions={memberItems}
      completeMethod={onMemberSearch}
      onChange={(e) => {
        setMemberValue(e.value);
        options.filterCallback(e.value, options.index);
      }}
      dropdown
      placeholder="Select user"
    />
  </InputWrapper>
);

export const dateFilterTemplate = (selectedDate, setSelectedDate, options) => {
  return (
    <Calendar
      showOnFocus={false}
      autoFocus
      value={selectedDate}
      onChange={(e) => {
        setSelectedDate(e.value);
        options.filterCallback(e.value, options.index);
      }}
      showIcon
      dateFormat="mm/dd/yy"
      placeholder="mm/dd/yyyy"
      mask="99/99/9999"
      inputMode="numeric"
      style={{ maxWidth: '10rem' }}
    />
  );
};

export const recordNoFilterTemplate = (recordNo, setRecordNo, options) => (
  <InputText
    autoFocus
    value={recordNo}
    onChange={(e) => {
      setRecordNo(e.target.value);
      options.filterCallback(e.value, options.index);
    }}
    placeholder="Search"
  />
);
