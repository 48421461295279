import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { getGroupInvoicesTemplates, editGroupInvoicesTemplate, deleteGroupInvoicesTemplate } from '../../../claims/actions/claims.action.creators';
import { patientFullNameWithAge } from '../../../utils/patientFullName';
import GlobalSearch from '../../../dashboard/components/GlobalSearch';
import { setShowNewPatientSuccessPage } from '../../actions/patients.action.creators';
import { routes } from '../../../../routes/routes';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { Dialog } from 'primereact/dialog';

const GroupEdit = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const doctorGuid = useSelector(state => state.user.details.DoctorGuid);
    const privateRecord = useSelector(state => state.claims.privateRecord);
    // const storedGroup = useSelector(state => state.patients.selectedGroup);

    

    const [loading, setLoading] = useState(false);
    const [groupList, setGroupList] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        patients: []
    });
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [groupToDelete, setGroupToDelete] = useState(null);


    const fetchGroupDetail = async () => {
        setLoading(true);
        try {
            const groupDetails = await getGroupInvoicesTemplates(doctorGuid);
            setGroupList(groupDetails);
        } catch (error) {
            console.error('Error fetching groups:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchGroupDetail();
    }, []);

    // useEffect(() => {
    //     if (storedGroup) {
    //         setSelectedGroup(storedGroup);
    //         setFormData({
    //             name: storedGroup.Name,
    //             description: storedGroup.Description,
    //             patients: storedGroup.FirstStep?.PatientDetails || []
    //         });
    //     }
    // }, [storedGroup]);

    useEffect(() => {
        if(privateRecord){
            console.log('Private Record:', privateRecord);
            setFormData({ ...formData, patients: [...formData.patients, privateRecord.BillTo?.[0]] });
        }
    }, [privateRecord]);

    const handleEdit = (group) => {
        history.push({
            pathname: `${routes.createPatient.path}/new`,
            state: { selectedGroup: group, activateGroup: true }
        });
    };

    const confirmDelete = (group) => {
        setGroupToDelete(group);
        setDeleteDialogVisible(true);
    };
    

    const handleDelete = async () => {
        if (!groupToDelete) return;
    
        setLoading(true);
        try {
            await deleteGroupInvoicesTemplate(groupToDelete.TemplateGuid, doctorGuid);
            dispatch(setToastMessage({ type: 'success', message: `Group "${groupToDelete.Name}" deleted successfully.` }));
            await fetchGroupDetail(); // Refresh the list
        } catch (error) {
            console.error("Error deleting group:", error);
            dispatch(setToastMessage({ type: 'error', message: 'Failed to delete the group. Please try again.' }));
        } finally {
            setLoading(false);
            setDeleteDialogVisible(false);
            setGroupToDelete(null);
        }
    };
        

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAddPatient = (selectedPatient) => {
        console.log('Selected Patient:', selectedPatient);
        if (!formData.patients.some((p) => p.PatientGuid === selectedPatient.PatientGuid)) {
            setFormData({ ...formData, patients: [...formData.patients, selectedPatient] });
        }
    };

    const handleRemovePatient = (PatientGuid) => {
        setFormData({
            ...formData,
            patients: formData.patients.filter((p) => p.PatientGuid !== PatientGuid)
        });
    };

    const handleSubmit = async () => {
        if (!selectedGroup) return;
    
        setLoading(true);
        try {
            // Create a new object that keeps everything the same except for Name, Description, and Patients
            const updatedGroup = {
                ...selectedGroup, // Keep everything as it was
                Name: formData.name, // Updated name
                Description: formData.description, // Updated description
                Status: 2, // Updated status
                FirstStep: {
                    ...selectedGroup.FirstStep, // Keep other FirstStep properties
                    PatientDetails: formData.patients // Updated patients list
                }
            };
    
            await editGroupInvoicesTemplate(updatedGroup);
    
            await fetchGroupDetail(); // Refresh the group list

            setSelectedGroup(null);  // Go back to list view

        } catch (error) {
            console.error("Error updating group:", error);
        }
        setLoading(false);
    };  
    
    const onAddClick = () => {
        // dispatch({ type: "SET_SELECTED_GROUP", payload: selectedGroup });
        dispatch(setShowNewPatientSuccessPage({ showSuccessPage: false, from: 'privateClaim' }));
        history.push(`${routes.createPatient.path}/new`);
      };

      const actionBodyTemplate = (rowData) => (
        <div className="flex justify-content-end gap-2 w-full">
            {/* Edit Button */}
            <Button
                icon="pi pi-pencil"
                rounded
                text
                className="p-button-primary"
                onClick={() => handleEdit(rowData)}
                tooltip="Edit Group"
                tooltipOptions={{ position: 'top' }}
            />
            
            {/* Delete Button */}
            <Button
                icon="pi pi-trash"
                rounded
                text
                className="p-button-danger"
                onClick={() => confirmDelete(rowData)}
                tooltip="Delete Group"
                tooltipOptions={{ position: 'top' }}
            />
        </div>
    );

    const title = () => (
        <div className="flex justify-content-between align-items-center">
        <span>{selectedGroup ? "Edit Group" : "Manage Groups"}</span>
        <Button 
            label="Add New Group" 
            icon="pi pi-plus" 
            onClick={() => history.push({
                pathname: `${routes.createPatient.path}/new`,
                state: { activateGroup: true }
            })}
        />
    </div>
    );
    
    const skeletonTemplate = () => <Skeleton width="100%" height="2rem" />;

    return (
        <div className="flex justify-content-center p-4">
            <Card title={title} className="w-full">
                {selectedGroup ? (
                    //  **Edit Group Form (Appears in the same card)**
                    <div>
                        <div className="p-field">
                            <label htmlFor="name">Group Name</label>
                            <InputText
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full"
                            />
                        </div>

                        <div className="p-field mt-2">
                            <label htmlFor="description">Description</label>
                            <InputText
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                className="w-full"
                            />
                        </div>

                        {/* Add Patients Section */}
                        <div className="p-field mt-3">
                            <h4>Patients</h4>
                            {/* <PatientSearch/> */}
                            <GlobalSearch
                                name="patient"
                                placeholder="Search patient"
                                onSelectPatient={handleAddPatient}
                            />
                            <Button
                                label="Add Patient"
                                onClick={onAddClick}
                            />
                            {formData.patients.length > 0 ? (
                                <ul className="list-none p-0 m-0">
                                    {formData.patients.map((patient, pIndex) => (
                                        <li key={patient.id || pIndex} className="flex align-items-center justify-content-between p-2">
                                            <span><strong>Patient:</strong> {patientFullNameWithAge(patient)}</span>
                                            <Button
                                                icon="pi pi-times"
                                                className="p-button-text p-button-danger"
                                                onClick={() => handleRemovePatient(patient.PatientGuid)}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No patients found.</p>
                            )}
                        </div>

                        {/* Save & Cancel Buttons */}
                        <div className="flex justify-content-end mt-4">
                            <Button label="Save Changes" onClick={handleSubmit} />
                            <Button
                                label="Cancel"
                                outlined
                                className="p-button-text ml-2"
                                onClick={() => setSelectedGroup(null)}
                            />
                        </div>
                    </div>
                ) : (
                    //  **Group List Table**
                    loading ? (
                        <DataTable value={[1, 2, 3]} paginator rows={20}>
                            <Column field="Name" header="Group Name" body={skeletonTemplate} />
                            <Column field="Description" header="Description" body={skeletonTemplate} />
                            <Column body={skeletonTemplate} />
                        </DataTable>
                    ) : (
                        <DataTable value={groupList} paginator rows={20}>
                            <Column field="Name" header="Group Name" sortable />
                            <Column field="Description" header="Description" sortable />
                            <Column body={actionBodyTemplate} />
                        </DataTable>
                    )
                )}
            </Card>
            <Dialog
                visible={deleteDialogVisible}
                onHide={() => setDeleteDialogVisible(false)}
                header="Confirm Deletion"
                modal
                footer={
                    <div className="flex justify-content-center gap-1">
                        <Button outlined label="Cancel" icon="pi pi-times" onClick={() => setDeleteDialogVisible(false)} />
                        <Button label="Delete" icon="pi pi-check" onClick={handleDelete} />
                    </div>
                }
            >
                <p className="text-center">Are you sure you want to delete the group <strong>{groupToDelete?.Name}</strong>?</p>
            </Dialog>

        </div>
    );
};

export default GroupEdit;
