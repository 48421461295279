import { validate } from 'uuid';
import { store } from '../../store';
import {
  NA_DoctorGuid,
  allPractitionerOption,
  multipleDoctorGuid,
  multiplePractitionerOption,
  naPractitionerOption
} from '../config/defaultValuesConfig';
import { prefsCodes } from '../config/prefsCodesConfig';
import { specialities } from '../config/specialitiesConfig';
import { isEmpty, sortBy } from 'lodash';
import { t } from '../../service/localization/i18n';
import { getDefaultPrefs } from '../claims/helpers/getDefaultPrefs';
import { activePractitionerStatuses } from '../config/statuses';

// interface Practitioner {
//   DoctorGuid: string;
//   Speciality: number;
//   LastName: string;
//   FirstName: string;
//   // Add any other properties from the practitioner object
// } | string

export const getPractitionerNameWithSpec = (practitioner, { naOption, multipleOption, allOption } = {}) => {
  const isObj = typeof practitioner === 'object' && !isEmpty(practitioner);
  const isString = typeof practitioner === 'string' && validate(practitioner);

  const getName = (doc) => {
    const memeberSpecTitle = specialities.find((i) => Number(i.value) === Number(doc.Speciality))?.label;
    return `${doc?.LastName}, ${doc?.FirstName} (${memeberSpecTitle})`;
  };

  if (isObj) {
    return getName(practitioner);
  }

  if (isString) {
    const currentPractitioner = getPractitionerByGuid(practitioner, { naOption, multipleOption, allOption });
    if (!currentPractitioner) return t('N_A');
    return getName(currentPractitioner);
  }

  return t('N_A');
};

export const getPractitionersList = ({ naOption, multipleOption, allOption, registeredOnly } = {}) => {
  const members = store.getState().clinic.members;

  // Filter members if registeredOnly is true (Only practitioners with a 5-digit PractitionerNumber)
  const filteredMembers = registeredOnly ? members.filter((practitioner) => practitioner.PractitionerNumber?.length === 5) : members;

  const safeList =
    filteredMembers.map((practitioner) => {
      const nameWithSpec = getPractitionerNameWithSpec(practitioner, { naOption, multipleOption, allOption });
      return { ...practitioner, label: `${nameWithSpec}`, value: practitioner.DoctorGuid };
    }) || [];

  const sortedList = sortBy(safeList, (x) => x.FullName);

  // Add "N/A" or "All" option if applicable
  const suggestionsWithNA =
    safeList.length > 1 && allOption
      ? [allPractitionerOption, ...sortedList]
      : safeList.length > 1 && naOption
      ? [naPractitionerOption, ...sortedList]
      : sortedList;

  // Add "Multiple Practitioners" option
  const suggestionsWithMultiple = multipleOption ? [multiplePractitionerOption, ...suggestionsWithNA] : suggestionsWithNA;

  // WIN-127: Include only active users (UserStatus in [0,1,2])
  const activeOnly = suggestionsWithMultiple.filter((i) => activePractitionerStatuses.includes(Number(i.UserStatus)));

  return activeOnly;
};

export const getPractitionerIdFromUserPrefs = () => {
  const user = store.getState().user.details;
  const currentUserPrefs = store.getState().preferences.currentUserPrefs;
  const currentUserDefaultPrefs = currentUserPrefs?.prefs?.find((i) => i.label === 'default');
  const currentPractitionerId = currentUserDefaultPrefs?.content?.find((i) => i.label === prefsCodes.defaultPractitioner)?.value;
  const practitionerId =
    currentPractitionerId === NA_DoctorGuid || currentPractitionerId === multipleDoctorGuid ? user?.DCNGuid : currentPractitionerId;

  return practitionerId;
};

export const getPractitionerGuid = (practitionerGuid) => {
  const user = store.getState().user.details;
  const isNA = practitionerGuid === NA_DoctorGuid || practitionerGuid === multipleDoctorGuid;

  if (isNA) return user.Role === 'rcp' || isNA ? user.DCNGuid : user.UserGuid;
  return practitionerGuid;
};

export const getPractitionerByGuid = (practitionerGuid, { naOption, multipleOption, allOption } = {}) => {
  const practitionersList = getPractitionersList({ naOption, multipleOption, allOption });
  const currentPractitioner = practitionersList?.find((i) => {
    return i.DoctorGuid?.toUpperCase() === practitionerGuid?.toUpperCase();
  });

  return currentPractitioner;
};

export const getInitPractitionerGuid = ({ naOption = true, multipleOption, allOption } = {}) => {
  const prefs = store.getState().preferences;
  const currentUserDefaultPrefs = prefs?.currentUserPrefs?.prefs?.find((i) => i.label === 'default');
  const members = store.getState().clinic.members;
  const DCNGuid = store.getState().user.details.DCNGuid;
  const prefsPractitionerGuid = getDefaultPrefs(currentUserDefaultPrefs?.content, prefsCodes.defaultPractitioner);

  const getDefaultPractitioner = () => {
    const defaultDocGuid = members?.find((i) => i.Role.toLowerCase() !== 'rcp')?.DoctorGuid;
    if (defaultDocGuid) {
      return defaultDocGuid;
    } else {
      if (allOption) return NA_DoctorGuid;
      if (naOption) return NA_DoctorGuid;
      if (multipleOption) return multipleDoctorGuid;
      return Array.isArray(members) && members?.length ? members[0]?.DoctorGuid : '';
    }
  };

  const defaultPractitioner = getDefaultPractitioner();

  // CMO-3096 - Teleplan - practitioner value is "-2"
  // Return default practitioner if preference is multiple practitioenr (practitioner guid is -2)
  if (prefsPractitionerGuid === multipleDoctorGuid) return defaultPractitioner;

  if (prefsPractitionerGuid && prefsPractitionerGuid !== DCNGuid) {
    return prefsPractitionerGuid;
  }
  // Introduce bug when there are no prefs for practitioner (empty string) and Role is not "rcp" then in the practitioner input will be displayed user guid
  // Do we need this condition???
  // else if (!prefsPractitionerGuid && user?.Role?.toLowerCase() !== 'rcp') {
  //   return user?.UserGuid;
  // }
  else {
    return defaultPractitioner;
  }
};
