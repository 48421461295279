import React from 'react';
import { useCatalogsContext } from '../../hooks/useCatalogsContext';
import { SlideMenu } from '../../../Menus/SlideMenuACI/SlideMenuACI';
import { t } from '../../../../service/localization/i18n';

const Categories = (props) => {
  const { catalogsState, setCatalogsState, slideMenuRef } = useCatalogsContext();

  const onNavigate = (data) => {
    const trail = data.map((x) => ({ label: x.label }));
    setCatalogsState((prevState) => ({ ...prevState, trail }));
  };

  const navigateBack = () => {
    setEmptyTableMessage();
    const sliced = catalogsState.drillDown.slice(0, -1);
    const _categoryValue = sliced?.length
      ? catalogsState.drillDown[catalogsState.drillDown?.length - 1]?.value
      : catalogsState?.activeTab?.searchAllType;

    setCatalogsState((prevState) => ({
      ...prevState,
      categoryValue: _categoryValue,
      drillDown: sliced //remove last item when user drills up throught the slide menu
    }));
  };

  const setEmptyTableMessage = () => {
    setCatalogsState((prevState) => ({
      ...prevState,
      tableData: { codeList: [] },
      emptyMessage: t('Please_select_a_category_on_the_left')
    }));
  };

  return (
    <SlideMenu
      ref={slideMenuRef}
      blockScroll
      backLabel={t('Categories')}
      style={{ width: '17.5rem', height: '100%' }}
      menuWidth={props.menuWidth}
      model={catalogsState.menuModel}
      viewportHeight={props.menuHeight}
      onBack={navigateBack}
      onNavigate={onNavigate}
    />
  );
};

export default Categories;
