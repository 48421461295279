import moment from 'moment';

export const referralCreditsDefaultFilters = {
  PeriodFrom: moment(moment().subtract(24, 'months').toDate()).format('YYYY/MM/DD'),
  PeriodTo: '',
  PatientGuid: '',
  DoctorGuid: ''
};

export const referralCreditsDefaultSortBy = {
  sortField: 'CreditStatus',
  sortOrder: -1
};
