import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from './routes/routes';

import Admin from './modules/auth/components/Admin/Admin';
import Legacy from './modules/auth/components/Legacy/Legacy';
import SignIn from './modules/auth/components/SignIn/SignIn';
import VerificationBureau from './modules/auth/components/VerificationBureau/VerificationBureau';

import PhoneVerificationActivation from './modules/auth/components/VerificationPhone/PhoneVerificationActivation';
import AskSecretQuestions from './modules/auth/components/AskSecretQuestion/AskSecretQuestion';
import PasswordRecovery from './modules/auth/components/PasswordRecovery/PasswordRecovery';
import AuthPrivacy from './modules/auth/components/AuthPrivacy/AuthPrivacy';
import AuthPrivacyAccept from './modules/auth/components/AuthPrivacyAccept/AuthPrivacyAccept';
import AuthPrivacyDeny from './modules/auth/components/AuthPrivacyDeny/AuthPrivacyDeny';
import RegisterPrivacy from './modules/registration/components/Privacy/RegisterPrivacy';
import RegisterPrivacyAccept from './modules/registration/components/Privacy/RegisterPrivacyAccept';
import RegisterPrivacyDeny from './modules/registration/components/Privacy/RegisterPrivacyDeny';
import withRequireJwtToken from './modules/hocs/withRequireJwtToken';
import withRequireIdParams from './modules/hocs/withRequireIdParams';
import withRequirePrivacy from './modules/hocs/withRequirePrivacy';
import RegistrationViews from './modules/registration/components/index';
import withRequirePasswordRecovery from './modules/hocs/withRequirePasswordRecovery';
import PasswordUpdate from './modules/auth/components/PasswordUpdate/PasswordUpdate';
import withRequirePasswordUpdate from './modules/hocs/withRequirePasswordUpdate';
import AuthCreateSecretQuestions from './modules/auth/components/AuthCreateSecretQuestions/AuthCreateSecretQuestions';
import { shouldUsePin } from './modules/utils/pinCode';
import SignInWithPin from './modules/auth/components/SignInWithPin';
import withRequirePin from './modules/hocs/withRequirePin';
import ConfirmationSendingTempCode from './modules/auth/components/AskSecretQuestion/ConfirmationSendingTempCode';
import ActivateAccount from './modules/registration/components/ActivateAccount';
import UsernameCreatedSuccess from './modules/registration/components/UsernameCreatedSuccess';
import PasswordRecoveryError from './modules/auth/components/AskSecretQuestion/PasswordRecoveryError';
import TemporarilyAccountBlockError from './modules/auth/components/SignIn/Steps/TemporarilyAccountBlockError';
import UsernameCreatedExpiration from './modules/registration/components/UsernameCreatedExpiration';
import withBureauUser from './modules/hocs/withBureauUser';
import Affiliates from './modules/auth/components/Bureau/Affiliates';
import withRedirectAdmin from './modules/hocs/withRedirectAdmin';
import withRedirectLegacy from './modules/hocs/withRedirectLegacy';

const NavAuth = () => {
  return (
    <Switch>
      <Route exact path={routes.signIn.path}>
        <SignIn />
      </Route>

      <Route exact path={routes.signInWithPin.path} component={withRequirePin(SignInWithPin)} />

      <Route exact path={routes.affiliates.path} component={withBureauUser(Affiliates)} />

      {/* user.JwtToken */}
      <Route exact path={routes.verificationBureau.path} component={withRequireJwtToken(VerificationBureau)} />

      {/* user.JwtToken */}
      <Route exact path={routes.verificationPhone.path} component={withRequireJwtToken(PhoneVerificationActivation)} />

      {/****** user.sessionGuid or user.UserStatus === 406 or user.UserStatus === 429******/}
      <Route exact path={routes.askSecretQuestions.path} component={withRequirePasswordRecovery(AskSecretQuestions)} />

      <Route exact path={routes.passwordRecoveryError.path} component={withRequirePasswordRecovery(PasswordRecoveryError)} />

      <Route exact path={routes.temporarilyAccountBlockError.path} component={withRequirePasswordRecovery(TemporarilyAccountBlockError)} />

      <Route exact path={routes.confirmationSendingTempCode.path} component={withRequirePasswordRecovery(ConfirmationSendingTempCode)} />

      {/****** user.sessionGuid ******/}
      <Route exact path={routes.passwordRecovery.path} component={withRequirePasswordRecovery(PasswordRecovery)} />

      {/****** user.UserStatus === 426 ******/}
      <Route exact path={routes.passwordUpdate.path} component={withRequirePasswordUpdate(PasswordUpdate)} />

      {/* id */}
      <Route exact path={`${routes.activate.path}/:sessionGuid`} component={withRequireIdParams(ActivateAccount)} />

      {/* ************* */}
      <Route exact path={`${routes.activate.path}/:sessionGuid/success`} component={withRequireIdParams(UsernameCreatedSuccess)} />
      <Route exact path={`${routes.activate.path}/:sessionGuid/expiration`} component={withRequireIdParams(UsernameCreatedExpiration)} />

      {/* 'code', 'DoctorGuid', 'DCNGuid' */}
      <Route exact path={routes.admin.path} component={withRedirectAdmin(Admin)} />

      {/* 'source', 'DCNGuid', 'UserGuid', 'key'*/}
      <Route exact path={routes.legacy.path} component={withRedirectLegacy(Legacy)} />

      {/* ****** Auth Privacy ******* */}

      {/* user.JwtToken */}
      <Route exact path={routes.authPrivacy.path} component={withRequireJwtToken(AuthPrivacy)} />

      {/* user.JwtToken */}
      <Route exact path={routes.authPrivacyAccept.path} component={withRequireJwtToken(AuthPrivacyAccept)} />

      {/* user.JwtToken */}
      <Route exact path={routes.authPrivacyDeny.path} component={withRequireJwtToken(AuthPrivacyDeny)} />

      {/* user.JwtToken */}
      <Route exact path={routes.createAuthSecretQuestions.path} component={withRequireJwtToken(AuthCreateSecretQuestions)} />

      {/* ****** Registration Privacy ******* */}

      {/* formState.sessionGuid */}
      <Route exact path={routes.registerPrivacy.path} component={withRequirePrivacy(RegisterPrivacy)} />

      {/* formState.sessionGuid */}

      <Route exact path={routes.registerPrivacyAccept.path} component={withRequirePrivacy(RegisterPrivacyAccept)} />

      {/* formState.sessionGuid */}
      <Route exact path={routes.registerPrivacyDeny.path} component={withRequirePrivacy(RegisterPrivacyDeny)} />

      {/* ************* */}

      <Route exact path={routes.registration.path}>
        <RegistrationViews />
      </Route>

      <Route path="*">
        <Redirect to={shouldUsePin() ? routes.signInWithPin.path : routes.signIn.path} />
      </Route>
    </Switch>
  );
};

export default NavAuth;
