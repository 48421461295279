import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { Button } from 'primereact/button';
import { v4 as uuidv4 } from 'uuid';

import LastName from '../inputs/LastName';
import FirstName from '../inputs/FirstName';
import Email from '../inputs/Email';
import EmailRepeat from '../inputs/EmailRepeat';
import PhoneNumber from '../inputs/PhoneNumber';
import RoleInClinic from '../inputs/RoleInClinic';
import ConfirmPassword from '../inputs/ConfirmPassword';
import RegistrationPassword from '../inputs/RegistrationPassword';
import HearAboutUs from '../inputs/HearAboutUs';
import TeleplanDataCenterNumber from '../inputs/TeleplanDataCenterNumber';
import ClinicBusinessNumber from '../inputs/ClinicBusinessNumber';
import Website from '../inputs/Website';
import PostalCode from '../inputs/PostalCode';
import Province from '../inputs/Province';
import City from '../inputs/City';
import StreetAddress from '../inputs/StreetAddress';
import ClinicName from '../inputs/ClinicName';
import ClinicOrganizationType from '../inputs/ClinicOrganizationType';
import { clinicDefaultValues } from '../helpers/clinicDefaultValues';

import RegistrationFormWrapper from '../RegistrationCard';

import { InputGroup } from '../../../../components/Inputs';
import { routes } from '../../../../routes/routes';
import { updateRegistrationFormState } from '../../actions/registration.actions.creators';

const ClinicForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const registrationState = useSelector((state) => state.registration.registrationFormState);

  const methods = useForm({ mode: 'all', defaultValues: { ...clinicDefaultValues, ...registrationState.initialFormState } });

  const { handleSubmit, reset, watch, getValues, formState, control } = methods;

  const clinicOrganizationalTypeStatus = watch('clinicType') === 'Sole Proprietorship';

  const onSubmit = (data) => {
    dispatch(
      updateRegistrationFormState({
        ...registrationState,
        type: 'register-practitioner-attached',
        initialFormState: { ...data, sessionGuid: uuidv4() },
        firstStepType: 'register-clinic'
      })
    );
  };

  const onClear = () => {
    reset(clinicDefaultValues);
  };

  const onCancel = () => {
    history.push(routes.signIn.path);
  };

  const onBack = () => {
    dispatch(
      updateRegistrationFormState({
        ...registrationState,
        type: 'register-type',
        initialFormState: { ...getValues(), password: '', confirmPassword: '' }
      })
    );
  };

  return (
    <FormProvider {...methods}>
      <RegistrationFormWrapper
        customersType="Open Account for Medical or Health Care Clinic"
        description="Select this option if you are the Account Administrator registering on behalf of a Clinic. An Account Administrator is a designated
              individual responsible for managing and overseeing the setup, access rights, and maintenance of an account within an organization. If
              you are not authorized, please abort this action."
      >
        <form onSubmit={handleSubmit(onSubmit)} className="grid formgrid">
          <InputGroup>
            <FirstName label="Your First Name (Account Administrator)" />
            <LastName label="Your Last Name (Account Administrator)" />
          </InputGroup>
          <InputGroup>
            <Email control={control} requiredStatus={true} />
            <EmailRepeat />
          </InputGroup>

          <InputGroup>
            <PhoneNumber control={control} requiredStatus={true} />
            <ClinicOrganizationType />
          </InputGroup>
          <RoleInClinic />

          <InputGroup>
            <TeleplanDataCenterNumber label="Clinic Teleplan Data Center Number" />
            {!clinicOrganizationalTypeStatus && <ClinicName />}
          </InputGroup>

          <InputGroup>
            <StreetAddress label="Clinic Street Address" requiredStatus={true} />
            <City requiredStatus={true} />
          </InputGroup>
          <InputGroup>
            <Province requiredStatus={true} />
            <PostalCode requiredStatus={true} />
          </InputGroup>
          <InputGroup>
            <Website label="Clinic Website" name="website" />
            <ClinicBusinessNumber />
          </InputGroup>
          <HearAboutUs />
          <InputGroup>
            <RegistrationPassword />
            <ConfirmPassword />
          </InputGroup>
          <div className="flex gap-3 aling-items-center justify-content-center mt-6 mb-4 w-full">
            <Button data-testid="nextButtonClinicForm" type="submit" label="Next" loading={formState.isSubmitting} />
            <Button data-testid="clearButtonClinicForm" onClick={onClear} type="button" label="Clear" outlined />
            <Button data-testid="cancelButtonClinicForm" onClick={onCancel} type="button" label="Cancel" outlined />
            <Button data-testid="backButtonClinicForm" onClick={onBack} type="button" label="Back" outlined />
          </div>
        </form>
      </RegistrationFormWrapper>
    </FormProvider>
  );
};

export default ClinicForm;
