import axios from 'axios';
import { store } from '../../../store';
import actions from './cashFlow.action.types';
import { getApiBase, setAuthHeader } from '../../../config';
import { cashFlowInitialState } from '../reducers/cashFlow.reducer';

export const getCashFlow = ({ page = 1, pageSize = 20, sortby = cashFlowInitialState.requestParams.sortby, query, patientGuid, type }) => {
  // `type` options: 0 - custom filters, 1 - day , 2 - week, 3 - month, 4 - year
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const queryParams = new URLSearchParams();
  if (patientGuid) queryParams.append('pid', patientGuid); // Patient ID
  if (sortby) queryParams.append('sortby', sortby); // Sort by
  if (query) queryParams.append('q', JSON.stringify(query)); // Filters
  const url = `${getApiBase()}/claims/${DCNGuid}/chart/${type}/?${queryParams.toString()}`;

  store.dispatch({ type: actions.SET_CASH_FLOW_LOADING, results: true });

  return axios
    .get(url)
    .then((results) => {
      store.dispatch({ type: actions.SET_CASH_FLOW_LOADING, results: false });
      return results.data;
    })
    .catch((reason) => {
      store.dispatch({ type: actions.SET_CASH_FLOW_LOADING, results: false });
      console.log('!!!getCashFlowAction', reason);
    });
};

export const setCashFlowData = (data) => (dispatch) => {
  return dispatch({ type: actions.SET_CASH_FLOW_DATA, results: data });
};

export const setCashFlowQuery = (query) => (dispatch) => {
  return dispatch({ type: actions.SET_QUERY, results: query });
};

export const resetCashFlowState = () => (dispatch) => {
  return dispatch({ type: actions.RESET_CASH_FLOW_STATE });
};

export const setClaimsDialogVisibility = (payload) => (dispatch) => {
  return dispatch({ type: actions.SET_CLAIMS_DIALOG_VISIBILITY, results: payload });
};
