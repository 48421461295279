import React from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import PayTo from './PayTo';
import PayToEmail from './PayToEmail';
import PayToAddress from './PayToAddress';
import PayToPhone from './PayToPhone';

import { elementIDs } from '../../../../config/elementIDsConfig';
import { t } from '../../../../../service/localization/i18n';

const EditPayToAddress = ({ showAddressDialog, setShowAddressDialog }) => {
  const onHide = () => {
    setShowAddressDialog(false);
  };

  return (
    <Dialog
      id={elementIDs.editPayeeAndPayerDialog}
      header="Edit Payee"
      visible={showAddressDialog}
      blockScroll
      className="w-full max-w-30rem"
      onHide={onHide}
      baseZIndex={999999}
    >
      <PayTo />
      <PayToEmail />
      <PayToPhone />
      <PayToAddress />

      <div className="mt-2">
        <Button className="w-full" type="button" label={t('Close')} onClick={onHide} />
      </div>
    </Dialog>
  );
};

export default EditPayToAddress;
