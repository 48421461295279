import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Payer from './Payer';
import PatientFormLayout from '../PatientForm/PatientFormLayout';
import RepresentativeFormLayout from '../Representative/RepresentativeFormLayout';

import { formatPhoneNumber } from '../../../modules/utils/formatPhoneNumber';
import { editPatient } from '../../../modules/patients/actions/patients.action.creators';
import { billToOptionTypes } from './constants';
import { getRepresentativeDefaultValues } from '../Representative/helpers/defaultValues';
import { onRepresentativeSubmit } from '../Representative/helpers/onSubmit';
import { defaultAddressType, defaultCountryCode } from '../../../modules/config/defaultValuesConfig';

// interface BillToFormProps {
//   invoice: Invoice;
//   patient: Patient;
//   representative: Representative;
//   onSubmitPatient: (updatedPatient: Patient) => void;
//   onSubmitRepresentative: (response: Representative) => void;
//   onClose: () => void;
//   loading: boolean; // Getting representative or patient or invoice
//   onPayerChange?: (Patient | Representative) => void;
// }

const BillToForm = ({ invoice, patient, representative, onSubmitPatient, onSubmitRepresentative, onClose, loading, onPayerChange }) => {
  const billTo = Array.isArray(invoice?.BillTo) ? invoice?.BillTo[0] : invoice?.BillTo;
  const defaultValues = getRepresentativeDefaultValues();

  const formatPatient = (patientData) => {
    return {
      ...patientData,
      Country: patientData?.Country || defaultCountryCode,
      AddressType: patientData?.AddressType || defaultAddressType,
      Phone: formatPhoneNumber(patientData?.Phone),
      EmailType: patientData?.EmailType || defaultValues.EmailType,
      PhoneType: patientData?.PhoneType || defaultValues.PhoneType
    };
  };

  const getPayerValue = () => {
    const billToGuid = billTo?.PatientGuid;
    const patientGuid = patient?.PatientGuid;

    if (billToGuid && billToGuid !== patientGuid) {
      return billToOptionTypes.REPRESENTATIVE;
    }

    if (billToGuid === patientGuid) {
      return billToOptionTypes.PATIENT;
    } else {
      return billToOptionTypes.REPRESENTATIVE;
    }
  };

  // Create a function that computes the default values
  const computeDefaultValues = useMemo(() => {
    // Determine who is the payer
    const payer = getPayerValue();
    const initPatient = billTo;

    return {
      Payer: payer,
      ...initPatient,
      ...formatPatient(initPatient),
      formState: {
        patient: formatPatient(patient),
        representative: representative ? formatPatient(representative) : defaultValues,
        isNew: !representative // If there is no representative, it's a new one
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient, representative, billTo]);

  // Initialize the form with empty defaults (or minimal safe values)
  const methods = useForm({
    defaultValues: {} // Initially empty until async data arrives
  });

  // Set the default values
  useEffect(() => {
    !loading && methods.reset(computeDefaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // Watch the payer field to determine which layout to show
  const isRepresentative = methods.watch('Payer') === billToOptionTypes.REPRESENTATIVE;

  const onRepresentativeSuccessSubmit = (data) => {
    onSubmitRepresentative?.(data);
    onClose(data);
  };

  const onSubmit = async (data) => {
    const payer = data.Payer;

    // Edit patient
    if (payer === billToOptionTypes.PATIENT) {
      // Save the payer value and remove extra form state fields
      delete data.Payer;
      delete data.formState;

      const patientResponse = await editPatient(data);
      if (patientResponse) {
        onSubmitPatient?.(patientResponse);
        onClose(patientResponse);
      }
    }

    // Create/Edit representative
    if (payer === billToOptionTypes.REPRESENTATIVE) {
      await onRepresentativeSubmit(data, { onSuccessCreate: onRepresentativeSuccessSubmit, onSuccessEdit: onRepresentativeSuccessSubmit, patient });
      methods.reset();
    }
  };

  if (loading) {
    return (
      <div className="h-30rem flex justify-content-center align-items-center">
        <div className="flex gap-4 align-items-center">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
          <div className="text-3xl">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form id="patient-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Payer onChange={onPayerChange} />
        {isRepresentative ? <RepresentativeFormLayout onClose={onClose} /> : <PatientFormLayout onClose={onClose} />}
      </form>
    </FormProvider>
  );
};

export default BillToForm;
