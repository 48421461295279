import { store } from '../../../../../store';
import { icons } from '../../../../config/stylesConfig';
import { t } from '../../../../../service/localization/i18n';
import { routes } from '../../../../../routes/routes';
import { updateNoteStatus } from '../../../actions/claims.action.creators';
import { setClaimNoteDetails } from '../../../../notes/actions/actions';

export const rowActionsMenuItems = (props) => {
  const dispatch = store.dispatch;
  const showEdit = props.rowData.DoctorGuid === store.getState().user.details.DoctorGuid;

  let commonItems = [
    {
      id: 'view',
      icon: icons.info,
      label: t('View'),
      command: () => viewNote(props.rowData, props.history)
    },
    {
      id: 'profile',
      icon: icons.invoice,
      label: 'Claim profile',
      command: () => viewProfile(props.rowData, props.history)
    },
    {
      id: 'reply',
      icon: 'pi pi-reply',
      label: 'Reply',
      command: () => viewReply(props.rowData, props.history, dispatch)
    },
    ...(showEdit
      ? [
          {
            id: 'edit',
            icon: 'pi pi-pencil',
            label: 'Edit',
            command: () => viewEdit(props.rowData, props.history, dispatch)
          }
        ]
      : []),
    {
      id: 'delete',
      icon: icons.delete,
      label: t('Delete'),
      command: props.onDelete
    }
  ];

  if (props.isView) {
    commonItems = commonItems.filter((item) => item.id !== 'view');
  }

  return commonItems;
};

export const viewNote = (noteDetails, history) => {
  updateNoteStatus({ ...noteDetails, Status: 1 });
  store.dispatch(setClaimNoteDetails(noteDetails));
  history.push(`${routes.claimNotesInbox.path}/view/${noteDetails.NoteGuid}`);
};

export const viewReply = (noteDetails, history, dispatch) => {
  updateNoteStatus({ ...noteDetails, Status: 1 });
  dispatch(setClaimNoteDetails(noteDetails));
  history.push(`${routes.claimNotesInbox.path}/reply`);
};

export const viewEdit = (noteDetails, history, dispatch) => {
  updateNoteStatus({ ...noteDetails, Status: 1 });
  dispatch(setClaimNoteDetails(noteDetails));
  history.push(`${routes.claimNotesInbox.path}/edit/${noteDetails.NoteGuid}`);
};

export const viewProfile = (noteDetails, history) => {
  history.push(`${routes.claimProfile.path}/${noteDetails.EntityGuid}`);
};
