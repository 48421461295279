import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import { routes } from '../../../routes/routes';
import { instructionIds } from '../helpers/instructionIds';
import { getFromLocalStorage } from '../../utils/getFromLocalStorage';
import { localStorageKeys } from '../../config/localStorageKeysConfig';

const AddToHomeScreenToastInstruction = () => {
  const toastRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const isMessageDismissed = getFromLocalStorage(localStorageKeys.addToHomeScreenDismissed);

    // Detect the browser and platform
    const userAgent = navigator.userAgent;
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent); // Detect iOS devices
    const isAndroid = /Android/i.test(userAgent); // Detect Android devices
    const showMessge = false;
    if (!isMessageDismissed && showMessge) {
      if (isIOS) {
        showToast(
          'Add to Home Screen',
          'In your <b>Safari browser</b>, tap <b>Share</b> (at the bottom of the screen) and then <b>"Add to Home Screen"</b> to save this app. You can access it like any other app. '
        );
      } else if (isAndroid) {
        showToast(
          'Add to Home Screen',
          'In your <b>Chrome browser</b>, tap the <b>three-dot menu</b> (in the top-right corner) and then <b>"Add to Home Screen"</b> to save this app. You can access it like any other app. '
        );
      }
    }
  }, []);

  const showToast = (summary, detail) => {
    if (toastRef.current) {
      toastRef.current.show({
        severity: 'info',
        summary,
        detail: (
          <div>
            <span dangerouslySetInnerHTML={{ __html: detail }} />
            <Button
              className="p-0 text-blue-700 underline pointer"
              link
              onClick={() => {
                history.push(`${routes.instructions.path}/${instructionIds.shortcut}`);
                closeToast();
              }}
            >
              Learn more
            </Button>
          </div>
        ),
        closable: true,
        sticky: true // Keeps the toast open until manually dismissed
      });
    }
  };

  const closeToast = () => {
    if (toastRef.current) {
      toastRef.current.clear(); // Programmatically close the toast
    }
    handleClose();
  };

  const handleClose = () => {
    localStorage.setItem(localStorageKeys.addToHomeScreenDismissed, 'true'); // Prevent showing it again
  };

  return <Toast ref={toastRef} onRemove={handleClose} />;
};

export default AddToHomeScreenToastInstruction;
