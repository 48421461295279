import React from 'react';
import { InputWrapper } from '..';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

const CollegeLicenceNumber = () => {
  const {
    control,
    formState: { errors },
    clearErrors
  } = useFormContext();

  return (
    <>
      <InputWrapper name="CollegeLicenceNumber" label="College License Number" errorMessage={errors.CollegeLicenceNumber?.message}>
        <Controller
          name="CollegeLicenceNumber"
          control={control}
          render={({ field }) => (
            <InputText
              id="CollegeLicenceNumber"
              data-testid="collegeLicenceNumber-testid"
              {...field}
              maxLength={10}
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(value);
                value && errors.CollegeLicenceNumber?.message && clearErrors('CollegeLicenceNumber');
              }}
              className={classNames({ 'p-invalid': errors.CollegeLicenceNumber })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default CollegeLicenceNumber;
