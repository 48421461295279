import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputWrapper } from '../../../../../../../components/Inputs';
import RadioButton from '../../../../../../../components/PrimeReactComponents/RadioButton';

import { inputs, requiredInputs } from '../../../helpers/inputs';
import { getRehabilitation } from '../../../helpers/formTypeActions';

const Rehabilitation = () => {
  const name = inputs.rehabilitation.name;
  const { errors, control } = useFormContext();

  const rehabilitationInput = (field) => {
    return (
      <InputWrapper
        name={name}
        label={inputs.rehabilitation.label}
        required={!!requiredInputs[name]}
        errorMessage={errors[name]?.message}
        style={{ height: '150px' }}
      >
        <div className="radio_buttons_wrap" style={{ height: '80px' }}>
          <div className="field-radiobutton mb-2">
            <RadioButton
              inputRef={field.ref}
              id={`${field.name}_no`}
              name={field.name}
              inputId="N"
              value="N"
              checked={field.value === 'N'}
              onChange={field.onChange}
            />
            <label htmlFor={field.name}>{getRehabilitation('N').label}</label>
          </div>

          <div className="field-radiobutton mb-2">
            <RadioButton id={`${field.name}_wcp`} name={field.name} inputId="C" value="C" checked={field.value === 'C'} onChange={field.onChange} />
            <label htmlFor={field.name}>{getRehabilitation('C').label}</label>
          </div>

          <div className="field-radiobutton">
            <RadioButton id={`${field.name}_other`} name={field.name} inputId="O" value="O" checked={field.value === 'O'} onChange={field.onChange} />
            <label htmlFor={field.name}>{getRehabilitation('O').label}</label>
          </div>
        </div>
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => rehabilitationInput(field)} />;
};

export default Rehabilitation;
