import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { routes } from '../../../routes/routes';

function MailSidebar() {
  const history = useHistory();
  const totalUnreadNotes = useSelector((state) => state.notes.totalUnreadNotes);

  const getBadge = (entity) => totalUnreadNotes?.find((i) => i.Entity === entity)?.TotalUnread || '';

  const items = [
    {
      section: 'Internal Memos'
    },
    {
      label: 'Claim Notes',
      icon: 'pi pi-file-edit',
      toolTip: 'Internal notes about claims',
      badge: getBadge('claim'),
      to: routes.claimNotesInbox.path
    },
    {
      label: 'Patient Notes',
      icon: 'pi pi-user',
      toolTip: 'Internal notes about patients',
      badge: getBadge('patient'),
      to: routes.patientNotesInbox.path
    },
    {
      label: 'Daysheets',
      icon: 'pi pi-calendar',
      toolTip: '',
      badge: getBadge('daysheet'),
      to: routes.daysheetsInbox.path
    },
    {
      section: 'Teleplan Memos'
    },
    {
      label: 'Remittances',
      icon: 'pi pi-book',
      toolTip: 'Teleplan payment reports',
      badge: getBadge('remittance'),
      to: routes.remittances.path
    },
    {
      label: 'Messages',
      icon: 'pi pi-envelope',
      toolTip: 'Teleplan Messages',
      badge: getBadge('message'),
      to: routes.messages.path
    }
  ];

  const navigate = (item) => {
    if (item.to) {
      history.push(item.to);
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <h3 className="mb-5 hidden md:block">Memos</h3>
      <div className="flex md:flex-column gap-2">
        {items.map((i, index) => (
          <div key={`memo-${index}`}>
            {i.section && windowWidth > 768 && <p className="font-bold text-lg text-gray-800 mb-2">{i.section}</p>}
            {i.label && (
              <Button
                text={!history.location.pathname.includes(i.to)}
                tooltip={i.toolTip}
                tooltipOptions={{ position: 'top' }}
                icon={i.icon}
                style={{ textAlign: 'left' }}
                className="w-full"
                label={windowWidth < 768 ? '' : i.label}
                onClick={() => navigate(i)}
              >
                {i.badge > 0 && <Badge value={i.badge} severity="warning"></Badge>}
              </Button>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default MailSidebar;
