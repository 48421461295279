import React from 'react';
import { useFormContext } from 'react-hook-form';

import PatientPicker from '../Inputs/PatientPicker';
import FeeCode from '../Inputs/FeeCode';
import BMI from '../Inputs/BMI';
import ICD9Code from '../Inputs/ICD9Code';
import TimeSection from './TimeSection';
import EmergencySection from './EmergencySection';
import UnitsSection from './UnitsSection';
import ReferralSection from './ReferralSection';
import SubmissionCode from '../Inputs/SubmissionCode';
import Comment from '../Inputs/Comment';
import SaveAsDraft from '../Inputs/SaveAsDraft';
import PayorOptionalSection from './PayorOptionalSection';
import PractitionerSection from './PractitionerSection';
import ToggleCatalogsButton from '../../ActionButtons/ToggleCatalogsButton';
import ReasonFor01080 from '../Inputs/ReasonFor01080';
import FirstVisitSection from './FirstVisitSection';
import SubmitWithGenericPHN from '../Inputs/SubmitWithGenericPHN';
import OOFHP from '../Inputs/OOFHP';
import PayorAndServiseDateSection from '../Inputs/Common/PayorAndServiseDateSection';

const InputsSection = () => {
  const { localState, isMobile } = useFormContext();

  return (
    <>
      {!isMobile && <ToggleCatalogsButton />}

      <FirstVisitSection />

      <PractitionerSection />

      <PatientPicker />

      <PayorAndServiseDateSection />

      {!localState.isEmptyPractitioner && (
        <>
          <PayorOptionalSection />

          <FeeCode />

          <ReasonFor01080 />

          <BMI />

          <ICD9Code />

          <OOFHP />

          {/* <EmergencySection /> */}

          <TimeSection />

          <UnitsSection />

          <ReferralSection />

          <SubmissionCode />

          <Comment />

          <SubmitWithGenericPHN />

          <SaveAsDraft />
        </>
      )}
    </>
  );
};

export default InputsSection;
