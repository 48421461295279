import React from 'react';
import cx from 'classnames';

const InputGroup = ({ children, ...props }) => {
  return (
    <div {...props} className={cx('flex flex-column sm:flex-row gap-0 sm:gap-3 w-full', props.className)}>
      {children}
    </div>
  );
};

export default InputGroup;
