import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';

import { dropdownScrollHeight } from '../../../modules/config/stylesConfig';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { isEmpty } from 'lodash';
import { formatDate } from '../../../modules/utils/formatDate';
import { setFocusToElement } from '../../../modules/utils/setFocusToElement';
import { t } from '../../../service/localization/i18n';
import cx from 'classnames';
import RadioButton from '../../PrimeReactComponents/RadioButton';

// interface IClaimNumberData {
//   AACText: string,
//   AOIText: string | null,
//   AnatomicCode: string,
//   AreaOfInjury: string,
//   ClaimGuid: string,
//   ClaimNumber: string,
//   ClaimType: string,
//   DCNGuid: string,
//   DiagnosticCode: string | null,
//   DiagnosticCode: string | null,
//   DisplayNumber: string,
//   EditedBy: string,
//   ICD9Code: string,
//   ICD9Note: string | null,
//   ICD9Text: string,
//   InjuryDay: Date,
//   NOIText: string | null,
//   NatureOfInjury: string,
//   PatientGuid: string,
//   Province: string | null,
//   RefName: string | null,
//   RefNumber: string,
//   RefToBy: string,
//   Settled: boolean
// }

// interface ICalimNumbersList {
//   PatientGuid: string,
//   ICBC?: [
//     IClaimNumberData
//   ],
//   WSBC?: [
//     IClaimNumberData
//   ],
// }

// interface IPatients {
//   id: string,
//   name: string,
//   value: any,
//   invoiceType: string, // available values "WSBC" | "ICBC"
//   calimNumbersList: ICalimNumbersList,
//   onChange: () => void,
//   updateInitialState: ({ type: string, value: any, claimNumber: string }) => void,

//   label?: string,
//   placeholder?: string,
// };

const ClaimNumber = (props) => {
  let timeout;
  const inputId = `${props.id}_input`;
  const clinic = useSelector((state) => state.clinic.details);

  const [suggestions, setSuggestions] = useState([]);
  const [allSuggestions, setAllSuggestions] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState('');
  const [prevClaimNumber, setPrevClaimNumber] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [showClaimNumberConfirm, setShowClaimNumberConfirm] = useState(false);

  const type = props.invoiceType;
  const claimData = type === 'WSBC' ? props.calimNumbersList?.WCB : type === 'ICBC' ? props.calimNumbersList?.ICBC : [];

  useEffect(() => {
    const prevNumber = claimData?.length ? claimData[0]?.ClaimNumber : '';
    setPrevClaimNumber(prevNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.calimNumbersList]);

  // set initial suggestions
  useEffect(() => {
    if (isEmpty(props.calimNumbersList)) return setAllSuggestions([]);

    if (type === 'WSBC' && props.calimNumbersList?.WCB?.length > 0) {
      const _suggestions = props.calimNumbersList?.WCB?.map((i) => {
        return { name: i.DisplayNumber, value: i.ClaimNumber, claimGuid: i.ClaimGuid };
      });
      setAllSuggestions(_suggestions);
    }

    if (type === 'ICBC' && props.calimNumbersList?.ICBC?.length > 0) {
      const _suggestions = props.calimNumbersList?.ICBC?.map((i) => {
        return { name: i.ClaimNumber, value: i.ClaimNumber, claimGuid: i.ClaimGuid };
      });
      setAllSuggestions(_suggestions);
    }
  }, [props.calimNumbersList, type]);

  const fetchSuggestions = (event) => {
    props.fetch && props.fetch(event.query);

    let _filteredNumbers;
    if (!event.query.trim().length) {
      _filteredNumbers = [...allSuggestions];
    } else {
      _filteredNumbers = allSuggestions.filter((i) => {
        return i.name.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }

    setSuggestions(_filteredNumbers);
  };

  const onChange = (value) => {
    clearTimeout(timeout);

    if (type === 'WSBC') {
      const WSBCClaimData = props.calimNumbersList?.WCB?.find((i) => {
        if (typeof value === 'string') return i.ClaimNumber === value;
        return i.ClaimGuid === value.claimGuid;
      });
      if (WSBCClaimData) {
        props.onChange({ field: 'ClaimGuid', value: WSBCClaimData.ClaimGuid });
        setShowClaimNumberConfirm(false);
      } else {
        if (props.calimNumbersList?.WCB?.length > 0) {
          setShowClaimNumberConfirm(true);
        } else {
          props.onChange({ field: 'ClaimGuid', value: clinic.DCNGuid });
        }
      }
    }

    if (type === 'ICBC') {
      const ICBCClaimData = props.calimNumbersList?.ICBC?.find((i) => {
        if (typeof value === 'string') return i.ClaimNumber === value;
        return i.ClaimGuid === value.claimGuid;
      });
      if (ICBCClaimData) {
        props.onChange({ field: 'ClaimGuid', value: ICBCClaimData.ClaimGuid });
        setShowClaimNumberConfirm(false);
      } else {
        if (props.calimNumbersList?.ICBC?.length > 0) {
          setShowClaimNumberConfirm(true);
        } else {
          props.onChange({ field: 'ClaimGuid', value: clinic.DCNGuid });
        }
      }
    }

    // Update initial state
    if (value?.value) {
      // On select value from dropdown
      const currentClaimNotEmpty = claimData?.find((i) => i.ClaimNumber === value?.value);
      props.updateInitialState(currentClaimNotEmpty); // update initial data (ClaimGuid, ClaimNumber, Injury Date, Dx, AOI, NOI and Anatomic Code)
    } else {
      // Enter new code manually
      props.onChange({ field: props.name, value });
    }
  };

  const onBlur = (e) => {
    const inputValue = e.target.value;

    if (!inputValue) {
      props.onChange({ field: props.name, value: inputValue });
    }

    timeout = setTimeout(function () {
      if (showClaimNumberConfirm) {
        if (!inputValue) {
          if (type === 'WSBC') {
            const WSBCClaimData = props.calimNumbersList?.WCB?.find((i) => i.ClaimNumber === 'N/A' || i.ClaimNumber === props.value);
            if (WSBCClaimData) {
              setShowClaimNumberConfirm(false);
            } else {
              props.updateInitialState({}); // reset initial data (Injury Date, AOI, NOI and Anatomic Code)
            }
          }

          if (type === 'ICBC') {
            const ICBCClaimData = props.calimNumbersList?.ICBC?.find((i) => i.ClaimNumber === 'N/A' || i.ClaimNumber === props.value);
            if (ICBCClaimData) {
              setShowClaimNumberConfirm(false);
            } else {
              props.updateInitialState({}); // reset initial data (Injury Date, AOI, NOI and Anatomic Code)
            }
          }
        } else {
          setShowDialog(true);
        }
      }
    }, 500);
  };

  const onFocus = (e) => {
    e.target.select();
  };

  const onCancel = () => {
    setShowDialog(false);
    setShowClaimNumberConfirm(false);
    setFocusToElement(inputId);
    // props.onChange({ field: props.name, value: prevClaimNumber });

    const currentClaimNotEmpty = claimData?.find((i) => i.ClaimNumber === prevClaimNumber);
    props.updateInitialState(currentClaimNotEmpty); // update initial data (ClaimGuid, ClaimNumber, Injury Date, Dx, AOI, NOI and Anatomic Code)
  };

  const modifySelectedClaim = () => {
    let claimData = {};

    if (type === 'WSBC') {
      claimData = props.calimNumbersList?.WCB?.find((i) => i.ClaimGuid === selectedClaim);
    }

    if (type === 'ICBC') {
      claimData = props.calimNumbersList?.ICBC?.find((i) => i.ClaimGuid === selectedClaim);
    }

    props.updateInitialState({ ...claimData, NewClaimNumber: props.value }); // update initial data (ClaimGuid, ClaimNumber, Injury Date, Dx, AOI, NOI and Anatomic Code)
    setShowDialog(false);
    setShowClaimNumberConfirm(false);
  };

  const onCreateNewClaim = () => {
    setShowDialog(false);
    setShowClaimNumberConfirm(false);
    props.updateInitialState({}); // reset initial data (Injury Date, AOI, NOI and Anatomic Code)
    props.onChange({ field: props.name, value: props.value, onCreateNewClaim: true });
  };

  const renderFooter = () => {
    return (
      <div className="w-full">
        <Button
          id={elementIDs.modifySelectedClaim}
          autoFocus={selectedClaim?.length > 0}
          label={t('Modify_Selected')}
          disabled={selectedClaim?.length === 0}
          onClick={modifySelectedClaim}
        />
        <Button
          id={elementIDs.createNewCalim}
          autoFocus={selectedClaim?.length === 0}
          className="p-button-outlined"
          label={t('Create_New')}
          onClick={onCreateNewClaim}
        />
        <Button id={elementIDs.dialogCancel} className="p-button-outlined" label={t('Cancel')} onClick={onCancel} />
      </div>
    );
  };

  return (
    <>
      <AutoComplete
        id={props.id}
        inputId={inputId}
        className={cx('flex', props.className || '')}
        field="name"
        dropdown
        delay={props.delay || 0}
        maxLength={props.maxLength || 69}
        value={props.value}
        suggestions={suggestions}
        scrollHeight={dropdownScrollHeight()}
        placeholder={props.placeholder}
        completeMethod={fetchSuggestions}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => onChange(e.value)}
        onSelect={(e) => onChange(e.value)}
      />

      <Dialog
        id={elementIDs.claimNumberConfirmation}
        header={t('Confirmation')}
        visible={showDialog}
        onHide={onCancel}
        breakpoints={{ '1366px': '50vw', '960px': '60vw', '768px': '95vw' }}
        style={{ width: '35vw' }}
        footer={renderFooter()}
      >
        <p id={elementIDs.claimNumberConfirmationDialogText}>{`${t('Are_you_trying_to_create_a_new_claim_or_modify_an_existing_claim')}`}</p>

        {claimData?.map((i) => {
          const showInjuryDate = type === 'ICBC' ? '' : `, ${t('Injury_Date.2')} ${formatDate(i.InjuryDay, 'YYYY-MM-DD', true)}`;

          return (
            <div key={i.ClaimGuid} className="field-radiobutton mt-5">
              <RadioButton
                id={i.ClaimGuid.replaceAll('-', '')}
                inputId={i.ClaimGuid}
                name="category"
                value={i.ClaimGuid}
                checked={selectedClaim === i.ClaimGuid}
                onChange={(e) => setSelectedClaim(e.value)}
              />
              <label id={`claimNumberConfirmationRadioButtonLabel_${i.ClaimGuid.replaceAll('-', '')}`} className="mb-0" htmlFor={i.ClaimGuid}>
                {`${t('Claim')} # ${i.ClaimNumber}${showInjuryDate}`}
              </label>
            </div>
          );
        })}
      </Dialog>
    </>
  );
};

export default ClaimNumber;
