import React from 'react';
import { patientFullName } from '../../../../../modules/utils/patientFullName';

const PayableSection = ({ data, isFetching }) => {
  if (isFetching) return null;

  const billTo = data.BillTo && Array.isArray(data.BillTo) ? data.BillTo[0] : data.BillTo;
  const isRepresentative = data.BillToGuid !== data.PatientGuid;

  if (!isRepresentative) return null;

  const isCompany = billTo.ContactType === 2;
  const payedBy = isCompany ? billTo.CompanyName : patientFullName(billTo);

  return <div className="pb-2">{`Invoice payable by ${payedBy}, caregiver/representative for ${patientFullName(data?.PatientDetails)}`}</div>;
};

export default PayableSection;
