import React from 'react';
import RepresentativeWithFormProvider from './RepresentativeWithFormProvider';

// interface {
//   patient: Patient;
//   initialState: Representative;
//   onSubmit: (formData) => Promise<void> | void;
//   onClose: () => Promise<void> | void;
// }

const RepresentativeForm = (props) => {
  return <RepresentativeWithFormProvider {...props} />;
};

export default RepresentativeForm;
