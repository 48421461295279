import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { inputs } from '../helpers/inputs';
import { serviceInitValues } from '../helpers/defaultValues';
import { findIndex } from 'lodash';
import { useConditions } from './useConditions';
import { setMissingCodesToRecentList } from '../../../actions/claims.action.creators';
import { currencyFormat } from '../../../../utils/currencyFormat';

export const useService = () => {
  const dispatch = useDispatch();
  const { clearErrors, watch, getValues, setValue, setLocalState } = useFormContext();
  const { recentCodesType } = useConditions();
  const services = watch(inputs.services.name);
  const defaultValues = serviceInitValues();

  const getTooltipForService = (service) => {
    let tooltip = '';

    if (service.label) {
      tooltip += `${service.label}`;
    }

    if (service.duration && service.unit) {
      tooltip += `, ${service.duration} ${service.unit}`;
    }

    if (service.amount) {
      tooltip += `, ${currencyFormat(service.amount)}`;
    }

    if (service.text) {
      tooltip += `, ${service.text}`;
    }

    return tooltip.trim();
  };

  const isSelected = (service) => {
    if (Array.isArray(services)) {
      return services?.find((i) => i?.value?.toUpperCase()?.trim() === service?.value?.toUpperCase()?.trim());
    } else {
      return services?.value?.toUpperCase()?.trim() === service?.value?.toUpperCase()?.trim();
    }
  };

  // Select service from quick pick
  const onSelectService = (service) => {
    // Check if the service is already selected
    const isItemSelected = isSelected(service);

    if (isItemSelected) {
      // If the service is already selected

      // Check if it's the last row
      const isLastRow = services?.length === 1;

      if (isLastRow) {
        // If it's the last row, reset the services list with a default value
        setValue(inputs.services.name, [defaultValues]);
      } else {
        // If there are multiple rows, remove the selected service from the list
        const filteredServicesList = services?.filter((i) => i?.value?.toUpperCase() !== service?.value?.toUpperCase());
        setValue(inputs.services.name, filteredServicesList);
      }
    } else {
      // If the service is not selected

      // Find the index of an empty row if available
      const indexOfEmptyRow = findIndex(services, (i) => !i.hasOwnProperty('value'));

      if (indexOfEmptyRow > -1) {
        // If an empty row is available, add the selected service to that row
        onServiceChange({ value: [service] }, indexOfEmptyRow);
        const serviceName = `${inputs.services.name}.${indexOfEmptyRow}`;
        clearErrors(serviceName);
      } else {
        // If there are no empty rows, create a new row for the selected service
        const newRow = serviceInitValues(service);
        const updatedServicesList = [...services, newRow];
        setValue(inputs.services.name, updatedServicesList);
        updateServiceAtIndex(updatedServicesList.length, newRow);
      }
    }
  };

  const onServiceChange = (e, index) => {
    const value = e.value;

    if (!value) return;

    if (value && !value?.length) {
      // Empty value
      updateServiceAtIndex(index, defaultValues);
    } else {
      // Selected value
      if (Array.isArray(value) && value.length) {
        const service = value.length === 2 ? value[1] : value[0];
        const initialService = serviceInitValues(service);
        updateServiceAtIndex(index, initialService);
      }
      const serviceName = `${inputs.services.name}.${index}`;
      clearErrors(serviceName);
    }
  };

  const updateServiceAtIndex = (index, newValues) => {
    const updatedServicesList = getValues(inputs.services.name)?.map((i, idx) => {
      return index === idx ? serviceInitValues(newValues) : i;
    });
    const updatedRecentCodesList = updatedServicesList?.filter((i) => i.value);

    setValue(inputs.services.name, updatedServicesList);
    updatedRecentCodesList?.length && dispatch(setMissingCodesToRecentList({ codes: { [recentCodesType]: updatedRecentCodesList } }));
  };

  const toggleServiceCatalogs = () => {
    setLocalState((prevState) => ({ ...prevState, catalogsDialog: { showDialog: true } }));
  };

  return {
    getTooltipForService,
    onServiceChange,
    onSelectService,
    toggleServiceCatalogs,
    isSelected
  };
};
