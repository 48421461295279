import TimelineMarker from '../modules/TimelineMarker';
import TimelineContent from '../modules/TimelineContent';

import { useInvoiceTimelineContext } from './useInvoiceTimelineContext';
import { getEClaimFormName } from '../../../../../modules/utils/getEClaimFormName';
import { addNewItemToArray } from '../../../../../modules/utils/addNewItemToArray';
import { convertToLocalTime } from '../../../../../modules/utils/formatDate';
import { t } from '../../../../../service/localization/i18n';
import { validate } from 'uuid';
import moment from 'moment';

export const useInvoiceTimeline = () => {
  const { state } = useInvoiceTimelineContext();
  const data = state.invoice;
  const paidStatus = (data.Status === 2 && data.FeePaid > 0) || data.Status === 27;

  // [VB] why do not use data.RemittanceGuid instead of data.Status ?
  // const remittanceReceivedStatus1 = (paidStatus
  //   || (data.Status === 2
  //   || data.Status === 3
  //   || data.Status === -3
  //   || data.Status === 6
  //   || data.Status === 9
  //   || data.Status === 27
  //   || data?.RemittanceCode?.length)) ? true : false;

  const remittanceReceivedStatus = data.RemittanceGuid && validate(data.RemittanceGuid);
  const submittedStatus = data.SubmissionGuid && validate(data.SubmissionGuid);

  // [VB] why do not use data.SubmissionGuid ?
  //const submittedStatus1 = (remittanceReceivedStatus || (data.Status === 1 || data?.SequenceNum?.length));
  const isEClaim = data?.ClaimType === 'E';

  const getRemittanceNumber = () => {
    if (isEClaim) {
      return `${getEClaimFormName(data?.RemittanceCode)}${data.SequenceNum ? ` #${data.SequenceNum}` : ''}`;
    }
    return '';
  };

  const formatDate = (date) => moment(date).format('ddd, MMM D, YYYY');

  const timelineValue = () => {
    let commonItems = [
      {
        icon: 'pi pi-check',
        status: `custom-marker-success_${data.Status}`,
        statusText: t('Created'),
        date: formatDate(convertToLocalTime(data.DateCreated)),
        dateLink: false,
        number: `${t('Office.1')}.#: ${data.RecordNo}`
      },

      {
        icon: paidStatus ? 'pi pi-check' : '',
        status: paidStatus ? `custom-marker-success_${data.Status}` : 'custom-marker-disable',
        statusText: t('Paid'),
        date: data?.PaymentDate ? formatDate(data.PaymentDate) : '',
        dateLink: false,
        number: ''
      }
    ];

    const submition = {
      icon: submittedStatus ? 'pi pi-check' : '',
      status: submittedStatus ? `custom-marker-success_${data.Status}` : 'custom-marker-disable',
      statusText: t('Sent'),
      date: data.SDate && data.InvoiceType !== 'P' ? formatDate(convertToLocalTime(data.SDate)) : '',
      dateLink: false,
      number: data.InvoiceType !== 'P' && data.SequenceNum ? `${t('Sequence.1')}.#: ${data.SequenceNum}` : ''
    };

    const remittance = {
      icon: remittanceReceivedStatus ? 'pi pi-check' : '',
      status: remittanceReceivedStatus ? `custom-marker-success_${data.Status}` : 'custom-marker-disable',
      statusText: isEClaim ? t('Response') : t('Remittance'),
      date: isEClaim ? data.Received : '',
      resubmitBefore: data.ServiceDate ? moment(data.ServiceDate).add(90, 'days').format('MMM DD, YYYY') : '',
      dateLink: !!data.Received,
      number: getRemittanceNumber(), // Show number for eClaims
      description: data.MSPCodes
    };

    const printed = {
      icon: '',
      status: 'custom-marker-disable',
      statusText: t('Printed'),
      date: '',
      dateLink: false,
      number: '',
      description: ''
    };

    const emailed = {
      icon: '',
      status: 'custom-marker-disable',
      statusText: t('Emailed'),
      date: '',
      dateLink: false,
      number: '',
      description: ''
    };

    // show remittance if InvoiceType is not Private
    if (data.InvoiceType !== 'Private') {
      commonItems = addNewItemToArray(commonItems, 1, submition);
    }

    // show remittance if InvoiceType is not Private
    if (data.InvoiceType !== 'Private') {
      commonItems = addNewItemToArray(commonItems, 2, remittance);
    }

    //Future releases
    // // show printed if InvoiceType is Private
    // if (data.InvoiceType === "Private") {
    //   commonItems = addNewItemToArray(commonItems, 1, printed);
    // };

    // // show emailed if InvoiceType is Private
    // if (data.InvoiceType === "Private") {
    //   commonItems = addNewItemToArray(commonItems, 2, emailed);
    // };

    return commonItems;
  };

  const marker = (item) => <TimelineMarker item={item} />;
  const content = (item) => <TimelineContent item={item} />;

  return { value: timelineValue(), marker, content };
};
