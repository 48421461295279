import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { range } from 'lodash';
import cx from 'classnames';

const Alphabet = ({ onClick, style, extraItems = [], stylesForExtraItems = {} }) => {
  const [selectedLetter, setSelectedLetter] = useState('');

  // Generate the alphabet array
  const alphabetArray = range('A'.charCodeAt(0), 'Z'.charCodeAt(0) + 1).map((charCode) => ({
    value: String.fromCharCode(charCode),
    label: String.fromCharCode(charCode),
    isExtra: false // Indicate regular alphabet items
  }));

  // Combine alphabet array with extra items
  const combinedArray = [
    ...alphabetArray,
    ...extraItems.map((item) => ({
      value: item,
      label: item,
      isExtra: true // Indicate extra items
    }))
  ];

  const handleClick = (item) => {
    const value = selectedLetter === item ? '' : item;
    setSelectedLetter(value);
    if (onClick) {
      onClick(value.toLowerCase());
    }
  };

  return (
    <div className="flex flex-wrap">
      {combinedArray.map((item) => (
        <Button
          key={item.value}
          className={cx(selectedLetter === item.value ? '' : 'p-button-text', 'p-0')}
          label={item.label}
          style={
            item.isExtra
              ? { ...style, ...stylesForExtraItems } // Apply custom styles for extra items
              : style || { minWidth: '21px', height: '21px' }
          }
          onClick={() => handleClick(item.value)}
        />
      ))}
    </div>
  );
};

export default Alphabet;
