import React, { useState } from 'react';
import PayeePhone from '../../../../../components/Inputs/Payee/PayeePhone';
import { usePrivateInvoicePrefs } from '../hooks/usePrivateInvoicePrefs';

const PrivateInvoicePhone = () => {
  const { payeePrefsPhone } = usePrivateInvoicePrefs();
  const [value, setValue] = useState(payeePrefsPhone);

  const onChange = (e) => {
    setValue(e.value);
  };

  return <PayeePhone value={value} savePrefsOnChange onChange={onChange} />;
};

export default PrivateInvoicePhone;
