import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputWrapper } from '../../../Inputs';
import { inputWrapperStyles } from '../helpers/styles';
import AddressTypePicker from '../../../Inputs/AddressType';
import { t } from '../../../../service/localization/i18n';

const AddressType = () => {
  const name = 'AddressType';
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper name={name} label={t('Address_Type')} errorMessage={errors[name]?.message} style={inputWrapperStyles}>
          <AddressTypePicker {...field} />
        </InputWrapper>
      )}
    />
  );
};

export default AddressType;
