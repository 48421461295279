import * as checkList from './codesCheckList';
import { t } from '../../../../../service/localization/i18n';
import { isWeekendOrHoliday } from '../../../../utils/formatDate';
import { isNightTime, isTimeValid, timeDiff } from '../../../../utils/formatTime';
import { castArray, includes, intersection, intersectionBy, join, maxBy, minBy } from 'lodash';
import { stringFormat } from '../../../../utils/stringFormat';
import { closeToastMessage } from '../../../../core/actions/core.action.creators';
import { store } from '../../../../../store';
import { calculateUnits } from './calculateUnits';

export const checkIfCodeValid4Location = (code, loc) => {
  const codeList = Array.isArray(code) ? code : [code];
  const validCCectionCodes = intersection(codeList, checkList.ccection);

  if (validCCectionCodes.length > 0 && includes(checkList.hospital, loc)) {
    return validCCectionCodes;
  }

  return false;
};

export const feeCodeConflicts = (code) => {
  let has47 = false;
  let has18 = false;

  if (Array.isArray(code) && code.length > 1) {
    code.forEach((i) => {
      if (checkList.spec47.indexOf(i) > -1) has47 = true;
      if (checkList.spec18.indexOf(i) > -1) has18 = true;
    });
  } else return false;

  return has47 && has18;
};

export const checkIfCodeNeedsReferralBy = (code) => {
  return checkIfFieldIsRequired(code, checkList.feeCodesNeedsReferralBy);
};

export const checkIfCodeNeedsReferralTo = (code) => {
  return checkIfFieldIsRequired(code, checkList.feeCodesNeedsReferralTo);
};

export const feeCodeNeedsOnlyStartTime = (code) => {
  return checkIfFieldIsRequired(code, checkList.start_time_needed);
};

export const feeCodeNeedsTime = (code) => {
  return checkIfFieldIsRequired(code, checkList.time_required);
};

export const checkIfCodeValid4Age = (code, age) => {
  //Show error message if => fee code depends on patient age
  var age_0_1 = ['12110', '12210', '12101', '12201', '12100', '12200'];
  var age_2_49 = ['00100', '00101', '00110', '00120'];
  var age_50_59 = ['15200', '15201', '15210', '15220', '15300', '15301', '15310', '15320'];
  var age_60_69 = ['16100', '16101', '16110', '16120', '16200', '16201', '16210', '16220'];
  var age_70_79 = ['17100', '17101', '17110', '17120', '17200', '17201', '17210', '17220'];
  var age_80_plus = ['18100', '18101', '18110', '18120', '18200', '18201', '18210', '18220'];

  // if (age_0_1.includes(code) && (age > 1)) return false; // Should we make this check????
  if (age_2_49.includes(code) && (age < 2 || age >= 50)) return false;
  if (age_50_59.includes(code) && (age < 50 || age >= 60)) return false;
  if (age_60_69.includes(code) && (age < 60 || age >= 70)) return false;
  if (age_70_79.includes(code) && (age < 70 || age >= 80)) return false;
  if (age_80_plus.includes(code) && age < 80) return false;

  // Psychiatry and some pediatrics
  if (checkList.age_below_18.includes(code) && age >= 18) return false;
  if (checkList.age_over_18.includes(code) && age < 18) return false;
  if (checkList.age_over_75.includes(code) && age < 75) return false;

  // additional geriatric: update Nov 2017
  var age_65_plus = [
    '33401',
    '3410',
    '33412',
    '33414',
    '33413',
    '33415',
    '33406',
    '33407',
    '33408',
    '33409',
    '33405',
    '33470',
    '33472',
    '33421',
    '33422',
    '33476',
    '33477',
    '33478',
    '33445',
    '33450',
    '33402'
  ];
  var age_65_74 = []; // before Oct 1, 2017 - '33455'
  var age_75_plus = ['96843', '96856']; // before Oct 1, 2017 - '33402'
  if (age_65_plus.includes(code) && age < 65) return false;
  if (age_75_plus.includes(code) && age < 75) return false;
  if (age_65_74.includes(code) && (age < 65 || age >= 75)) return false;

  // pediatrician: update Mar 2019
  // var age_less_1 = ['00525');
  var age_less_2 = ['00532'];
  var age_less_5 = ['00541'];
  var age_less_12 = ['00570'];
  var age_13_plus = ['00750'];
  var age_0_6 = ['50520', '50527', '50530', '50539', '50541', '50545'];
  var age_1_5 = ['00526'];
  var age_7_16 = ['50521', '50528', '50531', '50540', '50542', '50546'];
  var age_0_16 = ['00578', '00579', '00580', '00571', '00572', '50522'];
  if (age_less_2.includes(code) && age > 2) return false;
  if (age_less_5.includes(code) && age > 5) return false;
  if (age_less_12.includes(code) && age > 12) return false;
  if (age_13_plus.includes(code) && age < 13) return false;
  if (age_0_6.includes(code) && age > 6) return false;
  if (age_1_5.includes(code) && (age < 0 || age > 5)) return false;
  if (age_7_16.includes(code) && (age < 7 || age > 16)) return false;
  if (age_0_16.includes(code) && age > 16) return false;

  return true;
};

export const checkIfFieldIsRequired = (code, arr) => {
  if (!Array.isArray(arr)) return false; // Ensure arr is an array

  let isRequired = false;

  if (Array.isArray(code)) {
    if (arr.filter((i) => code.includes(i)).length > 0) isRequired = true;
  } else {
    if (arr.includes(code)) isRequired = true;
  }

  return isRequired;
};

export const checkOffHoursCorrespond = (feeCodes, serviceDate, startTime, endTime) => {
  const codes = castArray(feeCodes);

  // Validation that do NOT require start time
  if (!serviceDate || !codes) {
    store.dispatch(closeToastMessage(true));
    return { warn: false, warnMsg: null };
  }

  const weekendOrHoliday = isWeekendOrHoliday(serviceDate);
  const checkWholeWorkdays = join(intersection(checkList.whole_workdays, codes), ', ');
  const checkExWeekendWholeDay = join(intersection(checkList.ex_weekend_whole_day, codes));
  const checkDayMidwives = join(intersection(checkList.ec_day_mw, codes));
  const checkNightMidwives = join(intersection(checkList.ec_night_mw, codes));

  const isWholeWorkDay = !weekendOrHoliday;
  const isWeekendWholeDayValid = weekendOrHoliday;

  if (checkWholeWorkdays?.length && !isWholeWorkDay) {
    const msg = t('Start_time_does_not_correspond.wholeWorkday');
    const wholeWorkdayMsg = stringFormat(msg, checkWholeWorkdays);
    return { warn: true, warnMsg: wholeWorkdayMsg };
  }

  if (checkExWeekendWholeDay?.length && !isWeekendWholeDayValid) {
    const msg = t('Start_time_does_not_correspond.weekendWholeDay');
    const workDayMsg = stringFormat(msg, checkExWeekendWholeDay);
    return { warn: true, warnMsg: workDayMsg };
  }

  //  Below is a validation that requires a start time
  if (!startTime) {
    // store.dispatch(closeToastMessage(true));
    return { warn: false, warnMsg: null };
  }

  let militaryStartTime = startTime?.replace(':', '');
  let militaryEndTime = endTime?.replace(':', '');
  if (militaryStartTime?.substring(0, 1) === '0') {
    militaryStartTime = militaryStartTime?.substring(1, 4);
  }
  if (militaryEndTime?.substring(0, 1) === '0') {
    militaryEndTime = militaryEndTime?.substring(1, 4);
  }

  const nigthTime = isNightTime(startTime, '23:00', '08:00');
  const isMidwifeNight = isNightTime(startTime, '18:00', '08:00');
  const isMidwifeDay = !isMidwifeNight;

  const isWorkDayValid = !weekendOrHoliday && militaryStartTime >= 800 && militaryStartTime < 1800;
  const isEveningValid = !weekendOrHoliday && militaryStartTime >= 1800 && militaryStartTime < 2300;
  const isEveningFinishValid = !weekendOrHoliday && militaryEndTime >= 1800 && militaryEndTime < 2300 && militaryStartTime < militaryEndTime; // WIN-1058
  const isNightValid = nigthTime;
  const isWeekendValid = weekendOrHoliday && militaryStartTime >= 800 && militaryStartTime < 2300;

  const checkEcWorkdays = join(intersection(checkList.ec_workdays, codes), ', ');
  const checkEcEvening = join(intersection(checkList.ec_evening, codes));
  const checkEcEveningFinish = join(intersection(checkList.ec_evening_finish, codes));
  const checkEcNight = join(intersection(checkList.ec_night, codes));
  const checkEcWeekend = join(intersection(checkList.ec_weekend, codes));
  const checkEcWeekendWholeDay = join(intersection(checkList.ec_evening_and_ec_weekend, codes));

  if (checkDayMidwives?.length && !isMidwifeDay) {
    const msg = t('Start_time_does_not_correspond.dayMidwife');
    const workDayMsg = stringFormat(msg, checkEcWorkdays);
    return { warn: true, warnMsg: workDayMsg };
  }

  if (checkNightMidwives?.length && !isMidwifeNight) {
    const msg = t('Start_time_does_not_correspond.nightMidwife');
    const workDayMsg = stringFormat(msg, checkEcWorkdays);
    return { warn: true, warnMsg: workDayMsg };
  }

  if (checkDayMidwives?.length && !isMidwifeDay) {
    const msg = t('Start_time_does_not_correspond.dayMidwife');
    const workDayMsg = stringFormat(msg, checkEcWorkdays);
    return { warn: true, warnMsg: workDayMsg };
  }

  if (checkNightMidwives?.length && !isMidwifeNight) {
    const msg = t('Start_time_does_not_correspond.nightMidwife');
    const workDayMsg = stringFormat(msg, checkEcWorkdays);
    return { warn: true, warnMsg: workDayMsg };
  }

  if (checkEcWorkdays?.length && !isWorkDayValid) {
    const msg = t('Start_time_does_not_correspond.workDay');
    const workDayMsg = stringFormat(msg, checkEcWorkdays);
    return { warn: true, warnMsg: workDayMsg };
  }

  if (checkEcEvening?.length && !isEveningValid) {
    const msg = t('Start_time_does_not_correspond.evening');
    const workDayMsg = stringFormat(msg, checkEcEvening);
    return { warn: true, warnMsg: workDayMsg };
  }

  // WIN-1058
  if (checkEcEveningFinish?.length && !isEveningFinishValid) {
    const msg = t('Finish_time_does_not_correspond.evening');
    const workDayMsg = stringFormat(msg, checkEcEvening);
    return { warn: true, warnMsg: workDayMsg };
  }

  if (checkEcNight?.length && !isNightValid) {
    const msg = t('Start_time_does_not_correspond.night');
    const workDayMsg = stringFormat(msg, checkEcNight);
    return { warn: true, warnMsg: workDayMsg };
  }

  if (checkEcWeekend?.length && !isWeekendValid) {
    const msg = t('Start_time_does_not_correspond.weekend');
    const workDayMsg = stringFormat(msg, checkEcWeekend);
    return { warn: true, warnMsg: workDayMsg };
  }

  if (checkEcWeekendWholeDay?.length && !isEveningValid && !isWeekendValid) {
    const msg = t('Start_time_does_not_correspond.eveningAndWeekendMsg');
    const workDayMsg = stringFormat(msg, checkEcWeekendWholeDay);
    return { warn: true, warnMsg: workDayMsg };
  }

  store.dispatch(closeToastMessage(true));
  return { warn: false, warnMsg: null };
};

// CMO-3159 - Validate that the number of units is 1 or more
export const isFeeCodesDurationTooShort = (feeCodes, startTime, endTime) => {
  const isValidTime = isTimeValid(startTime) && isTimeValid(endTime);
  const isFeeCodesValid = Array.isArray(feeCodes) && feeCodes?.length;

  if (isValidTime && isFeeCodesValid) {
    return feeCodes.some((i) => {
      const units = calculateUnits(i, startTime, endTime);
      return units === 0;
    });
  }

  return false;
};

export const findClosestMatchingPsychiatryCode = ({ startTime, endTime, psychiatryCodesList = [] }) => {
  // Check if the start and end times are valid
  const isStartTimeValid = isTimeValid(startTime);
  const isEndTimeValid = isTimeValid(endTime);

  // Calculate the time difference if both start and end times are valid
  const timeDif = isStartTimeValid && isEndTimeValid ? timeDiff(startTime, endTime) : null;

  // Find the fee codes that have the duration within the range
  const validFeeCodes = psychiatryCodesList.filter((i) => timeDif >= i.minDuration && timeDif <= i.maxDuration);

  // If no valid fee codes found, return an empty object
  if (validFeeCodes.length === 0) return {};

  // Find the fee code with the closest duration to the actual duration
  validFeeCodes.sort((a, b) => Math.abs(timeDif - a.duration) - Math.abs(timeDif - b.duration));

  // Select the fee code with the closest duration to the actual duration
  const selectedFeeCode = validFeeCodes[0];

  return selectedFeeCode;
};

export const isDurationWithinPsychiatryCodeRange = ({ feeCodes, startTime, endTime, psychiatryCodesList }) => {
  // Check if start and end times are valid and not equal
  if (!startTime || !endTime || startTime === endTime) {
    return true; // Return valid result if start or end times are missing or equal
  }

  // Find common fee codes between the provided fee codes and psychiatry codes list
  const commonCodes = intersectionBy(feeCodes, psychiatryCodesList, 'value');
  if (commonCodes.length >= 2) {
    return false;
  }

  // Find a matching code from psychiatry codes list based on provided fee codes
  const matchedCode = psychiatryCodesList.find((i) => feeCodes.some((code) => code.value === i.value));

  if (matchedCode) {
    // If a matching code is found
    const isStartTimeValid = isTimeValid(startTime);
    const isEndTimeValid = isTimeValid(endTime);
    const timeDifference = isStartTimeValid && isEndTimeValid ? timeDiff(startTime, endTime) : null;

    if (timeDifference) {
      // If time difference is valid
      const codeWithMinDuration = minBy(psychiatryCodesList, 'duration');
      const codeWithMaxDuration = maxBy(psychiatryCodesList, 'duration');
      const isDurationMatch = timeDifference >= matchedCode.minDuration && timeDifference <= matchedCode.maxDuration;

      if (matchedCode.value === codeWithMinDuration.value) {
        return isDurationMatch;
      }

      if (matchedCode.value === codeWithMaxDuration.value) {
        return isDurationMatch || timeDifference >= matchedCode.maxDuration;
      }

      return isDurationMatch;
    }
  }

  return true;
};

export const getInvalidDurationMessageForPsychiatryCodes = ({ feeCodes, startTime, endTime }) => {
  const psychiatryCodesList = getPsychiatryCodesWithWrongDuration({ feeCodes, startTime, endTime });

  // If a matching code is found
  const isStartTimeValid = isTimeValid(startTime);
  const isEndTimeValid = isTimeValid(endTime);
  const timeDifference = isStartTimeValid && isEndTimeValid ? timeDiff(startTime, endTime) : null;
  const suggestedCode = findClosestMatchingPsychiatryCode({ startTime, endTime, psychiatryCodesList });
  let invalidMessage = '';

  // Find a matching code from psychiatry codes list based on provided fee codes
  const matchedCode = psychiatryCodesList?.find((i) => feeCodes.some((code) => code.value === i.value));

  if (matchedCode) {
    if (timeDifference < matchedCode.minDuration) {
      if (suggestedCode?.value) {
        invalidMessage = stringFormat(t('Code_X_min_duration_Y_suitable_code_P'), matchedCode.value, matchedCode.duration, suggestedCode?.value);
      } else {
        invalidMessage = matchedCode.minDurationMessage;
      }
    }

    if (timeDifference > matchedCode.maxDuration) {
      if (suggestedCode?.value) {
        invalidMessage = stringFormat(t('Code_X_max_duration_Y_suitable_code_P'), matchedCode.value, matchedCode.maxDuration, suggestedCode?.value);
      } else {
        invalidMessage = matchedCode.maxDurationMessage;
      }
    }
  }

  return invalidMessage;
};

export const getPsychiatryCodesWithWrongDuration = ({ feeCodes, startTime, endTime }) => {
  const psychiatryCodesWithWrongDuration = checkList.durationForPsychiatryCodesList.find((i) => {
    const checkDuration = isDurationWithinPsychiatryCodeRange({ feeCodes, startTime, endTime, psychiatryCodesList: i });
    return !checkDuration;
  });

  return psychiatryCodesWithWrongDuration;
};
