import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import AuthFormWrap from '../../../../../components/Wraps/PageWrap/AuthFormWrap';
import { Message } from 'primereact/message';
import { useTimer } from '../../../../hooks/useTimer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { resetState } from '../../../../../store';
import { routes } from '../../../../../routes/routes';
import moment from 'moment-timezone';

const TemporarilyAccountBlockError = () => {
  const user = useSelector((state) => state.user.details);
  const history = useHistory();
  const dispatch = useDispatch();
  const lockoutDurationMinutes = 30; // this durations depends on "user?.LastLogin", which come during exactly 30 minutes
  const duration = lockoutDurationMinutes * 60000; // number of milliseconds (1 minute = 60,000 milliseconds) for which the functionality of the component is blocked
  const startTime = user?.LastLogin || moment();
  const loginRedirect = () => {
    dispatch(resetState()); // Reset redux state
    history.replace(routes.dashboard.path);
  };
  const { formattedTime, active } = useTimer({ duration, startTime, onComplete: loginRedirect });

  const content = (
    <div>
      <div className="m-0 my-4 text-800 w-full text-center align-items-center font-bold text-3xl">Account temporarily blocked</div>
      <div className="flex flex-column gap-6 mb-4">
        <div className="flex line-height-3 text-800 text-lg">
          For your security, your account is temporarily blocked due to several invalid login attempts. Please wait {lockoutDurationMinutes} minutes
          before trying again.
        </div>
        {/* Display the time we captured after the component rendered */}
        {active && (
          <div className="text-lg text-center">
            Time remaining: <strong>{formattedTime}</strong>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <AuthFormWrap>
      <div className="flex flex-column gap-6 my-4">
        <Message severity="error" content={content} />
        <Button link label="Go to Login Page" type="button" onClick={loginRedirect} className="p-0 text-lg" />
      </div>
    </AuthFormWrap>
  );
};

export default TemporarilyAccountBlockError;
