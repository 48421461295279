import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import Context from '../../../../Context';
import ClaimInputField from './ClaimInputField';
import { dropdownScrollHeight } from '../../../config/stylesConfig';
import { updatePrivateRecord } from '../../actions/claims.action.creators';
import { prefsCodes } from '../../../config/prefsCodesConfig';
import { initDoc } from './claimDetailsHelpers/initDoc';
import { getDefaultPrefs, getPresetPrefs } from '../../helpers/getDefaultPrefs';
import { requiredError } from '../../../common/components/inputInfoMessages';
import { multipleDoctorGuid, NA_DoctorGuid } from '../../../config/defaultValuesConfig';
import { toTitleCase } from '../../../utils/toTitleCase';
import { commonInputs } from '../../../config/commonInputsConfig';
import { getPreferences, setCurrentPractitionerPrefs } from '../../../preferences/actions/preferences.action.creators';
import { setDirty } from '../../../core/actions/core.action.creators';
import { updatePreferencesForPrivateRecord } from './claimDetailsHelpers/newClaimBlankRecord';
import { inputs } from './claimDetailsHelpers/inputs';
import { clinicType, practitionerType } from './claimDetailsHelpers/payorTypes';
import { updatePractitionerValues } from './claimDetailsHelpers/updatePractitionerValues';
import { updateLocationValues } from './claimDetailsHelpers/updateLocationValues';
import { updateUserDefaultPrefs } from './claimDetailsHelpers/updateUserDefaultPrefs';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';
import { activePractitionerStatuses } from '../../../config/statuses';
import { InputText } from 'primereact/inputtext';

const HeaderPanel = () => {
  const dispatch = useDispatch();
  const {
    user,
    users,
    clinic,
    isDirty,
    locations,
    privateRecord,
    currentUserDefaultPrefs,
    practitionerIsRequired,
    setPractitionerIsRequired,
    isInvoicePatientForBalance
  } = useContext(Context);

  const [practitioner, setPractitioner] = useState(null);
  const [practitionersList, setPractitionersList] = useState([]);
  const [location, setLocation] = useState(null);
  const rcp = { ...initDoc(), DoctorGuid: NA_DoctorGuid, FullName: t('N_A'), UserStatus: '0' };
  const multiple = { ...initDoc(), DoctorGuid: multipleDoctorGuid, FullName: t('Multiple'), UserStatus: '0' };

  // init list of practitioners
  useEffect(() => {
    const doctor_list = users
      ? users
          .filter((x) => x.PractitionerNumber !== '0')
          .map((x) => {
            return { ...initDoc(x), DoctorGuid: x.DoctorGuid, FullName: x.FullName };
          })
      : [];

    // add "N/A" for all if more than one practitioner
    const practitionersList = doctor_list.length > 1 ? [rcp, multiple, ...doctor_list] : doctor_list;

    // WIN-127 - modify code to include only active users (status  in (0,1,2)) in dropdowns on Create/Edit claims/invoices/eClaims/appointments
    const activeOnly = practitionersList.filter((i) => activePractitionerStatuses.includes(Number(i.UserStatus)));

    setPractitionersList(activeOnly);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  // initial value for practitioner
  useEffect(() => {
    if (privateRecord?.DoctorGuid === NA_DoctorGuid) setPractitioner(rcp);
    if (privateRecord?.DoctorGuid === multipleDoctorGuid || privateRecord?.DoctorGuid === privateRecord.DCNGuid) {
      setPractitioner(multiple);
    }

    if (
      privateRecord?.DoctorGuid !== multipleDoctorGuid &&
      privateRecord?.DoctorGuid !== NA_DoctorGuid &&
      privateRecord?.DoctorGuid !== privateRecord.DCNGuid
    ) {
      const currentDoc = users?.find((i) => i.DoctorGuid === privateRecord?.DoctorGuid);
      setPractitioner({
        ...initDoc(currentDoc),
        DoctorGuid: privateRecord?.DoctorGuid,
        FullName: toTitleCase(privateRecord?.TreatedBy)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateRecord?.DoctorGuid, users]);

  // Init value for Location
  useEffect(() => {
    const currentLocation = locations?.find((i) => i.DCNGuid.toUpperCase() === privateRecord?.DCNGuid?.toUpperCase()) || locations?.[0];
    setLocation(currentLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateRecord.DCNGuid]);

  const onSelectLocation = (value) => {
    dispatch(updatePrivateRecord(updateLocationValues(value)));
  };

  const onSelectDoctor = (doc) => {
    doc?.DoctorGuid === NA_DoctorGuid ? setPractitionerIsRequired(true) : setPractitionerIsRequired(false); // input validation

    if (doc.DoctorGuid === undefined || doc.DoctorGuid === NA_DoctorGuid || doc.DoctorGuid === multipleDoctorGuid) {
      const userGuid =
        user.details.Role === 'rcp' || doc.DoctorGuid === NA_DoctorGuid || doc.DoctorGuid === multipleDoctorGuid
          ? user.details.DCNGuid
          : user.details.UserGuid;
      dispatch(getPreferences(user.details.DCNGuid, userGuid, (responseData) => getPrefsCallback(responseData, doc)));
    } else {
      dispatch(getPreferences(user.details.DCNGuid, doc.DoctorGuid, (responseData) => getPrefsCallback(responseData, doc)));
    }
  };

  const getPrefsCallback = (prefs, doc) => {
    const defaultPrefs = prefs?.find((i) => i.label === 'default');
    dispatch(setCurrentPractitionerPrefs(defaultPrefs));

    const payTo =
      (getDefaultPrefs(defaultPrefs?.content, prefsCodes.defaultPayTo) &&
        getDefaultPrefs(defaultPrefs?.content, prefsCodes.defaultPayTo)?.toUpperCase() === clinic?.DCNGuid?.toUpperCase()) ||
      doc?.DoctorGuid === NA_DoctorGuid ||
      doc?.DoctorGuid === multipleDoctorGuid
        ? { ...clinic, Type: clinicType }
        : { ...doc, Type: practitionerType };

    const claimRecordWithUpdatedPractitioner = {
      ...privateRecord,
      ...updatePractitionerValues(doc),
      DCNGuid: doc?.DCNGuid || privateRecord?.DCNGuid,
      PractitionerNumber: doc?.PractitionerNumber || '',
      [inputs().payTo.name]: payTo,

      // [KS] reset services when practitioner is changed
      [inputs().privateService.name]: isInvoicePatientForBalance ? privateRecord?.[inputs().privateService.name] : [],
      [inputs().privateService.descriptionName]: isInvoicePatientForBalance ? privateRecord?.[inputs().privateService.descriptionName] : []
    };

    const newPrefs = updateUserDefaultPrefs({ defaultPrefs, currentUserDefaultPrefs, doctorGuid: doc?.DoctorGuid });
    const updatedClaimRecord = updatePreferencesForPrivateRecord(claimRecordWithUpdatedPractitioner, newPrefs);

    !isDirty && dispatch(setDirty()); // [KS] CMO-1399 - Cannot save changes in practitioner on Edit Outs claim
    dispatch(updatePrivateRecord(updatedClaimRecord));
  };

  const onBlurDoctor = (doctorGuid) => {
    // practitioner input validation
    doctorGuid === NA_DoctorGuid ? setPractitionerIsRequired(true) : setPractitionerIsRequired(false);
  };

  return (
    <>
      <ClaimInputField label={commonInputs.location.label}>
        {isInvoicePatientForBalance ? (
          <InputText disabled value={privateRecord.LocationName} className="w-full" />
        ) : (
          <Dropdown
            id={elementIDs.locationPickerDropdown}
            className="flex"
            optionLabel="ClinicName"
            value={location}
            options={locations}
            scrollHeight={dropdownScrollHeight()}
            onChange={(e) => onSelectLocation(e.value)}
          />
        )}
      </ClaimInputField>

      <ClaimInputField
        // [KS] CMO-1167 - Create/Edit claim - add "lock" button for inputs, which have preferences values
        presetCode={prefsCodes.presetPractitioner}
        inputValue={privateRecord?.DoctorGuid}
        updateDefaultPractitioner={true}
        updatedPresetPrefs={[
          { value: '1', label: prefsCodes.presetPractitioner },
          { value: privateRecord?.DoctorGuid, label: prefsCodes.defaultPractitioner }
        ]}
        isLocked={getPresetPrefs(privateRecord?.CurrentUserPrefs?.content, prefsCodes.presetPractitioner)}
        //=======================//
        label={commonInputs.practitioner.label}
        showPreferencesButton={true}
        required={true}
      >
        <Dropdown
          id={elementIDs.practitionerPickerDropdown}
          className={cx('flex', practitionerIsRequired ? 'p-invalid' : '')}
          optionLabel="FullName"
          value={practitioner}
          options={practitionersList}
          disabled={isInvoicePatientForBalance}
          scrollHeight={dropdownScrollHeight()}
          placeholder={t('N_A')}
          onChange={(e) => onSelectDoctor(e.value)}
          onBlur={() => onBlurDoctor(privateRecord?.DoctorGuid)}
        />
        {practitionerIsRequired && requiredError}
      </ClaimInputField>
    </>
  );
};

export default HeaderPanel;
