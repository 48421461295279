import { formatDate } from '../../../../utils/formatDate';
import { inputs } from '../helpers/inputs';
import { decodeText } from '../../../../utils/decodeText';
import { parseMSPCodes } from '../../../../utils/parser';
import { convertInvoiceType } from './convertInvoiceType';
import moment from 'moment';

export const formatInitialValues = (initialValues) => {
  const invoiceType = initialValues[inputs.payor.name];
  const injuryDate = initialValues[inputs.injuryDate.name];
  const serviceDate = initialValues[inputs.serviceDate.name];
  const feeCode = initialValues[inputs.feeCodes.name];
  const feeDescription = initialValues[inputs.feeCodes.codeDescription];
  const dxCode = initialValues[inputs.icd9.name];
  const dxDescription = initialValues[inputs.icd9.codeDescription];
  const aoiCode = initialValues[inputs.aoi.name];
  const aoiDescription = initialValues[inputs.aoi.codeDescription];
  const noiCode = initialValues[inputs.noi.name];
  const noiDescription = initialValues[inputs.noi.codeDescription];
  const referralCode = initialValues[inputs.referral.name];
  const referralDescription = initialValues[inputs.referral.codeDescription];
  const callTime = initialValues[inputs.callTime.name];
  const startTime = initialValues[inputs.startTime.name];
  const endTime = initialValues[inputs.endTime.name];
  const mspCodes = initialValues[inputs.mspCodes.name];
  const dateCreated = initialValues[inputs.dateCreated.name];
  const dateUpdated = initialValues[inputs.dateUpdated.name];
  const sentDate = initialValues[inputs.sentDate.name];
  const units = initialValues[inputs.units.name];
  const percent = initialValues[inputs.percent.name];
  const status = initialValues[inputs.status.name];

  let dxCodes = dxCode;
  let errorCodes = [];

  if (initialValues.DiagnosticCode2) dxCodes += ',' + initialValues.DiagnosticCode2;
  if (initialValues.DiagnosticCode3) dxCodes += ',' + initialValues.DiagnosticCode3;

  if (mspCodes) errorCodes = parseMSPCodes(mspCodes);

  return {
    ...initialValues,

    [inputs.payor.name]: convertInvoiceType(invoiceType, true),
    [inputs.mspCodes.name]: errorCodes,
    [inputs.dateCreated.name]: dateCreated ? formatDate(dateCreated) : dateCreated,
    [inputs.dateUpdated.name]: dateUpdated ? formatDate(dateUpdated) : dateUpdated,
    [inputs.sentDate.name]: sentDate ? formatDate(sentDate) : sentDate,
    [inputs.units.name]: units > 0 ? units : 1,
    [inputs.percent.name]: percent > 0 ? percent : 100,

    [inputs.status.name]: status || 0,
    [inputs.saveAsDraft.name]: String(status) === '4' ? true : false,

    [inputs.injuryDate.name]: injuryDate ? moment.utc(injuryDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null,
    [inputs.serviceDate.name]: serviceDate ? moment.utc(serviceDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null,

    [inputs.feeCodes.name]: feeCode ? [feeCode] : [],
    [inputs.feeCodes.codeDescription]: feeDescription ? [feeDescription] : [],

    [inputs.icd9.name]: dxCodes ? dxCodes?.split(',') : [],
    [inputs.icd9.codeDescription]: dxCodes ? decodeText(dxDescription).split('|') : [],

    [inputs.aoi.name]: aoiCode ? [aoiCode] : [],
    [inputs.aoi.codeDescription]: aoiDescription ? [aoiDescription] : [],

    [inputs.noi.name]: noiCode ? [noiCode] : [],
    [inputs.noi.codeDescription]: noiDescription ? [noiDescription] : [],

    [inputs.referral.name]: referralCode ? [referralCode] : [],
    [inputs.referral.codeDescription]: referralDescription ? [referralDescription] : [],

    [inputs.callTime.name]: callTime ? formatTime(callTime) : '',
    [inputs.startTime.name]: startTime ? formatTime(startTime) : '',
    [inputs.endTime.name]: endTime ? formatTime(endTime) : '',

    [inputs.comment.name]: decodeText(initialValues[inputs.comment.name])
  };
};

const formatTime = (time) => moment(time, 'hh:mm A').format('HH:mm');
