import { defaultGuid } from "../../../../config/defaultValuesConfig";

export const getGroupTemplateDefaultValues = () => {
    return (
        {
            DCNGuid: '',
            DoctorGuid: defaultGuid,
            TemplateGuid: defaultGuid,
            Name: '',
            Description: '',
            DateCreated: '',
            Status: 1,
            Records: [],
            FirstStep: {}
        }
    )
};