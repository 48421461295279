import { Image } from 'primereact/image';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';

let counterParagraph = 0;
let counterList = 0;

export const contentfulOptions = (assets) => ({
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <b key={`${text}-key`} className="font-bold">
        {text}
      </b>
    )
  },

  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a key={data.uri} href={data.uri} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => {
      counterList++;
      return (
        <li key={`list-item-${counterList}`} className="list-disc line-height-3 mt-2">
          {children}
        </li>
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      counterParagraph++;
      return <p key={`paragr-${counterParagraph}`}>{children}</p>;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { file, title } = node.data.target.fields;
      const imageUrl = `https:${file.url}`;
      return <img key={imageUrl} src={imageUrl} alt={title || 'Contentful Image'} className="contentful-image" />;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const assetId = node.data.target.sys.id;
      const asset = assets[assetId];

      if (asset && asset.fields?.file?.url) {
        const imageUrl = `https:${asset.fields.file.url}`;
        const altText = asset.fields?.title || 'Contentful Image';
        return (
          <div className="text-center">
            <Image
              src={imageUrl}
              alt={altText}
              width="250" // Adjust the width as needed
              preview
              indicatorIcon={<i className="pi pi-search-plus" />}
            />
          </div>
        );
      }

      return <p className="text-red-500">Image not available</p>;
    }
  }
});
