import React from 'react';
import MenuLayout from './MenuLayout';
import NewBadge from '../../../../components/Misc/Badges/NewBadge';
import DaysheetUploadButton from '../../../../components/Widgets/DaysheetUploadButton';

const Memos = () => {
  const items = [
    {
      label: <div className="font-bold">Memos</div>,
      items: [
        {
          template: (item) => (
            <div className="flex align-items-center gap-2 w-full">
              <DaysheetUploadButton
                hideNewBadge
                chooseOptions={{
                  className: 'w-full p-button-text text-color-secondary'
                }}
              />
              <NewBadge />
            </div>
          )
        }
      ]
    }
  ];

  return <MenuLayout model={items} />;
};

export default Memos;
