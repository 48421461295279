import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import InputWrapper from '../InputWrapper/InputWrapper';

import { usePrivateInvoicePrefs } from '../../../modules/preferences/components/UserPreferences/hooks/usePrivateInvoicePrefs';
import { prefsCodes } from '../../../modules/config/prefsCodesConfig';
import { dropdownScrollHeight } from '../../../modules/config/stylesConfig';
import { formatPhoneNumber } from '../../../modules/utils/formatPhoneNumber';
import { getPayeeOptions } from '../../../modules/utils/getPayeeInfo';
import { defaultGuid } from '../../../modules/config/defaultValuesConfig';
import { t } from '../../../service/localization/i18n';

// interface PayeePhoneProps {
//   value: DropdownOption;
//   onChange: (value: any) => void;
//   savePrefsOnChange?: boolean;
// }

// interface DropdownOption = {
//   name: string;
//   data: Clinic | Practitioner; // formatted Clinic or Practitioner data object
//   id: string;
// }

const PayeePhone = ({ name, value, onChange, savePrefsOnChange }) => {
  const { savePrefs, isLocked } = usePrivateInvoicePrefs();
  const payeeOptions = getPayeeOptions();
  const prefsCode = prefsCodes.privateInvoicePhone;

  const itemTemplate = (option) => {
    if (option?.id === defaultGuid) return <span>{option?.name}</span>;

    return (
      <div className="flex flex-column gap-1">
        <span>{formatPhoneNumber(option?.data?.Phone)}</span>
        <span className="text-sm text-600">{option?.name}</span>
      </div>
    );
  };

  const valueTemplate = (option) => {
    if (option?.id === defaultGuid) return <span>{option?.name}</span>;
    return <span>{formatPhoneNumber(option?.data?.Phone)}</span>;
  };

  const handleChange = (e) => {
    onChange(e);
    savePrefsOnChange && onSavePrefs(e.value.id);
  };

  const onSavePrefs = (id) => {
    savePrefs(id, prefsCode);
  };

  return (
    <InputWrapper
      name="phone"
      label={t('Phone')}
      isLocked={isLocked(value?.id, prefsCode)}
      onSavePrefs={savePrefsOnChange ? undefined : () => onSavePrefs(value?.id)}
    >
      <Dropdown
        optionLabel="name"
        value={value}
        options={payeeOptions}
        scrollHeight={dropdownScrollHeight()}
        itemTemplate={itemTemplate}
        valueTemplate={valueTemplate}
        onChange={handleChange}
        ariaLabel="Select phone"
      />
    </InputWrapper>
  );
};

export default PayeePhone;
