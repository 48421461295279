import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteDialog from '../DeleteDialog/DeleteDialog';
import { setShowDeletePinDialog } from '../../../modules/core/actions/core.action.creators';
import { editPinCode } from '../../../modules/user/actions/user.action.creators';
import { localStorageKeys } from '../../../modules/config/localStorageKeysConfig';

const DeletePinCode = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const { showDeletePinDialog } = useSelector((state) => state.core);

  const onHide = () => {
    dispatch(setShowDeletePinDialog(false));
  };

  const onAccept = () => {
    const storedPinData = JSON.parse(localStorage.getItem(localStorageKeys.pinCode));
    if (!storedPinData) return;

    editPinCode({
      DoctorGuid: user.UserGuid,
      DCNGuid: storedPinData.DCNGuid,
      DeviceGuid: storedPinData.DeviceGuid,
      Signature: storedPinData.Signature,
      DeviceName: '',
      Fingerprint: '' // Empty fingerprint to delete the pin code
    });

    localStorage.removeItem(localStorageKeys.pinCode);
  };

  return (
    <DeleteDialog
      visible={showDeletePinDialog}
      message="Are you sure you want to delete the PIN code for this device?"
      accept={onAccept}
      onHide={onHide}
    />
  );
};

export default DeletePinCode;
