import React from 'react';
import PageWrap from '../../../components/Wraps/PageWrap/PageWrap';
import TableCard from '../../../components/Wraps/PageWrap/TableCard';
import Daysheet from './index';

const DaysheetPage = () => {
  return (
    <PageWrap>
      <div className="w-full" style={{ maxWidth: '1000px' }}>
        <TableCard>
          <Daysheet />
        </TableCard>
      </div>
    </PageWrap>
  );
};

export default DaysheetPage;
