import React, { useEffect, useMemo, useState } from 'react';
import CashFlowCard from './CashFlowCard';
import { getCashFlow } from '../../../cashFlow/actions/cashFlow.axtion.creators';
import { currencyFormat } from '../../../utils/currencyFormat';
import { Chart } from 'primereact/chart';
import { colors } from '../../../config/stylesConfig';
import { useCashFlowCards } from './useCashFlowCards';
import moment from 'moment';

const Last365DaysCard = () => {
  const { onCardClick } = useCashFlowCards();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const responseData = await getCashFlow({ type: 4 });
    setLoading(false);
    setData(responseData);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => onCardClick(365);

  const chartData = useMemo(() => {
    const items = data?.Items;

    if (!items?.length) {
      return null;
    }

    const dateFormat = 'MMM Do YYYY';
    const totalBilled = items?.reduce((acc, item) => acc + item.TotalBilled, 0);

    const labels = items
      ?.map((i) => moment(i.PeriodEnd).format(dateFormat))
      .sort((a, b) => moment(a, dateFormat).toDate() - moment(b, dateFormat).toDate());

    return {
      labels,
      datasets: [
        {
          id: 'billed',
          label: `Billed (${currencyFormat(totalBilled)})`,
          backgroundColor: colors.billed,
          borderColor: colors.billed,
          data: items?.map((i) => i?.TotalBilled).sort((a, b) => moment(a, dateFormat).toDate() - moment(b, dateFormat).toDate())
        }
      ]
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const options = useMemo(() => {
    return {
      responsive: true, // Make the chart responsive
      maintainAspectRatio: false, // Don't lock the aspect ratio
      plugins: {
        legend: {
          display: false // Hide legend
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const dataset = tooltipItem.dataset;
              const dataValue = dataset.data[tooltipItem.dataIndex];

              // Check if it's the Billed dataset
              if (dataset.id === 'billed') {
                return `Billed: ${currencyFormat(dataValue)}`;
              }
              // Check if it's the Paid dataset
              if (dataset.id === 'paid') {
                return `Paid: ${currencyFormat(dataValue)}`;
              }

              return `${dataset.label}: $${dataValue}`;
            }
          }
        }
      },
      scales: {
        x: {
          display: false // Hide x-axis
        },
        y: {
          display: false // Hide y-axis
        }
      },
      elements: {
        line: {
          borderWidth: 2 // Adjust line thickness
        }
      }
    };
  }, []);

  return (
    <CashFlowCard title="Last 365 days" subtitle="Billed (CAD)" amount={data?.TotalBilled} data={data} loading={loading} onClick={onClick}>
      <Chart className="w-full h-full" type="bar" data={chartData} options={options} />
    </CashFlowCard>
  );
};

export default Last365DaysCard;
