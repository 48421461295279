import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import Context from '../../../../Context';
import { Calendar } from 'primereact/calendar';
import { updatePrivateRecord, setMissingCodesToRecentList } from '../../actions/claims.action.creators';
import { requiredError } from '../../../common/components/inputInfoMessages';
import { inputs } from './claimDetailsHelpers/inputs';
import { addTermsToDueDate } from './claimDetailsHelpers/addTermsToDueDate';
import { commonInputs } from '../../../config/commonInputsConfig';
import { serviceDateFormat } from '../../../utils/serviceDateFormat';
import { localStorageKeys } from '../../../config/localStorageKeysConfig';
import { t } from '../../../../service/localization/i18n';
import moment from 'moment';
import cx from 'classnames';

const DatePicker = ({ id = '', errors, required, dateField, initialDate, setDateToLocalStorage = false }) => {
  const dispatch = useDispatch();
  const { privateRecord, catalogMostRecentCodes } = useContext(Context);
  const [date, setDate] = useState(new Date());
  const [invalid, setInvalid] = useState(false);

  const dateFormat = (value) => moment(value, 'MM/DD/YYYY').format('YYYY-MM-DD');

  useEffect(() => {
    setDate(initialDate ? new Date(moment(initialDate, 'YYYY/MM/DD').format('MM/DD/YYYY')) : new Date());
    date ? setInvalid(false) : setInvalid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate]);

  const onDateSelect = (value) => {
    value ? setInvalid(false) : setInvalid(true);
    const dataForUpdating =
      dateField === inputs().invoiceDate.name
        ? {
            [dateField]: value ? dateFormat(value) : '',
            [inputs().dueDate.name]: addTermsToDueDate(privateRecord[inputs().terms.name], dateFormat(value))
          }
        : { [dateField]: value ? dateFormat(value) : '' };
    setDate(value);
    dispatch(updatePrivateRecord(dataForUpdating));
    setDateToLocalStorage && localStorage.setItem(localStorageKeys.serviseDate, JSON.stringify(value ? dateFormat(value) : null)); // [KS] CMO-1423 - Persist service date during session

    // Add date to the quick pick catalogs if it's not in the list
    if (dateField === commonInputs.serviceDate.name) {
      const formattedServiceDate = Array.isArray(value) ? value.map((i) => serviceDateFormat(i)) : [serviceDateFormat(value)];
      dispatch(setMissingCodesToRecentList({ codes: { [commonInputs.serviceDate.codeType]: formattedServiceDate } }));
    }
  };

  const onDateChange = (value) => {
    setDate(value);
    value ? setInvalid(false) : setInvalid(true);
    const dataForUpdating =
      dateField === inputs().invoiceDate.name
        ? {
            [dateField]: value ? dateFormat(value) : '',
            [inputs().dueDate.name]: addTermsToDueDate(privateRecord[inputs().terms.name], dateFormat(value))
          }
        : { [dateField]: value ? dateFormat(value) : '' };
    dispatch(updatePrivateRecord(dataForUpdating));
  };

  const onBlur = (e) => {
    const dateValue = e.target.value;
    const oldDate = moment(initialDate).format('MM/DD/YYYY');
    const newDate = moment(dateValue).format('MM/DD/YYYY');
    if (oldDate === newDate) return;

    if (dateValue) {
      setInvalid(false);
    } else {
      setDate('');
      setInvalid(true);
    }

    if (dateFormat(dateValue) === 'Invalid date' && dateValue !== '') {
      setDate(dateValue);
      setInvalid(true);
    } else {
      dispatch(updatePrivateRecord({ [dateField]: dateFormat(dateValue) === 'Invalid date' ? '' : dateFormat(dateValue) }));
      setDateToLocalStorage && localStorage.setItem(localStorageKeys.serviseDate, JSON.stringify(dateValue ? dateFormat(dateValue) : null)); // [KS] CMO-1423 - Persist service date during session
    }
  };

  const dateTemplate = (data) => {
    if (catalogMostRecentCodes?.visits?.some((i) => i.Day === data.day && i.Month === data.month && i.Year === data.year)) {
      return <div className="flex justify-content-center align-items-center lastVisitsDateTempate">{data.day}</div>;
    } else {
      return data.day;
    }
  };

  const isValid = required && invalid ? 'p-invalid' : required && errors && !date ? 'p-invalid' : null;

  return (
    <>
      <Calendar
        id={id}
        inputId={`input_${id}`}
        className={cx('flex', isValid)}
        value={date}
        placeholder={t('MM_DD_YYYY')}
        dateFormat="mm/dd/yy"
        mask="99/99/9999"
        inputMode="numeric"
        showTime={false}
        showIcon={true}
        showButtonBar
        showOnFocus={true} // [CMO-777 - Primereact Calendar component common issue]
        baseZIndex={1000000}
        selectOtherMonths
        dateTemplate={dateField === commonInputs.serviceDate.name && dateTemplate}
        onBlur={onBlur}
        onSelect={(e) => onDateSelect(e.value)}
        onChange={(e) => onDateChange(e.value)}
      />

      {/* {date && (
        <span className="mt-1 block text-disabled-color small-text">
          {t("MM_DD_YYYY")}
        </span>
      )} */}

      {required && invalid ? requiredError : required && errors && !date ? requiredError : null}
    </>
  );
};

export default DatePicker;
