import React, { useRef, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { OverlayPanel } from 'primereact/overlaypanel';
import { getLastXDaysRange } from '../../../utils/getDate';
import moment from 'moment';

// Helper for inclusive day counts
function getDayDifferenceInclusive(start, end) {
  if (!start || !end) return 0;
  // e.g. Jan 3 to Jan 9 => 6 + 1 = 7
  return moment(end).startOf('day').diff(moment(start).startOf('day'), 'days') + 1;
}

const DateChangeArrows = () => {
  const op = useRef(null);
  const { watch, setValue } = useFormContext();
  const periodFrom = watch('PeriodFrom');
  const periodTo = watch('PeriodTo');

  const [selectedRange, setSelectedRange] = useState(null); // e.g. "7" for "7 days"
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(moment().year());

  // Calculate date difference dynamically (inclusive)
  const calculateDateDifference = () => getDayDifferenceInclusive(periodFrom, periodTo);

  // Set initial selected range based on the current date difference
  useEffect(() => {
    const diff = getDayDifferenceInclusive(periodFrom, periodTo);
    setSelectedRange(diff);
  }, [periodFrom, periodTo]);

  // Handlers for arrow buttons
  const onLeftArrowClick = () => {
    if (selectedMonth !== null) {
      // If a month is selected, shift to the previous month
      const newMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
      const newYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
      const newPeriodFrom = moment().month(newMonth).year(newYear).startOf('month').toDate();
      const newPeriodTo = moment().month(newMonth).year(newYear).endOf('month').toDate();
      setValue('PeriodFrom', newPeriodFrom);
      setValue('PeriodTo', newPeriodTo);
      setSelectedMonth(newMonth);
      setSelectedYear(newYear);
    } else {
      // Navigate based on day range
      const dateDifference = calculateDateDifference();
      if (periodFrom && periodTo && dateDifference > 0) {
        const newPeriodFrom = moment(periodFrom).subtract(dateDifference, 'days').toDate();
        const newPeriodTo = moment(periodTo).subtract(dateDifference, 'days').toDate();
        setValue('PeriodFrom', newPeriodFrom);
        setValue('PeriodTo', newPeriodTo);
      }
    }
  };

  const onRightArrowClick = () => {
    if (selectedMonth !== null) {
      // If a month is selected, shift to the next month
      const newMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
      const newYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
      const newPeriodFrom = moment().month(newMonth).year(newYear).startOf('month').toDate();
      const newPeriodTo = moment().month(newMonth).year(newYear).endOf('month').toDate();
      setValue('PeriodFrom', newPeriodFrom);
      setValue('PeriodTo', newPeriodTo);
      setSelectedMonth(newMonth);
      setSelectedYear(newYear);
    } else {
      // Navigate based on day range
      const dateDifference = calculateDateDifference();
      if (periodFrom && periodTo && dateDifference > 0) {
        const newPeriodFrom = moment(periodFrom).add(dateDifference, 'days').toDate();
        const newPeriodTo = moment(periodTo).add(dateDifference, 'days').toDate();
        setValue('PeriodFrom', newPeriodFrom);
        setValue('PeriodTo', newPeriodTo);
      }
    }
  };

  // For the label, e.g. "7 days" or "January 2025"
  const dateDifference = calculateDateDifference();
  const dateDifferenceLabel = dateDifference === 1 ? 'day' : 'days';
  const displayLabel = selectedMonth !== null ? `${moment.months()[selectedMonth]} ${selectedYear}` : `${dateDifference} ${dateDifferenceLabel}`;

  // Handler for quick pick buttons in the OverlayPanel
  const onQuickPick = (days) => {
    const { from, to } = getLastXDaysRange(days);
    setValue('PeriodFrom', from);
    setValue('PeriodTo', to);
    setSelectedRange(days);
    setSelectedMonth(null);
    setSelectedYear(moment().year());
    op.current.hide();
  };

  // Handler for filtering by month
  const onMonthPick = (monthIndex) => {
    const year = moment().year();
    const newPeriodFrom = moment().month(monthIndex).year(year).startOf('month').toDate();
    const newPeriodTo = moment().month(monthIndex).year(year).endOf('month').toDate();
    setValue('PeriodFrom', newPeriodFrom);
    setValue('PeriodTo', newPeriodTo);
    setSelectedMonth(monthIndex);
    setSelectedYear(year);
    setSelectedRange(null);
    op.current.hide();
  };

  const months = moment.months();

  return (
    <div className="flex justify-content-between align-items-center w-full">
      {/* Left Arrow */}
      <Button
        icon="pi pi-chevron-left"
        rounded
        text
        tooltip={`Previous ${displayLabel}`}
        tooltipOptions={{ position: 'top' }}
        onClick={onLeftArrowClick}
      />

      {/* Date Difference or Selected Month */}
      <div className="pointer" type="button" onClick={(e) => op.current.toggle(e)}>
        {displayLabel}
      </div>

      {/* Right Arrow */}
      <Button
        icon="pi pi-chevron-right"
        rounded
        text
        tooltip={`Next ${displayLabel}`}
        tooltipOptions={{ position: 'top' }}
        onClick={onRightArrowClick}
      />

      {/* Overlay Panel with Quick Pick Buttons */}
      <OverlayPanel ref={op} style={{ maxWidth: '400px' }}>
        <div>
          <h6 className="text-center">Select Date Range</h6>
          <div className="flex flex-wrap justify-content-center gap-3">
            {/* 1 Day */}
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 1 || selectedMonth !== null}
              label="1 Day"
              onClick={() => onQuickPick(1)}
            />
            {/* 7 Days */}
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 7 || selectedMonth !== null}
              label="7 Days"
              onClick={() => onQuickPick(7)}
            />
            {/* 14 Days */}
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 14 || selectedMonth !== null}
              label="14 Days"
              onClick={() => onQuickPick(14)}
            />
            {/* 30 Days */}
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 30 || selectedMonth !== null}
              label="30 Days"
              onClick={() => onQuickPick(30)}
            />
            {/* 60 Days */}
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 60 || selectedMonth !== null}
              label="60 Days"
              onClick={() => onQuickPick(60)}
            />
            {/* 90 Days */}
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 90 || selectedMonth !== null}
              label="90 Days"
              onClick={() => onQuickPick(90)}
            />
            {/* 180 Days */}
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 180 || selectedMonth !== null}
              label="180 Days"
              onClick={() => onQuickPick(180)}
            />
            {/* 365 Days */}
            <Button
              form="cash-flow-filters-form"
              rounded
              text={selectedRange !== 365 || selectedMonth !== null}
              label="365 Days"
              onClick={() => onQuickPick(365)}
            />
          </div>

          <Divider />

          <div className="flex flex-wrap justify-content-center gap-3">
            {months.map((month, index) => (
              <Button
                key={month}
                style={{ width: '100px' }}
                form="cash-flow-filters-form"
                rounded
                text={selectedMonth !== index}
                label={month}
                onClick={() => onMonthPick(index)}
              />
            ))}
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default DateChangeArrows;
