import { store } from '../../../store';
import { formatPatientDetails } from '../helpers/formatPatientDetails';
import patientsForAutocomplete from '../../../mocks/db/patients/patientsForAutocomplete.json';
import actions from './patients.action.types';
import { setPatientIdForHighlightInTable } from './patients.action.creators';
import cypressTestPatientInfoResponse from '../../../mocks/db/patients/getPatientInfo/cypressTestPatientInfoResponse.json';
import corVisitsPatientInfoResponse from '../../../mocks/db/patients/getPatientInfo/corVisitsPatientInfoResponse.json';
import validBCPHN from '../../../mocks/db/patients/phn/validBCPHN.json';
import invalidBCPHN from '../../../mocks/db/patients/phn/invalidBCPHN.json';
import duplicatePatientsListForLastNameValidation from '../../../mocks/db/patients/duplicatePatients/duplicatePatientsListForLastNameValidation.json';
import duplicatePatientsListForValidPHN from '../../../mocks/db/patients/duplicatePatients/duplicatePatientsListForValidPHN.json';
import duplicateArchivedPatientsListForValidPHN from '../../../mocks/db/patients/duplicatePatients/duplicateArchivedPatientsListForValidPHN.json';
import defaultEligibility from '../../../mocks/db/patients/eigibility/defaultEligibility.json';
import { setEligibilityError, setToastMessage } from '../../core/actions/core.action.creators';
import { setErrorMessageAutofill } from './patients.action.creators';

export const createPatientResponseAction = (patient, callback) => {
  callback && callback(formatPatientDetails(patient));
};

export const createPatientResponseActionForTests = (patientGuid, callback) => {
  const defaultPatientGuid = 'ff6b9c13-a196-44a0-b4c1-b8fc23b4392a';
  let patient = patientsForAutocomplete.find((i) => i.PatientGuid?.toUpperCase() === patientGuid?.toUpperCase());
  if (!patient) patient = patientsForAutocomplete.find((i) => i.PatientGuid?.toUpperCase() === defaultPatientGuid?.toLocaleUpperCase());
  createPatientResponseAction(patient, callback);
};

export const getPatientInfoResponseAction = (responce, callback) => {
  if (callback) {
    return callback(formatPatientDetails(responce.data));
  } else {
    store.dispatch(setPatientIdForHighlightInTable(responce.data));
    return store.dispatch({ type: actions.FETCH_PATIENT_DETAILS_SUCCESS, results: responce });
  }
};

export const getPatientInfoResponseActionForTests = (id, callback) => {
  const patientInfo = getPatientInfoForTests(id);
  getPatientInfoResponseAction({ data: patientInfo }, callback);
};

export const getPatientInfoForTests = (id) => {
  const patients = {
    '4329b102-5196-4531-8bd6-d983b1c9d2a9': cypressTestPatientInfoResponse,
    '324323b5-221f-4657-b8f2-887879ed6630': corVisitsPatientInfoResponse
  };

  const patientInfo = patients[id] || cypressTestPatientInfoResponse;
  return formatPatientDetails(patientInfo.data);
};

export const validatePHNAction = (responceData, callback) => {
  callback && callback(responceData);
};

export const validatePHNActionForTests = (phn, callback) => {
  // If province is not BC, then any PHN is valid
  if (phn.slice(0, 2) !== 'BC') return validatePHNAction(true, callback);

  const phnList = {
    BC4242424242: true, // valid BC PHN
    BC9876543218: validBCPHN.result, // valid BC PHN
    BC9876543211: true, // valid BC PHN for archived patient
    BC1234567890: invalidBCPHN.result // invalid BC PHN
  };

  const currentPHN = phnList[phn] || invalidBCPHN.result;
  validatePHNAction(currentPHN, callback);
};

export const duplicatePatientCheckAction = (responceData, callback) => {
  callback && callback(responceData);
};

export const duplicatePatientCheckctionForTests = (patientData, callback) => {
  let duplicateList = [];

  if (patientData.LastName === 'NEW' && !patientData.PHN) {
    duplicateList = duplicatePatientsListForLastNameValidation;
  }

  if (!patientData.LastName && patientData.PHN?.replaceAll(' ', '') === '9876543218') {
    duplicateList = duplicatePatientsListForValidPHN;
  }

  // Archived patient with PHN
  if (!patientData.LastName && patientData.PHN?.replaceAll(' ', '') === '9876543211') {
    duplicateList = duplicateArchivedPatientsListForValidPHN;
  }

  duplicatePatientCheckAction(duplicateList, callback);
};

export const getEligibilityPHNAction = (responceData, callback) => {
  if (responceData.ErrorMessage) {
    return store.dispatch(setToastMessage({ type: 'error', message: getEligibilityErrorMessage(responceData.ErrorMessage) }));
  } else {
    callback && callback(responceData);
  }
};

export const getEligibilityPHNActionAutofill = (responseData, setShowImage, formik, callback, imageIndex) => {
  if (responseData.ErrorMessage) {
    const errorMessage = responseData.ErrorMessage;

    store.dispatch(setEligibilityError({ error: errorMessage || null }));

    if (errorMessage.includes('BIRTHDATES DO NOT MATCH')) {
      setShowImage(true);
      if (formik) {
        formik.handleSubmit();
      }
      store.dispatch(setErrorMessageAutofill({index: imageIndex, type: 'error', message: 'Birthday and PHN mismatch. Correct PHN/DOB and click Check Eligibility, or enter the name manually to override the mismatch.', originalMSPErrorMessage: errorMessage, formData: { ...formik?.values || {} } }));
    } else if (errorMessage.includes('Invalid: PERSONAL HEALTH NUMBER')) {
      setShowImage(true);
      if (formik) {
        formik.handleSubmit();
      }
      store.dispatch(setErrorMessageAutofill({index: imageIndex, type: 'error', message: 'PHN appears invalid. Correct it and click Eligibility Check. To override this warning, enter the name manually', originalMSPErrorMessage: errorMessage, formData: { ...formik?.values || {} } }));
    } else {
      setShowImage(true);
      if (formik) {
        formik.handleSubmit();
      }
      store.dispatch(setErrorMessageAutofill({index: imageIndex, type: 'error', message: "Name not recognized. Please enter it manually.", originalMSPErrorMessage: errorMessage, formData: { ...formik?.values || {} } }));
    }  } else {
    callback && callback(responseData);
  }
};
export const getEligibilityErrorMessage = (responceMessage) => {
  // WIN-633 - Update eligibility error message
  if (
    responceMessage.includes('Connection with remote facility timed out') ||
    responceMessage.includes('TETA-002 CLAIMS AND ELIG SUBSYSTEMS UNAVAILABLE')
  ) {
    responceMessage = `${responceMessage}. Teleplan eligibility check is temporarily down. Please enter the patient's name and gender manually. Check eligibility later.`;
  }

  return responceMessage;
};

export const getEligibilityActionForTests = (params, callback) => {
  const phnList = {
    9876543211: defaultEligibility,
    4242424242: defaultEligibility
  };

  const currentPHN = phnList[params.PHN.replaceAll(' ', '')] || invalidBCPHN.result;
  getEligibilityPHNAction(currentPHN, callback);
};
