import { defaultCountryCode } from '../config/defaultValuesConfig';

export const formatZipCode = (zip, country = defaultCountryCode) => {
  if (!zip) return;
  if (!country || (country !== defaultCountryCode && country !== 'NZ' && country !== 'AU' && country !== 'GB')) {
    return zip;
  }

  const removeSpaces = zip.replace(/ /g, '').toUpperCase();
  const formatted = removeSpaces.substring(0, 3) + ' ' + removeSpaces.substring(3, 6);
  return formatted.trim();
};
