import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { practitionerDefaultValues } from '../../components/helpers/practitionerDefaultValues';
import FirstName from '../inputs/FirstName';
import LastName from '../inputs/LastName';
import Email from '../inputs/Email';
import PhoneNumber from '../inputs/PhoneNumber';
import PractitionerType from '../inputs/PractitionerType';
import CollegeLicenceNumber from '../../../../components/Inputs/CollegeLicenceNumber/CollegeLicenceNumber';
import PracticeType from '../../../../components/Inputs/PracticeType/PracticeType';
import StreetAddress from '../inputs/StreetAddress';
import City from '../inputs/City';
import Province from '../inputs/Province';
import PostalCode from '../inputs/PostalCode';
import MspPractitionerNumber from '../../../../components/Inputs/MspPractitionerNumber/MspPractitionerNumber';
import MspPayeeNumber from '../../../../components/Inputs/MspPayeeNumber/MspPayeeNumber';
import LicencingsBodies from '../../../../components/Inputs/LicencingsBodies/LicencingsBodies';
import { InputGroup } from '../../../../components/Inputs';
import { practitionerSerialNumber } from '../../components/helpers/practitionerSerialNumber';
import { saveRegistrationData } from '../../../../service/Lookup';
import RegistrationCard from '../RegistrationCard';
import { updateRegistrationFormState } from '../../actions/registration.actions.creators';

const PractitionerFormAttached = () => {
  const dispatch = useDispatch();

  const registrationState = useSelector((state) => state.registration.registrationFormState);

  const methods = useForm({
    mode: 'all',
    defaultValues: practitionerDefaultValues
  });
  const { handleSubmit, reset, watch, getValues, formState, control, trigger } = methods;

  const onSubmit = async (data) => {
    dispatch(
      updateRegistrationFormState({
        ...registrationState,
        type: 'secret-questions',
        initialFormState: {
          ...registrationState.initialFormState,
          attachedPractitioners: [...registrationState.initialFormState.attachedPractitioners, data] || []
        }
      })
    );

    const dataToSend = {
      ...registrationState.initialFormState,
      attachedPractitioners: [...registrationState.initialFormState.attachedPractitioners, data] || [],
      ReferenceCode: registrationState.ReferenceCode
    };

    await saveRegistrationData(dataToSend); // "Zip" property was changed to "postalCode" property inside function
  };

  const onClear = () => {
    reset(practitionerDefaultValues);
  };

  const onSkip = () => {
    dispatch(
      updateRegistrationFormState({
        ...registrationState,
        type: 'secret-questions',
        initialFormState: { ...registrationState.initialFormState, attachedPractitioners: [] }
      })
    );

    const dataToSend = {
      ...registrationState.initialFormState,
      ReferenceCode: registrationState.ReferenceCode
    };
    saveRegistrationData(dataToSend);
  };

  const onBack = () => {
    if (registrationState.firstStepType === 'register-clinic') {
      dispatch(
        updateRegistrationFormState({
          ...registrationState,
          type: 'register-clinic',
          initialFormState: { ...registrationState.initialFormState, password: '', confirmPassword: '' }
        })
      );
      return;
    }
    if (registrationState.firstStepType === 'register-bureau') {
      dispatch(
        updateRegistrationFormState({
          ...registrationState,
          type: 'register-bureau',
          initialFormState: { ...registrationState.initialFormState, password: '', confirmPassword: '' }
        })
      );
      return;
    }
  };

  const addNewPractitioner = async () => {
    const isValid = await trigger();
    if (isValid) {
      dispatch(
        updateRegistrationFormState({
          ...registrationState,
          initialFormState: {
            ...registrationState.initialFormState,
            attachedPractitioners: [...registrationState.initialFormState.attachedPractitioners, { ...getValues() }]
          }
        })
      );
      reset(practitionerDefaultValues);
    }
  };

  const practitionerType = watch('practitionerType');
  const specialtyLicencingsBodies = ['Physician', 'Optometrist', 'Acupuncturist', 'Chiropractor', 'RMT'].includes(practitionerType); // used to set the check condition for rendering LicencingsBodies

  return (
    <FormProvider {...methods}>
      <RegistrationCard
        customersType={`${registrationState.firstStepType === 'register-clinic' ? 'Clinic' : 'Billing Bureau'} Registration`}
        description={`Add ${practitionerSerialNumber(registrationState.initialFormState.attachedPractitioners?.length + 1)} practitioner to your ${
          registrationState.firstStepType === 'register-clinic' ? 'Clinic' : 'Billing Bureau'
        }.`}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="grid formgrid">
          <InputGroup>
            <FirstName label="Practitioner's First Name" />
            <LastName label="Practitioner's Last Name" />
          </InputGroup>
          <InputGroup>
            <PractitionerType />
            <CollegeLicenceNumber />
          </InputGroup>
          {specialtyLicencingsBodies && <LicencingsBodies name="licensingBody" />}
          {practitionerType === 'Physician' && <PracticeType name="practiceType" />}
          <InputGroup>
            <MspPractitionerNumber />
            <MspPayeeNumber />
          </InputGroup>

          <InputGroup>
            <Email control={control} requiredStatus={false} />
            <PhoneNumber control={control} requiredStatus={false} />
          </InputGroup>

          <InputGroup>
            <StreetAddress label="Street Address" requiredStatus={false} />
            <City requiredStatus={false} />
          </InputGroup>
          <InputGroup>
            <Province requiredStatus={false} />
            <PostalCode requiredStatus={false} />
          </InputGroup>

          <div className="flex flex-column aling-items-center justify-content-center w-full gap-3 mb-3">
            <Button
              data-testid="clearButtonPractitionerAttached"
              className="px-0"
              label="Clear All"
              style={{ width: '5%' }}
              type="button"
              link
              onClick={onClear}
            />
            <div className="flex gap-3 aling-items-center justify-content-center w-full">
              <Button data-testid="nextButtonPractitionerAttached" className="h-3rem" type="submit" label="Next" loading={formState.isSubmitting} />
              <Button
                data-testid="addPractitionerButton"
                className="h-3rem"
                type="button"
                label="Add Another Practitioner"
                outlined
                onClick={addNewPractitioner}
              />
              <Button
                data-testid="skipButtonPractitionerAttached"
                className="h-3rem"
                style={{ width: '70%' }}
                type="button"
                label="Skip"
                outlined
                onClick={onSkip}
              />
              <Button data-testid="backButtonPractitionerAttached" className="h-3rem" type="button" label="Back" outlined onClick={onBack} />
            </div>
          </div>
        </form>
      </RegistrationCard>
    </FormProvider>
  );
};

export default PractitionerFormAttached;
