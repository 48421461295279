import React from 'react';
import { RadioButton as PrimereactRadioButton } from 'primereact/radiobutton';

const RadioButton = ({ value, checked, onChange, name, ...props }) => {
  const handleKeyDown = (event) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      if (!checked) {
        onChange({
          target: { name, value },
          value,
          name
        });
      }
    }
  };

  return (
    <PrimereactRadioButton
      {...props}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      onKeyDown={handleKeyDown} // Fix space/enter issue
    />
  );
};

export default RadioButton;
