import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import CodeChip from '../../../../../../../components/Catalogs/Modules/Chips/CodeChip';
import ReferralCatalogsTable from '../../../../../../../components/Widgets/ReferralCatalogsTable/ReferralCatalogsTable';

import { deleteReferral } from '../../../../../../practitioners/actions/practitioners.action.creators';
import { updateInvoicesQuickPickCodesList } from '../../../../../actions/claims.action.creators';
import { useGroupPickerInputs } from '../../../views/TeleplanGroup/hooks/useGroupPickerInputs';
import { useGroupCatalogs } from '../../../views/TeleplanGroup/hooks/useGroupCatalogs';
import { useCodePickerActions } from '../../../hooks/useCodePickerActions';
import { useChipRemove } from '../../../hooks/useChipRemove';
import { inputs } from '../../../helpers/inputs';
import AddPractitionerButton from '../../ActionButtons/AddPractitionerButton';

const ReferralCatalogs = ({ scrollHeight, catalogsState, isGroup }) => {
  const dispatch = useDispatch();
  const { getValues, watch, localState, setLocalState } = useFormContext();
  const { onReferralChange } = useGroupPickerInputs();
  const { onCodeSelect } = useGroupCatalogs();
  const { onChipRemove } = useChipRemove();
  const { onChange } = useCodePickerActions(inputs.referral);
  const catalogsCodesList = catalogsState?.tableData?.codeList;
  const referral = watch(inputs.referral.codeType)?.[0];

  const onDelete = async (deletingData) => {
    setLocalState((prevState) => ({ ...prevState, isLoadingCatalogsData: true }));
    const result = await deleteReferral(deletingData.value);
    if (result) {
      setLocalState((prevState) => ({ ...prevState, isLoadingCatalogsData: false }));

      const mostRecentUpdatedList = catalogsCodesList?.filter((i) => i.value !== deletingData.value);

      if (isGroup) {
        // Remove code for each row that have this code
        localState.groupRecords?.forEach((record, index) => {
          const currentReferralCode = record?.[inputs.referral.codeType]?.[0];
          if (currentReferralCode && currentReferralCode?.value === deletingData.value) {
            onReferralChange([], { rowData: record, rowIndex: index });
          }
        });
      } else {
        const currentReferralNumber = getValues(inputs.referral.codeType);
        if (currentReferralNumber.some((i) => i.value === deletingData.value)) {
          onChange({ value: [] });
        }
      }

      // CMO-3047 - FE should refresh list of referrals after delete from catalogue
      dispatch(updateInvoicesQuickPickCodesList(mostRecentUpdatedList, inputs.referral.codeType));
    } else {
      setLocalState((prevState) => ({ ...prevState, isLoadingCatalogsData: false }));
    }
  };

  const onRowClick = (code) => {
    if (isGroup) {
      onCodeSelect(code, { catalogsState });
    } else {
      onChange({ value: [code] });
    }
  };

  return (
    <>
      <div className="flex justify-content-between align-items-center h-3rem">
        {referral ? (
          <div className="mt-3 mb-2 block lg:hidden">
            <CodeChip id={referral.value} item={referral} isSelected={true} label={referral.value} onClick={() => onChipRemove(referral)} />
          </div>
        ) : (
          <div></div>
        )}
        <AddPractitionerButton className="p-button-text" />
      </div>
      <ReferralCatalogsTable scrollHeight={scrollHeight} catalogsState={catalogsState} onRowClick={onRowClick} onDelete={onDelete} />
    </>
  );
};

export default ReferralCatalogs;
