import { t } from '../service/localization/i18n';

export const routes = {
  dashboard: {
    path: '/'
  },

  patients: {
    path: '/patients',
    title: 'Patients'
  },

  affiliates: {
    path: '/bureau/affiliates'
  },

  cashFlow: {
    path: '/cash-flow'
  },

  patientDetails: {
    path: '/patient-details',
    title: 'Patient details'
  },

  staffDetails: {
    path: '/staff-details',
    title: 'Staff Details'
  },

  createPatient: {
    path: '/create-patient',
    title: 'Patient details'
  },

  createNewborn: {
    path: '/create-newborn'
  },

  editNewborn: {
    path: '/edit-newborn'
  },

  roles: {
    path: '/roles',
    title: 'Roles'
  },

  roleDetails: {
    path: '/role-details',
    title: 'Role details'
  },

  createRole: {
    path: '/create-role',
    title: 'Create role'
  },

  editRole: {
    path: '/edit-role',
    title: 'Edit role'
  },

  claimInfo: {
    path: '/claim_info',
    title: 'Claim Info'
  },

  claimProfile: {
    path: '/claim_profile',
    title: 'CLaim profile'
  },

  claimNotesInbox: {
    path: '/claim_notes_inbox',
    title: 'Claim notes inbox'
  },

  c02viewer: {
    path: '/c02viewer',
    title: 'Submited Record'
  },

  patientNotesInbox: {
    path: '/patient_notes_inbox',
    title: 'Patient notes inbox'
  },

  remittances: {
    path: '/remittances',
    title: 'Remittances'
  },

  messages: {
    path: '/messages',
    title: 'Messages'
  },

  privetInvoice: {
    path: '/private-invoice',
    title: 'Private Invoice'
  },

  newWSBCReport: {
    path: '/wsbc-report/new',
    title: 'WSBC Report'
  },

  wsbcReportPreview: {
    path: '/wsbc-report/preview',
    title: 'WSBC Report'
  },

  wsbcReport: {
    path: '/wsbc-report',
    title: 'WSBC Report'
  },

  editClaimRecord: {
    path: '/teleplan-record/edit'
  },

  newClaimRecord: {
    path: '/teleplan-record/new',
    title: 'New claim record'
  },

  teleplanPOS: {
    path: '/teleplan-pos-record/new',
    title: 'New claim record'
  },

  teleplanGroup: {
    path: '/teleplan-group/new',
    title: 'New claim record'
  },

  claimRecordFirstVisit: {
    path: '/teleplan-record/new/first-visit',
    title: 'New claim record'
  },

  eClaimNew: {
    path: '/e-claim/new',
    title: 'eClaim'
  },

  eClaimView: {
    path: '/e-claim/view',
    title: 'eClaim'
  },

  eClaimEdit: {
    path: '/e-claim/edit'
  },

  eClaimPolicyNew: {
    path: '/e-claim-policy/new'
  },

  eClaimPolicyEdit: {
    path: '/e-claim-policy/edit'
  },

  privateRecord: {
    path: '/private-record',
    title: 'Private record'
  },

  invoicePatientForBalance: {
    path: '/private-record/e-claim'
  },

  daysheet: {
    path: '/daysheet',
    title: 'Daysheet'
  },

  daysheetsInbox: {
    path: '/daysheets-inbox'
  },

  newPrivateRecord: {
    path: '/private-record/new',
    title: 'New private record'
  },

  receivePayment: {
    path: '/receive-payment',
    title: 'Receive payment'
  },

  editPayment: {
    path: '/edit-payment',
    title: 'Receive payment'
  },

  invoices: {
    path: '/money/invoices',
    title: 'Invoices'
  },

  payments: {
    path: '/money/payments',
    title: 'Payments'
  },

  staff: {
    path: '/staff',
    title: 'Staff'
  },

  support: {
    path: '/support',
    title: 'Support'
  },

  ediPractitioner: {
    path: '/edit-practitioner',
    title: 'Practitioners'
  },

  appointments: {
    path: '/appointments',
    title: 'Appointments'
  },

  tableView: {
    path: '/table_view',
    title: 'TableView'
  },

  demoDetailsView: {
    path: '/demoDetailsView',
    title: 'demoDetailsView'
  },

  profile: {
    path: '/profile',
    title: 'Profile'
  },

  scheduler: {
    path: '/scheduler',
    title: 'Scheduler'
  },

  event: {
    path: '/event',
    title: 'Event'
  },

  billing: {
    path: '/billing',
    title: 'Billing'
  },

  clinic: {
    path: '/clinic',
    title: 'Clinic'
  },

  reports: {
    path: '/reports',
    title: 'Reports'
  },

  charts: {
    path: '/charts',
    title: 'Charts'
  },

  teleplan: {
    path: '/teleplan',
    title: 'Teleplan'
  },

  insurers: {
    path: '/insurers',
    title: 'Insurers'
  },

  audit: {
    path: '/audit',
    title: 'Audit'
  },

  settings: {
    path: '/settings',
    title: 'Settings'
  },

  userPreferences: {
    path: '/user-preferences',
    title: t('User_Preferences')
  },

  clinicPreferences: {
    path: '/clinic-preferences',
    title: t('Clinic_Preferences')
  },

  instructions: {
    path: '/instructions',
    title: 'Instructions'
  },

  upload: {
    path: '/upload',
    title: 'Upload'
  },

  userList: {
    path: '/user_list',
    title: 'userList'
  },

  print: {
    path: '/print',
    title: 'Print'
  },

  print_pdf: {
    path: '/print-pdf',
    title: 'Print'
  },

  editProfile: {
    path: '/edit_profile'
  },

  logs: {
    path: '/logs',
    title: 'Logs'
  },

  error: {
    path: '/error'
  },

  changePassword: {
    path: '/change-password'
  },

  registration: {
    path: '/registration'
  },

  activate: {
    path: '/activate'
  },

  bureau: {
    path: '/bureau'
  },

  admin: {
    path: '/admin'
  },

  signIn: {
    path: '/sign-in'
  },

  legacy: {
    path: '/legacy'
  },

  verificationBureau: {
    path: '/verification-bureau'
  },

  verificationPhone: {
    path: '/verification-phone'
  },

  askSecretQuestions: {
    path: '/ask-secret-question'
  },

  passwordRecoveryError: {
    path: '/messages/password-recovery-temporarily-blocked'
  },

  temporarilyAccountBlockError: {
    path: '/messages/account-temporarily-blocked'
  },

  passwordRecovery: {
    path: '/password-recovery'
  },

  passwordUpdate: {
    path: '/password-update'
  },

  authPrivacy: {
    path: '/auth/privacy'
  },

  authPrivacyAccept: {
    path: '/auth/privacy/accept'
  },

  authPrivacyDeny: {
    path: '/auth/privacy/deny'
  },

  registerPrivacy: {
    path: '/register/privacy'
  },

  registerPrivacyAccept: {
    path: '/register/privacy/accept'
  },

  registerPrivacyDeny: {
    path: '/register/privacy/deny'
  },

  createAuthSecretQuestions: {
    path: '/auth/create-secret-questions'
  },

  confirmationSendingTempCode: {
    path: '/auth/conf-tempcode-sent'
  },

  signInWithPin: {
    path: '/sign-in/pin'
  },

  createPin: {
    path: '/pin-code/create-pin'
  },

  editPin: {
    path: '/pin-code/edit-pin'
  },

  groupEdit: {
    path: '/group-edit'
  },

  printPDF: {
    path: '/print-pdf'
  }
};
