import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputWrapper } from '../../../../../../../components/Inputs';
import ControlledProvincePicker from '../../../../../../../components/Inputs/Province/ControlledProvincePicker';
import { inputs } from '../../../helpers/inputs';

const Province = () => {
  const name = inputs.province.name;
  const { errors } = useFormContext();

  return (
    <InputWrapper name={name} label={inputs.province.label} rrorMessage={errors[name]?.message}>
      <ControlledProvincePicker id={name} />
    </InputWrapper>
  );
};

export default Province;
