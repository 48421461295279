import { isPhysician, isRadiology, isSupplementary } from '../../../../config/specialitiesConfig';

export const hideEmergency = ({ speciality, invoiceType, isNew, isGroup }) => {
  return isGroup || !isNew || speciality === 80 || speciality === 39 || isSupplementary(speciality, invoiceType);
};

export const hideTimeInputs = (speciality, invoiceType) => {
  if (process.env.REACT_APP_CYPRESS) return false;
  return isSupplementary(speciality, invoiceType);
};

export const showRuralCodeInput = (speciality) => {
  // if (process.env.REACT_APP_CYPRESS) return false;
  return isPhysician(speciality); // [KS] CMO-1570 - Hide Rural code for all specialties except physicians (doctors)
};

export const showFacility = (speciality) => {
  return isRadiology(speciality) || isPhysician(speciality);
};

export const showSubFacility = (speciality) => {
  return isRadiology(speciality);
};

export const showReferralInput = (refToBy) => {
  return refToBy !== 'N';
};

export const showMultipleServiceDate = (speciality, invoiceType) => {
  return isSupplementary(speciality, invoiceType);
};
