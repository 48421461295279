import React from 'react';
import { useCatalogContentSizeDesktop } from '../../hooks/useCatalogContentSizeDesktop';
import { useCatalogsContext } from '../../hooks/useCatalogsContext';
import ChipsWithHorizontalScroll from '../Chips/ChipsWithHorizontalScroll';
import CatalogsSearch from './CatalogsSearch';
import Chips from '../Chips/Chips';
import ReferralAlphabet from '../../../Misc/Alphabet/ReferralAlphabet';
import { catalogsSearchWidth } from '../../config/styles';

const SearchPanelWithAlphabet = () => {
  const { catalogsState, setCatalogsState, getSelectedCodes, onLetterClick, slideMenuRef, breadcrumbRef, hideTabs } = useCatalogsContext();
  const currentCategory = catalogsState.currentCategory;
  const sizes = useCatalogContentSizeDesktop({ slideMenuRef, breadcrumbRef, currentCategory, hideTabs });
  const codes = getSelectedCodes(catalogsState?.activeTab);

  const handleLetterClick = (letter) => {
    catalogsState?.searchValue && setCatalogsState((prevState) => ({ ...prevState, searchValue: '' }));
    onLetterClick && onLetterClick({ letter, setCatalogsState });
  };

  return (
    <div className="flex justify-content-between align-items-center" style={{ height: `${sizes.chipWrapHeight}px`, columnGap: '12px' }}>
      <div style={{ minWidth: '60px', maxWidth: '60px' }}>{codes?.length ? <Chips /> : null}</div>

      <ReferralAlphabet onClick={handleLetterClick} />

      {/* Search */}
      <div className="p-inputgroup" style={{ maxWidth: `${catalogsSearchWidth}px`, minWidth: '200px' }}>
        <CatalogsSearch />
      </div>
    </div>
  );
};

const SearchPanel = () => {
  const { catalogsState, slideMenuRef, breadcrumbRef, hideTabs } = useCatalogsContext();
  const activeTab = catalogsState.activeTab;
  const currentCategory = catalogsState.currentCategory;
  const sizes = useCatalogContentSizeDesktop({ slideMenuRef, breadcrumbRef, currentCategory, hideTabs });

  // Layout for referral catalogs
  if (activeTab.showAlphabet) {
    return <SearchPanelWithAlphabet />;
  }

  return (
    <div className="desktopCatalogsSearchWrap" style={{ height: `${sizes.chipWrapHeight}px` }}>
      <ChipsWithHorizontalScroll />
      <CatalogsSearch />
    </div>
  );
};

export default SearchPanel;
