import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { Button } from 'primereact/button';
import { v4 as uuidv4 } from 'uuid';

import { bureauDefaultValues } from '../helpers/bureauDefaultValues';
import FirstName from '../inputs/FirstName';
import LastName from '../inputs/LastName';
import Email from '../inputs/Email';
import EmailRepeat from '../inputs/EmailRepeat';
import PhoneNumber from '../inputs/PhoneNumber';
import RoleInBureay from '../inputs/RoleInBureau';
import BureauType from '../inputs/BureauType';
import BureauName from '../inputs/BureauName';
import StreetAddress from '../inputs/StreetAddress';
import City from '../inputs/City';
import Province from '../inputs/Province';
import PostalCode from '../inputs/PostalCode';
import Website from '../inputs/Website';
import TeleplanDataCenterNumber from '../inputs/TeleplanDataCenterNumber';
import HearAboutUs from '../inputs/HearAboutUs';
import RegistrationPassword from '../inputs/RegistrationPassword';
import ConfirmPassword from '../inputs/ConfirmPassword';
import RegistrationFormWrapper from '../RegistrationCard';

import { InputGroup } from '../../../../components/Inputs';
import { routes } from '../../../../routes/routes';
import { updateRegistrationFormState } from '../../actions/registration.actions.creators';

const BureauForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const registrationState = useSelector((state) => state.registration.registrationFormState);

  const methods = useForm({ mode: 'all', defaultValues: { ...bureauDefaultValues, ...registrationState.initialFormState } });
  const { handleSubmit, reset, watch, getValues, formState, control } = methods;

  const bureauType = watch('bureauType');

  const onSubmit = (data) => {
    dispatch(
      updateRegistrationFormState({
        ...registrationState,
        type: 'register-practitioner-attached',
        initialFormState: { ...data, sessionGuid: uuidv4() },
        firstStepType: 'register-bureau'
      })
    );
  };

  const onClear = () => {
    reset(bureauDefaultValues);
  };

  const onCancel = () => {
    history.push(routes.signIn.path);
  };

  const onBack = () => {
    dispatch(
      updateRegistrationFormState({
        ...registrationState,
        type: 'register-type',
        initialFormState: { ...getValues(), password: '', confirmPassword: '' }
      })
    );
  };

  return (
    <FormProvider {...methods}>
      <RegistrationFormWrapper
        customersType="Open Account for a Billing Bureau"
        description="Select this option if you are the Account Administrator registering on behalf of a Billing Bureau. An Account Administrator is a
              designated individual responsible for managing and overseeing the setup, access rights, and maintenance of an account within an
              organization. If you are not authorized, please abort this action."
      >
        <form onSubmit={handleSubmit(onSubmit)} className="grid formgrid">
          <InputGroup>
            <FirstName label="Your First Name (Account Administrator)" />
            <LastName label="Your Last Name (Account Administrator)" />
          </InputGroup>
          <InputGroup>
            <Email control={control} requiredStatus={true} />
            <EmailRepeat />
          </InputGroup>
          <InputGroup>
            <PhoneNumber control={control} requiredStatus={true} />
            <RoleInBureay />
          </InputGroup>
          <InputGroup>
            <BureauType />
            <TeleplanDataCenterNumber label="Bureau Teleplan Data Center Number" />
          </InputGroup>
          <InputGroup>
            {bureauType !== 'Sole Proprietorship' && <BureauName />}
            <StreetAddress label="Billing Bureau Street Address" requiredStatus={true} />
          </InputGroup>
          <InputGroup>
            <City requiredStatus={true} />
            <Province requiredStatus={true} />
          </InputGroup>
          <InputGroup>
            <PostalCode requiredStatus={true} />
            <Website label="Bureau Website" name="website" />
          </InputGroup>
          <HearAboutUs />

          <InputGroup>
            <RegistrationPassword />
            <ConfirmPassword />
          </InputGroup>
          <div className="flex gap-3 aling-items-center justify-content-center mt-6 mb-4 w-full">
            <Button data-testid="nextButtonBureauForm" type="submit" label="Next" className="p-button-primary" loading={formState.isSubmitting} />
            <Button data-testid="clearButtonBureauForm" onClick={onClear} type="button" label="Clear" outlined />
            <Button data-testid="cancelButtonBureauForm" onClick={onCancel} type="button" label="Cancel" outlined />
            <Button data-testid="backButtonBureauForm" onClick={onBack} type="button" label="Back" outlined />
          </div>
        </form>
      </RegistrationFormWrapper>
    </FormProvider>
  );
};

export default BureauForm;
