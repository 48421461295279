import React, { useEffect, useState } from 'react';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';
import { authSendSecurityQuestions, getFullListSecretQuestions } from '../../../../service/Lookup';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../AuthProvider';
import SecretQuestionsBody from '../../../../components/SecretQuestions/SecretQuestionsBody';
import { useAuthVerifications } from '../../hooks/useAuthVerifications';

const AuthCreateSecretQuestions = () => {
  const user = useSelector((state) => state.user.details);
  const { phoneNotVerified, termsNotAccepted } = useAuthVerifications();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      question1: '',
      question2: '',
      question3: '',
      answer1: '',
      answer2: '',
      answer3: '',
      secretQuestions: [],
      loading: loading
    }
  });
  const { handleSubmit, setValue, getValues } = methods;

  useEffect(() => {
    fetchSecretQuestionsLists();
  }, []);

  const fetchSecretQuestionsLists = async () => {
    setLoading(true);
    const results = await getFullListSecretQuestions();
    setValue('secretQuestions', results);
    setLoading(false);
  };

  const onSubmit = () => {
    const selectedQuestionsList = getValues(['question1', 'question2', 'question3']);
    const selectedAnswersList = getValues(['answer1', 'answer2', 'answer3']);
    const secretQuestions = getValues('secretQuestions');
    const selectedQuestionsWithKeys = () => secretQuestions.filter((question) => selectedQuestionsList.includes(question.label));

    // This array contains objects with value=keys of question and label=answers of User.
    const selectedAnswersWithKeys = () => {
      let fullAnswers = [];
      selectedAnswersList.map((answer, index) => {
        fullAnswers[index] = { value: selectedQuestionsWithKeys()[index].value, label: answer };
      });
      return fullAnswers;
    };

    const payload = {
      DCNGuid: user?.DCNGuid,
      DoctorGuid: user?.DoctorGuid,
      SecurityQuestions: selectedAnswersWithKeys()
    };
    authSendSecurityQuestions(payload);

    if (!user.PhoneVerified) {
      return phoneNotVerified(user);
    }

    if (!user.TermsAccepted) {
      return termsNotAccepted();
    }

    login(user);
  };

  return (
    <FormProvider {...methods}>
      <AuthFormWrap style={{ maxWidth: '550px' }}>
        <div className="m-0 my-4 text-800 w-full text-center align-items-center font-bold text-3xl">Secure Your Account</div>
        <div className="flex flex-column gap-6">
          <span className="line-height-3">
            Please select three security questions from the list and provide answers to enhance your account's security. These will be used for
            identity verification if you need to recover your access.
          </span>

          <form onSubmit={handleSubmit(onSubmit)}>
            <SecretQuestionsBody />
          </form>
        </div>
      </AuthFormWrap>
    </FormProvider>
  );
};

export default AuthCreateSecretQuestions;
