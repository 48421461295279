import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import PhoneNumber from '../inputs/PhoneNumber';
import { updateRegistrationFormState } from '../../actions/registration.actions.creators';
import { saveContactData } from '../../../../service/Lookup';

const ChangePhone = ({ setVisibleChangePhone, setLabelContent }) => {
  const dispatch = useDispatch();
  const registrationState = useSelector((state) => state.registration.registrationFormState);

  const autoFocusStatus = true;
  const requiredStatus = true;
  const { control, handleSubmit, formState, reset } = useForm({
    mode: 'all'
  });

  const onSubmit = async (data) => {
    reset({ code: '' });
    const phoneStatus = registrationState.type === 'register-step-3';

    dispatch(
      updateRegistrationFormState({
        ...registrationState,
        initialFormState: {
          ...registrationState.initialFormState,
          phoneNumber: data.phoneNumber
        }
      })
    );

    const formatedPhoneNumber = data.phoneNumber?.replace(/[^0-9]/g, '');

    const body = {
      SessionGuid: registrationState.initialFormState.sessionGuid,
      Email: '',
      Phone: formatedPhoneNumber,
      Code: '',
      SendText: phoneStatus ? 1 : 0,
      SendEmail: phoneStatus ? 0 : 1,
      WhenCreated: new Date().toISOString()
    };

    await saveContactData(body);
    setVisibleChangePhone(false);
    setLabelContent('Verify Your Phone');
  };

  return (
    <>
      <div className="text-lg line-height-3">Update your phone number and send a verification code to the phone</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PhoneNumber control={control} autoFocusStatus={autoFocusStatus} requiredStatus={requiredStatus} />
        <div className="flex gap-3 mt-2 mb-6">
          <Button label="Send a code" className="w-6 h-3rem" loading={formState.isSubmitting} />
          <Button
            label="Cancel"
            className="p-button-outlined w-6 h-3rem"
            onClick={() => {
              setVisibleChangePhone(false);
              setLabelContent('Verify Your Phone');
            }}
          />
        </div>
      </form>
    </>
  );
};

export default ChangePhone;
