import React, { useState } from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { bureauRoles } from '../helpers/defaultValues';
import { roleInBureauValidation } from '../helpers/validation';

const RoleInBureay = () => {
  const name = 'bureauRole';
  const ref = React.createRef();
  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
    trigger
  } = useFormContext();
  const errorMessage = errors[name]?.message;

  const formatedRoleInBureau = () => bureauRoles.map((item) => item);
  const defaultRoleInBureau = formatedRoleInBureau();
  const [suggestions, setSuggestions] = useState([]);

  const onSearch = (event) => {
    if (event.query.trim() === '') {
      setSuggestions(formatedRoleInBureau);
    } else {
      const filtered = defaultRoleInBureau.filter((item) => {
        return item.toLowerCase().includes(event.query.toLowerCase());
      });
      if (filtered.length) {
        errorMessage && clearErrors(name);
      } else {
        setError(name, { type: 'custom', message: 'No results found' });
      }
      setSuggestions(filtered);
    }
  };

  return (
    <>
      <InputWrapper name={name} label="Your Role in the Bureau" required errorMessage={errorMessage} style={{ width: '100%', height: 'auto' }}>
        <Controller
          name={name}
          control={control}
          rules={{ validate: roleInBureauValidation }}
          render={({ field }) => (
            <AutoComplete
              {...field}
              ref={ref}
              data-testid="roleInBureau"
              inputRef={field.ref}
              suggestions={suggestions}
              dropdown
              forceSelection
              completeMethod={onSearch}
              className={classNames({ 'p-invalid': errors[name] })}
              onFocus={(e) => {
                ref.current?.search(e, '', 'dropdown');
              }}
              onClear={() => {
                if (field.value?.length) {
                  clearErrors(name);
                } else {
                  setError(name, { type: 'custom', message: 'Role in bureau is required' });
                }
              }}
              onBlur={() => {
                trigger(name);
              }}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default RoleInBureay;
