import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Memos from './Memos';
import CreateTeleplan from './CreateTeleplan';
import CreatePrivateInvoice from './CreatePrivateInvoice';
import CreateWSBCReport from './CreateWSBCReport';
import CreatePatient from './CreatePatient';
import CheckMSPCoverage from './CheckMSPCoverage';
import DaysheetUploadButton from '../../../../components/Widgets/DaysheetUploadButton';
import { ButtonWithBadge } from '../../../../components/Buttons';
import { Button } from 'primereact/button';
import { routes } from '../../../../routes/routes';

const BillingCard = () => {
  const history = useHistory();

  const handleLabelClick = () => {
    history.push({
      pathname: `${routes.createPatient.path}/new`,
      state: { activateAutofill: true }
    });
  };

  return (
    <div className="card m-0">
      <div className="block md:flex justify-content-between align-items-center gap-3 pb-3">
        <div className="hidden md:block text-xl font-bold">Do some billing</div>
        <div className="flex lg:hidden justify-content-end gap-3">
          <DaysheetUploadButton outlined buttonBadgeClassName="w-full sm:w-max" label="Daysheet" />
          <ButtonWithBadge value="Faster" rootClassName="w-full sm:w-max">
            <Button label="Label" icon="pi pi-camera" className="w-full sm:w-max" onClick={handleLabelClick} />
          </ButtonWithBadge>
        </div>
      </div>

      <div className="grid">
        <div className="col-12 sm:col-6">
          <div className="flex flex-column w-full gap-3">
            <div className="block md:hidden">
              <CreatePatient />
            </div>
            <div className="hidden md:flex flex-column w-full gap-3">
              <CreateTeleplan />
              <CreatePrivateInvoice />
            </div>
          </div>
        </div>

        <div className="col-12 sm:col-6">
          <div className="flex flex-column w-full gap-3">
            <div className="hidden md:block">
              <CreatePatient />
            </div>
            <div className="flex md:hidden flex-column w-full gap-3">
              <CreateTeleplan />
              <CreatePrivateInvoice />
            </div>
            <CreateWSBCReport />
            <CheckMSPCoverage />
            <div className="hidden lg:block">
              <Memos />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingCard;
