import { defaultProvinceCode } from '../../../config/defaultValuesConfig';

export const bureauDefaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  repeatEmail: '',
  phoneNumber: '',
  bureauRole: '',
  bureauType: '',
  bureauName: '',
  streetAddress: '',
  city: '',
  Province: defaultProvinceCode,
  Zip: '',
  bureauWebsite: '',
  teleplanDataCenterNumber: '',
  hearAboutUs: '',
  password: '',
  confirmPassword: '',
  attachedPractitioners: [],
  userType: 'Bureau',
  emailVerified: false,
  phoneVerified: false,
  termsAccepted: false,
  website: ''
};
