import React from 'react';
import AuthTitle from '../../../components/Layouts/Auth/AuthTitle';

const RegistrationCard = ({ children, customersType, description }) => {
  return (
    <div className="login-body">
      <div className="w-full flex flex-column justify-content-center align-items-center">
        <div className="card px-6 pt-6 mt-3" style={{ maxWidth: '800px', borderRadius: '0px ' }}>
          <AuthTitle />
          <div className="flex w-9 pb-4 justify-content-center mx-auto mt-4">
            <img src="/assets/layout/images/logo-cm-v5.png" alt="logo" style={{ height: '150px' }} />
          </div>
          <h2 className="text-center text-800 mb-6">{customersType}</h2>
          <p className="mb-4 text-center text-800 mb-6">{description}</p>
          <div className="grid flex flex-column justify-content-center align-items-center flex-wrap grid-nogutter p-fluid">
            <div className="col-12 px-3">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationCard;
