import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { Button } from 'primereact/button';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';
import PinCode from '../../../../components/Widgets/PinCode';

import { signInWithPinCode } from '../../actions/auth.actions.creators';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { localStorageKeys } from '../../../config/localStorageKeysConfig';
import { hashPinCode } from '../../../utils/pinCode';
import { t } from '../../../../service/localization/i18n';
import { routes } from '../../../../routes/routes';
import { useAuth } from '../../../../AuthProvider';

const SignInWithPin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { login } = useAuth();

  const onCancel = () => {
    history.push(routes.signIn.path);
  };

  const onSubmit = async (data) => {
    // Retrieve the stored hashed pin code from localStorage
    const storedPinData = JSON.parse(localStorage.getItem(localStorageKeys.pinCode));

    if (!storedPinData || !storedPinData.DeviceGuid) {
      return dispatch(
        setToastMessage({
          message: 'No PIN-code is set for this device. Please set up a PIN-code in your settings before proceeding.',
          type: 'error',
          lifeTime: 10000
        })
      );
    }

    // Hash the entered pin code for comparison
    const enteredPinHash = await hashPinCode(data.pin);

    const user = await signInWithPinCode({
      DeviceGuid: storedPinData.DeviceGuid,
      Fingerprint: enteredPinHash,
      Signature: storedPinData.Signature,
      DCNGuid: storedPinData.DCNGuid
    });

    if (user) {
      login(user);
    } else {
      dispatch(
        setToastMessage({
          message: 'Invalid PIN-code entered. Please check your PIN and try again.',
          type: 'error',
          lifeTime: 10000
        })
      );
    }
  };

  return (
    <AuthFormWrap label="Welcome to Claim Manager" hideLogo>
      <div className="flex flex-column gap-5">
        <Button label="Login with password" link onClick={onCancel} />
        <PinCode onCancel={onCancel} onSubmit={onSubmit} submitButtonLabel={t('Login')} />
      </div>
    </AuthFormWrap>
  );
};

export default SignInWithPin;
