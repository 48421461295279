import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import cx from 'classnames';

import { InputWrapper } from '../../../../components/Inputs';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';
import { t } from '../../../../service/localization/i18n';

import { setFocusToElement } from '../../../utils/setFocusToElement';
import { routes } from '../../../../routes/routes';
import { updateRegistrationFormState } from '../../actions/registration.actions.creators';
import { saveContactData, saveRegistrationData, sendVerificationCode } from '../../../../service/Lookup';
import { useTimer } from '../../../hooks/useTimer';
import ChangePhone from './ChangePhone';

const PhoneVerify = () => {
  const history = useHistory();
  const registrationState = useSelector((state) => state.registration.registrationFormState);
  const dispatch = useDispatch();

  const inputLength = 6;
  const TIMER_INITIAL_VALUE = 180000; // (180000 millisecunds - 3 minutes)

  const [visibleChangePhone, setVisibleChangePhone] = useState(false);
  const [labelContent, setLabelContent] = useState('Verify Your Phone');

  const { formattedTime, startTimer, active } = useTimer({ duration: TIMER_INITIAL_VALUE });

  const formatedPhoneNumber = registrationState.initialFormState.phoneNumber?.replace(/[^0-9]/g, '');
  const numberPhoneBlur = () => {
    return formatedPhoneNumber.replace(/(\d{2})\d{6}(\d{2})/, '$1******$2');
  };

  const phoneNumberSentence = `the phone number ${numberPhoneBlur()}`;

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    formState,
    reset
  } = useForm({
    defaultValues: {
      code: ''
    }
  });

  const onSubmit = async (data) => {
    const formatedPhoneNumber = registrationState.initialFormState.phoneNumber?.replace(/[^0-9]/g, '');
    const timeCreated = new Date().toISOString();
    const payload = {
      SessionGuid: registrationState.initialFormState.sessionGuid,
      Email: '',
      Phone: formatedPhoneNumber,
      Code: data.code,
      SendText: 1,
      SendEmail: 0,
      WhenCreated: timeCreated
    };

    try {
      await sendVerificationCode(payload);

      const dataToSend = {
        ...registrationState.initialFormState,
        phoneVerified: true,
        ReferenceCode: registrationState.ReferenceCode
      };
      await saveRegistrationData(dataToSend);

      dispatch(
        updateRegistrationFormState({
          ...registrationState,
          type: 'privacy',
          initialFormState: { ...registrationState.initialFormState, phoneVerified: true }
        })
      );

      history.push(routes.registerPrivacy.path); // redirect to Privacy ("Terms of use") page
    } catch (error) {
      setError('code', { type: 'custom', message: 'You entered an incorrect verification code. Try again.' });
    }
  };

  const resendCode = async () => {
    reset({ code: '' });
    const formatedPhoneNumber = registrationState.initialFormState.phoneNumber?.replace(/[^0-9]/g, '');
    const timeCreated = new Date().toISOString();
    const payload = {
      SessionGuid: registrationState.initialFormState.sessionGuid,
      Email: '',
      Phone: formatedPhoneNumber,
      Code: '',
      SendText: 1,
      SendEmail: 0,
      WhenCreated: timeCreated
    };

    await saveContactData(payload);

    startTimer();
    setFocusToElement('code');
  };

  const changePhone = () => {
    reset({ code: '' });
    setVisibleChangePhone(true);
    setLabelContent('Update Your Phone');
  };

  const codeVerificationLayout = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="code"
        control={control}
        rules={{
          required: t('Mandatory_field.1'),
          minLength: {
            value: inputLength,
            message: 'Minimum length is 6 characters'
          }
        }}
        render={({ field, fieldState }) => (
          <InputWrapper name="code" label="Code" required errorMessage={errors?.code?.message}>
            <InputText
              {...field}
              id={field.name}
              data-testid="codeVerifyPhone"
              autoFocus
              keyfilter="int"
              maxLength={inputLength}
              className={cx({ 'p-invalid': fieldState.error })}
            />
          </InputWrapper>
        )}
      />
      <div className="flex gap-3 mt-2 h-3rem">
        <Button
          id="submit"
          data-testid="submitButtonPhoneVerify"
          className="w-full"
          label={t('Submit')}
          type="submit"
          loading={formState.isSubmitting}
        />
      </div>

      <div className="my-6">
        {!active ? (
          <p className="w-12 mb-3 text-800 text-base ">
            Haven't received the text?
            <Button
              link
              label="Resend the code"
              type="button"
              onClick={resendCode}
              style={{ width: '130px', paddingBottom: '2px', paddingLeft: '0px', color: '#607D8B', fontSize: '1rem' }}
            />
          </p>
        ) : (
          <p className="w-12 pt-2 mb-3 text-800 text-base">
            Haven't receive the text? Resend the code in <span style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>{formattedTime}</span>.
          </p>
        )}
        {!active ? (
          <p className="text-800">
            <Button
              link
              label="Change phone number"
              type="button"
              onClick={changePhone}
              style={{ textAlign: 'left', width: '180px', paddingBottom: '0px', paddingLeft: '0px', color: '#607D8B', fontSize: '1rem' }}
            />
          </p>
        ) : (
          <p className="text-base h-full mt-4" style={{ color: 'var(--primary-color)' }}>
            Change phone number
          </p>
        )}
      </div>
    </form>
  );

  return (
    <>
      <AuthFormWrap>
        <div className="m-0 my-4 text-800 w-full text-center font-bold text-3xl">{labelContent}</div>

        {!visibleChangePhone && (
          <div className="line-height-3 text-center text-800 text-lg">
            Enter the verification code that we sent to <br /> {phoneNumberSentence}.
          </div>
        )}

        {visibleChangePhone ? (
          <ChangePhone setVisibleChangePhone={setVisibleChangePhone} setLabelContent={setLabelContent} />
        ) : (
          codeVerificationLayout
        )}
      </AuthFormWrap>
    </>
  );
};

export default PhoneVerify;
