import React from 'react';
import { InputMask } from 'primereact/inputmask';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

// interface IDateOfBirth {
//   value: string
//   onChange: (e) => void
//   onBlur?: (e) => void
//   isInvalid?: boolean
// }

const DateOfBirth = (props) => {
  return (
    <InputMask
      className={cx('w-full', { 'p-invalid': props.isInvalid })}
      inputMode="numeric"
      id="BirthDay"
      name="BirthDay"
      placeholder={t('MM_DD_YYYY')}
      slotChar={t('MM_DD_YYYY')}
      mask="99/99/99?99"
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur && props.onBlur}
    />
  );
};

export default DateOfBirth;
