import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { capitalizeFirstLetter, latinLetters, streetValidation } from '../helpers/validation';

const StreetAddress = ({ label, requiredStatus }) => {
  const {
    control,
    formState: { errors },
    clearErrors
  } = useFormContext();

  return (
    <>
      <InputWrapper name="streetAddress" label={label} required={requiredStatus} errorMessage={errors.streetAddress?.message}>
        <Controller
          name="streetAddress"
          control={control}
          rules={{ validate: requiredStatus ? streetValidation : latinLetters }}
          render={({ field }) => (
            <InputText
              id="streetAddress"
              data-testid="streetAddress-testid"
              {...field}
              onChange={(e) => {
                const value = e.currentTarget.value;
                field.onChange(capitalizeFirstLetter(value));
                value && errors.streetAddress?.message && clearErrors('streetAddress');
              }}
              className={classNames({ 'p-invalid': errors.streetAddress })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default StreetAddress;
