import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { useFormContext } from 'react-hook-form';
import ControlledProvincePicker from '../../../../components/Inputs/Province/ControlledProvincePicker';

const Province = ({ requiredStatus }) => {
  const {
    formState: { errors }
  } = useFormContext();

  return (
    <InputWrapper name="Province" label="Province" required={requiredStatus} errorMessage={errors.Province?.message}>
      <ControlledProvincePicker dataTestid="province-testid" required={requiredStatus} />
    </InputWrapper>
  );
};

export default Province;
