import React from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { userNameValidation } from './helpers/validation';
import { classNames } from 'primereact/utils';
import UserNameContent from './UserNameContent';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AuthFormWrap from '../../../components/Wraps/PageWrap/AuthFormWrap';
import { InputWrapper } from '../../../components/Inputs';
import { t } from '../../../service/localization/i18n';
import { createUsername } from '../../../service/Lookup';
import { routes } from '../../../routes/routes';

const ActivateAccount = () => {
  const history = useHistory();
  const { sessionGuid } = useParams();
  const { control, formState, handleSubmit, setError, setValue, getValues } = useForm({
    defaultValues: {
      username1: '',
      username2: ''
    }
  });

  const onChange = (e, field) => {
    const selectedValue = e.target.value;
    //field.onChange(selectedValue);
    setValue(field.name, selectedValue, { shouldValidate: true }); // Triggers validation
  };

  const onSubmit = async () => {
    const payload = {
      SessionGuid: sessionGuid,
      UserName: getValues('username2')
    };

    try {
      await createUsername(payload);
      history.replace(`${routes.activate.path}/${sessionGuid}/success`);
    } catch (error) {
      if (error.status === 409) {
        setError('username2', {
          type: 'manual',
          message: 'A user with this Username already exists. Please select another Username.'
        });
        return;
      }
      if (error.status === 404) {
        history.replace(`${routes.activate.path}/${sessionGuid}/expiration`);
      } else {
        setError('username2', {
          type: 'manual',
          message: 'Failed to create username. Please try again.'
        });
      }
    }
  };

  return (
    <AuthFormWrap>
      <UserNameContent userName={getValues('username1')} isDirty={formState.isDirty} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-column gap-4">
          <div className="flex flex-column ">
            <InputWrapper name={'username1'} label="Username" errorMessage={formState.errors.username1?.message}>
              <Controller
                name={'username1'}
                control={control}
                rules={{ validate: userNameValidation }}
                render={({ field }) => (
                  <InputText
                    type="text"
                    id={'username1'}
                    placeholder="Username"
                    autoFocus
                    className={classNames({ 'p-invalid': formState.errors.username1 })}
                    {...field}
                    onInput={(e) => onChange(e, field)}
                  />
                )}
              />
            </InputWrapper>

            <InputWrapper name={'username2'} label="Confirm Username" errorMessage={formState.errors.username2?.message}>
              <Controller
                name={'username2'}
                control={control}
                rules={{ validate: (value) => value === getValues('username1') || 'Usernames must match.' }}
                render={({ field }) => (
                  <InputText
                    type="text"
                    id={'username2'}
                    placeholder="Username"
                    className={classNames({ 'p-invalid': formState.errors.username2 })}
                    {...field}
                    onInput={(e) => onChange(e, field)}
                  />
                )}
              />
            </InputWrapper>
          </div>
          <div className="flex gap-3 mb-6 h-3rem">
            <Button id="submit" className="w-full" label={t('Next')} type="submit" loading={formState.isSubmitting} />
          </div>
        </div>
      </form>
    </AuthFormWrap>
  );
};

export default ActivateAccount;
