import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { patientFullName } from '../../../../../modules/utils/patientFullName';
import { t } from '../../../../../service/localization/i18n';
import moment from 'moment';
import AddressLayout from '../../../AddressLayout';

const ServiceDateSection = ({ data, isFetching }) => {
  const isRepresentative = data?.BillToGuid !== data?.PatientGuid;

  return (
    <div className="invoiceSectionWrap">
      <div className="flex align-items-center bill-to mb-2">
        <div className="serviceDate">{`${t('Service_date')}:`}</div>
        {isFetching ? (
          <Skeleton width="7rem" className="mt-1" />
        ) : (
          <div className="w-full text-right">{moment(data?.ServiceDate).format('MM/DD/YYYY')}</div>
        )}
      </div>

      <div className="flex align-items-start mb-2">
        <div className="font-semibold serviceDate">{`${t('Patient.1')}:`}</div>
        {isFetching ? (
          <Skeleton width="7rem" className="mt-1" />
        ) : (
          <div className="w-full text-right line-height-3">
            <div className="font-bold">{patientFullName(data?.PatientDetails)}</div>
            {isRepresentative && (
              <>
                {data?.Facility && <div className="m-0">{data?.Facility}</div>}
                <AddressLayout data={data?.PatientDetails} showEmail={false} showPhone={false} />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceDateSection;
