import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { InputWrapper } from '../../Inputs';

import { setToastMessage } from '../../../modules/core/actions/core.action.creators';
import { changePassword } from '../../../modules/user/actions/user.action.creators';
import { strongPasswordRegex } from '../../../modules/regex/regex';
import { t } from '../../../service/localization/i18n';
import cx from 'classnames';

// interface ForgotPasswordProps {
//   onCancel: () => void;
//   onSuccessSubmit?: () => void;
// }

const ForgotPassword = ({ onCancel, onSuccessSubmit }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const {
    getValues,
    reset,
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting }
  } = useForm({ defaultValues: { tempPassword: '', newPassword: '', repeatNewPassword: '' } });

  const header = <div className="font-bold mb-3">Pick a password</div>;
  const footer = (
    <>
      <Divider />
      <p className="mt-2">Your password must meet complexity requirements:</p>
      <ul className="pl-2 ml-2 mt-0 line-height-3 list-disc">
        <li>Length should be at least 8 characters (50 characters max)</li>
        <li>Use of uppercase [A - Z] and lowercase [a - z] letters</li>
        <li>Use of numbers [0 - 9]</li>
        <li>Use one of the next special characters: [!,%,&,@,$,^,*,_,(,)]</li>
        <li>Do not use #,&,+,-,=,?,~,,/,",' and other not listed above</li>
      </ul>
    </>
  );

  // Function to validate the new password
  const validateNewPassword = (value) => {
    if (!value) return t('Mandatory_field.1'); // Return error message if value is empty
    const tempPassword = getValues('tempPassword'); // Get value of Old Password field
    if (tempPassword === value) return t('New_password_cannot_be_same_as_old_password'); // Return error message if new password is same as old password
    return strongPasswordRegex.test(value) || t('Invalid_password_format_message'); // Return true if password format is valid, otherwise return error message
  };

  // Function to validate the repeat password
  const validateRepeatNewPassword = (value) => {
    if (!value) return t('Mandatory_field.1'); // Return error message if value is empty
    const newPassword = getValues('newPassword'); // Get value of New Password field
    return value === newPassword || t('Passwords_mismatch_error_message'); // Return true if repeat password matches new password, otherwise return error message
  };

  const handleCancel = () => {
    reset();
    onCancel();
  };

  const onSubmit = async (data) => {
    const payload = {
      DCNGuid: user.DCNGuid,
      DoctorGuid: user.DoctorGuid,
      OldPassword: data.tempPassword,
      NewPassword: data.newPassword
    };
    const result = await changePassword(payload);
    if (result) {
      reset();
      dispatch(setToastMessage({ type: 'success', message: t('Password_updated_successfully') }));
      if (onSuccessSubmit) {
        await onSuccessSubmit();
      }
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="flex flex-column w-full gap-2">
      <Controller
        name="tempPassword"
        control={control}
        rules={{ required: t('Mandatory_field.1') }}
        render={({ field, fieldState }) => (
          <InputWrapper name="tempPassword" label="Temporary Password" required errorMessage={fieldState.error?.message}>
            <Password
              className={cx(fieldState.error?.message ? 'p-invalid' : '', 'w-full')}
              inputClassName="w-full"
              toggleMask
              autoFocus
              feedback={false}
              inputRef={field.ref}
              {...field}
            />
          </InputWrapper>
        )}
      />

      <Controller
        name="newPassword"
        control={control}
        rules={{ validate: validateNewPassword }}
        render={({ field, fieldState }) => (
          <InputWrapper name="newPassword" label="New Password" required errorMessage={fieldState.error?.message}>
            <Password
              className={cx(fieldState.error?.message ? 'p-invalid' : '', 'w-full')}
              inputClassName="w-full"
              toggleMask
              header={header}
              footer={footer}
              strongRegex={strongPasswordRegex}
              inputRef={field.ref}
              {...field}
            />
          </InputWrapper>
        )}
      />

      <Controller
        name="repeatNewPassword"
        control={control}
        rules={{ validate: validateRepeatNewPassword }}
        render={({ field, fieldState }) => (
          <InputWrapper name="repeatNewPassword" label="Repeat Password" required errorMessage={fieldState.error?.message}>
            <Password
              className={cx(fieldState.error?.message ? 'p-invalid' : '', 'w-full')}
              inputClassName="w-full"
              toggleMask
              feedback={false}
              inputRef={field.ref}
              {...field}
            />
          </InputWrapper>
        )}
      />

      <div className="flex gap-3">
        {isDirty ? (
          <>
            <Button type="button" className="p-button-outlined flex-grow-1" label={t('Cancel')} onClick={handleCancel} />
            <Button className="flex-grow-1" label={t('Save')} loading={isSubmitting} />
          </>
        ) : (
          <Button type="button" className="flex-grow-1 h-3rem" label={t('Submit')} onClick={onCancel} />
        )}
      </div>
    </form>
  );
};

export default ForgotPassword;
