import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ComingSoon = () => {
  const history = useHistory();

  return (
    <div className="flex justify-content-start align-items-center flex-column pt-6" style={{ width: '100%', height: '90vh' }}>
      <h2>Coming soon...</h2>
      <Button className="mt-4" label="Go back" onClick={() => history.goBack()} />
    </div>
  );
};

export default ComingSoon;
