import { useDispatch, useSelector } from 'react-redux';
import { getLastXDaysRange } from '../../../utils/getDate';
import { setCashFlowQuery } from '../../../cashFlow/actions/cashFlow.axtion.creators';
import moment from 'moment';

export const useCashFlowCards = () => {
  const dispatch = useDispatch();
  const query = useSelector((state) => state.cashFlow.queryParams?.query);

  const onCardClick = (days) => {
    const dateFormat = 'YYYY-MM-DD';
    const { from, to } = getLastXDaysRange(days);
    dispatch(setCashFlowQuery({ ...query, PeriodFrom: moment(from).format(dateFormat), PeriodTo: moment(to).format(dateFormat) }));
  };

  return { onCardClick };
};
