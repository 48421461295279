import React from 'react';
import QuickPickChipsList from '../../../../../../../components/Catalogs/Modules/QuickPick/QuickPickChipsList';
import { useService } from '../../../hooks/useService';
import { inputs } from '../../../helpers/inputs';
import { useSelector } from 'react-redux';
import { useConditions } from '../../../hooks/useConditions';
import { sortByAlphabetical } from '../../../../../../utils/sortBy';

const QuickPick = () => {
  const { catalogMostRecentCodes } = useSelector((state) => state.claims);
  const { recentCodesType, disableCatalogs } = useConditions();
  const { onSelectService, isSelected, getTooltipForService, toggleServiceCatalogs } = useService();
  const servicesList = catalogMostRecentCodes?.[recentCodesType]?.map((i) => {
    return { ...i, tooltip: getTooltipForService(i) };
  });
  const sortedServicesList = sortByAlphabetical(servicesList, 'label');

  // if (!servicesList?.length) return null;

  return (
    <div className="flex flex-column lg:flex-row align-items-center gap-3 w-full">
      <div className="eclaim_step_two_left_gap"></div>

      <QuickPickChipsList
        itemsList={sortedServicesList}
        name={inputs.service.name}
        labelKey="label"
        tooltipKey="tooltip"
        disabled={disableCatalogs}
        isSelected={isSelected}
        onClick={onSelectService}
        onOpenCatalog={toggleServiceCatalogs}
      />

      <div className="eclaim_step_two_right_gap"></div>
    </div>
  );
};

export default QuickPick;
