import React from 'react';
import RadioButton from '../../../../../../../../components/PrimeReactComponents/RadioButton';
import { elementIDs } from '../../../../../../../config/elementIDsConfig';
import { t } from '../../../../../../../../service/localization/i18n';

const RefToByRadio = ({ value, onChange, disabled }) => {
  return (
    <div className="flex">
      <div className="field-radiobutton mb-0">
        <RadioButton
          id={elementIDs.NARadio}
          type="radio"
          inputId="na"
          name="refToBy"
          value="N"
          checked={value === 'N'}
          disabled={disabled}
          onChange={(e) => onChange(e.value)}
        />
        <label htmlFor="na">{t('None')}</label>
      </div>

      <div className="field-radiobutton ml-4 mb-0">
        <RadioButton
          id={elementIDs.ByRadio}
          type="radio"
          inputId="by"
          name="refToBy"
          value="B"
          checked={value === 'B'}
          disabled={disabled}
          onChange={(e) => onChange(e.value)}
        />
        <label htmlFor="by">{t('By')}</label>
      </div>

      <div className="field-radiobutton ml-4 mb-0">
        <RadioButton
          id={elementIDs.ToRadio}
          type="radio"
          inputId="to"
          name="refToBy"
          value="T"
          checked={value === 'T'}
          disabled={disabled}
          onChange={(e) => onChange(e.value)}
        />
        <label htmlFor="to">{t('To')}</label>
      </div>
    </div>
  );
};

export default RefToByRadio;
