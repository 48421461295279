import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { teleplanNumberValidation } from '../helpers/validation';

const TeleplanDataCenterNumber = ({ label }) => {
  const {
    control,
    formState: { errors },
    trigger
  } = useFormContext();

  return (
    <>
      <InputWrapper name="teleplanDataCenterNumber" label={label} errorMessage={errors.teleplanDataCenterNumber?.message}>
        <Controller
          name="teleplanDataCenterNumber"
          control={control}
          rules={{ validate: teleplanNumberValidation }}
          render={({ field }) => (
            <InputText
              {...field}
              id="teleplanDataCenterNumber"
              data-testid="teleplanDataCenterNumber-testid"
              maxLength={5}
              onChange={(e) => {
                const value = e.target.value;
                const formatedValue = value.toUpperCase();
                field.onChange(formatedValue);
              }}
              onBlur={() => {
                field.onBlur();
                trigger('teleplanDataCenterNumber');
              }}
              className={classNames({ 'p-invalid': errors.teleplanDataCenterNumber })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default TeleplanDataCenterNumber;
