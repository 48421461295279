import React, { useEffect } from 'react';
import { redirect } from '../../actions/auth.actions.creators';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../../../AuthProvider';
import { useDispatch } from 'react-redux';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { getLegacyUrlParams } from '../../../utils/getLink';

const Legacy = () => {
  const location = useLocation();
  const { login } = useAuth();
  const dispatch = useDispatch();

  useEffect(async () => {
    const params = getLegacyUrlParams(location.search);
    const user = await redirect(params);

    if (user) {
      login(user);
    } else {
      dispatch(
        setToastMessage({
          message: 'Something went wrong.',
          type: 'error',
          lifeTime: 10000
        })
      );
    }
  }, []);

  return (
    <div className="login-body">
      <div className="login-topbar"></div>
      <div className="flex justify-content-center align-items-center" style={{ height: '80vh' }}>
        <h1>Please wait while we retrieve your data.</h1>
      </div>
    </div>
  );
};

export default Legacy;
