import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputWrapper } from '../../../../components/Inputs';
import ControlledProvincePicker from '../../../../components/Inputs/Province/ControlledProvincePicker';
import ControlledZip from '../../../../components/Inputs/Zip/ControlledZip';

import { createNewPractitioner } from '../../actions/practitioners.action.creators';
import { isEmailValid, practitionerNumberValidation } from '../../../regex/regex';
import { toTitleCase } from '../../../utils/toTitleCase';
import { inputs } from './inputs';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

const AddPractitioner = ({ onCancel, onCreateResponseAction }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const clinic = useSelector((state) => state.clinic);
  const currentUser = clinic.members?.find((i) => i.UserGuid === user.details.UserGuid);
  const { isFetchingPractitioner } = useSelector((state) => state.practitioners);

  const currentSpeciality = () => {
    const spec = Number(currentUser?.Speciality);
    if (spec >= 0) return spec;
    return Number(currentUser?.Specialties?.split('|')[0]);
  };

  const methods = useForm({
    defaultValues: {
      [inputs.firstName.name]: '',
      [inputs.lastName.name]: '',
      [inputs.practitionerNumber.name]: '',
      [inputs.phone.name]: '',
      [inputs.email.name]: '',
      [inputs.addressLine.name]: '',
      [inputs.city.name]: '',
      [inputs.province.name]: 'BC',
      [inputs.zip.name]: '',
      [inputs.userStatus.name]: 0,
      [inputs.speciality.name]: currentSpeciality()
    }
  });

  const {
    setValue,
    handleSubmit,
    formState: { isDirty, errors },
    control,
    register
  } = methods;

  const onSubmit = (data) => {
    dispatch(
      createNewPractitioner(data, (responseData) => {
        // Response action
        onCreateResponseAction && onCreateResponseAction(responseData);
      })
    );
  };

  const practitionerNumberInfo = (
    <>
      <Tooltip target=".practitionerNumberInfo" position="top">
        {t('practitioner_number_info_tooltip')}
      </Tooltip>
      <i className="pi pi-question-circle practitionerNumberInfo" />
    </>
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <InputWrapper required name={inputs.firstName.name} label={inputs.firstName.label} errorMessage={errors[inputs.firstName.name]?.message}>
          <InputText
            id={inputs.firstName.name}
            className={cx({ 'p-invalid': errors[inputs.firstName.name]?.message })}
            {...register(inputs.firstName.name, {
              required: t('Mandatory_field.1')
            })}
            onChange={(e) => {
              const { name, value } = e.target;
              setValue(name, toTitleCase(value), { shouldDirty: true });
            }}
          />
        </InputWrapper>

        <InputWrapper required name={inputs.lastName.name} label={inputs.lastName.label} errorMessage={errors[inputs.lastName.name]?.message}>
          <InputText
            id={inputs.lastName.name}
            className={cx({ 'p-invalid': errors[inputs.lastName.name]?.message })}
            {...register(inputs.lastName.name, {
              required: t('Mandatory_field.1')
            })}
            onChange={(e) => {
              const { name, value } = e.target;
              setValue(name, toTitleCase(value), { shouldDirty: true });
            }}
          />
        </InputWrapper>

        <InputWrapper
          required
          name={inputs.practitionerNumber.name}
          label={inputs.practitionerNumber.label}
          labelOptionalContent={practitionerNumberInfo}
          errorMessage={errors[inputs.practitionerNumber.name]?.message}
        >
          <InputText
            id={inputs.practitionerNumber.name}
            maxLength={5}
            className={cx({ 'p-invalid': errors[inputs.practitionerNumber.name]?.message })}
            {...register(inputs.practitionerNumber.name, {
              required: t('Mandatory_field.1'),
              pattern: {
                value: practitionerNumberValidation,
                message: 'Enter a 5-digit number (1 letter, 4 digits)'
              }
            })}
          />
        </InputWrapper>

        <Controller
          id={inputs.phone.name}
          name={inputs.phone.name}
          control={control}
          render={({ field }) => (
            <InputWrapper name={inputs.phone.name} label={inputs.phone.label}>
              <InputMask
                id={field.name}
                inputMode="numeric"
                mask="(999) 999-9999"
                placeholder="(999) 999-9999"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            </InputWrapper>
          )}
        />

        <InputWrapper name={inputs.email.name} label={inputs.email.label} errorMessage={errors[inputs.email.name]?.message}>
          <InputText
            id={inputs.email.name}
            className={cx({ 'p-invalid': errors[inputs.email.name]?.message })}
            {...register(inputs.email.name, {
              pattern: {
                value: isEmailValid,
                message: t('Invalid_entry')
              }
            })}
          />
        </InputWrapper>

        <InputWrapper name={inputs.addressLine.name} label={inputs.addressLine.label}>
          <InputText
            id={inputs.addressLine.name}
            {...register(inputs.addressLine.name)}
            onChange={(e) => {
              const { name, value } = e.target;
              setValue(name, toTitleCase(value), { shouldDirty: true });
            }}
          />
        </InputWrapper>

        <InputWrapper name={inputs.city.name} label={inputs.city.label}>
          <InputText
            id={inputs.city.name}
            {...register(inputs.city.name)}
            onChange={(e) => {
              const { name, value } = e.target;
              setValue(name, toTitleCase(value), { shouldDirty: true });
            }}
          />
        </InputWrapper>

        <InputWrapper name={inputs.province.name} label={inputs.province.label}>
          <ControlledProvincePicker />
        </InputWrapper>

        <InputWrapper name={inputs.zip.name} label={inputs.zip.label} errorMessage={errors[inputs.zip.name]?.message}>
          <ControlledZip />
        </InputWrapper>

        {/* ACTION BUTTONS */}
        <div className="stickyActionButtons">
          <div className="w-full my-4">
            {isDirty ? (
              <div className="flex gap-3">
                <Button type="submit" label={t('Save')} loading={isFetchingPractitioner} />
                <Button className="p-button-outlined" type="button" label={t('Cancel')} onClick={onCancel} />
              </div>
            ) : (
              <Button type="button" label={t('Close')} onClick={onCancel} />
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddPractitioner;
