import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { NA_DoctorGuid } from '../../../../config/defaultValuesConfig';
import { getCatalogCategories, getEClaimInfo, setCatalogCategories, setMissingCodesToRecentList } from '../../../actions/claims.action.creators';
import { localStateInitValues } from '../helpers/initialState';
import { getDefaultValues, initPractitionerId } from '../helpers/defaultValues';
import { getPromiseAll } from '../../../../utils/getPromiseAll';
import { useSubmit } from './useSubmit';
import { inputs } from '../helpers/inputs';
import {
  getEClaimLocations,
  getPatientInfo,
  getPatientInsurancePolicies,
  getPatientInsurancePolicy,
  getPrefs,
  getRecentCodes
} from '../../../../../service/Lookup';
import { isEmpty } from 'lodash';
import { routes } from '../../../../../routes/routes';
import moment from 'moment';
import { getPractitionerByGuid } from '../../../../utils/getPractitioner';

export const useDefaultValues = ({ values = {}, ...props }) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const isNew = location.pathname.includes(routes.eClaimNew.path);
  const isEdit = location.pathname.includes(routes.eClaimEdit.path);
  const isView = location.pathname.includes(routes.eClaimView.path);
  const patientGuid = props.patientGuid || params.patientGuid;
  const claimGuid = props.claimGuid || params.claimGuid;
  const initValues = getDefaultValues({ initValues: values });
  const [localState, setLocalState] = useState(localStateInitValues);

  const methods = useForm({ defaultValues: initValues });

  useEffect(() => {
    // Create new
    if (isNew) setEmptyForm();

    // Edit mode
    if (isEdit) setPrefilledFormForEdit();

    // View mode
    if (isView) setPrefilledFormForView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, isEdit, isView]);

  const setEmptyForm = async () => {
    const practitionerGuid = initPractitionerId();

    // Prepare requests for patient information, preferences and insurance policies
    const patientInfoRequest = patientGuid
      ? {
          patientInfo: getPatientInfo(patientGuid),
          patientInsurancePolicies: getPatientInsurancePolicies(patientGuid),
          recentCodes: getRecentCodes(patientGuid, 'BT')
        }
      : {};

    // Prepare requests for e-Claim locations
    const practitionerLocationsListRequest =
      practitionerGuid !== NA_DoctorGuid
        ? {
            pbc: getEClaimLocations(practitionerGuid, 'pbc'),
            tec: getEClaimLocations(practitionerGuid, 'tec')
          }
        : {};

    const requests = {
      ...patientInfoRequest,
      ...practitionerLocationsListRequest,
      prefs: getPrefs(user.DCNGuid, practitionerGuid)
    };

    setLocalState((prevState) => ({ ...prevState, isLoading: true }));

    // Execute all requests concurrently and merge results into a single object
    const results = await getPromiseAll(requests);

    if (practitionerGuid !== NA_DoctorGuid) {
      const practitioner = getPractitionerByGuid(practitionerGuid);
      // CMO-2980 - Change fee catalogs when the user changes the profile in Teleplan form
      const categoriesResult = await getCatalogCategories(practitioner?.Speciality);
      if (categoriesResult) setCatalogCategories(categoriesResult);
    }

    // Extract practitioner preferences, patient info, and recent codes
    const practitionerPrefs = results?.prefs?.prefs?.find((i) => i.label === 'default') || {};
    const patientInfo = patientGuid ? [results.patientInfo] : [];
    const policy = results.patientInsurancePolicies?.length === 1 ? results.patientInsurancePolicies[0] : {}; // If policies length === 1 then autoselect this policy
    const initValues = getDefaultValues({ practitionerPrefs });
    const initState = { ...initValues, [inputs.patient.name]: patientInfo };

    // Reset the form with initial values
    methods.reset(initState);

    // If policies length === 1 then autoselect this policy
    if (!isEmpty(policy)) {
      methods.setValue(inputs.primaryPolicy.name, policy, { shouldDirty: true });
    }

    dispatch(setMissingCodesToRecentList({ codes: results.recentCodes }));
    setLocalState((prevState) => ({
      ...prevState,
      isLoading: false,
      practitionerPrefs,
      policiesList: results.patientInsurancePolicies || [],
      locationsList: {
        pbc: results.pbc || [],
        tec: results.tec || []
      }
    }));
  };

  const setPrefilledFormForView = async () => {
    setLocalState((prevState) => ({ ...prevState, isLoading: true }));

    const eClaim = await getEClaimInfo(claimGuid);

    if (eClaim) {
      const practitionerGuid = eClaim?.[inputs.practitioner.name];
      const currentPatientGuid = eClaim?.[inputs.patientGuid.name];
      const primaryPolicyGuid = eClaim?.[inputs.primaryPolicy.name];
      const secondaryPolicyGuid = eClaim?.[inputs.secondaryPolicy.name];
      const locationGuid = eClaim?.[inputs.location.name];
      const insurerID = eClaim?.[inputs.primaryPolicy.name].InsurerID;
      const catalogType = Number(insurerID) === -1 ? 'pbc' : 'tec';
      const isPBC = catalogType === 'pbc';
      const secondaryPolicyRequest = secondaryPolicyGuid
        ? { secondaryPolicy: getPatientInsurancePolicy(currentPatientGuid, secondaryPolicyGuid) }
        : {};

      // Execute all requests concurrently and merge results into a single object
      const results = await getPromiseAll({
        ...secondaryPolicyRequest,
        primaryPolicy: getPatientInsurancePolicy(currentPatientGuid, primaryPolicyGuid),
        patientInfo: getPatientInfo(currentPatientGuid),
        locations: getEClaimLocations(practitionerGuid, catalogType)
      });

      setLocalState((prevState) => ({ ...prevState, isLoading: false }));

      const formatServicesDates = eClaim?.[inputs.serviceDate.name]?.map((i) => moment(i).toDate());
      const locationInfo = results.locations?.find((i) => i.RowGuid === locationGuid);
      const formattedEClaim = {
        ...eClaim,
        [inputs.patient.name]: [results.patientInfo],
        [inputs.serviceDate.name]: formatServicesDates,
        [inputs.primaryPolicy.name]: results.primaryPolicy,
        [inputs.secondaryPolicy.name]: results.secondaryPolicy,
        [inputs.location.name]: locationInfo
      };

      // Reset the form with initial values
      methods.reset(formattedEClaim);

      setLocalState((prevState) => ({
        ...prevState,
        isLoading: false,
        policiesList: results.patientInsurancePolicies || [],
        locationsList: {
          pbc: isPBC ? [locationInfo] : [],
          tec: isPBC ? [] : [locationInfo]
        }
      }));
    } else {
      setLocalState((prevState) => ({ ...prevState, isLoading: false }));
      // Redirect to error page
      history.replace(routes.error.path);
    }
  };

  const setPrefilledFormForEdit = async () => {
    setLocalState((prevState) => ({ ...prevState, isLoading: true }));

    const eClaim = await getEClaimInfo(claimGuid);

    if (eClaim) {
      const practitionerGuid = eClaim?.[inputs.practitioner.name];
      const currentPatientGuid = eClaim?.[inputs.patientGuid.name];

      // Execute all requests concurrently and merge results into a single object
      const results = await getPromiseAll({
        patientInfo: getPatientInfo(currentPatientGuid),
        patientInsurancePolicies: getPatientInsurancePolicies(currentPatientGuid),
        recentCodes: getRecentCodes(currentPatientGuid),
        pbc: getEClaimLocations(practitionerGuid, 'pbc'),
        tec: getEClaimLocations(practitionerGuid, 'tec'),
        prefs: getPrefs(user.DCNGuid, practitionerGuid)
      });

      setLocalState((prevState) => ({ ...prevState, isLoading: false }));

      // Extract practitioner preferences, patient info, and recent codes
      const practitionerPrefs = results?.prefs?.prefs?.find((i) => i.label === 'default') || {};
      const primaryPolicy = results.patientInsurancePolicies?.length
        ? results.patientInsurancePolicies?.find((i) => i.RowGuid === eClaim?.[inputs.primaryPolicy.name])
        : {}; // If policies length === 1 then autoselect this policy
      const secondaryPolicy = results.patientInsurancePolicies?.length
        ? results.patientInsurancePolicies?.find((i) => i.RowGuid === eClaim?.[inputs.secondaryPolicy.name])
        : {};
      const formatServicesDates = eClaim?.[inputs.serviceDate.name]?.map((i) => moment(i).toDate());
      const insurerID = eClaim?.[inputs.primaryPolicy.name].InsurerID;
      const catalogType = Number(insurerID) === -1 ? 'pbc' : 'tec';
      const isPBC = catalogType === 'pbc';
      const locationsList = isPBC ? results.pbc : results.tec;
      const location = locationsList?.find((i) => i.RowGuid === eClaim?.[inputs.location.name]);
      const accidentType = eClaim?.[inputs.accidentType.name] || 'N/A';
      const accidentDate = eClaim?.[inputs.accidentDate.name] ? moment(eClaim?.[inputs.accidentDate.name]).toDate() : null;
      const prescriberType = eClaim?.[inputs.prescriberType.name] || 'N/A';

      const formattedEClaim = {
        ...eClaim,
        [inputs.patient.name]: [results.patientInfo],
        [inputs.serviceDate.name]: formatServicesDates,
        [inputs.secondaryPolicy.name]: secondaryPolicy,
        [inputs.location.name]: location,
        [inputs.accidentType.name]: accidentType,
        [inputs.accidentDate.name]: accidentDate,
        [inputs.prescriberType.name]: prescriberType
      };

      // Reset the form with initial values
      methods.reset(formattedEClaim);

      // If policies length === 1 then autoselect this policy
      if (!isEmpty(primaryPolicy)) {
        methods.setValue(inputs.primaryPolicy.name, primaryPolicy, { shouldDirty: isEdit });
      }

      dispatch(setMissingCodesToRecentList({ codes: results.recentCodes }));
      setLocalState((prevState) => ({
        ...prevState,
        isLoading: false,
        practitionerPrefs,
        policiesList: results.patientInsurancePolicies || [],
        locationsList: {
          pbc: results.pbc || [],
          tec: results.tec || []
        }
      }));
    } else {
      setLocalState((prevState) => ({ ...prevState, isLoading: false }));
      // Redirect to error page
      history.replace(routes.error.path);
    }
  };

  const context = {
    isNew,
    isEdit,
    isView,
    errors: methods.formState.errors,
    isDirty: methods.formState.isDirty,
    localState,
    setLocalState,
    setEmptyForm,
    ...methods
  };

  const onSubmitActions = useSubmit(context);

  return { ...context, ...onSubmitActions };
};
