import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { Button } from 'primereact/button';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

import AuthFormWrap from '../../../components/Wraps/PageWrap/AuthFormWrap';
import { captchaValidation } from '../../../service/Lookup';
import { InputWrapper } from '../../../components/Inputs';
import { routes } from '../../../routes/routes';
import { getLicencingsBodiesListAction, getPracticeTypeListAction, getSecretQuestions } from '../../auth/actions/auth.actions.creators';
import { updateRegistrationFormState } from '../actions/registration.actions.creators';
import { Skeleton } from 'primereact/skeleton';
import RadioButton from '../../../components/PrimeReactComponents/RadioButton';

const RegistrationType = () => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const registrationState = useSelector((state) => state.registration.registrationFormState);

  // Function to handle image load event
  const handleImageLoad = () => {
    setIsLoading(false);
  };

  // Function to handle image error event
  const handleImageError = () => {
    setIsLoading(false);
  };

  function getReferralCode() {
    // Get the hash part of the URL
    const hash = window.location.hash;

    // Check if there's a query string in the hash
    if (hash.includes('?')) {
      // Extract the part after '?'
      const queryString = hash.split('?')[1];

      // Parse the query string using URLSearchParams
      const params = new URLSearchParams(queryString);

      // Get the value of 'referralCode'
      return params.get('referralCode');
    }

    // Return null if 'referralCode' is not present
    return null;
  }
  const referralCode = getReferralCode();

  useEffect(() => {
    dispatch(getLicencingsBodiesListAction());
    dispatch(getPracticeTypeListAction());
    dispatch(getSecretQuestions());
  }, []);

  const registrationTypes = [
    { name: 'Sole practitioner / Doctor', key: 'P', value: 'register-practitioner' },
    { name: 'Clinic', key: 'C', value: 'register-clinic' },
    { name: 'Billing bureau', key: 'B', value: 'register-bureau' }
  ];

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setError
  } = useForm({
    defaultValues: {
      captchaCode: '',
      selectedRegisterType: registrationTypes[0],
      userType: registrationState.firstStepType
    }
  });

  const randomInteger = () => {
    const min = 1000;
    const max = 9999;
    let rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
  };
  const [captchaRequestCode, setCaptchaRequestCode] = useState(randomInteger());

  const onCancel = () => {
    history.push(routes.signIn.path);
  };

  const onSubmit = async (data) => {
    try {
      await captchaValidation(captchaRequestCode, data.captchaCode);

      dispatch(
        updateRegistrationFormState({
          ...registrationState,
          type: data.selectedRegisterType.value,
          firstStepType: data.selectedRegisterType.value,
          ReferenceCode: referralCode
        })
      );
    } catch (error) {
      setError('captchaCode', { message: 'Your code is an invalid. Try again.' });
    }
  };

  return (
    <AuthFormWrap footerContent={true}>
      <div className="m-0 mt-4 mb-2 text-800 flex justify-content-start text-center w-full font-bold text-3xl">
        Sign up for a 14-day free trial today!
      </div>
      <div className="flex line-height-3 text-800 text-base">
        Start your journey with our exceptional billing service, available to you free for one billing cycle. With top-notch customer support at your
        side, you'll quickly see why our clients are so satisfied. Discover the difference—sign up today!
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-content-start">
          <div className="flex flex-column gap-3">
            <div className="font-medium text-800 mt-2">I need an account for:</div>

            {registrationTypes.map((type) => {
              return (
                <div data-testid={type.value} key={type.key} className="flex align-items-center">
                  <Controller
                    name="selectedRegisterType"
                    control={control}
                    render={({ field }) => (
                      <RadioButton
                        {...field}
                        inputId={type.key}
                        name="selectedRegisterType"
                        value={type}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        checked={getValues('selectedRegisterType.key') === type.key}
                      />
                    )}
                  />
                  <label htmlFor={type.key} className="ml-2 text-800">
                    {type.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <p className="font-medium text-800 mt-3 text-center font-light">Enter the code to prove you are not a robot</p>
        <div className="flex mt-2 justify-content-between ">
          <div className="flex align-items-center justify-content-end" style={{ maxWidth: '48%', width: '48%', height: '60px' }}>
            {isLoading && <Skeleton height="3rem" style={{ maxWidth: '100%', width: '85%' }} />}
            <img
              data-testid="captcha-image-registration-type"
              src={`https://secure.claimmanager.ca/media/JpegImage.aspx?code=${captchaRequestCode}`}
              alt="captcha"
              onLoad={handleImageLoad}
              onError={handleImageError}
              style={{ maxWidth: '100%', width: '85%', height: '45px' }}
            />
            <img
              data-testid="refresh-captcha-registration-type"
              src="/assets/layout/images/refresh.png"
              alt="captcha"
              className="ml-2"
              style={{ width: '25px', height: '25px', cursor: 'pointer' }}
              onClick={() => setCaptchaRequestCode(randomInteger())}
            />
          </div>
          <Controller
            name="captchaCode"
            control={control}
            rules={{
              maxLength: { value: 4, message: 'This field must contain 4 characters' },
              required: 'CAPTCHA code is required'
            }}
            render={({ field }) => (
              <InputWrapper
                name="captchaCode"
                label="CAPTCHA code"
                required
                errorMessage={errors?.captchaCode?.message}
                style={{ width: '48%', height: '55px' }}
              >
                <InputText
                  {...field}
                  data-testid="captchaCode-registration-type"
                  id="captchaCode"
                  maxLength={4}
                  value={field.value}
                  className={classNames({ 'p-invalid': errors.captchaCode })}
                  style={{ padding: '5px', width: '100%', height: '35px' }}
                />
              </InputWrapper>
            )}
          />
        </div>

        <div className="flex justify-content-end align-items-center mt-5 mb-6 gap-3 w-full">
          <Button
            data-testid="next-button-registration-type"
            className="cursor-pointer w-6 h-3rem"
            type="submit"
            label="Next"
            loading={isSubmitting}
          />
          <Button
            data-testid="cancel-button-registration-type"
            className="cursor-pointer w-6 h-3rem"
            type="button"
            onClick={onCancel}
            label="Cancel"
            outlined
          />
        </div>
      </form>
    </AuthFormWrap>
  );
};

export default RegistrationType;
