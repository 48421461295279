import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { capitalizeFirstLetter, nameValidation } from '../helpers/validation';

const FirstName = ({ label }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <InputWrapper name="firstName" label={label} required errorMessage={errors.firstName?.message}>
        <Controller
          name="firstName"
          control={control}
          rules={{ validate: nameValidation }}
          render={({ field }) => (
            <InputText
              id="firstName"
              data-testid="firstName-testid"
              autoFocus
              {...field}
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(capitalizeFirstLetter(value));
              }}
              onBlur={() => {
                // Example of potential blur behavior: logging or triggering a function
                field.onBlur(); // Call the default onBlur handler
              }}
              className={classNames({ 'p-invalid': errors.firstName })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default FirstName;
