import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const StepTwoActionButtons = () => {
  const history = useHistory();
  const { localState, setLocalState, isNew } = useFormContext();
  const isCreating = localState.isCreating;
  const isSubmitting = localState.isSubmitting;

  const onBack = () => {
    setLocalState((prevState) => ({ ...prevState, step: 1 }));
  };

  const handleSubmin = () => {
    setLocalState((prevState) => ({ ...prevState, sendNow: true }));
  };

  const handleCreate = () => {
    setLocalState((prevState) => ({ ...prevState, sendNow: false }));
  };

  return (
    <div className="flex justify-content-start stickyActionButtons pt-4 w-full">
      <div className="flex w-full gap-3" style={{ maxWidth: '35rem' }}>
        <Button
          id={elementIDs.submit}
          className="w-full"
          type="submit"
          label={t('Submit')}
          loading={isSubmitting}
          disabled={isCreating}
          onClick={handleSubmin}
        />

        <Button
          id={elementIDs.create}
          className="p-button-outlined w-full"
          type="submit"
          label={isNew ? t('Create') : t('Save')}
          loading={isCreating}
          disabled={isSubmitting}
          onClick={handleCreate}
        />

        <Button id={elementIDs.back} className="p-button-outlined w-full" type="button" label={t('Back')} onClick={onBack} />
        <Button id={elementIDs.cancel} className="p-button-outlined w-full" type="button" label={t('Cancel')} onClick={() => history.goBack()} />
      </div>
    </div>
  );
};

export default StepTwoActionButtons;
