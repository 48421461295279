import React from 'react';
import { useFormContext } from 'react-hook-form';
import DatePicker from './Common/DatePicker';
import ServiceDateLabelLayout from '../Layouts/ServiceDateLabelLayout';
import { inputs } from '../../../helpers/inputs';
import { useServiceDate } from '../../../hooks/useServiceDate';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const ServiceDate = () => {
  const { onChangeServiceDate } = useServiceDate();
  const { watch } = useFormContext();
  const status = watch(inputs.status.name);

  return (
    <DatePicker
      id={elementIDs.serviceDate}
      required
      status={status}
      input={inputs.serviceDate}
      onChange={onChangeServiceDate}
      lableContent={<ServiceDateLabelLayout />}
    />
  );
};

export default ServiceDate;
