import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { TabPanel, TabView } from 'primereact/tabview';
import Logs from '../../logs/components/Logs/Logs';
import Practitioners from './PractitionersTable/Practitioners';
import ReferralCreditsView from '../../referralCredits/components/index';
import { t } from '../../../service/localization/i18n';
import { routes } from '../../../routes/routes';

const StaffView = () => {
  const params = useParams();
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);

  const tabMapping = {
    practitioners: 0,
    logs: 1,
    credits: 2
  };

  useEffect(() => {
    const tab = params.tab;

    if (tab && tabMapping[tab] !== undefined) {
      setActiveIndex(tabMapping[tab]);
    }
  }, [params]);

  const handleTabChange = (index) => {
    setActiveIndex(index);
    const tabName = Object.keys(tabMapping).find((key) => tabMapping[key] === index);
    history.push(`${routes.staff.path}/${tabName}`);
  };

  const showLogs =
    window.location.hostname === 'localhost' ||
    window.location.hostname.includes('onrender') ||
    window.location.hostname.includes('vercel.app') ||
    window.location.hostname.includes('nopaperwork');

  return (
    <div className="flex col-12 p-0 justify-content-center">
      <div className="px-3 w-full" style={{ maxWidth: '1800px' }}>
        <TabView activeIndex={activeIndex} onTabChange={(e) => handleTabChange(e.index)}>
          <TabPanel header={t('Staff')} headerClassName="extra-large-text">
            <Practitioners />
          </TabPanel>

          {showLogs && (
            <TabPanel header="Logs" headerClassName="extra-large-text">
              <Logs />
            </TabPanel>
          )}

          <TabPanel header="Credits" headerClassName="extra-large-text">
            <ReferralCreditsView />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default StaffView;
