import React, { useMemo } from 'react';
import Dropdown from '../../PrimeReactComponents/Dropdown';
import { dropdownScrollHeight } from '../../../modules/config/stylesConfig';
import { defaultCountryCode } from '../../../modules/config/defaultValuesConfig';
import { countries } from '../../../modules/config/dropdownListsConfig';
import { t } from '../../../service/localization/i18n';

/**
 * ProvincePicker component renders a dropdown for selecting a province based on the selected country.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.country=defaultCountryCode] - The selected country value.
 * @param {string} props.value - The selected province value.
 * @param {function} props.onChange - The callback function to handle the change event.
 *
 * @returns {JSX.Element} The rendered ProvincePicker component.
 */
const ProvincePicker = ({ country = defaultCountryCode, value, onChange, ...props }) => {
  // Memoize options for the selected country
  const options = useMemo(() => {
    const countryRegions = countries.find((i) => i.value === country)?.regions || [];
    return [{ label: t('N_A'), value: '' }, ...countryRegions];
  }, [country]);

  return (
    <Dropdown
      {...props}
      data-testid={props.dataTestid}
      value={value}
      onChange={onChange}
      scrollHeight={dropdownScrollHeight()}
      options={props.options || options}
      filter
      resetFilterOnHide // Not work in v9. Will be fixed in v10
    />
  );
};

export default ProvincePicker;
