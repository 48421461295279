import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TeleplanMessagePrint from './TeleplanMessage';
import WSBCReportPrint from './WSBCReport/WSBCReportPrint';
import PrivateInvoicePrint from './PrivateInvoice/PrivateInvoicePrint';

import { printTypes } from './helpers/printTypes';
import { setPrintData } from '../../../modules/core/actions/core.action.creators';

const Print = () => {
  const dispatch = useDispatch();
  const { print } = useSelector((state) => state.core);
  const types = printTypes();

  useEffect(() => {
    const handleAfterPrint = () => {
      dispatch(setPrintData()); // Clear the print data after printing
    };

    window.addEventListener('afterprint', handleAfterPrint);
    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, [dispatch]);

  const printLayout = () => {
    switch (print.type) {
      case types.privateInvoice.type:
        return <PrivateInvoicePrint data={print.printData || {}} />;

      case types.wsbcReport.type:
        return <WSBCReportPrint data={print.printData || {}} />;

      case types.teleplanMessage.type:
        return <TeleplanMessagePrint data={print.printData || {}} />;

      default:
        return null;
    }
  };

  // Only render when there is print data
  if (!print.printData) return null;

  return <>{printLayout()}</>;
};

export default Print;
