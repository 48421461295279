import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ChangePassword from '../../../../components/Widgets/ChangePassword/ChangePassword';
import PageWrap from '../../../../components/Wraps/PageWrap/PageWrap';
import { t } from '../../../../service/localization/i18n';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../AuthProvider';

const ChangePasswordView = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user.details);

  return (
    <PageWrap>
      <div className="card w-25rem">
        <h5 className="text-center pb-3">{t('Change_password')}</h5>
        <ChangePassword dcnGuid={user.DCNGuid} doctorGuid={user.UserGuid} onCancel={() => history.goBack()} />
      </div>
    </PageWrap>
  );
};

export default ChangePasswordView;
