import React from 'react';

import { Calendar } from 'primereact/calendar';
import { Skeleton } from 'primereact/skeleton';

import PrivateInvoiceServices from './PrivateInvoiceServices';
import InvoiceTimeline from '../../../components/Widgets/Timelines/InvoiceTimeline/InvoiceTimeline';
import ClaimStatusAvatar from '../../../components/Avatars/ClaimStatusAvatar/ClaimStatusAvatar';
import TimeWithDuration from '../../../components/Layouts/Time/TimeWithDuration';
import FacilityLayout from '../../../components/Layouts/Facility/FacilityLayout';
import AddressLayout from '../../../components/Layouts/AddressLayout';

import { formatDate, fixDate, convertToLocalTime } from '../../utils/formatDate';
import { icons } from '../../config/stylesConfig';
import { currencyFormat } from '../../utils/currencyFormat';
import { rural_codes, location_codes } from '../../config/constants';
import { commonInputs } from '../../config/commonInputsConfig';
import { submissionCodes } from '../helpers/mapper';
import { convertReferralValue } from '../helpers/convertReferralValue';
import { patientFullNameWithAge } from '../../utils/patientFullName';
import { formatPHN } from '../../utils/formatPHN';
import { allRelationshipOptions } from './EClaimPolicy/helpers/dropdownOptions';
import { t } from '../../../service/localization/i18n';
import classes from './RowExpantionTable.module.scss';
import cx from 'classnames';
import moment from 'moment';
import { isReduced, isUnmanaged, reducedAction } from '../helpers/rowActions';
import { InputSwitch } from 'primereact/inputswitch';

const RowExpantionTable = ({ data, isFetching }) => {
  const ruralCode = rural_codes.find((i) => i.value === data?.RuralCode);
  const locationCode = location_codes.find((i) => i.value === data?.LocationCode);
  const submissionCode = data?.SubmissionCode ? submissionCodes?.find((i) => i.value === data?.SubmissionCode) : submissionCodes[0];
  const refName = data?.RefName || '';
  const claimNo = data.ClaimNumber ? `#${data.ClaimNumber}` : t('N_A');
  const sectionItemsRowGap = '4px';
  const isEClaim = data?.ClaimType === 'E';
  const checked = isUnmanaged(data?.ReviewStatus);

  const amountSection = (
    <div className="flex">
      <ClaimStatusAvatar
        className={classes.statusRoundedIcon}
        status={data?.Status}
        statusText={data?.StatusText}
        style={{
          width: '2.4em',
          minWidth: '2.4em',
          height: '2.4em',
          marginTop: '3px',
          zIndex: '0',
          marginLeft: '-10px'
        }}
      />

      <div className="ml-3">
        {/* Private invoice */}
        {data.InvoiceType === 'Private' ? (
          <div className="h-full">
            <PrivateInvoiceServices claimRecord={data} />
          </div>
        ) : (
          <>
            {/* NOT Private invoice */}
            <div className="font-bold medium-size-text">
              {data?.FeeCode && data?.NoOfServiceUnits && data?.ICD9Code && `${data?.FeeCode} x ${data?.NoOfServiceUnits}`}
            </div>

            <div className="medium-size-text font-bold">
              {`${currencyFormat(data?.FeeAmount)} x ${data?.NoOfServiceUnits} = ${currencyFormat(data?.FeeTotal)}`}
            </div>
          </>
        )}
      </div>
    </div>
  );

  const patientSection = (
    <div className="flex">
      <div className="flex">
        <i className={cx(icons.patient, classes.iconWrap)} />
      </div>

      <div className="flex flex-column" style={{ rowGap: sectionItemsRowGap }}>
        <div className="white-space-normal font-bold">{patientFullNameWithAge(data)}</div>
        <div className="white-space-normal">{`DOB: ${moment(data?.BirthDay).format('ll')}`}</div>
        {data?.PHN ? (
          <div className="white-space-normal">{`${data?.PHNProvince && data?.PHNProvince} ${formatPHN(data?.PHN, data?.PHNProvince)}`}</div>
        ) : (
          <div className="white-space-normal">{`${t('PHN')}: ${t('N_A')}`}</div>
        )}
        {data?.RefNumber && (
          <div className="white-space-normal">
            {data?.RefToBy && `${t('Referred.2')}. ${convertReferralValue(data?.RefToBy).toLowerCase()} ${refName} #${data?.RefNumber}`}
          </div>
        )}
      </div>
    </div>
  );

  const codeDescriptionSection = (
    <div className="flex">
      {data.InvoiceType !== 'Private' && data.InvoiceType !== 'eClaims' && (
        <>
          <div className="flex">
            <i className={cx('pi pi-heart', classes.iconWrap)} />
          </div>

          <>
            {!data?.ICD9Code?.length && !data?.FeeCode?.length ? (
              <>No Codes</>
            ) : (
              <div className="flex flex-column" style={{ rowGap: sectionItemsRowGap }}>
                {data?.ICD9Code && (
                  <div>
                    {data?.ICD9Code?.split(',').map((i, index) => {
                      return (
                        <div className="flex">
                          <div className="font-bold" style={{ minWidth: '4rem' }}>
                            {i} -
                          </div>
                          <div className="white-space-normal" key={`${i}_${index}`}>
                            {data?.ICD9Text[index] || t('N_A')}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {data?.FeeCode && (
                  <div className="flex">
                    <div className="font-bold" style={{ minWidth: '4rem' }}>
                      {data?.FeeCode} -
                    </div>
                    <div className="white-space-normal">{data?.FeeCodeText}</div>
                  </div>
                )}
              </div>
            )}
          </>
        </>
      )}

      {data.InvoiceType === 'Private' && <div>test</div>}
    </div>
  );

  const doctorSection = (
    <div className="flex">
      <div className="flex">
        <i className={cx(icons.claimViewDoctorIcon, classes.iconWrap)} />
      </div>

      <div className="flex flex-column" style={{ rowGap: sectionItemsRowGap }}>
        <div className="font-bold white-space-normal">{`${data?.TreatedBy?.toUpperCase()}`}</div>

        {data.InvoiceType === 'Private' ? (
          <>
            {data && data[commonInputs.practitioner.licenceNumber] && (
              <div className="white-space-normal">{`${t('Licence')} # ${data[commonInputs.practitioner.licenceNumber]}`}</div>
            )}
            <div className="white-space-normal">{data?.LocationName}</div>
          </>
        ) : data.InvoiceType === 'eClaims' ? (
          <div className="white-space-normal">Licence ID: 58734</div>
        ) : (
          <div className="white-space-normal">{`${t('Practitioner')}/${t('Payee')}: #${data?.PractitionerNumber}/#${data?.PayeeNumber}`}</div>
        )}

        <FacilityLayout data={{ facility: data?.FacilityNum, subFacility: data?.SubFacility }} />

        {ruralCode && data.InvoiceType !== 'eClaims' && data.InvoiceType !== 'Private' && (
          <div className="white-space-normal">{ruralCode.label === 'N/A' ? t('No_rural_code') : ruralCode.label}</div>
        )}
        {locationCode?.label && data.InvoiceType !== 'Private' && data.InvoiceType !== 'eClaims' && (
          <div className="white-space-normal">{locationCode?.label}</div>
        )}
      </div>
    </div>
  );

  const billToSection = (
    <div className="flex">
      <div className="flex">
        <i className={cx('pi pi-users', classes.iconWrap)} />
      </div>

      <div className="flex flex-column" style={{ rowGap: sectionItemsRowGap }}>
        <div className="font-bold white-space-normal">{`${t('Bill_To')}:`}</div>
        <div className="white-space-normal">
          <AddressLayout data={data?.BillTo} />
        </div>
      </div>
    </div>
  );

  const noteSection = (
    <>
      {data.Comment && (
        <div className="flex white-space-normal">
          <div className="flex">
            <i className={cx('pi pi-book', classes.iconWrap)} />
          </div>

          <div>{`${t('Note')}: ${data.Comment}`}</div>
        </div>
      )}
    </>
  );

  const wsbcSection = (
    <div className="flex flex-column" style={{ rowGap: '2rem', columnGap: '2rem' }}>
      <div className="flex">
        <div className="flex">
          <i className={cx('pi pi-shield', classes.iconWrap)} />
        </div>

        {!data.AreaOfInjury?.length && !data.NatureOfInjury?.length ? (
          <>No Codes</>
        ) : (
          <div className="flex flex-column" style={{ rowGap: sectionItemsRowGap }}>
            {data.AreaOfInjury && (
              <div className="flex">
                <div className="font-bold" style={{ minWidth: '4rem' }}>
                  {data.AreaOfInjury} -{' '}
                </div>
                <div className="white-space-normal">{data.AOIText}</div>
              </div>
            )}

            {data.NatureOfInjury && (
              <div className="flex">
                <div className="font-bold" style={{ minWidth: '4rem' }}>
                  {data.NatureOfInjury} -{' '}
                </div>
                <div className="white-space-normal">{data.NOIText}</div>
              </div>
            )}

            {data.AACText && <div className="white-space-normal">{`${t('Anatomic_part')}: ${data.AACText}`}</div>}
          </div>
        )}
      </div>

      <div className="flex" style={{ height: '18px' }}>
        <div className="flex">
          <i className={cx('pi pi-folder', classes.iconWrap)} />
        </div>

        <div className="flex" style={{ rowGap: '2rem', columnGap: '2rem' }}>
          <div className="white-space-normal">{`${t('Claim')}: ${claimNo}`}</div>
          <div className="white-space-normal">{`${formatDate(data.InjuryDay)}`}</div>
        </div>
      </div>
    </div>
  );

  const icbcSection = (
    <div className="flex" style={{ height: '18px' }}>
      <div className="flex">
        <i className={cx('pi pi-car', classes.iconWrap)} />
      </div>

      <div className="flex" style={{ rowGap: '2rem', columnGap: '2rem' }}>
        <div className="white-space-normal">{`${t('Claim')}: ${claimNo}`}</div>
      </div>
    </div>
  );

  const defaultClaimInfo = (
    <>
      {data.InvoiceType !== 'Private' && data.ClaimType !== 'E' && (
        <div className="flex flex-column" style={{ rowGap: '2rem', columnGap: '2rem' }}>
          {/* Amount section */}
          {amountSection}

          {/* Codes description section */}
          {codeDescriptionSection}

          {/* WSBC section */}
          {(data.ClaimType === 'W' || data.ClaimType === 'R') && wsbcSection}

          {data.ClaimType === 'I' && icbcSection}

          {/* Note section */}
          {noteSection}

          {/* Patient section */}
          {patientSection}

          {/* Doctor section */}
          <div className="flex">{doctorSection}</div>
        </div>
      )}
    </>
  );

  const privateInvoiceInfo = (
    <>
      {data.InvoiceType === 'Private' && (
        <div className="flex flex-column" style={{ rowGap: '2rem', columnGap: '2rem' }}>
          {/* Amount section */}
          {amountSection}

          {/* Note section */}
          {noteSection}

          {/* Patient section */}
          {patientSection}

          {/* Bill To section */}
          {billToSection}

          {/* Doctor section */}
          {doctorSection}
        </div>
      )}
    </>
  );

  const eClaimsSection = (
    <>
      {isEClaim && (
        <div className="flex flex-column" style={{ rowGap: '2rem', columnGap: '2rem' }}>
          {/* Amount section */}
          {amountSection}

          {/* Note section */}
          {noteSection}

          {/* Patient section */}
          <div className="flex">
            <div className="flex">
              <i className={cx(icons.patient, classes.iconWrap)} />
            </div>

            <div className="flex flex-column gap-2">
              <div className="flex flex-column" style={{ rowGap: sectionItemsRowGap }}>
                <div className="white-space-normal font-bold">{patientFullNameWithAge(data)}</div>
                <div className="white-space-normal">{`DOB: ${moment(data?.BirthDay).format('ll')}`}</div>
              </div>

              <div className="flex flex-column" style={{ rowGap: sectionItemsRowGap }}>
                <div className="white-space-normal font-bold">{`${data?.InsurerName}`}</div>
                <div className="white-space-normal">{`Plan #${data?.PolicyNum}`}</div>
                <div className="white-space-normal">{`Member #${data?.MemberID}`}</div>
                <div className="white-space-normal">
                  {`Insured: ${allRelationshipOptions?.find((i) => i.value?.toUpperCase() === data?.Relationship?.toUpperCase())?.label || t('N_A')}`}
                </div>
              </div>
            </div>
          </div>

          {/* Doctor section */}
          <div className="flex">
            <div className="flex">
              <i className={cx(icons.claimViewDoctorIcon, classes.iconWrap)} />
            </div>

            <div className="flex flex-column" style={{ rowGap: sectionItemsRowGap }}>
              <div className="font-bold white-space-normal">{`${data?.TreatedBy?.toUpperCase()}`}</div>

              <div className="white-space-normal">{`Licence #${data.LicenseId}`}</div>

              {data?.LocationName && <div className="white-space-normal">{`Location: ${data.LocationName}`}</div>}
            </div>
          </div>
        </div>
      )}
    </>
  );

  if (isFetching) {
    return (
      <div className="grid col-12 px-4">
        <div className="col-12 lg:col-1 p-0 mb-3">
          <Skeleton width="100%" height="10rem" />
        </div>

        <div className={cx('col-12 lg:col-3 py-0 px-3 mb-5 lg:mb-0', classes.calendarWrap)}>
          <Skeleton width="100%" height="10rem" />
        </div>

        <div className="col-12 lg:col-5 py-0 px-3 mb-5 lg:mb-0">
          <Skeleton width="100%" height="10rem" />
        </div>

        <div className="col-12 lg:col-3 p-0 mb-5 lg:mb-0">
          <Skeleton width="100%" height="10rem" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-content-between w-full py-3 px-6">
      <div className="flex align-items-start" style={{ minWidth: '190px', maxWidth: '190px' }}>
        {(data.StartTime || (!data.StartTime && data.FinishTime)) && (
          <div className="flex align-items-center">
            <i className="pi pi-clock mr-2" />
            <span className="white-space-normal">
              <TimeWithDuration startTime={data?.StartTime} endTime={data?.FinishTime} />
            </span>
          </div>
        )}
      </div>

      <div className="flex w-full" style={{ maxWidth: '1200px', rowGap: '3rem', columnGap: '6rem' }}>
        {/* Calendar and time */}
        <div className={cx('expandedViewCalendar', classes.calendarWrap, `calendar_cm_status_${data.Status}`)}>
          <Calendar value={fixDate(data.ServiceDate + 'T00:00:00')} inline showWeek baseZIndex={1000000} />
        </div>

        {/* Claim info */}
        <div className="w-full" style={{ minWidth: '440px', maxWidth: '440px' }}>
          {/* All except Private and eClaims */}
          {defaultClaimInfo}

          {/* Private invoices section */}
          {privateInvoiceInfo}

          {/* eClaims section */}
          {eClaimsSection}
        </div>

        {/* Timeline */}
        <div>
          <InvoiceTimeline invoice={data} />
          {!isEClaim && (
            <div className="flex pt-3">
              <div className="flex">
                <i className={cx(icons.claimViewDoctorIcon, classes.iconWrap)} />
              </div>
              <div>{submissionCode?.label}</div>
            </div>
          )}

          {isReduced(data) && (
            <>
              <div className="flex align-items-center">
                <label htmlFor="reduced_switch" className="mr-3">
                  Accept Reduction
                </label>
                <InputSwitch
                  inputId="reduced_switch"
                  tooltipOptions={{ position: 'top' }}
                  checked={checked}
                  onChange={(e) => {
                    reducedAction(data.InvoiceGuid, checked ? 2 : 300);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default RowExpantionTable;
