import React from 'react';
import { InputWrapper } from '..';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { mspNumberValidation } from '../../../modules/registration/components/helpers/validation';

const MspPayeeNumber = () => {
  const {
    control,
    formState: { errors },
    clearErrors
  } = useFormContext();

  return (
    <>
      <InputWrapper name="mspPayeeNumber" label="MSP payee # if different from practitioner #" errorMessage={errors.mspPayeeNumber?.message}>
        <Controller
          name="mspPayeeNumber"
          control={control}
          rules={{ validate: mspNumberValidation }}
          render={({ field }) => (
            <InputText
              {...field}
              id="mspPayeeNumber"
              data-testid="mspPayeeNumber-testid"
              maxLength={5}
              className={classNames({ 'p-invalid': errors.mspPayeeNumber })}
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(value.toUpperCase());
                value && errors.mspPayeeNumber?.message && clearErrors('mspPayeeNumber');
              }}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default MspPayeeNumber;
