import { useFormContext } from 'react-hook-form';
import { inputs } from '../helpers/inputs';
import { NA_DoctorGuid } from '../../../../config/defaultValuesConfig';
import { getPractitionerByGuid } from '../../../../utils/getPractitioner';
import { practitionerOptions } from '../helpers/practitionerOptions';

export const useConditions = () => {
  const { watch, isView } = useFormContext();

  const insurerID = watch(inputs.primaryPolicy.name).InsurerID;
  const prescriberType = watch(inputs.prescriberType.name);
  const accidentType = watch(inputs.accidentType.name);
  const practitionerGuid = watch(inputs.practitioner.name);
  const practitioner = getPractitionerByGuid(practitionerGuid, practitionerOptions);

  const prescriberFirstNameRequired = prescriberType !== 'N/A';
  const prescriberLastNameRequired = prescriberType !== 'N/A';
  const accidentDateRequired = accidentType !== 'N/A';
  const catalogType = Number(insurerID) === -1 ? 'pbc' : 'tec';
  const isPBC = catalogType === 'pbc';
  const isTEC = catalogType === 'tec';
  const recentCodesType = isTEC ? 'feecodes_tec' : 'feecodes_pbc';
  const isPractitionerSelected = practitionerGuid !== NA_DoctorGuid;
  const isCompleteSetupRequired =
    practitionerGuid !== NA_DoctorGuid && ((isPBC && !practitioner?.completePbcSetup) || (isTEC && !practitioner?.completeTecSetup));
  const disableCatalogs = isView || !isPractitionerSelected;

  return {
    isPBC,
    isTEC,
    catalogType,
    recentCodesType,
    isCompleteSetupRequired,
    prescriberFirstNameRequired,
    prescriberLastNameRequired,
    accidentDateRequired,
    isPractitionerSelected,
    disableCatalogs
  };
};
