import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { capitalizeFirstLetter, nameValidation } from '../helpers/validation';

const LastName = ({ label }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <InputWrapper name="lastName" label={label} required errorMessage={errors.lastName?.message}>
        <Controller
          name="lastName"
          control={control}
          rules={{ required: 'Last Name is required', validate: nameValidation }}
          render={({ field }) => (
            <InputText
              id="lastName"
              data-testid="lastName-testid"
              {...field}
              onChange={(e) => field.onChange(capitalizeFirstLetter(e.target.value))}
              className={classNames({ 'p-invalid': errors.lastName })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default LastName;
