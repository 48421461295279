import { useFormContext } from 'react-hook-form';
import { useConditions } from './useConditions';
import { inputs } from '../helpers/inputs';
import { steps } from '../config/steps';
import { useInsurancePolicy } from './useInsurancePolicy';
import { getEClaimsServices } from '../../../actions/claims.action.creators';
import { defaultGuid } from '../../../../config/defaultValuesConfig';
import { validate } from 'uuid';
import { t } from '../../../../../service/localization/i18n';
import { generateServiceValue } from '../helpers/defaultValues';

export const useValidation = () => {
  const { deleteInsurancePolicies } = useInsurancePolicy();
  const { trigger, getValues, localState, setLocalState } = useFormContext();
  const { accidentDateRequired, catalogType } = useConditions();
  const defaultErrorMessage = t('Mandatory_field.1');

  const handleNext = async (fields) => {
    let isValid = false;

    switch (localState.step) {
      case 1:
        isValid = await trigger([inputs.primaryPolicy.name]); // Provide list of inputs that should be validated on current step
        break;

      case 2:
        const fieldValidationPromises = [
          trigger([inputs.serviceDate.name, inputs.practitioner.name, inputs.location.name, inputs.payee.name, inputs.service.name], {
            shouldFocus: true
          }),
          ...fields.map((_, index) =>
            trigger([`${inputs.services.name}.${index}.${inputs.duration.name}`, `${inputs.services.name}.${index}.${inputs.amount.name}`], {
              shouldFocus: true
            })
          )
        ];

        // Use Promise.all to wait for all field validation promises
        const fieldValidationResults = await Promise.all(fieldValidationPromises);

        // Check if all field validations are true (all inputs are valid)
        isValid = fieldValidationResults.every((result) => result === true);
        break;

      // // No need because on step 3 no Next button.
      // case 3:
      //   isValid = await trigger([
      //     inputs.accidentDate.name,
      //     inputs.firstName.name,
      //     inputs.lastName.name,
      //     inputs.termsAndConditions.name
      //   ]); // Provide list of inputs that should be validated on current step
      //   break;

      default:
        break;
    }

    if (isValid) {
      setLocalState((prevState) => ({ ...prevState, step: localState.step + 1 }));

      const policiesForDeletion = localState.deleteEClaimPolicy.policies;
      if (localState.step === steps.stepOne) {
        const speciality = getValues(inputs.speciality.name);
        // Delete insurance policies
        if (policiesForDeletion?.length) deleteInsurancePolicies();

        const servicesList = await getEClaimsServices({ catalogType, speciality });
        if (servicesList) {
          const services = servicesList?.map((service) => {
            if (validate(service.value)) return service;
            return { ...service, value: generateServiceValue(service) };
          });
          setLocalState((prevState) => ({ ...prevState, servicesList: services }));
        }
      }
    }
  };

  const insurancePolicyValidation = (value, input) => {
    if (input.name === inputs.primaryPolicy.name) {
      if (value?.InsurerID) return true; // Validation passes
      return defaultErrorMessage; // Validation fails with an error message
    }
    return true; // Validation passes for other fields
  };

  const prescriberFirstNameValidation = (value) => {
    const prescriberType = getValues(inputs.prescriberType.name);
    if (prescriberType !== 'N/A') {
      return !!value || t('Mandatory_field.1');
    }
    return true;
  };

  const prescriberLastNameValidation = (value) => {
    const prescriberType = getValues(inputs.prescriberType.name);
    if (prescriberType !== 'N/A') {
      return !!value || t('Mandatory_field.1');
    }
    return true;
  };

  const accidentDateValidation = (value) => {
    if (accidentDateRequired) {
      return value || t('Mandatory_field.1');
    }
    return true;
  };

  const serviceValidation = (value) => {
    return (value && !!value?.value) || t('Mandatory_field.1');
  };

  const locationValidation = (value) => {
    return (!!value.RowGuid && value.RowGuid !== defaultGuid) || t('Mandatory_field.1');
  };

  const payeeValidation = (value) => {
    return value !== 'N/A' || t('Mandatory_field.1');
  };

  const durationValidation = (value) => {
    return (!!value && Number(value) > 0) || t('Mandatory_field.1');
  };

  const feeValidation = (value) => {
    return value > 0 || t('Mandatory_field.1');
  };

  const termsValidation = (value) => {
    if (localState.sendNow) return !!value || t('Mandatory_field.1');
    return true;
  };

  return {
    handleNext,
    serviceValidation,
    durationValidation,
    feeValidation,
    payeeValidation,
    termsValidation,
    locationValidation,
    accidentDateValidation,
    insurancePolicyValidation,
    prescriberFirstNameValidation,
    prescriberLastNameValidation
  };
};
