import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RegistrationType from './RegistrationType';
import SecretQuestions from './SecretQuestion/SecretQuestions';

import ClinicForm from './Clinic/ClinicForm';
import BureauForm from './Bureau/BureauForm';

import { updateRegistrationFormState } from '../actions/registration.actions.creators';
import PractitionerFormSole from './Practitioner/PractitionerFormSole';
import PractitionerFormAttached from './Practitioner/PractitionerFormAttached';
import EmailVerify from './Verification/EmailVerify';
import PhoneVerify from './Verification/PhoneVerify';

const RegistrationViews = () => {
  const dispatch = useDispatch();
  const registrationState = useSelector((state) => state.registration.registrationFormState);

  useEffect(() => {
    dispatch(updateRegistrationFormState());
  }, []);

  if (!registrationState) return null;

  return (
    <>
      {registrationState.type === 'register-type' && <RegistrationType />}
      {registrationState.type === 'register-practitioner' && <PractitionerFormSole />}
      {registrationState.type === 'register-practitioner-attached' && <PractitionerFormAttached />}
      {registrationState.type === 'register-clinic' && <ClinicForm />}
      {registrationState.type === 'register-bureau' && <BureauForm />}
      {registrationState.type === 'secret-questions' && <SecretQuestions />}
      {registrationState.type === 'register-step-2' && <EmailVerify />}
      {registrationState.type === 'register-step-3' && <PhoneVerify />}
    </>
  );
};

export default RegistrationViews;
