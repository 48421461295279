import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import ForgotPassword from '../../../../components/Widgets/ForgotPassword/ForgotPassword';

import { setForgotPassword } from '../../actions/auth.actions.creators';
import { routes } from '../../../../routes/routes';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';

const PasswordRecovery = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.details);
  const email = user?.Email;

  const obfuscateEmail = (email) => {
    if (!email) return 'Email';
    const [localPart, domainPart] = email.split('@');

    // Obfuscate the local part
    let obfuscatedLocalPart;
    if (localPart.length > 1) {
      obfuscatedLocalPart = localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1];
    } else {
      obfuscatedLocalPart = '*';
    }

    // Obfuscate the domain part
    const [domainName, domainExtension] = domainPart.split('.');
    const obfuscatedDomainName =
      domainName.length > 2 ? domainName[0] + '*'.repeat(domainName.length - 2) + domainName[domainName.length - 1] : domainName[0] + '*';
    const obfuscatedDomainPart = obfuscatedDomainName + '.' + domainExtension;

    // Combine the obfuscated parts
    return obfuscatedLocalPart + '@' + obfuscatedDomainPart;
  };

  const onSuccessSubmit = () => {
    // Redirect to login page after successful password update
    dispatch(setForgotPassword(false, ''));
    history.push(routes.signIn.path);
  };

  return (
    <AuthFormWrap>
      <div className="m-0 my-4 text-800 w-full text-center align-items-center font-bold text-3xl">Password Recovery</div>
      <div className="flex line-height-3 text-800 text-lg">
        A temporary pasword has been sent to your {obfuscateEmail(email)}. Use it to set a new pasword.
      </div>
      <div className="login-panel-content col-12 lg:col-12">
        <ForgotPassword
          onSuccessSubmit={onSuccessSubmit}
          onCancel={() => {
            history.push(routes.signIn.path);
          }}
        />
      </div>
    </AuthFormWrap>
  );
};

export default PasswordRecovery;
