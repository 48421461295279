import { validateCanadianZip } from '../../../regex/regex';
import { practitionerTypes } from './defaultValues';
import { clinicTypes } from './defaultValues';
import { bureauTypes } from './defaultValues';

export const emailValidation = (email) => {
  if (email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [localPart, domainPart] = email.split('@');

    if (!email.includes('@') || email.split('@').length !== 2) {
      return 'Invalid email format: missing "@" or too many "@" symbols';
    }
    if (!emailRegex.test(email)) {
      return 'Invalid email format';
    }

    // Local part validation
    if (/^[._-]/.test(localPart) || /[._-]$/.test(localPart)) {
      return 'Local part cannot start or end with period, hyphen, or underscore';
    }
    if (/[^a-zA-Z0-9._-]/.test(localPart)) {
      return 'Local part contains invalid characters';
    }
    if (/([._-])\1/.test(localPart)) {
      return 'Local part contains consecutive periods, hyphens, or underscores';
    }

    // Domain part validation
    if (/[^a-zA-Z0-9.-]/.test(domainPart)) {
      return 'Domain part contains invalid characters';
    }
    if (!/\./.test(domainPart)) {
      return 'Domain part must contain at least one period';
    }
    if (!/\.[a-zA-Z]{2,}$/.test(domainPart)) {
      return 'Domain must end with a valid top-level domain';
    }
    if (/([.-])\1/.test(domainPart)) {
      return 'Domain part contains consecutive periods or hyphens';
    }

    return true;
  }
};

export const nameValidation = (name) => {
  if (!name) return 'First Name is required';

  const trimmedName = name?.trim();
  const nameRegex = /^[a-zA-Z\d' .() -]+$/;
  const matchLetters = /\w*[a-zA-Z]\w*/;

  if (trimmedName.length < 1 || trimmedName.length > 50) return 'Name must be between 1 and 50 characters';
  if (!nameRegex.test(trimmedName)) return 'Only letters, numbers, hyphens, and apostrophes are allowed'; // Update this line
  if (!matchLetters.test(trimmedName)) return 'At least 1 letter must be in the name';

  return true;
};

export const userNameValidation = (name) => {
  if (!name) return 'Mandatory field';

  const trimmedName = name?.trim();
  const noSpacesOrSpecialCharsRegex = /^[^\s!@#$%^&*(),.?":{}|<>]+$/; // Disallows spaces and special characters
  const validCharsRegex = /^[A-Za-z0-9_-]+$/; // Allows only latin letters, numbers, underscores, and hyphens
  const matchLetters = /\w*[a-zA-Z]\w*/;

  if (trimmedName.length < 8 || trimmedName.length > 50) {
    return 'Name must be between 8 and 50 characters';
  }
  if (!noSpacesOrSpecialCharsRegex.test(trimmedName)) {
    return 'No spaces or special characters are allowed.';
  }
  if (!validCharsRegex.test(trimmedName)) {
    return 'Only latin letters, numbers, underscores, and hyphens are allowed.';
  }
  if (!matchLetters.test(trimmedName)) {
    return 'At least 1 letter must be in the name';
  }

  return true;
};

export const cityValidation = (name) => {
  if (!name) return 'City is required';

  const trimmedName = name?.trim();
  const nameRegex = /^[a-zA-Z\d' -]+$/;
  const matchLetters = /\w*[a-zA-Z]\w*/;

  if (trimmedName.length < 1 || trimmedName.length > 100) return 'City must be between 1 and 100 characters';
  if (!nameRegex.test(trimmedName)) return 'Only latin letters, numbers, hyphens, and apostr are allowed';
  if (!matchLetters.test(trimmedName)) return 'At least 1 letter must be in the name';

  return true;
};

export const streetValidation = (name) => {
  if (!name) return 'Street Address is required';

  const trimmedName = name?.trim();
  const nameRegex = /^[a-zA-Z0-9\s.,#&'/-]*$/;
  const matchLetters = /\w*[a-zA-Z]\w*/;

  if (trimmedName.length < 1 || trimmedName.length > 100) return 'Street must be between 1 and 100 characters';
  if (!nameRegex.test(trimmedName)) return 'Only latin letters, numbers, some sp. ch. are allowed (, . # & / -)';
  if (!matchLetters.test(trimmedName)) return 'At least 1 letter must be in the name';

  return true;
};

export const latinLetters = (name) => {
  if (!name) return;
  const trimmedName = name?.trim();
  const nameRegex = /^[a-zA-Z\d' -]+$/;
  const matchLetters = /\w*[a-zA-Z]\w*/;

  if (!nameRegex.test(trimmedName)) return 'Only latin letters, numbers, hyphens, and apostr are allowed';
  if (!matchLetters.test(trimmedName)) return 'At least 1 letter must be in the name';

  return true;
};

export const validateZip = (zip) => {
  if (!zip) return;
  // if (zip?.length > 6) return 'This field must contain exactly 6 characters';
  if (!validateCanadianZip.test(zip)) return 'Invalid postal code format. Expected format: A1A 1A1';

  return true;
};

export const capitalizeFirstLetter = (string) => {
  return string.replace(/(\b\w)/g, (char) => char.toUpperCase());
};

export const websiteValidation = (website) => {
  if (!website) {
    return true;
  }

  const websiteRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}(\/[a-zA-Z0-9#?&=._-]*)?$/;

  // If input is not empty, then apply the pattern check:
  if (!websiteRegex.test(website)) {
    return 'Invalid format. Please use the "www.sample.com" format.';
  }
  return true;
};

export const phoneValidation = (phone) => {
  if (!phone) return;
  const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
  if (!phoneRegex.test(phone)) return 'Invalid format. Please use the format (123) 456-7890';
  return true;
};

export const addressValidation = (address) => {
  const addressRegex = /^[a-zA-Z0-9\s,.-]+$/;

  if (address?.length < 10 || address.length > 100) return 'Address must be between 10 and 100 characters';
  if (!addressRegex.test(address)) return 'Address contains invalid characters';

  return true;
};

export const mspNumberValidation = (number) => {
  const mspRegex = /^[a-zA-Z\d]{5}$/;
  const digitPattern = /^\d{5}$/;
  const alphaDigitPattern = /^[a-zA-Z]\d{4}$/;

  if (number?.length > 0 && number?.length !== 5) return 'This field must contain exactly 5 characters';
  if (number?.length > 0 && !mspRegex.test(number)) return 'This field contains invalid characters';
  if (number?.length > 0 && !digitPattern.test(number) && !alphaDigitPattern.test(number)) return 'Expected format: 12345 or J2345';

  return true;
};

export const teleplanNumberValidation = (number) => {
  const teleplanRegex = /^[STst]\d{4}$/;

  if (number?.length === 0) return true;
  if (number?.length !== 5) return 'This field must contain exactly 5 characters';
  if (!teleplanRegex.test(number)) return 'This field must start with S/s or T/t followed by four digits';

  return true;
};

export const practitionerTypeValidation = (value) => {
  if (!value) return 'Practitioner Type is required';
  const validOptions = practitionerTypes.map((type) => type.label);
  const filteredTypes = validOptions.filter((type) => type.toLowerCase().includes(value.toLowerCase()));
  if (!filteredTypes.length) return 'No results found';
  return true;
};

export const bureauTypeValidation = (value) => {
  if (!value) return 'Billing Bureau Organizational type is required';
  const validOptions = bureauTypes.map((type) => type.label);
  const filteredTypes = validOptions.filter((type) => type.toLowerCase().includes(value.toLowerCase()));
  if (!filteredTypes.length) return 'No results found';
  return true;
};

export const clinicTypeValidation = (value) => {
  if (!value) return 'Clinic Organizational type is required';
  const validTypes = clinicTypes.map((type) => type.label);
  const filteredTypes = validTypes.filter((type) => type.toLowerCase().includes(value.toLowerCase()));
  if (!filteredTypes.length) return 'No results found';
  return true;
};

export const roleInClinicValidation = (value) => {
  if (!value?.length) return 'Role in clinic is required';
  return true;
};

export const practiceTypeDropdownValidation = (value) => {
  if (!value?.length) return 'Speciality is required';
  return true;
};

export const roleInBureauValidation = (value) => {
  if (!value?.length) {
    return 'Role in bureau is required';
  }
  return true;
};

export const licensingBodyValidation = (value) => {
  const validOptions = [
    'College of Physicians and Surgeons of British Columbia (CPSBC)',
    'College of Physicians and Surgeons of Alberta (CPSA)',
    'College of Physicians and Surgeons of Saskatchewan (CPSS)',
    'College of Physicians and Surgeons of Manitoba (CPSM)',
    'College of Physicians and Surgeons of Ontario (CPSO)',
    'Collège des médecins du Québec (CMQ)',
    'College of Physicians and Surgeons of New Brunswick (CPSNB)',
    'College of Physicians and Surgeons of Nova Scotia (CPSNS)',
    'College of Physicians and Surgeons of Prince Edward Island (CPSPEI)',
    'College of Physicians and Surgeons of Newfoundland and Labrador (CPSNL)',
    'Medical Licensing Authority of Nunavut',
    'Department of Health and Social Services, Government of the Northwest Territories',
    'Department of Health, Government of Yukon',
    'Other'
  ];

  if (!value) return 'Please select a valid licensing body from the dropdown.';
  if (!validOptions.includes(value)) return 'Please select a valid licensing body from the dropdown.';
  return true;
};

export const practiceTypeValidation = (value) => {
  const validOptions = [
    'General Practitioner',
    'Critical Care',
    'Emergency Medicine',
    'Family Medicine',
    'Anesthesiology',
    'Dermatology',
    'Ophthalmology',
    'Otolaryngology',
    'Internal Medicine',
    'Cardiology',
    'Clinical Immunology and Allergy',
    'Endocrinology and Metabolism',
    'Gastroenterology',
    'Geriatric Medicine',
    'Hematology and Oncology',
    'Infectious Diseases',
    'Nephrology',
    'Occupational Medicine',
    'Respirology',
    'Rheumatology',
    'Neurology',
    'Neurosurgery',
    'Obstetrics and Gynecology',
    'Orthopaedics',
    'Pediatrics',
    'Psychiatry',
    'Physical Medicine and Rehabilitation',
    'Plastic Surgery',
    'General Surgery',
    'Vascular Surgery',
    'Cardiac Surgery',
    'Thoracic Surgery',
    'Urology',
    'Diagnostic Radiology',
    'Palliative Medicine',
    'Diagnostic Ultrasound',
    'Laboratory Medicine',
    'Nuclear Medicine',
    'Surgical Assists',
    'Consultant Specialist'
  ];

  if (!value) return 'Please select at least one practice type.';
  const invalidOptions = value.filter((item) => !validOptions.includes(item));
  if (invalidOptions.length > 0) return 'Please select valid practice types from the dropdown.';
  return true;
};
