export const formatBMI = (value) => {
  const bmi = Number(value);

  if (isNaN(bmi)) {
    return '0.0'; // Return '0.0' for non-numeric values
  }

  const floatValue = parseFloat(bmi);

  // Ensure one decimal place for whole numbers
  return floatValue % 1 === 0 ? floatValue.toFixed(1) : floatValue;
};
