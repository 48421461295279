import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';

import { formatFormTypeValue, onFormTypeChange } from '../../../helpers/formTypeActions';
import { removeAllSpecialCharacters } from '../../../../../../regex/regex';
import { quickPickItems } from '../../../helpers/quickPickItems';
import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { catalogsCodesTooltipOptions, quickPickChipCommonClass, quickPickCodeChipCommonClass } from '../../../../../../config/stylesConfig';
import { t } from '../../../../../../../service/localization/i18n';
import moment from 'moment';
import cx from 'classnames';

// interface = {
//   item: {
//     name: string,
//     label: string,
//     codeDescription: string,
//     codeType: string,
//   },
//   mostRecentCodes: {
//     amoun: number | string
//     label: string
//     order: number
//     value: string
//   } [],
//   showActionButtons?: boolean
// }

const QuickPickItem = (props) => {
  const { isMobile } = useSelector((state) => state.core.window);
  const { isFetchingInvoicesMostRecentCodes, gettingWSBCReport } = useSelector((state) => state.claims);
  const { watch, setValue, setLocalState, errors, clearErrors } = useFormContext();
  const isFetching = gettingWSBCReport || isFetchingInvoicesMostRecentCodes;
  const length = props.mostRecentCodes?.length || 20;
  const mostRecentCodes = isFetching ? Array.from({ length }, () => 'item') : props.mostRecentCodes;

  const isCodeSelected = (code) => {
    let formStateValue = watch(props.item.name);
    if (props.item.name === inputs.serviceDate.name) formStateValue = moment(formStateValue).format('YYYY-MM-DD');

    return formStateValue?.toUpperCase()?.trim() === code?.toUpperCase()?.trim();
  };

  const onCodeClick = (code) => {
    const _value = !!isCodeSelected(code.value) ? '' : code.value;

    if (props.item.codeDescription) {
      const _label = !!isCodeSelected(code.value) ? '' : code.label;
      setValue(props.item.codeDescription, _label);
    }

    // Clear error message
    if (errors[props.item.name]) clearErrors(props.item.name);

    // On Service Date click
    if (props.item.name === inputs.serviceDate.name) {
      const speciality = watch(inputs.speciality.name);
      const formType = watch(inputs.formType.name);
      const serviceDate = _value ? moment(_value).toDate() : null;

      setValue(props.item.name, serviceDate, { shouldDirty: true });

      // Update FormType input
      onFormTypeChange({
        value: formatFormTypeValue(formType, speciality),
        setValue,
        speciality: speciality,
        serviceDate
      });

      return;
    }

    return setValue(props.item.name, _value, { shouldDirty: true });
  };

  const onViewMore = () => {
    const _catalogIndex = quickPickItems?.findIndex((i) => i.codeType === props.item?.codeType);
    const _focusFieldParams = quickPickItems?.find((i) => i.codeType === props.item?.codeType);

    setLocalState((prevState) => ({
      ...prevState,
      catalogIndex: _catalogIndex,
      focusFieldParams: _focusFieldParams
    }));
  };

  const codeItemSkeleton = () => {
    const skeletons = mostRecentCodes?.map((_, index) => {
      return <Skeleton key={index} borderRadius="2rem" width="56px" height="24px" />;
    });

    return (
      <div className="flex flex-wrap" style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}>
        {skeletons}
      </div>
    );
  };

  const codesLayout = () => {
    if (isFetching) return codeItemSkeleton();
    return mostRecentCodes?.map((i, index) => codeItem(i, index));
  };

  const codeItem = (i, index) => {
    const isSelected = !!isCodeSelected(i?.value);
    const itemClass = cx(
      `${props.item.name}_${elementIDs.quickPickCodeItem}`, // this class needs for cypress tests only
      quickPickCodeChipCommonClass(isSelected, i)
    );

    const tooltip = () => {
      if (props.item?.codeType === inputs.serviceDate.codeType) return i.weekday;
      return i.label;
    };

    const codeLabel = () => {
      if (props.item.name === inputs.serviceDate.name) return i.label;
      return i.value;
    };

    return (
      <Button
        id={`${props.item.name}_${elementIDs.quickPickCodeItem}_${codeLabel()?.replaceAll(removeAllSpecialCharacters, '')}`}
        className={itemClass}
        key={`${i?.value}_${index}`}
        label={codeLabel()}
        tooltip={tooltip()}
        tooltipOptions={catalogsCodesTooltipOptions(isMobile)}
        onClick={() => onCodeClick(i)}
      />
    );
  };

  if (!props.mostRecentCodes?.length) {
    return (
      <div id={elementIDs.noResentCodes} className="mx-0 mb-1 py-1 px-3">
        {t('No_recent_codes')}
      </div>
    );
  }

  return (
    <div className="quick_pick_items_list">
      {codesLayout()}

      {props.item.openCatalogButton && props.showActionButtons && (
        <Button
          id={`${props.item.name}_viewMore`}
          className={cx(quickPickChipCommonClass, 'p-button-text p-button-info')}
          label={t('Open_Catalog')}
          iconPos="right"
          onClick={onViewMore}
        />
      )}
    </div>
  );
};

export default QuickPickItem;
