import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import PrivateRoutesLayout from './PrivateRoutesLayout';
import Nav from './Nav';
import NavAuth from './NavAuth';
import Cookies from './Cookies';
import Loader from './components/Misc/Loader/Loader';
import Print from './components/Layouts/Print/Print';
import Toasts from './components/Dialogs/Toasts/Toasts';
import CommonDialogs from './components/Dialogs/Common/CommonDialogs';
import SessionIdle from './components/Dialogs/SessionIdle/SessionIdle';
import AddToHomeScreenToastInstruction from './modules/instructions/components/AddToHomeScreenToastInstruction';
import { PrintInvoicePage } from './pages/PrintInvoicePage';
import ErrorBoundary from './ErrorBoundary';
import { useAuth } from './AuthProvider';
import { routes } from './routes/routes';

function App() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const pdfpath = routes.printPDF.path ?? '/print-pdf'; 
  const shouldRenderPrintPDF = location.pathname.includes(pdfpath);
  if (shouldRenderPrintPDF) return <PrintInvoicePage />;

  if (!isAuthenticated)
    return (
      <>
        <NavAuth />
        <Toasts />
        <Cookies />
      </>
    );

  return (
    <ErrorBoundary>
      <SessionIdle />
      <AddToHomeScreenToastInstruction />
      <Print />
      <Loader />
      <Toasts />
      <CommonDialogs />
      <PrivateRoutesLayout>
        <Nav />
      </PrivateRoutesLayout>
    </ErrorBoundary>
  );
}

export default App;
