import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../Inputs';

import { useValidation } from '../hooks/useValidation';
import { t } from '../../../../service/localization/i18n';
import { inputWrapperStyles } from '../helpers/styles';

const AditionalInfo = () => {
  const name = 'Comment';
  const { control } = useFormContext();
  const { isPerson } = useValidation();

  if (!isPerson) return null;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper name={name} label={t('AditionalInfo')} style={inputWrapperStyles}>
          <InputText value={field.value} onChange={(e) => field.onChange(e.target.value)} />
        </InputWrapper>
      )}
    />
  );
};

export default AditionalInfo;
