import React, { useState } from 'react';
import Payee from '../../../../../components/Inputs/Payee/Payee';
import { usePrivateInvoicePrefs } from '../hooks/usePrivateInvoicePrefs';

const PrivateInvoicePayee = () => {
  const { payeePrefs } = usePrivateInvoicePrefs();
  const [value, setValue] = useState(payeePrefs);

  const onChange = (e) => {
    setValue(e.value);
  };

  return <Payee value={value} savePrefsOnChange onChange={onChange} />;
};

export default PrivateInvoicePayee;
