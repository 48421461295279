import { useDispatch, useSelector } from 'react-redux';
import { useUpdateRecords } from './useUpdateRecords';
import { t } from '../../../../../service/localization/i18n';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { useFormContext } from 'react-hook-form';
import { steps } from '../helpers/steps';
import { filter, flatMap, keys, map, uniq } from 'lodash';

export const useActionButtons = () => {
  const dispatch = useDispatch();
  const { toastMessage } = useSelector((state) => state.core);
  const { localState, setLocalState, onCreateNewInvoice } = useFormContext();
  const { isAnyInputRequired, durationValidationForPsychiatryCodes, isInvalidUnits } = useUpdateRecords();

  const getRequiredInputsForStep2 = () => {
    const requiredFields = uniq(flatMap(localState.recordsRequiredInputs, (obj) => filter(keys(obj), (key) => obj[key].required)));

    const labels = {
      note: t('Note'),
      icd9: t('Dx'),
      fee: t('Fee'),
      service_start: t('Start_time'),
      service_finish: t('End_time'),
      duration: t('Duration'),
      units: t('Units'),
      percent: t('Percent'),
      billed: t('Billed')
    };

    const labelsList = map(requiredFields, (key) => labels[key]);
    return labelsList;
  };

  const handleRequiredFields = ({ ignoreDurationValidatonForPsychiatry, isCreateClicked } = {}) => {
    const requiredFields = isAnyInputRequired();
    if (requiredFields) {
      const requiredInputs = getRequiredInputsForStep2();
      const requiredInputsText = requiredInputs?.join()?.replaceAll(',', ', ');

      const warnMessage = `${t('Please_fill_required_fields')}: ${requiredInputsText}`;
      toastMessage.message !== warnMessage && dispatch(setToastMessage({ type: 'warn', message: warnMessage }));
      return true;
    }

    // CMO-3179 - Validation for psychiatry codes 00633 - 00639
    const codesWithInvalidDuration = !ignoreDurationValidatonForPsychiatry && durationValidationForPsychiatryCodes({ isCreateClicked });
    if (codesWithInvalidDuration) return true;

    return false;
  };

  const onReview = ({ ignoreDurationValidatonForPsychiatry } = {}) => {
    if (isInvalidUnits) return setLocalState((prevState) => ({ ...prevState, unitsValidationDialog: { showDialog: true } }));
    if (handleRequiredFields({ ignoreDurationValidatonForPsychiatry })) return;
    setLocalState((prevState) => ({ ...prevState, step: steps.preview }));
  };

  const onReviewCreate = ({ ignoreDurationValidatonForPsychiatry, saveAndClose } = {}) => {
    if (isInvalidUnits) return setLocalState((prevState) => ({ ...prevState, unitsValidationDialog: { showDialog: true } }));
    if (handleRequiredFields({ ignoreDurationValidatonForPsychiatry, isCreateClicked: true })) return;
    onCreateNewInvoice({ saveAndClose });
  };

  return { onReview, onReviewCreate };
};
