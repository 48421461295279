import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { useController } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { emailValidation } from '../helpers/validation';

const Email = ({ control, autoFocusStatus, requiredStatus }) => {
  const {
    formState: { errors },
    field
  } = useController({
    name: 'email',
    control,
    rules: { required: `${requiredStatus ? 'Email is required' : ''}`, validate: emailValidation }
  });

  return (
    <>
      <InputWrapper name="email" label="Email" required={requiredStatus} errorMessage={errors.email?.message}>
        <InputText
          {...field}
          data-testid="email-testid"
          type="text"
          autoFocus={autoFocusStatus}
          className={classNames({ 'p-invalid': errors.email })}
        />
      </InputWrapper>
    </>
  );
};

export default Email;
