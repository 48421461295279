import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../Inputs';

import { toTitleCase } from '../../../../modules/utils/toTitleCase';
import { t } from '../../../../service/localization/i18n';
import { inputWrapperStyles } from '../helpers/styles';

const StreetAddress = () => {
  const name = 'AddressLine';
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper name={name} label={t('Street Address')} style={inputWrapperStyles}>
          <InputText
            value={field.value}
            onChange={(e) => {
              setValue(name, toTitleCase(e.target.value), { shouldDirty: true });
            }}
          />
        </InputWrapper>
      )}
    />
  );
};

export default StreetAddress;
