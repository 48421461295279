import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CatalogsContext } from '../../../modules/contexts/contexts';
import { t } from '../../../service/localization/i18n';

const CatalogsProvider = (props) => {
  const slideMenuRef = useRef(null);
  const breadcrumbRef = useRef(null);
  const sideBarRef = useRef(null);
  const searchRef = useRef(null);
  const activeTab = props.activeTab || props.catalogTabs?.[props.activeTabIndex];
  const codeList = activeTab?.defaultTableData || [];
  const { catalogCategories } = useSelector((state) => state.claims);
  const categoryType = activeTab?.categoryType;
  const currentCategory = catalogCategories?.[categoryType];
  const isMobile = window.innerWidth < 1024;

  const [catalogsState, setCatalogsState] = useState({
    activeTab,
    activeSubTabIndex: 0,
    searchValue: '',

    trail: [],
    drillDown: [],
    tableData: { page: 0, codeList },
    menuModel: [],
    categoryValue: '',
    mobileContent: 'menu',
    searchLoader: false,
    isLoadingTableData: false,
    isLoadingDataScrollerTable: false,
    sideBarCurrentHeight: false,
    showSearch: false,
    setFocusToSearch: false,
    emptyMessage: t('No_records_found'),
    currentPage: 0
  });

  // Update table data for custom tab layout
  useEffect(() => {
    if (activeTab?.defaultTableData) {
      setCatalogsState((prevState) => ({
        ...prevState,
        tableData: { ...prevState.tableData, codeList: activeTab?.defaultTableData }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab?.defaultTableData]);

  return (
    <CatalogsContext.Provider
      value={{
        ...props,
        catalogsState: { ...catalogsState, currentCategory },
        setCatalogsState,
        slideMenuRef,
        breadcrumbRef,
        sideBarRef,
        searchRef,
        isMobile
      }}
    >
      {props.children}
    </CatalogsContext.Provider>
  );
};

export default CatalogsProvider;
