import React from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from '../../routes/routes';

const withAuthCondition = (Component, conditionFn) => {
  return (props) => {
    const isAllowed = conditionFn();
    return isAllowed ? <Component {...props} /> : <Redirect to={routes.signIn.path} />;
  };
};
export default withAuthCondition;
