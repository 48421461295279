import React from 'react';
import ControlledZip from '../../../Inputs/Zip/ControlledZip';
import { patientInputWrapperStyles } from '../helpers/styles';
import { InputWrapper } from '../../../Inputs';
import { useFormContext } from 'react-hook-form';
import { t } from '../../../../service/localization/i18n';

const ZipCode = () => {
  const {
    formState: { errors }
  } = useFormContext();
  const errorMessage = errors?.Zip?.message;

  return (
    <InputWrapper name="Zip" label={t('Postal_Code')} errorMessage={errorMessage} wrapperStyle={patientInputWrapperStyles}>
      <ControlledZip />
    </InputWrapper>
  );
};

export default ZipCode;
