import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

const ClinicBusinessNumber = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <InputWrapper name="clinicBusinessNumber" label="Clinic Business Number" errorMessage={errors.clinicBusinessNumber?.message}>
        <Controller
          name="clinicBusinessNumber"
          control={control}
          rules={{ maxLength: { value: 30, message: 'This field must contain no more than 30 characters.' } }}
          render={({ field }) => (
            <InputText
              {...field}
              id="clinicBusinessNumber"
              data-testid="clinicBusinessNumber"
              className={classNames({ 'p-invalid': errors.clinicBusinessNumber })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default ClinicBusinessNumber;
