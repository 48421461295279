import React, { useEffect, useState } from 'react';
import CashFlowCard from './CashFlowCard';
import CashFlowLineChart from './CashFlowLineChart';
import { getCashFlow } from '../../../cashFlow/actions/cashFlow.axtion.creators';
import { useCashFlowCards } from './useCashFlowCards';

const Last7DaysCard = () => {
  const { onCardClick } = useCashFlowCards();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const responseData = await getCashFlow({ type: 2 });
    setLoading(false);
    setData(responseData);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => onCardClick(7);

  return (
    <CashFlowCard title="Last 7 days" subtitle="Billed (CAD)" data={data} loading={loading} onClick={onClick}>
      <CashFlowLineChart data={data} />
    </CashFlowCard>
  );
};

export default Last7DaysCard;
