import React from 'react';

import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../../components/Inputs';

const BillingSoftware = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <InputWrapper
        style={{ height: 'auto', width: '100%', marginBottom: '15px' }}
        name="billingSoftware"
        label={'What billing software did you use before?'}
        errorMessage={errors.billingSoftware?.message}
      >
        <Controller
          name="billingSoftware"
          control={control}
          rules={{
            maxLength: { value: 100, message: 'Max 100 characters' },
            pattern: {
              value: /^[A-Za-z0-9' .()\-\s]+$/,
              message: 'Only letters, numbers, spaces, apostrophes, hyphens, dots, and brackets are allowed'
            }
          }}
          render={({ field }) => <InputText {...field} id="billingSoftware" data-testid="billingSoftware-testid" />}
        />
      </InputWrapper>
    </>
  );
};

export default BillingSoftware;
