import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormProvider } from 'react-hook-form';
import { Button } from 'primereact/button';

import UsernameStep from './Steps/UsernameStep';
import PasswordStep from './Steps/PasswordStep';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';

import { useSingIn } from './hooks/useSignIn';
import { setToastMessage } from '../../../core/actions/core.action.creators';

const SignIn = () => {
  const methods = useSingIn();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const { isMobile } = useSelector((state) => state.core.window);

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isSafari =
    isIOS &&
    navigator.vendor &&
    navigator.vendor.includes('Apple') &&
    !navigator.userAgent.includes('CriOS') && // Excludes Chrome on iOS
    !navigator.userAgent.includes('FxiOS'); // Excludes Firefox on iOS

  const [isStandalone, setIsStandalone] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const standalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
    setIsStandalone(standalone);

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // if (isIOS) {
    //   if (!isSafari) {
    //     dispatch(
    //       setToastMessage({
    //         type: 'info',
    //         lifeTime: 5000,
    //         message: 'Please use Safari to install the app on your iPhone.'
    //       })
    //     );
    //   }
    // }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [dispatch, isIOS, isSafari]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      console.log(outcome === 'accepted' ? 'App installed' : 'App installation dismissed');
      setDeferredPrompt(null); // Clear the deferred prompt after use
    }
  };

  return (
    <AuthFormWrap footerContent={true}>
      <div className="m-0 my-4 text-800 w-full text-center font-bold text-3xl">Welcome to Claim Manager</div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(methods.onSubmit)} name="SignIn">
          <div className="flex flex-column gap-3">
            {methods.signInState.step === 1 && <UsernameStep />}
            {methods.signInState.step === 2 && <PasswordStep />}

            {/* Show the install button only for non-iOS mobile devices */}

            {!isStandalone && isMobile && !isIOS && deferredPrompt && (
              <Button
                type="button"
                onClick={handleInstallClick}
                className="install-button p-button-outlined my-3"
                style={{ width: '100%', justifyContent: 'center' }}
              >
                Install App
              </Button>
            )}

            {/* Show a custom message for iOS users */}
            {!isStandalone && isMobile && isIOS && (
              <div className="ios-install-prompt my-3">
                <p>
                  To install this app on your iPhone, tap <strong>Share</strong> and then <strong>Add to Home Screen</strong>.
                </p>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </AuthFormWrap>
  );
};

export default SignIn;
