import React from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../../components/Inputs';
import { bureauVerify } from '../../actions/auth.actions.creators';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';
import { useAuth } from '../../../../AuthProvider';
import { useAuthVerifications } from '../../hooks/useAuthVerifications';

const BureauVerify = () => {
  const { logout, bureauLogin } = useAuth();
  const { userVerifyRequest, logoutRequest, gettingAffiliates, details: user } = useSelector((state) => state.user);
  const { noSecretQuestions, phoneNotVerified, termsNotAccepted } = useAuthVerifications();
  const inputLength = 4;

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      code: ''
    }
  });

  const onSubmit = async (data) => {
    try {
      const result = await bureauVerify(data.code);
      reset();

      if (!result) return;

      if (!user.HaveSecretQuestions) return noSecretQuestions();
      if (!user.PhoneVerified) return phoneNotVerified(user);
      if (!user.TermsAccepted) return termsNotAccepted();

      bureauLogin(user);
    } catch (error) {
      console.error('Error verifying bureau:', error);
      // Handle the error appropriately, e.g., show a notification
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="code"
        control={control}
        rules={{
          required: t('Mandatory_field.1'),
          minLength: {
            value: inputLength,
            message: 'Minimum length is 4 characters'
          }
        }}
        render={({ field, fieldState }) => (
          <InputWrapper name="code" label="Code" required errorMessage={errors?.code?.message}>
            <InputText
              id={field.name}
              {...field}
              autoFocus
              keyfilter="int"
              maxLength={inputLength}
              className={cx({ 'p-invalid': fieldState.error })}
            />
          </InputWrapper>
        )}
      />

      <div className="flex gap-3 mt-2 h-3rem">
        <Button
          id="submit"
          className="w-6"
          label={t('Submit')}
          type="submit"
          loading={userVerifyRequest || gettingAffiliates}
          disabled={logoutRequest}
        />
        <Button
          id="cancel"
          className="p-button-outlined w-6"
          label={t('Cancel')}
          type="button"
          disabled={logoutRequest || userVerifyRequest}
          onClick={() => logout()}
        />
      </div>
    </form>
  );
};

export default BureauVerify;
