import React from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import DatePicker from './DatePicker';
import CalendarDateTemplete from './CalendarDateTemplete';
import moment from 'moment';
import { compact } from 'lodash';

// interface = {
//  value: Date,
//  input: {
//     name: string,
//     label: string
//  },
//  onChange: (value: Date) => void,

//  ref?: ref,
//  id?: string,
//  errorMessage?: string,
//  yearRange?: string,
//  selectionMode?: "single" | "multiple",
//  required?: boolean,
//  disabled?: boolean,
//  hideLabel?: boolean,
//  isInvalid?: boolean,
//  showOnFocus?: boolean,
//  readOnlyInput?: boolean,
//  status?: number | string,
//  lableContent?: () => JSX.Element | JSX.Element
//  labelOptionalContent?: () => JSX.Element | JSX.Element
//  footerContent?: () => JSX.Element | JSX.Element
//  onBlur?: (value: Date) => void,
// };

const WrappedDatePicker = React.forwardRef(({ input, required = false, selectionMode = 'single', ...props }, ref) => {
  const name = input.name;
  const errorMessage = props.errorMessage;
  const isInvalid = props.isInvalid ? props.isInvalid : errorMessage && required;
  const dateTemplate = (data) => <CalendarDateTemplete data={data} />;

  const getValue = () => {
    if (selectionMode === 'single') {
      return moment(props.value).isValid() ? moment(props.value).toDate() : null;
    }

    if (selectionMode === 'multiple' && Array.isArray(props.value)) {
      const dates = props.value?.map((i) => (moment(i).isValid() ? moment(i).toDate() : null));
      return compact(dates);
    }
  };

  return (
    <InputWrapper
      name={name}
      label={input.label}
      required={required}
      hideLabel={props.hideLabel}
      errorMessage={errorMessage}
      lableContent={props.lableContent}
      labelOptionalContent={props.labelOptionalContent}
      footerContent={props.footerContent}
    >
      <DatePicker
        ref={ref}
        id={props.id || name}
        name={name}
        value={getValue()}
        isInvalid={isInvalid}
        status={props.status}
        showOnFocus={props.showOnFocus}
        readOnlyInput={props.readOnlyInput}
        selectionMode={selectionMode}
        yearRange={props.yearRange}
        disabled={props.disabled}
        dateTemplate={dateTemplate}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </InputWrapper>
  );
});

export default WrappedDatePicker;
