import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import cx from 'classnames';
import './PinCode.scss';

// interface PinCodeProps {
//   onCancel: () => void;
//   onSubmit: (data: { pin: string }) => Promise<void> | void;

//   onBack?: () => void;
//   title?: string;
//   subtitle?: string;
//   submitButtonLabel?: string;
// }

const PinCode = ({ title, subtitle, submitButtonLabel, onCancel, onBack, onSubmit }) => {
  const limit = 6;
  const numbers = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['C', '0', 'D']
  ];

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    watch,
    reset
  } = useForm({
    defaultValues: {
      pin: ''
    }
  });

  const onSubmitPin = async (data) => {
    await onSubmit(data);
    reset();
  };

  const pinValue = watch('pin');

  const handleNumberClick = (num) => {
    if (num === 'C') return handleClear();
    if (num === 'D') return handleDeleteLast();

    // Append number to the pin value if less than 6 digits
    if (pinValue.length < limit) {
      setValue('pin', pinValue + num);
    }
  };

  const handleClear = () => {
    setValue('pin', '');
  };

  const handleDeleteLast = () => {
    setValue('pin', pinValue.slice(0, -1)); // Remove the last character from pin
  };

  return (
    <form onSubmit={handleSubmit(onSubmitPin)} className="flex flex-column align-items-center gap-5">
      {(title || subtitle) && (
        <div className="flex flex-column gap-2">
          {title && <div className="text-2xl font-bold text-center">{title}</div>}
          {subtitle && <div className="text-lg text-center">{subtitle}</div>}
        </div>
      )}

      {/* Custom dot display */}
      <div className="flex gap-5">
        {[...Array(limit)].map((_, i) => (
          <span
            key={i}
            className={cx('w-1rem h-1rem border-circle', {
              'bg-primary': i < pinValue.length, // Filled dots with primary color
              'bg-gray-400': i >= pinValue.length // Empty dots with neutral color
            })}
          />
        ))}
      </div>

      <div className="flex flex-column gap-3">
        {numbers.map((row, rowIndex) => (
          <div key={rowIndex} className="flex gap-3">
            {row.map((num, colIndex) => (
              <Button
                type="button"
                className="pin-icon-button w-4rem h-4rem border-circle text-4xl"
                icon={num === 'D' && 'pi pi-arrow-left'}
                key={colIndex}
                label={num !== 'D' && num}
                rounded
                outlined
                onClick={() => handleNumberClick(num)}
                disabled={(num === 'C' || num === 'D') && !pinValue}
              />
            ))}
          </div>
        ))}
      </div>

      <div className="flex justify-content-center gap-3 w-full mt-1">
        {onBack && <Button className="w-full max-w-8rem" label="Back" type="button" outlined onClick={onBack} />}
        <Button className="w-full max-w-8rem" label="Cancel" type="button" outlined onClick={onCancel} />
        <Button
          className="w-full max-w-8rem"
          label={submitButtonLabel || 'Save'}
          type="submit"
          disabled={pinValue?.length !== limit}
          loading={isSubmitting}
        />
      </div>
    </form>
  );
};

export default PinCode;
