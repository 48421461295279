import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';

const ConfirmPassword = () => {
  const {
    control,
    formState: { errors },
    getValues,
    clearErrors
  } = useFormContext();

  const preventDefault = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      <InputWrapper name="confirmPassword" label="Confirm Password" required errorMessage={errors.confirmPassword?.message}>
        <Controller
          name="confirmPassword"
          control={control}
          rules={{
            required: 'Confirm Password is required',
            validate: (value) => value === getValues('password') || 'Passwords must match'
          }}
          render={({ field }) => (
            <Password
              id="confirmPassword"
              data-testid="confirmPassword-testid"
              {...field}
              toggleMask
              feedback={false}
              onCopy={(e) => {
                preventDefault(e);
              }}
              onPaste={(e) => {
                preventDefault(e);
              }}
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(value);
                value && errors.confirmPassword?.message && clearErrors('confirmPassword');
              }}
              className={classNames({ 'p-invalid': errors.confirmPassword })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default ConfirmPassword;
