import React, { useEffect, createContext, useContext, useState } from 'react';
import { logout as logoutAction, updateUser, getUserProfileData } from './modules/auth/actions/auth.actions.creators';
import { setTokenToSessionStorage } from './modules/utils/setTokenToSessionStorage';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from './routes/routes';
import { shouldUsePin } from './modules/utils/pinCode';
import { sessionStorageKeys } from './modules/config/localStorageKeysConfig';
import { getFromSessionStorage } from './modules/utils/getFromSessionStorage';
import { v4 as uuidv4 } from 'uuid';
import { resetState } from './store';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const currentTabId = sessionStorage.getItem(sessionStorageKeys.tabIdKey);
  // Check if token exists for this tab
  const tabTokenKey = `${currentTabId}_token`;
  const token = sessionStorage.getItem(tabTokenKey);
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  useEffect(() => {
    // Set tabId to sessionStorage
    const tabId = sessionStorage.getItem(sessionStorageKeys.tabIdKey);
    if (!tabId) {
      const id = uuidv4();
      sessionStorage.setItem(sessionStorageKeys.tabIdKey, id);
    }

    // Logout user if `keepSignedIn` is false
    const handleLogoutOnUnload = () => {
      const keepSignedIn = sessionStorage.getItem(sessionStorageKeys.keepSignedIn); // Check if keepSignedIn is in sessionStorage
      if (!keepSignedIn) return handleLogout();
      return;
    };

    if (isAuthenticated && !user?.JwtToken) {
      handleLogout();
    } else {
      window.addEventListener('unload', handleLogoutOnUnload);
    }

    return () => {
      window.removeEventListener('unload', handleLogoutOnUnload);
    };
  }, []);

  const handleLogout = () => {
    logoutAction(); // API request
    setIsAuthenticated(false);
    resetAllStates();
    sessionStorage.removeItem(sessionStorageKeys.bureauUser);
    history.replace(shouldUsePin() ? routes.signInWithPin.path : routes.signIn.path);
  };

  const login = async (user, { redirectTo, callback } = {}) => {
    await getUserProfileData(user);

    // Set jwtToken to session storage
    setTokenToSessionStorage(user.JwtToken);
    // Update user details in store
    dispatch(updateUser(user));
    setIsAuthenticated(true);
    history.replace(redirectTo || routes.dashboard.path);
    // !!!WARNING!!! This callback should be called after router navigation! Important for bureau users.
    callback?.();
  };

  const bureauLogin = (user) => {
    sessionStorage.setItem(sessionStorageKeys.bureauUser, JSON.stringify(user));
    return history.replace(routes.affiliates.path);
  };

  const affiliateLogout = () => {
    logoutAction(); // API request
    const bureauUser = getFromSessionStorage(sessionStorageKeys.bureauUser);
    setIsAuthenticated(false);
    resetAllStates();
    dispatch(updateUser(bureauUser));
    history.replace(routes.affiliates.path);
  };

  const resetAllStates = () => {
    const tabId = sessionStorage.getItem(sessionStorageKeys.tabIdKey);
    const tabToken = `${tabId}_token`;
    sessionStorage.removeItem(tabToken);
    sessionStorage.removeItem(sessionStorageKeys.keepSignedIn);
    localStorage.removeItem('persist:root');
    dispatch(resetState()); // Reset redux state
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout: handleLogout,
        bureauLogin,
        affiliateLogout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const props = useContext(AuthContext);

  if (!props) {
    throw new Error('No AuthProvider context found');
  }

  return props;
};
