import React, { useState } from 'react';
import PayeeEmail from '../../../../../components/Inputs/Payee/PayeeEmail';
import { usePrivateInvoicePrefs } from '../hooks/usePrivateInvoicePrefs';

const PrivateInvoiceEmail = () => {
  const { payeePrefsEmail } = usePrivateInvoicePrefs();
  const [value, setValue] = useState(payeePrefsEmail);

  const onChange = (e) => {
    setValue(e.value);
  };

  return <PayeeEmail value={value} savePrefsOnChange onChange={onChange} />;
};

export default PrivateInvoiceEmail;
