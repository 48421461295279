export default {
  FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  GET_AFFILIATES: 'GET_AFFILIATES',
  GETTING_AFFILIATES: 'GETTING_AFFILIATES',

  FETCH_USER_REQUEST: 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',

  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',

  UPDATE_USER_FIELD: 'UPDATE_USER_FIELD',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGIN_VIEW: 'LOGIN_VIEW',
  REGISTER_TYPE: 'REGISTER_TYPE',
  REDIRECT_REQUEST: 'REDIRECT_REQUEST',
  USER_VERIFY: 'USER_VERIFY',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  TERMS_OF_USE: 'TERMS_OF_USE',
  SET_SECRET_QUESTIONS: 'SET_SECRET_QUESTIONS',
  SET_LICENCINGS_BODIES_LIST: 'SET_LICENCINGS_BODIES_LIST',
  SET_PRACTICE_TYPE_LIST: 'SET_PRACTICE_TYPE_LIST',
  UDATE_REGISTRATION_FORM_STATE: 'UDATE_REGISTRATION_FORM_STATE'
};
