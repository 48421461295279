import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AutoComplete } from 'primereact/autocomplete';

import { getPractitionerByGuid, getPractitionersList } from '../../../modules/utils/getPractitioner';
import { NA_DoctorGuid } from '../../../modules/config/defaultValuesConfig';
import { dropdownScrollHeight } from '../../../modules/config/stylesConfig';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { t } from '../../../service/localization/i18n';
import { validate } from 'uuid';
import cx from 'classnames';

// interface IPractitioner {
//   value: string, // pass valid Practitioner guid
//   onChange: ({ value: string } | { value: { DoctorGuid: string } }) => void,

//   practitionersList?: { label: string, DoctorGuid: string }[] // List of practitioners
//   name?: string, // detect if need to add "N/A" opption
//   onBlur?: (practitionerGuid: string) => void,
//   onSelect?: (practitionerGuid: string) => void,
//   ref?: ref,
//   registeredOnly?: boolean, // detect if need to show registered practitioners only
//   naOption?: boolean, // detect if form is new to add N/A to the selection list of practitioners
//   allOption?: boolean, // The label for the "N/A" option in the selection list, if provided.
//   multipleOption?: boolean, // detect if need to add "Multiple practitioners" opption
//   isInvalid?: boolean,
//   autoFocus?: boolean,
//   disabled?: boolean,
// }

const Practitioner = React.forwardRef((props, ref) => {
  const practitioners = useSelector((state) => state.clinic.members);
  const [allSuggestions, setAllSuggestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionsFound, setIsSuggestionsFound] = useState(true);

  useEffect(() => {
    if (practitioners && Array.isArray(practitioners) && allSuggestions.length === 0) {
      const practitionersList =
        props?.practitionersList ||
        getPractitionersList({
          naOption: props.naOption,
          allOption: props.allOption,
          multipleOption: props.multipleOption,
          registeredOnly: props.registeredOnly
        });

      setAllSuggestions(practitionersList);
      setSuggestions(practitionersList);
    }
  }, [practitioners, props.practitionersList, props.naOption, props.allOption, props.multipleOption, allSuggestions.length, props.registeredOnly]);

  const getCurrentPractitioner = () => {
    const isString = typeof props.value === 'string';
    // const isObject = typeof props.value === 'object';

    if (isString) {
      const currentPractitioner = allSuggestions?.find((i) => i.DoctorGuid?.toUpperCase() === props.value?.toUpperCase());
      return currentPractitioner;
    }

    // if (isObject) return props.value;
  };

  const selected = getCurrentPractitioner();
  const value = selected?.label || getPractitionerByGuid(props.value)?.label || '';

  const search = (e) => {
    let _filtered;
    if (!e.query.trim().length) {
      setIsSuggestionsFound(true);
      _filtered = [...allSuggestions];
    } else {
      const criteria = e.query.toLowerCase();
      _filtered = allSuggestions.filter((i) => {
        return (
          i.FirstName?.toLowerCase().startsWith(criteria) ||
          i.LastName?.toLowerCase().startsWith(criteria) ||
          i.PractitionerNumber?.toLowerCase().startsWith(criteria)
        );
      });
      const isFound = _filtered?.length;
      setIsSuggestionsFound(isFound);
    }

    setSuggestions(_filtered);
  };

  const handleChange = (e) => {
    if (e.value?.DoctorGuid === props.value) return;
    props.onChange({ ...e, value: e?.value || '' });
  };

  const handleSelect = (e) => {
    const practitionerGuid = e.value?.DoctorGuid;
    props.onSelect && props.onSelect(practitionerGuid);
  };

  const onFocus = (e) => {
    e.target.select();
  };

  const onBlur = (e) => {
    if (props.onBlur) {
      const isValidPractitioner = validate(props.value);
      const practitionerGuid = isValidPractitioner ? props.value : NA_DoctorGuid;
      props.onBlur(practitionerGuid);
    }

    setTimeout(() => {
      if (!e.target.value) {
        !isSuggestionsFound && setIsSuggestionsFound(true);
        props.onChange({ ...e, value: props.naOption ? NA_DoctorGuid : '' });
      }
    }, 500);
  };

  return (
    <>
      <AutoComplete
        id={elementIDs.practitionerPickerDropdown}
        inputId={elementIDs.practitionerPickerDropdownInput}
        className={cx('w-full', { 'p-invalid': props.isInvalid })}
        inputRef={ref}
        name={props.name || 'practitioner'}
        value={value}
        suggestions={suggestions}
        completeMethod={search}
        type="text"
        field="label"
        dropdown
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        autoHighlight
        forceSelection
        scrollHeight={dropdownScrollHeight()}
        onChange={handleChange}
        onSelect={handleSelect}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      {!isSuggestionsFound && <span className="p-error text-sm">{t('No_results_found')}</span>}
    </>
  );
});

export default Practitioner;
