import React from 'react';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { elementIDs } from '../../config/elementIDsConfig';
import { t } from '../../../service/localization/i18n';

export const requiredErrorMessage = t('Mandatory_field.1');
export const noResultsErrorMessage = t('No_results_found');
export const maxLimitErrorMessage = t('Limit_one_patient');
export const invalidEntryMessage = t('Invalid_entry');

export const requiredError = (
  <span className="mt-1 block p-error small-text" style={{ width: 'max-content' }}>
    {requiredErrorMessage}
  </span>
);

export const noResultsError = <span className="mt-1 block p-error small-text">{t('No_results_found')}</span>;

export const maxLimitError = <span className="mt-1 block p-error small-text">{t('Limit_one_patient')}</span>;

export const invalidEntry = <span className="mt-1 block p-error small-text">{t('Invalid_entry')}</span>;

export const selectPractitionerError = (
  <span className="mt-1 block p-error small-text" style={{ width: 'max-content' }}>
    {t('Please_select_practitioner')}
  </span>
);

export const addNewItem = ({ onClick, label, showNoResults, showRequired, limitError, icon = true, buttonId, errorMessage }) => {
  const error = () => {
    if (errorMessage) return errorMessage();
    return showRequired ? requiredError : showNoResults ? noResultsError : limitError ? maxLimitError : null;
  };

  return (
    <div className="flex col-12 p-0">
      <div id={elementIDs.inputErrorText} className="col-6 p-0 mt-1">
        {error()}
      </div>

      <div className="flex justify-content-end col-6 p-0">
        <Button
          id={buttonId || elementIDs.addNewPatient}
          tabIndex={-1}
          icon={icon ? 'pi pi-plus' : ''}
          label={label}
          style={{ height: '20px !important' }}
          className="p-button-text py-1 px-1 mt-1 small-text"
          type="button"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export const switchToMultipleCalendar = (errorMessage, checked, setChecked) => {
  return (
    <div className="flex align-items-center col-12 p-0 mt-2">
      <div className="col-6 p-0">{errorMessage}</div>

      <div className="flex justify-content-end col-6 p-0">
        <div className="mr-3 small-text">{t('Bill_multiple_dates')}</div>
        <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
      </div>
    </div>
  );
};
