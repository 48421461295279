import { useSelector } from 'react-redux';
import withAuthCondition from './withAuthCondition';
import { expiredPasswordStatus } from '../config/userStatuses';

const withRequireJwtToken = (Component) => {
  return withAuthCondition(Component, () => {
    const user = useSelector((state) => state.user.details);
    return Boolean((user && user.JwtToken) || (user && user.UserStatus === expiredPasswordStatus) || (user && user.SessionGuid));
  });
};
export default withRequireJwtToken;
