import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';

import Context from '../../../../Context';
import AddServiceButton from './actionButtons/AddServiceButton';
import DeleteConfirmation from '../../../common/components/DeleteConfirmation';

import { updatePrivateRecord } from '../../actions/claims.action.creators';
import { onEditorInit, onEditorCancel, onEditorSubmit } from './reviewPanelHelpers/editCell';
import { rowExpansionTemplate } from './reviewPanelHelpers/rowExpansionTemplate';
import { columns } from './reviewPanelHelpers/columns';
import { inputs } from './claimDetailsHelpers/inputs';
import { footerGroup } from './reviewPanelHelpers/footerGroup';
import { multipleDoctorGuid } from '../../../config/defaultValuesConfig';
import { formattedService } from './claimDetailsHelpers/formatServices';
import { t } from '../../../../service/localization/i18n';
import classes from './ReviewPanel.module.scss';
import cx from 'classnames';

const ReviewPanel = () => {
  const dispatch = useDispatch();
  const { mobileView, isMobileOnly, clientWidth, isLandscape } = useSelector((state) => state.core.window);
  const { privateRecord, records, setInitialRecords, setIsReviewDirty, isPractitionerIsEmpty, user, isInvoicePatientForBalance } =
    useContext(Context);

  const [editingCellRows, setEditingCellRows] = useState([]);
  const [claim, setClaim] = useState({});
  const [expandedRows, setExpandedRows] = useState({});
  const [showNoteInput, setShowNoteInput] = useState({});
  const [isMultiplePractitioners, setIsMultiplePractitioners] = useState(-2);
  const [displayConfirmationRP, setDisplayConfirmationRP] = useState(-2);

  const hideElems = false;
  const isMobileWithLowRezolution = isMobileOnly && isLandscape && clientWidth < 730;

  // set isMultiplePractitioners
  useEffect(() => {
    privateRecord?.DoctorGuid === multipleDoctorGuid || privateRecord?.DoctorGuid === user.details.DCNGuid
      ? setIsMultiplePractitioners(true)
      : setIsMultiplePractitioners(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateRecord?.DoctorGuid]);

  // Set records list for data table
  useEffect(() => {
    if (privateRecord.ServicesList?.length) setInitialRecords(privateRecord.ServicesList);
    if (!privateRecord.ServicesList?.length) setInitialRecords([formattedService()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateRecord.ServicesList]);

  useEffect(() => {
    // [KS] Scroll to the top
    let _expandedRows = {};
    window[`scrollTo`]({ top: 0 }); // scroll page to the top
    records?.forEach((i) => {
      if (i.id === '-1') return;
      if (i.id !== '-1') _expandedRows[`${i.id}`] = true;
    });
    setExpandedRows(_expandedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddNote = (data, bool) => {
    setExpandedRows({
      ...expandedRows,
      [data.id]: true
    });
    setShowNoteInput({
      ...showNoteInput,
      [data.id]: bool
    });
  };

  const setRecords = (serviceList) => {
    const filteredList = serviceList?.filter((i) => i.id !== '-1');
    const privateServiceCodes = filteredList?.map((x) => x.value);
    dispatch(
      updatePrivateRecord({
        ...privateRecord,
        [inputs().privateService.name]: privateServiceCodes,
        [inputs().privateService.descriptionName]: filteredList
      })
    );
  };

  const deleteInvoice = () => {
    const updatedServicesCodes = privateRecord[inputs().privateService.descriptionName].filter((i) => i.value !== claim.id);
    const privateServiceCodes = updatedServicesCodes?.map((x) => x.value);

    dispatch(
      updatePrivateRecord({
        [inputs().privateService.name]: privateServiceCodes,
        [inputs().privateService.descriptionName]: updatedServicesCodes
      })
    );
    setDisplayConfirmationRP(-2);
    setClaim({});
  };

  const rowExpantionParams = { records, isMobileWithLowRezolution, showNoteInput, setRecords, onAddNote, setIsReviewDirty };
  const columnsParams = {
    records,
    setRecords,
    setIsReviewDirty,
    hideElems,
    isMobileOnly,
    onAddNote,
    setClaim,
    setDisplayConfirmationRP,
    isPractitionerIsEmpty,
    isMultiplePractitioners,
    mobileView,
    isInvoicePatientForBalance
  };

  return (
    <div className="mb-4">
      <Divider />

      <div className="datatable-responsive-layout">
        <DataTable
          className={cx(
            'editable-cells-table p-datatable-responsive-layout',
            mobileView ? classes.mobileDataTableRoot : classes.desktopDataTableRoot
          )}
          dataKey="id"
          editMode="cell"
          sortField="index"
          rowGroupMode="RowSpan"
          value={records}
          editable={false}
          expandedRows={mobileView ? null : expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={(e) => rowExpansionTemplate(e, rowExpantionParams)}
          footerColumnGroup={footerGroup(records, isMultiplePractitioners)}
        >
          {!mobileView && <Column expander style={{ width: '4em' }} />}

          {columns(columnsParams)?.map((col) => {
            return (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                style={{ ...col.style, position: 'relative' }}
                body={col.body}
                editor={isInvoicePatientForBalance ? undefined : col.editor}
                onEditorInit={(e) => onEditorInit(e, editingCellRows, setEditingCellRows, records)}
                onEditorCancel={(e) => onEditorCancel(e, editingCellRows, setEditingCellRows, records, setRecords)}
                onEditorSubmit={(e) => onEditorSubmit(e, editingCellRows, setEditingCellRows)}
              />
            );
          })}
        </DataTable>

        {mobileView && <AddServiceButton />}
      </div>

      {displayConfirmationRP === 2 && (
        <DeleteConfirmation
          visible={displayConfirmationRP === 2}
          message={t('Are_you_sure_delete_this_record')}
          header={t('Delete_Claim')}
          icon="pi pi-exclamation-triangle"
          acceptLabel={t('Yes')}
          rejectLabel={t('No')}
          accept={deleteInvoice}
          reject={() => setDisplayConfirmationRP(-2)}
          onHide={() => setDisplayConfirmationRP(-2)}
          baseZIndex={1000}
        />
      )}
    </div>
  );
};

export default ReviewPanel;
