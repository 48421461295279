import React from 'react';
import { CONTACT_PHONE_7 } from '../../../config';

const AuthFormWrap = (props) => {
  return (
    <div className="flex flex-column justify-content-center align-items-center min-h-screen sm:surface-ground bg-white">
      <div className="flex flex-column sm:justify-content-center w-full" style={props.style || { maxWidth: '500px' }}>
        <div className="my-0 sm:my-3 cm-card">
          <AuthContent {...props} />
        </div>
      </div>
    </div>
  );
};

export default AuthFormWrap;

const AuthContent = ({ children, footerContent }) => {
  const footer = footerContent ? (
    <div>
      Access is restricted to registered and authorized users only. If you haven't registered yet, please click on the Register button or call us at{' '}
      <a href={`tel:${CONTACT_PHONE_7}`}>{CONTACT_PHONE_7}</a> for assistance.
    </div>
  ) : (
    <div>
      Access is restricted to authorized users only. If you needs assistance, please call us at{' '}
      <a href={`tel:${CONTACT_PHONE_7}`}>{CONTACT_PHONE_7}</a> or email us at info@claimmanager.ca.
    </div>
  );
  return (
    <>
      <div className="flex flex-column pt-4 gap-3 text-justify" style={{ padding: '20px 35px' }}>
        <div className="w-full text-center">
          <img src="/assets/layout/images/logo-cm-v5.png" alt="logo" style={{ height: '120px' }} />
        </div>
        <div className="text-center text-600 w-full text-base sm:text-base">Claim Manager: Discover the Bright Side of Billing!</div>
        {children}
        {footer}
      </div>
    </>
  );
};
