import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import PageWrap from '../../../components/Wraps/PageWrap/PageWrap';
import HighlightSearchQuery from '../../../components/Misc/HighlightSearchQuery/HighlightSearchQuery';

import { getInstructionsWithAssets } from '../../contentful/apiRequests';
import { contentfulOptions } from '../../contentful/contentfulOptions';

const Instructions = () => {
  const { id } = useParams();
  const [instructions, setInstructions] = useState([]);
  const [assets, setAssets] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch instructions and assets
  useEffect(() => {
    const fetchInstructions = async () => {
      setLoading(true);
      try {
        const { items, assetsById } = await getInstructionsWithAssets();
        setInstructions(items);
        setAssets(assetsById);
      } catch (error) {
        console.error('Error fetching instructions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInstructions();
  }, []);

  // Sort instructions alphabetically
  const sortedInstructions = useMemo(
    () => (instructions ? [...instructions].sort((a, b) => a.fields.title.localeCompare(b.fields.title)) : []),
    [instructions]
  );

  // Filter instructions based on the search term
  const filteredInstructions = sortedInstructions.filter((instruction) => instruction.fields.title.toLowerCase().includes(searchTerm.toLowerCase()));

  // Set active tab based on the URL parameter
  useEffect(() => {
    if (instructions && id) {
      const index = sortedInstructions.findIndex((instruction) => instruction.fields.id === id);
      if (index !== -1) {
        setActiveIndex(index);
      }
    }
  }, [instructions, sortedInstructions, id]);

  if (loading) {
    return (
      <PageWrap>
        <div className="text-center text-lg">Loading instructions...</div>
      </PageWrap>
    );
  }

  if (!instructions.length) {
    return (
      <PageWrap>
        <div className="text-center text-lg">No instructions available.</div>
      </PageWrap>
    );
  }

  return (
    <PageWrap>
      <div className="flex flex-column gap-3 w-full" style={{ maxWidth: '800px' }}>
        <h3 className="m-0 text-center">Instructions</h3>

        <div className="p-inputgroup flex-1 pb-4">
          <span className="p-inputgroup-addon">
            <i className="pi pi-search"></i>
          </span>
          <InputText className="max-w-18rem" placeholder="Search" type="search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        </div>

        {filteredInstructions.length > 0 ? (
          <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            {filteredInstructions.map((instruction) => (
              <AccordionTab key={instruction.sys.id} header={<HighlightSearchQuery text={instruction.fields.title} query={searchTerm} />}>
                <div>{documentToReactComponents(instruction.fields.content, contentfulOptions(assets))}</div>
              </AccordionTab>
            ))}
          </Accordion>
        ) : (
          <div className="text-center text-lg text-gray-500">No instructions found matching your search.</div>
        )}
      </div>
    </PageWrap>
  );
};

export default Instructions;
