import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputWrapper } from '../../../Inputs';
import { Dropdown } from 'primereact/dropdown';
import { t } from '../../../../service/localization/i18n';
import { inputWrapperStyles } from '../helpers/styles';

const ContactType = ({ autoFocus }) => {
  const name = 'ContactType';
  const { control, setValue, clearErrors } = useFormContext();

  const options = [
    { label: t('Person'), value: 1 },
    { label: t('Company'), value: 2 }
  ];

  const onChange = (value, field) => {
    field.onChange(value);
    setValue('CompanyName', '');
    setValue('Comment', '');
    clearErrors(['CompanyName', 'Comment', 'FirstName', 'LastName']);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper name={name} label={t('Contact_Type')} style={inputWrapperStyles}>
          <Dropdown value={field.value} options={options} autoFocus={autoFocus} onChange={(e) => onChange(e.value, field)} />
        </InputWrapper>
      )}
    />
  );
};

export default ContactType;
