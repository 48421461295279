import axios from 'axios';
import { store } from '../../../store';
import { setToastMessage } from '../../core/actions/core.action.creators';
import { getApiBase, setAuthHeader } from '../../../config';
import { calculateFirst } from '../../utils/calculate';
import { t } from '../../../service/localization/i18n';

export const getDaysheetFiles = (data) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const { pageSize, pageNumber } = data || { pageNumber: 1, pageSize: 10 };

  return axios
    .get(`${getApiBase()}/upload/${DCNGuid}/${pageNumber ?? 1}/${pageSize ?? 10}`)
    .then((results) => {
      const page = results.data.page;
      const pageSize = results.data.pageSize;
      return {
        ...results.data,
        first: calculateFirst({ page, pageSize })
      };
    })
    .catch((error) => {
      console.log('!!!getDaysheetFiles', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const uploadDaysheet = async (file) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const userGuid = currentState?.user?.details?.DoctorGuid;

  try {
    const response = await axios.post(`${getApiBase()}/upload/${DCNGuid}/${userGuid}/report`, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    // Ensure HTTP status codes outside of 2xx range are treated as errors
    if (response.status < 200 || response.status >= 300) {
      throw new Error(`HTTP Error: ${response.status}`);
    }

    return response; // Resolve successfully if no error
  } catch (error) {
    // Log the error for debugging
    console.error('Error during uploadDaysheet:', error);

    // Re-throw the error to propagate it to the calling function
    throw error;
  }
};

export const updateStatus = (data) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .put(`${getApiBase()}/upload/${DCNGuid}`, data)
    .then((results) => results)
    .catch((error) => {
      console.log('!!!updateStatus', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};
