import React from 'react';
import { DatePicker, InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

const DateClaims = () => {
  const { control } = useFormContext();
  var today = new Date();

  const handleFocus = (event) => {
    if (!event.target.value) {
      event.target.value = 'MM/DD/YYYY'; // Set placeholder text when the field gains focus
    }
  };

  return (
    <>
      <InputWrapper name="dateClaims_input" label={'When was your most recent claims submission?'}>
        <Controller
          name="dateClaims"
          control={control}
          render={({ field }) => <DatePicker {...field} id="dateClaims_wrapper" inputId="dateClaims" maxDate={today} onFocus={handleFocus} />}
        />
      </InputWrapper>
    </>
  );
};

export default DateClaims;
