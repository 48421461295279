import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import SendByEmailDialog from './SendByEmailDialog';

import PaymenstDialog from '../../PaymenstDialog';
import RowClaimHistory from '../../RowClaimHistory';
import PrivateInvoicePreviewContent from './PrivateInvoicePreviewContent';
import DeleteConfirmation from '../../../../common/components/DeleteConfirmation';
import CommonConfirmDialog from '../../../../common/components/CommonConfirmDialog';

import { icons } from '../../../../config/stylesConfig';
import { trimLeftZeros } from '../../../../utils/trimLeftZeros';
import { onPrint } from '../../../../core/actions/core.action.creators';
import { addNewItemToArray } from '../../../../utils/addNewItemToArray';
import { printTypes } from '../../../../../components/Layouts/Print/helpers/printTypes';
import { archiveClaimRecord, deleteClaim, getClaimHistory } from '../../../actions/claims.action.creators';
import { onEditInvoiceClick, onReceivePayment, onSendViaEmail, openPatientProfile } from '../../../helpers/rowActions';
import { t } from '../../../../../service/localization/i18n';
import { startCase } from 'lodash';

import './PrivateInvoicePreview.scss';

const PrivateInvoicePreview = ({ data, onRecordPayment, onEditInvoice, onDeleteInvoice, onClose, showEditButton = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const optionsMenuRef = useRef(null);
  const { gettingClaimInfo } = useSelector((state) => state.claims);

  const [privateRecord, setPrivateRecord] = useState({});
  const [showPaymentsDialog, setShowPaymentsDialog] = useState(false);
  const [deleteClaimConfirm, setDeleteClaimConfirm] = useState(false);
  const [claimHistoryDialog, setClaimHistoryDialog] = useState(false);
  const [sendByEmailDialog, setSendByEmailDialog] = useState(false);
  const [archivedDialog, setArchivedDialog] = useState(false);
  const [claimHistory, setClaimHistory] = useState([]);

  // Set initial invoice details
  useEffect(() => {
    data && setPrivateRecord(data);
  }, [data]);

  const optionsMenuItems = () => {
    let commonItems = [
      {
        id: 'patients_profile',
        icon: icons.patient,
        label: t('Patient.1'),
        command: () => openPatientProfile(privateRecord?.PatientDetails?.PatientGuid, history)
      },

      {
        separator: true
      },

      {
        id: 'receivePayment',
        icon: icons.money,
        label: t('Receive_Payment'),
        command: () => handleRecordPayment()
      },

      {
        id: 'viewPayments',
        icon: 'pi pi-list',
        label: startCase(t('View_payments')),
        command: () => setShowPaymentsDialog(true)
      },

      {
        separator: true
      },

      {
        id: 'sendViaEmail',
        icon: 'pi pi-envelope',
        label: t('Send_by_email'),
        command: () => setSendByEmailDialog(true)
      },

      {
        id: 'print',
        icon: icons.print,
        label: t('Print'),
        command: () => dispatch(onPrint({ recordId: privateRecord.InvoiceGuid, printType: printTypes().privateInvoice.type }))
      },

      {
        separator: true
      },

      {
        id: 'delete',
        icon: icons.delete,
        label: t('Delete'),
        command: () => setDeleteClaimConfirm(true)
      }
    ];

    const paidWithAdjustment = {
      id: 'paidWithAdjustment',
      icon: 'pi pi-exclamation-circle',
      label: t('Paid_with_adjustment'),
      command: () => requestHistory()
    };

    const archived = {
      id: 'archived',
      icon: icons.archived,
      label: t('Archive'),
      command: () => setArchivedDialog(true)
    };

    const edit = {
      id: 'edit',
      icon: icons.edit,
      label: t('Edit'),
      command: () => handleEditPayment()
    };

    //show Edit button
    if (
      showEditButton ||
      (privateRecord?.FeeCode !== 'eClaims Balance' && privateRecord?.ClaimType === 'P' && !privateRecord?.Locked) // CMO-2248 - Private invoice : make non-editable invoices and/or service lines created in old UI
    ) {
      commonItems = addNewItemToArray(commonItems, 0, edit);
    }

    //show Archived button
    //Status === 6, Status === 3 - declined (not paid)
    if (privateRecord?.Status === 6 || privateRecord?.Status === 3) {
      commonItems = addNewItemToArray(commonItems, commonItems.length - 1, archived);
    }

    //show Paid With Adjustment
    if (privateRecord.RemDiff >= 1) {
      commonItems = addNewItemToArray(commonItems, 0, paidWithAdjustment);
    }

    return commonItems;
  };

  const mainButton = () => {
    const balanceDue = privateRecord ? privateRecord.FeeTotal - privateRecord.FeePaid : 0;

    if (balanceDue > 0) {
      return <Button className="p-button-outlined ml-3" label={t('Receive_Payment')} disabled={gettingClaimInfo} onClick={handleRecordPayment} />;
    }

    return <Button className="p-button-outlined ml-3" label={t('Email_Invoice')} disabled={gettingClaimInfo} onClick={onEmailPayment} />;
  };

  const onEmailPayment = () => onSendViaEmail({ claimNumber: privateRecord?.ClaimNumber, patientGuid: privateRecord?.PatientDetails?.PatientGuid });

  const handleRecordPayment = () => {
    if (onRecordPayment) return onRecordPayment();
    return onReceivePayment({ data: privateRecord, history });
  };

  const handleEditPayment = () => {
    if (onEditInvoice) return onEditInvoice();
    return onEditInvoiceClick({ data: privateRecord, history });
  };

  const handleDeletePayment = (record) => {
    return setPrivateRecord(record);
  };

  const handleClose = () => {
    if (onClose) return onClose();
    return history.goBack();
  };

  const requestHistory = () => {
    dispatch(
      getClaimHistory(privateRecord.InvoiceGuid, (response) => {
        setClaimHistory(response);
        setClaimHistoryDialog(true);
      })
    );
  };

  const onArchiveClick = () => {
    dispatch(archiveClaimRecord(privateRecord));
    setArchivedDialog(false);
  };

  const onHideDeleteConfirm = () => {
    setDeleteClaimConfirm(false);
  };

  const onAcceptDeleteClaim = () => {
    dispatch(
      deleteClaim({
        invoiceGuid: privateRecord?.InvoiceGuid,
        patientGuid: privateRecord?.PatientDetails?.PatientGuid,
        showOnDetailsView: true,
        callback: () => {
          if (onDeleteInvoice) return onDeleteInvoice();
          return history.goBack();
        }
      })
    );
  };

  const claimHistoryDialogFooter = <Button label="Close" icon="pi pi-check" onClick={() => setClaimHistoryDialog(false)} />;

  const deleteMessage = String.format(
    t('Are_you_sure_you_want_to_delete_invoice'),
    trimLeftZeros(privateRecord.RecordNo),
    `${privateRecord?.PatientDetails?.LastName}, ${privateRecord?.PatientDetails?.FirstName}`
  );

  const claimHistoryDialogHeader = `${t('Claim_History_for')} #${privateRecord.SequenceNumber} (${privateRecord?.PatientDetails?.LastName}, ${
    privateRecord?.PatientDetails?.FirstName
  })`;

  const archiveBodyText = String.format(
    t('Are_you_sure_you_want_to_archive_invoice'),
    privateRecord.RecordNo,
    `${privateRecord?.PatientDetails?.LastName}, ${privateRecord?.PatientDetails?.FirstName}`
  );

  return (
    <div className="flex w-full justify-content-center">
      <div className="privateInvoicePreviewWrap">
        {/* Action buttons */}
        <div className="flex justify-content-end align-items-center py-3 privateInvoicePreviewButtonsWrap">
          {mainButton()}

          <Button
            className="p-button-text p-button-rounded ml-3"
            icon="pi pi-ellipsis-v"
            aria-controls="options_popup_menu"
            aria-haspopup
            tooltip={t('More_options')}
            tooltipOptions={{ position: 'top' }}
            onClick={(event) => optionsMenuRef.current.toggle(event)}
          />
          <Menu id="options_popup_menu" ref={optionsMenuRef} popup model={optionsMenuItems()} />

          <Button
            className="p-button-text p-button-rounded ml-3"
            icon="pi pi-times"
            tooltip={t('Close')}
            tooltipOptions={{ position: 'top' }}
            onClick={handleClose}
          />
        </div>

        <PrivateInvoicePreviewContent data={privateRecord} setShowPaymentsDialog={setShowPaymentsDialog} />

        <PaymenstDialog
          data={privateRecord}
          showPaymentsDialog={showPaymentsDialog}
          setShowPaymentsDialog={setShowPaymentsDialog}
          onDeletePayment={handleDeletePayment}
        />

        <DeleteConfirmation
          visible={deleteClaimConfirm}
          header={t('Delete_Claim')}
          message={deleteMessage}
          accept={onAcceptDeleteClaim}
          reject={onHideDeleteConfirm}
          onHide={onHideDeleteConfirm}
        />

        <Dialog
          modal
          header={claimHistoryDialogHeader}
          footer={claimHistoryDialogFooter}
          visible={claimHistoryDialog}
          onHide={() => setClaimHistoryDialog(false)}
        >
          {RowClaimHistory(claimHistory)}
        </Dialog>

        <SendByEmailDialog visible={sendByEmailDialog} privateRecord={privateRecord} onHide={() => setSendByEmailDialog(false)} />

        <CommonConfirmDialog
          visible={archivedDialog}
          bodyText={archiveBodyText}
          accept={onArchiveClick}
          reject={() => setArchivedDialog(false)}
          onHide={() => setArchivedDialog(false)}
        />
      </div>
    </div>
  );
};

export default PrivateInvoicePreview;
