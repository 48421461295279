import React from 'react';
import { useController } from 'react-hook-form';

import WrappedPractitioner from './WrappedPractitioner';

import { validate } from 'uuid';
import { commonInputs } from '../../../modules/config/commonInputsConfig';
import { NA_DoctorGuid, multipleDoctorGuid } from '../../../modules/config/defaultValuesConfig';
import { t } from '../../../service/localization/i18n';

// interface {
//   control: UseControllerProps<FormValues>, // react-hook-form control

//   practitionersList?: { label: string, DoctorGuid: string }[] // List of practitioners
//   registeredOnly?: boolean, // detect if need to show registered practitioners only
//   naOption?: boolean, // detect if need to add "N/A" opption
//   allOption?: boolean, // The label for the "N/A" option in the selection list, if provided.
//   multipleOption?: boolean, // detect if need to add "Multiple practitioners" opption
//   onChange?: (e) => void, // No need to pass onChange function if practitioner changes only using field.onChange()
//   onSelect?: (e) => void,
//   onBlur?: (e) => void,
//   autoFocus?: boolean
//   disabled?: boolean
// }

// !!!IMPOTANT!!! CAN BE USED ONLY WITH REACT-HOOK-FORM
// If you want to use validation thet defined in current component, then DO NOT(!) use resolver in the useForm! Resolver overrides validation that is defined in the useController.

const PractitionerWithFormControl = (props) => {
  const name = commonInputs.practitionerGuid.name;
  const label = commonInputs.practitionerGuid.label;
  const {
    field,
    formState: { errors }
  } = useController({
    name,
    control: props.control,
    rules: {
      validate: (value) => validation(value)
    }
  });
  const practitionerGuid = field.value;

  const validation = (value) => {
    return (value && validate(value) && value !== NA_DoctorGuid) || t('Mandatory_field.1');
  };

  const onChange = (e) => {
    let value = typeof e?.value === 'string' ? e?.value : e?.value?.DoctorGuid;

    if (props.onChange && (validate(value) || value === NA_DoctorGuid || value === multipleDoctorGuid)) {
      props.onChange(value, field);
    } else {
      field.onChange(value);
    }
  };

  return (
    <WrappedPractitioner
      {...field}
      {...props}
      name={name}
      label={label}
      required={!props.disabled}
      practitionerGuid={practitionerGuid}
      isInvalid={errors[name]?.message}
      errorMessage={errors[name]?.message}
      onChange={onChange}
    />
  );
};

export default PractitionerWithFormControl;
