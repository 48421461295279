import React from 'react';
import FirstName from './Inputs/FirstName';
import LastName from './Inputs/LastName';
import StreetAddress from './Inputs/StreetAddress';
import City from './Inputs/City';
import Province from './Inputs/Province';
import ZipCode from './Inputs/ZipCode';
import Facility from './Inputs/Facility';
import Email from './Inputs/Email';
import EmailType from './Inputs/EmailType';
import Phone from './Inputs/Phone';
import PhoneType from './Inputs/PhoneType';
import AditionalInfo from './Inputs/AditionalInfo';
import ActionButtons from './ActionButtons/ActionButtons';
import Country from './Inputs/Country';
import AddressType from './Inputs/AddressType';

const PatientFormLayout = ({ onClose }) => {
  return (
    <>
      <div className="flex flex-column gap-4">
        <div className="flex flex-column">
          <div className="flex flex-column md:flex-row gap-0 md:gap-3">
            <FirstName />
            <LastName />
          </div>

          <AditionalInfo />

          <div className="flex flex-column md:flex-row gap-0 md:gap-3">
            <AddressType />
            <Country />
          </div>

          <div className="flex flex-column md:flex-row gap-0 md:gap-3">
            <StreetAddress />
            <City />
          </div>

          <div className="flex flex-column md:flex-row gap-0 md:gap-3">
            <Province />
            <ZipCode />
          </div>

          <Facility />

          <div className="flex flex-column md:flex-row gap-0 md:gap-3">
            <Email />
            <EmailType />
          </div>

          <div className="flex flex-column md:flex-row gap-0 md:gap-3">
            <Phone />
            <PhoneType />
          </div>
        </div>
      </div>

      <ActionButtons onClose={onClose} />
    </>
  );
};

export default PatientFormLayout;
