import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { onSendViaEmail } from '../../../helpers/rowActions';
import { isEmailValid } from '../../../../regex/regex';
import { t } from '../../../../../service/localization/i18n';
import RadioButton from '../../../../../components/PrimeReactComponents/RadioButton';

const SendByEmailDialog = ({ visible, onHide, privateRecord }) => {
  const patientEmail = privateRecord?.PatientDetails?.Email;
  const initialSendToRadio = patientEmail ? 'patient' : 'custom';
  const [sendToRadio, setSendToRadio] = useState('patient'); // 'patient' | 'custom'

  useEffect(() => {
    setSendToRadio(initialSendToRadio);
  }, [initialSendToRadio]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { customEmail: '' } });

  const onSubmit = async (data) => {
    const payload = {
      claimNumber: privateRecord?.ClaimNumber,
      patientGuid: privateRecord?.PatientDetails?.PatientGuid,
      customEmail: sendToRadio === 'custom' ? data.customEmail : undefined
    };

    onSendViaEmail(payload);
    handleHide();
  };

  const handleHide = () => {
    onHide();
    reset();
    setSendToRadio(initialSendToRadio);
  };

  return (
    <Dialog header={t('Send_by_email')} style={{ maxWidth: '430px', width: '100%' }} visible={visible} onHide={handleHide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-column gap-4 py-2">
          {/* Send to patient option */}
          {patientEmail && (
            <div className="flex align-items-center">
              <RadioButton
                inputId="patient"
                name="sendTo"
                value="patient"
                style={{ marginLeft: '11px' }}
                checked={sendToRadio === 'patient'}
                onChange={(e) => setSendToRadio(e.value)}
              />
              <label htmlFor="patient" className="ml-2">
                {`Send to patient at ${patientEmail}`}
              </label>
            </div>
          )}

          {/* Custom email input */}
          <div className="flex flex-column gap-2">
            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon">
                <RadioButton name="sendTo" value="custom" checked={sendToRadio === 'custom'} onChange={(e) => setSendToRadio(e.value)} />
              </span>
              <InputText
                placeholder="Email"
                autoFocus={sendToRadio === 'custom'}
                keyfilter="email"
                {...register('customEmail', {
                  required: sendToRadio === 'custom' ? t('Mandatory_field.1') : false,
                  pattern: {
                    value: isEmailValid,
                    message: t('Mandatory_field.1')
                  }
                })}
                className={`p-inputtext ${errors.customEmail && 'p-invalid'}`}
                onFocus={() => setSendToRadio('custom')}
              />
            </div>
            {errors.customEmail && <small className="p-error">{errors.customEmail.message}</small>}
          </div>
          {/* Submit Button */}
          <div className="flex gap-3 w-full">
            <Button className="w-full" type="button" label={t('Cancel')} outlined onClick={handleHide} />
            <Button className="w-full" label={t('Send')} />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default SendByEmailDialog;
