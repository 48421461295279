import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

const EmailRepeat = () => {
  const {
    control,
    formState: { errors },
    getValues,
    clearErrors
  } = useFormContext();

  const preventDefault = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      <InputWrapper name="repeatEmail" label="Repeat Your Email Address" required errorMessage={errors.repeatEmail?.message}>
        <Controller
          name="repeatEmail"
          control={control}
          rules={{
            required: 'Repeat Email is required',
            validate: (value) => value === getValues('email') || 'Emails must match'
          }}
          render={({ field }) => (
            <InputText
              {...field}
              id="repeatEmail"
              data-testid="repeatEmail-testid"
              type="text"
              className={classNames({ 'p-invalid': errors.repeatEmail })}
              onCopy={(e) => {
                preventDefault(e);
              }}
              onPaste={(e) => {
                preventDefault(e);
              }}
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(value);
                value && errors.repeatEmail?.message && clearErrors('repeatEmail');
              }}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default EmailRepeat;
