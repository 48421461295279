import React from 'react';
import { InputText } from 'primereact/inputtext';
import { formatZipCode } from '../../../modules/utils/formatZipCode';
import { defaultCountryCode } from '../../../modules/config/defaultValuesConfig';

/**
 * Zip component for formatting and handling zip code input.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} [props.country=defaultCountryCode] - The country code to determine the zip code format.
 * @param {Function} [props.onChange] - Callback function to handle change events.
 * @param {Function} [props.onBlur] - Callback function to handle blur events.
 * @returns {JSX.Element} The rendered input component.
 */
const Zip = ({ country = defaultCountryCode, ...props }) => {
  const formatZip = (code) => {
    return country === defaultCountryCode ? formatZipCode(code, country) : code;
  };

  const handleChange = (e) => {
    const formattedValue = formatZip(e.target.value);
    props.onChange?.(formattedValue || '');
  };

  const handleBlur = (e) => {
    const formattedValue = formatZip(e.target.value);
    props.onBlur?.(formattedValue || '');
  };

  return (
    <InputText
      {...props}
      keyfilter={/^[A-Za-z0-9\s-]$/} // Letters, numbers, spaces, and hyphens
      maxLength={10} // Set max length for global coverage
      type="text"
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default Zip;
