import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PatientForm from '../PatientForm/PatientForm';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';
import { getPatient, setPatientInfo } from '../../actions/patients.action.creators';

const EditProfile = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        setLoading(true);
        const patient = await getPatient(id);
        if (patient) {
          dispatch(setPatientInfo(patient));
        }
      } catch (error) {
        console.error('Error fetching patient:', error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchPatient();
    }
  }, [id, dispatch]);

  if (loading) {
    return <CircularProgress />;
  }

  return <PatientForm />;
};

export default EditProfile;
