import { decodeText, decodeTextContent } from '../../../utils/decodeText';
import { trimLeftZeros } from '../../../utils/trimLeftZeros';
import { convertToLocalTime } from '../../../utils/formatDate';

export const titleBodyTemplate = (note, onRowSelect) => (
  <div className="w-full p-0 flex align-items-center pl-0 pr-2 py-3" style={{ height: '55px' }} onClick={() => onRowSelect(note)}>
    <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis">{`${decodeText(note?.Title)} - ${decodeTextContent(
      note?.Comments
    )}`}</div>
  </div>
);

export const authorBodyTemplate = (note, onRowSelect) => (
  <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }} onClick={() => onRowSelect(note)}>
    {note?.Author}
  </div>
);

export const patientBodyTemplate = (note, onRowSelect) => (
  <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }} onClick={() => onRowSelect(note)}>
    {note?.PatientName}
  </div>
);

export const officeNumBodyTemplate = (note, onRowSelect) => (
  <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }} onClick={() => onRowSelect(note)}>
    {`${trimLeftZeros(note?.OfficeNumber)} (o)`}
  </div>
);

export const dateBodyTemplate = (note, onRowSelect) => (
  <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }} onClick={() => onRowSelect(note)}>
    {convertToLocalTime(note?.NoteDate)}
  </div>
);
