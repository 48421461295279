import React from 'react';
import { FormProvider } from 'react-hook-form';

import Dialogs from './modules/Dialogs/Dialogs';
import EClaimsView from './views/EClaimsView';
import ComingSoon from '../../../../components/Layouts/ComingSoon';

import { useDefaultValues } from './hooks/useDefaultValues';
import { showPolicy } from '../../../config/specialitiesConfig';

import './eClaim.scss';
import '../../../../components/Catalogs/Catalogs.scss';

// interface EClaimProps {
//   values?: {}, // provide initial form values if needed
//   claimGuid?: string, // need for edit mode if component called not in Nav.js (for example if called in the dialog)
//   patientGuid?: string, // need for edit mode if component called not in Nav.js (for example if called in the dialog)
//   onCancel?: () => void,
//   onCreate?: (formData) => void,
//   onEdit?: (formData) => void,
// }

const EClaim = (props) => {
  const initValues = useDefaultValues(props);

  if (!showPolicy()) return <ComingSoon />;

  return (
    <FormProvider {...initValues}>
      <EClaimsView />
      <Dialogs />
    </FormProvider>
  );
};

export default EClaim;
