import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'primereact/button';
import { t } from '../../../../service/localization/i18n';

const ActionButtons = ({ onClose }) => {
  const {
    getValues,
    formState: { isDirty, isSubmitting }
  } = useFormContext();

  const isNew = getValues('formState.isNew');

  return (
    <div className="w-full py-3 sticky bottom-0 bg-white">
      {isDirty ? (
        <div className="flex gap-3">
          <Button className="w-full" label={isNew ? t('Create') : t('Save')} loading={isSubmitting} />
          <Button className="w-full" type="button" label={t('Cancel')} outlined onClick={onClose} />
        </div>
      ) : (
        <Button type="button" label={t('Close')} className="w-full" onClick={() => onClose(getValues())} />
      )}
    </div>
  );
};

export default ActionButtons;
