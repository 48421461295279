import _ from 'lodash';

// Utility function to filter out invalid payee numbers
const isValidPayeeNumber = (n) => n && n !== '0' && n !== 0 && n !== '-1' && n !== -1;

export const reformatMembers = (list, spec) => {
  return list.map((x) => {
    // Process PayeeNumbers, ensuring it's an array and filtering out invalid values
    const payeeNumbers = _.get(x, 'PayeeNumbers', []);
    const PayeeNumbers = Array.isArray(payeeNumbers)
      ? payeeNumbers.filter(isValidPayeeNumber)
      : typeof payeeNumbers === 'string'
      ? payeeNumbers.split(',').filter(isValidPayeeNumber)
      : [];

    // Determine role with a clear conditional check
    const Role = x.Role || (x.PractitionerNumber === '0' ? 'rcp' : 'doc');

    // Construct a clean FullAddress
    const FullAddress = _.compact([x.AddressLine, x.City, x.Province, x.Zip]).join(', ');

    return {
      ...x,
      PayeeNumbers,
      FullName: `${_.get(x, 'LastName', '')}, ${_.get(x, 'FirstName', '')}`,
      Role,
      FullAddress,
      Speciality: spec && !isNaN(spec) ? parseInt(spec, 10) : _.get(x, 'Speciality', ''),
      Phone: x.Phone || 'N/A',
      Email: x.Email || 'N/A'
    };
  });
};

export const addFullAddress = (data) => {
  return {
    ...data,
    FullAddress: `${data?.AddressLine} ${data?.City} ${data?.Province} ${data?.Zip}`
  };
};
