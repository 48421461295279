import React from 'react';
import Daysheet from './index';
import MailsLayout from '../../mails/components/MailsLayout';

const DaysheetInbox = () => {
  return (
    <MailsLayout>
      <Daysheet />
    </MailsLayout>
  );
};

export default DaysheetInbox;
