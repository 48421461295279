import React from 'react';
import DatePicker from './Common/DatePicker';
import ServiceDateLabelLayout from '../Layouts/ServiceDateLabelLayout';
import { inputs } from '../../../helpers/inputs';
import { useServiceDate } from '../../../hooks/useServiceDate';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { useFormContext } from 'react-hook-form';

const MultipleServiceDates = () => {
  const { watch } = useFormContext();
  const { onChangeMultipleServiceDate } = useServiceDate();
  const status = watch(inputs.status.name);
  const serviceDate = watch(inputs.serviceDate.name);

  const footerContent = () => {
    if (serviceDate?.length) {
      return <div className="w-full text-right text-sm">{serviceDate?.length} date(s)</div>;
    }

    return null;
  };

  return (
    <DatePicker
      id={elementIDs.multipleServiceDate}
      required
      readOnlyInput
      showOnFocus
      status={status}
      input={inputs.serviceDate}
      footerContent={footerContent}
      onChange={onChangeMultipleServiceDate}
      lableContent={<ServiceDateLabelLayout />}
    />
  );
};

export default MultipleServiceDates;
