import { createContext } from 'react';

export const PractitionerReportsContext = createContext();
export const SimilarClaimsListContext = createContext();
export const PayeeReportsContext = createContext();
export const PatientBillingHistoryContext = createContext();
export const PatientPoliciesContext = createContext();
export const InvoiceTimelineContext = createContext();
export const CatalogsContext = createContext();
export const RegistrationContext = createContext();
