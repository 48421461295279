export const confirmDialogNames = {
  delete: 'delete',
  debit: 'debit',
  archive: 'archive',
  archiveEClaim: 'archiveEClaim',
  updatePHN: 'updatePHN',
  updatePatientProfile: 'updatePatientProfile',
  viewPayments: 'viewPayments',
  addClaimNote: 'addClaimNote',
  billingHistory: 'billingHistory',
  similarClaims: 'similarClaims',
  editPatient: 'editPatient',
  sendByEmail: 'sendByEmail'
};
