import React from 'react';
import { useSelector } from 'react-redux';

import PaymentsButton from './PaymentsButton';
import PayeeSection from '../../../../../components/Layouts/Print/PrivateInvoice/Sections/PayeeSection';
import BillToSection from '../../../../../components/Layouts/Print/PrivateInvoice/Sections/BillToSection';
import InvoiceDetailsSection from '../../../../../components/Layouts/Print/PrivateInvoice/Sections/InvoiceDetailsSection';
import ServiceDateSection from '../../../../../components/Layouts/Print/PrivateInvoice/Sections/ServiceDateSection';
import PayableSection from '../../../../../components/Layouts/Print/PrivateInvoice/Sections/PayableSection';
import DataTableSection from '../../../../../components/Layouts/Print/PrivateInvoice/Sections/DataTableSection';
import NotesSection from '../../../../../components/Layouts/Print/PrivateInvoice/Sections/NotesSection';
import TotalSection from '../../../../../components/Layouts/Print/PrivateInvoice/Sections/TotalSection';

import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { t } from '../../../../../service/localization/i18n';

import './PrivateInvoicePreview.scss';

const PrivateInvoicePreview = ({ data, setShowPaymentsDialog }) => {
  const { currentUserPrefs } = useSelector((state) => state.preferences);
  const { gettingClaimInfo } = useSelector((state) => state.claims);
  const { clinic } = useSelector((state) => state.clinic.details);

  const invoiceLogo = currentUserPrefs?.prefs?.find((i) => i.label === 'system')?.content?.find((i) => i.label === prefsCodes.clinicLogo)?.value;

  const invoiceLogoSection = (
    <img
      id="invoice-logo"
      className="logo-image"
      // src="assets/layout/images/logo-colored.png"
      src={invoiceLogo || ''}
      alt={clinic?.ClinicName}
    />
  );

  const titleLayout = <div className="invoice-title">{t('Invoices.3').toUpperCase()}</div>;

  return (
    <div className="card p-0 privateInvoicePreviewDesktopLayout">
      <div className="invoice">
        {/* Header */}

        <div className="privateInvoicePreviewInvoiceTitleWrapMobile">{titleLayout}</div>

        <div className="invoice-header privateInvoicePreviewInvoiceHeaderWrap">
          <div className="invoice-company">
            {/* {invoiceLogoSection} */}
            <PayeeSection data={data} isFetching={gettingClaimInfo} />
          </div>

          <div>
            <div className="privateInvoicePreviewInvoiceTitleWrapDesktop">{titleLayout}</div>
            <InvoiceDetailsSection data={data} isFetching={gettingClaimInfo} />
          </div>
        </div>

        {/* Invoice to */}
        {/* {invoiceToSection} */}
        <div className="invoice-to">
          <div className="flex justify-content-between privateInvoicePreviewInvoiceToWrap">
            <BillToSection data={data} isFetching={gettingClaimInfo} />
            <ServiceDateSection data={data} isFetching={gettingClaimInfo} />
          </div>
        </div>

        {/* Data table */}
        <div className="invoice-items mt-0">
          <PayableSection data={data} isFetching={gettingClaimInfo} />
          <DataTableSection data={data} isFetching={gettingClaimInfo} />
        </div>

        {/* Total / Notes */}
        <div className="invoice-summary privateInvoicePreviewInvoiceSummaryWrap">
          <div className="noteSectionWrap">
            <NotesSection data={data} />
            <PaymentsButton data={data} onClick={() => setShowPaymentsDialog(true)} />
          </div>
          <TotalSection data={data} isFetching={gettingClaimInfo} />
        </div>
      </div>
    </div>
  );
};

export default PrivateInvoicePreview;
