import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../Inputs';

import { useValidation } from '../hooks/useValidation';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';
import { inputWrapperStyles } from '../helpers/styles';

const CompanyName = () => {
  const name = 'CompanyName';
  const { isCompany, companyNameValidation } = useValidation();
  const {
    control,
    trigger,
    formState: { errors }
  } = useFormContext();

  if (!isCompany) return null;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: companyNameValidation
      }}
      render={({ field }) => (
        <InputWrapper name={name} label={t('Company_Name')} errorMessage={errors[name]?.message} style={inputWrapperStyles}>
          <InputText
            className={cx({ 'p-invalid': errors[name]?.message })}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value?.toUpperCase());
              if (isCompany) {
                trigger('FirstName');
                trigger('LastName');
              }
            }}
          />
        </InputWrapper>
      )}
    />
  );
};

export default CompanyName;
