import { useCallback, useEffect, useState } from 'react';
import { referralCreditsDefaultSortBy } from '../helpers/defaultValues';
import ReferralCreditsTable from './ReferralCreditsTable/ReferralCreditsTable';
import { getReferralCreditsList } from '../actions/referralCredits.action.creators';

const ReferralCreditsView = () => {
  const [referrals, setReferrals] = useState();
  const [loading, setLoading] = useState(false);

  const fetchReferrals = useCallback(async ({ page, pageSize, query, sortby }) => {
    setLoading(true);
    const result = await getReferralCreditsList({ page, pageSize, query, sortby });
    setLoading(false);
    if (result) setReferrals(result);
  }, []);

  useEffect(() => {
    fetchReferrals({
      page: 1,
      pageSize: 20,
      query: {},
      sortby: `${referralCreditsDefaultSortBy.sortField}_${referralCreditsDefaultSortBy.sortOrder === 1 ? 'asc' : 'desc'}`
    });
  }, []);

  return (
    <div className="flex justify-content-center">
      <div className="mt-3 w-full" style={{ maxWidth: '750px' }}>
        <ReferralCreditsTable data={referrals} loading={loading} fetchData={fetchReferrals} />
      </div>
    </div>
  );
};

export default ReferralCreditsView;
