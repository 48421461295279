import React from 'react';
import { useFormContext } from 'react-hook-form';

import IECDialog from './IECDialog';
import PromptDialog from './PromptDialog';
import WeekendDialog from './WeekendDialog';
import AnatomicCodeDialog from './AnatomicCodeDialog';
import EmergencyInfoDialog from './EmergencyInfoDialog';
import TravelInfoDialog from './TravelInfoDialog';
import CCFPPInfoDialog from './CCFPPInfoDialog';
import ChangeOrderDialog from './ChangeOrderDialog';
import SameDurationDialog from './SameDurationDialog';
import ApplyToAllDxDialog from './ApplyToAllDxDialog';
import SubmitionCodeDialog from './SubmitionCodeDialog';
import BatchCatalogsDialog from './BatchCatalogsDialog';
import AnesProceduralDialog from './AnesProceduralDialog';
import BillingHistoryDialog from './BillingHistoryDialog';
import FeeCodeDurationDialog from './FeeCodeDurationDialog';
import ValidationUnitsDialog from './ValidationUnitsDialog';
import WSBCConsultationDialog from './WSBCConsultationDialog';
import DuplicatePatientDialog from './DuplicatePatientDialog';
import BMISurgicalAssistDialog from './BMISurgicalAssistDialog';
import GroupTemplateListDialog from './GroupTemplateListDialog';
import ApplyToAllReferralDialog from './ApplyToAllReferralDialog';
import AddNewPractitionerDialog from './AddNewPractitionerDialog';
import DeleteGroupRecordConfirm from './DeleteGroupRecordConfirm';
import GroupTableSettingsDialog from './GroupTableSettingsDialog';
import CreateGroupTemplateDialog from './CreateGroupTemplateDialog';
import TimeGoesOverMidnightDialog from './TimeGoesOverMidnightDialog';
import DurationForPsychiatryCodesDialog from './DurationForPsychiatryCodesDialog';
import BMISurgicalAssistForGroupDialog from './BMISurgicalAssistForGroupDialog';
import BatchDurationForPsychiatryCodesDialog from './BatchDurationForPsychiatryCodesDialog';
import CommonConfirmDialog from '../../../../../common/components/CommonConfirmDialog';
import InvalidLocationForMidwifeInductionDialog from './InvalidLocationForMidwifeInductionDialog';
import ConflictsBetweenGroupAndIndividualTimesDialog from './ConflictsBetweenGroupAndIndividualTimesDialog';
import BatchRecordsWarnDialogForOnePatient from '../../../../../common/components/Dialogs/BatchRecordsWarnDialogForOnePatient';
import BatchRecordsWarnDialogForMultiplePatients from '../../../../../common/components/Dialogs/BatchRecordsWarnDialogForMultiplePatients';
import FeeCodeForWSBCDialog from './FeeCodeForWSBCDialog';
import UnsavedChangesForBatchDialog from './UnsavedChangesForBatchDialog';
import UnsavedChangesForTeleplanDialog from './UnsavedChangesForTeleplanDialog';
import UnsavedChangesForFirstVisitDialog from './UnsavedChangesForFirstVisitDialog';
import OOFHPDescriptionDialog from './OOFHPDescriptionDialog';

import { t } from '../../../../../../service/localization/i18n';
import { steps } from '../../helpers/steps';

const Dialogs = () => {
  const { localState, setLocalState, isNew, firstVisit } = useFormContext();

  const onHideConfirmDialog = (key) => {
    setLocalState((prevState) => ({
      ...prevState,
      [key]: false,
      claimData: {}
    }));
  };

  return (
    <>
      <PromptDialog />

      <UnsavedChangesForBatchDialog />

      <UnsavedChangesForTeleplanDialog />

      <UnsavedChangesForFirstVisitDialog />

      <IECDialog />

      <OOFHPDescriptionDialog />

      <BillingHistoryDialog />

      <FeeCodeForWSBCDialog />

      <ValidationUnitsDialog />

      <BatchCatalogsDialog />

      <AnatomicCodeDialog />

      <FeeCodeDurationDialog />

      <BMISurgicalAssistDialog />

      <BMISurgicalAssistForGroupDialog />

      <GroupTableSettingsDialog />

      <WSBCConsultationDialog />

      <AnesProceduralDialog />

      <SubmitionCodeDialog />

      <AddNewPractitionerDialog />

      <DurationForPsychiatryCodesDialog />

      <BatchDurationForPsychiatryCodesDialog />

      <WeekendDialog />

      <EmergencyInfoDialog />

      <TravelInfoDialog />

      <CCFPPInfoDialog />

      <ChangeOrderDialog />

      <ApplyToAllDxDialog />

      <ApplyToAllReferralDialog />

      <SameDurationDialog />

      <DeleteGroupRecordConfirm />

      <GroupTemplateListDialog />

      <CreateGroupTemplateDialog />

      <TimeGoesOverMidnightDialog />

      <InvalidLocationForMidwifeInductionDialog />

      <ConflictsBetweenGroupAndIndividualTimesDialog />

      {firstVisit && <DuplicatePatientDialog />}

      <CommonConfirmDialog
        visible={localState.recordsTableChangesDialog}
        body={t('You_made_some_changes_to_this_screen')}
        accept={() =>
          setLocalState((prevState) => ({
            ...prevState,
            recordsTableChangesDialog: false,
            step: steps.form,
            isStep2Dirty: false,
            recordsRequiredInputs: {}
          }))
        }
        reject={() => setLocalState((prevState) => ({ ...prevState, recordsTableChangesDialog: false }))}
      />

      <BatchRecordsWarnDialogForOnePatient
        data={localState.claimData}
        visible={localState.showBatchRecordsWarnForOnePatient}
        showOnCreateNewClaimScreen={isNew}
        showOnInvoicesPage={isNew ? false : true}
        onHide={() => onHideConfirmDialog('showBatchRecordsWarnForOnePatient')}
      />

      <BatchRecordsWarnDialogForMultiplePatients
        data={localState.claimData}
        visible={localState.showBatchRecordsWarnForMultiplePatient}
        onHide={() => onHideConfirmDialog('showBatchRecordsWarnForMultiplePatient')}
      />
    </>
  );
};

export default Dialogs;
