import React, { useState, useEffect, useRef } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PHN from '../Inputs/PHN';
import BirthDate from '../Inputs/BirthDate';
import LastName from '../Inputs/LastName';
import FirstName from '../Inputs/FirstName';
import MidName from '../Inputs/MidName';
import Gender from '../Inputs/Gender';
import StreetAddress from '../Inputs/StreetAdress';
import City from '../Inputs/City';
import Province from '../Inputs/Province';
import Zip from '../Inputs/Zip';
import EligibilityButtonsSection from './EligibilityButtonsSection';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { handleAutofill } from '../../../../../../patients/components/PatientForm/AutoFillHandler';
import useAutofill from '../../../../../../patients/components/PatientForm/useAutofill';
import { duplicatePatientCheck } from '../../../../../../patients/actions/patients.action.creators';
import CircularProgress from '../../../../../../../components/Misc/Loader/CircularProgress/CircularProgress';
import { v4 as randomUUID } from 'uuid';
import { setSessionGuid } from '../../../../../helpers/sessionGuid';
import { inputs } from '../../../helpers/inputs';
import { ButtonWithBadge } from '../../../../../../../components/Buttons';
import { logStatusToAPI } from '../../../../../../patients/actions/patients.action.creators';
import { setErrorMessageAutofill } from '../../../../../../patients/actions/patients.action.creators';
import { FileUpload } from 'primereact/fileupload';
import heic2any from 'heic2any';
import BetaBadge from '../../../../../../../components/Misc/Badges/BetaBadge';

const FirstVisitSection = () => {
  const { setValue, setLocalState, trigger, control, watch, firstVisit, clearErrors, resetField } = useFormContext(); // Get methods from react-hook-form
  const dispatch = useDispatch(); // Redux dispatch
  const { DCNGuid } = useSelector((state) => state.user.details); // Get DCNGuid from Redux
  const [extractedText, setExtractedText] = useState(''); // Manage extracted text state
  const formik = { values: watch() }; // Get form values
  const watchValues = watch(); // Watch form values
  const [croppedImage, setCroppedImage] = useState(null); // Manage cropped image state
  const [showImage, setShowImage] = useState(false); // Manage image display state
  const [isPHNValid, setIsPHNValid] = useState(true); // Manage PHN validation state
  const [disableAbortButoon, setDisableAbortButoon] = useState(false); // Manage abort button state
  const [imageTimer, setImageTimer] = useState(null); // Manage image timer state
  const [image, setImage] = useState(null); // Manage image state
  const [showForm, setShowFrom] = useState(true); // Manage form display state
  const [loading, setLoading] = useState(false); // Manage loading state
  const [errorMessage, setErrorMessage] = useState(''); // Manage error message state
  const [autofillTriggered, setAutofillTriggered] = useState(false); // Manage autofill trigger state
  const [nameFromEligibility, setNameFromEligibility] = useState('');
  const [nameFromPicture, setNameFromPicture] = useState('');
  const { isFetchingPatientEligibility } = useSelector((state) => state.patients);
  const { isMobile, isMobileOnly, iPadInPortrait, isLandscape } = useSelector((state) => state.core.window);
  const [imageIndex, setImageIndex] = useState(0); // Tracks the currently processed image

  const errorToastMessage = useSelector((state) => state?.patients?.autofillError[imageIndex]?.message) || '';
  const errorToastType = useSelector((state) => state?.patients?.autofillError[imageIndex]?.severity) || '';
  const [loadingDuplicatePatient, setLoadingDuplicatePatient] = useState(false);
  const [isImageDialogVisible, setIsImageDialogVisible] = useState(false);
  const [suggestedNames, setSuggestedNames] = useState([]);
  const [imageStatuses, setImageStatuses] = useState([]); // Tracks the status of each image
  const DataCenterNumber = useSelector((state) => state?.clinic?.details?.DataCenterNumber);
  const [file, setFile] = useState(null);
  const fileUploadRef = useRef(null);
  const dynamicLabel = loading ? 'Loading' : isMobileOnly ? 'Label' : 'Upload Label';
  const dynamicIcon = loading ? 'pi pi-spin pi-spinner' : isMobileOnly ? 'pi pi-camera' : 'pi pi-upload';




  const isPWA = window.matchMedia('(display-mode: standalone)').matches || navigator.standalone;
  const showLabelReader = true; // hide label reader for now on mobile
  const phnProvince = watch(inputs.phnProvince.name);

  const closeImageDialog = () => {
    setIsImageDialogVisible(false);
  };

  const openImageDialog = () => {
    setIsImageDialogVisible(true);
  };

  // const { loading, uploading, errorMessage, onAutofillClick } = useAutoFillFirstVisit(setValue, trigger, dispatch, DCNGuid, setImage, watch); // Pass setImage to the hook

  // useEffect(() => {
  //   console.log('loading', loading); // Log loading
  // }, [loading]);

  useEffect(() => {
    console.log('Loading duplicate patient:', loadingDuplicatePatient); // Log loading
  }, [loadingDuplicatePatient]);

  useEffect(() => {
      dispatch(setErrorMessageAutofill({index: null, type: null, message: null, originalMSPErrorMessage: null }));
  }, []);

  let sessionGuid = null;

  const startNewScanningSession = () => {
    sessionGuid = randomUUID();
    setSessionGuid(sessionGuid);
    console.log('New SessionGuid created:', sessionGuid);
  };

  useEffect(() => {
    setSessionGuid(null);
  }, []);

  const onUpload = async (event) => {
    const file = event.files[0];
    if (!file) return; // Ensure a file is selected

    // Check if the file is HEIC
      if (file) {
        // setLoading(true);
        setImageStatuses([]); 
        const validImageTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/jpg', 'image/webp'];
        if (file.type === "image/heif" || file.type === "image/heic") {
            // Convert HEIF/HEIC to JPEG
            // setLoading(true);
            try {
                const converted = await heic2any({
                    blob: file,
                    toType: "image/jpeg",
                });
                // reader = new FileReader();
                // reader.readAsDataURL(converted);
                setFile(converted);
            } catch (error) {
              setLoading(false);
              setShowImage(true);
              dispatch(setErrorMessageAutofill({ index: imageIndex, severity: 'error', message: 'Invalid file type. Please upload a valid image file.' }));
            }
        } else if (validImageTypes.includes(file.type)) {
          setFile(file);
            // const reader = new FileReader();
            // reader.readAsDataURL(file);
            // reader.onload = () =>
            //   setCroppedImage(reader.result);
            //   fileUploadRef.current.clear();
        } else {
            dispatch(setErrorMessageAutofill({ index: imageIndex, severity: 'error', message: 'Invalid file type. Please upload a valid image file.' }));
        }
    } 
    
    else {
        throw new Error('No file selected');
    }
    fileUploadRef.current.clear(); 
};

useEffect(() => {
  if (file) {
    onAutofillClick();
  }
}, [file]);


  useEffect(() => {
    if (errorToastMessage) {
      const error = errorToastMessage;
      console.log('Processing error for imageIndex:', imageIndex, 'Error:', error);


      setTimeout(() => {
        
      setImageStatuses((prevStatuses) =>
        prevStatuses.map((status, idx) => 
          idx === imageIndex && status.status !== 'success' && status.status !== 'duplicate' && status.status !== 'failed'
            ? {
              ...status,
              status: error === "First name or last name not found in the image. Please verify the autofill data."
                ? 'success'
                : error === "PHN already exists in the system."
                ? 'duplicate'
                : 'failed',
              errorMessage: error,
              suggestedNames: suggestedNames
            }
          : status
        )
      );
    }, 1000);
    }

  }, [errorToastMessage]);


  useEffect(() => {
    const allImagesProcessed =
      imageStatuses.length > 0 &&
      imageStatuses.every((status) => ['failed', 'duplicate', 'success'].includes(status.status));
      const location = 'fromFirstVisitSection';
  
    if (allImagesProcessed) {
      logStatusToAPI(DCNGuid, imageStatuses, sessionGuid, location, DataCenterNumber);
    }
  }, [imageStatuses]);

  useEffect(() => {
    console.log('Image statuses:', imageStatuses);
  }, [imageStatuses]);

  const handleNameClick = (name) => {
    // Ensure that 'firstName' and 'lastName' are the exact field names in your form
    setValue('FirstName', name.firstName);
    setValue('LastName', name.lastName);
  };

  const checkPatientDuplicate = ({ patientData, callback }) => {
    setLoadingDuplicatePatient(true);
    const partialPatientDetails = {
      DCNGuid: patientData.DCNGuid || DCNGuid,
      PHN: patientData.PHN || '',
      PHNProvince: patientData.PHNProvince || '',
      FirstName: patientData.FirstName || '',
      MidName: patientData.MidName || '',
      LastName: patientData.LastName || '',
      FullName: patientData.FullName || '',
      Sex: patientData.Sex || '',
      DOB: patientData.BirthDay || ''
    };

    dispatch(
      duplicatePatientCheck(partialPatientDetails, (responseData) => {
        if (responseData?.length) {
          // Duplicate found, use callback to process duplicate data
          callback(responseData); // Pass the first duplicate match
        } else {
          // No duplicate found
          callback(null);
        }
        setLoadingDuplicatePatient(false); // End loading after response
      })
    );
  };

  const onAutofillClick = async () => {
    // resetForm();
    // clearErrors();
    startNewScanningSession();

    setImageStatuses((prevStatuses) =>
      prevStatuses.map((status, idx) =>
        idx === imageIndex ? { ...status, status: 'processing' } : status
      )
    );

    if (loading) {
      // If already loading, you might want to return or show a message
      return;
    }

    try {
      await handleAutofill(
        null,
        setExtractedText,
        setImage,
        setShowImage,
        setIsPHNValid,
        file,
        croppedImage,
        setLoading,
        setDisableAbortButoon,
        setImageTimer,
        setValue,
        resetField,
        sessionGuid,
        imageIndex,
        setImageStatuses,
        // { signal: controller.signal }
      );
      setAutofillTriggered(true);
      setLocalState((prevState) => ({
        ...prevState,
        eligibility: null // Update eligibility state
      }));
    } catch (error) {
      console.error('Error in autofill:', error);
    } finally {
      setLoading(false);
      clearErrors();
    }
  };

  useAutofill(
    null,
    setLoading,
    setShowImage,
    extractedText,
    autofillTriggered,
    setAutofillTriggered,
    checkPatientDuplicate,
    setNameFromEligibility,
    setNameFromPicture,
    null,
    null,
    setSuggestedNames,
    imageIndex,
    setShowFrom,
    setValue,
    watchValues,
    setLocalState
  );

  if (!firstVisit) return null;

  return (
    <>
      {/* CircularProgress Overlay */}
      {(loading || isFetchingPatientEligibility || loadingDuplicatePatient) && (
        <>
          <CircularProgress />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.6)', // Semi-transparent overlay
              zIndex: 1
            }}
          />
        </>
      )}

      {/* Autofill Button */}
      {showLabelReader && (
        <div className="autofill-section pb-3" style={{ position: 'relative' }}>
        <ButtonWithBadge value='Faster'>
          <FileUpload
            ref={fileUploadRef}
            mode="basic"
            accept="image/*, .heif, .heic"
            customUpload
            chooseOptions={{ label: dynamicLabel, icon: dynamicIcon, className: 'w-full' }}
            auto
            uploadHandler={onUpload}
            disabled={loading}
            contentStyle={{ width: '100%', height: '100%' }}
            // chooseLabel="Upload Image"
          />
          </ButtonWithBadge>
        {/* <BetaBadge/> */}
      </div>
      )}

      {showImage && (
        <div className="pb-2">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '20px',
              padding: '10px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
            }}
          >
            <h3
              style={{
                fontSize: '1.2rem',
                marginBottom: '10px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#333'
              }}
            >
              Uploaded Image
            </h3>
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'flex', // Ensures Flexbox layout
                paddingTop: '2rem',
                paddingBottom: '2rem',
                justifyContent: 'center', // Horizontal centering
                alignItems: 'center', // Vertical centering
                overflow: 'hidden', // Prevents overflow of the image
                cursor: 'pointer' // Change cursor to pointer on hover
              }}
              onClick={openImageDialog}
            >
              <img
                src={image}
                style={{
                  maxWidth: '100%',
                  maxHeight: '300px',
                  objectFit: 'contain', // Ensures the image scales without distortion
                  margin: '0' // Remove any default margin on the image
                }}
              />
            </div>
            {errorToastMessage && errorToastMessage !== '' && (
              <div
                className={`flex flex-column w-full items-center justify-center px-3 py-3 bg-yellow-100 text-yellow-600`}
                style={{ height: 'auto', borderRadius: '8px', fontWeight: 'bold', marginTop: '10px' }}
              >
                <div className="flex items-center justify-center">
                  <i className={`pi pi-exclamation-triangle mr-2`} style={{ fontSize: '1.5rem' }}></i>
                  <p>{errorToastMessage}</p>
                </div>
                {errorToastMessage?.toLowerCase().includes('name not recognized') ? (
                  <div className="flex sm:flex-row flex-column" style={{ marginTop: '10px' }}>
                    {suggestedNames.map((name, index) => (
                      <Button
                        key={index}
                        label={`${name.lastName}, ${name.firstName}`}
                        style={{
                          width: 'auto'
                        }}
                        type="button"
                        text
                        onClick={() => handleNameClick(name)}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="phn_birthday_inputs_wrap">
        <PHN />
        <BirthDate />
      </div>

      <EligibilityButtonsSection />

      <div className="two_inputs_in_a_row">
        <LastName />
        <FirstName />
      </div>

      <div className="two_inputs_in_a_row">
        <MidName />
        <Gender />
      </div>

      {phnProvince !== 'BC' && (
        <>
          <div className="two_inputs_in_a_row">
            <StreetAddress />
            <City />
          </div>

          <div className="two_inputs_in_a_row">
            <Province />
            <Zip />
          </div>
        </>
      )}

      {/* Display the uploaded image and error message */}
      {/* {image && (
        <Dialog header="Uploaded Image" visible={!!image} onHide={() => setImage(null)}>
          <img src={image} alt="Uploaded" style={{ maxWidth: '100%', objectFit: 'contain' }} />
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </Dialog>
      )} */}

      <Dialog
        header="Uploaded Image"
        visible={isImageDialogVisible}
        style={{ width: '70vw', maxWidth: '800px' }} // Default dialog size
        headerStyle={{
          textAlign: 'center' // Center-align the header text
        }}
        onHide={closeImageDialog} // Close the dialog
        breakpoints={{
          '960px': '90vw', // For screens smaller than 960px, set the dialog width to 90% of the viewport width
          '640px': '100vw' // For screens smaller than 640px, set the dialog width to 100% of the viewport width
        }}
      >
        <div className="flex justify-content-center pb-6">
          <img
            src={image}
            style={{
              maxWidth: '100%',
              maxHeight: '600px',
              objectFit: 'contain', // Ensures the image scales without distortion
              margin: '0' // Remove any default margin on the image
            }}
          />
        </div>
        <div className="flex justify-content-center">
          <Button label="Close" onClick={closeImageDialog} />
        </div>
      </Dialog>
    </>
  );
};

export default FirstVisitSection;
