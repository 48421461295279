import { store } from '../../../store';
import { Button } from 'primereact/button';
import { addEClaim, addNewEClaimPolicy, addNewInvoice, addNewTeleplan, addNewWSBCR } from './addNewInvoice';
import { elementIDs } from '../../config/elementIDsConfig';
import { t } from '../../../service/localization/i18n';
import { showPolicy } from '../../config/specialitiesConfig';

export const invoicesTypes = {
  teleplan: 'teleplan',
  private: 'private'
};

export const addNewOverlayItemsList = ({ history, patient_details = null, replaceRoute = false, isSplitButton = false, formik }) => {
  const user = store.getState().user.details;
  const showNewTeleplanButtons = store.getState().core.window.showNewTeleplanButtons;

  const handleResetAndAction = (action) => {
    if (formik) {
      // Explicitly reset to current values and wait for completion
      formik.resetForm();
    }
    setTimeout(() => {
      action?.(); // Safely invoke the action after the delay
    }, 10); // Adjust the delay as needed (100ms is a good starting point)
    };

  
  let commonItems = [
    {
      label: isSplitButton ? t('New_Invoice') : t('Invoices.3'),
      id: 'newInvoice',
      template: (item) => {
        return (
          <Button
            id={elementIDs.newIncoiceButton}
            className="p-button-text w-full"
            style={{ textAlign: 'left' }}
            label={item.label}
            onClick={() => handleResetAndAction(() => addNewInvoice(invoicesTypes.private, history, patient_details, replaceRoute))}
          />
        );
      }
    }
  ];

  const firstVisitTelteplan = {
    label: t('Teleplan_first_visit'),
    id: 'teleplanFirstVisit',
    template: (item) => {
      return (
        <Button
          className="p-button-text w-full"
          style={{ textAlign: 'left' }}
          label={item.label}
          onClick={() => handleResetAndAction(() => addNewTeleplan({ history, firstVisit: true }))}
        />
      );
    }
  };

  const telteplan = {
    label: t('Teleplan'),
    id: 'teleplan',
    template: (item) => {
      return (
        <Button
          className="p-button-text w-full"
          style={{ textAlign: 'left' }}
          label={item.label}
          onClick={() => handleResetAndAction(() => addNewTeleplan({ history, patient_details, replaceRoute }))}
        />
      );
    }
  };

  const telteplanPOS = {
    label: t('Teleplan_POS'),
    id: 'teleplanPOS',
    template: (item) => {
      return (
        <Button
          className="p-button-text w-full"
          style={{ textAlign: 'left' }}
          label={item.label}
          onClick={() => handleResetAndAction(() => addNewTeleplan({ history, patient_details, replaceRoute, pos: true }))}
        />
      );
    }
  };

  const telteplanGroup = {
    label: t('Teleplan_Batch'),
    id: 'teleplanGroup',
    template: (item) => {
      return (
        <Button
          className="p-button-text w-full"
          style={{ textAlign: 'left' }}
          label={item.label}
          onClick={() => handleResetAndAction(() => addNewTeleplan({ history, patient_details, replaceRoute, group: true }))}
        />
      );
    }
  };

  const wsbcrButton = {
    label: isSplitButton ? t('New_WSBC_Report') : t('WSBC_Report'),
    id: 'wsbcReport',
    template: (item, options) => {
      return (
        <Button
          id={elementIDs.createWSBCReportButton}
          className="p-button-text w-full"
          style={{ textAlign: 'left' }}
          label={item.label}
          onClick={() => handleResetAndAction(() => addNewWSBCR({ history, patient_details }))}
        />
      );
    }
  };

  const eClaim = {
    label: t('eClaim'),
    id: 'eClaim',
    template: (item, options) => {
      return (
        <Button
          id={elementIDs.createEClaimPolicyButton}
          className="p-button-text w-full"
          style={{ textAlign: 'left' }}
          label={item.label}
          onClick={() => handleResetAndAction(() => addEClaim({ history, patientGuid: patient_details?.PatientGuid }))}
        />
      );
    }
  };

  const eClaimPolicy = {
    label: t('eClaim_Policy'),
    id: 'eClaimPolicy',
    template: (item, options) => {
      return (
        <Button
          id={elementIDs.createWSBCReportButton}
          className="p-button-text w-full"
          style={{ textAlign: 'left' }}
          label={item.label}
          onClick={() => handleResetAndAction(() => addNewEClaimPolicy({ history, patientGuid: patient_details?.PatientGuid }))}
        />
      );
    }
  };

  if (isSplitButton) commonItems = commonItems.filter((i) => i.id !== 'teleplan');
  if (user.canBillMSP) {
    const canBillMSPCommonItems = showNewTeleplanButtons ? [telteplan, ...commonItems] : [telteplan, telteplanPOS, telteplanGroup, ...commonItems];
    if (!patient_details) commonItems = [firstVisitTelteplan, ...canBillMSPCommonItems];
    if (patient_details) commonItems = canBillMSPCommonItems;
  }
  if (user.canBillWCB) commonItems = [...commonItems, wsbcrButton];
  if (patient_details && showPolicy()) commonItems = [...commonItems, eClaim, eClaimPolicy];

  return commonItems;
};
