import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import { InputWrapper } from '../../../../../../../components/Inputs';
import OutOfOfficeHoursPremiums from './Common/OutOfOfficeHoursPremiums';

import { inputs } from '../../../helpers/inputs';
import { usePreferences } from '../../../hooks/usePreferences';
import { hideEmergency } from '../../../helpers/inputsConditions';
import { t } from '../../../../../../../service/localization/i18n';
import OOFHPInfoIcont from '../Layouts/OOFHPInfoIcont';

const OOFHP = () => {
  const { control, isNew, isGroup } = useFormContext();
  const { isLockedPrefsForEmergency, onSavePreferencesForEmergency } = usePreferences();
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const hideEmergencySection = hideEmergency({ isGroup, isNew, speciality, invoiceType });

  // [KS] CMO-1239 - Hide Emergency fields for midwives
  // CMO-1488 - Hidden fields and default Fee code for specialty 44
  if (hideEmergencySection) return null;

  return (
    <div className="pt-2 pb-3">
      <InputWrapper
        name="outOfOfficeHoursPremiums"
        label={t('Out_of_office_hours_premiums')}
        isLocked={isLockedPrefsForEmergency()}
        onSavePrefs={onSavePreferencesForEmergency}
        labelStyle={{ paddingBottom: '12px' }}
        labelOptionalContent={<OOFHPInfoIcont />}
      >
        <OutOfOfficeHoursPremiums />
      </InputWrapper>
    </div>
  );
};

export default OOFHP;
