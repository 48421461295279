import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import ViewPanelSkeleton from './Skeletons/ViewPanelSkeleton';
import PrivateInvoiceServices from './PrivateInvoiceServices';
import FacilityLayout from '../../../components/Layouts/Facility/FacilityLayout';
import CircularProgress from '../../../components/Misc/Loader/CircularProgress/CircularProgress';
import ClaimStatusAvatar from '../../../components/Avatars/ClaimStatusAvatar/ClaimStatusAvatar';
import TimeWithDuration from '../../../components/Layouts/Time/TimeWithDuration';

import {
  setClaimIndex,
  getClaimList,
  getClaims4Scroll,
  setClaimInfo,
  setDirection,
  setDefaultClaimsTableValues,
  setFilterByUserForInvoicesViewPanel,
  getClaimInfo
} from '../actions/claims.action.creators';
import { rural_codes, location_codes } from '../../config/constants';
import { submissionCodes } from '../helpers/mapper';
import { icons } from '../../config/stylesConfig';
import { formatDate } from '../../utils/formatDate';
import { currencyFormat } from '../../utils/currencyFormat';
import { commonInputs } from '../../config/commonInputsConfig';
import { isEmpty } from 'lodash';
import { elementIDs } from '../../config/elementIDsConfig';
import { convertReferralValue } from '../helpers/convertReferralValue';
import { patientFullNameWithAge } from '../../utils/patientFullName';
import { allRelationshipOptions } from './EClaimPolicy/helpers/dropdownOptions';
import { getRemittanceLink } from '../../utils/getLink';
import { formatPHN } from '../../utils/formatPHN';
import { t } from '../../../service/localization/i18n';
import classes from './ViewPanel.module.scss';
import moment from 'moment';
import cx from 'classnames';
import { getEClaimFormName } from '../../utils/getEClaimFormName';
import ClaimMenu from './ClaimDetails/ClaimMenu';
import PaymenstDialog from './PaymenstDialog';

const ViewPanel = (props) => {
  const { showPagination = true, notesCounter = true } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    active_item,
    active_offset,
    page,
    sortby,
    filters,
    pageSize,
    totalPages,
    claim_list,
    claim_details,
    claim_index,
    totalRecords,
    isSavingClaims,
    isArchiving,
    isFetchingClaims,
    isFetchingClaimHistory,
    filterByUserForInvoicesViewPanel
  } = useSelector((state) => state.claims);
  const { isMobile, isMobileOnly, isLandscape } = useSelector((state) => state.core.window);
  const { showDataScroller, readonly } = useSelector((state) => state.core);
  const { PatientGuid } = useSelector((state) => state.patients.patient_details);

  const [contentFirst, setContentFirst] = useState(0);
  const [claimRecord, setClaimRecord] = useState({});
  const [claimRecordForPayments, setClaimRecordForPayments] = useState({});
  const [showPaymentsDialog, setShowPaymentsDialog] = useState(false);

  const cerrentClaimMobile = active_item + active_offset;
  const currentClaim = isMobileOnly && showDataScroller ? cerrentClaimMobile : contentFirst + 1;
  const showSeq = claim_details?.Status === 0 || claim_details?.Status === 4 ? false : true;
  const ruralCode = rural_codes?.find((i) => i.value === claimRecord?.RuralCode);
  const locationCode = location_codes?.find((i) => i.value === claimRecord?.LocationCode);
  const submissionCode = claimRecord?.SubmissionCode ? submissionCodes?.find((i) => i.value === claimRecord?.SubmissionCode) : submissionCodes[0];
  const refName = claimRecord?.RefName ? claimRecord?.RefName : '';
  const claimNo = claimRecord.ClaimNumber ? `#${claimRecord.ClaimNumber}` : t('N_A');
  const insured = allRelationshipOptions?.find((i) => i.value?.toUpperCase() === claimRecord?.Relationship?.toUpperCase())?.label || t('N_A');
  const isEClaim = claimRecord?.ClaimType === 'E';

  // set initial claim details
  useEffect(() => {
    setClaimRecord(claim_details);
  }, [claim_details]);

  useEffect(() => {
    const index = claim_list?.findIndex((i) => i.InvoiceGuid === claim_details?.InvoiceGuid);
    if (index === -1 && claim_list?.length > 0) {
      //debugger; // should never happen!
    } else {
      if (index !== -1) dispatch(setClaimIndex(index));
      if (active_offset === 0 && claim_list?.length > 0 && index === claim_index) {
        dispatch(setDirection(index + 1));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim_details, claim_list, dispatch]);

  // set contentFirst
  useEffect(() => {
    let offset = 0;
    const claim = claim_list?.find((i) => i.InvoiceGuid === claimRecord?.InvoiceGuid);
    if (page > 1) {
      offset = (page - 1) * pageSize;
    }

    const mobileContentFirst = page > 2 ? pageSize + claim_list.indexOf(claim) : offset + claim_list.indexOf(claim);
    const _contentFirst = isMobileOnly && showDataScroller ? mobileContentFirst : offset + claim_list.indexOf(claim);

    setContentFirst(_contentFirst);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimRecord?.InvoiceGuid, claim_index, claim_list, page, pageSize, isMobileOnly]);

  //component will unmount
  useEffect(() => {
    return () => {
      dispatch(setFilterByUserForInvoicesViewPanel(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOnePage = (dir) => {
    let newPage = '';
    const userId = filterByUserForInvoicesViewPanel ? PatientGuid : 'undefined';
    if (dir === 'nextPage') newPage = page + 1;
    if (dir === 'prevPage') newPage = page - 1;
    if (dir === 'firstPage') newPage = 1;
    if (dir === 'lastPage') newPage = totalPages;

    if (isMobileOnly && showDataScroller) {
      dispatch(
        getClaims4Scroll(
          {
            page: newPage,
            pageSize: pageSize,
            filter: JSON.stringify(filters),
            query: `pid=${userId}&sortby=${sortby}&q=${JSON.stringify(filters)}&dir=${dir}`
          },
          true,
          dir
        )
      );
    } else {
      dispatch(
        getClaimList(
          {
            page: newPage,
            pageSize: pageSize,
            filter: JSON.stringify(filters),
            query: `pid=${userId}&sortby=${sortby}&q=${JSON.stringify(filters)}`
          },
          true,
          dir
        )
      );
    }
  };

  const navigateOnPage = (dir) => {
    let direction = dir === 'nextPage' ? 1 : dir === 'prevPage' ? -1 : 0;
    dispatch(setDirection(direction));

    if (dir === 'nextPage') {
      if (claim_list.length === claim_index + 1) {
        getOnePage(dir);
      } else {
        const nextClaim = claim_list?.find((i, index) => index === claim_index + 1);
        setClaimRecord(nextClaim);
        //set claim to highlight in in DataTable/DataScroller
        dispatch(setClaimInfo(nextClaim));
      }
    }

    if (dir === 'prevPage') {
      if (claim_index === 0) {
        getOnePage(dir);
      } else {
        const nextClaim = claim_list?.find((i, index) => index === claim_index - 1);
        setClaimRecord(nextClaim);
        //set claim to highlight in in DataTable/DataScroller
        dispatch(setClaimInfo(nextClaim));
      }
    }

    if (dir === 'firstPage') {
      if (page === 1) {
        setClaimRecord(claim_list[0]);
        //set claim to highlight in in DataTable/DataScroller
        dispatch(setClaimInfo(claim_list[0]));
      } else {
        getOnePage(dir);
      }
    }

    if (dir === 'lastPage') {
      if (page === totalPages) {
        setClaimRecord(claim_list[claim_list.length - 1]);
        //set claim to highlight in in DataTable/DataScroller
        dispatch(setClaimInfo(claim_list[claim_list.length - 1]));
      } else {
        getOnePage(dir);
      }
    }
  };

  const claimsNotFoundOnClose = () => {
    history.goBack();
    //set default values for data table
    dispatch(setDefaultClaimsTableValues());

    //reset highlighted element for DataScroller
    dispatch(setClaimInfo({}));
  };

  const onPayment = () => {
    setShowPaymentsDialog(true);

    if (isEmpty(claimRecordForPayments) || claimRecordForPayments?.InvoiceGuid !== claimRecord?.InvoiceGuid) {
      dispatch(
        getClaimInfo(claimRecord?.InvoiceGuid, (responseData) => {
          setClaimRecordForPayments(responseData);
        })
      );
    }
  };

  const practitionerLayout = () => {
    if (claimRecord?.ClaimType === 'E') {
      return (
        <div className={cx('flex py-0 pl-0', isMobileOnly && !isLandscape ? 'col-12' : 'col-6 pr-6')}>
          <div className="flex">
            <i className={cx(icons.claimViewDoctorIcon, classes.iconWrap)} />
          </div>

          <div className="flex flex-column gap-1">
            <div className="font-bold white-space-normal">{`${claimRecord?.TreatedBy?.toUpperCase()}`}</div>

            <div className="white-space-normal">{`Licence #${claimRecord.LicenseId}`}</div>

            {claimRecord?.LocationName && <div className="white-space-normal">{`${claimRecord.LocationName}`}</div>}
          </div>
        </div>
      );
    }

    return (
      <div className={cx('flex py-0 pl-0', isMobileOnly && !isLandscape ? 'col-12' : 'col-6 pr-6')}>
        <div className="flex">
          <i className={cx(icons.claimViewDoctorIcon, classes.iconWrap)} />
        </div>

        <div>
          <div className="mb-1 font-bold">{`${claimRecord?.TreatedBy?.toUpperCase()}`}</div>
          {claimRecord.InvoiceType === 'Private' ? (
            // Private invoice
            <>
              {claimRecord && claimRecord[commonInputs.practitioner.licenceNumber] && (
                <div className="mb-1">{`${t('Licence')} # ${claimRecord[commonInputs.practitioner.licenceNumber]}`}</div>
              )}
              {claimRecord?.LocationName && <div className="mb-1">{claimRecord?.LocationName}</div>}
            </>
          ) : (
            <>
              <div className="mb-1">{`${t('Practitioner')}/${t('Payee')}: #${claimRecord?.PractitionerNumber}/#${claimRecord?.PayeeNumber}`}</div>

              <div className="mb-1">
                <FacilityLayout
                  data={{
                    facility: claimRecord?.FacilityNum,
                    subFacility: claimRecord?.SubFacility
                  }}
                />
              </div>

              {ruralCode && <div className="mb-1">{ruralCode.label === 'N/A' ? t('No_rural_code') : ruralCode.label}</div>}

              {locationCode?.label && (
                <div className="mb-1">{locationCode?.label === 'N/A' ? `${t('Location_code')}: ${locationCode?.label}` : locationCode?.label}</div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const patientLayout = () => {
    if (claimRecord?.ClaimType === 'E') {
      return (
        <div className={cx('flex p-0', isMobileOnly && !isLandscape ? 'col-12 mt-3' : 'col-6')}>
          <div className="flex">
            <i className={cx(icons.patient, classes.iconWrap)} />
          </div>

          <div className="flex flex-column gap-2">
            <div className="flex flex-column gap-1">
              <div className="mb-1 font-bold">{patientFullNameWithAge(claimRecord)}</div>
              <div className="white-space-normal">{`DOB: ${moment(claimRecord?.BirthDay).format('ll')}`}</div>
            </div>

            <div className="flex flex-column gap-2">
              <div className="flex flex-column gap-1">
                <div className="white-space-normal font-bold">{`${claimRecord?.InsurerName}`}</div>
                <div className="white-space-normal">{`Plan #${claimRecord?.PolicyNum}`}</div>
                <div className="white-space-normal">{`Member #${claimRecord?.MemberID}`}</div>
                <div className="white-space-normal">{`Insured: ${insured}`}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={cx('flex p-0', isMobileOnly && !isLandscape ? 'col-12 mt-3' : 'col-6')}>
        <div className="flex">
          <i className={cx(icons.patient, classes.iconWrap)} />
        </div>

        <div>
          <div className="mb-1 font-bold">{patientFullNameWithAge(claimRecord)}</div>
          <div className="mb-1">{`DOB: ${moment(claimRecord?.BirthDay).format('ll')}`}</div>
          {claimRecord?.PHN ? (
            <div>{`${formatPHN(claimRecord?.PHN, claimRecord?.PHNProvince)}, ${claimRecord?.PHNProvince && claimRecord?.PHNProvince}`}</div>
          ) : (
            <div>{`${t('PHN')}: ${t('N_A')}`}</div>
          )}
          {claimRecord?.RefNumber && (
            <div className="mb-1">
              {claimRecord?.RefToBy &&
                `${t('Referred.1')} ${convertReferralValue(claimRecord?.RefToBy).toLowerCase()} ${refName} #${claimRecord?.RefNumber}`}
            </div>
          )}
        </div>
      </div>
    );
  };

  const onDeletePayment = () => {
    const _callback = (list) => {
      const current = list?.find((i) => i.InvoiceGuid === claimRecord.InvoiceGuid);
      setClaimRecord?.(current);
    };

    const userId = filterByUserForInvoicesViewPanel ? PatientGuid : 'undefined';
    dispatch(
      getClaimList(
        {
          page,
          pageSize: pageSize,
          filter: JSON.stringify(filters),
          query: `pid=${userId}&sortby=${sortby}&q=${JSON.stringify(filters)}`
        },
        false,
        false,
        (claimsList) => _callback(claimsList)
      )
    );
  };

  const paymentsLayout = (
    <>
      {claimRecord?.PaymentCount > 0 && (
        <div className="flex align-items-center">
          <Button
            className="p-button-link p-0 mr-1"
            label={`${claimRecord?.PaymentCount} ${claimRecord?.PaymentCount > 1 ? t('Payments.1').toLowerCase() : t('Payments.2').toLowerCase()}`}
            aria-haspopup
            aria-controls="overlay_panel"
            onClick={onPayment}
          />
          <div className="flex align-items-center" style={{ marginBottom: '3px', fontSize: '1.1rem' }}>
            {`${t('made_on')} ${moment(claimRecord?.PaymentDate).format('MM/DD/YYYY')}`}
          </div>
        </div>
      )}
    </>
  );

  const getRemittanceNumber = () => {
    if (isEClaim) {
      return `${getEClaimFormName(claimRecord?.RemittanceCode)}${claimRecord.SequenceNumber ? ` #${claimRecord.SequenceNumber}` : ''}`;
    }
    if (claimRecord?.SequenceNumber && claimRecord?.RemittanceGuid) {
      return t('Remittance');
    }
    return '';
  };

  return (
    <div id={elementIDs.claimInfoRolodex} className={isMobileOnly ? 'col-12 px-3' : 'flex justify-content-center mt-2'}>
      {(isFetchingClaims || isFetchingClaimHistory || isSavingClaims || isArchiving) && <CircularProgress />}

      <div className={!isMobileOnly ? classes.card : ''}>
        {isEmpty(claim_details) && isFetchingClaims ? (
          <ViewPanelSkeleton />
        ) : isEmpty(claim_details) && !isFetchingClaims ? (
          <div className="flex flex-column align-items-center mt-5">
            <h5>{t('No_claims_found')}</h5>
            <div>
              <Button className="p-button-text" label={t('Close')} icon="pi pi-times" onClick={claimsNotFoundOnClose} />
            </div>
          </div>
        ) : (
          <>
            {showPagination && (
              <div className={isMobile ? classes.paginatorRoot : 'flex col-12 p-0'}>
                <div className="col-12 p-0">
                  {/* <Paginator
                  first={contentFirst}
                  rows={1}
                  totalRecords={totalRecords}
                  onPageChange={onContentPageChange}
                  template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                /> */}

                  <div className="p-paginator p-component">
                    <Button
                      className={
                        contentFirst === 0 || isFetchingClaims
                          ? 'p-paginator-prev p-paginator-element p-link p-disabled'
                          : 'p-paginator-first p-paginator-element p-link'
                      }
                      icon="p-paginator-icon pi pi-angle-double-left"
                      onClick={() => navigateOnPage('firstPage')}
                    />
                    <Button
                      className={
                        contentFirst === 0 || isFetchingClaims
                          ? 'p-paginator-prev p-paginator-element p-link p-disabled'
                          : 'p-paginator-prev p-paginator-element p-link'
                      }
                      icon="p-paginator-icon pi pi-angle-left"
                      onClick={() => navigateOnPage('prevPage')}
                    />
                    <span className="p-paginator-current">{`${currentClaim} of ${totalRecords}`}</span>
                    <Button
                      className={
                        (page === totalPages && claim_index === claim_list.length - 1) || isFetchingClaims
                          ? 'p-paginator-next p-paginator-element p-link p-disabled'
                          : 'p-paginator-next p-paginator-element p-link'
                      }
                      icon="p-paginator-icon pi pi-angle-right"
                      onClick={() => navigateOnPage('nextPage')}
                    />
                    <Button
                      className={
                        (page === totalPages && claim_index === claim_list.length - 1) || isFetchingClaims
                          ? 'p-paginator-last p-paginator-element p-link p-disabled'
                          : 'p-paginator-last p-paginator-element p-link'
                      }
                      icon="p-paginator-icon pi pi-angle-double-right"
                      onClick={() => navigateOnPage('lastPage')}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="mb-2">
              <div className={cx('mt-1', isMobileOnly ? 'contentMarginBottom' : 'card')}>
                {/* HEADER */}
                <div className={cx('flex justify-content-between mb-3 pb-3', classes.borderBottomLine)}>
                  <div className="flex flex-column align-items-start justify-content-center">
                    <div className="flex font-bold extra-large-text">
                      {`${claimRecord?.ClaimType === 'E' ? 'eClaims' : claim_details?.RecType} #${claim_details?.RecordNo}`}
                    </div>

                    {showSeq && (
                      <div className={cx('flex align-items-center mt-1', classes.sequenceRoot)}>
                        {claimRecord?.SequenceNumber && `${t('Sequence.1')}. #${claimRecord?.SequenceNumber}`}
                      </div>
                    )}
                  </div>

                  <div className="flex p-0 align-items-center mb-1">
                    <ClaimMenu claimRecord={claimRecord} setClaimRecord={setClaimRecord} notesCounter={notesCounter} />
                  </div>
                </div>

                {/* BODY */}
                <>
                  <div className={classes.borderBottomLine}>
                    <div className={cx('col-12 p-0 mb-2', isMobileOnly && !isLandscape ? '' : 'flex justify-content-between')}>
                      {/* Doctor */}
                      {practitionerLayout()}

                      {/* Patient */}
                      {patientLayout()}
                    </div>
                  </div>

                  {/* Codes */}
                  <div>
                    <div className="mt-3">
                      <div className="flex font-bold mb-1">
                        <>{formatDate(claimRecord?.ServiceDate).toUpperCase()}</>
                        {(claimRecord?.StartTime || claimRecord?.FinishTime) && (
                          <div className="ml-4">
                            <TimeWithDuration startTime={claimRecord?.StartTime} endTime={claimRecord?.FinishTime} />
                          </div>
                        )}
                      </div>

                      {(claimRecord.ClaimType === 'W' || claimRecord.ClaimType === 'R') && (
                        <>
                          <div className="mb-1">{`${t('Claim')}: ${claimNo}`}</div>
                          <div className="mb-1">{`${t('Date_of_injury.1')}: ${formatDate(claimRecord.InjuryDay)}`}</div>
                        </>
                      )}

                      {claimRecord.InvoiceType !== 'Private' && !isEClaim && <div className="mb-1">{submissionCode?.label}</div>}
                    </div>

                    <div className="mt-4">
                      <div className="flex align-items-start">
                        <ClaimStatusAvatar className="mt-2" status={claimRecord?.Status} statusText={claimRecord?.StatusText} />
                        {claimRecord?.InvoiceType === 'Private' ? (
                          // Private invoice
                          <div className="w-full">
                            <div className="flex align-items-start justify-content-between col-10 p-0 ml-4 h-100">
                              <PrivateInvoiceServices claimRecord={claimRecord} />
                              {(!isMobileOnly || (isMobileOnly && isLandscape)) && paymentsLayout}
                            </div>

                            <div className="ml-4 py-3">{`${t('Owes')}: ${currencyFormat(claimRecord.FeeTotal - claimRecord.FeePaid)}`}</div>
                          </div>
                        ) : (
                          // NOT Private invoice
                          <div className="ml-4">
                            <div className="mb-1 font-bold medium-size-text">
                              <div className="mr-4">
                                {claimRecord?.FeeCode &&
                                  claimRecord?.NoOfServiceUnits &&
                                  claimRecord?.ICD9Code &&
                                  `${claimRecord?.FeeCode} x ${claimRecord?.NoOfServiceUnits} (${t('Dx')} ${claimRecord.ICD9Code})`}
                              </div>
                            </div>

                            <div className="medium-size-text">
                              <div className="font-bold">
                                {`${currencyFormat(claimRecord?.FeeAmount)} x ${claimRecord?.NoOfServiceUnits} = ${currencyFormat(
                                  claimRecord?.FeeTotal
                                )}`}
                              </div>
                            </div>

                            <div className="mt-4">
                              {claimRecord?.Comment && (
                                <div className="flex my-3">
                                  <div className={cx('font-bold', classes.codeTitle)}>{`${t('Note')}:`}</div>
                                  <div className={classes.codeTitle}>{claimRecord?.Comment}</div>
                                </div>
                              )}

                              {claimRecord?.ICD9Code ? (
                                <div className="flex">
                                  <div className={cx('font-bold', classes.codeTitle)}>{`${t('Dx')}:`}</div>

                                  <div className="flex flex-column">
                                    {claimRecord?.ICD9Code?.split(',').map((i, index) => {
                                      return (
                                        <div className="flex mb-1" key={`${i}_${index}`}>
                                          <div className={classes.codeTitle}>{`${i} - `}</div>
                                          <div>{claimRecord?.ICD9Text[index] || t('N_A')}</div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              ) : null}

                              {claimRecord?.FeeCode && (
                                <div className="mb-1 my-3">
                                  {!claimRecord?.FeeCodeText && (
                                    <div className="flex">
                                      {claimRecord?.ClaimType === 'E' ? (
                                        <div className={classes.codeTitle}>{`${claimRecord?.FeeCode}`}</div>
                                      ) : (
                                        <>
                                          <div className={cx('font-bold', classes.codeTitle)}>{`${t('Fee')}:`}</div>
                                          <div className={classes.codeTitle}>{`${claimRecord?.FeeCode}`}</div>
                                        </>
                                      )}
                                    </div>
                                  )}

                                  {claimRecord?.FeeCodeText && (
                                    <div className="flex">
                                      {claimRecord?.ClaimType === 'E' ? (
                                        <div className={classes.codeTitle}>{`${claimRecord?.FeeCodeText}`}</div>
                                      ) : (
                                        <>
                                          <div className={cx('font-bold', classes.codeTitle)}>{`${t('Fee')}:`}</div>
                                          <div className={classes.codeTitle}>{`${claimRecord?.FeeCode} - `}</div>
                                          <div>{claimRecord?.FeeCodeText}</div>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}

                              {(claimRecord.ClaimType === 'W' || claimRecord.ClaimType === 'R') && (
                                <>
                                  {claimRecord?.AreaOfInjury ? (
                                    <div className="flex mb-3">
                                      <div className={cx('font-bold', classes.codeTitle)}>{`${t('Area_of_Injury.2')}:`}</div>
                                      <div className={classes.codeTitle}>{`${claimRecord?.AreaOfInjury} - `}</div>
                                      <div>{claimRecord?.AOIText}</div>
                                    </div>
                                  ) : null}

                                  {claimRecord?.NatureOfInjury ? (
                                    <div className="flex mb-3">
                                      <div className={cx('font-bold', classes.codeTitle)}>{`${t('Nature_of_Injury.2')}:`}</div>
                                      <div className={classes.codeTitle}>{`${claimRecord?.NatureOfInjury} - `}</div>
                                      <div>{claimRecord?.NOIText}</div>
                                    </div>
                                  ) : null}

                                  {claimRecord?.AACText ? (
                                    <div className="flex">
                                      <div className={cx('font-bold', classes.codeTitle)}>{`${t('Anatomic_Code.2')}:`}</div>
                                      <div>{claimRecord.AACText}</div>
                                    </div>
                                  ) : null}
                                </>
                              )}

                              <div className="mt-4 mb-1">
                                <div className="mb-1">{claimRecord?.SDate && `${t('Submission')}: ${moment(claimRecord?.SDate).format('ll')}`}</div>

                                {claimRecord?.Received && (
                                  <div className="flex mb-1">
                                    <div className={cx('font-bold', classes.codeTitle)}>{`${getRemittanceNumber()}: `}</div>
                                    <a className="ml-2" href={getRemittanceLink(claimRecord)} target="_blank" rel="noreferrer">
                                      {formatDate(claimRecord?.Received)}
                                    </a>
                                  </div>
                                )}

                                {Array.isArray(claimRecord?.MSPCodes) && (
                                  <div className="p-error mb-1">
                                    {claimRecord?.MSPCodes?.map((code, index) => {
                                      return (
                                        <div className="mb-2" key={`${code}_${index}`}>
                                          {code}
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {isMobileOnly && !isLandscape && paymentsLayout}
                  </div>
                </>
              </div>
            </div>
          </>
        )}
      </div>

      <PaymenstDialog
        data={claimRecordForPayments}
        showPaymentsDialog={showPaymentsDialog}
        setShowPaymentsDialog={setShowPaymentsDialog}
        onDeletePayment={onDeletePayment}
      />
    </div>
  );
};

export default ViewPanel;
