import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ProvincePicker from './index';
import { t } from '../../../service/localization/i18n';

/**
 * ControlledProvincePicker component renders a ProvincePicker component
 * controlled by react-hook-form's Controller.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} [props.required=false] - Flag to indicate if the field is required.
 * @returns {JSX.Element} The rendered ControlledProvincePicker component.
 */
const ControlledProvincePicker = ({ required = false, ...props }) => {
  const name = 'Province';
  const {
    control,
    watch,
    formState: { errors }
  } = useFormContext();

  const country = watch('Country');

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) => {
          if (required && (!value || value === 'N/A')) {
            return t('Mandatory_field.1');
          }
          return true; // Passes validation
        }
      }}
      render={({ field }) => <ProvincePicker {...field} {...props} id={name} country={country} className={errors[name] ? 'p-invalid' : ''} />}
    />
  );
};

export default ControlledProvincePicker;
