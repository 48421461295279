import actionTypes from '../actions/daysheet.action.types';

export const uploadInitialState = {};

const uploadReducer = (state = uploadInitialState, action) => {
  const { results } = action;
  switch (action.type) {
    default:
      return state;
  }
};

export default uploadReducer;
