import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';
import './Registration.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { contentfulOptions } from '../../modules/contentful/contentfulOptions';
import { getTermsOfUseAction } from '../../modules/auth/actions/auth.actions.creators';

const PrivacyTabs = ({ handleScroll }) => {
  const terms = useSelector((state) => state.user.termsOfUse);
  // const [scrollStatus, setScrollStatus] = useState(false);
  const dispatch = useDispatch();

  const tabOrder = [
    'terms-of-use',
    'schedule-definitions',
    'billing-and-payment-agreement',
    'third-party-service-agreement',
    'account-member-agreement',
    'data-sharing-agreement',
    'cookie-policy',
    'privacy-policy'
  ];

  useEffect(() => {
    dispatch(getTermsOfUseAction());
  }, []);

  return (
    <TabView scrollable style={{ maxWidth: '90%' }}>
      {terms?.items
        ?.filter((term) => term.fields.id !== 'cancel-letter' && term.fields.id !== 'accept-letter')
        .sort((a, b) => tabOrder.indexOf(a.fields.id) - tabOrder.indexOf(b.fields.id)) // Sort based on tabOrder
        .map((term) => (
          <TabPanel header={term.fields.title}>
            <div className="p-4 overflow-y-scroll text-800" style={{ height: '75vh' }} onScroll={(e) => handleScroll(e)}>
              <div className="mb-6 context-header">
                <h4 className="text-center">ACCLAIM CONSULTING INC.</h4>
                <h4 className="text-center">{term.fields.title}</h4>
              </div>
              {documentToReactComponents(term.fields.content, contentfulOptions())}
            </div>
          </TabPanel>
        ))}
    </TabView>
  );
};

export default PrivacyTabs;
