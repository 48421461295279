import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';
import { routes } from '../../../../routes/routes';
import { useDispatch } from 'react-redux';
import { resetState } from '../../../../store';

const ConfirmationSendingTempCode = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const loginRedirect = () => {
    dispatch(resetState()); // Reset redux state
    history.replace(routes.dashboard.path);
  };

  return (
    <AuthFormWrap>
      <div className="flex flex-column gap-3 mt-3">
        <div className="m-0 text-800 w-full text-center align-items-center font-bold text-3xl">Password Sent Confirmation</div>
        <div className="flex flex-column gap-5 my-4">
          <div className="flex line-height-3 text-800 text-lg">
            Your temporary password has been successfully sent to your registered email address. Please check your inbox (and spam/junk folder, if
            needed) for further instructions.If you do not receive the email within a few minutes, contact our support team for assistance. Thank you!
          </div>
          <div>
            <div className="flex flex-column gap-3">
              <div className="flex line-height-3 text-800 text-lg">You can return to the login page here:</div>
              <p className="flex flex-column align-items-center text-800">
                <Button link label="Go to Login Page" type="button" onClick={loginRedirect} className="p-0 text-lg" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </AuthFormWrap>
  );
};

export default ConfirmationSendingTempCode;
