import React from 'react';
import { inputs } from '../../../../../modules/claims/components/PrivateDetails/claimDetailsHelpers/inputs';

const NotesSection = ({ data }) => {
  return (
    <div className="invoice-notes h-full">
      <div className="flex flex-column justify-content-between h-full">
        <div>{data[inputs().messageOnInvoice.name] && <div className="pr-6">{data[inputs().messageOnInvoice.name]}</div>}</div>
      </div>
    </div>
  );
};

export default NotesSection;
