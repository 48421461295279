import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { capitalizeFirstLetter, cityValidation, latinLetters } from '../helpers/validation';

const City = ({ requiredStatus }) => {
  const {
    control,
    formState: { errors },
    clearErrors
  } = useFormContext();

  return (
    <>
      <InputWrapper name="city" label="City" required={requiredStatus} errorMessage={errors.city?.message}>
        <Controller
          name="city"
          control={control}
          rules={{ validate: requiredStatus ? cityValidation : latinLetters }}
          render={({ field }) => (
            <InputText
              {...field}
              id="city"
              data-testid="city-testid"
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(capitalizeFirstLetter(value));
                clearErrors('city');
              }}
              className={classNames({ 'p-invalid': errors.city })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default City;
