import heic2any from 'heic2any';

export const generateReadableFilename = (file, newExtension = null) => {
  const extension = newExtension || file.name.split('.').pop(); // Get file extension
  const now = new Date();

  const formattedTimestamp = now
    .toLocaleString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    })
    .replace(',', '')
    .replace(/[/]/g, '-')
    .replace(/[:]/g, '.');

  return `${formattedTimestamp}.${extension}`;
};

export const convertHeicToJpg = async (file) => {
  try {
    const convertedBlob = await heic2any({
      blob: file,
      toType: 'image/jpeg'
    });

    return new File([convertedBlob], generateReadableFilename(file, 'jpg'), { type: 'image/jpeg' });
  } catch (error) {
    console.error('HEIC to JPG conversion failed:', error);
    throw new Error('HEIC conversion error');
  }
};
