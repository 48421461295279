import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';
import InputPhoneActivation from './InputPhoneActivation';
import ChangePhoneActivation from './ChangePhoneActivation';

const PhoneVerificationActivation = () => {
  const { details } = useSelector((state) => state.user);
  const [visibleChangePhone, setVisibleChangePhone] = useState(true);

  const formatedPhoneNumber = details.Phone?.replace(/[^0-9]/g, '');
  const handleNumberPhoneBlur = (phone) => {
    return phone?.replace(/(\d{2})\d{6}(\d{2})/, '$1******$2');
  };

  const phoneDetails = formatedPhoneNumber ? `the phone number ${handleNumberPhoneBlur(formatedPhoneNumber)}` : ' your phone number';

  return (
    <AuthFormWrap>
      <div className="m-0 my-4 text-800 w-full text-center font-bold text-3xl">Verify Your Phone</div>
      {visibleChangePhone && (
        <div className="line-height-3 text-center text-800 text-lg">
          Enter the verification code that we sent to <br /> {phoneDetails}.
        </div>
      )}

      {visibleChangePhone ? (
        <InputPhoneActivation setVisibleChangePhone={setVisibleChangePhone} />
      ) : (
        <ChangePhoneActivation setVisibleChangePhone={setVisibleChangePhone} />
      )}
    </AuthFormWrap>
  );
};

export default PhoneVerificationActivation;
