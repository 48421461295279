import React from 'react';
import { InputWrapper } from '..';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { mspNumberValidation } from '../../../modules/registration/components/helpers/validation';

const MspPractitionerNumber = () => {
  const {
    control,
    formState: { errors },
    clearErrors
  } = useFormContext();

  return (
    <>
      <InputWrapper name="mspPractitionerNumber" label="MSP Practitioner Number" errorMessage={errors.mspPractitionerNumber?.message}>
        <Controller
          name="mspPractitionerNumber"
          control={control}
          rules={{ validate: mspNumberValidation }}
          render={({ field }) => (
            <InputText
              {...field}
              id="mspPractitionerNumber"
              data-testid="mspPractitionerNumber-testid"
              maxLength={5}
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(value.toUpperCase());
                value && errors.mspPractitionerNumber?.message && clearErrors('mspPractitionerNumber');
              }}
              className={classNames({ 'p-invalid': errors.mspPractitionerNumber })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default MspPractitionerNumber;
