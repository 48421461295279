import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'primereact/button';
import { setClaimFilters } from '../../../../../../claims/actions/claims.action.creators';
import { routes } from '../../../../../../../routes/routes';
import { claimsDefaultFilters } from '../../../../../../config/defaultValuesConfig';
import { setClaimsDialogVisibility } from '../../../../../actions/cashFlow.axtion.creators';
import { cashFlowInitialState } from '../../../../../reducers/cashFlow.reducer';
import { useClaimsListContext } from '../../../hooks/useClaimsListContext';

const ViewOnMoneyPageButton = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useClaimsListContext();

  const onClick = () => {
    const filters = { ...claimsDefaultFilters, ...state.query };
    dispatch(setClaimFilters(filters));
    dispatch(setClaimsDialogVisibility(cashFlowInitialState.dialogs.claimsDialog)); // Close dialog
    history.push(routes.invoices.path);
  };

  return <Button text rounded icon="pi pi-external-link" tooltip="View on Money page" tooltipOptions={{ position: 'top' }} onClick={onClick} />;
};

export default ViewOnMoneyPageButton;
