import { classNames } from 'primereact/utils';
import React from 'react';

const AuthTitle = (props) => {
  return (
    <div {...props} className={classNames('text-center text-2xl', props.className)}>
      Claim Manager: Discover the Bright Side of Billing
    </div>
  );
};

export default AuthTitle;
