import React, { useState, useRef } from 'react';

import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { practitionerTypeValidation } from '../helpers/validation';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { practitionerTypes } from '../helpers/defaultValues';
import { InputWrapper } from '../../../../components/Inputs';

const PractitionerType = () => {
  const ref = useRef(null);

  const practitionerNames = () => practitionerTypes.map((item) => item.label);
  const defaultPractitionerNames = practitionerNames();
  const [practitionerTypesNames, setPractitionerTypesNames] = useState(practitionerNames);

  const onPractitionerTypeSearch = (event) => {
    const query = event.query;
    if (query.trim() === '') {
      setPractitionerTypesNames(practitionerNames);
    } else {
      const filteredNames = defaultPractitionerNames.filter((name) => name.toLowerCase().includes(query.toLowerCase()));
      setPractitionerTypesNames(filteredNames);
    }
  };

  const {
    control,
    formState: { errors },
    trigger,
    setValue
  } = useFormContext();

  return (
    <>
      <InputWrapper name="practitionerType" label="Practitioner Type" required errorMessage={errors.practitionerType?.message}>
        <Controller
          name="practitionerType"
          control={control}
          rules={{ validate: practitionerTypeValidation }}
          render={({ field }) => (
            <AutoComplete
              {...field}
              ref={ref}
              dropdown
              forceSelection
              id="practitionerType"
              data-testid="practitionerType-testid"
              suggestions={practitionerTypesNames}
              value={field.value}
              completeMethod={onPractitionerTypeSearch}
              onChange={(e) => {
                field.onChange(e.value);
                // reset licencing body value
                setValue('licensingBody', '');
              }}
              onFocus={(e) => ref.current?.search(e, '', 'dropdown')}
              onBlur={() => trigger('practitionerType')}
              className={classNames({ 'p-invalid': errors.practitionerType })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default PractitionerType;
