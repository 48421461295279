import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { Button } from 'primereact/button';
import classes from './SuccessPage.module.scss';
import cx from 'classnames';
import { icons } from '../../../modules/config/stylesConfig';
import { ButtonWithBadge } from '../../Buttons';

const SuccessLayout = ({ successMessage, onSuccessMessageClick, successMessageAditionalAction }) => {
  const history = useHistory();
  const showLabelreader = true;
  const { isMobileOnly } = useSelector((state) => state.core.window);

  return (
    <div className="flex flex-column justify-content-center align-items-center gap-3">
      <i id={elementIDs.successIcon} className={cx('pi pi-check-circle', classes.successIcon)} />
      <div className="flex">
        <div
          id={elementIDs.successMessage}
          className={cx('flex justify-content-center col-12 p-0', classes.successMessage, onSuccessMessageClick ? 'pointer' : '')}
        >
          <span className="text-center" onClick={onSuccessMessageClick}>
            {successMessage}
          </span>
          {successMessageAditionalAction && successMessageAditionalAction}
        </div>
      </div>
      {showLabelreader && (
        <ButtonWithBadge value="Faster">
          <Button
            label="Upload Label"
            className="p-button-outlined"
            icon={isMobileOnly ? 'pi pi-camera' : 'pi pi-upload'}
            iconPos="left"
            onClick={() =>
              history.push({
                pathname: `${routes.createPatient.path}/new`,
                state: { activateAutofill: true }
              })
            }
          />
        </ButtonWithBadge>
      )}
    </div>
  );
};

const ErrorLayout = ({ successMessage, onSuccessMessageClick, successMessageAditionalAction }) => {
  return (
    <div className="flex flex-column justify-content-center align-items-center gap-3">
      <i id={elementIDs.errorIcon} className={cx(icons.warn, classes.errorIcon)} />
      <div className="flex">
        <div
          id={elementIDs.errorMessage}
          className={cx('flex justify-content-center col-12 p-0', classes.errorMessage, onSuccessMessageClick ? 'pointer' : '')}
        >
          <span className="text-center" onClick={onSuccessMessageClick}>
            {successMessage}
          </span>
          {successMessageAditionalAction && successMessageAditionalAction}
        </div>
      </div>
    </div>
  );
};

const SuccessPage = ({ children, ...props }) => {
  return (
    <div id={elementIDs.successPage} className="flex flex-column align-items-center justify-content-start gap-2 md:gap-5">
      <div className="flex flex-column align-items-center justify-content-start p-3 w-full">
        {props.isError ? <ErrorLayout {...props} /> : <SuccessLayout {...props} />}
      </div>

      {children}
    </div>
  );
};

export default SuccessPage;
