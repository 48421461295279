import React from 'react';
import { canadaProvincesListWithInstituonal } from '../../../../config/dropdownListsConfig';
import { t } from '../../../../../service/localization/i18n';

const ProvinceTemplate = ({ rowData }) => {
  const province = canadaProvincesListWithInstituonal.find((i) => i.value === rowData.PHNProvince?.toUpperCase())?.value || t('N_A');

  return <>{province}</>;
};

export default ProvinceTemplate;
