import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BureauVerify from './BureauVerify';
import { Button } from 'primereact/button';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';
import { routes } from '../../../../routes/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const obfuscateEmail = (email) => {
  if (email && email.indexOf('@') > -1) {
    const parts = email.split('@');
    const l1 = parts[0].length;
    return parts[0].substr(0, 1) + '*'.repeat(l1 - 2) + parts[0].substr(l1 - 1, 1) + '@' + parts[1];
  }
  return 'x'.repeat(10);
};

const VerificationBureau = () => {
  const { details: user } = useSelector((state) => state.user);
  const mfaEmail = obfuscateEmail(user?.Email);
  const [resendStatus, setResendStatus] = useState(false);
  const [emailStatus, setEmailStatus] = useState(true);
  const [timer, setTimer] = useState(60);
  const history = useHistory();

  useEffect(() => {
    if (!user?.JwtToken) history.push(routes.signIn.path);
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else {
      setResendStatus(true);
    }
  }, [timer]);

  const resendCode = async () => {
    setTimer(60);
    setResendStatus(false);
  };

  const changeEmailPhone = async () => {
    setEmailStatus((prev) => !prev);
  };

  return (
    <AuthFormWrap>
      <div className="m-0 my-4 text-800 w-full text-center font-bold text-3xl">User verification</div>
      <div className="flex justify-content-center text-center line-height-3 text-800 text-lg">
        Enter the verification code that we sent to <br /> {`${!emailStatus ? 'the phone number 12****789' : mfaEmail}`}.
      </div>
      <BureauVerify />

      <div className="my-3">
        {resendStatus ? (
          <p className="w-full mb-3 text-800 text-base">
            {`Haven't received the ${!emailStatus ? 'code' : 'email'}?`}{' '}
            <Button
              link
              label={`Resend the ${!emailStatus ? 'code' : 'code'}`}
              type="button"
              onClick={resendCode}
              style={{ width: '130px', paddingBottom: '2px', paddingLeft: '0px', color: '#607D8B', fontSize: '1rem' }}
            />
          </p>
        ) : (
          <p className="w-12 pt-2 mb-3 text-800 text-base">{`Haven't receive the ${!emailStatus ? 'text' : 'email'}? Resend the ${
            !emailStatus ? 'text' : 'email'
          } in a ${timer} seconds.`}</p>
        )}
        <Button
          link
          label={`Send the ${emailStatus ? 'text' : 'code'} to my ${emailStatus ? 'phone number' : 'email'} `}
          type="button"
          onClick={changeEmailPhone}
          className="text-left"
          style={{ paddingBottom: '2px', paddingLeft: '0px', color: '#607D8B', fontSize: '1rem' }}
        />
      </div>
    </AuthFormWrap>
  );
};

export default VerificationBureau;
