import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getAdminUrlParams } from '../utils/getLink';
import withAuthCondition from './withAuthCondition';

const withRedirectAdmin = (Component) => {
  return withAuthCondition(Component, () => {
    const location = useLocation();
    const params = getAdminUrlParams(location.search);
    return Boolean(params?.AdminCode && params?.DoctorGuid && params?.DCNGuid);
  });
};
export default withRedirectAdmin;
