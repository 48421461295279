import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '../../../../service/localization/i18n';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';
import { setForgotPassword, updateUser } from '../../actions/auth.actions.creators';
import { InputWrapper } from '../../../../components/Inputs';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { answerSecretQuestion, getFullListSecretQuestions } from '../../../../service/Lookup';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { routes } from '../../../../routes/routes';
import { resetState } from '../../../../store';
import { passwRecoveryTemporaryBlocked } from '../../../config/userStatuses';

const AskSecretQuestions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.details);
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    getValues
  } = useForm({
    defaultValues: {
      answer: ''
    },
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const [question, setQuestion] = useState([]);

  useEffect(() => {
    fetchSecretQuestionsLists();
  }, []);

  const fetchSecretQuestionsLists = async () => {
    try {
      const results = await getFullListSecretQuestions();
      const filtredGuidSecretQuestions = results.filter((item) => {
        return item.value === user.SecretAnswer.value;
      });
      setQuestion(filtredGuidSecretQuestions[0].label);
    } catch {
      return false;
    }
  };

  const handleAnswerChange = (e, field) => {
    const selectedValue = e.target.value;
    field.onChange(selectedValue);
  };

  const onSubmit = async (data) => {
    const payload = {
      QuestionGuid: user.SecretAnswer.value,
      answer_provided_by_the_user: data.answer
    };
    try {
      const SessionGuid = user?.SessionGuid;
      const answerStatus = await answerSecretQuestion(SessionGuid, payload); // return results.status (200 if answer correct or 202 if answer is incorrect)

      const passwordStatus = true;
      dispatch(setForgotPassword(passwordStatus, getValues('username')));

      if (answerStatus === 202) {
        dispatch(updateUser({ ...user, AnswerStatus: answerStatus }));
        history.replace(routes.verificationPhone.path);
        return;
      }

      history.replace(routes.confirmationSendingTempCode.path);
    } catch (error) {
      if (error === passwRecoveryTemporaryBlocked) {
        dispatch(updateUser({ ...user, UserStatus: 406 }));
        history.replace(routes.passwordRecoveryError.path);
        return;
      }

      setError('answer', {
        type: 'manual',
        message:
          'The answer you provided is incorrect. For security reasons, your account will be temporarily locked for 30 minutes. Please try again after the lockout period has ended or contact our support team if you need help.'
      });
    }
  };

  const goBack = () => {
    dispatch(resetState()); // Reset redux state
    history.replace(routes.dashboard.path);
  };

  const errorMessage = errors['answer']?.message;

  return (
    <AuthFormWrap>
      <div className="m-0 my-4 text-800 w-full text-center align-items-center font-bold text-3xl">Verify Your Identity</div>
      <div className="flex flex-column gap-5">
        <div className="flex line-height-3 text-800 text-lg">
          To secure your account, please answer the security question you set up during registration. This helps us confirm it's really you.
        </div>
        <div className="flex flex-column gap-2">
          <span className="font-italic text-xl font-medium">{question}</span>
          <div className="flex flex-column gap-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-column auth-form gap-6">
                <div className="h-4rem">
                  <InputWrapper
                    style={{ height: '65px', width: '100%' }}
                    name={'answer'}
                    label="Your Answer (case-sensitive)"
                    labelStyle={{ width: 'max-content' }}
                    errorMessage={errorMessage}
                    required
                  >
                    <Controller
                      name={'answer'}
                      control={control}
                      rules={{
                        required: 'Answer is required',
                        minLength: { value: 5, message: 'Min 5 characters' },
                        maxLength: { value: 100, message: 'Max 100 characters' },
                        pattern: {
                          value: /^[A-Za-z0-9'.,!? -]+$/,
                          message: 'Only latin letters allowed'
                        }
                      }}
                      render={({ field }) => (
                        <InputText
                          {...field}
                          id={'answer'}
                          type="text"
                          onChange={(e) => handleAnswerChange(e, field)}
                          className={classNames({ 'p-invalid': errors['answer'] })}
                        />
                      )}
                    />
                  </InputWrapper>
                </div>

                <div className="flex flex-column gap-4 h-10rem">
                  <Button id="submit" className="w-full h-3rem" label={t('Submit')} type="submit" loading={isSubmitting} />
                  <Button className="px-0" label="Go back to the registration page" type="button" link onClick={goBack} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AuthFormWrap>
  );
};

export default AskSecretQuestions;
