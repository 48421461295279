import React, { useContext } from 'react';
import Context from '../../../../../Context';
import { Button } from 'primereact/button';
import { elementIDs } from '../../../../config/elementIDsConfig';
import { toTitleCase } from '../../../../utils/toTitleCase';
import { formattedService } from '../claimDetailsHelpers/formatServices';
import { t } from '../../../../../service/localization/i18n';

const AddServiceButton = () => {
  const { records, setInitialRecords, isInvoicePatientForBalance } = useContext(Context);

  if (isInvoicePatientForBalance || records?.some((i) => i.id === '-1')) return null;

  return (
    <Button
      id={elementIDs.addService}
      text
      label={toTitleCase(t('Add_service'))}
      onClick={() => setInitialRecords([...records, formattedService()])}
    />
  );
};

export default AddServiceButton;
