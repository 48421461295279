export const elementIDs = {
  // Common elements
  successPage: 'successPage',
  toastMessage: 'toastMessage',
  inputErrorText: 'inputErrorText',
  patientPickerAutocomplete: 'patientPickerAutocomplete',
  patientPickerAutocompleteInput: 'patientPickerAutocompleteInput',
  locationPickerDropdown: 'locationPickerDropdown',
  payeePickerDropdown: 'payeePickerDropdown',
  accidentTypeDropdown: 'accidentTypeDropdown',
  prescriberTypeDropdown: 'prescriberTypeDropdown',
  payeeNumberPickerDropdown: 'payeeNumberPickerDropdown',
  payeeNumberPickerDropdownInput: 'payeeNumberPickerDropdownInput',
  practitionerPickerDropdown: 'practitionerPickerDropdown',
  practitionerPickerDropdownInput: 'practitionerPickerDropdownInput',
  unlockedIcon: 'unlockedIcon',
  lockedIcon: 'lockedIcon',
  customPrompt: 'customPrompt',
  noteInput: 'noteInput',
  warningConfirmation: 'warningConfirmation',
  deleteConfirmation: 'deleteConfirmation',
  successMessage: 'successMessage',
  errorMessage: 'errorMessage',
  localeUS: 'locale-us',
  mandatoryField: 'mandatoryField',
  patientsAutocompleteChip: 'patientsAutocompleteChip',
  newIncoiceButton: 'newIncoiceButton',
  cardTitle: 'cardTitle',
  claimNumberConfirmationDialogText: 'claimNumberConfirmationDialogText',
  catalogsTitle: 'catalogsTitle',
  quickPickPanel: 'quickPickPanel',
  noResentCodes: 'noResentCodes',
  catalogsDataScrollerCode: 'catalogsDataScrollerCode',
  catalogsDataScrollerDescriprion: 'catalogsDataScrollerDescriprion',
  catalogsSearch: 'catalogsSearch',
  mobileCatalogsTitle: 'mobileCatalogsTitle',
  mobileCatalogsSearchIcon: 'mobileCatalogsSearchIcon',
  statusChip: 'statusChip',
  clearSearchButton: 'clearSearchButton',
  catalogsTableCategory: 'catalogsTableCategory',
  slideMenuBackButton: 'slideMenuBackButton',
  successIcon: 'successIcon',
  BatchRecordsWarnDialogForOnePatient: 'BatchRecordsWarnDialogForOnePatient',
  sessionIdleDialog: 'sessionIdleDialog',

  // Claim info rolodex
  claimInfoRolodex: 'claimInfoRolodex',

  // action buttons
  no: 'no',
  yes: 'yes',
  close: 'close',
  saveAndClose: 'saveAndClose',
  exit: 'exit',
  dialogClose: 'dialogClose',
  dialogAccept: 'dialogAccept',
  next: 'next',
  review: 'review',
  cancel: 'cancel',
  next: 'next',
  back: 'back',
  ignore: 'ignore',
  editPatientProfile: 'editPatientProfile',
  dialogCancel: 'dialogCancel',
  submit: 'submit',
  submitAndClaim: 'submitAndClaim',
  create: 'create',
  createTemplate: 'createTemplate',
  save: 'save',
  edit: 'edit',
  delete: 'delete',
  duplicate: 'duplicate',
  dialogSave: 'dialogSave',
  viewMore: 'viewMore',
  openCatalog: 'openCatalog',
  logInButton: 'logInButton',
  usernameInput: 'usernameInput',
  passwordInput: 'passwordInput',
  addNewPatient: 'addNewPatient',
  addNewborn: 'addNewborn',
  addNewPractitioner: 'addNewPractitioner',
  catalogsSearchButton: 'catalogsSearchButton',
  editProfile: 'editProfile',
  deletePatient: 'deletePatient',
  createNewPatient: 'createNewPatient',
  saveChanges: 'saveChanges',
  openPatientProfile: 'openPatientProfile',
  goToPreviousPage: 'goToPreviousPage',
  patientProfileMenuThreeDotsButton: 'patientProfileMenuThreeDotsButton',
  newPatient: 'newPatient',
  newClaimSplitButton: 'newClaimSplitButton',
  addNote: 'addNote',
  newClaimButton: 'newClaimButton',
  closeSidebarButton: 'closeSidebarButton',
  threeDotsButton: 'threeDotsButton',
  editNameButton: 'editNameButton',
  expandAllButton: 'expandAllButton',
  nameButton: 'nameButton',
  ignoreButton: 'ignoreButton',
  goBackButton: 'goBackButton',
  refetchEligibility: 'refetchEligibility',
  NoRadio: 'NoRadio',
  YesRadio: 'YesRadio',

  // Home page
  homePage: 'homePage',
  homePageSearchContainer: 'homePageSearchContainer',
  ignoreSimilarRecords: 'ignoreSimilarRecords',

  // Patient profile
  patientProfile: 'patientProfile',
  editPatientButton: 'editPatientButton',
  patientName: 'patientName',
  patientDOB: 'patientDOB',
  patientPHN: 'patientPHN',
  patientEmail: 'patientEmail',
  patientEmailType: 'patientEmailType',
  patientPhone: 'patientPhone',
  patientPhoneType: 'patientPhoneType',
  patientAddressLine: 'patientAddressLine',
  patientCity: 'patientCity',
  patientZip: 'patientZip',

  // Create/Edit patient form
  invalidPHN: 'invalidPHN',
  nameMismatch: 'nameMismatch',
  nameMismatchDialog: 'nameMismatchDialog',
  nameMismatchDialogRootContent: 'nameMismatchDialogRootContent',
  createPatientRootContainer: 'createPatientRootContainer',
  editPatientRootContainer: 'editPatientRootContainer',
  createPatientCardTitle: 'createPatientCardTitle',
  editPatientCardTitle: 'editPatientCardTitle',
  duplicatePHNDialog: 'duplicatePHNDialog',
  openExistingProfile: 'openExistingProfile',
  useExistingProfile: 'useExistingProfile',
  checkEligibilityOnly: 'checkEligibilityOnly',
  checkMSPNameAndEligibility: 'checkMSPNameAndEligibility',
  useEligibilityDemographics: 'useEligibilityDemographics',
  male: 'male',
  female: 'female',
  unknown: 'unknown',
  genderWrap: 'genderWrap',

  // Create 1st visit teleplan
  duplicateRecordsAlert: 'duplicateRecordsAlert',
  noEligibilityReportText: 'noEligibilityReportText',
  elegibilityReportMSPText: 'elegibilityReportMSPText',
  servicePaidWrap: 'servicePaidWrap',
  eyeExamWrap: 'eyeExamWrap',
  elegibilityReport23Text: 'elegibilityReport23Text',
  elegibilityReportPremiumAssistanceStatusText: 'elegibilityReportPremiumAssistanceStatusText',

  // Create/Edit teleplan invoices
  quickPickButton: 'quickPickButton',
  teleplanInvoiceFormContainer: 'teleplanInvoiceFormContainer',
  createTeleplanInvoiceButton: 'createTeleplanInvoiceButton',
  createWSBCReportButton: 'createWSBCReportButton',
  createEClaimButton: 'createEClaimButton',
  createEClaimPolicyButton: 'createEClaimPolicyButton',
  newPolicyButton: 'newPolicyButton',
  createFirstVisitInvoiceButton: 'createFirstVisitInvoiceButton',
  editPanel: 'editPanel',
  generateInvoices: 'generateInvoices',
  payorDropdown: 'payorDropdown',
  bmiInput: 'bmiInput',
  facilityInput: 'facilityInput',
  subFacilityInput: 'subFacilityInput',
  emergencySwitch: 'emergencySwitch',
  oofPremiumsSwitch: 'oofPremiumsSwitch',
  travelSwitch: 'travelSwitch',
  ccfppSwitch: 'ccfppSwitch',
  NARadio: 'NARadio',
  ByRadio: 'ByRadio',
  ToRadio: 'ToRadio',
  feeItemsAutocomplete: 'feeItemsAutocomplete',
  feeItemsAutocompleteInput: 'feeItemsAutocompleteInput',
  icd9Autocomplete: 'icd9Autocomplete',
  icd9AutocompleteInput: 'icd9AutocompleteInput',
  noiAutocomplete: 'noiAutocomplete',
  noiAutocompleteInput: 'noiAutocompleteInput',
  aoiAutocomplete: 'aoiAutocomplete',
  aoiAutocompleteInput: 'aoiAutocompleteInput',
  referralAutocomplete: 'referralAutocomplete',
  referralAutocompleteInput: 'referralAutocompleteInput',
  ruralCodeAutocomplete: 'ruralCodeAutocomplete',
  ruralCodeAutocompleteInput: 'ruralCodeAutocompleteInput',
  locationCodeDropdown: 'locationCodeDropdown',
  locationCodeAutocomplete: 'locationCodeAutocomplete',
  locationCodeAutocompleteInput: 'locationCodeAutocompleteInput',
  claimNumberAutocomplete: 'claimNumberAutocomplete',
  claimNumberAutocompleteInput: 'claimNumberAutocompleteInput',
  startTimeInput: 'startTimeInput',
  endTimeInput: 'endTimeInput',
  callTimeInput: 'callTimeInput',
  unitsInput: 'unitsInput',
  percentInput: 'percentInput',
  injuryDay: 'injuryDay',
  payeeNumberInput: 'payeeNumberInput',
  anatomicCode: 'anatomicCode',
  reviewPanel: 'reviewPanel',
  reviewPanelPatient: 'reviewPanelPatient',
  reviewPanelPractitioner: 'reviewPanelPractitioner',
  teleplanRecordsTablePatientLabel: 'teleplanRecordsTablePatientLabel',
  teleplanRecordsTablePatientName: 'teleplanRecordsTablePatientName',
  teleplanRecordsTablePractitionerLabel: 'teleplanRecordsTablePractitionerLabel',
  teleplanRecordsTablePractitionerName: 'teleplanRecordsTablePractitionerName',
  teleplanRecordsTableDOSLabel: 'teleplanRecordsTableDOSLabel',
  teleplanRecordsTableDOS: 'teleplanRecordsTableDOS',
  teleplanRecordsTableNoteLabel: 'teleplanRecordsTableNoteLabel',
  teleplanRecordsTableNoteValue: 'teleplanRecordsTableNoteValue',
  reviewPanelDOS: 'reviewPanelDOS',
  codeColumn: 'codeColumn',
  dxColumn: 'dxColumn',
  feeColumn: 'feeColumn',
  fromColumn: 'fromColumn',
  toColumn: 'toColumn',
  unitsColumn: 'unitsColumn',
  percentColumn: 'percentColumn',
  billedColumn: 'billedColumn',
  reviewTotal: 'reviewTotal',
  reviewButton: 'reviewButton',
  previewPanel: 'previewPanel',
  previewPractitioner: 'previewPractitioner',
  previewPayeeNum: 'previewPayeeNum',
  previewRuralCode: 'previewRuralCode',
  previewLocation: 'previewLocation',
  previewReferral: 'previewReferral',
  previewInvoiceType: 'previewInvoiceType',
  previewWSBCClaimNumber: 'previewWSBCClaimNumber',
  previewSubmitionCode: 'previewSubmitionCode',
  previewTotalAmount: 'previewTotalAmount',
  previewLocationIcon: 'previewLocationIcon',
  previewLocationTooltip: 'previewLocationTooltip',
  previewReferralIcon: 'previewReferralIcon',
  previewReferralTooltip: 'previewReferralTooltip',
  previewCodesDetailsIcon: 'previewCodesDetailsIcon',
  previewCodesDetailsTooltip: 'previewCodesDetailsTooltip',
  previewFeeCodeTooltip: 'previewFeeCodeTooltip',
  previewFeeCodeTextTooltip: 'previewFeeCodeTextTooltip',
  previewAOICodeTooltip: 'previewAOICodeTooltip',
  previewAOICodeTextTooltip: 'previewAOICodeTextTooltip',
  previewNOICodeTooltip: 'previewNOICodeTooltip',
  previewNOICodeTextTooltip: 'previewNOICodeTextTooltip',
  noteText: 'noteText',
  noClaimsToDisplay: 'noClaimsToDisplay',
  expandAll: 'expandAll',
  clearClaimDataButton: 'clearClaimDataButton',
  claimNumberConfirmation: 'claimNumberConfirmation',
  createNewCalim: 'createNewCalim',
  modifySelectedClaim: 'modifySelectedClaim',
  selectedPatients: 'selectedPatients',
  submissionCodes: 'submissionCodes',
  saveAsDraft: 'saveAsDraft',
  authorizationFormCheckbox: 'authorizationFormCheckbox',
  recordNumber: 'recordNumber',
  sequenceNumber: 'sequenceNumber',
  feeAmountInput: 'feeAmountInput',
  feeTotalInput: 'feeTotalInput',
  reviewAndSubmitButton: 'reviewAndSubmitButton',
  expansionTemplate: 'expansionTemplate',
  emergencySectionWrap: 'emergencySectionWrap',
  teleplanPractitionerPanel: 'teleplanPractitionerPanel',
  billing: 'billing',

  // Create/Edit private invoices
  privateInvoiceFormContainer: 'privateInvoiceFormContainer',
  createPrivateInvoiceButton: 'createPrivateInvoiceButton',
  savePrivateInvoiceButton: 'savePrivateInvoiceButton',
  quickPickCodeItem: 'quickPickCodeItem',
  quickPickServiceItemsContainer: 'quickPickServiceItemsContainer',
  privateInvoiceCard: 'privateInvoiceCard',
  payToEditButton: 'payToEditButton',
  billToEditButton: 'billToEditButton',
  editPayeeAndPayerDialog: 'editPayeeAndPayerDialog',
  sidebarCatalogs: 'sidebarCatalogs',
  serviceDate: 'serviceDate',
  multipleServiceDate: 'multipleServiceDate',
  dueDate: 'dueDate',
  status: 'status',
  invoiceDate: 'invoiceDate',
  termsDropdown: 'termsDropdown',
  billToAddressWrap: 'billToAddressWrap',
  payToAddressWrap: 'payToAddressWrap',
  payToDropdown: 'payToDropdown',
  selectServiceAutocompleteInput: 'selectServiceAutocompleteInput',
  columnNoteBody: 'columnNoteBody',
  quantityInput: 'quantityInput',
  feeInput: 'feeInput',
  salesTaxInput: 'salesTaxInput',
  discountInput: 'discountInput',
  discountUnitsInput: 'discountUnitsInput',
  reviewPractitioner: 'reviewPractitioner',
  addService: 'addService',
  payeeSection: 'payeeSection',
  invoiceNumber: 'invoiceNumber',
  billToSection: 'billToSection',
  serviceBodyTemplate: 'serviceBodyTemplate',
  providerBodyTemplate: 'providerBodyTemplate',
  discoutnBodyTemplate: 'discoutnBodyTemplate',
  quantityBodyTemplate: 'quantityBodyTemplate',
  feeCurrencyBodyTemplate: 'feeCurrencyBodyTemplate',
  amountCurrencyBodyTemplate: 'amountCurrencyBodyTemplate',
  salesTaxBodyTemplate: 'salesTaxBodyTemplate',
  subtotal: 'subtotal',
  gst: 'gst',
  pst: 'pst',
  total: 'total',
  balanceDue: 'balanceDue',
  billOtherPrivatePatient: 'billOtherPrivatePatient',
  billOtherTeleplanPatient: 'billOtherTeleplanPatient',
  billOtherTeleplanPatientPOS: 'billOtherTeleplanPatientPOS',
  billOtherWSBCRPatient: 'billOtherWSBCRPatient',
  billOtherTeleplanFirstVisitPatient: 'billOtherTeleplanFirstVisitPatient',
  billSameTeleplanPatient: 'billSameTeleplanPatient',
  billSameTeleplanPatientPOS: 'billSameTeleplanPatientPOS',
  billSamePrivatePatient: 'billSamePrivatePatient',
  billSameWSBCRPatient: 'billSameWSBCRPatient',
  billSameEClaim: 'billSameEClaim',
  disabledPatientInput: 'disabledPatientInput',
  receivePaymentButton: 'receivePaymentButton',
  recordPayment: 'recordPayment',

  // Create/Edit Record Payment
  for: 'for',
  due: 'due',
  payer: 'payer',
  paidInFull: 'paidInFull',
  paymentMethodDropdown: 'paymentMethodDropdown',
  lastDigitsOfCreditCard: 'lastDigitsOfCreditCard',
  feePaid: 'feePaid',
  paymentDate: 'paymentDate',
  paymentDateLabel: 'paymentDateLabel',

  // Practitioners data table
  practitionersDataTable: 'practitionersDataTable',

  // Patient data table
  patientsDataTable: 'patientsDataTable',

  // WSBC Report
  WSBCReportForm: 'WSBCReportForm',
  createReportButton: 'createReportButton',
  submitReportButton: 'submitReportButton',
  wsbcMobileCatalogs: 'wsbcMobileCatalogs',
  wsbcDesktopCatalogs: 'wsbcDesktopCatalogs',
  toggleCatalogsButton: 'toggleCatalogsButton',
  openCatalogsButton: 'openCatalogsButton',
  closeCatalogsButton: 'closeCatalogsButton',
  patientChip: 'patientChip',
  noPatientSelected: 'noPatientSelected',
  catalogsCodeChip: 'catalogsCodeChip',
  wsbcReportTitle: 'wsbcReportTitle',

  // Teleplan Invoice
  teleplanInvoiceForm: 'teleplanInvoiceForm',
  teleplanMobileCatalogs: 'teleplanMobileCatalogs',
  teleplanDesktopCatalogs: 'teleplanDesktopCatalogs',
  wsbcrDesktopCatalogs: 'wsbcrDesktopCatalogs',
  eClaimCatalogs: 'eClaimCatalogs',
  teleplanInvoiceTitle: 'teleplanInvoiceTitle',
  desktopFormCard: 'desktopFormCard',
  mobileFormCard: 'mobileFormCard',
  practitionerName: 'practitionerName',
  payeeAndRuralCodes: 'payeeAndRuralCodes',
  locationText: 'locationText',
  recordsDataTableWrap: 'recordsDataTableWrap',
  quickPickEmergencyBlock: 'quickPickEmergencyBlock',
  quickPickTimePickerBlock: 'quickPickTimePickerBlock',
  emergencyInfoDialog: 'emergencyInfoDialog',
  changeOrderDialog: 'changeOrderDialog',
  groupTemplateListDialog: 'groupTemplateListDialog',
  createGroupTemplateDialog: 'createGroupTemplateDialog',
  editGroupTemplateDialog: 'editGroupTemplateDialog',
  timeGoesOverMidnightDialog: 'timeGoesOverMidnightDialog',
  sameDurationDialog: 'sameDurationDialog',
  applyToAllDxDialog: 'applyToAllDxDialog',
  catalogsDialog: 'catalogsDialog',
  revokeDialog: 'revokeDialog',
  completeSetupDialog: 'completeSetupDialog',
  applyToAllReferralDialog: 'applyToAllReferralDialog',
  deleteGroupRecordConfirm: 'deleteGroupRecordConfirm',
  deleteGroupTemplateConfirm: 'deleteGroupTemplateConfirm',
  replaceAllEnteredDataWarnDialog: 'replaceAllEnteredDataWarnDialog',
  conflictsBetweenGroupAndIndividualTimesDialog: 'conflictsBetweenGroupAndIndividualTimesDialog',
  bmiSurgicalAssistDialog: 'bmiSurgicalAssistDialog',
  bmiSurgicalAssistForGroupDialog: 'bmiSurgicalAssistForGroupDialog',
  travelInfoDialog: 'travelInfoDialog',
  ccfppInfoDialog: 'ccfppInfoDialog',
  invalidLocationForMidwifeInductionDialog: 'invalidLocationForMidwifeInductionDialog',
  percentEditorHidden: 'percentEditorHidden',
  percentEditor: 'percentEditor',
  unitsEditor: 'unitsEditor',
  invoiceRecordsDxTemplate: 'invoiceRecordsDxTemplate',
  invoiceRecordsTimeBodyTemplate: 'invoiceRecordsTimeBodyTemplate',
  invoiceRecordsUnitsTemplate: 'invoiceRecordsUnitsTemplate',
  invoiceRecordsPercentTemplate: 'invoiceRecordsPercentTemplate',
  createdUpdatedInfoButton: 'createdUpdatedInfoButton',
  mspCodeError: 'mspCodeError',
  teleplanMessageForDeclinedInvoice: 'teleplanMessageForDeclinedInvoice',
  asa4: 'asa4',
  asa4e: 'asa4e',
  asa5: 'asa5',
  asa5e: 'asa5e',
  iabp: 'iabp',
  mechanicalAssistDevice: 'MechanicalAssistDevice',
  timeOver8Hours: 'timeOver8Hours',
  weekendValidationDialog: 'weekendValidationDialog',
  anesProceduralDialog: 'anesProceduralDialog',
  anatomicCodeDialog: 'anatomicCodeDialog',
  durationForPsychiatryCodesDialog: 'durationForPsychiatryCodesDialog',
  groupTabelSettingsDialog: 'groupTabelSettingsDialog',
  wsbcConsultationDialog: 'wsbcConsultationDialog',
  birthDate: 'birthDate',
  eligibilityMessage: 'eligibilityMessage',

  // eClaim
  eClaimFormContainer: 'eClaimFormContainer',
  eClaimForm: 'eClaimForm',
  eClaimServiceAutocomplete: 'eClaimServiceAutocomplete',
  eClaimServiceAutocompleteInput: 'eClaimServiceAutocompleteInput'
};
