import React, {useEffect, useState} from 'react';
import * as signalR from "@microsoft/signalr";
import { SplitButton } from 'primereact/splitbutton';
import {
    handleBillSameTeleplanPatient,
    handleBillSameTeleplanPatientPOS,
    handleBillSameTeleplanPatientGroup,
    handleBillSamePrivatePatient,
    handleBillSameEClaim,
    handleWSBCReportWithSamePatient,
} from './billSamePatientHelpers';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { setToastMessage } from '../../../modules/core/actions/core.action.creators';

const BillSamePatientSplitButton = ({ history, patientsList, user, showEClaimButton }) => {

    const dispatch = useDispatch();

    const isMobileOnly = useSelector((state) => state.core.window.isMobileOnly);
    const DoctorGuid = useSelector((state) => state.user.details.DoctorGuid);


    const [connection, setConnection] = useState(null);
    const userId = DoctorGuid;

    useEffect(() => {
        console.log(connection);
    }, [connection]);

    // useEffect(() => {
    //     const connection = new signalR.HubConnectionBuilder()
    //     .withUrl("http://localhost:5139/syncHub", {
    //         withCredentials: true, // Required if AllowCredentials is used in backend
    //     })
    //     .withAutomaticReconnect() // Optional: Reconnect if the connection drops
    //     .configureLogging(signalR.LogLevel.Debug) // Enable logging for debugging
    //     .build();
    //     setConnection(connection);

    
    // connection
    //     .start()
    //     .then(() => console.log("Connected to SignalR!"))
    //     .catch((err) => console.error("Failed to connect to SignalR:", err));
    
    // }, [userId]);

    // Function to trigger the OpenBatchScreen command
    const sendOpenBatchScreenCommand = (patientsList) => {
        if (connection) {
            connection.invoke("OpenBatchScreen", userId, patientsList)
                .then(dispatch(setToastMessage({ type: 'success', title: 'Patient details sent to Desktop', message: 'Make sure you logged into the same account on a desktop to view changes' })))
                .catch((err) => console.error("Error sending command:", err));
        } else {
            console.error("No connection established");
        }
    };


    const menuItems = [
        !isMobileOnly && {
            label: 'Picker',
            command: handleBillSameTeleplanPatientPOS(history, patientsList),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    onClick={handleBillSameTeleplanPatientPOS(history, patientsList)}
                />
            ),
        },
        {
            label: 'Teleplan',
            command: handleBillSameTeleplanPatient(history, patientsList),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    onClick={handleBillSameTeleplanPatient(history, patientsList)}
                />
            ),
        },
        !isMobileOnly && { // Exclude "Batch" on mobile devices
            label: 'Batch',
            command: handleBillSameTeleplanPatientGroup(history, patientsList),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    onClick={handleBillSameTeleplanPatientGroup(history, patientsList)}
                />
            ),
        },
        {
            label: 'Private Invoice',
            command: handleBillSamePrivatePatient(history, patientsList),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    onClick={handleBillSamePrivatePatient(history, patientsList)}
                />
            ),
        },
        user?.canBillWCB && {
            label: 'WSBC Report',
            command: handleWSBCReportWithSamePatient(history, patientsList),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    onClick={handleWSBCReportWithSamePatient(history, patientsList)}
                />
            ),
        },
        showEClaimButton && {
            label: 'eClaims',
            command: handleBillSameEClaim(history, patientsList),
            template: (item) => (
                <Button
                    className="p-button-text w-full"
                    style={{ textAlign: 'left' }}
                    label={item.label}
                    onClick={handleBillSameEClaim(history, patientsList)}
                />
            ),
        },
        // isMobileOnly &&
        // {
        //     label: 'Push to Batch on Desktop',
        //     command: () => sendOpenBatchScreenCommand(patientsList),
        //     template: (item) => (
        //         <Button
        //             className="p-button-text w-full"
        //             style={{ textAlign: 'left' }}
        //             label={item.label}
        //             onClick={() => sendOpenBatchScreenCommand(patientsList)}
        //         />
        //     ),
        // },
    ].filter(Boolean);        

    return (
        <SplitButton
            label="New Claim"
            // className='custom-outlined-button'
            // outlined
            icon='pi pi-plus'
            model={menuItems}
            onClick={!isMobileOnly ? handleBillSameTeleplanPatientPOS(history, patientsList): handleBillSameTeleplanPatient(history, patientsList)} // Default action
        />
    );
};

export default BillSamePatientSplitButton;
