import React from 'react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

const Footer = ({ onAccept, setVisibleAccept, scrollStatus, setVisibleCancel, checkBoxStatus, setCheckBoxStatus }) => {
  const handleAccept = () => {
    if (!scrollStatus || !checkBoxStatus) {
      setVisibleAccept(true);
      return;
    }
    onAccept();
  };

  const onCancel = () => {
    setVisibleCancel(true);
  };

  return (
    <div className="flex flex-column w-full gap-4 px-4 text-800">
      <div className="w-full">
        <Checkbox onChange={(e) => setCheckBoxStatus(e.checked)} checked={checkBoxStatus} />
        <label htmlFor="secure" className="w-full ml-2">
          By checking this box, I confirm that I have read and agree to the Acclaim’s Agreement.
        </label>
      </div>

      <div className="flex justify-content-center gap-3 ">
        <Button label="Accept" data-testid="buttonAcceptTermsOfUse" className="w-full max-w-10rem w-6 h-3rem" onClick={handleAccept} />
        <Button
          onClick={onCancel}
          data-testid="buttonCancelTermsOfUse"
          className="p-button-outlined w-full max-w-10rem w-6 h-3rem"
          label="Cancel"
          type="button"
        />
      </div>
    </div>
  );
};

export default Footer;
