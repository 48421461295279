import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';

import RadioButton from '../../PrimeReactComponents/RadioButton';
import CircularProgress from '../../Misc/Loader/CircularProgress/CircularProgress';

import { toggleSorting } from '../../../modules/core/actions/core.action.creators';
import { t } from '../../../service/localization/i18n';
import classes from './Sort.module.scss';
import cx from 'classnames';

const Sort = (props) => {
  const dispatch = useDispatch();
  const { showSortSidebar } = useSelector((state) => state.core);

  const formatSortOrder = (sortOrder, sortField) => {
    const sortOrderValue = sortOrder === 1 ? 'asc' : 'desc';
    return `${sortField}_${sortOrderValue}`;
  };

  const [checked, setChecked] = useState(formatSortOrder(props.sortOrder, props.sortField));

  useEffect(() => {
    setChecked(formatSortOrder(props.sortOrder, props.sortField));
  }, [props.sortField, props.sortOrder]);

  const header = () => (
    <div className="w-full px-3">
      <h5 className={cx('m-0')}>{t('Sort')}</h5>
    </div>
  );

  const closeSidebar = () => dispatch(toggleSorting(!showSortSidebar));

  const onSort = (e) => {
    const sortOrderValue = formatSortOrder(e.sortOrder, e.sortField);
    props.onSort(e);
    setChecked(sortOrderValue);
  };

  const sortButtonsList = props.columns.map((i) => {
    return (
      <div key={i.id}>
        <div className="field-radiobutton mb-5">
          <RadioButton
            inputId={`${i.id}_down`}
            name={`${i.field}_desc`}
            value={i.field}
            checked={checked === `${i.field}_desc`}
            onChange={(e) => onSort({ sortOrder: -1, sortField: e.value })}
          />
          <label className="mb-0 ml-3" htmlFor={`${i.id}_down`}>
            {String.format(t('Sort_by_x'), i.header)}
            <i className="pi pi-sort-amount-down ml-3" />
          </label>
        </div>

        <div className="field-radiobutton mb-5">
          <RadioButton
            inputId={`${i.id}_up`}
            name={`${i.field}_asc`}
            value={i.field}
            checked={checked === `${i.field}_asc`}
            onChange={(e) => onSort({ sortOrder: 1, sortField: e.value })}
          />
          <label className="mb-0 ml-3" htmlFor={`${i.id}_up`}>
            {String.format(t('Sort_by_x'), i.header)}
            <i className="pi pi-sort-amount-up ml-3" />
          </label>
        </div>
      </div>
    );
  });

  const sortButtons = () => {
    return (
      <>
        {props.defaultSortTemplate && (
          <div className="field-radiobutton mb-5">
            <RadioButton
              inputId="DateUpdated_desc"
              name="DateUpdated_desc"
              value="DateUpdated"
              checked={checked === 'DateUpdated_desc'}
              onChange={(e) => onSort({ sortOrder: -1, sortField: e.value })}
            />
            <label className="mb-0 ml-3" htmlFor={'DateUpdated_desc'}>
              {String.format(t('Sort_by_x'), 'Date updated')}
              <i className="pi pi-sort-amount-down ml-3" />
            </label>
          </div>
        )}

        {sortButtonsList}
      </>
    );
  };

  return (
    <Sidebar
      className={classes.sidebar}
      visible={showSortSidebar}
      position="right"
      blockScroll
      baseZIndex={9999999999}
      icons={header()}
      onHide={() => dispatch(toggleSorting(!showSortSidebar))}
    >
      <div className="mt-3 px-3">
        <div className={cx('sidebarFilters', classes.rootWrap)}>
          {sortButtons()}

          <div className={classes.actionButtonsWrap}>
            <Button className="p-button-outlined w-full" label={t('Close')} onClick={closeSidebar} />
          </div>

          {props.isFetchingData && <CircularProgress />}
        </div>
      </div>
    </Sidebar>
  );
};

export default Sort;
