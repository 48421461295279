import { store } from '../../store';
import { t } from '../../service/localization/i18n';

export const getPayeeNumbersListForFilter = () => {
  // Use a Set to track unique payee numbers and avoid duplicates
  const uniquePayeeNumbers = new Set();

  return [
    // Always include the "All" option at the beginning
    { label: t('All'), value: '-2' },

    // Retrieve the list of payee numbers from the Redux store
    ...(store.getState().clinic?.members || []) // Ensure members is always an array
      .flatMap((member) => member.PayeeNumbers || []) // Flatten the PayeeNumbers arrays of all members
      .filter((payeeNumber) => {
        // Ignore empty, null, undefined, and zero values
        if (!payeeNumber || payeeNumber === '0' || payeeNumber === 0) {
          return false;
        }

        // Remove duplicates: If the number is already in the Set, filter it out
        if (uniquePayeeNumbers.has(payeeNumber)) {
          return false;
        }

        // Add the new payee number to the Set and keep it in the list
        uniquePayeeNumbers.add(payeeNumber);
        return true;
      })
      .map((payeeNumber) => ({ label: payeeNumber, value: payeeNumber })) // Convert the filtered numbers into the required format
  ];
};
