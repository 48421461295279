import React from 'react';
import { Dialog } from 'primereact/dialog';
import AddReferral from '../../../practitioners/components/AddReferral/AddReferral';
import { t } from '../../../../service/localization/i18n';

const AddNewReferralDialog = ({ visible, formik, setShowNewPractitionerDialog, setDefaultReferralList }) => {
  const onCreateResponseAction = (code) => {
    formik.setFieldValue('MRP', [code]);
    setDefaultReferralList((prevState) => [code, ...prevState]);
    setShowNewPractitionerDialog(false);
  };

  return (
    <Dialog
      header={t('Add_new_referral_practitioner')}
      contentClassName="pb-0"
      visible={visible}
      style={{ width: '25vw' }}
      breakpoints={{ '1366px': '35vw', '960px': '55vw', '768px': '95vw' }}
      baseZIndex={1000000}
      onHide={() => setShowNewPractitionerDialog(false)}
    >
      <AddReferral onCancel={() => setShowNewPractitionerDialog(false)} onCreateResponseAction={(code) => onCreateResponseAction(code)} />
    </Dialog>
  );
};

export default AddNewReferralDialog;
