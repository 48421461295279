import React from 'react';
import DeletePinCode from '../DeletePinCode';
import ApplicationUpdateDialog from '../ApplicationUpdateDialog';
import MissingPatientData from '../MissingPatientData/MissingPatientData';
import TotalOutstandingDialog from '../MissingPatientData/TotalOutstandingDialog';

const CommonDialogs = () => {
  return (
    <>
      <DeletePinCode />
      <MissingPatientData />
      <TotalOutstandingDialog />
      <ApplicationUpdateDialog />
    </>
  );
};

export default CommonDialogs;
