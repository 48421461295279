import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { useController } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { phoneValidation } from '../helpers/validation';

const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, ''); // Remove all non-digit characters
  if (phoneNumber.length <= 3) return phoneNumber;
  if (phoneNumber.length <= 6) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

const PhoneNumber = ({ control, autoFocusStatus, requiredStatus }) => {
  const {
    formState: { errors },
    field
  } = useController({
    name: 'phoneNumber',
    control,
    rules: { required: `${requiredStatus ? 'Phone Number is required' : ''}`, validate: phoneValidation }
  });

  return (
    <>
      <InputWrapper name="phoneNumber" label="Phone Number" required={requiredStatus} errorMessage={errors.phoneNumber?.message}>
        <InputText
          id="phoneNumber"
          data-testid="phoneNumber-testid"
          placeholder="(555) 555-5555"
          onChange={(e) => field.onChange(formatPhoneNumber(e.target.value))} // Format the value before updating the state
          value={formatPhoneNumber(field.value)} // Format the value before displaying it
          autoFocus={autoFocusStatus}
          className={classNames({ 'p-invalid': errors.phoneNumber })}
        />
      </InputWrapper>
    </>
  );
};

export default PhoneNumber;
