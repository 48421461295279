import actionTypes from '../actions/auth.action.types';

export const userInitialState = {
  details: {},
  affiliates: [],
  loginRequest: false,
  logoutRequest: false,
  redirectRequest: false,
  gettingAffiliates: false,
  userVerifyRequest: false,
  registerType: '',
  forgotPassword: {},
  termsOfUse: {},
  licencingsBodiesList: {},
  practiceTypeList: {},
  secretQuestions: {},
  registrationFormState: {
    firstStepType: '',
    type: 'register-type',
    initialFormState: {},
    sessionGuid: ''
  }
};

const userReducer = (state = userInitialState, action) => {
  if (state === undefined) state = {};
  const { results } = action;
  switch (action.type) {
    // list
    case actionTypes.FETCH_USERS_SUCCESS:
      const onePage = results ? results.data : {};
      let newList = onePage;
      if (onePage.userList) newList = onePage.userList;

      if (state.query === onePage.query && state.user_list) {
        newList = [...state.user_list, ...onePage.userList];
      }

      return Object.assign({}, state, {
        user_list: newList,
        page: onePage.page,
        first: (onePage.page - 1) * onePage.pageSize + 1,
        totalPages: onePage.totalPages,
        totalRecords: onePage.totalRecords,
        pageSize: onePage.pageSize,
        query: onePage.query
      });

    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        details: results
      };

    case actionTypes.GET_AFFILIATES:
      return {
        ...state,
        affiliates: results.data
      };

    case actionTypes.UPDATE_USER_FIELD:
      const { current_user } = state;
      return {
        ...state,
        current_user: { ...current_user, ...results }
      };

    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loginRequest: results
      };

    case actionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        logoutRequest: results
      };

    case actionTypes.USER_VERIFY:
      return {
        ...state,
        userVerifyRequest: results
      };

    case actionTypes.GETTING_AFFILIATES:
      return {
        ...state,
        gettingAffiliates: results
      };

    case actionTypes.REGISTER_TYPE:
      return {
        ...state,
        registerType: results
      };

    case actionTypes.FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: results
      };

    case actionTypes.REDIRECT_REQUEST:
      return {
        ...state,
        redirectRequest: results
      };

    case actionTypes.LOGOUT_SUCCESS:
      return {};

    case actionTypes.TERMS_OF_USE:
      return { ...state, termsOfUse: results };

    case actionTypes.SET_LICENCINGS_BODIES_LIST:
      return {
        ...state,
        licencingsBodiesList: results
      };

    case actionTypes.SET_PRACTICE_TYPE_LIST:
      return {
        ...state,
        practiceTypeList: results
      };

    case actionTypes.SET_SECRET_QUESTIONS:
      return { ...state, secretQuestions: results };

    case actionTypes.UDATE_REGISTRATION_FORM_STATE:
      return { ...state, registrationFormState: results };

    default:
      return state;
  }
};

export default userReducer;
