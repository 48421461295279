import React from 'react';
import { Button } from 'primereact/button';
import { t } from '../../../../../service/localization/i18n';
import moment from 'moment';

const PaymentsButton = ({ data, onClick }) => {
  if (!data?.Payments?.length) return null;

  const paymentLatestDate = () => new Date(Math.max(...data?.Payments?.map((i) => new Date(i.PaymentDate))));

  return (
    <div className="flex align-items-center">
      <Button
        className="p-button-link p-0 mr-1"
        label={`${data?.Payments?.length} ${data?.Payments?.length > 1 ? t('Payments.1').toLowerCase() : t('Payments.2').toLowerCase()}`}
        aria-haspopup
        aria-controls="overlay_panel"
        onClick={onClick}
      />
      <div className="flex align-items-center ml-1" style={{ marginBottom: '2px', fontSize: '1.1rem' }}>
        {`${t('made_on')} ${moment(paymentLatestDate()).format('MM/DD/YYYY')}`}
      </div>
    </div>
  );
};

export default PaymentsButton;
