import React from 'react';
import { useFormContext } from 'react-hook-form';
import { icons } from '../../../../../../config/stylesConfig';
import cx from 'classnames';

const OOFHPInfoIcont = () => {
  const { setLocalState } = useFormContext();

  return (
    <i
      className={cx('oofhpTooltip cursor-pointer', icons.info)}
      onClick={() =>
        setLocalState((prevState) => ({
          ...prevState,
          oofhpDescriptionDialog: true
        }))
      }
    />
  );
};

export default OOFHPInfoIcont;
