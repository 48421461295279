import axios from 'axios';
import { store } from '../../../store';
import { setAuthHeader } from '../../../config';
import { setToastMessage } from '../../core/actions/core.action.creators';
import { getApiBase } from '../../../config';
import { calculateFirst, calculateLast } from '../../utils/calculate';
import { t } from '../../../service/localization/i18n';

export const getReferralCreditsList = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const DoctorGuid = currentState?.user?.details?.DoctorGuid;
  const { pageSize = 20, page = 1, sortby, query } = payload;
  const queryParams = new URLSearchParams();
  sortby && queryParams.set('sortby', sortby);
  query && queryParams.set('q', JSON.stringify(query));

  // const baseUrl = `${getApiBase()}/billing/${DCNGuid}/credits/${page}/${pageSize}`;
  const baseUrl = `${getApiBase()}/users/${DCNGuid}/${DoctorGuid}/referees/${page}/${pageSize}`;

  const url = new URL(baseUrl);
  url.search = queryParams.toString();

  return axios
    .get(url)

    .then((results) => ({
      ...results.data,
      query: results.data.query ?? JSON.parse(results.data.query),
      first: calculateFirst({
        page: results.data.page,
        pageSize: results.data.pageSize
      }),
      last: calculateLast({
        page: results.data.page,
        pageSize: results.data.pageSize,
        totalRecords: results.data.totalRecords
      })
    }))

    .catch((error) => {
      console.log('!!!getReferralCreditsList', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const getReferralCode = () => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const UserGuid = currentState?.user?.details?.UserGuid;

  return axios
    .get(`${getApiBase()}/users/${DCNGuid}/${UserGuid}/refer`)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getReferralCode', error);
    });
};
