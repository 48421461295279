import React, { useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useSelector } from 'react-redux';
import AuthTitle from '../Layouts/Auth/AuthTitle';
import { contentfulOptions } from '../../modules/contentful/contentfulOptions';
import { saveRegistrationData } from '../../service/Lookup';

const PrivacyDeny = ({ userName, email }) => {
  const terms = useSelector((state) => state.user.termsOfUse);
  const registrationState = useSelector((state) => state.registration.registrationFormState);

  useEffect(async () => {
    // notify the server that the user has completed the registration
    await saveRegistrationData({ ...registrationState.initialFormState, termsAccepted: false, wizardStep: 6, email });
  }, []);

  return (
    <div id="privacy-body" className="flex flex-column justify-content-center align-items-center text-800" style={{ background: '#e6e6e6' }}>
      <div className="px-6 pt-6 my-6" style={{ maxWidth: '800px', background: 'white' }}>
        <AuthTitle />
        <div className="flex w-9 pb-4 justify-content-center mx-auto mt-4">
          <img src="/assets/layout/images/logo-cm-v5.png" alt="logo" style={{ height: '150px' }} />
        </div>
        <p className="mt-4 mb-6">
          <b className="text-2xl">Welcome to Claim Manager, Dear {userName}!</b>
        </p>
        {terms?.items
          ?.filter((term) => term.fields.id === 'cancel-letter')
          .map((term, index) => (
            <div key={index}>{documentToReactComponents(term.fields.content, contentfulOptions())}</div>
          ))}
      </div>
    </div>
  );
};

export default PrivacyDeny;
