import { anatomicList, payeeList, submissionCodes } from '../../../helpers/mapper';
import { location_codes, rural_codes_only } from '../../../../config/constants';

export const findAnatomicCode = (code) => anatomicList.find((i) => i.label === code);
export const findPayorCode = (code) => payeeList.find((i) => i.short === code);

export const submissionCodesDropdownOptions = submissionCodes;

export const anatomicCodesDropdownOptions = [
  { label: findAnatomicCode('N').name, value: findAnatomicCode('N').label },
  { label: findAnatomicCode('L').name, value: findAnatomicCode('L').label },
  { label: findAnatomicCode('R').name, value: findAnatomicCode('R').label },
  { label: findAnatomicCode('B').name, value: findAnatomicCode('B').label }
];

export const payorDropdownOptions = [
  { label: findPayorCode('M').name, value: findPayorCode('M').label },
  { label: findPayorCode('W').name, value: findPayorCode('W').label },
  { label: findPayorCode('I').name, value: findPayorCode('I').label }
];

export const locationCodesDropdownOptions = location_codes.sort((a, b) => a.label.localeCompare(b.label));
export const ruralCodesDropdownOptions = [{ value: 'N/A', label: 'N/A' }, ...rural_codes_only];
