import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { CodePicker } from '../../../../components/Inputs';
import Catalogs from '../../../../components/Catalogs/Catalogs';
import CommonInputField from '../../../common/components/CommonInputField';
import CodeChip from '../../../../components/Catalogs/Modules/Chips/CodeChip';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';
import ReferralCatalogsTable from '../../../../components/Widgets/ReferralCatalogsTable/ReferralCatalogsTable';

import { elementIDs } from '../../../config/elementIDsConfig';
import { commonInputs } from '../../../config/commonInputsConfig';
import { patientFullNameWithAge } from '../../../utils/patientFullName';
import { deleteReferral } from '../../../practitioners/actions/practitioners.action.creators';
import { getReferralCodesByLetter } from '../../../claims/actions/claims.action.creators';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { t } from '../../../../service/localization/i18n';

import './ReferralInput.scss';

const ReferralInput = ({ formik, inputFieldClassName, setShowNewPractitionerDialog, defaultReferralList }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const [showRferralCatalogsDialog, setrShowRferralCatalogsDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMobile = window.innerWidth < 992;
  const maxEntry = 1;
  const referral = formik.values.MRP?.[0];

  const toggleCatalogs = () => setrShowRferralCatalogsDialog((prevState) => !prevState);

  const onLetterClick = async ({ letter, setCatalogsState }) => {
    if (letter) {
      setLoading(true);
      const results = await getReferralCodesByLetter({ letter });
      setLoading(false);
      if (results) {
        setCatalogsState((prevState) => ({
          ...prevState,
          tableData: { codeList: results },
          emptyMessage: `${t('No_records_found')}`
        }));
      }
    } else {
      setCatalogsState((prevState) => ({
        ...prevState,
        tableData: { codeList: defaultReferralList },
        emptyMessage: `${t('No_records_found')}`
      }));
    }
  };

  const catalogsTableValues = (catalogsState) => {
    return catalogsState.tableData?.codeList;
  };

  const onChange = useCallback(
    (code) => {
      if (code?.length > maxEntry) {
        return dispatch(setToastMessage({ type: 'warn', message: `${t('Maximum_number_of_entries_is_X')} ${maxEntry}` }));
      }

      formik.setFieldValue('MRP', code);
    },
    [formik, maxEntry, dispatch]
  );

  const onDeleteReferral = useCallback(
    async (deletingData) => {
      const result = await deleteReferral(deletingData.value);
      if (result) {
        const currentReferralNumber = formik.values.MRP;
        if (currentReferralNumber.some((i) => i.value === deletingData.value)) {
          onChange([]);
        }
      }
    },
    [formik.values.MRP, onChange]
  );

  const getSelectedCodes = () => {
    return formik.values.MRP;
  };

  const catalogTabs = [
    {
      name: commonInputs.referral.name,
      label: commonInputs.referral.tabLabel,
      codeType: commonInputs.referral.codeType,
      categoryType: commonInputs.referral.codeType,
      codeDescription: commonInputs.referral.codeDescription,
      maxEntries: commonInputs.referral.maxEntries,
      searchAllType: 'referral_all',
      requestParams: { codeType: commonInputs.referral.codeType, speciality: user.Speciality, getCodesFor: 'teleplan' },
      defaultTableData: defaultReferralList,
      showAlphabet: true,
      showSearchPanelForCustomTabLayout: true,
      tabLayout: (e) => {
        return (
          <>
            <div className="flex justify-content-between align-items-center h-3rem">
              {referral ? (
                <div className="mt-3 mb-2 block lg:hidden">
                  {/* <CodeChip id={referral.value} item={referral} isSelected={true} label={referral.value} onClick={() => onChange([])} /> */}
                </div>
              ) : (
                <div></div>
              )}
              <Button
                id={elementIDs.addNewPractitioner}
                style={{ maxHeight: '32px', minWidth: '172px' }}
                text
                label={t('Add_new_practitioner')}
                type="button"
                icon="pi pi-plus"
                onClick={() => setShowNewPractitionerDialog(true)}
              />
            </div>
            <ReferralCatalogsTable {...e} onRowClick={(code) => onChange([code])} onDelete={onDeleteReferral} />
          </>
        );
      },
      searchActionButton: {
        tooltip: t('Add_new_practitioner'),
        onClick: () => setShowNewPractitionerDialog(true)
      }
    }
  ];

  const patientChipsLayout =
    formik.values.FirstName && formik.values.LastName ? (
      <Chip className="w-max" label={patientFullNameWithAge(formik.values)} icon="pi pi-user" />
    ) : (
      <></>
    );

  const description = (
    <div className="flex flex-column pt-1">
      {formik.values.MRP?.map((i) => (
        <div key={i.value}>{i.name}</div>
      ))}
    </div>
  );

  return (
    <>
      {loading && <CircularProgress />}
      <CommonInputField
        className={inputFieldClassName ? inputFieldClassName : 'col-12 py-0 px-2 mb-1'}
        label="MRP"
        description={description}
        style={{ height: 'auto', minHeight: '90px' }}
      >
        <div className="mrp_referral">
          <Button
            id={elementIDs.addNewPractitioner}
            className={'p-button-text'}
            type="button"
            tooltip={!isMobile && t('Add_new_practitioner')}
            tooltipOptions={{ position: 'top' }}
            icon="pi pi-plus"
            onClick={() => setShowNewPractitionerDialog(true)}
          />
          <CodePicker
            name="MRP"
            value={formik.values.MRP}
            isInvalid={!!formik.errors.MRP}
            placeholder={t('Search_for_codes')}
            maxEntry={maxEntry}
            showCatalogsButton
            groupLabel={false}
            catalogsButtonTooltip={!isMobile && t('Find_practitioner_in_the_catalog')}
            searchParams={{ speciality: user.Speciality, codeType: commonInputs.referral.codeType, getCodesFor: 'teleplan' }}
            toggleCatalogs={toggleCatalogs}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      </CommonInputField>

      <Dialog
        className="batch_catalogs_dialog"
        showHeader={false}
        visible={showRferralCatalogsDialog}
        style={{ width: '50vw', height: '100%' }}
        breakpoints={{ '1600px': '60vw', '1366px': '70vw', '1024px': '95vw' }}
        baseZIndex={1000000}
        dismissableMask
        onHide={toggleCatalogs}
      >
        <Catalogs
          id={`new_patient_referral_catalogs`}
          activeTabIndex={0}
          catalogTabs={catalogTabs}
          showCatalogs={{ desktop: showRferralCatalogsDialog && !isMobile, mobile: showRferralCatalogsDialog && isMobile }}
          patientChipsLayout={patientChipsLayout}
          catalogsTableValues={catalogsTableValues}
          toggleCatalogs={toggleCatalogs}
          getSelectedCodes={getSelectedCodes}
          onLetterClick={onLetterClick}
          onCodeChipClick={() => onChange([])}
        />
      </Dialog>
    </>
  );
};

export default ReferralInput;
