import React from 'react';

import { InputNumber } from 'primereact/inputnumber';
import { Controller, useFormContext } from 'react-hook-form';

import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { defaultBMI, getMinBMI, maxBMI } from '../../../../../../config/defaultValuesConfig';
import { codesThatRequiresBMI } from '../../../helpers/codesCheckList';
import { isAllPatientsOlder18 } from '../../../../../../utils/getAge';
import { stringFormat } from '../../../../../../utils/stringFormat';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const BMI = () => {
  const name = inputs.bmi.name;
  const { control, watch, setValue, isNew, firstVisit, errors } = useFormContext();
  const feeCodes = watch(inputs.feeCodes.codeType);
  const feeCodesOnly = feeCodes?.map((i) => i.value);
  const patients = firstVisit ? [{ BirthDay: watch(inputs.birthDay.name) }] : watch(inputs.patient.name);
  const isAnyPatientYounger18 = isAllPatientsOlder18(patients);
  const errorMessage = isAnyPatientYounger18 && errors[name]?.message;
  const minBMI = getMinBMI(feeCodesOnly);
  const description = !errorMessage && stringFormat(t('bmi_must_be_at_least'), minBMI);
  const required = isAnyPatientYounger18; // CMO-3109 - Make BMI validation age dependent
  const showBMI = codesThatRequiresBMI.some((i) => feeCodesOnly?.includes(i));

  const onBlur = (value) => {
    !value && setValue(name, defaultBMI);
  };

  const onFocus = (e) => {
    e.target.select();
  };

  const onBmiChange = (e, field) => {
    const value = e.value < 100 ? e.value : e.value / 10;
    field.onChange(value);
  };

  if (!showBMI || !isNew) return null;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper name={name} label={inputs.bmi.label} errorMessage={errorMessage} required={required} description={description}>
          <InputNumber
            className={cx('flex', { 'p-invalid': errorMessage })}
            inputId={elementIDs.bmiInput}
            type="text"
            value={field.value}
            mode="decimal"
            minFractionDigits={1}
            maxFractionDigits={1}
            min={defaultBMI}
            max={maxBMI}
            onChange={(e) => onBmiChange(e, field)}
            onBlur={(e) => onBlur(e.target.value)}
            onFocus={onFocus}
          />
        </InputWrapper>
      )}
    />
  );
};

export default BMI;
