import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../Inputs';

import { useValidation } from '../hooks/useValidation';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';
import { inputWrapperStyles } from '../helpers/styles';

const FirstName = () => {
  const name = 'FirstName';
  const {
    control,
    trigger,
    formState: { errors }
  } = useFormContext();
  const { isPerson, isCompany, firstNameValidation } = useValidation();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: firstNameValidation
      }}
      render={({ field }) => (
        <InputWrapper required={isPerson} name={name} label={t('First_Name')} errorMessage={errors[name]?.message} style={inputWrapperStyles}>
          <InputText
            className={cx({ 'p-invalid': errors[name]?.message })}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value?.toUpperCase());
              if (isCompany) {
                trigger('LastName');
                trigger('CompanyName');
              }
            }}
          />
        </InputWrapper>
      )}
    />
  );
};

export default FirstName;
