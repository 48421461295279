import React, { useRef } from 'react';
import { Dropdown as PrimeReactDropdown } from 'primereact/dropdown';

/**
 * Dropdown component that wraps PrimeReactDropdown with additional functionality.
 * Use this component accross the application to ensure consistent styling and functionality.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {function} [props.onChange] - Optional onChange handler provided by the parent component.
 * @returns {JSX.Element} The rendered Dropdown component.
 */
const Dropdown = (props) => {
  const dropdownRef = useRef(null); // Ref for the Dropdown

  // Custom onChange to handle focus
  const handleChange = (e) => {
    props.onChange?.(e); // Trigger parent onChange if provided

    // Set focus back to the dropdown input
    if (dropdownRef.current) {
      dropdownRef.current.focus();
    }
  };

  return <PrimeReactDropdown {...props} ref={dropdownRef} onChange={handleChange} />;
};

export default Dropdown;
