import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import AddReferral from '../../../../../practitioners/components/AddReferral/AddReferral';

import { inputs } from '../../helpers/inputs';
import { useCodePickerActions } from '../../hooks/useCodePickerActions';
import { useGroupPickerInputs } from '../../views/TeleplanGroup/hooks/useGroupPickerInputs';
import { updateInvoicesQuickPickCodesList } from '../../../../actions/claims.action.creators';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const AddNewPractitionerDialog = () => {
  const dispatch = useDispatch();
  const [warning, setWarning] = useState({ showDialog: false, code: null });
  const { catalogMostRecentCodes } = useSelector((state) => state.claims);
  const { onChange } = useCodePickerActions(inputs.referral);
  const { onReferralChange } = useGroupPickerInputs();
  const { localState, setLocalState, getValues } = useFormContext();
  const isDialog = localState.addNewPractitionerDialog;
  const isGroupDialog = localState.addNewPractitionerForGroupDialog.showDialog;

  const updateReferralCodes = (code) => {
    if (isDialog) onChange({ value: [code] });

    if (isGroupDialog) {
      const mostRecentList = catalogMostRecentCodes?.[inputs.referral.codeType];
      const mostRecentUpdatedList = [code, ...mostRecentList];
      dispatch(updateInvoicesQuickPickCodesList(mostRecentUpdatedList, inputs.referral.codeType));
      onReferralChange([code], localState.addNewPractitionerForGroupDialog.rowOptions);
    }
  };

  const onCreateResponseAction = (code) => {
    const groupReferral = localState?.addNewPractitionerForGroupDialog?.rowOptions?.rowData?.[inputs.referral.codeType];
    const referral = groupReferral || getValues(inputs.referral.codeType);

    if (referral?.length) {
      setWarning({ showDialog: true, code });
    } else {
      updateReferralCodes(code);
      onHide();
    }
  };

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      addNewPractitionerDialog: false,
      addNewPractitionerForGroupDialog: { showDialog: false, rowOptions: {} }
    }));
  };

  const handleYes = () => {
    updateReferralCodes(warning.code);
    setWarning({ showDialog: false, code: null });
    onHide();
  };

  const handleNo = () => {
    const mostRecentList = catalogMostRecentCodes?.[inputs.referral.codeType];
    const mostRecentUpdatedList = [warning.code, ...mostRecentList];
    dispatch(updateInvoicesQuickPickCodesList(mostRecentUpdatedList, inputs.referral.codeType));
    setWarning({ showDialog: false, code: null });
    onHide();
  };

  const footerLayout = (
    <>
      <Button id={elementIDs.dialogAccept} label={t('Yes')} onClick={handleYes} autoFocus />
      <Button className="p-button-outlined" id={elementIDs.dialogClose} label={t('No')} onClick={handleNo} />
    </>
  );

  return (
    <>
      <Dialog
        header={t('Add_new_referral_practitioner')}
        contentClassName="pb-0"
        visible={localState.addNewPractitionerDialog || localState.addNewPractitionerForGroupDialog.showDialog}
        style={{ width: '25vw' }}
        breakpoints={{ '1366px': '35vw', '960px': '55vw', '768px': '95vw' }}
        baseZIndex={1000000}
        onHide={onHide}
      >
        <AddReferral onCancel={onHide} onCreateResponseAction={onCreateResponseAction} />
      </Dialog>

      <Dialog
        header={t('Warning')}
        contentClassName="pb-0"
        visible={warning.showDialog}
        style={{ width: '25vw' }}
        breakpoints={{ '1366px': '35vw', '960px': '55vw', '768px': '95vw' }}
        baseZIndex={1000001}
        footer={footerLayout}
        onHide={onHide}
      >
        {`Do you want to use the ${warning.code?.name}, ${warning.code?.value} as a referral?`}
      </Dialog>
    </>
  );
};

export default AddNewPractitionerDialog;
