import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { InputWrapper } from '../../../../../components/Inputs';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import LoginWithPinButton from './LoginWithPinButton';

import { useValidate } from '../hooks/useValidate';
import { elementIDs } from '../../../../config/elementIDsConfig';
import { t } from '../../../../../service/localization/i18n';
import { updateUser } from '../../../actions/auth.actions.creators';
import { askSecretQuestion } from '../../../../../service/Lookup';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { routes } from '../../../../../routes/routes';
import { sessionStorageKeys } from '../../../../config/localStorageKeysConfig';
import { passwRecoveryTemporaryBlocked } from '../../../../config/userStatuses';

const PasswordStep = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const name = 'password';
  const { onSignIn, passwordValidation } = useValidate();
  const { control, formState, setSignInState, signInState, clearErrors, getValues, setValue, watch, setError } = useFormContext();
  const errorMessage = formState.errors?.password?.message;
  const checked = watch('signed', false);
  const user = useSelector((state) => state.user.details);

  const onBack = () => {
    setSignInState((prevState) => ({ ...prevState, step: 1 }));
    // Clear error message
    errorMessage && clearErrors('password');
    // Clear password value
    getValues(name) && setValue(name, '');
  };

  const onChange = (e, field) => {
    field.onChange(e);
    errorMessage && clearErrors(name);
  };

  if (checked) {
    sessionStorage.setItem(sessionStorageKeys.keepSignedIn, 'true');
  } else {
    sessionStorage.removeItem(sessionStorageKeys.keepSignedIn);
  }

  const forgotPassword = async () => {
    if (user.UserStatus === passwRecoveryTemporaryBlocked) {
      history.replace(routes.passwordRecoveryError.path);
      return;
    }

    const userUpdated = await askSecretQuestion({ Email: getValues('username') });
    if (userUpdated) {
      if (userUpdated?.SourceID === 0) {
        setError('username', { type: 'manual', message: 'We could not verify your information. Please contact support for assistance.' });
        return setSignInState((prevState) => ({
          ...prevState,
          step: 1,
          impersonateKey: null
        }));
      }
      dispatch(updateUser(userUpdated));
      history.push(routes.askSecretQuestions.path);
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ validate: (value) => passwordValidation(value) }}
        render={({ field }) => (
          <InputWrapper className="h-auto" name={name} label="Password" errorMessage={errorMessage}>
            <Password
              className={errorMessage ? 'p-invalid' : ''}
              inputStyle={{ width: '100%' }}
              id={name}
              inputId={elementIDs.passwordInput}
              inputRef={field.ref}
              feedback={false}
              toggleMask
              autoFocus
              {...field}
              onInput={(e) => onChange(e, field)}
            />
          </InputWrapper>
        )}
      />
      <Button className="w-5 text-left pl-0 text-sm" type="button" link label="Forgot password?" onClick={forgotPassword} />
      <div className="flex mt-3 gap-3 w-full">
        <Button
          id={elementIDs.logInButton}
          type="button"
          className="h-3rem w-6"
          label={t('Login')}
          loading={signInState.isSubmitting}
          onClick={onSignIn}
        />
        <Button
          id={elementIDs.goBackButton}
          type="button"
          className="p-button-outlined h-3rem w-6"
          label={t('Back')}
          disabled={signInState.isSubmitting}
          onClick={onBack}
        />
      </div>

      <LoginWithPinButton />

      <div className="flex justify-content-center align-items-center mt-4 gap-2">
        <Controller
          name="signed"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <>
              <Checkbox
                id="keepSignInCheckbox"
                inputId="signed"
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
              <label htmlFor="signed">{t('KeepSignIn')}</label>
              <Tooltip position="top" target=".signedTooltip">
                {t('KeepSignInText')}
              </Tooltip>
              <i className="signedTooltip cursor-pointer pi pi-question-circle" />
            </>
          )}
        />
      </div>
    </>
  );
};

export default PasswordStep;
