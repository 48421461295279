import React from 'react';
import { useFormContext } from 'react-hook-form';

import { steps } from '../../config/steps';
import { inputs } from '../../helpers/inputs';
import { patientFullNameWithAge } from '../../../../../utils/patientFullName';
import { t } from '../../../../../../service/localization/i18n';

const FormTitle = () => {
  const { getValues, isNew, isEdit, isView, localState } = useFormContext();
  const patients = getValues(inputs.patient.name);
  const primaryPolicy = getValues(inputs.primaryPolicy.name);
  const secondaryPolicy = getValues(inputs.secondaryPolicy.name);
  const claimNumber = getValues(inputs.claimNumber.name);
  const patientName = patients?.length ? patientFullNameWithAge(patients[0]) : '';
  const modText = isNew ? t('Create_eClaim') : isEdit ? t('Edit_eClaim') : isView ? `${t('View_eClaim')} ${claimNumber}` : '';
  const firstStepTitle = patientName ? `${modText} - ${patientName}` : `${modText}`;
  const titleWithInsuranceName = `${primaryPolicy?.InsurerNameEn} - ${patientName}`;
  const title = localState.step === steps.stepOne ? firstStepTitle : titleWithInsuranceName;
  const secondaryPolicyName = secondaryPolicy?.InsurerNameEn;

  return (
    <div className="flex flex-column gap-3">
      <h4 className="text-center">{title}</h4>
      {secondaryPolicyName && localState.step !== steps.stepOne && <span className="text-center">{`${t('Secondary')}: ${secondaryPolicyName}`}</span>}
    </div>
  );
};

export default FormTitle;
