import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';

import MobileRowTemplate from './MobileRowTemplate';
import SidebarFilters from './Filters/SidebarFilters';
import Table from '../../../../components/Table/Table';
import AddPractitioner from '../AddPractitioner/AddPractitioner';
import TableCard from '../../../../components/Wraps/PageWrap/TableCard';
import DesktopRowExpansionTemplate from './DesktopRowExpansionTemplate';
import DeleteConfirmation from '../../../common/components/DeleteConfirmation';

import { tableProps } from '../../../../components/Table/helpers/tableProps';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { defaultPageSize, practitionersDefaultSortBy, dataTablesParams } from '../../../config/defaultValuesConfig';
import { deletePractitioner } from '../../actions/practitioners.action.creators';
import { columnsForPDF, recordsForExcel } from './helpers/exportData';
import { getMembers, setMembers } from '../../../clinic/actions/clinic.action.creators';
import { columns } from './helpers/columns';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';

const Practitioners = ({ showInCard = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clinic = useSelector((state) => state.clinic);
  const { isFetchingPractitioner } = useSelector((state) => state.practitioners);
  const { members } = useSelector((state) => state.clinic);

  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [selectedRows, setSelectedRows] = useState(null);
  const [showNewPractitionerDialog, setShowNewPractitionerDialog] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState({
    sortField: practitionersDefaultSortBy.sortField,
    sortOrder: practitionersDefaultSortBy.sortOrderNumbersortField
  });

  const dt = useRef(null);
  const dataKey = 'DoctorGuid';
  const showExpand = false;

  // Expand all rows
  useEffect(() => {
    if (isExpanded) {
      let _expandedRows = {};
      members.forEach((i) => (_expandedRows[`${i[dataKey]}`] = true));

      setExpandedRows(_expandedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  const onAddPractitionerClick = () => setShowNewPractitionerDialog(true);
  const onCloseNewPractitioner = () => setShowNewPractitionerDialog(false);

  const onCreateNewPractitionerResponseAction = async () => {
    try {
      const result = await getMembers(clinic.details.DCNGuid);
      setMembers(result);
      dispatch(setToastMessage({ type: 'success', message: t('New_practitioner_is_created') }));
      onCloseNewPractitioner();
    } catch (error) {
      console.log('!!!onCreateNewPractitionerResponseAction', error);
      dispatch(setToastMessage({ type: 'success', message: t('Something_went_wrong') }));
    }
  };

  const handleRowClick = (rowData) => {
    history.push(`/staff-details/${rowData.DoctorGuid}`);
  };

  const rowActionButtonsMenuModel = (rowData) => {
    return [
      // {
      //   label: t("Edit"),
      //   command: () => history.push(`${routes.ediPractitioner.path}/${rowData.DoctorGuid}`),
      //   icon: "pi pi-pencil",
      // },

      {
        label: 'View',
        icon: 'pi pi-info-circle',
        command: () => handleRowClick(rowData)
      },

      {
        label: t('Delete'),
        icon: 'pi pi-trash',
        command: () => onDelete([rowData])
      },

      //TODO: Make 'Logs' option be availbale only for admins.
      {
        label: 'Logs',
        icon: 'pi pi-list',
        command: () => history.push(`/user-logs/${rowData.UserGuid}`)
      }
    ];
  };

  const rowActionButtons = (rowData) => {
    const rowMenuRef = React.createRef();
    return (
      <div className="flex justify-content-end">
        <Menu id={`popup_menu_${rowData.DoctorGuid}`} ref={rowMenuRef} popup model={rowActionButtonsMenuModel(rowData)} />
        <Button
          id={rowData.id?.replaceAll('-', '')}
          className="p-button-text p-button-rounded mobileRoundedButtonSize"
          icon="pi pi-ellipsis-v"
          aria-controls={`popup_menu_${rowData.DoctorGuid}`}
          aria-haspopup
          onClick={(e) => {
            rowMenuRef.current.toggle(e);
            setSelectedRows([rowData]);
          }}
        />
      </div>
    );
  };

  const onSort = (e) => {
    setSort(e);
  };

  const onDelete = (rows) => {
    setSelectedRows(rows);
    setShowDeleteConfirm(true);
  };

  const closeDeleteConfirm = () => {
    setShowDeleteConfirm(false);
  };

  const onDeletePractitioner = () => {
    if (selectedRows?.length > 1) {
      // Multiple delete
      return;
      console.log('!!!', selectedRows);
    }

    return dispatch(
      deletePractitioner(selectedRows[0].DoctorGuid, async () => {
        const result = await getMembers(clinic.details.DCNGuid);
        setMembers(result);
        setShowDeleteConfirm(false);
      })
    );
  };

  const deletePractitionerConfirmationMessage = () => {
    if (selectedRows?.length > 1) return String.format(t('Are_you_sure_you_want_to_delete_this_records'), selectedRows?.length);
    return t('Are_you_sure_delete_this_practitioner');
  };

  const onChipRemove = (chip) => {
    const updatedChipsList = filters.filter((i) => i.label !== chip.label);
    dt.current.filter('', chip.field);
    setFilters(updatedChipsList);
  };

  const clearAllFilters = () => {
    setFilters([]);
    dt.current.reset();
  };

  const onGlobalSearch = (e) => {
    setGlobalFilter(e.target.value);
  };

  const tableHeader = {
    addNewButton: {
      display: true,
      label: t('Add_practitioner'),
      onClick: onAddPractitionerClick
    },

    filterButton: {
      display: false
    },

    deleteButton: {
      display: false, // [KS] 30.08.2022 - GROUP ACTIONS ARE NOT SUPPORTED BY BACK END ATM
      onClick: onDelete
    },

    globalSearch: {
      display: true,
      placeholder: t('Search_for_practitioner'),
      onChange: (e) => onGlobalSearch(e)
    }
  };

  const sidebarFiltersLayout = <SidebarFilters filters={filters} setFilters={setFilters} />;

  const mobileRowTemplate = (rowProps) => {
    return (
      <MobileRowTemplate
        {...rowProps}
        dataKey={dataKey}
        showExpand={showExpand}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        rowActionButtons={rowActionButtons}
      />
    );
  };

  const rowExpansionTemplate = (rowData) => {
    return <DesktopRowExpansionTemplate rowData={rowData} />;
  };

  const columnsProps = {
    filters,
    setFilters,
    dtRef: dt
  };

  const dataTableProps = {
    ...tableProps,
    ref: dt,
    id: elementIDs.practitionersDataTable,
    name: dataTablesParams.practitioners.name,
    dataKey,
    // tableDescription: t('This_screen_shows_the_list_of_the_practitioners'),
    value: members,
    sortField: sort.sortField,
    sortOrder: sort.sortOrder,
    fetchingData: isFetchingPractitioner,
    showExpand,
    globalFilter: globalFilter,
    header: tableHeader,
    columns: columns(columnsProps),
    columnsForPDF,
    recordsForExcel: recordsForExcel(members),
    expandedRows,
    filters,
    showPaginator: false,
    sidebarFilters: sidebarFiltersLayout,
    selectionMode: 'checkbox',
    rows: defaultPageSize,
    lazy: false,
    showInCard,
    isExpanded,
    setIsExpanded,
    setExpandedRows,
    onSort,
    setFilters,
    onChipRemove,
    clearAllFilters,
    rowActionButtons,
    mobileRowTemplate,
    rowExpansionTemplate,
    rowActionButtonsMenuModel,
    setGlobalFilter: onGlobalSearch
  };

  return (
    <div className="mt-3">
      <TableCard height={'calc(100vh - 120px)'}>
        {/* Page title */}
        <Table {...dataTableProps} />

        {/* Add new practitioner dialog */}
        <Dialog
          header={t('Add_new_practitioner')}
          contentClassName="pb-0"
          visible={showNewPractitionerDialog}
          style={{ width: '25vw' }}
          breakpoints={{ '1366px': '35vw', '960px': '55vw', '768px': '95vw' }}
          baseZIndex={1000000}
          onHide={onCloseNewPractitioner}
        >
          <AddPractitioner onCancel={onCloseNewPractitioner} onCreateResponseAction={onCreateNewPractitionerResponseAction} />
        </Dialog>

        {/* Delete practitioner(s) confirmation */}
        <DeleteConfirmation
          visible={showDeleteConfirm}
          message={deletePractitionerConfirmationMessage()}
          header={t('Delete_Practitioner')}
          icon="pi pi-exclamation-triangle"
          acceptLabel={t('Yes')}
          rejectLabel={t('No')}
          accept={onDeletePractitioner}
          reject={closeDeleteConfirm}
          onHide={closeDeleteConfirm}
          baseZIndex={1000}
        />
      </TableCard>
    </div>
  );
};

export default Practitioners;
