import React, { useContext } from 'react';

import Context from '../../../../Context';
import AddressLayout from '../../../../components/Layouts/AddressLayout';
import EditBillTo from './EditBillToAddress';

import { inputs } from './claimDetailsHelpers/inputs';
import { elementIDs } from '../../../config/elementIDsConfig';
import { Dialog } from 'primereact/dialog';
import { onBillToUpdate } from './claimDetailsHelpers/onClaimUpdate';
import { patientFullName } from '../../../utils/patientFullName';

const BillToAddress = ({ showAddressDialog, setShowAddressDialog }) => {
  const { privateRecord } = useContext(Context);
  const billTo = privateRecord?.BillTo?.[0] || {};

  const onHideDialog = (data) => {
    const billToGuid = data?.PatientGuid || billTo?.PatientGuid;

    // If there is no bill to, then we need to update the bill to with the patient
    if (!billToGuid) {
      const patient = Array.isArray(privateRecord.Patients) ? privateRecord.Patients[0] : privateRecord.Patients;
      onBillToUpdate([patient]);
    }

    setShowAddressDialog(false);
  };

  return (
    <div id={elementIDs.billToAddressWrap}>
      <div className="flex align-items-center">
        <div className="flex flex-column line-height-3">
          <div className="flex col-12 p-0 m-0">{patientFullName(billTo)}</div>
          {billTo?.Comment && <div className="m-0">{billTo?.Comment}</div>}
          {billTo?.Facility && <div className="p-0 m-0">{billTo?.Facility}</div>}
          {billTo?.CompanyName && <div className="p-0 m-0">{billTo?.CompanyName}</div>}
          <AddressLayout data={privateRecord[inputs().billTo.name] ? privateRecord[inputs().billTo.name][0] : ''} />
        </div>
      </div>

      <Dialog
        className="w-full"
        contentClassName="pb-0"
        header="Edit payer"
        visible={showAddressDialog}
        style={{ maxWidth: '530px' }}
        onHide={onHideDialog}
      >
        <EditBillTo onClose={onHideDialog} />
      </Dialog>
    </div>
  );
};

export default BillToAddress;
