import React from 'react';
import Privacy from '../../../../components/Privacy';
import { termsConfirmationAuth } from '../../../../service/Lookup';
import { useSelector } from 'react-redux';
import { routes } from '../../../../routes/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../../../AuthProvider';

const AuthPrivacy = () => {
  const user = useSelector((state) => state.user.details);
  const history = useHistory();
  const { login } = useAuth();

  const onAccept = async () => {
    const payload = {
      DCNGuid: user.DCNGuid,
      DoctorGuid: user.DoctorGuid,
      termsAccepted: true
    };
    await termsConfirmationAuth(payload);
    login(user);
  };

  const onCancel = () => {
    history.replace(routes.authPrivacyDeny.path);
  };

  return <Privacy onAccept={onAccept} onCancel={onCancel} />;
};

export default AuthPrivacy;
