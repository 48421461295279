import React from 'react';
import { useSelector } from 'react-redux';
import PrivacyAccept from '../../../../components/Privacy/PrivacyAccept';

const RegisterPrivacyAccept = () => {
  const formState = useSelector((state) => state.registration.registrationFormState);
  const firstName = formState.initialFormState.firstName;
  const lastName = formState.initialFormState.lastName;
  const userName = `${firstName} ${lastName}`;
  const email = formState.initialFormState.email;

  return <PrivacyAccept userName={userName} email={email} />;
};

export default RegisterPrivacyAccept;
