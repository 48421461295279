import { aoiCodeMaxEntry, getMinBMI, icd9CodeMaxEntry, maxBMI, noiCodeMaxEntry, referralCodeMaxEntry } from '../../../../config/defaultValuesConfig';
import {
  checkIfCodeNeedsReferralBy,
  checkIfCodeNeedsReferralTo,
  checkIfCodeValid4Age,
  checkIfCodeValid4Location,
  checkOffHoursCorrespond,
  feeCodeNeedsOnlyStartTime,
  feeCodeNeedsTime,
  isFeeCodesDurationTooShort
} from './claimFormValidation';
import { store } from '../../../../../store';
import { inputs } from './inputs';
import { invoiceTypes, refToByInit } from './defaultValues';
import { NA_DoctorGuid } from '../../../../config/defaultValuesConfig';
import { getAgeAtDOS, isAllPatientsOlder18 } from '../../../../utils/getAge';
import { feeCodesNeedsReferralBy } from './codesCheckList';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { validateCanadianZip } from '../../../../regex/regex';
import { formatPatientsListForFirstVisit } from './dataForSubmit';
import { isPhysician, isSupplementary } from '../../../../config/specialitiesConfig';
import { includes, intersection, isEmpty, some } from 'lodash';
import { t } from '../../../../../service/localization/i18n';
import moment from 'moment';
import * as yup from 'yup';
import * as checkList from './codesCheckList';
import { stringFormat } from '../../../../utils/stringFormat';
import { checkDateDiff, isWeekendOrHoliday } from '../../../../utils/formatDate';
import { isNightTime, timeDiff } from '../../../../utils/formatTime';

const minDate = '01/01/1990';

const createTimeValidation = (requirementFn, dependencies) =>
  yup
    .string()
    .nullable()
    .test('valid-time', 'Invalid time', (value) => {
      if (!value) return true; // Allow nullable values if the field is optional

      // Regex to validate "HH:mm" format
      const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)|24:00$/;
      return timeRegex.test(value);
    })
    .when(dependencies, {
      is: (...args) => requirementFn(...args),
      then: yup.string().required(t('Mandatory_field.1')).nullable(),
      otherwise: yup.string().notRequired().nullable()
    });

const patientInputValidation = (isGroup) => {
  return {
    [inputs.patient.name]: yup
      .array()
      .min(1, t('Mandatory_field.1'))
      .required(t('Mandatory_field.1'))
      .test('max', t('Limit_one_patient'), function (value) {
        const { path, createError } = this;
        const invoiceType = this.resolve(yup.ref(inputs.payor.name));
        const speciality = this.resolve(yup.ref(inputs.speciality.name));
        const max = isGroup ? 999 : invoiceType === invoiceTypes.wsbc || invoiceType === invoiceTypes.icbc || speciality === 18 ? 1 : 999;
        return value?.length <= max || createError({ path, message: t('Limit_one_patient') });
      })
  };
};

// Common validation for all Teleplan views - Teleplan, Teleplan POS, Teleplan Group, Teleplan 1st Visit
const commonValidationForAll = (isNew, firstVisit = false, isGroup, step) => {
  return {
    [inputs.practitioner.name]: yup.string().when(inputs.practitioner.name, {
      is: (value) => !value || value === NA_DoctorGuid,
      then: yup.string().min(NA_DoctorGuid.length + 1, t('Mandatory_field.1')),
      otherwise: yup.string().notRequired()
    }),

    // [inputs.payeeNumber.name]: yup.string().required(t('Mandatory_field.1')),
    [inputs.payeeNumber.name]: yup
      .string()
      .required(t('Mandatory_field.1')) // ✅ Mandatory field
      .test(
        'is-valid-payee',
        'Invalid payee number', // Message if validation fails
        (value) => isValidPayeeNumber(value) // ✅ Use custom validation function
      ),

    [inputs.locationCode.name]: yup
      .string()
      .nullable()
      .when(inputs.saveAsDraft.name, {
        is: (saveAsDraft) => locationCodeInputRequirements(saveAsDraft),
        then: yup.string().required(t('Mandatory_field.1')),
        otherwise: yup.string().notRequired().nullable()
      }),

    [inputs.serviceDate.name]: yup.mixed().test('is-service-date-required', t('Mandatory_field.1'), function (value) {
      // Check if serviceDate is an array and has at least one date element
      if (Array.isArray(value) && value.length > 0) {
        return true;
      }

      // Check if serviceDate is a single date and it's not null or undefined
      if (!Array.isArray(value) && value !== null && value !== undefined) {
        return true;
      }

      // If none of the above conditions are met, serviceDate is not valid
      return false;
    }),

    [inputs.claimNumber.name]: yup.string().when(inputs.payor.name, {
      is: (invoiceType) => invoiceType === invoiceTypes.icbc,
      then: yup.string().test('is-claimNumber-valid', t('Invalid_number'), function (claimNumber) {
        const isValidICBCNum = validateICBCNum(claimNumber);
        const invoiceType = this.parent[inputs.payor.name];
        const isICBC = invoiceType === invoiceTypes.icbc;
        if (!isValidICBCNum) store.dispatch(setToastMessage({ message: t('Invalid_ICBC') }));
        return isICBC && isValidICBCNum;
      }),
      otherwise: yup.string().notRequired()
    }),

    [inputs.injuryDate.name]: yup
      .date()
      .nullable()
      .when(inputs.payor.name, {
        is: (invoiceType) => injuryDateInputRequirements(invoiceType),
        then: yup
          .date()
          .min(minDate, `${t('Year_minimum_value_is')} ${minDate}`)
          .required(t('Mandatory_field.1'))
          .nullable(),
        otherwise: yup.date().notRequired().nullable()
      }),

    [inputs.aoi.name]: yup
      .array()
      .nullable()
      .when([inputs.payor.name, inputs.saveAsDraft.name], {
        is: (invoiceType, saveAsDraft) => aoiInputRequirements(invoiceType, saveAsDraft),
        then: yup
          .array()
          .min(1, t('Mandatory_field.1'))
          .max(aoiCodeMaxEntry, `${t('Maximum_number_of_entries_is_X')} ${aoiCodeMaxEntry}`)
          .required(t('Mandatory_field.1'))
          .nullable(),
        otherwise: yup.array().notRequired().nullable()
      }),

    [inputs.noi.name]: yup
      .array()
      .nullable()
      .when([inputs.payor.name, inputs.saveAsDraft.name], {
        is: (invoiceType, saveAsDraft) => noiInputRequirements(invoiceType, saveAsDraft),
        then: yup
          .array()
          .min(1, t('Mandatory_field.1'))
          .max(noiCodeMaxEntry, `${t('Maximum_number_of_entries_is_X')} ${noiCodeMaxEntry}`)
          .required(t('Mandatory_field.1'))
          .nullable(),
        otherwise: yup.array().notRequired().nullable()
      }),

    [inputs.bmi.name]: yup
      .number()
      .nullable()
      .when([inputs.feeCodes.codeType, inputs.patient.name, inputs.birthDay.name], {
        is: (feeCodes, patients, birthDay) => {
          const patientsList = firstVisit ? [{ BirthDay: birthDay }] : patients;
          const isAnyPatientYounger18 = isAllPatientsOlder18(patientsList); // CMO-3109 - Make BMI validation age dependent
          const feeCodesOnly = feeCodes?.map((i) => i.value);
          const isBMICode = checkList.codesThatRequiresBMI.some((i) => feeCodesOnly?.includes(i));
          return isBMICode && isNew && isAnyPatientYounger18;
        },
        then: yup
          .number()
          .nullable()
          .required(t('Mandatory_field.1'))
          // .min(minBMI, t('bmi_must_be_at_least'))
          .max(maxBMI, t('bmi_must_be_at_most'))
          .test('test-feeCodes', '', function (value) {
            const { path, createError } = this;
            const bmi = Number(value);
            const feeCodes = this.parent[inputs.feeCodes.codeType];
            const feeCodesOnly = feeCodes?.map((i) => i.value);
            const minBMIValue = getMinBMI(feeCodesOnly);
            const message = stringFormat(t('bmi_must_be_at_least'), minBMIValue);
            return bmi >= minBMIValue || createError({ path, message });
          }),
        otherwise: yup.number().notRequired().nullable()
      }),

    [inputs.feeCodes.name]: yup
      .array()
      .nullable()
      .when(inputs.saveAsDraft.name, {
        is: (saveAsDraft) => feeCodeInputRequirements(saveAsDraft),
        then: yup
          .array()
          .min(1, t('Mandatory_field.1'))
          .required(t('Mandatory_field.1'))
          .test('test-feeCodes', '', function (value) {
            // Do not validate for batch screen
            if (isGroup) return true; // VER-693 - Batch -> validation of restrictive codes on step 0 does not let the user proceed to next step

            const { path, createError } = this;
            const patients = firstVisit ? formatPatientsListForFirstVisit(this.parent) : this.parent[inputs.patient.name];
            const serviceDate = this.parent.ServiceDate ?? new Date();
            // CMO-1705 - Validate that the selected billing code matches the age before Generate Invoice
            // check if fee codes valid for age
            const validateAge = patients?.reduce((acc, currentValue) => {
              const invalidCodes =
                value?.filter((i) => {
                  return !checkIfCodeValid4Age(i, getAgeAtDOS(currentValue.BirthDay, serviceDate));
                }) || [];
              return [...acc, ...invalidCodes];
            }, []);

            const message = invalidAgeMessage(validateAge);

            if (validateAge?.length) {
              store.dispatch(setToastMessage({ type: 'warn', message }));
            }
            return !validateAge?.length || createError({ path, message });
          })
          .nullable(),
        otherwise: yup.array().notRequired().nullable()
      }),

    [inputs.comment.name]: yup
      .string()
      .nullable()
      .when(
        [
          inputs.submission.name,
          inputs.payor.name,
          inputs.saveAsDraft.name,
          inputs.serviceDate.name,
          inputs.startTime.name,
          inputs.endTime.name,
          inputs.feeCodes.codeType,
          inputs.units.name,
          inputs.speciality.name
        ],
        {
          is: (submissionCode, invoiceType, saveAsDraft, serviceDate, startTime, endTime, feeCodes, units, speciality) => {
            return commentInputRequirements({
              submissionCode,
              invoiceType,
              saveAsDraft,
              serviceDate,
              startTime,
              endTime,
              feeCodes,
              step,
              units,
              speciality
            });
          },
          then: yup.string().required(t('Mandatory_field.1')).nullable(),
          otherwise: yup.string().notRequired().nullable()
        }
      )
  };
};

// Common validation for Teleplan, Teleplan POS, Teleplan 1st Visit
const commonValidationForBasicTeleplan = (isNew, firstVisit, isGroup, step) => {
  return {
    ...commonValidationForAll(isNew, firstVisit, isGroup, step),

    [inputs.reasonFor.name]: yup
      .array()
      .nullable()
      .when(inputs.feeCodes.codeType, {
        is: (feeCodes) => reasonFor01080InputRequirements(feeCodes, isGroup, isNew),
        then: yup.array().min(1, t('Mandatory_field.1')).required(t('Mandatory_field.1')).nullable(),
        otherwise: yup.array().notRequired().nullable()
      }),

    [inputs.icd9.name]: yup
      .array()
      .nullable()
      .when(inputs.saveAsDraft.name, {
        is: (saveAsDraft) => icd9InputRequirements(saveAsDraft),
        then: yup
          .array()
          .min(1, t('Mandatory_field.1'))
          .max(icd9CodeMaxEntry, `${t('Maximum_number_of_entries_is_X')} ${icd9CodeMaxEntry}`)
          .required(t('Mandatory_field.1'))
          .nullable(),
        otherwise: yup.array().notRequired().nullable()
      }),

    [inputs.callTime.name]: createTimeValidation(callTimeInputRequirements, [
      inputs.emergency.name,
      inputs.travel.name,
      inputs.saveAsDraft.name,
      inputs.speciality.name,
      inputs.payor.name
    ]),

    [inputs.startTime.name]: createTimeValidation(startTimeInputRequirements, [
      inputs.emergency.name,
      inputs.feeCodes.name,
      inputs.saveAsDraft.name,
      inputs.speciality.name,
      inputs.payor.name
    ]),

    [inputs.endTime.name]: createTimeValidation(endTimeInputRequirements, [
      inputs.emergency.name,
      inputs.feeCodes.name,
      inputs.saveAsDraft.name,
      inputs.speciality.name,
      inputs.payor.name
    ]),

    [inputs.referral.name]: yup
      .array()
      .nullable()
      .when([inputs.refToBy.name, inputs.saveAsDraft.name], {
        is: (refToBy, saveAsDraft) => referralInputRequirements(refToBy, saveAsDraft),
        then: yup
          .array()
          .min(1, t('Mandatory_field.1'))
          .max(referralCodeMaxEntry, `${t('Maximum_number_of_entries_is_X')} ${referralCodeMaxEntry}`)
          .required(t('Mandatory_field.1'))
          .nullable(),
        otherwise: yup.array().notRequired().nullable()
      })
  };
};

// Validation for Teleplan and Teleplan POS
const basicTeleplanValidation = (isNew, firstVisit, isGroup, step) => {
  return {
    ...commonValidationForBasicTeleplan(isNew, firstVisit, isGroup, step),
    ...patientInputValidation()
  };
};

// Validation for Teleplan 1st Visit
const firstVisitValidation = (isNew, firstVisit, isGroup) => {
  return {
    ...commonValidationForBasicTeleplan(isNew, firstVisit, isGroup),
    [inputs.phnProvince.name]: yup.string().required(t('Mandatory_field.1')).nullable(),

    [inputs.phn.name]: yup.string().required(t('Mandatory_field.1')).nullable(),

    [inputs.birthDay.name]: yup
      .date()
      .min('01/01/1900', `${t('Year_minimum_value_is')} 1900`)
      .max(moment(), `${t('Year_maximum_value_is')} ${moment().year()}`)
      .required(t('Mandatory_field.1'))
      .nullable(),

    [inputs.lastName.name]: yup.string().required(t('Mandatory_field.1')).nullable(),

    [inputs.firstName.name]: yup.string().required(t('Mandatory_field.1')).nullable(),

    [inputs.gender.name]: yup.string().required(t('Mandatory_field.1')).nullable(),

    [inputs.zip.name]: yup
      .string()
      .nullable()
      .test('is-canadian-zip', t('Invalid_entry'), function (value) {
        if (!value) {
          return true; // pass validation if value is empty or null
        }
        return validateCanadianZip.test(value);
      })
  };
};

// Validation for Teleplan Group
const teleplanGroupValidation = (isNew, firstVisit, isGroup) => {
  return {
    ...commonValidationForAll(isNew, firstVisit, isGroup),
    ...patientInputValidation(isGroup)
  };
};

const validateICBCNum = (num) => {
  if (!num) return true;

  /* 8 character
      Step 1: Convert the first position from a letter to a number in the range of 0 - 9
      Step 2: If the second position is an alphabet, then convert it to a number in the
      range of 0 to 9, otherwise keep the second position number
      Step 3: Divide position 1 through 7 by the number 7 with the remainder being the
      calculated check digit. Use whole numbers only.
      Step 4: Compare the remainder of this division to the last digit of the ICBC field
      (position 8). If they are equal the check digit is correct
       */

  const Alpha = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
  const Atoi = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '1', '2', '3', '4', '5', '6'];

  const ClaimNumber = num.trim();

  if (ClaimNumber.length !== 8) return false;
  // calculate MOD7 CRC
  let Converted = '';
  const sLast = ClaimNumber.substr(7, 1);
  for (var i = 0; i < 7; i++) {
    var sOne = ClaimNumber.substr(i, 1).toUpperCase();
    const isAlpha = /^[a-zA-Z]$/.test(sOne);
    if ((i === 0 || i === 1) && isAlpha) {
      var index = Alpha.indexOf(sOne);
      sOne = Atoi[index];
    }
    Converted += sOne;
  }

  const nTotal = parseInt(Converted, 10);
  if (nTotal) {
    const crc = nTotal % 7;
    if (crc === parseInt(sLast, 10)) return true;
  }

  return false;
};

export const invalidAgeMessage = (feeCodes) => {
  if (!feeCodes?.length) return;

  if (checkList.age_over_18.some((code) => feeCodes.includes(code))) {
    return t('Patients_age_doesnt_match_must_be_18_years_or_older');
  }

  if (checkList.age_over_75.some((code) => feeCodes.includes(code))) {
    return t('Patients_age_doesnt_match_must_be_75_years_or_older');
  }

  if (checkList.age_below_18.some((code) => feeCodes.includes(code))) {
    return t('Patients_age_doesnt_match_must_be_from_1_to_17_years_old');
  }

  return stringFormat(t('Wrong_fee_item_code'), feeCodes?.join()?.replaceAll(',', ', '));
};

export const validationSchema = ({ isNew, firstVisit, isGroup, step }) => {
  const validation = () => {
    if (firstVisit) return firstVisitValidation(isNew, firstVisit, isGroup);
    if (isGroup) return teleplanGroupValidation(isNew, firstVisit, isGroup);
    return basicTeleplanValidation(isNew, firstVisit, isGroup, step);
  };

  return yup.object().shape(validation(), [
    // Add Cyclic deps here because when require itself
    [inputs.practitioner.name, inputs.practitioner.name],
    [inputs.claimNumber.name, inputs.claimNumber.name]
  ]);
};

export const locationCodeInputRequirements = (saveAsDraft) => {
  return !saveAsDraft;
};

export const injuryDateInputRequirements = (invoiceType) => {
  return invoiceType === invoiceTypes.wsbc;
};

export const aoiInputRequirements = (invoiceType, saveAsDraft) => {
  return invoiceType === invoiceTypes.wsbc && !saveAsDraft;
};

export const noiInputRequirements = (invoiceType, saveAsDraft) => {
  return invoiceType === invoiceTypes.wsbc && !saveAsDraft;
};

export const feeCodeInputRequirements = (saveAsDraft) => {
  return !saveAsDraft;
};

export const icd9InputRequirements = (saveAsDraft) => {
  return !saveAsDraft;
};

export const callTimeInputRequirements = (emergency, travel, saveAsDraft, speciality, invoiceType) => {
  return emergency && travel && !saveAsDraft && !isSupplementary(speciality, invoiceType);
};

export const startTimeInputRequirements = (emergency, feeCodes, saveAsDraft, speciality, invoiceType) => {
  return (
    (emergency || feeCodeNeedsTime(feeCodes) || feeCodeNeedsOnlyStartTime(feeCodes)) && !saveAsDraft && !isSupplementary(speciality, invoiceType)
  );
};

export const endTimeInputRequirements = (emergency, feeCodes, saveAsDraft, speciality, invoiceType) => {
  return (emergency || feeCodeNeedsTime(feeCodes)) && !saveAsDraft && !isSupplementary(speciality, invoiceType);
};

export const referralInputRequirements = (refToBy, saveAsDraft) => {
  return refToBy !== 'N' && !saveAsDraft;
};

export const commentInputRequirements = ({
  submissionCode,
  invoiceType,
  saveAsDraft,
  serviceDate,
  startTime,
  endTime,
  feeCodes = [],
  step,
  units,
  speciality
}) => {
  const isMSPorICBC = invoiceType === invoiceTypes.msp || invoiceType === invoiceTypes.icbc;
  const isWSBC = invoiceType === invoiceTypes.wsbc;
  const dateDiff = serviceDate && checkDateDiff(serviceDate);
  const duration = timeDiff(startTime, endTime);
  const feeCodesOnly = feeCodes?.map((i) => i.value);
  const more90Days = dateDiff < -90;
  const less90Days = dateDiff >= -90;
  const isFeeCodeRequiredComment = step > 1 && checkList.feeCodesNeedsComment.some((i) => feeCodesOnly?.includes(i));
  let groupPhysiotherapyRequired = false;

  // VER-764 - 01111-> add max of 4 units without a note, more than 4 - note is required
  const commentRequiredFor01111 = isCommentRequiredFor01111({ feeCodes, units });
  if (commentRequiredFor01111) return true;

  // VER-550 - Midwives->code 36081->note required for 10 units and above
  const commentRequiredForMidwives = isCommentRequiredForMidwives({ feeCodes, units, speciality });
  if (commentRequiredForMidwives) return true;

  if (isFeeCodeRequiredComment) groupPhysiotherapyRequired = true;

  // VER-409 - Group physiotherapy
  if (duration > 120 && step > 1) {
    groupPhysiotherapyRequired = checkList.groupPhysiotherapyCodes.some((i) => feeCodesOnly?.includes(i.value));
  }

  // CMO-3190
  return (
    (groupPhysiotherapyRequired || submissionCode === 'X' || submissionCode === 'D' || (submissionCode === 'A' && isMSPorICBC && more90Days)) &&
    // || (submissionCode === 'R' && isMSPorICBC && less90Days)
    // || (submissionCode === 'W' && isWSBC) // Commented because of VER-829 - Submission code W->no note is needed
    !saveAsDraft
  );
};

export const checkIfCodeValidForLocation = (feeCodes, locationCode, speciality) => {
  const currentMessage = store.getState().core.toastMessage.message;
  const checkCode4Location = checkIfCodeValid4Location(feeCodes, locationCode);
  const validMidwifeConsultativeCodes = intersection(feeCodes, checkList.midwifeConsultativeCodes);
  const validMidwifeInductionCodes = intersection(feeCodes, checkList.midwifeInductionCodes);
  const validMidwifePhase4HomeBirthCodes = intersection(feeCodes, checkList.midwifePhase4HomeBirthCodes);

  const notBillableMessage = `${checkCode4Location.toString()} ${t('feeCodes_are_not_billable')}`;
  const messageForPCode = t('Location_code_P_is_not_suitable_for_selected_fee_items');
  const messageForMidwifeConsultativeCode = stringFormat(
    t('Location_code_is_not_compatible_with_fee_code'),
    locationCode,
    validMidwifeConsultativeCodes?.join().replaceAll(',', ', ')
  );
  const messageForMidwifeInductionCode = t('Service_must_be_performed_in_hospital');
  const messageForMidwifePhase4HomeBirthCode = t('Fee_code_36045_requires_location_code_R');

  const showWarnForMidwifeConsultative =
    speciality === 80 &&
    validMidwifeConsultativeCodes.length > 0 &&
    includes(checkList.validMidwifeConsultativeHospital, locationCode) &&
    messageForMidwifeConsultativeCode !== currentMessage;

  const showWarnForMidwifeInduction =
    speciality === 80 &&
    validMidwifeInductionCodes.length > 0 &&
    !includes(checkList.validMidwifeInductionHospital, locationCode) &&
    messageForMidwifeInductionCode !== currentMessage;

  const showWarnForMidwifePhase4HomeBirth =
    speciality === 80 &&
    validMidwifePhase4HomeBirthCodes.length > 0 &&
    !includes(checkList.validmidwifePhase4HomeBirthHospital, locationCode) &&
    messageForMidwifePhase4HomeBirthCode !== currentMessage;

  if (!!checkCode4Location && notBillableMessage !== currentMessage) {
    store.dispatch(setToastMessage({ type: 'warn', message: notBillableMessage }));
    // return value for cypress tests
    return { type: 'warn', message: notBillableMessage };
  }

  // CMO-2783 - Midwives -> validate location code for some fee codoes
  if (showWarnForMidwifeConsultative) {
    store.dispatch(setToastMessage({ type: 'warn', message: messageForMidwifeConsultativeCode }));
    // return value for cypress tests
    return { type: 'warn', message: messageForMidwifeConsultativeCode };
  }

  // CMO-3256 - [Teleplan] midves, 36094 and 36095
  // if (showWarnForMidwifeInduction) {
  //   store.dispatch(setToastMessage({ type: "warn", message: messageForMidwifeInductionCode }));
  //   // return value for cypress tests
  //   return ({ type: "warn", message: messageForMidwifeInductionCode });
  // };

  // CMO-2783 - Midwives -> validate location code for some fee codoes
  if (showWarnForMidwifePhase4HomeBirth) {
    store.dispatch(setToastMessage({ type: 'warn', message: messageForMidwifePhase4HomeBirthCode }));
    // return value for cypress tests
    return { type: 'warn', message: messageForMidwifePhase4HomeBirthCode };
  }

  if (locationCode === 'P' && feeCodes?.length && speciality === 80 && messageForPCode !== currentMessage) {
    store.dispatch(setToastMessage({ type: 'warn', message: messageForPCode }));
    // return value for cypress tests
    return { type: 'warn', message: messageForPCode };
  }

  // return value for cypress tests
  return { type: null, message: null };
};

export const validateOffHours = (feeCodes, serviceDate, startTime, endTime) => {
  const checker = checkOffHoursCorrespond(feeCodes, serviceDate, startTime, endTime);
  const warnMsg = store.getState().core.toastMessage.message;
  if (checker.warn && warnMsg !== checker.warnMsg) {
    store.dispatch(setToastMessage({ type: 'warn', message: checker.warnMsg, lifeTime: 20000 }));
  }
};

export const getReferralValueByFeeCode = (feeCodes = []) => {
  if (some(feeCodes, checkIfCodeNeedsReferralBy)) {
    return 'B';
  }

  if (some(feeCodes, (code) => !checkIfCodeNeedsReferralBy(code) && checkIfCodeNeedsReferralTo(code))) {
    return 'T';
  }

  return 'N';
};

export const checkIfRefNumberIsRequeredOnAddingCode = ({ feeCodes, refToBy, preferences, setValue, speciality, isGroup, invoiceType }) => {
  feeCodes?.forEach((i) => {
    let suspectValue;
    if (checkIfCodeNeedsReferralBy(i.value)) suspectValue = 'B';
    if (!checkIfCodeNeedsReferralBy(i.value) && checkIfCodeNeedsReferralTo(i.value)) suspectValue = 'T';

    const initRefToBy = refToByInit({ preferences: preferences?.content, speciality, isGroup, invoiceType });
    const refToByInitValue = initRefToBy !== suspectValue ? suspectValue : initRefToBy;
    const shoudUpdateRefToBy = refToByInitValue !== refToBy;
    if ((checkIfCodeNeedsReferralBy(i.value) || checkIfCodeNeedsReferralTo(i.value)) && shoudUpdateRefToBy) {
      setValue(inputs.refToBy.name, refToByInitValue);
    }
  });
};

export const checkFor03333Code = ({ feeCodes, refToBy, preferences, setValue, speciality, isGroup, invoiceType }) => {
  let updatedCodes = feeCodes;
  const code03333 = feeCodes?.find((i) => i.value === '03333');

  if (code03333 && feeCodes?.length > 1) {
    const isFeeCodeWhichNeedReferral = feeCodes.some((i) => feeCodesNeedsReferralBy.indexOf(i.value) >= 0);
    if (isFeeCodeWhichNeedReferral) {
      const removeCode03333 = feeCodes?.filter((i) => i.value !== code03333.value);
      updatedCodes = removeCode03333;
      checkIfRefNumberIsRequeredOnAddingCode({
        feeCodes: removeCode03333,
        refToBy,
        preferences,
        setValue,
        speciality,
        isGroup,
        invoiceType
      });
      store.dispatch(setToastMessage({ type: 'warn', message: t('Please_create_a_claim_for_03333_code_separately') }));
    }
  } else {
    checkIfRefNumberIsRequeredOnAddingCode({ feeCodes, refToBy, preferences, setValue, speciality, isGroup, invoiceType });
  }

  return updatedCodes;
};

export const checkIfFeeCodeNeedsEmergency = (feeCodes) => {
  return intersection(feeCodes, checkList.feeCodeNeedsEmergency).length > 0;
};

export const reasonFor01080InputRequirements = (feeCodes, isGroup, isNew) => {
  return isNew && !isGroup && some(feeCodes, { value: '01080' });
};

// VER-550 - Midwives->code 36081->note required for 10 units and above
export const isCommentRequiredForMidwives = ({ feeCodes, units, speciality }) => {
  if (Number(speciality) !== 80 || !feeCodes || !feeCodes.length || !units) return false;
  const isCodeRequiredNote = feeCodes.some((i) => checkList.requiresNoteCodesForMidwives.includes(i.value));
  if (isCodeRequiredNote && Number(units) >= 10) return true;
  return false;
};

const isCommentRequiredFor01111 = ({ feeCodes, units }) => {
  const isCodeRequiredNote = feeCodes.some((i) => i.value === '01111');
  if (isCodeRequiredNote && Number(units) > 4) return true;
};

const validationForBMISurgicalAssist = ({ feeCodes }) => {
  const feeCodesOnly = feeCodes?.map((i) => i.value);
  const isBMISurgicalAssist = checkList.bmiSurgicalAssistCodes.some((i) => feeCodesOnly?.includes(i));
  if (!isBMISurgicalAssist) return true;
  if (isBMISurgicalAssist) {
    const code13003 = feeCodes?.find((i) => i.value === '13003');
    const baseCodes = code13003?.base_codes?.split(',') || checkList.bmiSurgicalAssistBaseCodes;
    const isBaseCodeSelected = baseCodes?.some((i) => feeCodesOnly.includes(i));
    return isBaseCodeSelected;
  }
};

const validationForIECCodes = ({ feeCodes, locationCode }) => {
  const feeCodesOnly = feeCodes?.map((i) => i.value);
  const isIECCodeSelected = checkList.inpatientExtendedConsultation.some((i) => feeCodesOnly?.includes(i));
  const isValidLocation = locationCode === 'E' || locationCode === 'I';
  if (isIECCodeSelected) {
    if (!isValidLocation) return false; // Show dialog with "Service location should be E or I" message
    const isConsultationAnaesthesiaCodeSelected = feeCodesOnly.includes('01015');
    return isConsultationAnaesthesiaCodeSelected;
  }

  return true;
};

export const validateBeforSubmit = ({ invoice, localState, setLocalState, isNew, isGroup, errors, doNotShowToday }) => {
  const ignoreValidation = invoice[inputs.ignoreValidation.name] || false;
  const serviceDate = invoice[inputs.serviceDate.name];
  const speciality = invoice[inputs.speciality.name];
  const anatomicCode = invoice[inputs.anatomicCode.name];
  const emergency = invoice[inputs.emergency.name];
  const startTime = invoice[inputs.startTime.name];
  const endTime = invoice[inputs.endTime.name];
  const locationCode = invoice[inputs.locationCode.name];
  const feeCodes = invoice[inputs.feeCodes.codeType];
  const feeCodesOnly = feeCodes?.map((i) => i.value);
  const invoiceType = invoice[inputs.payor.name];
  const submissionCode = invoice[inputs.submission.name];
  const isMSP = invoiceType === invoiceTypes.msp;
  const isWSBC = invoiceType === invoiceTypes.wsbc;
  const isICBC = invoiceType === invoiceTypes.icbc;
  const dateDiff = checkDateDiff(serviceDate);

  const isWeekendCondidion = !ignoreValidation && serviceDate && isWeekendOrHoliday(serviceDate) && isPhysician(speciality) && !emergency;
  const isNightTimeCondition = !ignoreValidation && startTime && isNightTime(startTime) && isPhysician(speciality) && !emergency;

  const dateDiffCondition = !localState.submitionCodeDialog && dateDiff < -90 && (isMSP || isICBC || (isWSBC && submissionCode !== 'W')); // CMO-3124 - Overage WSBC claims ->new and declined ->default submission code, comment and warning

  const isFeeCodesNeedWeekend = feeCodesOnly.some((item) => checkList.feeCodesNoNeedWeekendValidation.includes(item));
  const checkOffHours = checkOffHoursCorrespond(feeCodesOnly, serviceDate, startTime, endTime);
  const showWeekendValidationDialog = isNew && (isWeekendCondidion || isNightTimeCondition) && !isFeeCodesNeedWeekend && !doNotShowToday;
  const isAnesProcedural =
    !localState.anesProceduralDialog && feeCodes?.length === 1 && intersection(feeCodesOnly, checkList.anesProceduralCodes).length > 0;
  const isAnatomicNone = !localState.anatomicCodeDialog && isWSBC && anatomicCode === 'N';
  const isWSBCConsultation = emergency && isWSBC && checkList.isWSBCConsultationCodes.some((code) => feeCodesOnly?.includes(code));
  const validMidwifeInductionCodes = intersection(feeCodesOnly, checkList.midwifeInductionCodes);
  const showWarnForMidwifeInduction =
    speciality === 80 && validMidwifeInductionCodes.length > 0 && !includes(checkList.validMidwifeInductionHospital, locationCode);
  const isBMISurgicalAssistValid = validationForBMISurgicalAssist({ feeCodes });
  const isBMISurgicalAssist = checkList.bmiSurgicalAssistCodes.some((i) => feeCodesOnly?.includes(i));
  const isIECCodeValid = validationForIECCodes({ feeCodes, locationCode }); // VER-415: Add validations for new codes 01118 and 01119
  const isFeeCodeForWSBC = feeCodesOnly.some((i) => i.startsWith('19')); // VER-400: Rules to check if code WSBC: if code starts from 19

  // [OB] - CMO-3159 validates that no codes has 0 units. But we decided to disable validation under CMO-3159 from 1st step because it prevents users from moving to step 2 and changing time there. We moved the validation that units more than 0 to the 3nd step, whether 0 comes from BE or entered by the user
  // const showFeeCodesDurationTooShortDialog = isFeeCodesDurationTooShort(feeCodes, startTime, endTime);

  if (!isEmpty(errors)) return;

  // CMO-3202 - Fee code 13003
  if (isBMISurgicalAssist && isGroup) {
    setLocalState((prevState) => ({ ...prevState, bmiSurgicalAssistForGroupDialog: true }));
    return false;
  }

  if (!isBMISurgicalAssistValid && isNew) {
    setLocalState((prevState) => ({ ...prevState, bmiSurgicalAssistDialog: true }));
    return false;
  }

  // VER-415 - Add validations for new codes 01118 and 01119
  if (!isIECCodeValid) {
    setLocalState((prevState) => ({ ...prevState, iecDialog: true }));
    return false;
  }

  // CMO-3159 - Validate that the number of units is 1 or more
  // [OB] - CMO-3159 validates that no codes has 0 units. But we decided to disable validation under CMO-3159 from 1st step because it prevents users from moving to step 2 and changing time there. We moved the validation that units more than 0 to the 3nd step, whether 0 comes from BE or entered by the user
  // if (showFeeCodesDurationTooShortDialog) {
  //   setLocalState(prevState => ({ ...prevState, feeCodeDurationDialog: true }));
  //   return false;
  // };

  // CMO-3179 - Validation for psychiatry codes 00633- 00639
  if (showWarnForMidwifeInduction) {
    setLocalState((prevState) => ({ ...prevState, invalidLocationForMidwifeInductionDialog: true }));
    return false;
  }

  // VER-400 - Teleplan->MSP or ICBC->show warning if user selects WSBC codes, force to change insurer
  if (isFeeCodeForWSBC && !isWSBC) {
    setLocalState((prevState) => ({ ...prevState, feeCodeForWSBCDialog: true }));
    return false;
  }

  if (checkOffHours.warn) {
    const warnMsg = store.getState().core.toastMessage.message;
    if (warnMsg !== checkOffHours.warnMsg) {
      store.dispatch(setToastMessage({ type: 'warn', message: checkOffHours.warnMsg, lifeTime: 20000 }));
    }
    return false;
  }

  // CMO-2995 - Make changes to WSBC billing ->Expedited button, 01015 to 19934, anatomic code
  if (isWSBCConsultation && !ignoreValidation) {
    setLocalState((prevState) => ({ ...prevState, wsbcConsultationDialog: true }));
    return false;
  }

  // CMO-2968 - Show a warning pop-up message if user bills 01080 separately
  // VER-732 - Rebill declined claim (Edit outstanding claims)->fee code 01080->don't show error message about main code
  if (isAnesProcedural && isNew) {
    setLocalState((prevState) => ({ ...prevState, anesProceduralDialog: true }));
    return false;
  }

  if (isAnatomicNone) {
    setLocalState((prevState) => ({ ...prevState, anatomicCodeDialog: true }));
    return false;
  }

  if (!localState.isPHNValid) {
    store.dispatch(setToastMessage({ type: 'warn', message: t('Invalid_PHN') }));
    return false;
  }

  // CMO-2245 - Add validation to Generate Invoices button on Create Teleplan and 1st visit screens
  if (showWeekendValidationDialog) {
    setLocalState((prevState) => ({ ...prevState, weekendValidationDialog: true }));
    return false;
  }

  // CMO-2109 - Add validation for late claims with submission code A
  if (dateDiffCondition) {
    setLocalState((prevState) => ({ ...prevState, submitionCodeDialog: true }));
    return false;
  }

  return true;
};

/**
 * Validates the payee number with the following rules:
 * 1. Must not be empty, '0', or '-1'.
 * 2. Can be alphanumeric (letters and digits).
 * 3. Must be at least 5 characters long.
 * 4. Must be a string.
 *
 * @param {string} payeeNumber - The payee number to validate.
 * @returns {boolean} - Returns true if valid, false otherwise.
 */
export const isValidPayeeNumber = (payeeNumber) => {
  // Exclude non-strings, empty, '0', and '-1'
  if (typeof payeeNumber !== 'string' || !payeeNumber || ['0', '-1'].includes(payeeNumber)) {
    return false;
  }

  // Regex Explanation:
  // ^[A-Za-z0-9]{5,}$ → Allows letters and digits, minimum of 5 characters
  return /^[A-Za-z0-9]{5,}$/.test(payeeNumber);
};

export const isCustomFeeCode = (feeCode = '') => {
  return checkList.customFeeCodes.includes(feeCode);
};
