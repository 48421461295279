import React from 'react';
import { InputWrapper } from '../../../Inputs';
import ControlledProvincePicker from '../../../Inputs/Province/ControlledProvincePicker';
import { patientInputWrapperStyles } from '../helpers/styles';

const Province = () => {
  return (
    <InputWrapper name="Province" label="Province/State" style={patientInputWrapperStyles}>
      <ControlledProvincePicker />
    </InputWrapper>
  );
};

export default Province;
