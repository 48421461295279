import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { strongPasswordRegex } from '../../../regex/regex';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';

const RegistrationPassword = () => {
  const {
    control,
    formState: { errors },
    clearErrors
  } = useFormContext();

  const passHeader = <div className="font-bold mb-3">Pick a password</div>;
  const passFooter = (
    <>
      <Divider />
      <p className="mt-2">Your password must meet complexity requirements:</p>
      <ul className="pl-2 ml-2 mt-0 line-height-3 list-disc">
        <li>Length should be at least 8 characters (50 characters max)</li>
        <li>Use of uppercase [A - Z] and lowercase [a - z] letters</li>
        <li>Use of numbers [0 - 9]</li>
        <li>Use one of the next special characters: [!,%,&,@,$,^,*,_,#,+,-,=,?,~,/,",',(,)]</li>
      </ul>
    </>
  );
  const preventDefault = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      <InputWrapper name="password" label="Select Password" required errorMessage={errors.password?.message}>
        <Controller
          name="password"
          control={control}
          rules={{
            required: 'Select Password is required',
            pattern: {
              value: strongPasswordRegex,
              message:
                'Password must be 8-50 characters long, include uppercase and lowercase Latin letters, numbers, and one special character from [!,%,&,@,$,^,*,_,#,+,-,=,?,~,/,",(,)].'
            }
          }}
          render={({ field }) => (
            <Password
              {...field}
              id="password"
              data-testid="password-testid"
              toggleMask
              header={passHeader}
              footer={passFooter}
              strongRegex={strongPasswordRegex}
              onCopy={(e) => {
                preventDefault(e);
              }}
              onPaste={(e) => {
                preventDefault(e);
              }}
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(value);
                value && errors.password?.message && clearErrors('password');
              }}
              className={classNames({ 'p-invalid': errors.password })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default RegistrationPassword;
