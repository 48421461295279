import React from 'react';
import { Badge } from 'primereact/badge';
import { classNames } from 'primereact/utils';

/**
 * A button component with a badge.
 *
 * @param {ButtonWithBadgeProps} props - The props for the Badge component.
 * @returns {JSX.Element} The rendered ButtonWithBadge component.
 */
export default function ButtonWithBadge({ children, rootClassName, ...props }) {
  return (
    <div className={classNames('relative', rootClassName)}>
      {children}
      {props.value ? (
        <Badge
          {...props}
          className={classNames('absolute m-0', props.className)}
          style={props.style || { top: '-10px', right: '-10px' }}
          severity={props.severity || 'info'}
        />
      ) : null}
    </div>
  );
}
