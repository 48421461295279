import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { routes } from '../../../../../routes/routes';
import { shouldUsePin } from '../../../../utils/pinCode';

const LoginWithPinButton = () => {
  const history = useHistory();

  if (!shouldUsePin()) {
    return null;
  }

  return <Button label="Login with PIN-code" link onClick={() => history.push(routes.signInWithPin.path)} />;
};

export default LoginWithPinButton;
