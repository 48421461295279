import React, { useMemo, useRef } from 'react';
import { Chart } from 'primereact/chart';
import { colors } from '../../../config/stylesConfig';

const CashFlowLineChart = ({ data }) => {
  const chartRef = useRef(null);

  const chartData = useMemo(() => {
    const items = data?.Items;

    if (!items?.length) {
      return null;
    }

    const ratioPercent = Number(data?.RatioBilled) || 0;
    const isRatioPercentNegative = ratioPercent < 0;
    const color = isRatioPercentNegative ? colors.danger : colors.success;

    const labels = Array.from({ length: items?.length }, () => '');
    const paidData = items?.map((i) => i.TotalBilled) || [];
    const isAllZero = paidData.every((val) => val === 0);

    if (isAllZero) {
      return null;
    }

    return {
      labels: labels,
      datasets: [
        {
          data: paidData,
          fill: true,
          borderColor: color,
          backgroundColor: (ctx) => {
            const chart = ctx.chart;
            const { ctx: canvasContext, chartArea } = chart;

            if (!chartArea) {
              return null; // Return early if chart area is not available
            }

            // Create a gradient fill for the background based on danger or success color
            const gradient = canvasContext.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
            if (isRatioPercentNegative) {
              gradient.addColorStop(0, 'rgba(255, 99, 132, 0.7)'); // Darker danger color at the top
              gradient.addColorStop(1, 'rgba(255, 99, 132, 0)'); // Lighter danger color at the bottom
            } else {
              gradient.addColorStop(0, 'rgba(75, 192, 192, 0.7)'); // Darker success color at the top
              gradient.addColorStop(1, 'rgba(75, 192, 192, 0)'); // Lighter success color at the bottom
            }

            return gradient;
          },
          tension: 0.4,
          pointRadius: 0 // No points, just the line
        }
      ]
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const options = useMemo(() => {
    return {
      responsive: true, // Make the chart responsive
      maintainAspectRatio: false, // Don't lock the aspect ratio
      plugins: {
        legend: {
          display: false // Hide legend
        }
      },
      scales: {
        x: {
          display: false // Hide x-axis
        },
        y: {
          display: false // Hide y-axis
        }
      },
      elements: {
        line: {
          borderWidth: 2 // Adjust line thickness
        }
      }
    };
  }, []);

  return <Chart ref={chartRef} type="line" data={chartData} options={options} className="w-full h-full" />;
};

export default CashFlowLineChart;
