import { useSelector } from 'react-redux';
import withAuthCondition from './withAuthCondition';
import { expiredPasswordStatus } from '../config/userStatuses';

const withRequirePasswordUpdate = (Component) => {
  return withAuthCondition(Component, () => {
    const { UserStatus } = useSelector((state) => state.user.details);
    return UserStatus === expiredPasswordStatus;
  });
};
export default withRequirePasswordUpdate;
