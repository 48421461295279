/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { Divider } from 'primereact/divider';
import Demographics from './Demographics';
import Address from './Address';
import Success from './Success';
import PatientFormCard from '../../../common/components/PatientFormCard';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';

import { integersOnlyCheckForString, validateCanadianZip } from '../../../regex/regex';
import { newPatientBlankRecord } from './helpers/newPatientBlankRecord';
import { setEligibilityError, setToastMessage } from '../../../core/actions/core.action.creators';
import { setFormikFocusToInvalidInput } from '../../../utils/setFormikFocusToInvalidInput';
import { t } from '../../../../service/localization/i18n';
import classes from './PatientForm.module.scss';
import moment from 'moment';
import cx from 'classnames';
import * as yup from 'yup';
import { forEach, isEmpty } from 'lodash';
import {
  createPatient,
  setShowNewPatientSuccessPage,
  setEligibility,
  duplicatePatientCheck,
  setPatientInfo,
  setActiveTabIndexForPatientForm,
  editPatientAction,
  getPatientInfo,
  getPatient
} from '../../actions/patients.action.creators';
import { routes } from '../../../../routes/routes';
import DuplicatePHNDialog from '../commonElements/DuplicatePHNDialog';
import DuplicatePatientsDialog from '../commonElements/DuplicatePatientsDialog';
import { formatPatientInfoForClaims } from '../../helpers/formatPatientInfoForClaims';
import { commonInputs } from '../../../config/commonInputsConfig';
import {
  getCodesByType,
  getRecentCodesList,
  setPatientPickerQueryValue,
  updatePrivateRecord,
  updateTeleplanInvoice,
  updateWSBCRecord
} from '../../../claims/actions/claims.action.creators';
import { defaultCountryCode, phoneNumberLength } from '../../../config/defaultValuesConfig';
import { addZerosToPhoneNumber } from '../../../utils/addZerosToPhoneNumber';
import { initialAddressType, initialEmailType, initialPhoneType } from './helpers/initialValuesForPatientForm';
import { onBillToUpdate } from '../../../claims/components/PrivateDetails/claimDetailsHelpers/onClaimUpdate';
import { elementIDs } from '../../../config/elementIDsConfig';
import { inputs as wsbcInputs } from '../../../claims/components/WSBCReport/helpers/inputs';
import { inputs as teleplanInputs } from '../../../claims/components/TeleplanInvoice/helpers/inputs';
import { useURLQuery } from '../../../hooks/useURLQuery';
import { patientFullNameWithAge } from '../../../utils/patientFullName';
import { Tooltip } from 'primereact/tooltip';
import { handleAutofill } from './AutoFillHandler';
import useAutofill from './useAutofill';
import { Dialog } from 'primereact/dialog';
import { v4 as randomUUID } from 'uuid';
import { setErrorMessageAutofill } from '../../actions/patients.action.creators';
import { linkSessionGuid } from '../../actions/patients.action.creators';
import { logStatusToAPI } from '../../actions/patients.action.creators';
import { Cropper } from 'react-cropper';
import { Card } from 'primereact/card';
import { ButtonWithBadge } from '../../../../components/Buttons';
import 'cropperjs/dist/cropper.css';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AddNewReferralDialog from './AddNewReferralDialog';
import { InputText } from 'primereact/inputtext';
import GlobalSearch from '../../../dashboard/components/GlobalSearch';
import { getDefaultValues } from '../../../claims/components/TeleplanInvoice/helpers/defaultValues';
import { getGroupTemplateDefaultValues } from '../../../claims/components/TeleplanInvoice/helpers/groupTemplateDefaultValues';
import { createGroupInvoicesTemplate, editGroupInvoicesTemplate } from '../../../claims/actions/claims.action.creators';
import { getInitPractitionerGuid } from '../../../utils/getPractitioner';
import { patientLastNameWithInitials } from '../../../utils/patientFullName';
import { FileUpload } from 'primereact/fileupload';
import heic2any from 'heic2any';
import { importPatientsCSV } from '../../actions/patients.action.creators';
import { validatePHN } from '../../actions/patients.action.creators';

const PatientForm = ({ showInCard = true, showPrompt = true, isEdit, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = useURLQuery();
  const { id } = useParams();
  const { DCNGuid } = useSelector((state) => state.user.details);
  const { DoctorGuid } = useSelector((state) => state.user.details);
  const { teleplanInvoice, wsbcRecord, patientPickerQueryValue } = useSelector((state) => state.claims);
  const { isMobile, isMobileOnly, iPadInPortrait, isLandscape } = useSelector((state) => state.core.window);
  const {
    patient_details,
    isFetchingPatients,
    eligibility,
    isCreatingPatient,
    isSavingPatientDetails,
    showNewPatientSuccessPage,
    checkingPatientDuplicates,
    patientFormActiveTabIndex,
    isFetchingPHNValidation
  } = useSelector((state) => state.patients);
  const previousEligbility = useSelector((state) => state.patients?.patient_details?.Eligibility?.Parsed);
  const currentPractitionerPrefs = useSelector((state) => state.preferences.currentUserPrefs.prefs);
  const defaultPreference = currentPractitionerPrefs?.find((pref) => pref.label === 'default');
  const practitionerGuid = getInitPractitionerGuid();
  const today = moment(moment().toDate()).format('YYYY/MM/DD');

  const selectPreviousImage = (state) => {
    const labelImages = state?.patients?.patient_details?.LabelImages;

    // Ensure labelImages is an array before proceeding
    if (labelImages && labelImages.length > 0) {
      return labelImages[0]?.FileUrl;
    }

    // Return null if LabelImages is undefined, not an array, or empty
    return null;
  };
  const previousImage = useSelector(selectPreviousImage);

  const [eligibilityFromEdit, setEligibilityFromEdit] = useState(null);
  const nameFromLabelAfterEdit = useLocation().state?.nameFromPicture;
  const preferredNameAfterEdit = useLocation().state?.preferredName;
  const selectedGroupForEdit = useLocation().state?.selectedGroup;

  const [imageIndex, setImageIndex] = useState(0); // Tracks the currently processed image

  const autofillErrors = useSelector((state) => state?.patients?.autofillError || []);

  const errorAutofillMessage = autofillErrors[imageIndex]?.message || '';
  const errorAutofillType = autofillErrors[imageIndex]?.severity || '';
  const originalMSPErrorMessage = autofillErrors[imageIndex]?.originalMSPErrorMessage || '';
  const errorToastMessage = useSelector((state) => state?.core?.toastMessage?.message);

  const [isImageDialogVisible, setIsImageDialogVisible] = useState(false); // State for image dialog
  const fromSuccessPage = location.state?.fromSuccessPage;

  const phnRef = useRef(null);
  const fileUploadRef = useRef(null); // Create a ref
  const CSVUploadRef = useRef(null); // Create a ref
  const autofillButtonRef = useRef(null);
  const [step, setStep] = useState(0);
  const [isPHNValid, setIsPHNValid] = useState(true);
  const [isDateValid, setIsDateValid] = useState(true);
  const [isPHNDuplicate, setIsPHNDuplicate] = useState(false);
  const [showForm, setShowForm] = useState(!location.state?.activateAutofill);
  const [showPatientDuplicateDialog, setShowPatientDuplicateDialogAction] = useState(false);
  const [showPHNDuplicateDialog, setShowPHNDuplicateDialogAction] = useState(false);
  const [displayPrompt, setDisplayPrompt] = useState(true);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [isPHNFocused, setIsPHNFocused] = useState(false);
  const [duplicatedPatients, setDuplicatedPatients] = useState([]);
  const [patientDetails, setPatientDetails] = useState(null);
  const [extractedText, setExtractedText] = useState('');
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [intialImageForCropper, setIntialImageForCropper] = useState(null);
  const [showCropper, setShowCropper] = useState(false); // Toggle for cropping tool
  const [showCropperCard, setShowCropperCard] = useState(true);
  const [showCroppedImages, setShowCroppedImages] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removeFocusPhn, setRemoveFocusPhn] = useState(false);
  const [abortController, setAbortController] = useState(null);
  const [disableAbortButoon, setDisableAbortButoon] = useState(false);
  const [imageTimer, setImageTimer] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [imageToAutofill, setImageToAutofill] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [nameFromEligibility, setNameFromEligibility] = useState('');
  const [nameFromPicture, setNameFromPicture] = useState('');
  const [preferredName, setPreferredName] = useState(null);
  const [autofillTriggered, setAutofillTriggered] = useState(false);
  const [defaultReferralList, setDefaultReferralList] = useState([]);
  const [showNewPractitionerDialog, setShowNewPractitionerDialog] = useState(false);
  const [suggestedNames, setSuggestedNames] = useState([]);
  // const [suggestedNamesVisible, setSuggestedNamesVisible] = useState(false);
  const { isFetchingPatientEligibility } = useSelector((state) => state.patients);
  const isNew =
    (location?.pathname?.includes(routes.createNewborn.path) || location?.pathname?.includes(routes.createPatient.path)) &&
    location?.pathname?.includes('new');
  const isNewborn = location?.pathname?.includes(routes.createNewborn.path) || location?.pathname?.includes(routes.editNewborn.path);
  const [croppedImages, setCroppedImages] = useState([]); // Store all cropped images
  const [imageStatuses, setImageStatuses] = useState([]); // Tracks the status of each image
  const [createdPatients, setCreatedPatients] = useState([]);
  const [processingAllPressed, setProcessingAllPressed] = useState(false); // New state to track Process-All
  const DataCenterNumber = useSelector((state) => state?.clinic?.details?.DataCenterNumber);
  const [selectedPatients, setSelectedPatients] = useState([]); // Store selected patients
  const [showGroup, setShowGroup] = useState(location.state?.activateGroup); // State to show group button
  const [groupName, setGroupName] = useState(selectedGroupForEdit?.Name || today); // Store group names
  const [addToGroupUsed, setAddToGroupUsed] = useState(location.state?.activateGroup || null);
  const [description, setDescription] = useState(selectedGroupForEdit?.Description || '');
  const [groupNameError, setGroupNameError] = useState(''); // Track validation errors
  const [file, setFile] = useState(null); // Store the selected file
  const [uploadFileFromSuccessPage, setUploadFileFromSuccessPage] = useState(false);
  const globalSearchRef = useRef(null); // Create a ref
  const [CSVFile, setCSVFile] = useState(null);
  const [messages, setMessages] = useState([]); // State to store messages

  const addNewPatientToNewClaim =
    props.showInDialog ||
    !showNewPatientSuccessPage.showSuccessPage ||
    (showNewPatientSuccessPage.showSuccessPage && showNewPatientSuccessPage.from === 'teleplanClaim');
  const disabledPHN = !isNew && patientDetails?.PHNProvince === 'BC' && patientDetails?.TotalRecords > 0;
  // const [showQRCodeDialog, setShowQRCodeDialog] = useState(false);
  // const qrCodeURL = encodeURI(`${window.location.origin}/#${routes.upload.path}`);
  const [autoFillUsedLast, setAutoFillUsedLast] = useState(false);
  const [sessionGuid, setSessionGuid] = useState(null);

  let newSessionGuid = null;
  const showLabelreader = isNew;

  const fetchReferralsList = async () => {
    const results = await getRecentCodesList();
    if (results?.referral?.length) {
      setDefaultReferralList(results.referral);
    }
  };

  useEffect(() => {
    fetchReferralsList();
  }, []);

  const handleCropSave = () => {
    setShowCroppedImages(true);
    const cropper = cropperRef.current?.cropper; // Access the Cropper instance
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      if (croppedCanvas) {
        const croppedImageDataUrl = croppedCanvas.toDataURL(); // Convert cropped image to data URL
        setCroppedImage(croppedImageDataUrl); // Save cropped image to state
      }
    }
    // setAutoFillUsedLast(true);
    setTimeout(() => {
      setShowCropper(false); // Open the cropper if needed
    }, 50);
  };

  const handleOpenCropper = () => {
    setShowCropper(true); // Open the cropper
  };

  // Function to cancel cropping
  const handleCropCancel = () => {
    setShowCropper(false); // Return to the form without saving
    setShowForm(true);
  };

  const cropperRef = useRef(null);

  const handleFileChange = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*, image/heif, image/heic'; // Specify acceptable file types
    input.style.display = 'none'; // Keep it hidden

    // Append the input to the body and simulate a click to open the file dialog
    document.body.appendChild(input);
    input.click();

    input.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          // Set the image state with the file data
          setIntialImageForCropper(reader.result);
          setImageStatuses([]);
        };
        reader.readAsDataURL(file);
      }
      // Remove the input element after use to keep the DOM clean
      document.body.removeChild(input);
    };
  };

  const fetchPatient = async () => {
    try {
      setLoading(true);
      const patient = await getPatient(id);
      dispatch(setPatientInfo(patient));
    } catch (error) {
      console.error('!!!Error fetching patient:', error);
    } finally {
      setLoading(false);
    }
  };

  // const [isRotated, setIsRotated] = useState(false); // State to track if the image is rotated
  // const [rotatedImage, setRotatedImage] = useState(null); // Store the rotated version of the image

  // const rotateImage = () => {
  //   if (!rotatedImage) {
  //     // Create a rotated version of the image only once
  //     const canvas = document.createElement('canvas');
  //     const ctx = canvas.getContext('2d');
  //     const img = new Image();
  //     img.src = image;

  //     img.onload = () => {
  //       // Swap canvas dimensions for rotation
  //       canvas.width = img.height;
  //       canvas.height = img.width;

  //       // Rotate the canvas and draw the image
  //       ctx.translate(canvas.width / 2, canvas.height / 2);
  //       ctx.rotate((90 * Math.PI) / 180); // Rotate 90 degrees
  //       ctx.drawImage(img, -img.width / 2, -img.height / 2);

  //       // Save the rotated image as a data URL
  //       setRotatedImage(canvas.toDataURL());
  //       setIsRotated(true); // Update state to show rotated image
  //     };
  //   } else {
  //     // Swap between the original and rotated image
  //     setIsRotated(!isRotated);
  //   }
  // };

  // Get patient info
  useEffect(() => {
    id && fetchPatient();

    if (selectedGroupForEdit) {
      forEach(selectedGroupForEdit.FirstStep.PatientDetails, (patient) => {
        setSelectedPatients((prevPatients) => [...prevPatients, patient]);
      });
    }
  }, [selectedGroupForEdit]);

  useEffect(() => {
    if (!fromSuccessPage) {
      dispatch(setErrorMessageAutofill({ index: null, type: null, message: null, originalMSPErrorMessage: null, formData: { ...formik.values } }));
      dispatch(setEligibilityError({ error: null }));
    }
  }, []);

  const dataForSubmit = (data) => {
    const mrp = data?.MRP?.length ? data?.MRP[0] : null;
    const result = {
      ...data,
      FirstName: data.FirstName?.trim()?.toUpperCase(),
      LastName: data.LastName?.trim()?.toUpperCase(),
      MidName: data.MidName?.trim()?.toUpperCase(),
      MRPNumber: mrp?.value || '',
      MRPName: mrp?.name || '',
      DateDeceased: data?.DateDeceased ? moment(data.DateDeceased).format('YYYY-MM-DD') : null
    };

    delete result.MRP; // Remove the MRP property

    return result;
  };

  const onSubmit = (formData, checkDuplicate = true) => {
    const data = dataForSubmit(formData);
    // Check if the PHNProvince property of the data object is "Institutional"
    const isInstitutional = data?.PHNProvince === 'Institutional';
    data.AccessWord = data?.AccessWord?.toLowerCase() || ''; // BE requrement convert AccessWord to lowercase

    // If the PHNProvince is not "Institutional"
    if (!isInstitutional) {
      // Remove leading zeros from the PHN property of the data object
      // VER-440
      data.PHN = data.PHN?.replace(/^0+/, '');
    }

    // Create new patient
    if (isNew && !isEdit) {
      if (!checkingPatientDuplicates) {
        // [KS] CMO-640 - Validate BC PHN number on the New patient profile screen
        // [KS] CMO-641 - Show error message if the DOB is invalid on New Patient screen
        if ((!isPHNValid && data?.PHN?.length) || !isDateValid) {
          dispatch(setToastMessage({ type: 'warn', message: t('Please_correct_invalid_entries') }));
        } else {
          if (!data?.PHN?.length && checkDuplicate) {
            checkPatientDuplicate({ callback: () => createNewPatient(data) });
          } else {
            createNewPatient(data);
          }
        }
      }
    }

    // Edit patient
    if (!isNew || isEdit) {
      if (!checkingPatientDuplicates) {
        const FullName = data?.MidName
          ? `${data?.LastName?.toUpperCase()}, ${data?.FirstName?.toUpperCase()} ${data?.MidName?.toUpperCase()}`
          : `${data?.LastName?.toUpperCase()}, ${data?.FirstName?.toUpperCase()}`;

        const newData = {
          ...data,
          FirstName: data.FirstName?.toUpperCase(),
          LastName: data.LastName?.toUpperCase(),
          MidName: data.MidName?.toUpperCase(),
          PHN: data.PHN?.replace(/_/g, ''),
          FullName
        };

        // delete newData.EligibilityInfo;

        // [KS] CMO-640 - Validate BC PHN number on the New patient profile screen
        // [KS] CMO-641 - Show error message if the DOB is invalid on New Patient screen
        if ((!isPHNValid && data?.PHN?.length) || !isDateValid) {
          dispatch(setToastMessage({ type: 'warn', message: t('Please_correct_invalid_entries') }));
        } else {
          if (!newData?.PHN?.length && checkDuplicate) {
            console.log('New data:', newData);
            checkPatientDuplicate({
              callback: () =>
                dispatch(
                  editPatientAction(newData, (formattedData) => {
                    setEligibilityFromEdit(formattedData?.Eligibility?.Parsed);
                    formik.resetForm();
                    dispatch(setToastMessage({ type: 'success', message: t('Your_changes_are_saved') }));
                    if (props.showInDialog) {
                      props.onEdit(newData);
                    } else {
                      if (!fromSuccessPage) {
                        navTo();
                      } else {
                        setNameFromEligibility('');
                        setNameFromPicture('');
                        setPreferredName(null);
                        // dispatch(setEligibilityError({error: null}));
                        // dispatch(setErrorMessageAutofill({ type: null, message: null, originalMSPErrorMessage: null }));
                        setPatientDetails(newData); // Update local state
                        formik.resetForm({ values: newData }); // Update form values
                        if (!addToGroupUsed) {
                          setStep(1);
                        } else {
                          setShowGroup(true);
                        }
                      }
                    }
                  })
                )
            });
          } else {
            dispatch(
              editPatientAction(newData, (formattedData) => {
                dispatch(setToastMessage({ type: 'success', message: t('Your_changes_are_saved') }));
                setEligibilityFromEdit(formattedData?.Eligibility?.Parsed);

                formik.resetForm();
                if (props.showInDialog) {
                  props.onEdit(newData);
                } else {
                  if (!fromSuccessPage) {
                    navTo();
                  } else {
                    setNameFromEligibility('');
                    setNameFromPicture('');
                    setPreferredName(null);
                    // dispatch(setEligibilityError({error: null}));
                    // dispatch(setErrorMessageAutofill({ type: null, message: null, originalMSPErrorMessage: null }));
                    setPatientDetails(newData); // Update local state
                    formik.resetForm({ values: newData }); // Update form values
                    if (!addToGroupUsed) {
                      setStep(1);
                    } else {
                      setShowGroup(true);
                    }
                  }
                }
              })
            );
          }
        }
      }
    }
  };

  const createNewPatient = (data) => {
    dispatch(
      createPatient(data, (responseData) => {
        handleAddPatient(responseData);
        setShowImage(false);

        // console.log('SETTING IMAGE STATUS TO SUCCESS');
        // setImageStatuses((prevStatuses) => {
        //   if (prevStatuses[imageIndex]?.status === 'success') {
        //     console.log('Status already success, skipping update.');
        //     return prevStatuses;
        //   }
        //   return prevStatuses.map((status, idx) =>
        //     idx === imageIndex
        //       ? { ...status, status: 'success', formData: { ...formik.values } }
        //       : status
        //   );
        // });
        if (props.showInDialog) return props.onCreate(responseData);
        if (
          (showNewPatientSuccessPage.showSuccessPage && showNewPatientSuccessPage.from === 'teleplanClaim' && !isEmpty(eligibility)) ||
          (showNewPatientSuccessPage.showSuccessPage && !showNewPatientSuccessPage.from)
        ) {
          //show success page

          linkSessionGuid(DCNGuid, responseData.PatientGuid, sessionGuid, responseData.PatientID);

          setCreatedPatients((prevPatients) => [...prevPatients, responseData]);

          dispatch(setPatientInfo(responseData));
          setPatientDetails(responseData);
          if (!showCroppedImages && !addToGroupUsed) {
            setStep(1);
          } else if (addToGroupUsed) {
            setShowGroup(true);
          }
        } else {
          //do not show success page
          setDisplayPrompt(false);
          addNewPatientForNewClaim(responseData); // Set new patient to private claim form
          dispatch(setShowNewPatientSuccessPage({ showSuccessPage: true, from: '' }));
          dispatch(setToastMessage({ type: 'success', message: t('Patient_created_successfully') }));
          navTo();
        }

        formik.resetForm();
        showPatientDuplicateDialog && setShowPatientDuplicateDialog(false);
      })
    );
  };

  const setShowPHNDuplicateDialog = (showPHNDialog) => {
    setShowPHNDuplicateDialogAction(showPHNDialog);
    setDisplayPrompt(!showPHNDialog);
  };

  const setShowPatientDuplicateDialog = (showDuplicateDialog) => {
    setShowPatientDuplicateDialogAction(showDuplicateDialog);
    setDisplayPrompt(!showDuplicateDialog);
  };

  const handleExistingProfile = (patient) => {
    if (props.showInDialog) return props.onCreate(patient);
    const navToPatientProfile = () => {
      dispatch(setPatientInfo(patient));
      history.replace(`${routes.patientDetails.path}/${patient.PatientGuid}`);
    };

    if (!isNew) return navToPatientProfile(); // redirect to patient profile

    if (isNew) {
      if (!addNewPatientToNewClaim) return navToPatientProfile(); // redirect to patient profile

      // redirect to create new claim form
      if (addNewPatientToNewClaim) {
        const redirectToCreateClaimForm = (responseData) => {
          addNewPatientForNewClaim(responseData);
          navTo();
        };
        // if patient is archived
        if (patient.Status === -1) {
          dispatch(editPatientAction({ ...patient, Status: 0 }, (responseData) => redirectToCreateClaimForm(responseData)));
        } else {
          // if patient is NOT archived
          redirectToCreateClaimForm(patient);
        }
      }
    }
  };

  const getInitialValuesForEdit = async () => {
    let MRP = [];
    if (patient_details.MRPNumber) {
      const result = await getCodesByType({ codeType: commonInputs.referral.codeType, query: patient_details.MRPNumber });
      MRP = result;
    }

    const PHNInit = patient_details.PHN?.length > 0 && patient_details.PHN !== 'N/A' ? patient_details.PHN : '';
    const phoneInit =
      patient_details?.Phone?.length === phoneNumberLength
        ? patient_details.Phone
        : patient_details?.Phone?.length > 0 && patient_details?.Phone?.length < phoneNumberLength
        ? addZerosToPhoneNumber(patient_details?.Phone)
        : patient_details?.Phone;
    const phoneTypeInit = patient_details.PhoneType ? patient_details.PhoneType : initialPhoneType;
    const emailTypeInit = patient_details.EmailType ? patient_details.EmailType : initialEmailType;
    const addressTypeInit = patient_details.AddressType ? patient_details.AddressType : initialAddressType;

    const initValues = {
      ...patient_details,
      Country: patient_details?.Country || defaultCountryCode,
      PHN: PHNInit,
      BirthDay: moment(patient_details?.BirthDay).format('MM/DD/YYYY') || '', // [KS] CMO-1287 - Edit patient - demographicsFormLayout - incorrect display of date of birth
      NewbornBD: patient_details.NewbornBD ? moment(patient_details?.NewbornBD).format('MM/DD/YYYY') : null,
      Phone: phoneInit,
      PhoneType: phoneTypeInit,
      EmailType: emailTypeInit,
      AddressType: addressTypeInit,
      Zip: patient_details?.Zip || '',
      MRP,
      DateDeceased: patient_details.DateDeceased ? moment(patient_details.DateDeceased).toDate() : null
    };

    return initValues;
  };

  const formikInitValues = async () => {
    // New patient
    if (isNew) return newPatientBlankRecord(DCNGuid, patientPickerQueryValue);

    // Edit patient
    if (!isNew || isEdit) {
      const initData = await getInitialValuesForEdit();
      setPatientDetails(initData);
      return initData;
    }
  };

  const validationSchema = yup.object().shape({
    BirthDay: yup
      .date()
      .min('01/01/1900', `${t('Year_minimum_value_is')} 1900`)
      .max(new Date(), `${t('Year_maximum_value_is')} ${moment().year()}`)
      .required(t('Mandatory_field.1'))
      .nullable(),
    Sex: yup.string().required(t('Mandatory_field.1')),
    LastName: yup.string().required(t('Mandatory_field.1')),
    FirstName: yup.string().required(t('Mandatory_field.1')),
    Country: yup.string().required(t('Mandatory_field.1')),
    Email: yup.string().email(t('Invalid_entry')).nullable(),
    Zip: yup.string().test('zip-validation', t('Invalid_entry'), function (zip) {
      const province = this.parent.Province;
      const country = this.parent.Country;
      if (!zip || !province || province === 'N/A' || country !== defaultCountryCode) {
        return true; // No validation required
      }
      return validateCanadianZip.test(zip); // `true` = valid, `false` = invalid
    }),
    NewbornBD: yup
      .date()
      .nullable()
      .when('IsNewborn', {
        is: true,
        then: yup
          .date()
          .min('01/01/1900', `${t('Year_minimum_value_is')} 1900`)
          .max(new Date(), `${t('Year_maximum_value_is')} ${moment().year()}`)
          .required(t('Mandatory_field.1'))
          .nullable(),
        otherwise: yup.date().nullable()
      }),
    IsNewborn: yup.boolean()
  });

  const formik = useFormik({
    enableReinitialize: true,
    // validateOnChange: false,
    // validateOnBlur: true,
    initialValues: isNew && newPatientBlankRecord(DCNGuid, patientPickerQueryValue),
    validationSchema,
    onSubmit
  });

  // Formik initial values
  useEffect(() => {
    const fetchData = async () => {
      const initialValues = await formikInitValues();
      formik.resetForm({ values: initialValues });
    };

    fetchData();
  }, []);

  // Set initial PHN or LastName value from patientPickerQueryValue
  useEffect(() => {
    if (patientPickerQueryValue) {
      const isPHNNumbersOnly = integersOnlyCheckForString.test(patientPickerQueryValue);
      if (isPHNNumbersOnly) {
        formik.setFieldValue('PHN', patientPickerQueryValue);
      } else formik.setFieldValue('LastName', patientPickerQueryValue?.toUpperCase());
    }
  }, [patientPickerQueryValue]);

  //initial provinces list for create new patient
  useEffect(() => {
    //Reset eligibility message
    isMobile && window[`scrollTo`]({ top: 0 });
    if (isNew) dispatch(setEligibility({}));
  }, []);

  // scroll to first invalid field
  useEffect(() => {
    if (!autoFillUsedLast) {
      setFormikFocusToInvalidInput(formik);
    }
  }, [formik]);

  //component unmount
  useEffect(() => {
    return () => {
      //Reset eligibility message
      dispatch(setEligibility({}));
      dispatch(setActiveTabIndexForPatientForm(0));
      dispatch(setShowNewPatientSuccessPage({ showSuccessPage: true, from: '' }));
      patientPickerQueryValue && dispatch(setPatientPickerQueryValue('')); // CMO-1365 - New Teleplan claim->Patient field->type in PHN->No matches->Carry PHN from Patient field into PHN field on the Add patient screen
    };
  }, []);

  const addNewPatientForNewClaim = (patient) => {
    const formatted = formatPatientInfoForClaims(patient);

    if (!isEmpty(patient)) {
      switch (showNewPatientSuccessPage?.from) {
        case 'teleplanClaim':
          dispatch(
            updateTeleplanInvoice({
              ...teleplanInvoice,
              formState: {
                ...teleplanInvoice.formState,
                [teleplanInputs.patient.name]: [...teleplanInvoice.formState[teleplanInputs.patient.name], formatted]
              }
            })
          );
          break;

        case 'privateClaim':
          dispatch(updatePrivateRecord({ [commonInputs.patients.descriptionName]: [formatted] }));
          onBillToUpdate([formatted]); // Add Bill To value
          break;

        // case 'editGroup':
        //   dispatch(
        //     updateTeleplanInvoice({
        //       ...teleplanInvoice,
        //       formState: {
        //         ...teleplanInvoice.formState,
        //         [teleplanInputs.patient.name]: [...teleplanInvoice.formState[teleplanInputs.patient.name], formatted]
        //       }
        //     })
        //   );
        //   break;

        case 'WSBCReport':
          dispatch(
            updateWSBCRecord({
              ...wsbcRecord,
              resetWSBCR: true, // CMO-2317 - Cach is not cleaned on new Teleplan when the new patient is created from that screen
              formState: { ...wsbcRecord.formState, [wsbcInputs.patient.name]: [formatted] }
            })
          );
          break;

        default:
          break;
      }
    }
  };

  const checkPatientDuplicate = ({ onPHNChange = false, callback, patientData }) => {
    let partialPatientDetails = {
      DCNGuid: formik.values.DCNGuid,
      PHN: patientData?.PHN || formik.values.PHN,
      PHNProvince: patientData?.PHNProvince || formik.values.PHNProvince,
      FirstName: formik.values.FirstName,
      MidName: formik.values.MidName,
      LastName: formik.values.LastName,
      FullName: formik.values.FullName,
      Sex: formik.values.Sex,
      DOB: formik.values.BirthDay
    };

    if (!isNew) partialPatientDetails = { ...partialPatientDetails, PatientGuid: formik.values.PatientGuid };

    dispatch(
      duplicatePatientCheck(partialPatientDetails, (responseData) => {
        if (responseData?.length) {
          setDuplicatedPatients(responseData);
          console.log('Duplicated patients:', responseData);
          if (addToGroupUsed) {
            handleAddPatient(responseData[0]);
            setShowImage(false);
            setShowGroup(true);
          }
          if (onPHNChange) {
            // on PHN update
            setShowPHNDuplicateDialog(true);
            setDisableSaveButton(true); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
            setIsPHNDuplicate(true); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
          } else {
            // on "Create"/"Save" click
            if (!autofillTriggered) {
              setShowPatientDuplicateDialog(true);
            }
          }
          callback(responseData);
        } else {
          setDisableSaveButton(false); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
          setIsPHNDuplicate(false); // [KS] CMO-1406 - Create new patient - error 500 (check comments)
          if (!onPHNChange) {
            // on "Create"/"Save" click
            // if no duplicate
            callback();
          }
        }
      })
    );
  };

  const onExit = () => {
    if (props.onClose) return props.onClose();
    formik.resetForm();
    navTo();
  };

  const promptMessage = () => {
    return JSON.stringify({ isDirty: formik.dirty || addToGroupUsed });
  };

  const onHideDuplicatePatientsDialog = () => {
    setShowPatientDuplicateDialog(false);
    setDuplicatedPatients([]);
  };

  const onHideDuplicatePHNDialog = () => {
    setShowPHNDuplicateDialog(false);
    setIsPHNDuplicate(false);
    if (!removeFocusPhn) {
      phnRef?.current?.focus();
    }
    setRemoveFocusPhn(false);
  };

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small id={`${elementIDs.mandatoryField}_${name}`} className="p-error">
          {formik.errors[name]}
        </small>
      )
    );
  };

  const onClose = () => {
    if (fromSuccessPage) {
      setIsPHNValid(true);
      setStep(1);
    } else if (addToGroupUsed) {
      setShowGroup(true);
    } else {
      if (props.onClose) return props.onClose();
      return navTo();
    }
  };

  const navTo = () => {
    query.get('replaceTo') ? history.replace(query.get('replaceTo')) : history.goBack();
  };

  const demographicsFormLayout = (
    <Demographics
      isNew={isNew}
      isNewborn={isNewborn}
      phnRef={phnRef}
      formik={formik}
      isDateValid={isDateValid}
      isPHNValid={isPHNValid}
      isPHNDuplicate={isPHNDuplicate}
      disabledPHN={disabledPHN}
      setIsPHNDuplicate={setIsPHNDuplicate}
      setShowPHNDuplicateDialog={setShowPHNDuplicateDialog}
      setIsDateValid={setIsDateValid}
      setIsPHNValid={setIsPHNValid}
      setIsPHNFocused={setIsPHNFocused}
      isFormFieldValid={isFormFieldValid}
      getFormErrorMessage={getFormErrorMessage}
      checkPatientDuplicate={checkPatientDuplicate}
      setShowNewPractitionerDialog={setShowNewPractitionerDialog}
      defaultReferralList={defaultReferralList}
      autofillTriggered={autofillTriggered}
    />
  );

  const addressFormLayout = <Address formik={formik} isFormFieldValid={isFormFieldValid} getFormErrorMessage={getFormErrorMessage} />;

  // Action buttons
  const actionButtons = (
    <div
      className={cx('flex col-12 align-items-end px-0 py-3 stickyActionButtons', isMobile ? '' : 'mt-auto mb-0')}
      style={{ marginBottom: isMobile ? '' : '16px' }}
    >
      <div className={cx('field flex col-12 p-0')}>
        {formik.dirty ? (
          <>
            <Button
              form="patient-form"
              id={isNew ? elementIDs.createNewPatient : elementIDs.saveChanges}
              className="mr-2"
              type="submit"
              loading={isCreatingPatient || isSavingPatientDetails}
              disabled={
                isPHNFocused || // VER-617 - [OB] I suggest that we show that Save & Close is disabled as long as the cursor is in PHN
                isFetchingPHNValidation ||
                checkingPatientDuplicates ||
                !isDateValid ||
                (!isNew && disableSaveButton && formik.values.PHN?.length > 0)
              }
              label={isNew ? t('Create_Record') : t('Save_and_Close')}
              // onClick={() => setAutoFillUsedLast(false)}
              // onClick={() => onSubmit(formik.values)}
            />
            <Button
              id={!formik.dirty && (iPadInPortrait || isMobileOnly) ? elementIDs.close : elementIDs.cancel}
              className="p-button-outlined ml-2"
              type="button"
              label={!formik.dirty && (iPadInPortrait || isMobileOnly) ? t('Close') : t('Cancel')}
              onClick={onClose}
            />
          </>
        ) : (
          <Button className="p-button-outlined" id={elementIDs.close} type="button" label={t('Close')} onClick={onClose} />
        )}
      </div>
    </div>
  );

  const patientsName = () => {
    return patientFullNameWithAge(patient_details);
  };

  //--------------------------------- Autofill ---------------------------------//

  useEffect(() => {
    if (location.state?.activateAutofill) {
      // autofillButtonRef.current?.click();
      setShowForm(false);
      fileUploadRef.current?.getInput().click(); // Open file picker
    }
  }, [location.state]);

  useEffect(() => {
    if (uploadFileFromSuccessPage) {
      fileUploadRef.current?.getInput().click(); // Open file picker
      setUploadFileFromSuccessPage(false);
    }
  }, [uploadFileFromSuccessPage]);

  // const isFetchingPrevious = useRef(isFetchingPatientEligibility);

  // useEffect(() => {
  //   // Check if isFetchingPatientEligibility changed from true to false
  //   if (isFetchingPrevious.current && !isFetchingPatientEligibility && loading) {
  //     setLoading(false);
  //   }
  //   // Update the ref to the current value
  //   isFetchingPrevious.current = isFetchingPatientEligibility;
  // }, [isFetchingPatientEligibility, loading]);

  // const loadingPrevious = useRef(loading);

  // useEffect(() => {
  //   // Detect when loading changes from true to false
  //   if (loadingPrevious.current && !loading) {
  //     setTimeout(() => {
  //       setShowForm(true);
  //     }, 300);
  //   }
  //   // Update ref to the current loading state
  //   loadingPrevious.current = loading;
  // }, [loading]);

  useEffect(() => {
    if (autofillTriggered) {
      setTimeout(() => {
        formik.setFieldTouched('PHN', true);
        formik.setFieldValue('PHN', formik.values.PHN); // Re-set the value explicitly
      }, 50);
    }
  }, [autofillTriggered, formik]);

  useEffect(() => {
    if (errorAutofillMessage && (errorAutofillType === 'error' || errorAutofillType === 'warn' || errorAutofillType === 'info')) {
      setErrorMessage(errorAutofillMessage);

      //   const targetIndex = errorAutofillMessage?.index;

      //   console.log('Target Index', targetIndex)

      //   setImageStatuses((prevStatuses) => {
      //     console.log('Previous imageStatuses:', prevStatuses);

      //     const updatedStatuses = prevStatuses.map((status, idx) => {
      //       const isWithinRange = idx <= targetIndex; // Use index from the error message
      //       const shouldSetToFailed =
      //         isWithinRange &&
      //         errorAutofillMessage.message !== 'First name or last name not found in the image. Please verify the autofill data';

      //       console.log(`Updating index ${idx}: isWithinRange =`, isWithinRange, 'shouldSetToFailed =', shouldSetToFailed);

      //       return shouldSetToFailed
      //         ? { ...status, status: 'failed', errorMessage: errorAutofillMessage.message }
      //         : status;
      //     });

      //     console.log('Updated imageStatuses:', updatedStatuses);

      //     return updatedStatuses; // Return the updated statuses
      //   });
    }

    if (errorAutofillMessage === '') {
      setErrorMessage(null);
    }
  }, [errorAutofillMessage]);

  useEffect(() => {
    if (autofillErrors[imageIndex]) {
      const error = autofillErrors[imageIndex];
      console.log('Processing error for imageIndex:', imageIndex, 'Error:', error);

      // Retrieve the current status of the image at imageIndex
      const currentStatus = imageStatuses[imageIndex]?.status;

      // Skip updating if the current status is already "success"
      console.log('Current status:', currentStatus);
      if (currentStatus !== 'success') {
        setTimeout(() => {
          setImageStatuses((prevStatuses) =>
            prevStatuses.map((status, idx) =>
              idx === imageIndex && status.status !== 'success' && status.status !== 'duplicate' && status.status !== 'failed'
                ? {
                    ...status,
                    status:
                      error.message === 'First name or last name not found in the image. Please verify the autofill data.'
                        ? 'success'
                        : error.message === 'PHN already exists in the system.'
                        ? 'duplicate'
                        : error.severity === 'success'
                        ? 'success'
                        : 'failed',
                    formData: { ...formik.values },
                    errorMessage: error.message,
                    suggestedNames: suggestedNames
                  }
                : status
            )
          );
        }, 1000);
      }

      // Move to the next image if applicable
      if (imageIndex !== null && imageIndex < croppedImages.length) {
        setTimeout(() => {
          // Move to the next image and re-trigger autofill
          setImageIndex((prevIndex) => prevIndex + 1);
        }, 100); // Slight delay to ensure state updates are settled
      }
    }
  }, [autofillErrors]);

  useEffect(() => {
    const allImagesProcessed =
      imageStatuses.length > 0 && imageStatuses.every((status) => ['failed', 'duplicate', 'success'].includes(status.status));
    const location = 'fromPatientForm';

    if (allImagesProcessed) {
      logStatusToAPI(DCNGuid, imageStatuses, sessionGuid, location, DataCenterNumber);
    }
  }, [imageStatuses]);

  useEffect(() => {
    if (imageIndex !== null && imageIndex < croppedImages.length) {
      setTimeout(() => {
        onAutofillClick();
      }, 100); // Slight delay to ensure state updates are settled
    }
    //   }
    // }
  }, [imageIndex]);

  useEffect(() => {
    const allImagesProcessed = imageStatuses.length > 0 && imageStatuses.every((status) => status.status === 'success');

    if (allImagesProcessed) {
      if (!addToGroupUsed) {
        setStep(1);
      } else {
        setShowGroup(true);
      }
      setShowCropper(false); // Ensure the cropper is closed
      console.log('All images processed:', allImagesProcessed);
    }
  }, [imageStatuses]);

  useEffect(() => {
    if (showImage) {
      setShowForm(true);
    }
  }, [showImage]);

  // Function to start a new scanning session
  const startNewScanningSession = () => {
    newSessionGuid = randomUUID(); // Generate a new UUID
    setSessionGuid(newSessionGuid); // Update state with the new UUID
  };

  // useEffect(() => { //abort autofill after 10 seconds
  //   let timeoutId;

  //   if (imageTimer) {
  //     timeoutId = setTimeout(() => {
  //       if (loading) { // Check if still loading
  //         setShowImage(true);
  //         dispatch(setToastMessage({
  //           type: 'warn',
  //           message: 'Consider aborting and cropping if processing is too long.'
  //         }));
  //       }
  //       setImageTimer(false); // Reset timer state
  //     }, 10000);
  //   }

  //   // Clean up the timer on unmount or when `loading` changes
  //   return () => clearTimeout(timeoutId);
  // }, [imageTimer, loading]); // Trigger the effect when these states change

  const onAutofillClick = async () => {
    if (loading) {
      // If already loading, you might want to return or show a message
      return;
    }
    // if (imageIndex >= croppedImages.length) return;

    // const croppedImage = croppedImages[imageIndex];

    // Set status to 'processing'
    setImageStatuses((prevStatuses) => prevStatuses.map((status, idx) => (idx === imageIndex ? { ...status, status: 'processing' } : status)));

    setAutoFillUsedLast(true);
    startNewScanningSession();

    // Create a new AbortController to manage the abort functionality
    const controller = new AbortController();
    setAbortController(controller);
    console.log('AbortController created:', controller);

    try {
      // Call the handleAutofill function with the abort signal
      await handleAutofill(
        formik,
        setExtractedText,
        setImage,
        setShowImage,
        setIsPHNValid,
        file,
        croppedImage,
        setLoading,
        setDisableAbortButoon,
        setImageTimer,
        null,
        null,
        newSessionGuid,
        imageIndex,
        setImageStatuses
        // { signal: controller.signal }
      );
      setCroppedImage(null); // Reset the cropped image
      setFile(null);
      setAutofillTriggered(true);
    } catch (error) {
      setErrorMessage(error);
      dispatch(setErrorMessageAutofill({ index: imageIndex, severity: 'warn', message: error.message, formData: { ...formik.values } }));
      if (error.name === 'AbortError') {
        console.log('Autofill aborted');
      } else {
        console.error('Error during autofill:', error);
      }
    } finally {
      console.log('Resetting loading state and controller');
      setImageTimer(false); // Reset the timer state
      setLoading(false); // Reset loading state
      // setShowForm(true); // Show the form again
      setAbortController(null); // Clear the abort controller
      // if(image){
      //   await uploadImage(DCNGuid, image, sessionGuid); // Call the upload function
      // }
    }
  };

  const processAllImages = () => {
    setShowCroppedImages(true);
    setShowCropperCard(false);
    setProcessingAllPressed(true);
    onAutofillClick();
  };

  useAutofill(
    formik,
    setLoading,
    setShowImage,
    extractedText,
    autofillTriggered,
    setAutofillTriggered,
    checkPatientDuplicate,
    setNameFromEligibility,
    setNameFromPicture,
    setShowPHNDuplicateDialogAction,
    setRemoveFocusPhn,
    setSuggestedNames,
    imageIndex
  );

  const onUpload = async (event) => {
    const file = event.files[0];
    if (!file) return; // Ensure a file is selected

    // Check if the file is HEIC
    if (file) {
      // setLoading(true);
      setImageStatuses([]);
      const validImageTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/jpg', 'image/webp'];
      if (file.type === 'image/heif' || file.type === 'image/heic') {
        // Convert HEIF/HEIC to JPEG
        // setLoading(true);
        try {
          const converted = await heic2any({
            blob: file,
            toType: 'image/jpeg'
          });
          // reader = new FileReader();
          // reader.readAsDataURL(converted);
          setFile(converted);
        } catch (error) {
          setLoading(false);
          setShowImage(true);
          dispatch(
            setErrorMessageAutofill({ index: imageIndex, severity: 'error', message: 'Invalid file type. Please upload a valid image file.' })
          );
        }
      } else if (validImageTypes.includes(file.type)) {
        setFile(file);
        // const reader = new FileReader();
        // reader.readAsDataURL(file);
        // reader.onload = () =>
        //   setCroppedImage(reader.result);
        //   fileUploadRef.current.clear();
      } else {
        dispatch(setErrorMessageAutofill({ index: imageIndex, severity: 'error', message: 'Invalid file type. Please upload a valid image file.' }));
      }
    } else {
      throw new Error('No file selected');
    }
    fileUploadRef.current.clear();
  };

  const onUploadImport = async (event) => {
    const file = event.files[0];
    if (!file) return; // Ensure a file is selected

    setCSVFile(file);
    setMessages([]); // Clear previous messages
    setLoading(true); // Show loading spinner
    try {
      // Call importPatientsCSV and get the imported data
      const response = await importPatientsCSV(file);

      if (response) {
        setAddToGroupUsed(true);
        const importedPatients = response.ImportedPatients;
        console.log('Imported Patients:', importedPatients);

        let createdCount = 0;
        let skippedCount = 0;

        // Process each patient sequentially
        for (const patientData of importedPatients) {
          const formattedPatient = formatImportedPatient(patientData);
          console.log('Formatted Patient:', formattedPatient);

          // Reset Formik state for the new patient
          formik.resetForm();
          formik.setValues(formattedPatient);

          // Validate the form before proceeding
          const formErrors = await formik.validateForm();
          if (Object.keys(formErrors).length > 0) {
            console.error('Validation errors:', formErrors);

            // Extract only the field names (keys) from the errors object
            const invalidFields = Object.keys(formErrors).join(', ');

            // Add error to the messages array
            skippedCount++;
            const errorMessage = `Skipping patient ${
              patientData.LastName + ', ' + patientData.FirstName
            }: The following fields are invalid - ${invalidFields}.`;
            setMessages((prev) => [...prev, { type: 'error', message: errorMessage }]);
            continue; // Skip this patient and move to the next one
          }

          // Check for unknown or invalid fields
          if (
            patientData?.FirstName === '' ||
            patientData?.FirstName === 'Unknown' ||
            patientData?.LastName === '' ||
            patientData?.LastName === 'Unknown'
          ) {
            // Determine which fields are invalid or unknown
            const invalidFields = [];
            if (patientData?.FirstName === '' || patientData?.FirstName === 'Unknown') {
              invalidFields.push('First Name');
            }
            if (patientData?.LastName === '' || patientData?.LastName === 'Unknown') {
              invalidFields.push('Last Name');
            }

            // Add warning to the messages array
            const warningMessage = `Skipping patient ${
              patientData?.LastName + ', ' + patientData?.FirstName
            }: The following fields are unknown or invalid - ${invalidFields.join(', ')}.`;
            skippedCount++;
            setMessages((prev) => [...prev, { type: 'error', message: warningMessage }]);
            console.warn(warningMessage);
            continue; // Skip this patient and move to the next one
          }

          // Validate PHN if it exists
          if (patientData?.PHN !== '') {
            console.log('PHN:', patientData.PHN);

            // Wrap the dispatch in a Promise to wait for the result
            const isValidPHN = await new Promise((resolve) => {
              dispatch(
                validatePHN(patientData?.PHN, (result) => {
                  resolve(result); // Resolve the Promise with the result
                })
              );
            });

            console.log('isValidPHN', isValidPHN);

            if (isValidPHN === false) {
              formattedPatient.PHN = ''; // Clear the PHN
              const warningMessage = `Created ${patientData?.LastName + ', ' + patientData?.FirstName}: Invalid PHN.`;
              setMessages((prev) => [...prev, { type: 'warning', message: warningMessage }]);
            }
          }

          // Wait for the duplicate check to complete
          const hasDuplicate = await new Promise((resolve) => {
            dispatch(
              duplicatePatientCheck(formattedPatient, (responseData) => {
                if (responseData?.length) {
                  handleAddPatient(responseData[0]);
                  setMessages((prev) => [
                    ...prev,
                    { type: 'warning', message: `Patient ${formattedPatient.LastName + ', ' + formattedPatient.FirstName} already exists.` }
                  ]);
                  setShowGroup(true);
                  resolve(true); // Indicate that a duplicate was found
                } else {
                  resolve(false); // Indicate that no duplicate was found
                }
              })
            );
          });

          console.log('Has duplicate:', hasDuplicate);

          // If no duplicate was found, create the patient
          if (!hasDuplicate) {
            await new Promise((resolve) => {
              onSubmit(formattedPatient, false);
              resolve();
            });

            createdCount++;
          }
        }

        // Display a summary message
        const summaryMessage = `Import completed. Created ${createdCount} of ${importedPatients.length} patients.`;
        const skippedMessage = (
          <span>
            <strong>Skipped {skippedCount}</strong> patients. Use <strong>“Add New Patient”</strong> or <strong>“Add New Label”</strong> buttons to
            add skipped patients to this group. Additionally, correct the PHN in profiles with invalid PHNs after saving this group.
          </span>
        );

        setMessages((prev) => [...prev, { type: 'info', message: summaryMessage }]);
        if (skippedCount > 0) {
          setMessages((prev) => [...prev, { type: 'infoSkip', message: skippedMessage }]);
        }
      } else {
        throw new Error('Invalid response format from import.');
      }
    } catch (error) {
      console.error('Error importing patients:', error);

      // Generic message with required headers
      const requiredHeadersMessage = `The CSV file must include the following headers:
    - First Name
    - Last Name
    - Address (or Street Address)
    - City
    - Province
    - Email (or Email Address)
    - MRP (or Referred To)
    - Nickname (or Preferred Name)
    - Home Phone
    - Phone (or Mobile Phone)
    - Birth Date (or Date of Birth)
    - Sex

    Please ensure your file contains all required columns and try again.`;

      // Update messages with the generic required headers message
      setMessages((prev) => [...prev, { type: 'error', message: requiredHeadersMessage }]);
    }

    setLoading(false); // Hide loading spinner
    CSVUploadRef.current.clear(); // Clear input
  };

  const formatImportedPatient = (partialPatientDetails) => {
    const initialValues = newPatientBlankRecord(DCNGuid, patientPickerQueryValue);

    const formattedPatient = {
      ...initialValues,
      PHN: partialPatientDetails.PHN,
      FirstName: partialPatientDetails.FirstName,
      LastName: partialPatientDetails.LastName,
      Sex: partialPatientDetails.Gender,
      BirthDay: partialPatientDetails.DOB,
      Email: partialPatientDetails?.Email || '',
      Street: partialPatientDetails?.Address || '',
      City: partialPatientDetails?.City || '',
      Province: partialPatientDetails?.Province || initialValues.Province,
      Zip: partialPatientDetails?.PostalCode || '',
      Phone: partialPatientDetails?.MobilePhone || '',
      PhoneType: partialPatientDetails?.MobilePhone ? 'Cell' : '',
      Phone2: partialPatientDetails?.HomePhone || '',
      PhoneType2: partialPatientDetails?.HomePhone ? 'Home' : '',
      AlterName: partialPatientDetails?.Nickname || ''
    };

    return formattedPatient;
  };

  const openInNewTab = () => {
    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.write(`
      <html>
        <head>
          <title>Messages</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            .message { margin-bottom: 10px; padding: 5px; border-radius: 5px; }
            .error { color: red; }
            .warning { color: orange; }
            .success { color: green; }
            .info { color: black; }
          </style>
        </head>
        <body>
          <h2>Messages</h2>
          <div>
            ${messages.map((msg) => `<div class="message ${msg.type}">${msg.message}</div>`).join('')}
          </div>
        </body>
      </html>
    `);
      newWindow.document.close();
    }
  };

  useEffect(() => {
    if (file) {
      onAutofillClick();
    }
  }, [file]);

  // useEffect(() => {
  //   if (!autofillTriggered && !formik.values.FirstName && !formik.values.LastName && autoFillUsedLast) {
  //     if (suggestedNames && suggestedNames.length > 0) {
  //       setSuggestedNamesVisible(true); // Open the dialog
  //     }
  //   }
  // }, [autofillTriggered, formik.values.FirstName, formik.values.LastName, autoFillUsedLast, suggestedNames]);

  const handleNameClick = (name) => {
    const updatedValues = {
      ...formik.values,
      FirstName: name.firstName,
      LastName: name.lastName
    };

    formik.setValues(updatedValues);
  };

  useEffect(() => {
    if (!isNew) {
      setNameFromEligibility(`${previousEligbility?.LAST_NAME?.trim()}, ${previousEligbility?.FIRST_NAME?.trim()}`);
      setNameFromPicture(nameFromLabelAfterEdit);
      setEligibilityFromEdit(previousEligbility);
      setPreferredName(preferredNameAfterEdit);
    }
  }, []);

  useEffect(() => {
    if (!isNew && fromSuccessPage) {
      setImage(previousImage);
    }
  }, [selectPreviousImage]);

  const openImageDialog = () => {
    setIsImageDialogVisible(true); // Show the dialog
  };

  // Function to close the image dialog
  const closeImageDialog = () => {
    setIsImageDialogVisible(false); // Hide the dialog
  };

  // const toggleQRCodeDialog = () => {
  //   setShowQRCodeDialog(!showQRCodeDialog);
  //   console.log('qrCodeURL:', qrCodeURL);
  // };

  const dynamicLabel = loading ? 'Loading' : isMobileOnly ? 'Label' : 'Upload Label';
  const dynamicIcon = loading ? 'pi pi-spin pi-spinner' : isMobileOnly ? 'pi pi-camera' : 'pi pi-upload';

  const headerActionButton = (
    <div className="flex">
      {/* <Button
        label="QR"
        icon="pi pi-qrcode"
        className="qr-code-button"
        onClick={toggleQRCodeDialog}
        style={{ marginRight: '1rem' }}
      /> */}
      <Tooltip target=".autofill-button" position="top" content="Ensure good lighting and keep the camera steady while taking a picture." />
      <div className="flex gap-3">
        <div style={{ position: 'relative', display: 'inline-block' }}>
          {!isMobileOnly && (
            <>
              {/* <Button
              ref={autofillButtonRef}
              label={isMobileOnly ? 'Label' : 'Upload Label'}
              className="autofill-button"
              onClick={onAutofillClick}
              type="Submit"
              loading={loading}
              icon={isMobileOnly ? 'pi pi-camera' : 'pi pi-upload'}
              iconPos="left"
              data-cy="autofill-button"
            /> */}
              <ButtonWithBadge value="Faster">
                <FileUpload
                  ref={fileUploadRef}
                  mode="basic"
                  accept="image/*, .heif, .heic"
                  customUpload
                  chooseOptions={{ label: dynamicLabel, icon: dynamicIcon }}
                  auto
                  visible={isNew}
                  uploadHandler={onUpload}
                  // chooseLabel="Upload Image"
                />
              </ButtonWithBadge>
            </>
          )}
          {isMobileOnly && (
            <>
              {/* <LabelSplitButton
              loading={loading}
              onAutofillClick={onAutofillClick}
              handleOpenCropper={handleOpenCropper}
              isMobileOnly={isMobileOnly}
              autofillButtonRef={autofillButtonRef}
            /> */}
              <ButtonWithBadge value="Faster">
                <FileUpload
                  ref={fileUploadRef}
                  mode="basic"
                  accept="image/*, .heif, .heic"
                  customUpload
                  chooseOptions={{ label: dynamicLabel, icon: dynamicIcon }}
                  auto
                  visible={isNew}
                  uploadHandler={onUpload}
                  // chooseLabel="Upload Image"
                />
              </ButtonWithBadge>
            </>
          )}
          {/* {isNew &&(
              <BetaBadge />
            )} */}
        </div>
        {/* {!isMobileOnly && (
          <Button
            label='Cropper'
            style={{ width: 'auto' }}
            onClick={handleOpenCropper}
            icon="pi pi-arrows-alt"
            iconPos="left"
            visible={isNew}
          />
          )} */}
      </div>
    </div>
  );

  useEffect(() => {
    if (croppedImage) {
      setImageToAutofill(croppedImage); // Update the state with the cropped image
    }
  }, [croppedImage]); // Listen for changes in croppedImage

  // Trigger autofill when imageToAutofill is set
  useEffect(() => {
    if (imageToAutofill) {
      onAutofillClick();
    }
  }, [imageToAutofill]); // Listen for changes in the image to trigger autofill

  const removeImage = (index) => {
    // Remove the image from both croppedImages and imageStatuses
    setCroppedImages((prevCroppedImages) => prevCroppedImages.filter((_, idx) => idx !== index));

    setImageStatuses((prevStatuses) => prevStatuses.filter((_, idx) => idx !== index));
  };

  const handleAddPatient = (patient) => {
    console.log('Attempting to add patient:', patient.PatientGuid);

    setSelectedPatients((prevPatients) => {
      // Check if the patient already exists in the updated state
      const isDuplicate = prevPatients.some((p) => p.PatientGuid === patient.PatientGuid);

      if (isDuplicate) {
        console.warn('Duplicate detected, patient not added:', patient);
        dispatch(setToastMessage({ type: 'warn', message: 'This patient is already in the group.' }));
        return prevPatients; // Return unchanged state
      }

      console.log('Patient added successfully.');
      return [...prevPatients, patient]; // Add patient to the updated list
    });
  };

  const handleRemovePatient = (index) => {
    setSelectedPatients((prev) => prev.filter((_, idx) => idx !== index)); // Remove the patient at the specified index
  };

  const handleSaveGroup = async () => {
    if (selectedGroupForEdit) {
      // **Editing an Existing Group**
      const updatedGroup = {
        ...selectedGroupForEdit,
        Name: groupName,
        Description: description || selectedPatients.map((patient) => patientLastNameWithInitials(patient)).join(', '),
        Status: 2, // Updated status
        FirstStep: {
          ...selectedGroupForEdit.FirstStep,
          PatientDetails: selectedPatients
        }
      };

      await editGroupInvoicesTemplate(updatedGroup);
      setGroupName(''); // Reset group name
      setSelectedPatients([]); // Clear selected patients
      setAddToGroupUsed(false); // Reset the flag
      history.goBack();
      dispatch(setToastMessage({ type: 'success', message: `Group "${groupName}" updated successfully.` }));
    } else {
      if (!groupName.trim()) {
        setGroupNameError('Group Name is required');
        return;
      }
      setGroupNameError(''); // Clear error if valid

      // Create the firstStep object with patient details included
      const firstStep = {
        ...getDefaultValues({
          currentPractitionerPrefs: defaultPreference,
          isNew: true,
          isGroup: true
        }),
        PatientDetails: selectedPatients // Add selectedPatients directly here
      };

      const records = selectedPatients.map((patient) => ({
        ...getDefaultValues({
          currentPractitionerPrefs: defaultPreference,
          isNew: true,
          isGroup: false // Each record is not a group itself
        }),
        PatientDetails: [patient] // Add the individual patient here
      }));

      // Create the groupData object with the initialized firstStep
      const groupData = {
        ...getGroupTemplateDefaultValues(),
        DCNGuid: DCNGuid,
        DoctorGuid: practitionerGuid,
        DateCreated: today,
        Description: description || selectedPatients.map((patient) => patientLastNameWithInitials(patient)).join(', '),
        Name: groupName,
        Records: records,
        FirstStep: firstStep // Use the updated firstStep
      };

      console.log('groupData', groupData);
      // Close the dialog (if needed)
      // setShowGroupDialog(false);
      try {
        // Call API or dispatch action to save the group
        const result = await createGroupInvoicesTemplate(groupData);

        // Handle successful save
        if (result) {
          dispatch(setToastMessage({ type: 'success', message: `Group "${groupName}" created successfully.` }));
          setGroupName(''); // Reset group name
          setSelectedPatients([]); // Clear selected patients
          setAddToGroupUsed(false); // Reset the flag
          history.push(`${routes.dashboard.path}`);
        }
      } catch (error) {
        dispatch(setToastMessage({ type: 'error', message: 'Failed to save the group. Please try again.' }));
      } finally {
        console.log('Group save completed.');
      }
    }
  };

  //--------------------------------- Autofill END ---------------------------------//

  const cardTitle = isNew ? t('New_Patient') : isMobileOnly && !isLandscape ? t('Edit_Profile') : `${t('Edit_Profile')}: ${patientsName()}`;

  const createGroupTitle = (
    <div className="flex justify-content-between align-items-center">
      {selectedGroupForEdit ? 'Edit Group' : 'Create a Group of Patients'}
      <div id="csv-upload-wrapper">
        <FileUpload
          outlined
          ref={CSVUploadRef}
          mode="basic"
          accept=".csv"
          customUpload
          chooseOptions={{
            iconOnly: true,
            icon: 'pi pi-file-import',
            className: 'p-button-outlined p-button-rounded',
            style: { maxWidth: '32.5px', width: '32.5px', minWidth: 0 }
          }}
          auto
          uploadHandler={onUploadImport}
        />
      </div>
      <Tooltip target="#csv-upload-wrapper" content="Import patients from Jane" position="top" />
    </div>
  );

  const dialogLayout = (
    <>
      {!process.env.REACT_APP_CYPRESS && (
        <TabView
          className="createNewPatientFormTabView block md:hidden"
          activeIndex={patientFormActiveTabIndex}
          onTabChange={(e) => dispatch(setActiveTabIndexForPatientForm(e.index))}
        >
          <TabPanel header={t('Profile')}>
            <div className="grid mt-1">{demographicsFormLayout}</div>
          </TabPanel>

          <TabPanel id="testId" header={t('Address')}>
            <div className="grid mt-1">{addressFormLayout}</div>
          </TabPanel>
        </TabView>
      )}

      <div className="hidden md:flex">
        <div className={cx('flex flex-column')} style={{ width: '500%' }}>
          <div className="mb-2">{t('Demographics')}</div>
          <div className="flex flex-column justify-content-between h-full">
            <div className="grid mt-1">{demographicsFormLayout}</div>
          </div>
        </div>

        <Divider layout="vertical" />

        <div className={cx('flex flex-column')} style={{ width: '500%' }}>
          <div className="mb-2">{t('Address')}</div>
          <div className="grid mt-1">{addressFormLayout}</div>
          {actionButtons}
        </div>
      </div>
    </>
  );

  const pageLayout = (
    <>
      {isMobile ? (
        <TabView
          className="createNewPatientFormTabView"
          activeIndex={patientFormActiveTabIndex}
          onTabChange={(e) => dispatch(setActiveTabIndexForPatientForm(e.index))}
        >
          <TabPanel header={t('Profile')}>
            <div className="grid mt-1">{demographicsFormLayout}</div>
          </TabPanel>

          <TabPanel id="testId" header={t('Address')}>
            <div className="grid mt-1">{addressFormLayout}</div>
          </TabPanel>
        </TabView>
      ) : (
        <div className="flex">
          <div className={cx('flex flex-column', classes.wrapForLeftAndRightParts)}>
            <div className="mb-2">{t('Demographics')}</div>
            <div className="flex flex-column justify-content-between h-full">
              <div className="grid mt-1">{demographicsFormLayout}</div>
            </div>
          </div>

          <Divider className={classes.verticalDevider} layout="vertical" />

          <div className={cx('flex flex-column', classes.wrapForLeftAndRightParts)}>
            <div className="mb-2">{t('Address')}</div>
            <div className="grid mt-1">{addressFormLayout}</div>
            {actionButtons}
          </div>
        </div>
      )}
    </>
  );

  const formLayout = (
    <div style={{ position: 'relative' }}>
      {/* Overlay to block interaction when loading or fetching */}
      {(loading || isFetchingPatientEligibility) && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.6)', // Semi-transparent overlay
            zIndex: 1
          }}
        />
      )}
      <form id="patient-form" onSubmit={formik.handleSubmit} className="p-fluid mt-2">
        <div className="col-12 p-0">
          {isMobileOnly && !isLandscape && !isNew && <div className="flex mb-3 justify-content-center">{patientsName()}</div>}
        </div>

        {props.showInDialog ? dialogLayout : pageLayout}

        {/* Mobile view action buttons */}
        {isMobile && actionButtons}
      </form>
    </div>
  );

  const formWrap = () => {
    if (!showInCard) return formLayout;
    const headerAction = showLabelreader ? headerActionButton : null;
    return (
      <>
        {showForm && !showCropper && !showGroup && (
          <PatientFormCard
            title={cardTitle}
            titleClassName={`flex align-items-center ${isNew ? 'justify-content-center' : 'justify-content-center'} pb-4`}
            rootClassName={isMobile ? classes.newPatientFormMobile : classes.newPatientFormDesktop}
            headerContentLeft={headerAction}
            spacer={isNew}
            showForm={showForm}
            // headerContentLeft={isMobileOnly && !isLandscape && headerContentLeft} // align card title at the center on mobile devices
          >
            {showImage && (
              <div className="pb-2">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '20px',
                    padding: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  {/* <h3 style={{
                fontSize: '1.2rem',
                marginBottom: '10px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#333'
              }}>Uploaded Image</h3> */}
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                      display: 'flex', // Ensures Flexbox layout
                      paddingTop: '2rem',
                      paddingBottom: '2rem',
                      justifyContent: 'center', // Horizontal centering
                      alignItems: 'center', // Vertical centering
                      overflow: 'hidden', // Prevents overflow of the image
                      cursor: 'pointer' // Change cursor to pointer on hover
                    }}
                    onClick={() => openImageDialog(image)}
                  >
                    <img
                      src={image}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '400px',
                        objectFit: 'contain', // Ensures the image scales without distortion
                        margin: '0' // Remove any default margin on the image
                      }}
                    />
                  </div>
                </div>
                {errorMessage && (
                  <div
                    className={`flex flex-column w-full items-center justify-center px-3 py-3 bg-yellow-100 text-yellow-600`}
                    style={{ height: 'auto', borderRadius: '8px', fontWeight: 'bold', marginTop: '10px' }}
                  >
                    <div className="flex items-center justify-center">
                      <i className={`pi pi-exclamation-triangle mr-2`} style={{ fontSize: '1.5rem' }}></i>
                      <p>{errorMessage}</p>
                    </div>
                    {errorMessage?.toLowerCase().includes('name not recognized') ? (
                      <div className="flex sm:flex-row flex-column" style={{ marginTop: '10px' }}>
                        {suggestedNames.map((name, index) => (
                          <Button
                            key={index}
                            label={`${name.lastName}, ${name.firstName}`}
                            style={{
                              width: 'auto'
                            }}
                            text
                            onClick={() => handleNameClick(name)}
                          />
                        ))}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            )}

            {formLayout}
          </PatientFormCard>
        )}

        {showCropper ? (
          <div className="flex pl-4">
            {showCropperCard && (
              <Card
                title="Crop Image"
                subTitle="Adjust the cropping area and save when ready."
                style={{ width: '40vw', maxWidth: '600px', margin: '20px auto' }}
                header={
                  <div style={{ textAlign: 'center' }}>
                    <i className="pi pi-image mt-3" style={{ fontSize: '2rem' }}></i>
                  </div>
                }
                footer={
                  <div className="cropper-buttons flex justify-content-center gap-3">
                    {intialImageForCropper ? (
                      <>
                        <Button label="Save" icon="pi pi-check" onClick={handleCropSave} />
                        <Button label="Cancel" outlined icon="pi pi-times" onClick={handleCropCancel} />
                      </>
                    ) : (
                      <Button label="Cancel" outlined icon="pi pi-times" onClick={handleCropCancel} />
                    )}
                  </div>
                }
              >
                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                  <Button label="Upload Image" icon="pi pi-upload" className="p-button-primary" onClick={handleFileChange} />
                </div>
                <Cropper
                  src={intialImageForCropper}
                  style={{ height: 400, width: '100%' }}
                  initialAspectRatio={NaN}
                  autoCropArea={1}
                  guides={true}
                  aspectRatio={NaN}
                  viewMode={isMobile ? 0 : 1}
                  background={false}
                  responsive={true}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  ref={cropperRef}
                  dragMode={isMobile ? 'move' : 'crop'}
                />
                <div className="cropped-images-list">
                  {/* {croppedImages.map((img, index) => (
          <div key={index} className="cropped-image-preview">
            <img src={img} alt={`Cropped region ${index + 1}`} style={{ maxWidth: '500px', maxHeight: 'auto' }} />
          </div>
        ))} */}
                </div>
              </Card>
            )}
            {showCroppedImages && (
              <Card
                title="Cropped Images Status"
                subTitle="Review the status of processed images"
                style={{ margin: '20px', padding: '20px', width: '100%' }}
              >
                <div
                  className="cropped-images-grid"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                    gap: '20px'
                  }}
                >
                  {imageStatuses.map((img, index) => (
                    <div
                      key={index}
                      className="cropped-image-preview"
                      style={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: '10px',
                        backgroundColor: '#f9f9f9',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <img
                        src={img.image}
                        alt={`Cropped region ${index + 1}`}
                        style={{
                          width: '100%',
                          height: 'auto',
                          borderRadius: '5px',
                          marginBottom: '10px'
                        }}
                      />
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {img.status === 'failed' && <i className="pi pi-times-circle" style={{ color: 'red', fontSize: '1.5rem' }}></i>}
                        {img.status === 'processing' && <i className="pi pi-spin pi-spinner" style={{ color: '#007bff', fontSize: '1.5rem' }}></i>}
                        {img.status === 'success' && <i className="pi pi-check-circle" style={{ color: 'green', fontSize: '1.5rem' }}></i>}
                        {img.status === 'duplicate' && <i className="pi pi-exclamation-circle" style={{ color: 'orange', fontSize: '1.5rem' }}></i>}
                      </div>
                      {img.status === 'failed' && (
                        <div>
                          <Button
                            label="View error"
                            onClick={() => {
                              setShowCropper(false);

                              // Get the data from the current imageStatus
                              const currentStatus = imageStatuses[index];

                              console.log('Current Status:', currentStatus);

                              // Set the form to show the error data
                              if (currentStatus?.formData) {
                                formik.setValues(currentStatus.formData); // Update form data
                              }

                              console.log('Formik values:', formik.values);

                              // Set the image for display
                              if (currentStatus?.image) {
                                setShowImage(true);
                                setImage(currentStatus.image);
                              }

                              console.log('Image:', image);

                              console.log('Current Error:', currentStatus?.errorMessage);
                              if (currentStatus?.errorMessage) {
                                setErrorMessage(currentStatus?.errorMessage);
                              }

                              console.log('Current Suggested Names:', currentStatus?.suggestedNames);

                              if (currentStatus?.suggestedNames) {
                                setSuggestedNames(currentStatus?.suggestedNames);
                              }
                              setStep(0);
                            }}
                            className="mt-2"
                          />
                        </div>
                      )}
                      {!processingAllPressed && (
                        <Button icon="pi pi-trash" rounded onClick={() => removeImage(index)} className="p-button-danger mt-3" />
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex justify-content-center mt-5">
                  <Button label="Process All" icon="pi pi-check" onClick={processAllImages} type="submit" disabled={croppedImages.length === 0} />
                  {imageStatuses.length > 0 && imageStatuses.every((status) => status.status !== 'pending' && status.status !== 'processing') && (
                    <div className="flex justify-content-center mt-5">
                      <Button
                        label="Complete"
                        onClick={() => {
                          setStep(1); // Move to the success page
                          setShowCropper(false); // Close the cropper
                        }}
                      />
                    </div>
                  )}
                </div>
              </Card>
            )}
          </div>
        ) : null}

        {!showForm && !showGroup && showLabelreader && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '80vh',
                textAlign: 'center',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                position: 'relative'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center', // Center content vertically
                  flexGrow: 1 // Takes available space in the column layout
                }}
              >
                <h2
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '600',
                    marginBottom: '15px',
                    color: '#333'
                  }}
                >
                  New Patient
                </h2>
                <p
                  style={{
                    color: '#666',
                    maxWidth: '400px',
                    lineHeight: '1.7',
                    marginBottom: '30px'
                  }}
                >
                  Take a picture of the label or upload it from your Gallery. If you don't see the option to select the Camera or browse your Gallery,
                  change permissions for this browser and allow it to access your Camera and Gallery.
                  <br />
                  <span style={{ display: 'inline-block', marginTop: '15px' }}>
                    Ensure that the image you upload is clear, well-lit, and contains all necessary information.
                  </span>
                </p>

                <div className="flex flex-column gap-3">
                  <FileUpload
                    ref={fileUploadRef}
                    mode="basic"
                    accept="image/*, .heif, .heic"
                    customUpload
                    chooseOptions={{ label: dynamicLabel, icon: dynamicIcon }}
                    auto
                    visible={isNew}
                    uploadHandler={onUpload}
                    // chooseLabel="Upload Image"
                  />
                  <Button label="Enter Manually" onClick={() => setShowForm(true)} className="p-button-text" />
                </div>
              </div>

              {/* Close Button Anchored at the Bottom */}
              <Button className="p-button-outlined w-full" type="button" label="Close" onClick={onClose} />
            </div>
          </>
        )}
      </>
    );
  };

  const whenPrompt = (showPrompt && formik.dirty && displayPrompt) || addToGroupUsed;

  if (!formik.values) return <CircularProgress />;

  return (
    <div id={isNew ? elementIDs.createPatientRootContainer : elementIDs.editPatientRootContainer}>
      <Prompt when={whenPrompt} message={promptMessage} />

      {/* CircularProgress Overlay */}
      {(loading || isFetchingPatientEligibility) && <CircularProgress />}

      {isFetchingPatients && <CircularProgress />}

      {step === 0 && <div className={`flex justify-content-center ${!showGroup ? 'contentOutsidePaddings' : ''}`}>{formWrap()}</div>}

      {step === 1 && patientDetails && (
        <Success
          patientDetails={patientDetails}
          eligibility={isNew ? eligibility : eligibilityFromEdit}
          showNewPatientSuccessPage={showNewPatientSuccessPage}
          setStep={setStep}
          addNewPatientForNewClaim={addNewPatientForNewClaim}
          image={image}
          nameFromEligibility={nameFromEligibility}
          nameFromPicture={nameFromPicture}
          setShowForm={setShowForm}
          onAutofillClick={onAutofillClick}
          setShowImage={setShowImage}
          setImage={setImage}
          isNew={isNew}
          preferredName={preferredName}
          setPreferredName={setPreferredName}
          formik={formik}
          imageIndex={imageIndex}
          selectedPatients={selectedPatients}
          setSelectedPatients={setSelectedPatients}
          setUploadFileFromSuccessPage={setUploadFileFromSuccessPage}
        />

        //   <div>
        //   {createdPatients.map((patient, index) => (
        //     <Success
        //       key={patient.PatientGuid || index} // Ensure a unique key
        //       patientDetails={patient}
        //       eligibility={isNew ? eligibility : patient.Eligibility} // Adjust eligibility if needed
        //       showNewPatientSuccessPage={showNewPatientSuccessPage}
        //       setStep={setStep}
        //       addNewPatientForNewClaim={addNewPatientForNewClaim}
        //       image={image}
        //       nameFromEligibility={nameFromEligibility}
        //       nameFromPicture={nameFromPicture}
        //       setShowForm={setShowForm}
        //       onAutofillClick={onAutofillClick}
        //       setShowImage={setShowImage}
        //       setImage={setImage}
        //       isNew={isNew}
        //       preferredName={preferredName}
        //       setPreferredName={setPreferredName}
        //       formik={formik}
        //       imageIndex={imageIndex}
        //     />
        //   ))}
        // </div>
      )}

      {showGroup && (
        <Card
          title={createGroupTitle}
          subTitle={isMobile ? 'to use on Batch screen' : 'Use this group to bill all patients at once with the Batch screen.'}
          style={{
            width: isMobile ? '100vw' : window.innerWidth <= 950 ? '75vw' : '60vw',
            maxWidth: '700px', // Prevent it from being too large
            margin: isMobile ? '5px auto' : '20px auto',
            position: 'relative',
            maxHeight: isMobile ? '90vh' : ' 90vh', // Prevent card from expanding indefinitely
            overflowY: 'auto' // Add scroll when content overflows
          }}
        >
          <div className="p-fluid flex flex-column gap-3">
            {/* Group Name */}
            <div className="field">
              <div>
                <label htmlFor="groupName">
                  Group Name <span style={{ color: 'red' }}>*</span>
                </label>
                <InputText
                  id="groupName"
                  value={groupName}
                  onChange={(e) => {
                    setGroupName(e.target.value);
                    if (e.target.value.trim()) {
                      setGroupNameError('');
                    }
                  }}
                  placeholder="Enter group name"
                  className={groupNameError ? 'p-invalid' : ''} // Highlight input if invalid
                />
                {groupNameError && <small className="p-error">{groupNameError}</small>}
              </div>

              {/* <label className= 'pt-3' htmlFor="groupDescription">Group Description</label>
      <InputText
        id="groupName"
        value = {description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Enter group description"
      /> */}
            </div>

            {/* Add New Patient Options */}
            <div className="field">
              {/* <h5>Add New Patient</h5>  flex-column gap-3*/}

              <p>{isMobile ? 'Add a patient with either method.' : 'You can add a patient to the group using any of the methods below.'}</p>
              <div className={`flex align-items-start ${isMobile ? 'justify-content-between' : 'gap-2'} w-100`}>
                {/* Global Search - Takes more space on Desktop */}
                <div
                  ref={globalSearchRef} // Attach the ref here
                  className={`${isMobile ? 'flex-grow-1 pr-2' : 'w-100'}`}
                  style={{ flexGrow: 1 }} // Ensures it stretches but doesn't shrink too much
                >
                  <GlobalSearch name="patient" placeholder="Search patient" onSelectPatient={handleAddPatient} onFocus={isMobile} />
                </div>

                {/* Buttons - Stay Small Without Wrapping */}
                <div
                  className="flex gap-2 mt-2"
                  style={isMobile ? { flexGrow: 0, flexShrink: 0, whiteSpace: 'nowrap' } : { flexGrow: 1, flexShrink: 0, whiteSpace: 'nowrap' }} // Keeps buttons from expanding and prevents wrapping
                >
                  <Button
                    label={!isMobile ? 'Snap Label' : ''}
                    icon={isMobileOnly ? 'pi pi-camera' : 'pi pi-upload'}
                    className=""
                    rounded={isMobile}
                    outlined
                    onClick={() => {
                      formik.resetForm();
                      setShowImage(false);
                      setShowGroup(false);
                      setShowForm(false);
                      setAddToGroupUsed(true);
                      setTimeout(() => {
                        fileUploadRef.current?.getInput().click();
                      }, 20); // 100ms delay ensures React updates states first
                    }}
                  />
                  <Button
                    label={!isMobile ? 'Add New Patient' : ''}
                    icon="pi pi-plus"
                    className="p-button-primary"
                    rounded={isMobile}
                    onClick={() => {
                      formik.resetForm();
                      setShowImage(false);
                      setShowGroup(false);
                      setShowForm(true);
                      setAddToGroupUsed(true);
                    }}
                  />
                  {/* <FileUpload
      outlined
      ref={CSVUploadRef}
      mode="basic"
      accept=".csv"
      customUpload
      chooseOptions={{ label: 'Import Patients', icon: 'pi pi-users' }}
      auto
      uploadHandler={onUploadImport}
      /> */}
                </div>
              </div>
            </div>
            {/* Current Patient List */}
            <div className="field">
              {/* <h5>Patients in Group</h5> */}
              {messages.length > 0 && (
                <div style={{ maxHeight: '400px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', position: 'relative' }}>
                  <Button
                    tooltip="Open in new tab"
                    tooltipOptions={{ position: 'top' }}
                    outlined
                    icon="pi pi-external-link"
                    onClick={openInNewTab}
                    style={{
                      position: 'sticky',
                      top: '0',
                      right: '0',
                      float: 'right'
                    }}
                  />
                  {messages
                    .filter((msg) => msg.type !== 'infoSkip') // Exclude "infoSkip" messages
                    .map((msg, index) => (
                      <div
                        key={index}
                        style={{
                          color: msg.type === 'error' ? 'red' : msg.type === 'warning' ? 'orange' : msg.type === 'success' ? 'green' : 'black',
                          marginBottom: '10px'
                        }}
                      >
                        {msg.message}
                      </div>
                    ))}
                </div>
              )}
              <p>
                You have added <strong>{selectedPatients.length}</strong> patients to the group.
                {messages.length > 0 && <span style={{ marginLeft: '5px' }}>{messages.find((msg) => msg.type === 'infoSkip')?.message}</span>}
              </p>
              {selectedPatients.length > 0
                ? selectedPatients
                    .slice() // Create a shallow copy to avoid mutating the original array
                    .reverse() // Reverse the order so the newest patient is on top
                    .map((patient, index) => (
                      <div
                        key={selectedPatients.length - 1 - index} // Ensure unique keys
                        className="flex justify-content-between align-items-center"
                        style={{
                          padding: isMobile ? '0.5rem 0' : '0.5rem 1rem', // Set left/right padding to 0 when mobile
                          borderBottom: '1px solid #ccc' // Optional divider for visual separation
                        }}
                      >
                        {/* Patient Info */}
                        <div
                          className="flex align-items-center gap-3"
                          data-pr-tooltip={`PHN: ${patient.PHN || 'N/A'}`} // Tooltip for PHN
                        >
                          <Tooltip target=".flex.align-items-center.gap-3" position="top" />
                          <span>
                            <strong>
                              {selectedPatients.length - index}. {patientFullNameWithAge(patient)}
                            </strong>
                          </span>
                        </div>

                        {/* Remove Button */}
                        <Button
                          icon="pi pi-trash"
                          className="p-button-text p-button-danger"
                          onClick={() => handleRemovePatient(selectedPatients.length - 1 - index)} // Adjust remove function to match the reversed order
                          tooltip="Remove Patient"
                        />
                      </div>
                    ))
                : null}
            </div>

            {/* Save and Cancel Buttons */}
            {/* Save and Cancel Buttons */}
            <div
              className="flex justify-content-end gap-3 pb-3 surface-overlay"
              style={{
                position: 'sticky',
                bottom: 0,
                left: 0,
                right: 0
              }}
            >
              {' '}
              {selectedPatients.length > 0 ? (
                <>
                  <Button label="Save & Close" className="p-button-primary" onClick={handleSaveGroup} />
                  <Button label="Cancel" outlined onClick={() => history.goBack()} />
                </>
              ) : (
                <Button label="Close" className="p-button-primary" outlined onClick={() => history.goBack()} />
              )}
            </div>
          </div>
        </Card>
      )}

      {!addToGroupUsed && (
        <>
          <DuplicatePHNDialog
            formik={formik}
            visible={showPHNDuplicateDialog}
            duplicatedPatientsData={duplicatedPatients[0]}
            addNewPatientToNewClaim={addNewPatientToNewClaim}
            handleExistingProfile={handleExistingProfile}
            onHide={onHideDuplicatePHNDialog}
            onExit={onExit}
          />

          <DuplicatePatientsDialog
            visible={showPatientDuplicateDialog}
            addNewPatientToNewClaim={addNewPatientToNewClaim}
            duplicatedPatientsList={duplicatedPatients}
            onHide={onHideDuplicatePatientsDialog}
            onSubmit={() => onSubmit(formik.values, false)}
            handleExistingProfile={handleExistingProfile}
          />
        </>
      )}

      <Dialog
        header="Uploaded Image"
        visible={isImageDialogVisible}
        style={{ width: '70vw', maxWidth: '800px' }} // Default dialog size
        headerStyle={{
          textAlign: 'center' // Center-align the header text
        }}
        onHide={closeImageDialog} // Close the dialog
        breakpoints={{
          '960px': '90vw', // For screens smaller than 960px, set the dialog width to 90% of the viewport width
          '640px': '100vw' // For screens smaller than 640px, set the dialog width to 100% of the viewport width
        }}
      >
        <div className="flex justify-content-center pb-6">
          <img
            src={image}
            style={{
              maxWidth: '100%',
              maxHeight: '600px',
              objectFit: 'contain', // Ensures the image scales without distortion
              margin: '0' // Remove any default margin on the image
            }}
          />
        </div>
        <div className="flex justify-content-center">
          <Button label="Close" onClick={closeImageDialog} />
        </div>
      </Dialog>

      <AddNewReferralDialog
        visible={showNewPractitionerDialog}
        formik={formik}
        setShowNewPractitionerDialog={setShowNewPractitionerDialog}
        setDefaultReferralList={setDefaultReferralList}
      />

      {/*  <Dialog
        header="Select a Name"
        visible={suggestedNamesVisible}
        style={{ width: '400px' }}
        modal
        onHide = {() => setSuggestedNamesVisible(false)}
      >
        <div>
          {suggestedNames.map((name, index) => (
            <Button
              key={index}
              label={`${name.lastName}, ${name.firstName}`}
              style={{ display: 'block', margin: '5px 0' }}
              onClick={() => handleNameClick(name)}
            />
          ))}
        </div>
      // </Dialog> */}

      {/* QR Code Dialog */}
      {/* <Dialog
        header="QR Code for Upload Page"
        visible={showQRCodeDialog}
        onHide={toggleQRCodeDialog}
        style={{ width: '30vw' }}
      >
        <div className="flex justify-content-center">
          <QRCodeCanvas value={qrCodeURL} size={200} />
        </div>
        <p style={{ textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
          Scan the QR code to visit the upload page.
        </p>
      </Dialog> */}
    </div>
  );
};

export default PatientForm;
