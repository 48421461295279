import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import GroupPickerTable from '../GroupPickerTable/GroupPickerTable';
import RotateYoutDeviseMessage from '../../Layouts/RotateYoutDeviseMessage';
import GroupPickerContentWrap from '../../../../modules/Layouts/GroupPickerContentWrap';
import GroupDxPickerActionButtons from '../../ActionButtons/GroupDxPickerActionButtons';

import { useGroupPickerColumns } from '../../hooks/useGroupPickerColumns';
import { closeToastMessage } from '../../../../../../../core/actions/core.action.creators';
import { useGroupPickerInputs } from '../../hooks/useGroupPickerInputs';
import { bonusFeeCodes } from '../../../../../../../config/defaultValuesConfig';
import { inputs } from '../../../../helpers/inputs';
import { isDxPrefilled } from '../../../../../../../config/specialitiesConfig';

const GroupDxPicker = () => {
  const dispatch = useDispatch();
  const { watch, localState, setLocalState } = useFormContext();
  const { dxColumns } = useGroupPickerColumns();
  const { autofillLatestDxCodes, autofillL23Code } = useGroupPickerInputs();
  const { toastMessage, window } = useSelector((state) => state.core);
  const speciality = watch(inputs.speciality.name);

  // Close toast messages
  useEffect(() => {
    toastMessage.message && dispatch(closeToastMessage(true));

    if (isDxPrefilled(speciality)) setLocalState((prevState) => ({ ...prevState, latestDx: false }));

    // Do not autofill Dx codes if latest Dx codes is turned off
    // Do not autofill Dx codes if practitioenr is Anesth. or Midwife
    if (localState.latestDx && !isDxPrefilled(speciality)) {
      // CMO-2577 - Group Teleplan->filling Dx codes by default
      autofillLatestDxCodes();
    } else {
      // CMO-2648 - Group Teleplan -> add L23 dx to 98011, 98012, 98990
      // Do not add latest dx codes for 98011, 98012, 98990 codes
      localState.groupRecords.forEach((i, index) => {
        const feeCode = i[inputs.feeCodes.codeType][0]?.value;
        const checkBonusCode = bonusFeeCodes.includes(feeCode);
        if (!checkBonusCode) return;
        return autofillL23Code(i, index);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.listOfPatients]);

  if (window.showNewTeleplanButtons) return <RotateYoutDeviseMessage />;

  return (
    <GroupPickerContentWrap>
      <GroupPickerTable columns={dxColumns} />
      <GroupDxPickerActionButtons />
    </GroupPickerContentWrap>
  );
};

export default GroupDxPicker;
