import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import ClaimsTable from '../ClaimsTable/ClaimsTable';
import { setClaimsDialogVisibility } from '../../actions/cashFlow.axtion.creators';
import { cashFlowInitialState } from '../../reducers/cashFlow.reducer';
import { formatDate } from '../../../utils/formatDate';

const ClaimsTableDialog = () => {
  const dispatch = useDispatch();
  const claimsDialog = useSelector((state) => state.cashFlow.dialogs.claimsDialog);
  const billedCount = claimsDialog.billedCount || 0;
  const claimsText = billedCount === 1 ? 'claim' : 'claims';

  const onHide = () => dispatch(setClaimsDialogVisibility(cashFlowInitialState.dialogs.claimsDialog));

  return (
    <Dialog
      header={`${billedCount} ${claimsText} on ${formatDate(claimsDialog.periodFrom)}`}
      visible={claimsDialog.visible}
      breakpoints={{ '968px': '95vw' }}
      style={{ minWidth: '360px', minHeight: '320px' }}
      onHide={onHide}
    >
      <ClaimsTable requestParams={claimsDialog.requestParams} />
    </Dialog>
  );
};

export default ClaimsTableDialog;
