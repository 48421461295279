import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AuthFormWrap from '../../../components/Wraps/PageWrap/AuthFormWrap';
import { routes } from '../../../routes/routes';

const UsernameCreatedExpiration = () => {
  const history = useHistory();

  const loginRedirect = () => {
    window.open('https://www.claimmanager.ca/', '_blank');
  };

  return (
    <AuthFormWrap>
      <div className="flex flex-column gap-6 my-4">
        <div className="m-0 text-800 w-full text-center align-items-center font-bold text-3xl">Link Expired!</div>
        <div className="flex flex-column line-height-3 text-800 text-lg">
          <div>
            The link you followed has expired or is no longer valid. To complete the account creation process, please contact us at 604-626-9903 or
            info@claimmanager.ca.
          </div>
          <div className="font-bold">If you registered via a Referral Link, you must use the original Referral Link to renew your registration.</div>
        </div>
        <p className="flex flex-column align-items-center text-800">
          <Button link label="Go to our marketing website" type="button" onClick={loginRedirect} className="p-0 text-lg" />
        </p>
      </div>
    </AuthFormWrap>
  );
};

export default UsernameCreatedExpiration;
