import React from 'react';
import TeleplanPrintMessageBody from './TeleplanPrintMessageBody';
import TeleplanMessageHeader from './TeleplanMessageHeader';

const TeleplanMessagePrint = ({ data }) => {
  return (
    <div id="invoice-content" className="printContent flex flex-column gap-3">
      <div className="py-3">
        <TeleplanMessageHeader date={data?.MessageDate} />
      </div>
      <TeleplanPrintMessageBody message={data?.MessageLine} />
    </div>
  );
};

export default TeleplanMessagePrint;
