import React from 'react';
import { useFormContext } from 'react-hook-form';
import { PractitionerWithFormControl } from '../../../../../../../components/Inputs';

const Practitioner = () => {
  const { control, isNew } = useFormContext();
  return <PractitionerWithFormControl control={control} naOption={isNew} registeredOnly />;
};

export default Practitioner;
