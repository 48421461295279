import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AuthFormWrap from '../../../components/Wraps/PageWrap/AuthFormWrap';
import { routes } from '../../../routes/routes';

const UsernameCreatedSuccess = () => {
  const history = useHistory();

  const loginRedirect = () => {
    history.replace(routes.dashboard.path);
  };

  return (
    <AuthFormWrap>
      <div className="flex flex-column gap-6 my-4">
        <div className="m-0 text-800 w-full text-center align-items-center font-bold text-3xl">User Name Created Successfully!</div>
        <div className="flex line-height-3 text-800 text-lg">
          Your username has been successfully created. You're just one step away from accessing your account. Click the link below to log in and get
          started!
        </div>
        <p className="flex flex-column align-items-center text-800">
          <Button link label="Go to Login Page" type="button" onClick={loginRedirect} className="p-0 text-lg" />
        </p>
      </div>
    </AuthFormWrap>
  );
};

export default UsernameCreatedSuccess;
