import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

import { InputLabel } from '../Inputs/InputWrapper/LabelLayout';
import { InputWrapper } from '../Inputs';

const SecretQuestion = ({ title, name, handleQuestionChange }) => {
  const {
    control,
    formState: { errors },
    watch,
    loading
  } = useFormContext();

  const question1 = watch('question1');
  const question2 = watch('question2');
  const question3 = watch('question3');
  const secretQuestions = watch('secretQuestions');

  const filtredQuestionsList = useMemo(() => {
    const valuesList = ['question1', 'question2', 'question3'];

    const filtredValuesList = valuesList.filter((item) => item !== name);
    const selectedQuestionsList = watch(filtredValuesList);
    const secretQuestionsDropdownList = secretQuestions?.map((item) => item.label);

    return secretQuestionsDropdownList.filter((item) => !selectedQuestionsList.includes(item));
  }, [loading, secretQuestions, question1, question2, question3]);

  const errorMessage = errors[name]?.message;
  return (
    <div className="flex align-items-center justify-content-between gap-1">
      <label htmlFor={name} className="text-500 text-sm w-10rem" style={{ minWidth: '8rem' }}>
        <InputLabel name={name} label={title} required />
      </label>
      <InputWrapper
        name={name}
        style={{ height: '45px', width: '100%', boxSizing: 'border-box', overflow: 'hidden' }}
        errorMessage={errorMessage}
        required
        hideLabel
      >
        <Controller
          name={name}
          control={control}
          rules={{ required: 'Question is required' }}
          render={({ field }) => (
            <Dropdown
              autoFocus={name === 'question1'}
              id={name}
              data-testid="secretQuestion"
              {...field}
              value={field.value}
              placeholder="Choose a question..."
              // className="h-2rem w-full mb-0 text-base align-items-center"
              options={filtredQuestionsList}
              onChange={(e) => {
                handleQuestionChange(e, field);
              }}
              className={classNames({ 'p-invalid': errors[name] }, 'w-full text-base align-items-center')}
            />
          )}
        />
      </InputWrapper>
    </div>
  );
};

export default SecretQuestion;
