import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Chip } from 'primereact/chip';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import DeleteDialog from '../../../../components/Dialogs/DeleteDialog/DeleteDialog';
import ActionButtons from '../../../patients/components/Patients/rowTemplates/ActionButtons';
import TablePaginator from '../../../../components/Paginators/TablePaginator';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';
import { authorBodyTemplate, titleBodyTemplate, dateBodyTemplate, officeNumBodyTemplate, patientBodyTemplate } from './rowTemplates';
import { authorRowFilterTemplate, patientFilterTemplate, dateFilterTemplate, recordNoFilterTemplate } from './rowFilters';
import { activePractitionerStatuses } from '../../../config/statuses';
import { debounce } from 'lodash';
import moment from 'moment';
import cx from 'classnames';

import './NoteTable.scss';

const NoteTable = ({ notes, onSearchAll, onSelectRowCallback, fetchNotes, onDeleteNote, rowActionsMenuItems, inboxType, loading }) => {
  const [filters, setFilters] = useState({
    Author: { value: null, matchMode: FilterMatchMode.EQUALS },
    Date: { value: null, matchMode: FilterMatchMode.DATE_IS },
    Patient: { value: null, matchMode: FilterMatchMode.EQUALS },
    RecordId: { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  const [globalFilter, setGlobalFilter] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState({});
  const [selectedMails, setSelectedMails] = useState([]);
  const [patientValue, setPatientValue] = useState();
  const [selectedDate, setSelectedDate] = useState('');
  const [recordNo, setRecordNo] = useState('');
  const [filterChips, setFilterChips] = useState([]);
  const history = useHistory();

  const { members } = useSelector((state) => state.clinic);
  const isMobile = useSelector((state) => state.core.window.isMobile);
  const activeOnlyMembers = members?.filter((i) => activePractitionerStatuses.includes(Number(i.UserStatus)));
  const memberNamesSet = new Set(activeOnlyMembers?.map((item) => item.FullName));
  const memberNames = [...memberNamesSet];

  const [memberValue, setMemberValue] = useState('');
  const [memberItems, setMemberItems] = useState(memberNames);
  const selectedMember = members.find((item) => item.FullName === memberValue);
  const first = (notes.page - 1) * notes.pageSize;

  // Filter for Author
  useEffect(() => {
    if (selectedMember) {
      setQuery((prevState) => ({
        ...prevState,
        DoctorGuid: selectedMember.DoctorGuid
      }));
      //setFilterChips(prevChips => [...prevChips, { key: 'DoctorGuid', label: 'Author', value: selectedMember.FullName }]);
    }
  }, [selectedMember]);

  // Filter for date
  useEffect(() => {
    if (selectedDate) {
      setQuery((prevValue) => ({
        ...prevValue,
        NoteDate: moment(new Date(selectedDate)).format('YYYY/MM/DD')
      }));
      //setFilterChips(prevChips => [...prevChips, { key: 'NoteDate', label: 'Date', value: moment(new Date(selectedDate)).format('YYYY/MM/DD') }]);
    }
  }, [selectedDate]);

  // Filter for a patient
  useEffect(() => {
    if (patientValue && typeof patientValue === 'object') {
      setQuery((prevState) => ({
        ...prevState,
        PatientGuid: patientValue.PatientGuid
      }));
      //setFilterChips(prevChips => [...prevChips, { key: 'PatientGuid', label: 'Patient', value: patientValue.FullName }]);
    }
  }, [patientValue]);

  // Filter for a claim number
  useEffect(() => {
    if (recordNo) {
      setQuery((prevState) => ({
        ...prevState,
        ClaimNumber: recordNo
      }));
      //setFilterChips(prevChips => [...prevChips, { key: 'ClaimNumber', label: 'Record Id', value: recordNo }]);
    }
  }, [recordNo]);

  const onMemberSearch = (event) => {
    const query = event.query;
    if (query.trim() === '') {
      setMemberItems(memberNames);
    } else {
      const filteredMembers = memberNames.filter((member) => member.toLowerCase().includes(query.toLowerCase()));
      setMemberItems(filteredMembers);
    }
  };

  const debouncedRequest = useCallback(
    debounce((query) => onSearchAll(query), 1000),
    []
  );

  const onGlobalSearch = (e) => {
    setGlobalFilter(e.target.value);
    debouncedRequest(e.target.value);
  };

  const onClearGlobalSearch = () => {
    setGlobalFilter('');
    onSearchAll('');
  };

  const onRowSelect = async (note) => {
    onSelectRowCallback?.(note);
  };

  const onPage = (e) => {
    const page = (e.page ?? 0) + 1;
    fetchNotes?.({ page, pageSize: notes.pageSize });
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await Promise.all(selectedMails.map((mail) => onDeleteNote(mail.NoteGuid)));
      setIsLoading(false);
      fetchNotes();
      setShowDialog(false);
      setSelectedMails([]);
    } catch (error) {
      console.error('Error deleting notes:', error);
    }
  };

  const removeFilter = (filterKey) => {
    if (filterKey === 'NoteDate') setSelectedDate('');
    if (filterKey === 'DoctorGuid') setMemberValue('');
    if (filterKey === 'PatientGuid') setPatientValue(null);
    if (filterKey === 'ClaimNumber') setRecordNo('');
    const updatedFilters = { ...filters };
    delete updatedFilters[filterKey];
    setFilters(updatedFilters);
    setFilterChips(filterChips.filter((chip) => chip.key !== filterKey));
    setQuery((prevQuery) => {
      const updatedQuery = { ...prevQuery };
      delete updatedQuery[filterKey];
      return updatedQuery;
    });
    fetchNotes({ page: 1, query: { ...query, [filterKey]: '' } });
  };

  const rowActionButtonsMenuModel = (rowData) => {
    const actionProps = {
      rowData,
      history,
      setSelectedMails,
      onDelete: () => setShowDialog(true)
    };

    return rowActionsMenuItems(actionProps);
  };

  const rowActionButtons = (rowData) => {
    return <ActionButtons rowData={rowData} setSelectedRows={setSelectedMails} rowActionButtonsMenuModel={rowActionButtonsMenuModel} />;
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2 w-full">
      <div id="chipContainer" className="flex align-items-center gap-2">
        {selectedMails.length ? <Button onClick={() => setShowDialog(true)} icon="pi pi-trash" text rounded /> : null}
        <div className="flex flex-wrap align-items-center gap-2 overflow-auto">
          {filterChips.map((chip) => (
            <Chip key={chip.key} label={`${chip.label}: ${chip.value}`} className="min-w-max" removable onRemove={() => removeFilter(chip.key)} />
          ))}
        </div>
      </div>
      <span className={cx('p-input-icon-left', globalFilter ? 'p-input-icon-right' : '')}>
        <i className="pi pi-search" />
        <InputText autoFocus={!isMobile} value={globalFilter} onChange={onGlobalSearch} placeholder="Search notes" className="max-w-25rem w-20rem" />
        {globalFilter && <i className="pi pi-times pointer" onClick={onClearGlobalSearch} />}
      </span>
    </div>
  );

  const rowClassName = (rowData) => {
    return {
      'font-bold': rowData.Status === 0,
      'cursor-pointer': true
    };
  };

  return (
    <div className="memos-table-wrap">
      {(loading || isLoading) && <CircularProgress />}
      <DataTable
        lazy
        value={notes.notesList}
        tableStyle={{ minWidth: '50rem' }}
        size="small"
        scrollable
        scrollHeight="flex"
        filters={filters}
        rows={notes.pageSize}
        header={header}
        first={notes.first}
        filterDisplay="menu"
        selection={selectedMails}
        onSelectionChange={(e) => setSelectedMails(e.value)}
        rowClassName={rowClassName}
        emptyMessage={!notes.notesList?.length && loading ? 'Loading...' : 'No notes found'}
        footer={<TablePaginator first={first || 0} rows={notes.pageSize || 0} totalRecords={notes.totalRecords || 0} onPageChange={onPage} />}
      >
        <Column selectionMode="multiple" style={{ width: '4rem', padding: '10px 15px 10px 10px' }} />

        <Column
          className="memos-column-contet"
          field="Author"
          header="Author"
          style={{ minWidth: '150px', maxWidth: '150px' }}
          filterField="Author"
          filter
          body={(note) => authorBodyTemplate(note, onRowSelect)}
          onFilterClear={() => {
            setQuery((prevValue) => ({
              ...prevValue,
              DoctorGuid: ''
            }));
            setMemberValue('');
            setFilters((prevState) => ({
              ...prevState,
              Author: { value: '' }
            }));
            fetchNotes({ page: 1, query: { ...query, DoctorGuid: '' } });
            removeFilter('DoctorGuid');
          }}
          filterElement={(options) => authorRowFilterTemplate(memberValue, setMemberValue, memberItems, onMemberSearch, options)}
          onFilterApplyClick={(e) => {
            setFilters((prevState) => ({
              ...prevState,
              Author: { value: e.constraints.value }
            }));
            fetchNotes({ page: 1, query });
            setFilterChips((prevChips) => [...prevChips, { key: 'DoctorGuid', label: 'Author', value: selectedMember.FullName }]);
          }}
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
        />

        <Column
          className="memos-column-contet"
          field="Title"
          style={{ minWidth: '400px', maxWidth: '400px' }}
          body={(note) => titleBodyTemplate(note, onRowSelect)}
          header="Title"
        />

        <Column
          className="memos-column-contet"
          field="PatientName"
          header="Patient"
          style={{ minWidth: '180px', maxWidth: '180px' }}
          filterField="Patient"
          body={(note) => patientBodyTemplate(note, onRowSelect)}
          filter
          filterElement={(options) => patientFilterTemplate(patientValue, setPatientValue, options)}
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          onFilterClear={() => {
            setQuery((prevValue) => ({
              ...prevValue,
              PatientGuid: ''
            }));
            setFilters((prevState) => ({
              ...prevState,
              Patient: { value: '' }
            }));
            setPatientValue(null);
            fetchNotes({ page: 1, query: { ...query, PatientGuid: '' } });
            removeFilter('PatientGuid');
          }}
          onFilterApplyClick={(e) => {
            setFilters((prevState) => ({
              ...prevState,
              Patient: { value: e.constraints.value.FullName }
            }));
            fetchNotes({ page: 1, query });
            setFilterChips((prevChips) => [...prevChips, { key: 'PatientGuid', label: 'Patient', value: patientValue.FullName }]);
          }}
        />

        {inboxType === 'claim' && (
          <Column
            className="memos-column-contet"
            field="OfficeNumber"
            header="Record Id"
            body={(note) => officeNumBodyTemplate(note, onRowSelect)}
            style={{ minWidth: '110px', maxWidth: '110px' }}
            filter
            filterField="RecordId"
            showFilterMenuOptions={false}
            showFilterMatchModes={false}
            filterElement={(options) => recordNoFilterTemplate(recordNo, setRecordNo, options)}
            onFilterClear={() => {
              setQuery((prevValue) => ({
                ...prevValue,
                ClaimNumber: ''
              }));
              setFilters((prevState) => ({
                ...prevState,
                RecordId: { value: '' }
              }));
              setRecordNo('');
              fetchNotes({ page: 1, query: { ...query, ClaimNumber: '' } });
              removeFilter('ClaimNumber');
            }}
            onFilterApplyClick={(e) => {
              setFilters((prevState) => ({
                ...prevState,
                RecordId: { value: recordNo }
              }));
              fetchNotes({ page: 1, query });
              setFilterChips((prevChips) => [...prevChips, { key: 'ClaimNumber', label: 'Record Id', value: recordNo }]);
            }}
          />
        )}

        <Column
          className="memos-column-contet"
          field="NoteDate"
          dataType="date"
          style={{ minWidth: '165px', maxWidth: '165px' }}
          header="Date"
          body={(note) => dateBodyTemplate(note, onRowSelect)}
          filterField="Date"
          filter
          filterElement={(options) => dateFilterTemplate(selectedDate, setSelectedDate, options)}
          showFilterMenuOptions={false}
          showFilterMatchModes={false}
          onFilterClear={() => {
            setQuery((prevValue) => ({
              ...prevValue,
              NoteDate: ''
            }));
            setFilters((prevState) => ({
              ...prevState,
              Date: { value: '' }
            }));
            setSelectedDate('');
            fetchNotes({ page: 1, query: { ...query, NoteDate: '' } });
            removeFilter('NoteDate');
          }}
          onFilterApplyClick={(e) => {
            setFilters((prevState) => ({
              ...prevState,
              Date: { value: moment(new Date(selectedDate)).format('YYYY/MM/DD') }
            }));
            fetchNotes({ page: 1, query });
            setFilterChips((prevChips) => [
              ...prevChips,
              { key: 'NoteDate', label: 'Date', value: moment(new Date(selectedDate)).format('YYYY/MM/DD') }
            ]);
          }}
        />

        <Column body={rowActionButtons} alignFrozen="right" frozen />
      </DataTable>
      <DeleteDialog
        visible={showDialog}
        message="Do you really want to delete selected records?"
        onHide={() => setShowDialog(false)}
        accept={handleDelete}
      />
    </div>
  );
};

export default NoteTable;
