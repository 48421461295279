import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { checkIfStringIsDate } from '../../../utils/checkIfStringIsDate';
import { formatDate } from '../../../utils/formatDate';
import { showCompleteReport } from './helpers/showCompleteReport';
import { getEligibility, setEligibility } from '../../actions/patients.action.creators';
import moment from 'moment';
import { t } from '../../../../service/localization/i18n';
import { elementIDs } from '../../../config/elementIDsConfig';
import { Message } from 'primereact/message';
import { setClaimFilters } from '../../../claims/actions/claims.action.creators';
import { claimsDefaultFilters } from '../../../config/defaultValuesConfig';
import { routes } from '../../../../routes/routes';
import { isEmpty } from 'lodash';
import RadioButton from '../../../../components/PrimeReactComponents/RadioButton';

const NewEligibilityTable = ({
  eligibility,
  patient,
  showRefreshButton = false,
  getEligibilityCallback,
  nameFromEligibility,
  nameFromPicture,
  preferredName,
  handleNameChange
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user.details);
  const { isFetchingPatientEligibility, checkEligibilityOnly, checkNameAndEligibility } = useSelector((state) => state.patients);
  const disabled = !patient?.PHN || !patient?.BirthDay || checkEligibilityOnly || checkNameAndEligibility;
  const history = useHistory();
  const isMobile = useSelector((state) => state.core.window.isMobile);
  const errorEligibilityMessage = useSelector((state) => state?.core?.eligibilityError.error);

  const onRefresh = () => {
    const params = {
      PHN: patient?.PHN,
      DOB: patient?.BirthDay ? moment(patient?.BirthDay).format('MM-DD-YYYY') : null
    };
    dispatch(
      getEligibility(params, (responseData) => {
        if (getEligibilityCallback) return getEligibilityCallback(responseData.Parsed);
        dispatch(setEligibility(responseData.Parsed));
      })
    );
  };

  const tableHeader = (
    <div className="flex justify-content-between align-items-center">
      <span style={{ paddingRight: '5px' }}>{t('MSP_Eligibility_Report')}</span>
      {/* {errorToastMessage && errorToastMessage === 'First name or last name not found in the image. Please verify the autofill data.' && (
        <Message severity="warn" text={errorToastMessage} />
      )}
      {nameFromEligibility && nameFromPicture && nameFromEligibility !== nameFromPicture && (
        <Message severity="warn" text={'Label and Eligbility Report names differ. Please confirm correct spelling'} />    
      )} */}
    </div>
  );
  // Prepare data for the table
  const eligibilityData = [
    ...(nameFromEligibility && nameFromPicture && nameFromEligibility !== nameFromPicture
      ? [
          {
            label: 'Name from Report',
            value: nameFromEligibility
          }
        ]
      : []),
    {
      label: 'MSP Coverage',
      value:
        eligibility.ELIG_ON_DOS?.toUpperCase() === 'YES'
          ? `${t('Active_on')} ${formatDate(eligibility.CHECK_ON_DATE)}`
          : eligibility.ELIG_ON_DOS?.toUpperCase() === 'NO'
          ? `${t('Inactive_on')} ${formatDate(eligibility.CHECK_ON_DATE)}`
          : `${t('Undetermined_on')} ${formatDate(eligibility.CHECK_ON_DATE)}`,
      className:
        eligibility.ELIG_ON_DOS?.toUpperCase() === 'NO' ? 'p-error' : eligibility.ELIG_ON_DOS?.toUpperCase() === 'YES' ? '' : 'p-message-error'
    },
    ...(showCompleteReport(user?.Speciality)
      ? [
          {
            label: `$23.00`,
            value: eligibility.SERVICES_PAID ? `MSP visits paid-to-date: ${eligibility.SERVICES_PAID} out of 10` : eligibility.PATIENT_VISIT_CHARGE,
            className: eligibility.SERVICES_PAID
              ? eligibility.SERVICES_PAID === 10
                ? 'p-error' // Apply p-error class when SERVICES_PAID is 10
                : ''
              : 'p-message-error' // Apply p-message-error class when PATIENT_VISIT_CHARGE is set
          },
          {
            label: t('Eye_Exam').toUpperCase(),
            value: checkIfStringIsDate(eligibility.DATE_OF_LAST_EYE_EXAM)
              ? `${t('Date_of_last_eye_exam')}: ${formatDate(eligibility.DATE_OF_LAST_EYE_EXAM)}`
              : eligibility.DATE_OF_LAST_EYE_EXAM?.replaceAll("''", "'")
          }
        ]
      : []),
    {
      label: 'Premium Assistance ($23)',
      value: eligibility.SERVICES_PAID ? `MSP visits paid-to-date: ${eligibility.SERVICES_PAID} out of 10` : eligibility.PATIENT_VISIT_CHARGE,
      className: eligibility.SERVICES_PAID
        ? eligibility.SERVICES_PAID === 10
          ? 'p-error' // Apply p-error class for SERVICES_PAID = 10
          : ''
        : eligibility.PATIENT_VISIT_CHARGE?.toUpperCase() === 'THIS IS NOT AN INSURED BENEFIT'
        ? 'p-error' // Apply p-error class for "THIS IS NOT AN INSURED BENEFIT"
        : 'p-message-error' // Apply p-message-error class for other cases
    },
    ...(eligibility.DATE_OF_LAST_EYE_EXAM
      ? [
          {
            label: t('Eye_Exam').toUpperCase(),
            value: checkIfStringIsDate(eligibility.DATE_OF_LAST_EYE_EXAM)
              ? `${t('Date_of_last_eye_exam')}: ${formatDate(eligibility.DATE_OF_LAST_EYE_EXAM)}`
              : eligibility.DATE_OF_LAST_EYE_EXAM?.replaceAll("''", "'")
          }
        ]
      : []),
    ...(eligibility.PATIENT_RESTRICTION
      ? [
          {
            label: t('Patient_Restriction'),
            value: eligibility.PATIENT_RESTRICTION
          }
        ]
      : []),
    ...(eligibility.MESSAGE
      ? [
          {
            label: t('Message'),
            value: eligibility.MESSAGE
          }
        ]
      : [])
  ];

  const simplifiedErrorData = [
    {
      label: 'MSP Coverage',
      value: `Report not available. ${errorEligibilityMessage}`,
      className: 'p-error' // Class for red text
    }
  ];

  const handleOpenPatientProfile = (patientGuid) => {
    console.log('patientGuid', patientGuid);
    dispatch(setClaimFilters(claimsDefaultFilters));
    return history.replace(`${routes.patientDetails.path}/${patientGuid}`);
  };

  useEffect(() => {
    console.log('Eligibility Data:', eligibility);
  }, [eligibility]);

  return (
    <div>
      {Object.keys(eligibility).length === 0 && errorEligibilityMessage !== null ? (
        <div className="">
          {/* Show simplified table with error message */}
          <DataTable
            value={simplifiedErrorData}
            header={tableHeader}
            showHeaders={false}
            rowClassName={(data) => ({
              'p-error': data.className === 'p-error' // Highlight rows with 'p-error' class
            })}
            style={{ padding: '0px', marginTop: '1rem' }}
          >
            <Column field="label" style={{ minWidth: '90px', width: window.innerWidth <= 590 ? '100px' : '200px' }} />
            <Column
              field="value"
              style={{ minWidth: '152px', flexShrink: 0 }}
              body={(rowData) => <span className={rowData.className || ''}>{rowData.value}</span>}
            />
          </DataTable>
        </div>
      ) : !isEmpty(eligibility) ? (
        <>
          <DataTable
            value={eligibilityData}
            header={tableHeader}
            showHeaders={false}
            rowClassName={(data) => ({
              'p-error': data.value && data.value.includes('p-error') // Only check includes if value exists
            })}
            style={{ padding: '0px', width: '100%' }}
          >
            <Column field="label" style={{ minWidth: '90px', width: window.innerWidth <= 590 ? '90px' : '200px' }} />
            <Column
              field="value"
              style={{ minWidth: '160px', flex: '1 1 auto !important' }}
              body={(rowData) => {
                if (rowData.label === 'Name from Report') {
                  return preferredName === nameFromEligibility ? (
                    <Button
                      className="p-button-link"
                      label={rowData.value}
                      onClick={() => handleOpenPatientProfile(patient?.PatientGuid)}
                      tooltip="Open Patient Profile"
                      style={{
                        padding: '0', // Remove button padding
                        margin: '0', // Remove button margin
                        textAlign: 'left' // Ensure alignment is consistent
                      }}
                    />
                  ) : (
                    <div
                      className=""
                      style={{
                        display: 'flex', // Inline layout
                        alignItems: 'center', // Vertically align container items
                        gap: '1px' // Minimal spacing between elements
                      }}
                    >
                      <span
                        className=""
                        style={{
                          alignItems: 'center', // Aligns text and button vertically
                          gap: '5px' // Adds some space between the text and button
                        }}
                      >
                        {rowData.value}
                        {/* <Button
                            //   label={isMobile ? "Select" : "Select Spelling"}
                            className="p-button-rounded p-button-text"
                            tooltip="Click to select this spelling"
                            onClick={() => handleNameChange(rowData.value)}
                            style={{
                                paddingTop: '0', // Adjust padding to fit with text
                                paddingLeft: '5px', // Adjust padding to fit with text
                                paddingBottom: '3px', // Adjust padding to fit with text
                                margin: '0', // Remove additional margin
                                verticalAlign: 'middle', // Align button with the middle of the text
                            }}
                            /> */}
                      </span>
                    </div>
                  );
                }

                return <span className={rowData?.className || ''}>{rowData.value}</span>; // Default rendering for other fields
              }}
            />
            {nameFromEligibility && nameFromPicture && nameFromEligibility !== nameFromPicture && (
              <Column
                header="Action"
                body={(rowData) =>
                  rowData.label === 'Name from Report' ? (
                    <>
                      {/* <Button
                                // label={isMobile ? "Select" : "Select Spelling"}
                                    // icon = 'pi pi-circle'
                                    text
                                className="p-button-rounded"
                                style={{
                                    fontSize: '2rem !important', // Adjust icon size
                                  }}
                                tooltip="Click to select this spelling"
                                onClick={() => handleNameChange(rowData.value)}
                            >      <i
                            className='pi pi-circle' // Retaining color via class
                            style={{
                              fontSize: '2.25rem',
                              marginRight: '1rem', // Space between icon and message
                              display: 'flex',
                              alignItems: 'center', // Ensures the icon is vertically centered within its container
                            }}
                          /></Button> */}
                      <RadioButton
                        inputId="nameFromEligibility"
                        value={nameFromEligibility}
                        checked={preferredName === nameFromEligibility}
                        onChange={(e) => handleNameChange(e.value)}
                        style={{ marginRight: '10px' }}
                        tooltip={preferredName === nameFromEligibility ? 'Selected spelling' : 'Select this spelling'}
                        tooltipOptions={{ position: 'top' }}
                      />
                    </>
                  ) : null
                }
                style={{
                  // position: 'sticky',
                  right: 0,
                  backgroundColor: '#fff', // Prevent overlapping content
                  zIndex: 1, // Ensure the sticky column is on top
                  maxWidth: '50px',
                  minWidth: '50px',
                  width: '50px',
                  textAlign: 'center',
                  flex: '0 0 50px !important'
                }}
              />
            )}
            {/* {nameFromEligibility &&
              nameFromPicture &&
              nameFromEligibility !== nameFromPicture && preferredName === nameFromEligibility && (
                <Column
                  header="Action"
                  body={(rowData) =>
                    rowData.label === 'Name from Report' ? (
                      <Button
                        icon="pi pi-check"
                        disabled
                        className="p-button-rounded"
                        style={{ pointerEvents: 'none' }}          
                      />
                    ) : null
                  }
                  style={{
                    position: 'sticky',
                    right: 5,
                    backgroundColor: '#fff', // Prevent overlapping content
                    zIndex: 1, // Ensure the sticky column is on top
                    maxWidth: '50px',
                    minWidth: '50px',
                    width: '50px',
                    textAlign: 'center',
                }}
               />
              )} */}
          </DataTable>
        </>
      ) : null}
    </div>
  );
};

export default NewEligibilityTable;
