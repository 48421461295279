import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import Chips from './Chips';

import classes from './Table.module.scss';
import cx from 'classnames';

const TableHeaderDesktop = (props) => {
  const rootWrapperRef = useRef(null);
  const defaultModeContentRef = useRef(null);
  const { isMobile } = useSelector((state) => state.core.window);

  const onGlobalSearch = (e) => {
    props.globalSearch.onChange(e);
  };

  const defaultModeContent = (
    <>
      <div className="flex" ref={defaultModeContentRef}>
        {props.globalSearch.display && (
          <span className={cx('p-input-icon-left', props?.globalFilter ? 'p-input-icon-right' : '')}>
            <i className="pi pi-search" />
            <InputText
              // type="search" // not support in Firefox
              className={classes.searchDesktop}
              autoFocus={!isMobile}
              value={props.globalFilter}
              placeholder={props.globalSearch.placeholder}
              onChange={onGlobalSearch}
            />
            {props?.globalFilter && <i className="pi pi-times pointer" onClick={() => onGlobalSearch({ target: { value: '' } })} />}
          </span>
        )}

        {props.addNewButton.display && (
          <Button
            className={cx('ml-3', props.tabletBreakpoint ? 'p-button-rounded' : '', classes.addNewButtonDesktop)}
            type="button"
            label={props.tabletBreakpoint ? '' : props.addNewButton.label}
            icon="pi pi-plus"
            onClick={props.addNewButton.onClick}
          />
        )}

        {props.groupButton?.display && (
          <Button
            className={cx('ml-3', props.tabletBreakpoint ? 'p-button-rounded' : '', classes.addNewButtonDesktop)}
            type="button"
            outlined
            label={props.tabletBreakpoint ? '' : props.groupButton.label}
            icon="pi pi-pencil"
            onClick={props.groupButton.onClick}
          />
        )}

        {/* More options button */}
        {!props.isMultipleSelect && props.moreOptionsButton}
      </div>

      {props.filters?.length > 0 && (
        <div className={cx('ml-5', classes.dataTableChipsWrap)}>
          <Chips
            filters={props.filters}
            onChipRemove={props.onChipRemove}
            clearAllFilters={props.clearAllFilters}
            rootWidth={rootWrapperRef?.current?.clientWidth}
            defaultModeContentWidth={defaultModeContentRef?.current?.clientWidth}
          />
        </div>
      )}
    </>
  );

  return (
    <div className={classes.tableHeaderDesktop} ref={rootWrapperRef}>
      {props.isMultipleSelect ? props.selectModeTemplate : defaultModeContent}
    </div>
  );
};

export default TableHeaderDesktop;
