const numberCollection = [
  { number: 1, value: 'first' },
  { number: 2, value: 'second' },
  { number: 3, value: 'third' },
  { number: 4, value: 'fourth' },
  { number: 5, value: 'fifth' },
  { number: 6, value: 'sixth' },
  { number: 7, value: 'seventh' },
  { number: 8, value: 'eighth' },
  { number: 9, value: 'ninth' },
  { number: 10, value: 'tenth' },
  { number: 11, value: 'eleventh' },
  { number: 12, value: 'twelfth' },
  { number: 13, value: 'thirteenth' },
  { number: 14, value: 'fourteenth' },
  { number: 15, value: 'fifteenth' },
  { number: 16, value: 'sixteenth' },
  { number: 17, value: 'seventeenth' },
  { number: 18, value: 'eighteenth' },
  { number: 19, value: 'nineteenth' },
  { number: 20, value: 'twentieth' },
  { number: 21, value: 'twenty-first' },
  { number: 22, value: 'twenty-second' },
  { number: 23, value: 'twenty-third' },
  { number: 24, value: 'twenty-fourth' },
  { number: 25, value: 'twenty-fifth' },
  { number: 26, value: 'twenty-sixth' },
  { number: 27, value: 'twenty-seventh' },
  { number: 28, value: 'twenty-eighth' },
  { number: 29, value: 'twenty-ninth' },
  { number: 30, value: 'thirtieth' }
];

export const practitionerSerialNumber = (number) => {
  const serialNumber = numberCollection.find((item) => item.number === number);
  return serialNumber ? serialNumber.value : number;
};
