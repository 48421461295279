import moment from 'moment';
import { formatDate } from '../../utils/formatDate';
import { formatPHN } from '../../utils/formatPHN';
import { formatZipCode } from '../../utils/formatZipCode';
import { getAge } from '../../utils/getAge';
import { defaultGuid } from '../../config/defaultValuesConfig';
import { isEmpty } from 'lodash';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { canadaProvincesListWithInstituonal } from '../../config/dropdownListsConfig';

export const formatPatientDetails = (item, index) => {
  if (!item) return item;

  const age = getAge(item?.BirthDay);
  const gender =
  item?.Sex === 'M' || item?.Sex === 'F' || item?.Sex === 'U'
    ? item?.Sex
    : item?.Sex?.toLowerCase() === 'male' || item?.Sex?.toLowerCase() === 'female' || item?.Sex?.toLowerCase() === 'unknown'
    ? item?.Sex?.charAt(0)?.toUpperCase()
    : 'U'; // Default to 'U' if gender is unknown


  item.PHN = formatPHN(item?.PHN, item?.PHNProvince);
  item.LastVisit = item?.LastVisit ? formatDate(item?.LastVisit, 'YYYY, D MMM') : item?.LastVisit;
  item.NextVisit = item?.NextVisit ? formatDate(item?.NextVisit, 'YYYY, D MMM') : item?.NextVisit;
  item.LastPrivateGuid = item?.LastPrivateGuid || defaultGuid;
  item.BirthDay = new Date(item?.BirthDay);
  item.DOBText = formatDate(item?.BirthDay);
  item.index = index || 0;
  item.Age = age;
  item.Sex = gender;
  item.FirstName = item?.FirstName?.toUpperCase();
  item.LastName = item?.LastName?.toUpperCase();
  item.MidName = item?.MidName?.toUpperCase();
  item.FullAddress = item?.AddressLine
    ? `${item?.AddressLine} ${item?.City} ${item?.Province} ${item?.Zip}`
    : item?.City && item?.Province && item?.Zip
    ? `${item?.City} ${item?.Province} ${item?.Zip}`
    : item?.City && item?.Province
    ? `${item?.City} ${item?.Province}`
    : '';
  item.Summary = `${item?.LastName}, ${item?.FirstName} (${age}${gender})`;
  item.Phone = item?.Phone ? formatPhoneNumber(item?.Phone) : item?.Phone;
  item.ZipFormatted = item?.Zip ? formatZipCode(item?.Zip, item?.Country) : '';
  item.Eligibility =
    item?.Eligibility && !isEmpty(item?.Eligibility)
      ? {
          ...item?.Eligibility,
          Parsed: {
            ...item?.Eligibility?.Parsed,
            CHECK_ON_DATE_TEXT: moment(new Date(item?.Eligibility?.Parsed.CHECK_ON_DATE)).format('MM/DD/YYYY')
          },
          ErrorMessage: item?.Eligibility?.ErrorMessage ? { error: item?.Eligibility?.ErrorMessage } : item?.Eligibility?.ErrorMessage
        }
      : {};
  if (item.PHN) {
    const reciprocal = item?.PHN?.substring(0, 2);
    const suspect = canadaProvincesListWithInstituonal?.find((x) => item?.value === reciprocal);
    item.Payor = suspect ? suspect?.value : item?.Province;
    item.PHN = item?.PHN?.replace(item.Payor, '');
  }

  return item;
};
