import React, { useEffect, useState, useMemo } from 'react';
import { Timeline } from 'primereact/timeline';
import CommonCard from '../../../common/components/CommonCard';
import TimelineContent from './TimelineContent';
import { getCloseOffDays } from '../../../../service/Lookup';
import { TIMEZONES } from '../../../config/enums';
import { isDevelopment } from '../../../../config';
import moment from 'moment-timezone';
import cx from 'classnames';

import './CloseOff.scss';

const CloseOffCard = () => {
  const PST_TIMEZONE = TIMEZONES.PST;
  const hours = 19;
  const closeOffDeadline = useMemo(() => ({ hours, minutes: 0, seconds: 0 }), [hours]);

  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentDate, setCurrentDate] = useState(moment().tz(PST_TIMEZONE).startOf('day'));

  const labels = {
    C: 'Close-off',
    R: 'Remittance',
    P: 'Payment'
  };

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getCloseOffDays();
      if (response) setDates(response);
    } catch (err) {
      setError('Failed to fetch Teleplan Cut-Off Dates');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const checkIsCloseOff = (item) => item.value === 'C';

  const value = useMemo(() => {
    let currentTimeline = [];
    let nextTimeline = [];

    if (!dates) return { currentTimeline, nextTimeline };

    // Group dates into cycles of three (C, R, P)
    const cycles = [];
    if (dates && dates.length > 0) {
      for (let i = 0; i < dates.length; i += 3) {
        const cycle = dates.slice(i, i + 3);
        cycles.push(cycle);
      }
    }
    // Get current and next months
    const currentMonth = currentDate.month();
    const nextMonth = currentDate.clone().add(1, 'month').month();
    const nextMonthYear = currentDate.clone().add(1, 'month').year();

    // Filter cycles by month and year
    const currentMonthCycles = cycles.filter((cycle) => moment(cycle[0].date).tz(PST_TIMEZONE).month() === currentMonth);
    const nextMonthCycles = cycles.filter(
      (cycle) => moment(cycle[0].date).tz(PST_TIMEZONE).month() === nextMonth && moment(cycle[0].date).tz(PST_TIMEZONE).year() === nextMonthYear
    );

    // Check if the first timeline is fully in the past
    const isFirstTimelinePassed =
      currentMonthCycles.length > 0 && currentMonthCycles[0].every((item) => moment(item.date).tz(PST_TIMEZONE).isBefore(currentDate, 'day'));

    if (isFirstTimelinePassed) {
      // If the first timeline has passed, shift timelines
      currentTimeline = currentMonthCycles[1] || [];
      nextTimeline = nextMonthCycles[0] || [];
    } else {
      // Normal behavior
      if (currentMonthCycles.length >= 2) {
        const firstCycle = currentMonthCycles[0];
        const secondCycle = currentMonthCycles[1];
        const remittanceDate = moment(secondCycle[1].date).tz(PST_TIMEZONE).startOf('day');

        if (currentDate.isBefore(remittanceDate, 'day')) {
          currentTimeline = firstCycle;
          nextTimeline = secondCycle;
        } else {
          currentTimeline = secondCycle;
          nextTimeline = nextMonthCycles.length > 0 ? nextMonthCycles[0] : [];
        }
      } else if (currentMonthCycles.length === 1) {
        currentTimeline = currentMonthCycles[0];
        nextTimeline = nextMonthCycles.length > 0 ? nextMonthCycles[0] : [];
      } else {
        currentTimeline = nextMonthCycles.length > 0 ? nextMonthCycles[0] : [];
        nextTimeline = nextMonthCycles.length > 1 ? nextMonthCycles[1] : [];
      }
    }

    // Combine and process dates
    const allDates = [...currentTimeline, ...nextTimeline];
    let firstUpcomingDateFound = false;

    allDates.forEach((item) => {
      const itemDate = moment(item.date).startOf('day');
      const isBefore = itemDate.isBefore(currentDate, 'day');
      const isSameDay = itemDate.isSame(currentDate, 'day');

      item.isBefore = isBefore;
      const isCloseOff = checkIsCloseOff(item);
      const isAfterSevenPM = moment().isAfter(moment().set(closeOffDeadline));

      // Updated condition: Only mark as checked if strictly before current day
      if (isCloseOff && isAfterSevenPM && isSameDay) {
        item.icon = 'pi pi-circle';
        item.iconClassName = 'custom-marker-upcoming';
        firstUpcomingDateFound = true;
      } else if (isBefore) {
        item.icon = 'pi pi-check';
        item.iconClassName = 'custom-marker-success';
      } else if (!firstUpcomingDateFound) {
        item.icon = 'pi pi-circle';
        item.iconClassName = 'custom-marker-upcoming';
        firstUpcomingDateFound = true;
      } else {
        item.icon = '';
        item.iconClassName = 'custom-marker-disable';
      }
    });

    // Calculate days away for the first upcoming date
    const futureDates = allDates.filter((item) => !item.isBefore);
    if (futureDates.length > 0) {
      const daysAway = Math.ceil(moment.duration(moment(futureDates[0].date).startOf('day').diff(currentDate)).asDays());
      futureDates[0].daysAway = daysAway;
    }

    return {
      currentTimeline,
      nextTimeline
    };
  }, [dates, currentDate, closeOffDeadline]);

  const adjustCurrentDate = (days) => {
    setCurrentDate((prev) => prev.clone().add(days, 'days'));
  };

  const opposite = (item) => labels[item.value];

  const content = (item) => (
    <TimelineContent
      item={item}
      isCloseOff={checkIsCloseOff(item)}
      closeOffDeadline={closeOffDeadline}
      currentDate={currentDate}
      hours={hours}
      loading={loading}
    />
  );

  const marker = (item) => (
    <span className={item.iconClassName}>
      <i className={cx(item.icon, 'text-sm')} />
    </span>
  );

  if (error) {
    return (
      <CommonCard title="Teleplan Cut-Off Dates">
        <div className="p-error text-center text-lg">{error}</div>
      </CommonCard>
    );
  }

  return (
    <CommonCard title="Teleplan Cut-Off Dates">
      {/* {isDevelopment && (
        <div className="flex gap-2 mb-4">
          <button onClick={() => adjustCurrentDate(-1)}>Move Current Date -1 Day</button>
          <button onClick={() => adjustCurrentDate(1)}>Move Current Date +1 Day</button>
          <p>Current Date: {currentDate.format('MMM D, YYYY')}</p>
        </div>
      )} */}

      <div className="flex flex-column gap-5 sm:gap-3 mb-4">
        {['currentTimeline', 'nextTimeline'].map((part) => (
          <Timeline
            key={part}
            className="closeOffTimeline"
            value={value[part]}
            layout="horizontal"
            align="top"
            content={content}
            opposite={opposite}
            marker={marker}
          />
        ))}
      </div>
    </CommonCard>
  );
};

export default CloseOffCard;
