import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { teleplanStatusesLables } from '../../../modules/claims/helpers/mapper';
import { getEveryFirstLetterOfString } from '../../../modules/utils/getEveryFirstLetterOfString';
import cx from 'classnames';
import './ClaimStatusAvatar.scss';

const ClaimStatusAvatar = ({ status, statusText, className = '', style }) => {
  const formattedStatusText = () => {
    if (statusText?.toUpperCase() === teleplanStatusesLables.accepted?.toUpperCase()) return 'A';
    if (statusText?.toUpperCase() === teleplanStatusesLables.draft?.toUpperCase()) return 'DR';
    if (statusText?.toUpperCase() === teleplanStatusesLables.rebilled?.toUpperCase()) return 'RB';
    if (Number(status) === 199) return 'P-';
    if (Number(status) === 200) return 'P+';
    return getEveryFirstLetterOfString(statusText);
  };

  const tooltip = statusText === teleplanStatusesLables.accepted ? teleplanStatusesLables.paid : statusText;

  return (
    <>
      <Tooltip target=".claimStatusAvatarTooltip" position="top" />
      <div
        className={cx('claimStatusAvatarTooltip pointer', 'claim_status_avatar', `cm_status_${status}`, className)}
        data-pr-tooltip={tooltip}
        style={style ? style : { width: '2.4em', minWidth: '2.4em', height: '2.4em', zIndex: '0' }}
      >
        <span className="claim_status_avatar_content">{formattedStatusText()}</span>
      </div>
    </>
  );
};

export default ClaimStatusAvatar;
