import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { useActionButtons } from '../../hooks/useActionButtons';
import { steps } from '../../helpers/steps';
import { t } from '../../../../../../service/localization/i18n';

const RecordsActionButtons = () => {
  const history = useHistory();
  const { isMobile } = useSelector((state) => state.core.window);
  const { isSavingClaims } = useSelector((state) => state.claims);
  const { isCreatingPatient } = useSelector((state) => state.patients);
  const { localState, setLocalState } = useFormContext();

  const { onReview, onReviewCreate: onCreate } = useActionButtons();

  const onBack = () => {
    localState.isStep2Dirty
      ? setLocalState((prevState) => ({ ...prevState, recordsTableChangesDialog: true }))
      : setLocalState((prevState) => ({ ...prevState, step: steps.form, recordsRequiredInputs: {} }));
  };

  const createActionButtons = () => {
    if (!localState.records?.length) return null;

    const items = [
      { label: t('Create'), command: () => onCreate() },
      { label: t('CreateAndBill'), command: () => onCreate({ saveAndClose: true }) }
    ];

    return (
      <>
        <div className="flex sm:hidden">
          <SplitButton
            type="button"
            className="recordsDataTableActionButtons min-w-max max-w-10rem w-full md:w-10rem"
            label={t('Review')}
            onClick={onReview}
            model={items}
          />
        </div>

        <div className="hidden sm:flex gap-2">
          <Button
            id={elementIDs.reviewButton}
            type="button"
            className="recordsDataTableActionButtons min-w-max max-w-10rem w-full md:w-10rem"
            label={t('Review')}
            onClick={onReview}
          />
          <Button
            id={elementIDs.create}
            type="button"
            className="p-button-outlined recordsDataTableActionButtons min-w-max max-w-10rem w-full md:w-auto"
            loading={isSavingClaims}
            label={t('Create')}
            disabled={isCreatingPatient}
            tooltip={!isMobile && t('Save_your_claim_without_submitting_it')}
            tooltipOptions={{ position: 'top' }}
            onClick={onCreate}
          />
          <Button
            id={elementIDs.create}
            type="button"
            className="p-button-outlined recordsDataTableActionButtons min-w-max max-w-10rem w-full md:w-auto"
            loading={isSavingClaims}
            label={t('CreateAndBill')}
            disabled={isCreatingPatient}
            tooltip={!isMobile && t('Save_your_claim_without_submitting_it')}
            tooltipOptions={{ position: 'top' }}
            onClick={() => onCreate({ saveAndClose: true })}
          />
        </div>
      </>
    );
  };

  return (
    <div className="flex gap-2 justify-content-center">
      {createActionButtons()}

      <Button
        id={elementIDs.back}
        type="button"
        className="p-button-outlined recordsDataTableActionButtons min-w-max max-w-10rem w-full md:w-auto"
        label={t('Back')}
        onClick={onBack}
      />

      <Button
        id={elementIDs.cancel}
        type="button"
        className="p-button-outlined recordsDataTableActionButtons min-w-max max-w-10rem w-full md:w-auto"
        label={t('Cancel')}
        onClick={() => history.goBack()}
      />
    </div>
  );
};

export default RecordsActionButtons;
