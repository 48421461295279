import React from 'react';
import { InputMask } from 'primereact/inputmask';
import { parseMilitaryTime } from '../../../modules/utils/formatTime';
import cx from 'classnames';
import './TimeInput.scss';

// interface ITimeInput {
//   name: string,
//   value: string // time format should be "HH:mm"
//   onChange: (time) => void,

//   ref?: ref
//   id?: string,
//   className?: string,
//   disabled?: boolean,
//   isInvalid?: boolean,
// }

const TimeInput = React.forwardRef(({ name, value, isInvalid, id, disabled, onChange, className = '' }, ref) => {
  return (
    <span className="p-input-icon-right w-full">
      <InputMask
        id={id || name}
        ref={ref}
        className={cx(className, { 'p-invalid': isInvalid, disabled_time_input: disabled })}
        style={{ width: 'inherit' }}
        type="text"
        inputMode="numeric"
        value={value}
        mask="99:99"
        placeholder="HH:MM"
        disabled={disabled}
        onChange={(e) => onChange(e.value)}
      />
      {value && !disabled && <i className="pi pi-times pointer" onClick={() => onChange('')} />}
    </span>
  );
});

export default TimeInput;
