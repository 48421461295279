import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'primereact/button';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { useSelector } from 'react-redux';
import AuthTitle from '../Layouts/Auth/AuthTitle';
import { contentfulOptions } from '../../modules/contentful/contentfulOptions';

import { routes } from '../../routes/routes';
import { resetState } from '../../store';
import Link from '../Buttons/Link/Link';

const PrivacyAccept = ({ userName, email }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const terms = useSelector((state) => state.user.termsOfUse);

  const goBack = () => {
    dispatch(resetState()); // Reset redux state
    history.replace(routes.registration.path);
  };

  return (
    <div id="privacy-body" className="flex flex-column justify-content-center align-items-center text-800 " style={{ background: '#e6e6e6' }}>
      <div className="px-6 pt-6 my-6" style={{ maxWidth: '800px', background: 'white' }}>
        <AuthTitle />
        <div className="flex w-9 pb-4 justify-content-center mx-auto mt-4">
          <img src="/assets/layout/images/logo-cm-v5.png" alt="logo" style={{ height: '150px' }} />
        </div>
        <p className="mt-4 mb-6">
          <b className="text-2xl ">Welcome to Claim Manager, Dear {userName}!</b>
        </p>
        <div className="mb-4">
          {terms?.items
            ?.filter((term) => term.fields.id === 'accept-letter')
            .map((term, index) => (
              <div key={index}>{documentToReactComponents(term.fields.content, contentfulOptions())}</div>
            ))}
          <div className="flex gap-8 aling-items-center justify-content-center w-full my-4">
            <Button className="px-0" label="Go back to the registration page" type="button" link onClick={goBack} />

            <Link href="https://www.claimmanager.ca/">
              <Button className="px-0" link label="Visit our website" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyAccept;
