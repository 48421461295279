import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../Inputs';

import { phone_types } from '../../../../modules/config/constants';
import { t } from '../../../../service/localization/i18n';
import { inputWrapperStyles } from '../helpers/styles';

const PhoneType = () => {
  const name = 'PhoneType';
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const options = phone_types.map((type) => ({ name: type.label, code: type.value }));
        const value = options.find((type) => type.code === field.value);
        return (
          <InputWrapper name={name} label={t('Phone Type')} style={inputWrapperStyles}>
            <Dropdown value={value} optionLabel="name" options={options} onChange={(e) => field.onChange(e.value.code)} />
          </InputWrapper>
        );
      }}
    />
  );
};

export default PhoneType;
