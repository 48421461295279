import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../Inputs';
import { representativeStatus } from '../../../modules/config/defaultValuesConfig';
import { dropdownScrollHeight } from '../../../modules/config/stylesConfig';
import { billToOptions, billToOptionTypes } from './constants';
import { t } from '../../../service/localization/i18n';

const Payer = ({ onChange }) => {
  const name = 'Payer';
  const { control, getValues, setValue } = useFormContext();

  const handleChange = (e) => {
    const newPayer = e.value;
    // Retrieve the current form state
    const currentFormState = getValues('formState');

    // Determine whether the selected payer is Patient
    const isPayerPatient = newPayer === billToOptionTypes.PATIENT;

    // Create an updated form state including the payer flag
    const updatedFormState = {
      ...currentFormState,
      isPayerPatient
    };

    // Select the values to update based on the payer type
    const valuesToUpdate = isPayerPatient ? updatedFormState.patient : updatedFormState.representative;

    // Update each field in the chosen group
    Object.entries(valuesToUpdate).forEach(([key, value]) => {
      setValue(key, value);
    });

    // If the payer is Representative, update the status accordingly
    if (newPayer === billToOptionTypes.REPRESENTATIVE) {
      setValue('Status', representativeStatus);
    }

    // // Update BillTo
    // onBillToUpdate([valuesToUpdate]);

    onChange?.(valuesToUpdate);

    // Update the Payer field
    setValue(name, newPayer, { shouldDirty: currentFormState.isNew }); // Make form dirty if it's a new representative
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper name={name} label={t('Payer')} style={{ height: 'auto', minHeight: '75px', width: '100%' }}>
          <Dropdown autoFocus value={field.value} scrollHeight={dropdownScrollHeight()} options={billToOptions} onChange={handleChange} />
        </InputWrapper>
      )}
    />
  );
};

export default Payer;
