import { commonInputs } from '../../../../config/commonInputsConfig';
import { defaultCountryCode, defaultProvinceCode } from '../../../../config/defaultValuesConfig';
import { integersOnlyCheckForString } from '../../../../regex/regex';
import { initialEmailType, initialPhoneType, initialAddressType } from './initialValuesForPatientForm';

export const newPatientBlankRecord = (DCNGuid, initPHNValue) => {
  const isPHNNumbersOnly = integersOnlyCheckForString.test(initPHNValue);

  return {
    [commonInputs.accessWord.name]: '', // Access Word
    AddressLine: '',
    AddressType: initialAddressType,
    Country: defaultCountryCode, // Canada
    Age: '',
    AptNo: '',
    BirthDay: null,
    NewbornBD: null,
    IsNewborn: false,
    City: '',
    DCNGuid,
    DOB: null,
    DOBText: '', //1960, Jan 31
    Email: '',
    EmailType: initialEmailType,
    FirstName: '',
    LastName: isPHNNumbersOnly ? '' : initPHNValue?.toUpperCase(),
    MidName: '',
    FullName: '',
    [commonInputs.nickname.name]: '', // Nickname
    HouseNo: '',
    MSP4: null,
    PHN: isPHNNumbersOnly ? initPHNValue : '',
    PHNProvince: defaultProvinceCode,
    PatientGuid: DCNGuid,
    Payor: '',
    Phone: '',
    Phone2: null,
    Phone3: null,
    PhoneType: initialPhoneType,
    PhoneType2: null,
    PhoneType3: null,
    Province: defaultProvinceCode,
    Sex: '',
    Status: 0,
    Street: '',
    Summary: '',
    Zip: ''
  };
};
