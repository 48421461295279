import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { devSignIn, signIn, updateUser } from '../../../actions/auth.actions.creators';
import { sendPhoneCodeAuth } from '../../../../../service/Lookup';
import { localStorageKeys } from '../../../../config/localStorageKeysConfig';
import { routes } from '../../../../../routes/routes';
import { useAuth } from '../../../../../AuthProvider';
import { expiredPasswordStatus, userLockedStatus, expiredTemporaryPassword } from '../../../../config/userStatuses';
import { showPin } from '../../../../utils/pinCode';
import { useAuthVerifications } from '../../../hooks/useAuthVerifications';

export const useSubmit = ({ signInState, setSignInState, setError }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { login } = useAuth();
  const { noSecretQuestions, phoneNotVerified, termsNotAccepted, needMFA } = useAuthVerifications();

  const onSubmit = (data) => {
    handleSignIn(data);

    // if (process.env.REACT_APP_CYPRESS) {
    //   if (data.username === 'demo') {
    //     // Use "demo" account for all cypress tests
    //     developmentSignIn(data);
    //   } else {
    //     // If not "demo". Need for log in cypress tests
    //     handleSignIn(data);
    //   }
    // } else {
    //   // Normal sign in
    //   handleSignIn(data);
    // }
  };

  const handleSignIn = async (data) => {
    try {
      // Save username to local storage
      // WIN-599 - remember login name
      if (data.signed) localStorage.setItem(localStorageKeys.username, data.username);

      const dataForSubmit = {
        username: signInState.step === 2 ? signInState.impersonateKey : data.username,
        password: data.password,
        step: signInState.step
      };

      // Show loading
      setSignInState((prevState) => ({ ...prevState, isSubmitting: true }));

      const user = await signIn(dataForSubmit);
      // Hide loading
      setSignInState((prevState) => ({ ...prevState, isSubmitting: false }));

      if (user) {
        // Save user data to redux to use ImpersonateKey in HOK for PasswordRecovery Component
        dispatch(updateUser(user));

        if (user.UserStatus === expiredTemporaryPassword) {
          setError('username', { type: 'manual', message: 'Your Temporary Password is expired' });
          return setSignInState((prevState) => ({
            ...prevState,
            step: 1,
            impersonateKey: null
          }));
        }

        // If password is expired then redirect to update password page
        if (user.UserStatus === expiredPasswordStatus) {
          const payload = {
            DCNGuid: user.DCNGuid,
            DoctorGuid: user.DoctorGuid,
            SendText: 1,
            SendEmail: 0
          };
          sendPhoneCodeAuth(payload);
          history.replace(routes.verificationPhone.path);
          return;
        }

        // User locked out due to several invalid login attempts
        if (user.UserStatus === userLockedStatus) {
          history.replace(routes.temporarilyAccountBlockError.path);
          return;
        }

        // Step 1 - username
        if (signInState.step === 1) {
          if (user.TmpPwd) {
            history.push(routes.passwordRecovery.path);
            return;
          }

          return setSignInState((prevState) => ({
            ...prevState,
            step: 2,
            impersonateKey: user.ImpersonateKey
          }));
        }

        // Step 2 - password
        if (signInState.step === 2) {
          if (user.Role === 'bureau') {
            return history.push(routes.verificationBureau.path);
          }

          // Before Login a User, we check the conditions that User has "Secret questions for password recovery",
          //  the "Phone is verified", and the "Terms of Use" are confirmed.
          //  The order should be exactly as follows: "Secret questions", "Phone verification", "Confirmation of Terms of Use"
          //  and corresponds to a similar order when registering a new user.

          if (!user.HaveSecretQuestions) {
            return noSecretQuestions();
          }

          if (!user.PhoneVerified) {
            return phoneNotVerified(user);
          }

          if (!user.TermsAccepted) {
            return termsNotAccepted();
          }

          // "needMFA" is required to be at the last stage, after the User has confirmed all other conditions:
          //  "Secret questions", "Phone verification", "Confirmation of Terms of Use"

          if (user.NeedMFA) {
            return needMFA(user);
          }

          const pinCode = JSON.parse(localStorage.getItem(localStorageKeys.pinCode));
          // If pin code is not set and it's mobile device then navigate to create pin code page else navigate to dashboard
          const redirectTo = pinCode === null && showPin() ? routes.createPin.path : routes.dashboard.path;

          await login(user, { redirectTo });
        }
      }
    } catch (error) {
      setSignInState((prevState) => ({ ...prevState, isSubmitting: false }));
      if (error.message === 'InvalidCredentials') {
        setError('password', { type: 'custom', message: "You've entered an invalid username / password combination." });
      } else {
        console.error('Sign-in error:', error);
        setError('password', { type: 'custom', message: 'An unexpected error occurred. Please try again.' });
      }
    }
  };

  const developmentSignIn = async (data) => {
    if (signInState.step === 1) {
      try {
        const dataForSubmit = { username: 'demo', password: '' };
        // Show loading
        setSignInState((prevState) => ({ ...prevState, isSubmitting: true }));
        const user = await devSignIn(dataForSubmit);
        // Show loading
        setSignInState((prevState) => ({ ...prevState, isSubmitting: false }));
        // Navigate to dashboard
        await login(user);
      } catch (error) {
        console.log('!!!developmentSignIn', error);
      }
    }

    if (signInState.step === 2) {
      if (data.password) {
        handleSignIn(data);
      } else {
        try {
          const dataWithDefaultPassword = { username: signInState.impersonateKey, password: 'vba777A!' };
          // Show loading
          setSignInState((prevState) => ({ ...prevState, isSubmitting: true }));
          await devSignIn(dataWithDefaultPassword);
          // Show loading
          setSignInState((prevState) => ({ ...prevState, isSubmitting: false }));
          await handleSignIn(dataWithDefaultPassword);
        } catch (error) {
          console.log('!!!developmentSignIn', error);
        }
      }
    }
  };

  // const developmentSignIn = async (data) => {
  //   if (signInState.step === 1) {
  //     if (data.username) {
  //       handleSignIn(data);
  //     } else {
  //       try {
  //         const dataForSubmit = { username: 'demo', password: '' };
  //         // Show loading
  //         setSignInState((prevState) => ({ ...prevState, isSubmitting: true }));
  //         await devSignIn(dataForSubmit);
  //         // Show loading
  //         setSignInState((prevState) => ({ ...prevState, isSubmitting: false }));
  //         // Navigate to dashboard
  //         history.replace(routes.dashboard.path);
  //       } catch (error) {
  //         console.log('!!!developmentSignIn', error);
  //       }
  //     }
  //   }

  //   if (signInState.step === 2) {
  //     if (data.password) {
  //       handleSignIn(data);
  //     } else {
  //       try {
  //         const dataWithDefaultPassword = { username: signInState.impersonateKey, password: 'vba777A!' };
  //         // Show loading
  //         setSignInState((prevState) => ({ ...prevState, isSubmitting: true }));
  //         await devSignIn(dataWithDefaultPassword);
  //         // Show loading
  //         setSignInState((prevState) => ({ ...prevState, isSubmitting: false }));
  //         await handleSignIn(dataWithDefaultPassword);
  //       } catch (error) {
  //         console.log('!!!developmentSignIn', error);
  //       }
  //     }
  //   }
  // };

  return { onSubmit };
};
