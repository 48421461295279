import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import DOMPurify from 'dompurify';
import { websiteValidation } from '../helpers/validation';

const Website = ({ label, name }) => {
  const {
    control,
    formState: { errors },
    clearErrors,
    setError
  } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ validate: websiteValidation, maxLength: { value: 150, message: 'Max length is 150 characters' } }}
        render={({ field }) => (
          <InputWrapper name={name} label={label} errorMessage={errors[name]?.message}>
            <InputText
              {...field}
              id={name}
              data-testid="website"
              className={classNames({ 'p-invalid': errors[name] })}
              placeholder="www.sample.com"
              onChange={(e) => {
                const value = e.target.value;
                const sanitizedContent = DOMPurify.sanitize(value);
                field.onChange(sanitizedContent);
                if (value.length > 150) {
                  setError(name, {
                    type: 'manual',
                    message: 'Max length is 150 characters'
                  });
                } else {
                  clearErrors(name);
                }
              }}
            />
          </InputWrapper>
        )}
      />
    </>
  );
};

export default Website;
