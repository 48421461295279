import { store } from '../../../../store';
import { defaultAddressType, defaultCountryCode, defaultProvinceCode, representativeStatus } from '../../../../modules/config/defaultValuesConfig';

// SHould be function because of the default values  DCNGuid: store.getState().clinic.details.DCNGuid
export const getRepresentativeDefaultValues = () => {
  return {
    DCNGuid: store.getState().clinic.details.DCNGuid,
    PatientGuid: '',
    RepresentativeGuid: '',
    BirthDay: new Date(),
    Relationship: '',
    CompanyName: '',
    ContactType: 1, // 1 - Person, 2 - Company
    FirstName: '',
    LastName: '',
    AddressLine: '',
    City: '',
    Province: defaultProvinceCode,
    Country: defaultCountryCode,
    AddressType: defaultAddressType,
    Zip: '',
    Email: '',
    EmailType: 'P',
    Phone: '',
    PhoneType: 'C',
    Comment: '',
    Status: representativeStatus
  };
};

// SHould be function because of the default values  DCNGuid: store.getState().clinic.details.DCNGuid
export const formDefaultValues = () => {
  return {
    ...getRepresentativeDefaultValues(),
    formState: {
      isNew: true
    }
  };
};
