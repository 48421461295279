import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import Email from '../inputs/Email';
import { updateRegistrationFormState } from '../../actions/registration.actions.creators';
import { saveContactData } from '../../../../service/Lookup';

const ChangeEmail = ({ setVisibleChangeEmail, setLabelContent }) => {
  const dispatch = useDispatch();
  const registrationState = useSelector((state) => state.registration.registrationFormState);
  const EMAIL_VERIFICATION_LABEL = 'Verify Your Email';
  const autoFocusStatus = true;
  const requiredStatus = true;
  const { control, handleSubmit, formState, reset } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      phoneNumber: ''
    }
  });

  const onSubmit = async (data) => {
    reset({ code: '' });
    dispatch(
      updateRegistrationFormState({
        ...registrationState,
        initialFormState: {
          ...registrationState.initialFormState,
          repeatEmail: data.email,
          email: data.email
        }
      })
    );

    const payload = {
      SessionGuid: registrationState.initialFormState.sessionGuid,
      Email: data.email,
      Phone: '',
      Code: '',
      SendEmail: 1,
      SendText: 0,
      WhenCreated: new Date().toISOString()
    };

    await saveContactData(payload);
    setVisibleChangeEmail(false);
    setLabelContent(EMAIL_VERIFICATION_LABEL);
  };

  const handleCancel = () => {
    setVisibleChangeEmail(false);
    setLabelContent(EMAIL_VERIFICATION_LABEL);
  };

  return (
    <>
      <div className="text-lg line-height-3">Update your email address and send a verification code to the new email</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Email control={control} autoFocusStatus={autoFocusStatus} requiredStatus={requiredStatus} />
        <div className="flex gap-3 mt-2 mb-6">
          <Button label="Send a code" className="w-6 h-3rem" loading={formState.isSubmitting} />
          <Button label="Cancel" className="p-button-outlined w-6 h-3rem" onClick={handleCancel} />
        </div>
      </form>
    </>
  );
};

export default ChangeEmail;
