import React from 'react';
import Catalogs from '../../../../../../../components/Catalogs/Catalogs';
import { useFormContext } from 'react-hook-form';
import { localStateInitValues } from '../../../helpers/initialState';
import { inputs } from '../../../helpers/inputs';
import { Chip } from 'primereact/chip';
import { patientFullNameWithAge } from '../../../../../../utils/patientFullName';
import { useConditions } from '../../../hooks/useConditions';
import { currencyFormat } from '../../../../../../utils/currencyFormat';
import { useService } from '../../../hooks/useService';
import { validate } from 'uuid';
import { generateServiceValue } from '../../../helpers/defaultValues';
import cx from 'classnames';

const EClaimCatalogs = () => {
  const { localState, setLocalState, getValues } = useFormContext();
  const patients = getValues(inputs.patient.name);
  const speciality = getValues(inputs.speciality.name);
  const patientName = patients?.length ? patientFullNameWithAge(patients[0]) : '';
  const { catalogType } = useConditions();
  const { onSelectService } = useService();

  const onHide = () => {
    setLocalState((prevState) => ({ ...prevState, catalogsDialog: localStateInitValues.catalogsDialog }));
  };

  const catalogsTab = {
    name: inputs.services.name,
    label: inputs.services.label,
    codeType: inputs.services.name,
    categoryType: `feecodes_${catalogType}`,
    maxEntries: 1,
    openCatalogButton: true,
    // searchAllType: 'aoi_all',
    requestParams: { getCodesFor: 'eclaim', catalogType: catalogType, speciality }
  };

  const getSelectedCodes = () => {
    const servicesList = getValues(inputs.services.name);
    return servicesList.filter((i) => i.value);
  };

  const onChipRemove = (service) => {
    onSelectService(service);
  };

  const onCodeSelect = (service) => {
    if (validate(service.value)) return onSelectService(service);
    onSelectService({ ...service, value: generateServiceValue(service) });
  };

  const dataScrollerItemTemplate = (e) => {
    const service = e.data;

    const label = `${service.text}: ${service.label}`;
    const serviceName = service?.unit && !!Number(service?.duration) ? `${label}, ${service.duration} ${service.unit}` : label;

    return (
      <div className={cx('flex col-12 p-0')}>
        <div className={cx('flex col-12 align-items-center justify-content-end px-0 ml-2 py-2')} style={{ width: '65px', minHeight: '45px' }}>
          {currencyFormat(service?.amount)}
        </div>

        <div className={cx('flex align-items-center p-jc-left px-2 py-2 ml-5 w-full')} style={{ minHeight: '45px' }}>
          <span>{serviceName}</span>
        </div>
      </div>
    );
  };

  const codeChipLabel = (service) => {
    return service.label;
  };

  const chipsLayout = <Chip className="mr-2 w-max" label={patientName} removable={false} icon="pi pi-user" />;

  return (
    <Catalogs
      id="eClaim_Catalogs"
      activeTabIndex={0}
      catalogTabs={[catalogsTab]}
      showCatalogs={localState.showCatalogs}
      patientChipsLayout={chipsLayout}
      hideTabs
      activeTab={catalogsTab}
      dataScrollerItemTemplate={dataScrollerItemTemplate}
      codeChipLabel={codeChipLabel}
      // Handlers
      toggleCatalogs={onHide}
      getSelectedCodes={getSelectedCodes}
      onCodeChipClick={onChipRemove}
      onSelectCodeFromCatalogsTable={onCodeSelect}
    />
  );
};

export default EClaimCatalogs;
