import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

import PrivateInvoiceServices from './PrivateInvoiceServices';
import TimeWithDuration from '../../../components/Layouts/Time/TimeWithDuration';
import ClaimStatusAvatar from '../../../components/Avatars/ClaimStatusAvatar/ClaimStatusAvatar';

import { onClaimViewClick, rowActionsMenuItems } from '../helpers/rowActions';
import { setRequestHistory } from '../helpers/setRequestHistory';
import { setClaimInfo } from '../actions/claims.action.creators';
import { formatDate } from '../../utils/formatDate';
import { currencyFormat } from '../../utils/currencyFormat';
import { convertReferralValue } from '../helpers/convertReferralValue';
import { inputs } from './PrivateDetails/claimDetailsHelpers/inputs';
import { allRelationshipOptions } from './EClaimPolicy/helpers/dropdownOptions';
import { patientFullNameWithAge } from '../../utils/patientFullName';
import { getRemittanceLink } from '../../utils/getLink';
import { t } from '../../../service/localization/i18n';
import classes from './MobileRowExpansion.module.scss';
import cx from 'classnames';
import moment from 'moment';
import { getEClaimFormName } from '../../utils/getEClaimFormName';
import NotesCounter from './ClaimDetails/NotesCounter';

const MobileRowExpansion = ({
  data,
  expandedRow,
  setExpandedRow,
  setHeader,
  setClaimHistory,
  setPopupVisible,
  actionButtonsParams,
  paymentSwitch,
  showOnPatientDetailsScreen
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const menuRef = useRef(null);
  const privetServicesList = data[inputs().privateService.descriptionName];
  const privetServicesListLength = privetServicesList?.length > 1 ? `+ ${privetServicesList?.length - 1}` : '';
  const privateServiceToDisplay = () => {
    const servicesList = privetServicesList?.filter((i) => i.category === 'service');
    const productsList = servicesList?.length === 0 && privetServicesList?.filter((i) => i.category === 'product');
    const suspectList = servicesList?.length ? servicesList : productsList;
    const sortByHighestAmount = suspectList?.length > 1 ? suspectList?.sort((a, b) => b.amount - a.amount) : suspectList;
    return sortByHighestAmount?.length && sortByHighestAmount[0];
  };
  const isEClaim = data?.ClaimType === 'E';

  const serviceName = () => {
    if (data.LineDetails?.length > 1) return t('Multiple_services');
    return data.FeeCodeText || data.FeeCode;
  };

  const toggleDetails = (rowId) => (rowId !== expandedRow ? setExpandedRow(rowId) : setExpandedRow(null));

  const onRoundIconClick = () => {
    onClaimViewClick(data, history);
  };

  const showOverlay = (e) => {
    menuRef.current.toggle(e);
  };

  const rowVisible = data.InvoiceGuid !== expandedRow ? true : false;
  const refName = data?.RefName ? data?.RefName : '';

  const getRemittanceNumber = () => {
    if (isEClaim) {
      return `${getEClaimFormName(data?.RemittanceCode)}${data.SequenceNumber ? ` #${data.SequenceNumber}` : ''}`;
    }
    if (data?.SequenceNumber && data?.RemittanceGuid) {
      return t('Remittance');
    }
    return '';
  };

  const headerInfoLayout = () => {
    // Private
    if (data.ClaimType === 'P') {
      return (
        <>
          <span className="pb-1">{`${t('Private')}, ${serviceName()} - ${currencyFormat(data?.FeeTotal)}`}</span>

          {privateServiceToDisplay()?.label && (
            <div className="pb-1">
              {/* Приоритеты:
                  1) Самы дорогой сервис
                  2) Самы дорогой продукт
              */}
              {`${privateServiceToDisplay()?.label} ${privetServicesListLength}`}
            </div>
          )}

          <div>{`${data?.TreatedBy?.toUpperCase()}`}</div>
        </>
      );
    }

    // eClaims
    if (data.ClaimType === 'E') {
      return (
        <>
          <span className="pb-1">{`${serviceName()} - ${currencyFormat(data?.FeeTotal)}`}</span>

          <div>{`${data?.TreatedBy?.toUpperCase()}`}</div>
        </>
      );
    }

    // MSP, WSBC, ICBC
    return (
      <>
        <span className="pb-1">
          {`${data.InvoiceType}, ${data.FeeCode} x ${data.NoOfServiceUnits} (${data.ICD9Code.replaceAll(',', ', ')}) - ${currencyFormat(
            data.FeeTotal
          )}`}
        </span>

        <span>{data.TreatedBy ? `${data?.TreatedBy?.toUpperCase()}` : t('No_service_provider')}</span>
      </>
    );
  };

  return (
    <div className={classes.mobileRowExpantionRoot} onClick={() => dispatch(setClaimInfo(data))}>
      {/* HEADER */}
      <div className="flex flex-column">
        <div className="flex col-12 p-0">
          <div className="flex col-10 p-0">
            <div className={cx('flex align-items-center px-0', classes.leftItemBox)} onClick={onRoundIconClick}>
              <ClaimStatusAvatar status={data.Status} statusText={data.StatusText} style={{ width: '3em', height: '3em' }} />
            </div>

            <div className={classes.headerDetailsContainer}>
              <div className="flex justify-content-center flex-column pr-0" onClick={() => toggleDetails(data.InvoiceGuid)}>
                <span className="font-bold pb-2">{formatDate(data.ServiceDate)}</span>

                {!showOnPatientDetailsScreen && <span className="white-space-normal pb-1">{patientFullNameWithAge(data)}</span>}

                {headerInfoLayout()}
              </div>
            </div>
          </div>

          <div className="flex flex-column gap-3 justify-content-center align-items-end col-2 p-0">
            <Button
              id={`mobileRowExpansionActionButton_${data.ClaimGuid?.replaceAll('-', '').toUpperCase()}`}
              className="p-button-text p-button-rounded"
              icon="pi pi-ellipsis-v"
              aria-controls="popup_menu"
              onClick={(e) => showOverlay(e)}
            />
            <Menu model={rowActionsMenuItems({ ...actionButtonsParams, data })} popup ref={menuRef} id="popup_menu" />
            {paymentSwitch?.(data)}
          </div>
        </div>
        <div className="text-right">
          <NotesCounter totalNotes={data.TotalNotes} invoiceGuid={data.InvoiceGuid} />
        </div>
      </div>

      {/* HIDDEN DETAILS */}
      <div className={cx(rowVisible ? 'hidden' : null, 'px-2')}>
        {data?.InvoiceType === 'Private' ? (
          <div className="flex col-12 p-0 flex-column mt-4">
            <PrivateInvoiceServices claimRecord={data} />
          </div>
        ) : (
          <div className="mt-4 white-space-normal">
            {data.MSPCodes && data.MSPCodes.length > 0 && (
              <div className="col-12 p-error px-0">
                {data.MSPCodes.map((x, index) => {
                  const key = `msc_${data.index}_${index}`;
                  return (
                    <div key={key} className="mb-1">
                      <span>{x}</span>
                    </div>
                  );
                })}
              </div>
            )}

            <>
              {data?.RefNumber && data.ClaimType !== 'E' && (
                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0 white-space-normal', classes.leftItemBox)}>
                    {`${t('Referred.2')} ${convertReferralValue(data?.RefToBy).toLowerCase()}`}:
                  </div>
                  <div className="mb-1 white-space-normal">{`${refName} #${data?.RefNumber}`}</div>
                </div>
              )}

              {data.FeeCode?.length ? (
                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Amount')}:</div>
                  <div className="mb-1">
                    {data.FeeAmount} x {data.NoOfServiceUnits} x {data.Percent}% = {currencyFormat(data.FeeTotal)}
                  </div>
                </div>
              ) : null}

              {/* Practitioner/Payee number */}
              {/* <div className="flex p-0">
                <div className={cx("font-bold pb-0 flex align-items-start px-0", classes.leftItemBox)}>
                  {`${t("Practitioner")}/${t("Payee")}: `}
                </div>
                <div className="mb-1">
                  {`#${data?.PractitionerNumber}/#${data?.PayeeNumber}`}
                </div>
              </div> */}

              {(data.StartTime || data.FinishTime) && (
                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Time')}:</div>
                  <div className="mb-2 white-space-normal">
                    <TimeWithDuration startTime={data?.StartTime} endTime={data?.FinishTime} />
                  </div>
                </div>
              )}
            </>

            {data.ClaimType !== 'E' && data.ICD9Code?.length ? (
              <div className="flex p-0 my-1">
                <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Dx')}:</div>

                <div className="flex pb-0 flex-column">
                  {data.ICD9Code.split(',').map((i, index) => {
                    const key = `icd9code_${data.index}_${index}`;
                    return (
                      <div className="mb-1 white-space-normal" key={key}>
                        {`${i}: ${data.ICD9Text[index]}`}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {data.InvoiceType !== 'Private' && data.FeeCode?.length ? (
              <div className="flex p-0">
                <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>
                  {data.ClaimType === 'E' ? t('Service') : t('Fee')}:
                </div>
                <div className="mb-2 white-space-normal">
                  {data.FeeCode}: {data.FeeCodeText}, {`${data.NoOfServiceUnits} ${t('Unit').toUpperCase()}, ${currencyFormat(data.FeeAmount)}`}
                </div>
              </div>
            ) : null}

            {/* eClaims */}
            {data.ClaimType === 'E' && (
              <>
                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>Plan:</div>
                  <div className="mb-2 white-space-normal">#{data?.PolicyNum}</div>
                </div>

                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>Member:</div>
                  <div className="mb-2 white-space-normal">#{data?.MemberID}</div>
                </div>

                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>Insured:</div>
                  <div className="mb-2 white-space-normal">
                    {`${allRelationshipOptions?.find((i) => i.value?.toUpperCase() === data?.Relationship?.toUpperCase())?.label || t('N_A')}`}
                  </div>
                </div>

                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>Licence:</div>
                  <div className="mb-2 white-space-normal">#{data.LicenseId}</div>
                </div>

                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>Location:</div>
                  <div className="mb-2 white-space-normal">#{data.LocationName}</div>
                </div>
              </>
            )}

            {data.FacilityNum && data.ClaimType !== 'E' && (
              <div className="flex p-0">
                <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Facility')}:</div>
                <div className="mb-2 white-space-normal">{data.FacilityNum}</div>
              </div>
            )}

            {data.SubFacility && data.ClaimType !== 'E' && (
              <div className="flex p-0">
                <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Sub_Facility')}:</div>
                <div className="mb-2 white-space-normal">{data.SubFacility}</div>
              </div>
            )}

            {data.RuralCode && data.RuralCode !== '00' && data.ClaimType !== 'E' && (
              <div className="flex p-0">
                <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('SCCode')}:</div>
                <div className="mb-2 white-space-normal">
                  {data.RuralCode === 'N/A' ? t('No_rural_code') : `${data.RuralCode}: ${data.RuralName}`}
                </div>
              </div>
            )}

            <div className="col-12 pt-0 pb-0">
              {data.LineDetails &&
                data.LineDetails.map &&
                data.LineDetails.length === 1 &&
                data.LineDetails.map((line, index) => {
                  const key = `ild_${data.index}_${index}`;
                  return (
                    <div className="mb-3 white-space-normal" key={key}>
                      {line.Name && <span>{line.Name}</span>}
                      {line.FeeCodeText && (
                        <span>
                          {line.FeeItemCode}: {line.FeeCodeText}
                        </span>
                      )}
                      <br />
                    </div>
                  );
                })}

              {data.LineDetails &&
                data.LineDetails.map &&
                data.LineDetails.length > 1 &&
                data.LineDetails.map((line, index) => {
                  const key = `ild_${data.index}_${index}`;
                  return (
                    <div key={key}>
                      <div className="mb-3 font-bold white-space-normal" title="Billed">
                        {currencyFormat(line.FeeAmount)} x {line.NumUnits} x 100% = {currencyFormat(line.FeeAmount * line.NumUnits)}
                      </div>
                      {line.Name && <div className="mb-3">{line.Name}</div>}
                      {line.FeeCodeText && (
                        <div>
                          {line.FeeItemCode}: {line.FeeCodeText}
                        </div>
                      )}
                      <br />
                    </div>
                  );
                })}

              {data.Included !== '' && (
                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Converted from MSP claim on')}:</div>
                  <div className="mb-2 white-space-normal">{data.DateCreated}</div>
                </div>
              )}
            </div>

            {(data.ClaimType === 'W' || data.ClaimType === 'R') && (
              <>
                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Anatomic_Code.2')}:</div>
                  <div className="mb-2 white-space-normal">{data.AACText}</div>
                </div>

                {data.AreaOfInjury?.length ? (
                  <div className="flex p-0">
                    <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Area_of_Injury.2')}:</div>
                    <div className="mb-2 white-space-normal">
                      {data.AreaOfInjury}: {data.AOIText}
                    </div>
                  </div>
                ) : null}

                {data.NatureOfInjury?.length ? (
                  <div className="flex p-0">
                    <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Nature_of_Injury.2')}:</div>
                    <div className="mb-2 white-space-normal">
                      {data.NatureOfInjury}: {data.NOIText}
                    </div>
                  </div>
                ) : null}
              </>
            )}

            {/* {data.InvoiceType === 'ICBC' &&
            <div className="flex p-0">
              <div className={cx("font-bold pb-0 flex align-items-start px-0", classes.leftItemBox)}>????: </div>
              <div className="mb-2 white-space-normal">
                here goes ICBC specific visit details (same as MSP plus ICBC claim number)
              </div>
            </div>
          } */}

            {data.Comment && (
              <div className="flex p-0">
                <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Note')}:</div>
                <div className="mb-2 white-space-normal">{data.Comment}</div>
              </div>
            )}

            {(data.ClaimType === 'W' || data.ClaimType === 'R' || data.ClaimType === 'I') && data.ClaimNumber && (
              <div className="flex p-0">
                <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Claim')}:</div>
                <div className="mb-2 white-space-normal">{data.ClaimNumber}</div>
              </div>
            )}

            {(data.ClaimType === 'W' || data.ClaimType === 'R') && (
              <div className="flex p-0">
                <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Date_of_injury.1')}:</div>
                <div className="mb-2 white-space-normal">{formatDate(data.InjuryDay)}</div>
              </div>
            )}

            <>
              {data.SequenceNumber && data.Status > 0 && data.InvoiceType !== 'Private' && (
                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Sequence.1')}.#:</div>
                  <div className="mb-2 white-space-normal">{data.SequenceNumber}</div>
                </div>
              )}

              {data?.RecordNo && (
                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Office.1')}.#:</div>
                  <div className="mb-2 white-space-normal">{data.RecordNo}</div>
                </div>
              )}

              {data.Status > 0 && data.InvoiceType !== 'Private' && (
                <>
                  {data?.SDate && (
                    <div className="flex p-0">
                      <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Sent')}:</div>
                      <div className="mb-2 white-space-normal">{data.SDate}</div>
                    </div>
                  )}

                  {data?.Received && (
                    <div className="flex p-0">
                      <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>
                        {isEClaim ? getRemittanceNumber() : t('Remittance')}:
                      </div>
                      {/* <div className="mb-2 white-space-normal">{formatDate(data.Received)}</div> */}
                      <a href={getRemittanceLink(data)} target="_blank" rel="noreferrer">
                        {formatDate(data?.Received)}
                      </a>
                    </div>
                  )}

                  {data?.Received && (Number(data?.Status) === 3 || Number(data?.Status) === -3) && (
                    <div className="flex p-0">
                      <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>{t('Resubmit_before')}:</div>

                      <div className="mb-2 white-space-normal">{moment(data.ServiceDate).add(90, 'days').format('MMM DD, YYYY')}</div>
                    </div>
                  )}
                </>
              )}

              {data.InvoiceType === 'Private' && (
                <div className="flex p-0">
                  <div className={cx('font-bold pb-0 flex align-items-start px-0', classes.leftItemBox)}>????:</div>
                  <div className="mb-2 white-space-normal">{/* here goes patient pay visit details */}</div>
                </div>
              )}

              {data.RemDiff >= 1 && (
                <div className="flex justify-content-end pb-2" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                  <span label="history" onClick={() => setRequestHistory(data, setClaimHistory, setPopupVisible, setHeader, dispatch)}>
                    {t('History')}
                  </span>
                </div>
              )}
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileRowExpansion;
