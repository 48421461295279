import React from 'react';
import { getPayeeInfoInitValues } from '../../../../../modules/utils/getPayeeInfo';
import { t } from '../../../../../service/localization/i18n';
import { Skeleton } from 'primereact/skeleton';
import { elementIDs } from '../../../../../modules/config/elementIDsConfig';
import AddressLayout from '../../../AddressLayout';

const PayeeSection = ({ data, isFetching }) => {
  const payeeInfo = getPayeeInfoInitValues({ privateRecord: data });
  const payTo = data?.PayTo;

  const getData = (name) => {
    return payeeInfo?.find((i) => i.id === name)?.label;
  };

  const payee = getData('payee');
  const address = getData('address');
  const email = getData('email');
  const phone = getData('phone');

  const parts = address?.split(',')?.map((part) => part.trim());

  // Map the parts back to the object
  const addressData = {
    AddressLine: parts?.[0],
    City: parts?.[1],
    Province: parts?.[2],
    Zip: parts?.[3],
    Country: data?.PayTo?.Country,
    Email: email,
    Phone: phone
  };

  return (
    <>
      {isFetching ? (
        <>
          <Skeleton width="10rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
        </>
      ) : (
        <div id={elementIDs.payeeSection} className="line-height-3">
          <div className="flex col-12 p-0 m-0 font-bold">{payee?.toUpperCase()}</div>

          <AddressLayout data={addressData} />

          {/* GST number */}
          {payTo?.GSTNumber && <div>{`${t('GST')} # ${payTo?.GSTNumber}`}</div>}
        </div>
      )}
    </>
  );
};

export default PayeeSection;
