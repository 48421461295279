import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import cx from 'classnames';
import { InputWrapper } from '../../../../components/Inputs';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';
import { t } from '../../../../service/localization/i18n';

import { setFocusToElement } from '../../../utils/setFocusToElement';
import { updateRegistrationFormState } from '../../actions/registration.actions.creators';
import { saveContactData, saveRegistrationData, sendVerificationCode } from '../../../../service/Lookup';
import { useTimer } from '../../../hooks/useTimer';
import ChangeEmail from './ChangeEmail';

const EmailVerify = () => {
  const registrationState = useSelector((state) => state.registration.registrationFormState);
  const dispatch = useDispatch();

  const email = registrationState.initialFormState.email;

  const INPUT_LENGTH = 6;
  const TIMER_INITIAL_VALUE = 180000; // (180000 millisecunds - 3 minutes)
  const EMAIL_VERIFICATION_LABEL = 'Verify Your Email';
  const EMAIL_UPDATE_LABEL = 'Update Your Email';
  const [visibleChangeEmail, setVisibleChangeEmail] = useState(false);

  const [labelContent, setLabelContent] = useState(EMAIL_VERIFICATION_LABEL);
  const { formattedTime, startTimer, active } = useTimer({ duration: TIMER_INITIAL_VALUE });

  const obfuscateEmail = (email) => {
    if (!email) {
      return ''; // or some default placeholder, like 'example@example.com'
    }

    const [localPart, domainPart] = email.split('@');

    // Obfuscate the local part
    let obfuscatedLocalPart;
    if (localPart.length > 1) {
      obfuscatedLocalPart = localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1];
    } else {
      obfuscatedLocalPart = '*';
    }

    // Obfuscate the domain part
    const [domainName, domainExtension] = domainPart.split('.');
    const obfuscatedDomainName =
      domainName.length > 2 ? domainName[0] + '*'.repeat(domainName.length - 2) + domainName[domainName.length - 1] : domainName[0] + '*';
    const obfuscatedDomainPart = obfuscatedDomainName + '.' + domainExtension;

    // Combine the obfuscated parts
    return obfuscatedLocalPart + '@' + obfuscatedDomainPart;
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    formState,
    reset
  } = useForm({
    defaultValues: {
      code: ''
    }
  });

  const onSubmit = async (data) => {
    const timeCreated = new Date().toISOString();
    const body = {
      SessionGuid: registrationState.initialFormState.sessionGuid,
      Email: registrationState.initialFormState.email,
      Phone: '',
      Code: data.code,
      SendText: 0,
      SendEmail: 1,
      WhenCreated: timeCreated
    };

    try {
      await sendVerificationCode(body);

      const dataToSend = {
        ...registrationState.initialFormState,
        emailVerified: true,
        ReferenceCode: registrationState.ReferenceCode
      };
      await saveRegistrationData(dataToSend);

      const formatedPhoneNumber = registrationState.initialFormState.phoneNumber?.replace(/[^0-9]/g, '');
      const payload = {
        SessionGuid: registrationState.initialFormState.sessionGuid,
        Email: '',
        Phone: formatedPhoneNumber,
        Code: '',
        SendText: 1,
        SendEmail: 0,
        WhenCreated: timeCreated
      };

      await saveContactData(payload); // If Email verification is successful, we send a request to generate a one-time password to verify the phone.

      dispatch(
        updateRegistrationFormState({
          ...registrationState,
          type: 'register-step-3',
          initialFormState: { ...registrationState.initialFormState, emailVerified: true }
        })
      );
    } catch (error) {
      setError('code', { type: 'custom', message: 'You entered an incorrect verification code. Try again.' });
    }
  };

  const resendCode = async () => {
    reset({ code: '' });
    const timeCreated = new Date().toISOString();
    const payload = {
      SessionGuid: registrationState.initialFormState.sessionGuid,
      Email: registrationState.initialFormState.email,
      Phone: '',
      Code: '',
      SendEmail: 1,
      SendText: 0,
      WhenCreated: timeCreated
    };

    await saveContactData(payload);

    startTimer();
    setFocusToElement('code');
  };

  const changeEmail = () => {
    reset({ code: '' });
    setVisibleChangeEmail(true);
    setLabelContent(EMAIL_UPDATE_LABEL);
  };

  const codeVerificationLayout = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="code"
        control={control}
        rules={{
          required: t('Mandatory_field.1'),
          minLength: {
            value: INPUT_LENGTH,
            message: 'Minimum length is 6 characters'
          }
        }}
        render={({ field, fieldState }) => (
          <InputWrapper name="code" label="Code" required errorMessage={errors?.code?.message}>
            <InputText
              {...field}
              id={field.name}
              data-testid="codeVerifyEmail"
              autoFocus
              keyfilter="int"
              maxLength={INPUT_LENGTH}
              className={cx({ 'p-invalid': fieldState.error })}
            />
          </InputWrapper>
        )}
      />
      <div className="flex gap-3 mt-2 h-3rem">
        <Button
          id="submit"
          data-testid="submitButtonEmailVerify"
          className="w-full"
          label={t('Submit')}
          type="submit"
          loading={formState.isSubmitting}
        />
      </div>

      <div className="my-6">
        {!active ? (
          <p className="w-12 mb-3 text-800 text-base ">
            Haven't received the email?
            <Button
              link
              label="Resend the code"
              type="button"
              onClick={resendCode}
              style={{ width: '130px', paddingBottom: '2px', paddingLeft: '0px', color: '#607D8B', fontSize: '1rem' }}
            />
          </p>
        ) : (
          <p className="w-12 pt-2 mb-3 text-800 text-base">
            Haven't receive the email Resend the code in <span style={{ color: 'var(--primary-color)', fontWeight: 'bold' }}>{formattedTime}</span>.
          </p>
        )}
        {!active ? (
          <p className="text-800">
            <Button
              link
              label="Change email"
              type="button"
              onClick={changeEmail}
              style={{ textAlign: 'left', width: '180px', paddingBottom: '0px', paddingLeft: '0px', color: '#607D8B', fontSize: '1rem' }}
            />
          </p>
        ) : (
          <p className="text-base h-full mt-4" style={{ color: 'var(--primary-color)' }}>
            Change email
          </p>
        )}
      </div>
    </form>
  );

  const Title = ({ text }) => <div className="m-0 my-4 text-800 w-full text-center font-bold text-3xl">{text}</div>;

  return (
    <>
      <AuthFormWrap>
        <Title text={labelContent} />

        {!visibleChangeEmail && (
          <div className="line-height-3 text-center text-800 text-lg">
            Enter the verification code that we sent to <br /> {obfuscateEmail(email)}.
          </div>
        )}

        {visibleChangeEmail ? (
          <ChangeEmail setVisibleChangeEmail={setVisibleChangeEmail} setLabelContent={setLabelContent} />
        ) : (
          codeVerificationLayout
        )}
      </AuthFormWrap>
    </>
  );
};

export default EmailVerify;
