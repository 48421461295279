import { countries } from '../config/dropdownListsConfig';
import { defaultCountryCode } from '../config/defaultValuesConfig';

export const getAddress = (data) => {
  const { AddressLine, City, Province, Zip } = data || {};

  // Construct full address
  let fullAddress = '';

  if (AddressLine) {
    fullAddress += AddressLine;
  }

  if (City) {
    fullAddress += `\n${City}`;
  }

  if (Province) {
    fullAddress += `, ${Province}`;
  }

  if (Zip) {
    fullAddress += `, ${Zip}`;
  }

  // Trim any extra spaces or commas
  return fullAddress.trim().replace(/,\s*$/, '');
};

export const getRegionsList = (country) => countries.find((i) => i.value === country)?.regions || [];

export const getRegionByCode = (code, country = defaultCountryCode) => {
  if (!code) return '';

  const regions = getRegionsList(country || defaultCountryCode);
  return regions.find(({ value }) => value === code) || '';
};

export const getCountryByCode = (country) => {
  if (!country) return '';
  return countries.find(({ value }) => value === country) || '';
};
