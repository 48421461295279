import React from 'react';
import { Button } from 'primereact/button';
import CommonCard from '../../../common/components/CommonCard';
import ReferralSocialSharing from '../../../referralCredits/components/ReferralSocialSharing';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { routes } from '../../../../routes/routes';

const ReferralProgram = () => {
  const history = useHistory();

  const onViewCredits = () => {
    history.push(`${routes.staff.path}/credits`);
  };

  return (
    <CommonCard title="Earn Free Service by Referring a Colleague">
      <div className="flex flex-column gap-5">
        <div className="flex flex-wrap align-items-center gap-2">
          <div> Refer a colleague and get 1 month free, plus 2 months for them!</div>
          <Button className="w-auto p-0" text label="View credits" onClick={onViewCredits} />
        </div>

        <div className="flex flex-column gap-3">
          <ReferralSocialSharing />
        </div>
      </div>
    </CommonCard>
  );
};

export default ReferralProgram;
