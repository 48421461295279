// eslint-disable-next-line import/no-anonymous-default-export
export default {
  FETCH_CLAIMS_REQUEST: 'FETCH_CLAIMS_REQUEST',
  FETCH_CLAIMS_SUCCESS: 'FETCH_CLAIMS_SUCCESS',
  // DataScroll support
  FETCH_CLAIMS_LIST_SUCCESS: 'FETCH_CLAIMS_LIST_SUCCESS',
  FETCH_CLAIMS_LIST_FAILURE: 'FETCH_CLAIMS_LIST_FAILURE',
  SET_CLAIM_LIST: 'SET_CLAIM_LIST',
  SET_COMPLETE_LIST: 'SET_COMPLETE_LIST',

  FETCH_CLAIM_DETAILS_REQUEST: 'FETCH_CLAIM_DETAILS_REQUEST',
  FETCH_CLAIM_DETAILS_SUCCESS: 'FETCH_CLAIM_DETAILS_SUCCESS',
  FETCH_CLAIM_DETAILS_FAILURE: 'FETCH_CLAIM_DETAILS_FAILURE',

  FETCH_CLAIMS_COUNTERS_SUCCESS: 'FETCH_CLAIMS_COUNTERS_SUCCESS',
  FETCH_CLAIMS_COUNTERS_FAILURE: 'FETCH_CLAIMS_COUNTERS_FAILURE',

  PREPARE_CLAIMS_REQUEST: 'PREPARE_CLAIMS_REQUEST',
  PREPARE_CLAIMS_SUCCESS: 'PREPARE_CLAIMS_SUCCESS',
  PREPARE_CLAIMS_FAILURE: 'PREPARE_CLAIMS_FAILURE',

  CREATE_CLAIM_FAILURE: 'CREATE_CLAIM_FAILURE',

  EDIT_CLAIM_REQUEST: 'EDIT_CLAIM',
  EDIT_CLAIM_SUCCESS: 'EDIT_CLAIM_SUCCESS',
  EDIT_CLAIM_FAILURE: 'EDIT_CLAIM_FAILURE',

  CLAIM_FIELD_UPDATED: 'CLAIM_FIELD_UPDATED',
  CLAIM_VALIDATION_SUCCESS: 'CLAIM_VALIDATION_SUCCESS',
  CLAIM_VALIDATION_FAILURE: 'CLAIM_VALIDATION_FAILURE',

  CLAIM_FILTERS: 'CLAIM_FILTERS',
  CLAIM_STATUS_OPTIONS: 'CLAIM_STATUS_OPTIONS',
  IS_FETCHING_CLAIMS: 'IS_FETCHING_CLAIMS',
  IS_SAVING_CLAIMS: 'IS_SAVING_CLAIMS',
  IS_FETCHING_CLAIM_HISTORY: 'IS_FETCHING_CLAIM_HISTORY',
  IS_FETCHING_COUNTERS: 'IS_FETCHING_COUNTERS',
  IS_FETCHING_DEBID_REQUEST: 'IS_FETCHING_DEBID_REQUEST',
  CLAIMS_SELECTED_CODES: 'CLAIMS_SELECTED_CODES',
  SET_CLAIM_INDEX: 'SET_CLAIM_INDEX',
  SET_CLAIM_DIR: 'SET_CLAIM_DIR',

  SET_CLAIM_RECORD: 'SET_CLAIM_RECORD',
  SET_PRIVATE_RECORD: 'SET_PRIVATE_RECORD',
  UPDATE_CLAIM_RECORD: 'UPDATE_CLAIM_RECORD',
  UPDATE_PRIVATE_RECORD: 'UPDATE_PRIVATE_RECORD',
  SET_PATIENTS_FOR_CLAIM_DETAILS: 'SET_PATIENTS_FOR_CLAIM_DETAILS',

  SHOULD_RESET_CLAIMS_FORM: 'SHOULD_RESET_CLAIMS_FORM',
  IS_FETCHING_GENERATED_INVOICES: 'IS_FETCHING_GENERATED_INVOICES',
  SET_CLAIMS_DEFAULT_TABLE_VALUES: 'SET_CLAIMS_DEFAULT_TABLE_VALUES',
  CREATE_CLAIM_PANEL_COLLAPSED: 'CREATE_CLAIM_PANEL_COLLAPSED',
  SET_CLAIMS_DATA: 'SET_CLAIMS_DATA',

  SET_EXPANDED_ROWS: 'SET_EXPANDED_ROWS', // [KS] CMO-898 - Keep extended on when user Views claim and goes back to the invoices table
  SET_VALUES_FOR_WSBC: 'SET_VALUES_FOR_WSBC',
  SET_VALUES_FOR_ICBC: 'SET_VALUES_FOR_ICBC',
  RESET_WSBC_CLAIM_DATA: 'RESET_WSBC_CLAIM_DATA',
  RESET_ICBC_CLAIM_DATA: 'RESET_ICBC_CLAIM_DATA',
  FILTER_BY_PATIENT_FOR_CLAIMS: 'FILTER_BY_PATIENT_FOR_CLAIMS',

  GET_ALL_CATALOG_CATEGORIES: 'GET_ALL_CATALOG_CATEGORIES',
  SET_ACTIVE_TAB_INDEX_FOR_INVOICES_CATALOG: 'SET_ACTIVE_TAB_INDEX_FOR_INVOICES_CATALOG',
  GET_INVOICES_CATALOG_MOST_RECENT_CODES: 'GET_INVOICES_CATALOG_MOST_RECENT_CODES',
  UPDATE_INVOICES_CATALOG_MOST_RECENT_CODES: 'UPDATE_INVOICES_CATALOG_MOST_RECENT_CODES',
  IS_FETCHING_INVICES_MOST_RECENT_CODES: 'IS_FETCHING_INVICES_MOST_RECENT_CODES',
  IS_SUBMITTING_ALL_CLAIMS: 'IS_SUBMITTING_ALL_CLAIMS',
  CREATING_PRIVATE_RECORD: 'CREATING_PRIVATE_RECORD',
  IS_ARCHIVING: 'IS_ARCHIVING',
  IS_ACCEPTING: 'IS_ACCEPTING',
  UPDATE_CLAIMS_STATE: 'UPDATE_CLAIMS_STATE',
  RESET_CLAIM_FORM: 'RESET_CLAIM_FORM',
  SAVING_RECEIVE_PAYMENT: 'SAVING_RECEIVE_PAYMENT',
  SET_PAYMENT_DETAILS: 'SET_PAYMENT_DETAILS',
  DELETING_PAYMENT: 'DELETING_PAYMENT',
  GET_PATIENTS_MOST_REASENT_CLAIM: 'GET_PATIENTS_MOST_REASENT_CLAIM',
  GETTING_CLAIM_INFO: 'GETTING_CLAIM_INFO',
  SET_PATIENT_PICKER_QUERY_VALUE: 'SET_PATIENT_PICKER_QUERY_VALUE',
  UPDATE_WSBC_RECORD: 'UPDATE_WSBC_RECORD',
  UPDATE_TELEPLAN_INVOICE: 'UPDATE_TELEPLAN_INVOICE',
  GETTING_WSBC_REPORT: 'GETTING_WSBC_REPORT',
  DELETING_CLAIM: 'DELETING_CLAIM',
  SHOW_MISSING_PATIENT_DATA_DIALOG: 'SHOW_MISSING_PATIENT_DATA_DIALOG',
  SHOW_TOTAL_OUTSTANDING_DIALOG: 'SHOW_TOTAL_OUTSTANDING_DIALOG',
  SENDING_BY_EMAIL: 'SENDING_BY_EMAIL',
  GETTING_INSURANCE_POLISY: 'GETTING_INSURANCE_POLISY',
  GETTING_INVOICES_CATALOG_CATEGORIES: 'GETTING_INVOICES_CATALOG_CATEGORIES',
  GETTING_RECENT_CODES_FOR_CURRENT_USER: 'GETTING_RECENT_CODES_FOR_CURRENT_USER'
};
