import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputSwitch } from 'primereact/inputswitch';
import { inputs } from '../../../helpers/inputs';

const SwitchMultipleDates = () => {
  const { localState, setLocalState, setValue, isMobile, isGroup } = useFormContext();

  // Only allow multiple dates on a large screen
  if (isGroup) return null;

  const onChange = (e) => {
    const serviceDate = e.value ? [] : null;
    setValue(inputs.serviceDate.name, serviceDate);
    setLocalState((prevState) => ({
      ...prevState,
      isMultipleDates: e.value
    }));
  };

  return (
    <InputSwitch
      tooltip={!isMobile && 'Multiple dates'}
      tooltipOptions={{ position: 'top' }}
      checked={localState.isMultipleDates}
      onChange={onChange}
    />
  );
};

export default SwitchMultipleDates;
