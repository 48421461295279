import React from 'react';
import { InputLabel } from '../../../../../../../components/Inputs/InputWrapper/LabelLayout';
import SwitchMultipleDates from '../Inputs/SwitchMultipleDates';
import ToggleCatalogsButton from '../../ActionButtons/ToggleCatalogsButton';
import { inputs } from '../../../helpers/inputs';
import { useFormContext } from 'react-hook-form';

const ServiceDateLabelLayout = () => {
  const { isMobile } = useFormContext();

  return (
    <div className="flex justify-content-between align-items-center">
      <div className="flex">
        <InputLabel name={inputs.serviceDate.name} label={inputs.serviceDate.label} required />
        <div className="ml-3">
          <SwitchMultipleDates />
        </div>
      </div>
      {isMobile && <ToggleCatalogsButton />}
    </div>
  );
};

export default ServiceDateLabelLayout;
