import axios from 'axios';
import { getApiBase, setAuthHeader } from '../config';
import { store } from '../store';

class ClinicService {
  static getUsers() {
    const state = store.getState();
    if (state?.clinic?.members) {
      return state.clinic.members.map((x) => {
        return { value: x.DoctorGuid, label: x.LastName };
      });
    }
    return [];
  }
  static getstaffMembers() {
    const state = store.getState();
    if (state && state.user && state.clinic.members) {
      return state.clinic.members;
    }
    return [];
  }
  static getLocations() {
    return [
      {
        LocationGuid: '1',
        Name: 'Demoo location',
        CprLocationId: ''
      },
      {
        LocationGuid: '2',
        Name: 'Second location',
        CprLocationId: ''
      }
    ];
  }
  static getTelusClinics() {
    return [
      {
        ClinicGuid: '1',
        Name: 'Demoo Clinic',
        CprId: '1234'
      },
      {
        ClinicGuid: '2',
        Name: 'Second Clinic',
        CprId: '5678'
      }
    ];
  }
  static getAuditLogs() {
    return [
      {
        record_id: '1',
        action_date: '2019-10-01',
        action_time: '10:00',
        user_name: 'John Smith',
        user_role: 'practitioner',
        action: 'View',
        phn: '9876543218',
        id: '112',
        description: 'Access patient record'
      },
      {
        record_id: '2',
        action_date: '2019-10-01',
        action_time: '10:00',
        user_name: 'John Smith',
        user_role: 'practitioner',
        action: 'Update',
        phn: '9876543218',
        id: '112',
        description: 'Update patient record'
      },
      {
        record_id: '3',
        action_date: '2019-10-01',
        action_time: '10:01',
        user_name: 'John Smith',
        user_role: 'practitioner',
        action: 'Create',
        phn: '9876543218',
        id: '556',
        description: 'Create MSP claim'
      },
      {
        record_id: '4',
        action_date: '2019-10-01',
        action_time: '10:02',
        user_name: 'Anna Deliott',
        user_role: 'receptionist',
        action: 'View',
        phn: '9876543218',
        id: '112',
        description: 'Access patient record'
      },
      {
        record_id: '5',
        action_date: '2019-10-01',
        action_time: '10:02',
        user_name: 'John Smith',
        user_role: 'practitioner',
        action: 'Submit',
        phn: '9876543218',
        id: '556',
        description: 'Submitted MSP claim'
      },
      {
        record_id: '6',
        action_date: '2019-10-01',
        action_time: '10:02',
        user_name: 'Anna Deliott',
        user_role: 'receptionist',
        action: 'View',
        phn: 'N/A',
        id: '86',
        description: 'Access patient record'
      }
    ];
  }
  static getSubmissions() {
    const data = [
      {
        id: '3d1b7844-c93b-4d6d-969a-42f54c986259',
        cell: ['2019-11-19 6:49:55 PM', '2730', '2730', '1', 'Submitted 1 record(s).', 'showSubRecords']
      },
      {
        id: '9daaef36-b4d9-442d-9447-7265e0cce125',
        cell: ['2019-11-19 6:44:09 PM', '2728', '2728', '1', 'Submitted 1 record(s).', 'showSubRecords']
      },
      {
        id: '106d0a32-1fc1-4f5a-a6ae-52ef67787f12',
        cell: ['2019-11-19 6:27:56 PM', '2722', '2726', '5', 'Submitted 5 record(s).', 'showSubRecords']
      }
    ];
    return data.map((x) => {
      return { record_id: x.id, sdate: x.cell[0], from: x.cell[1], to: x.cell[2], num: x.cell[3], description: x.cell[4] };
    });
  }
  static async getRemittances(dcnguid, pageNumber, pageSize) {
    const currentState = store.getState();
    setAuthHeader(currentState);

    try {
      const results = await axios.get(`${getApiBase()}/clinic/${dcnguid}/remittances/${pageNumber}/${pageSize}`);
      return results.data;
    } catch (reason) {}
  }

  static async getMessages(dcnguid, pageNumber, pageSize) {
    const currentState = store.getState();
    setAuthHeader(currentState);

    try {
      const results = await axios.get(`${getApiBase()}/clinic/${dcnguid}/messages/${pageNumber}/${pageSize}`);
      return results.data;
    } catch (reason) {}
  }

  static getTecOrgs() {
    return [
      {
        record_id: '1',
        tecorgid: '1',
        name: 'Beaty HealthCare Clinic',
        address: '110-5600 Blundell Rd., Richmond',
        status: 'active',
        effective: '2016-11-01',
        role: 'Walk-in Clinic'
      },
      {
        record_id: '2',
        tecorgid: '2',
        name: 'So-so Health',
        address: '101 Arbutus Rd, Vancouver',
        status: 'active',
        effective: '2019-01-05',
        role: 'Rehabilitation Centre'
      }
    ];
  }
  static getTecProviders() {
    const state = store.getState();
    if (state?.clinic?.members) {
      return state.clinic.members;
    }
    return [];
  }
  static getTecLocations() {
    return [
      {
        record_id: '1',
        LocationGuid: '1',
        name: 'Demo location',
        CprLocationId: '22112',
        status: 'active',
        effective: '2016-12-01'
      },
      {
        record_id: '2',
        LocationGuid: '2',
        name: 'Second location',
        CprLocationId: '22113',
        status: 'active',
        effective: '2019-01-05'
      }
    ];
  }
  static sendErrorReport(errorData) {
    const currentState = store.getState();
    setAuthHeader(currentState);
    const DCNGuid = currentState && currentState.user && currentState.user.details ? currentState.user.details.DCNGuid : '';
    const url = `${getApiBase()}/reports/${DCNGuid}/error/`;
    return axios
      .post(url, errorData)
      .then((results) => {
        return results.data;
      })
      .catch((reason) => {
        return { error: reason };
      });
  }

  static sendLogout() {
    const currentState = store.getState();
    setAuthHeader(currentState);
    const DCNGuid = currentState && currentState.user && currentState.user.details ? currentState.user.details.DCNGuid : '';
    const url = `${getApiBase()}/users/${DCNGuid}/logout/`;
    axios.post(url, currentState.user.details).catch((reason) => {
      return { error: reason };
    });
  }
}

export default ClinicService;
export const sendErrorReport = ClinicService.sendErrorReport;
export const sendLogout = ClinicService.sendLogout;
export const getRemittances = ClinicService.getRemittances;
export const getMessages = ClinicService.getMessages;
