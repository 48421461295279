import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

import { InputWrapper } from '../Inputs';
import { InputLabel } from '../Inputs/InputWrapper/LabelLayout';

const SecretAnswer = ({ name, handleAnswerChange }) => {
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext();

  const errorMessage = errors[name]?.message;
  const fieldAnswersList = { answer1: getValues('answer1'), answer2: getValues('answer2'), answer3: getValues('answer3') };

  return (
    <div className="flex align-items-center justify-content-between">
      <label htmlFor={name} className="text-500 text-sm w-10rem" style={{ minWidth: '8rem' }}>
        <InputLabel name={name} label="Your Answer:" required />
      </label>
      <InputWrapper name={name} style={{ height: '45px', width: '100%' }} errorMessage={errorMessage} required hideLabel>
        <Controller
          name={name}
          control={control}
          rules={{
            validate: (value) => {
              // Get answers for all other fields
              const filteredAnswers = Object.keys(fieldAnswersList)
                .filter((key) => key !== name)
                .map((key) => fieldAnswersList[key]);

              // Check if value is unique
              if (filteredAnswers.includes(value)) {
                return 'Answer must be unique';
              }
              if (!/[A-Za-z]/.test(value)) {
                return 'At least 1 letter must be in the name';
              }
              return true;
            },
            minLength: { value: 5, message: 'Min 5 characters' },
            maxLength: { value: 100, message: 'Max 100 characters' },
            pattern: {
              value: /^[A-Za-z0-9'.,!? -]+$/,
              message: 'Only latin letters allowed'
            },
            required: 'Answer is required'
          }}
          render={({ field }) => (
            <InputText
              {...field}
              id={name}
              minLength={5}
              type="text"
              onChange={(e) => handleAnswerChange(e, field)}
              className={classNames({ 'p-invalid': errors[name] }, 'w-full text-base align-items-center')}
            />
          )}
        />
      </InputWrapper>
    </div>
  );
};

export default SecretAnswer;
