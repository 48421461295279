import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { capitalizeFirstLetter } from '../helpers/validation';

const ClinicName = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <InputWrapper name="clinicName" label="Clinic Name" required errorMessage={errors.clinicName?.message}>
        <Controller
          name="clinicName"
          control={control}
          rules={{ required: 'Clinic name is required' }}
          render={({ field }) => (
            <InputText
              {...field}
              id="clinicName"
              data-testid="clinicName"
              onChange={(e) => {
                const value = e.target.value;
                field.onChange(capitalizeFirstLetter(value));
              }}
              className={classNames({ 'p-invalid': errors.clinicName })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default ClinicName;
