import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';

import DateAssistButton from '../../ActionButtons/DateAssistButton';
import BillingHistoryButton from '../../ActionButtons/BillingHistoryButton';
import ServiceDateButton from '../../TeleplanInvoiceForm/Inputs/ServiceDateButton';
import SwitchMultipleDates from '../../TeleplanInvoiceForm/Inputs/SwitchMultipleDates';

import { useCatalogTabs } from '../../../hooks/useCatalogTabs';
import { useServiceDate } from '../../../hooks/useServiceDate';
import { setToastMessage } from '../../../../../../core/actions/core.action.creators';
import { useCodePickerActions } from '../../../hooks/useCodePickerActions';
import { removeAllSpecialCharacters } from '../../../../../../regex/regex';
import { isOverMaxEntries } from '../../../helpers/isOverMaxEntries';
import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { localStorageKeys } from '../../../../../../config/localStorageKeysConfig';
import { getFromLocalStorage } from '../../../../../../utils/getFromLocalStorage';
import { catalogsCodesTooltipOptions, quickPickChipCommonClass, quickPickCodeChipCommonClass } from '../../../../../../config/stylesConfig';
import { toArray } from 'lodash';
import { t } from '../../../../../../../service/localization/i18n';
import moment from 'moment';
import cx from 'classnames';

// interface = {
//   item: {
//     name: string,
//     label: string,
//     codeDescription: string,
//     codeType: string,
//   },
//   mostRecentCodes: {
//     amoun: number | string
//     label: string
//     order: number
//     value: string
//   } [],
//   showActionButtons?: boolean
// }

const QuickPickItem = (props) => {
  const name = props.item.name;
  const codeType = props.item.codeType;
  const dispatch = useDispatch();
  const { isMobile } = useSelector((state) => state.core.window);
  const { gettingWSBCReport } = useSelector((state) => state.claims);
  const { watch, isUnlimitedEntriesForFee, localState } = useFormContext();
  const { onTabChange, catalogTabs } = useCatalogTabs();
  const { onChange } = useCodePickerActions(props.item);
  const { onChangeServiceDate, onChangeMultipleServiceDate } = useServiceDate();
  const isFetching = gettingWSBCReport || localState.gettingRecentCodes;
  const length = props.mostRecentCodes?.length || 20;
  const mostRecentCodes = isFetching ? Array.from({ length }, () => 'item') : props.mostRecentCodes;
  const serviceDate = watch(inputs.serviceDate.name);
  const showMultipleDates = localState.isMultipleDates;

  const [switchReferredByButtonLabel, setSwitchReferredByButtonLabel] = useState(
    getFromLocalStorage(localStorageKeys.displayReferredByNames) ? t('Show_codes') : t('Show_names')
  );

  const isCodeSelected = (code) => {
    const formStateValue = watch(name);

    if (name === inputs.serviceDate.name) {
      if (showMultipleDates) {
        const dates = toArray(formStateValue);
        return dates?.find((i) => moment(i).isSame(code, 'day'));
      } else {
        return moment(formStateValue).format('YYYY-MM-DD') === moment(code).format('YYYY-MM-DD');
      }
    }

    const formattedCode = code?.toUpperCase()?.trim();
    return toArray(formStateValue).some((i) => i?.toUpperCase()?.trim() === formattedCode);
  };

  const onCodeClick = (code) => {
    // On select Service Date
    if (name === inputs.serviceDate.name && code) {
      const selectedDate = moment(code.value).toDate();
      const isSelected = !!isCodeSelected(code.value);

      if (showMultipleDates) {
        const updatedDates = isSelected ? serviceDate.filter((date) => !moment(date).isSame(selectedDate, 'day')) : [...serviceDate, selectedDate];
        return onChangeMultipleServiceDate(updatedDates);
      } else {
        const updatedDate = isSelected ? null : selectedDate;
        return onChangeServiceDate(updatedDate);
      }
    }

    if ((name === inputs.feeCodes.name || name === inputs.icd9.name) && code) {
      // Show a warning message if the user tries to add more codes than the maximum number of entries
      if (isOverMaxEntries(watch(name), name, isUnlimitedEntriesForFee) && !isCodeSelected(code.value)) {
        return dispatch(setToastMessage({ type: 'warn', message: `${t('Maximum_number_of_entries_is_X')} ${props.item.maxEntries}` }));
      }

      const formattedCodeList = () => {
        const currentList = watch(codeType) || [];

        // Unselect code
        if (!!isCodeSelected(code.value)) return currentList.filter((i) => i.value !== code.value);

        // Select code
        return [...currentList, code];
      };

      onChange({ value: formattedCodeList() }, false);

      return;
    }

    // Unselect code
    if (!!isCodeSelected(code.value)) return onChange({ value: [] }, false);

    return onChange({ value: [code] }, false);
  };

  const onViewMore = () => {
    const _catalogIndex = catalogTabs?.findIndex((i) => i.codeType === props.item?.codeType);
    onTabChange({ index: _catalogIndex });
  };

  const onSwitchReferral = () => {
    const key = localStorageKeys.displayReferredByNames;
    setSwitchReferredByButtonLabel(getFromLocalStorage(key) ? t('Show_names') : t('Show_codes'));
    localStorage.setItem(key, !getFromLocalStorage(key));
  };

  const codeItem = (i, index) => {
    const isSelected = !!isCodeSelected(i?.value);
    const itemClass = cx(
      `${name}_${elementIDs.quickPickCodeItem}`, // this class needs for cypress tests only
      quickPickCodeChipCommonClass(isSelected, i)
    );

    return (
      <Button
        id={`${name}_${elementIDs.quickPickCodeItem}_${codeLabel(i)?.replaceAll(removeAllSpecialCharacters, '')}`}
        className={itemClass}
        key={`${i?.value}_${index}`}
        label={codeLabel(i)}
        tooltip={tooltip(i)}
        tooltipOptions={catalogsCodesTooltipOptions(isMobile)}
        onClick={() => onCodeClick(i)}
      />
    );
  };

  // // FOR TESTS //
  // const codeItem = (i, index) => {
  //   const target = `${elementIDs.quickPickCodeItem}_${i?.value?.replaceAll(removeAllSpecialCharacters, '')}_${index}`;
  //   const isSelected = !!isCodeSelected(i?.value);
  //   const itemClass = cx(
  //     `${name}_${elementIDs.quickPickCodeItem}`, // this class needs for cypress tests only
  //     quickPickCodeChipCommonClass(isSelected, i)
  //   );

  //   return (
  //     <div key={`${i?.value}_${index}`}>
  //       <Tooltip style={{ maxWidth: '120px' }} target={`.${target}`} position='top' showDelay={1000} hideDelay={999999}>
  //         {tooltip(i)}
  //       </Tooltip>

  //       <div className={cx(`${target}`)}>
  //         <Button
  //           id={`${name}_${elementIDs.quickPickCodeItem}_${codeLabel(i)?.replaceAll(removeAllSpecialCharacters, '')}`}
  //           className={itemClass}
  //           label={codeLabel(i)}
  //           onClick={() => onCodeClick(i)}
  //         />
  //       </div>
  //     </div>
  //   )
  // };

  const codeItemSkeleton = () => {
    const skeletons = mostRecentCodes?.map((_, index) => {
      return <Skeleton key={index} borderRadius="2rem" width="56px" height="24px" />;
    });

    return (
      <div className="flex flex-wrap" style={{ rowGap: '0.5rem', columnGap: '0.5rem' }}>
        {skeletons}
      </div>
    );
  };

  const codesLayout = () => {
    if (isFetching) return codeItemSkeleton();
    return mostRecentCodes?.map((i, index) => codeItem(i, index));
  };

  const tooltip = (i) => {
    // CMO-1676 - Display names instead of numbers in Referring practitioner on Quick Pick
    if (props.item?.showSwitchButton) {
      const codeValue = !i?.label || i?.label === 'N/A' ? i?.name : i?.value;
      return getFromLocalStorage(localStorageKeys.displayReferredByNames) ? codeValue : i?.label;
    }

    if (props.item?.codeType === inputs.serviceDate.codeType) return i.weekday;
    if (props.item?.codeType === inputs.referral.codeType) return i.name;

    return i.label;
  };

  const codeLabel = (i) => {
    // CMO-1676 - Display names instead of numbers in Referring practitioner on Quick Pick
    if (props.item?.showSwitchButton) {
      const label = !i?.label || i?.label === 'N/A' ? i?.value : i?.ShortName;
      return getFromLocalStorage(localStorageKeys.displayReferredByNames) ? label : i?.value;
    }

    if (name === inputs.serviceDate.name) return i.label;

    return i.value;
  };

  const billingHistoryButton = () => {
    if (props.item.name !== inputs.feeCodes.name) return null;
    return <BillingHistoryButton />;
  };

  const openCatalogButton = (
    <Button
      id={`${name}_viewMore`}
      className={cx(quickPickChipCommonClass, 'p-button-text p-button-info')}
      label={t('Open_Catalog')}
      iconPos="right"
      onClick={onViewMore}
    />
  );

  if (!mostRecentCodes?.length) {
    return (
      <div id={elementIDs.noResentCodes} className="flex align-items-center gap-2">
        <div className="pr-2">{t('No_recent_codes')}</div>

        {name !== inputs.serviceDate.name && openCatalogButton}
        {billingHistoryButton()}
      </div>
    );
  }

  return (
    <div className="quick_pick_items_list">
      {codesLayout()}

      {props.item.showServiceDateButton && (
        <div className="flex align-items-center gap-4">
          <ServiceDateButton />
          <SwitchMultipleDates />
        </div>
      )}
      {props.item.showDateAssistButton && <DateAssistButton />}

      {props.item.openCatalogButton && props.showActionButtons && openCatalogButton}

      {billingHistoryButton()}

      {/* CMO-1676 - Display names instead of numbers in Referring practitioner on Quick Pick */}
      {props.item.showSwitchButton && props.showActionButtons && (
        <Button
          id={`${props.item?.codeType}_switch`}
          className={cx(quickPickChipCommonClass, 'p-button-text p-button-info')}
          label={switchReferredByButtonLabel}
          icon="pi pi-sync"
          iconPos="right"
          onClick={onSwitchReferral}
        />
      )}
    </div>
  );
};

export default QuickPickItem;
