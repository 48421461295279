import React, { useState } from 'react';
import { InputWrapper } from '..';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { dropdownScrollHeight } from '../../../modules/config/stylesConfig';
import { useSelector } from 'react-redux';
import { practiceTypeDropdownValidation } from '../../../modules/registration/components/helpers/validation';

// interface = {
//   name: string
// }

// this Component should be used only by useFormContext and wrapped in react-hoock-form <FormProvider> for gettig of useFormContext

const PracticeType = ({ name }) => {
  const ref = React.createRef();
  const practiceTypeList = useSelector((state) => state.user.practiceTypeList);
  const formatedPracticeTypeList = () => practiceTypeList?.map((item) => item.name);
  const defaultPracticeType = formatedPracticeTypeList();

  const [suggestions, setSuggestions] = useState([]);

  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
    trigger
  } = useFormContext();
  const errorMessage = errors[name]?.message;

  const onSearch = (event) => {
    if (event.query.trim() === '') {
      setSuggestions(formatedPracticeTypeList().sort((a, b) => a.localeCompare(b)));
    } else {
      const filtered = defaultPracticeType.filter((item) => {
        return item.toLowerCase().includes(event.query.toLowerCase());
      });
      if (filtered.length) {
        errorMessage && clearErrors(name);
      } else {
        setError(name, { type: 'custom', message: 'No results found' });
      }
      setSuggestions(filtered);
    }
  };

  return (
    <>
      <InputWrapper
        name="practiceType"
        label="Specialty"
        required
        errorMessage={errorMessage}
        style={{ height: 'auto', width: '100%', marginBottom: '15px' }}
      >
        <Controller
          name="practiceType"
          control={control}
          rules={{ validate: practiceTypeDropdownValidation }}
          render={({ field }) => (
            <AutoComplete
              {...field}
              data-testid="practiceType-testid"
              ref={ref}
              inputRef={field.ref}
              suggestions={suggestions}
              dropdown
              // forceSelection // Brocken in current vertion of primeReact (v9.2.0)
              multiple
              autoHighlight
              scrollHeight={dropdownScrollHeight()}
              completeMethod={onSearch}
              className={classNames({ 'p-invalid': errors[name] })}
              onFocus={(e) => {
                ref.current?.search(e, '', 'dropdown');
              }}
              onClear={() => {
                if (field.value.length) {
                  clearErrors(name);
                } else {
                  setError(name, { type: 'custom', message: 'Practice type is required.' });
                }
              }}
              onBlur={() => {
                trigger(name);
              }}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default PracticeType;
