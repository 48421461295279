import React from 'react';
import { useHistory } from 'react-router';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { steps } from '../../config/steps';
import { createPrivateInvoice } from '../../../../actions/claims.action.creators';
import { currencyFormat } from '../../../../../utils/currencyFormat';
import { defaultGuid } from '../../../../../config/defaultValuesConfig';
import { isEClaimStatusEOB } from '../../helpers/getEClaimStatus';
import { inputs } from '../../helpers/inputs';
import { onEditInvoiceClick } from '../../../../helpers/rowActions';
import { t } from '../../../../../../service/localization/i18n';
import cx from 'classnames';
import moment from 'moment';

const ResultsPreviewActionButtons = ({ eClaimResult }) => {
  const history = useHistory();
  const { localState, setLocalState, getValues } = useFormContext();
  const owes = eClaimResult?.FeeBilled - eClaimResult?.FeeAccepted;
  const showInvoicePatient = Number(owes) > 0;
  const replyType = eClaimResult.ReplyType;
  const isEOB = isEClaimStatusEOB(replyType);

  const onClose = () => {
    setLocalState((prevState) => ({ ...prevState, step: steps.success }));
  };

  const onInvoice = async () => {
    setLocalState((prevState) => ({ ...prevState, creatingPrivateInvoice: true }));
    const serviceDate = Array.isArray(eClaimResult?.ServiceDate) ? eClaimResult?.ServiceDate?.[0] : eClaimResult?.ServiceDate;
    const formattedServiceDate = moment(serviceDate).format('YYYY-MM-DD');
    const serviceList = getValues(inputs.services.name);
    const srviceListToArray = Array.isArray(serviceList) ? serviceList : [serviceList];
    const updatedServicesList = srviceListToArray?.map((i) => ({ ...i, label: 'eClaims Balance' }));

    const payload = {
      BillToGuid: eClaimResult.PatientGuid,
      ClaimGuid: defaultGuid,
      DCNGuid: eClaimResult.DCNGuid,
      DoctorGuid: eClaimResult.DoctorGuid,
      FeeTotal: owes,
      InvoiceDate: formattedServiceDate,
      InvoiceGuid: defaultGuid,
      InvoiceType: 'P',
      PatientList: [eClaimResult.PatientGuid],
      PayToGuid: eClaimResult.DoctorGuid,
      ServiceDate: formattedServiceDate,
      ServicesList: updatedServicesList,
      Speciality: getValues(inputs.speciality.name),
      Status: 0
    };

    const result = await createPrivateInvoice(payload);
    setLocalState((prevState) => ({ ...prevState, creatingPrivateInvoice: false }));
    if (result) onEditInvoiceClick({ data: result, history, showOnViewPanel: true });
  };

  const handleRevoke = () => {
    setLocalState((prevState) => ({ ...prevState, revokeDialog: { showDialog: true, data: eClaimResult } }));
  };

  return (
    <div className="flex justify-content-center">
      <div className="flex gap-3">
        {showInvoicePatient && (
          <Button
            type="button"
            label={`${t('Invoice_patient')} for ${currencyFormat(owes)}`}
            loading={localState.creatingPrivateInvoice}
            onClick={onInvoice}
          />
        )}

        {isEOB && (
          <Button
            type="button"
            className="p-button-outlined w-9rem"
            label={t('Revoke')}
            disabled={localState.creatingPrivateInvoice}
            onClick={handleRevoke}
          />
        )}

        <Button
          type="button"
          className={cx('w-9rem', showInvoicePatient ? 'p-button-outlined' : '')}
          label={t('Close')}
          disabled={localState.creatingPrivateInvoice}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default ResultsPreviewActionButtons;
