import React from 'react';
import { useSelector } from 'react-redux';
import { Message } from 'primereact/message';
import ChangePassword from '../../../../components/Widgets/ChangePassword/ChangePassword';
import { routes } from '../../../../routes/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';

const PasswordUpdate = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user.details);

  const onSuccessSubmit = () => {
    // Redirect to login page after successful password update
    history.push(routes.signIn.path);
  };

  return (
    <AuthFormWrap>
      <div className="m-0 my-4 text-800 w-full text-center align-items-center font-bold text-3xl">Update password</div>
      <div className="login-panel-content col-12 lg:col-12">
        <Message
          icon="pi pi-exclamation-triangle"
          className="w-full mb-3"
          severity="warn"
          text="It's time to update your password. Please enter your new password below to ensure your account remains secure."
        />
        <ChangePassword
          dcnGuid={user.DCNGuid}
          doctorGuid={user.UserGuid}
          onSuccessSubmit={onSuccessSubmit}
          onCancel={() => history.push(routes.signIn.path)}
        />
      </div>
    </AuthFormWrap>
  );
};
export default PasswordUpdate;
