import React from 'react';

import { Tooltip } from 'primereact/tooltip';

import EligibilityMessage from '../../../patients/components/commonElements/EligibilityMessage';
import AddressLayout from '../../../../components/Layouts/AddressLayout';

import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { email_types, phone_types } from '../../../config/constants';
import { t } from '../../../../service/localization/i18n';
import classes from './DesktopRowExpansionTemplate.module.scss';
import cx from 'classnames';

const DesktopRowExpansionTemplate = ({ rowData, mobileView }) => {
  const emailTypeTooltip = email_types.find((i) => i.value.toUpperCase() === rowData?.EmailType.toUpperCase())?.label;
  const phoneTypeTooltip = phone_types.find((i) => i.value.toUpperCase() === rowData?.PhoneType.toUpperCase())?.label;

  return (
    <div className={mobileView ? classes.rootWrapMobile : classes.rootWrap} id={'exp_' + rowData?.PatientGuid}>
      <div className={cx('flex p-0 mb-5', mobileView ? classes.eligibilityRootMobile : classes.eligibilityRoot)}>
        {rowData?.Eligibility?.Parsed && !rowData?.Eligibility?.Parsed?.ERROR ? (
          <div className="field white-space-normal">
            <EligibilityMessage
              eligibility={rowData?.Eligibility?.Parsed}
              showIcon={true}
              showMessageColor={false}
              showInRowExpantion={true}
              showFullCheckOnDate={false}
            />
          </div>
        ) : (
          <>{t('No_MSP_coverage_report')}</>
        )}
      </div>

      <div className="flex">
        <i className="pi pi-home mr-3 mt-1" />

        <div className={cx('p-0', classes.addressWrap)}>
          {/* CONTACT INFO */}
          <>
            {!rowData?.Email && !rowData?.Phone ? (
              <div className="mb-1">{t('No_email_or_phone_number')}</div>
            ) : (
              <div className="mb-1">
                {rowData?.Email && (
                  <div className="mb-1">
                    {rowData?.EmailType ? (
                      <div className="flex">
                        <Tooltip target=".emailType" />
                        <span className="mr-2">{rowData.Email}</span>
                        <span className={cx('emailType', classes.typeWrap)} data-pr-tooltip={emailTypeTooltip}>
                          {`(${rowData?.EmailType.toLowerCase()})`}
                        </span>
                      </div>
                    ) : (
                      <span>{rowData?.Email}</span>
                    )}
                  </div>
                )}

                {rowData?.Phone && (
                  <div className="mb-1">
                    {rowData?.PhoneType ? (
                      <>
                        <Tooltip target=".phoneType" />
                        <span className="mr-2">{formatPhoneNumber(rowData?.Phone)}</span>
                        <span className={cx('phoneType', classes.typeWrap)} data-pr-tooltip={phoneTypeTooltip}>
                          {`(${rowData?.PhoneType.toLowerCase()})`}
                        </span>
                      </>
                    ) : (
                      <span>{formatPhoneNumber(rowData?.Phone)}</span>
                    )}
                  </div>
                )}
              </div>
            )}
          </>

          {/* ADDRESS */}
          <AddressLayout data={rowData} />
        </div>
      </div>
    </div>
  );
};

export default DesktopRowExpansionTemplate;
