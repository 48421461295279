import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { practitionerDefaultValues } from '../../components/helpers/practitionerDefaultValues';
import FirstName from '../inputs/FirstName';
import LastName from '../inputs/LastName';
import Email from '../inputs/Email';
import EmailRepeat from '../inputs/EmailRepeat';
import PhoneNumber from '../inputs/PhoneNumber';
import PractitionerType from '../inputs/PractitionerType';
import CollegeLicenceNumber from '../../../../components/Inputs/CollegeLicenceNumber/CollegeLicenceNumber';
import PracticeType from '../../../../components/Inputs/PracticeType/PracticeType';
import StreetAddress from '../inputs/StreetAddress';
import City from '../inputs/City';
import Province from '../inputs/Province';
import PostalCode from '../inputs/PostalCode';
import MspPractitionerNumber from '../../../../components/Inputs/MspPractitionerNumber/MspPractitionerNumber';
import MspPayeeNumber from '../../../../components/Inputs/MspPayeeNumber/MspPayeeNumber';
import TeleplanDataCenterNumber from '../inputs/TeleplanDataCenterNumber';
import BillingSoftware from '../inputs/BillingSoftware';
import DateClaims from '../inputs/DateClaims';
import HearAboutUs from '../inputs/HearAboutUs';
import RegistrationPassword from '../inputs/RegistrationPassword';
import ConfirmPassword from '../inputs/ConfirmPassword';
import LicencingsBodies from '../../../../components/Inputs/LicencingsBodies/LicencingsBodies';
import { InputGroup } from '../../../../components/Inputs';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { saveRegistrationData } from '../../../../service/Lookup';
import RegistrationCard from '../RegistrationCard';
import { updateRegistrationFormState } from '../../actions/registration.actions.creators';
import { routes } from '../../../../routes/routes';
import { specialtyLicencingsBodies } from '../helpers/registrationFormsHelpers';
import { store } from '../../../../store';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { isDevelopment } from '../../../../config';

const PractitionerFormSole = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const registrationState = useSelector((state) => state.registration.registrationFormState);
  const methods = useForm({
    mode: 'all',
    defaultValues: { ...practitionerDefaultValues, ...registrationState.initialFormState }
  });
  const { handleSubmit, reset, watch, getValues, formState, control } = methods;

  const onSubmit = async (data) => {
    const sessionGuid = uuidv4();
    try {
      const payload = { ...data, sessionGuid, ReferenceCode: registrationState.ReferenceCode };
      await saveRegistrationData(payload);
      dispatch(
        updateRegistrationFormState({
          ...registrationState,
          type: 'secret-questions',
          initialFormState: { ...data, sessionGuid }
        })
      );
    } catch {
      store.dispatch(setToastMessage({ type: 'error', message: 'Opps, something went wrong' }));
    }
  };

  const Autofill = () => {
    reset({
      firstName: 'Bilbo',
      lastName: 'Begin',
      email: 'zeevnadiv@gmail.com',
      repeatEmail: 'zeevnadiv@gmail.com',
      phoneNumber: '7784889955',
      practitionerType: 'Nurse',
      streetAddress: 'via Roma',
      city: 'Milan',
      Zip: 'A1A 1A1',
      password: 'Qwerty@99',
      confirmPassword: 'Qwerty@99'
    });
  };

  const onClear = () => {
    reset(practitionerDefaultValues);
  };

  const onSkip = () => {
    history.push(routes.signIn.path);
  };

  const onBack = () => {
    dispatch(
      updateRegistrationFormState({
        ...registrationState,
        type: 'register-type',
        initialFormState: { ...getValues(), password: '', confirmPassword: '' }
      })
    );
  };

  const practitionerType = watch('practitionerType');
  const showLicensingBodies = specialtyLicencingsBodies(practitionerType); // used to set the check condition for rendering LicencingsBodies

  return (
    <FormProvider {...methods}>
      <RegistrationCard
        customersType="Sole Practitioner Registration"
        description="Select this option if you are a practitioner. If you are a medical office assistant (MOA), go back and select Clinic or Billing Bureau."
      >
        <form onSubmit={handleSubmit(onSubmit)} className="grid formgrid">
          <InputGroup>
            <FirstName label="Practitioner's First Name" />
            <LastName label="Practitioner's Last Name" />
          </InputGroup>
          <InputGroup>
            <Email control={control} requiredStatus={true} />
            <EmailRepeat />
          </InputGroup>
          <InputGroup>
            <PhoneNumber control={control} requiredStatus={true} />
            <PractitionerType />
          </InputGroup>
          {showLicensingBodies && <LicencingsBodies name="licensingBody" />}
          <InputGroup>
            <CollegeLicenceNumber />
            <TeleplanDataCenterNumber label="Teleplan Data Center Number" />
          </InputGroup>
          {practitionerType === 'Physician' && <PracticeType name="practiceType" />}
          <InputGroup>
            <StreetAddress label="Street Address" requiredStatus={true} />
            <City requiredStatus={true} />
          </InputGroup>
          <InputGroup>
            <Province requiredStatus={true} />
            <PostalCode requiredStatus={true} />
          </InputGroup>
          <InputGroup>
            <MspPractitionerNumber />
            <MspPayeeNumber />
          </InputGroup>

          <InputGroup>
            <BillingSoftware />
            <DateClaims />
          </InputGroup>

          <HearAboutUs />
          <InputGroup>
            <RegistrationPassword />
            <ConfirmPassword />
          </InputGroup>

          <div className="flex gap-3 aling-items-center justify-content-center mt-6 mb-4 w-full">
            <Button data-testid="nextButtonPractitionerSole-testid" type="submit" className="h-3rem" label="Next" loading={formState.isSubmitting} />
            <Button data-testid="clearButtonPractitionerSole-testid" onClick={onClear} type="button" className="h-3rem" label="Clear" outlined />
            <Button data-testid="cancelButtonPractitionerSole-testid" onClick={onSkip} type="button" className="h-3rem" label="Cancel" outlined />
            <Button data-testid="backButtonPractitionerSole-testid" type="button" onClick={onBack} className="h-3rem" label="Back" outlined />
            {isDevelopment && <Button onClick={Autofill} type="button" className="h-3rem bg-yellow-300" label="Autofill" outlined />}
          </div>
        </form>
      </RegistrationCard>
    </FormProvider>
  );
};

export default PractitionerFormSole;
