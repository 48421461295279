import React from 'react';
import { Badge } from 'primereact/badge';

const CreditStatusRowTemplate = ({ rowData }) => {
  const creditStatusText = [
    { creditStatus: -1, creditStatusText: 'Not eligible' },
    { creditStatus: 0, creditStatusText: 'Not used' },
    { creditStatus: 1, creditStatusText: 'Eligible' },
    { creditStatus: 2, creditStatusText: 'Used' }
  ];

  const checkCreditStatus = (incomCreditStatus) => {
    const match = creditStatusText.find((item) => item.creditStatus === incomCreditStatus);
    return match ? match.creditStatusText : null;
  };

  return <Badge value={checkCreditStatus(rowData.CreditStatus)} severity={rowData.CreditStatus === 0 ? 'danger' : 'success'} />;
};

export default CreditStatusRowTemplate;
