import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import RepresentativeFormLayout from './RepresentativeFormLayout';
import { formatPhoneNumber } from '../../../modules/utils/formatPhoneNumber';
import { formDefaultValues } from './helpers/defaultValues';
import { onRepresentativeSubmit } from './helpers/onSubmit';
import { defaultAddressType, defaultCountryCode } from '../../../modules/config/defaultValuesConfig';

const RepresentativeWithFormProvider = ({ patient, initialState, onSuccessCreate, onSuccessEdit, onClose, autoFocus }) => {
  // Compute the default values for the form
  const computedDefaultValues = useMemo(() => {
    if (!initialState) return formDefaultValues();

    return {
      ...formDefaultValues(),
      ...initialState,
      Country: initialState?.Country || defaultCountryCode,
      AddressType: initialState?.AddressType || defaultAddressType,
      Phone: formatPhoneNumber(initialState.Phone),
      EmailType: initialState.EmailType || formDefaultValues().EmailType,
      PhoneType: initialState.PhoneType || formDefaultValues().PhoneType
    };
  }, [initialState]);

  // Initialize the form with computed default values
  const methods = useForm({
    defaultValues: computedDefaultValues
  });

  // If the initialState updates asynchronously, reset the form values
  useEffect(() => {
    methods.reset(computedDefaultValues);
  }, [computedDefaultValues, methods]);

  const onSubmit = async (data) => {
    await onRepresentativeSubmit(data, { onSuccessCreate, onSuccessEdit, patient });
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form id="representative-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <RepresentativeFormLayout onClose={onClose} autoFocus={autoFocus} />
      </form>
    </FormProvider>
  );
};

RepresentativeWithFormProvider.propTypes = {
  patient: PropTypes.object.isRequired,
  initialState: PropTypes.object.isRequired,
  onSuccessCreate: PropTypes.func.isRequired,
  onSuccessEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool // autoFocus is optional
};

export default RepresentativeWithFormProvider;
