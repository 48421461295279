import React, { useContext } from 'react';
import Context from '../../../../../Context';
import { inputs } from '../claimDetailsHelpers/inputs';
import { useDispatch } from 'react-redux';
import { updatePrivateRecord } from '../../../actions/claims.action.creators';
import { getPayeeOptions, processPayeeData } from '../../../../utils/getPayeeInfo';
import { defaultGuid } from '../../../../config/defaultValuesConfig';

// interface PayeeInputWrapperProps {
//   Component: React.Component;
//   name: 'address' | 'phone' | 'email' | 'payee';
//   autoFocus: boolean;
// }

const PayeeInputWrapper = ({ Component, name, autoFocus }) => {
  const dispatch = useDispatch();
  const { privateRecord } = useContext(Context);
  const payeeInfo = privateRecord?.[inputs().payeeInfo.name] || [];
  const payeeOptions = getPayeeOptions();

  const currentPayee = payeeInfo.find((item) => item.id === name);
  const payeeId = currentPayee?.value;
  const value = payeeOptions.find((option) => option.id === payeeId) || payeeOptions.find((option) => option.id === defaultGuid);

  const onChange = (e) => {
    const selectedValue = e.value;

    const updatedPayeeInfo = payeeInfo.map((item) => {
      if (item.id === name) {
        return {
          id: item.id,
          value: selectedValue.id,
          label: processPayeeData(item.id, selectedValue)
        };
      }
      return item;
    });

    const updatedRecord = {
      [inputs().payeeInfo.name]: updatedPayeeInfo,
      ...(name === 'payee' && { [inputs().payTo.name]: selectedValue.data })
    };

    dispatch(updatePrivateRecord(updatedRecord));
  };

  return <Component value={value} onChange={onChange} autoFocus={autoFocus} />;
};

export default PayeeInputWrapper;
