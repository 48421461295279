import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProvincePicker from '../../../../../components/Inputs/Province/index';

import { columnValues } from '../helpers/columnValues';
import { getPatients } from '../../../actions/patients.action.creators';
import { canadaProvincesListWithInstituonal } from '../../../../config/dropdownListsConfig';

const ProvinceFilter = ({ showInSidebar = false }) => {
  const currentField = columnValues.province.field;

  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.patients);
  const [value, setValue] = useState(filters[currentField] || '');

  // Update local state when chip is deleted
  useEffect(() => {
    const initValue = canadaProvincesListWithInstituonal.find((i) => i.value === filters[currentField])?.value || '';
    setValue(initValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters[currentField]]);

  const onChange = (e) => {
    const province = e.target.value;
    const updatedFilters = { ...filters, [currentField]: province };
    setValue(province);
    dispatch(getPatients({ filters: updatedFilters, page: 0 }));
  };

  return (
    <ProvincePicker
      name={columnValues.province.id}
      value={value}
      placeholder={showInSidebar ? columnValues.province.sidebarPlaceholder : columnValues.province.placeholder}
      // optionLabel="value"
      onChange={onChange}
    />
  );
};

export default ProvinceFilter;
