import React from 'react';
import { currencyFormat } from '../../../../../../utils/currencyFormat';

const BilledTemplate = ({ rowData = {}, style = {} }) => {
  const billed = rowData?.billed ?? 0; // Default to 0 if billed is undefined or null
  const formattedBilled = currencyFormat(isNaN(billed) ? 0 : billed); // Format billed, default to 0 if NaN

  return (
    <div
      className="flex justify-content-end"
      style={{ marginRight: '25%', ...style }} // Merge custom styles with default margin
    >
      {formattedBilled}
    </div>
  );
};

export default BilledTemplate;
