import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import SecretQuestion from './SecretQuestion';
import SecretAnswer from './SecretAnswer';
import { Button } from 'primereact/button';
import { t } from '../../service/localization/i18n';

const SecretQuestionsBody = () => {
  const methods = useForm();
  const { formState } = methods;

  const handleQuestionChange = (e, field) => {
    field.onChange(e.target.value);
  };

  const handleAnswerChange = (e, field) => {
    const selectedValue = e.target.value;
    field.onChange(selectedValue);
  };

  return (
    <div className="flex flex-column auth-form gap-4 max-w-full">
      <SecretQuestion title={'Question 1'} name={'question1'} handleQuestionChange={handleQuestionChange} />
      <SecretAnswer name={'answer1'} handleAnswerChange={handleAnswerChange} />

      <SecretQuestion title={'Question 2'} name={'question2'} handleQuestionChange={handleQuestionChange} />
      <SecretAnswer name={'answer2'} handleAnswerChange={handleAnswerChange} />

      <SecretQuestion title={'Question 3'} name={'question3'} handleQuestionChange={handleQuestionChange} />
      <SecretAnswer name={'answer3'} handleAnswerChange={handleAnswerChange} />

      <div className="flex gap-3 mb-3 h-3rem w-full">
        <Button id="submit" className="w-full" label={t('Next')} type="submit" loading={formState.isSubmitting} />
      </div>
    </div>
  );
};

export default SecretQuestionsBody;
