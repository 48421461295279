import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';

import { Button } from 'primereact/button';
import { checkAppliedFilters } from '../utils/checkAppliedFilters';

import classes from './LegoSearchPanel.module.scss';
import cx from 'classnames';
import { t } from '../../service/localization/i18n';
import { useSelector } from 'react-redux';
import LockClaimFilters from '../claims/components/LockClaimFilters';
import { isMoneyPage } from '../../routes/routeMatchers';

const LegoSearchPanel = ({
  onAddClick,
  searchPanelComponent,
  filters,
  setShowSidebarFilters,
  isMobile,
  showOnPatientDetailsScreen,
  layoutMode,
  iPadProInLandscape,
  showAddNewOverlay = true,
  addNewOverlayItems = []
}) => {
  const menu = useRef(null);
  const { isFetchingPatients } = useSelector((state) => state.patients);

  return (
    <div
      className={cx(
        'p-inputgroup col-12 p-0',
        showOnPatientDetailsScreen
          ? classes.showOnPatientDetailsScreenRoot
          : iPadProInLandscape && layoutMode === 'overlay'
          ? classes.iPadProInLandscapeOverlayRoot
          : iPadProInLandscape && layoutMode === 'static'
          ? classes.iPadProInLandscapeStaticRoot
          : isMobile
          ? classes.rootMobile
          : classes.root
      )}
    >
      <Menu model={addNewOverlayItems} popup ref={menu} id="popup_menu" />

      {searchPanelComponent()}

      {!isMobile && (
        <div className="flex align-items-center">
          {showAddNewOverlay ? (
            <>
              {/* <SplitButton
                className={classes.splitButton}
                label={t("New_claim")}
                model={addNewOverlayItems}
                onClick={onAddClick}
              /> */}
              <Button
                className="w-max"
                style={{ height: '42px' }}
                icon="pi pi-plus"
                label={t('Add_New')}
                aria-controls="popup_menu"
                aria-haspopup
                disabled={isFetchingPatients}
                onClick={(e) => menu.current.toggle(e)}
              />
            </>
          ) : (
            <Button
              className="w-max"
              style={{ height: '42px' }}
              icon="pi pi-plus"
              label={t('Add_New')}
              disabled={isFetchingPatients}
              onClick={onAddClick}
            />
          )}
          <Button
            className="w-max pr-5"
            style={{ height: '42px' }}
            text
            label={t('Filters')}
            icon="pi pi-filter"
            onClick={() => setShowSidebarFilters(true)}
          />

          {isMoneyPage() && (
            <div className="pr-5">
              <LockClaimFilters />
            </div>
          )}
        </div>
      )}

      {isMobile && (
        <>
          {showAddNewOverlay ? (
            // <SplitButton
            //   className={classes.splitButton}
            //   icon="pi pi-plus"
            //   model={addNewOverlayItems}
            //   onClick={onAddClick}
            // />
            <Button
              icon="pi pi-plus"
              aria-controls="popup_menu"
              aria-haspopup
              disabled={isFetchingPatients}
              onClick={(event) => menu.current.toggle(event)}
            />
          ) : (
            <Button icon="pi pi-plus" disabled={isFetchingPatients} onClick={() => onAddClick()} />
          )}
          <span className={cx('p-overlay-badge', classes.bageWrap)}>
            <Button className={cx('p-button-text', classes.bageWrap)} icon="pi pi-filter" onClick={() => setShowSidebarFilters(true)} />
            {checkAppliedFilters(filters).length > 0 && (
              <span className={cx('p-badge p-badge-warning', classes.badgeIcon)}>{checkAppliedFilters(filters).length}</span>
            )}
          </span>
        </>
      )}
    </div>
  );
};

export default LegoSearchPanel;
