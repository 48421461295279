import React from 'react';
import { useDispatch } from 'react-redux';

import InfinitScrollPanel from '../../../Misc/ScrollPanel/InfinitScrollPanel';
import HighlightSearchQuery from '../../../Misc/HighlightSearchQuery/HighlightSearchQuery';

import { useCatalogsContext } from '../../hooks/useCatalogsContext';
import { elementIDs } from '../../../../modules/config/elementIDsConfig';
import { removeAllSpecialCharacters } from '../../../../modules/regex/regex';
import { commonInputs } from '../../../../modules/config/commonInputsConfig';
import { setToastMessage } from '../../../../modules/core/actions/core.action.creators';
import { setFocusToElement } from '../../../../modules/utils/setFocusToElement';
import { useRequests } from '../../hooks/useRequests';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

const CatalogsDataScroller = (props) => {
  const dispatch = useDispatch();
  const recentMenuItem = 'Recent';
  const { getCatalogCodesList } = useRequests();
  const {
    catalogsState,
    setCatalogsState,
    onSelectCodeFromCatalogsTable,
    dataScrollerItemTemplate,
    catalogsTableValues,
    getSelectedCodes,
    slideMenuRef
  } = useCatalogsContext();

  const handleCodeSelect = (code) => {
    const params = { catalogsState, setCatalogsState, slideMenuRef: slideMenuRef };
    const currentCode = getSelectedCodes(catalogsState?.activeTab) || [];

    // Show toast message if code already added
    if (currentCode?.some((i) => i?.value.toString() === code.value.toString())) {
      return dispatch(setToastMessage({ type: 'info', message: String.format(t('already_added'), code.value) }));
    }

    setFocusToElement(elementIDs.catalogsSearch); // CMO-2904 - Return the cursor to search field on Fee, Dx, and Referral and other wsbc catalog
    onSelectCodeFromCatalogsTable(code, params);
  };

  const dataScrollerItemTemplateView = (data) => {
    const highlightRowForDxParentCodes =
      !data?.value?.includes('.') &&
      catalogsState?.activeTab?.name === commonInputs.icd9.name &&
      catalogsState.trail[0]?.label !== recentMenuItem &&
      catalogsState.trail[0]?.label === 'All'; // catalogsState.trail[0]?.label === "All" - to fix CMO-1536 - remove bold font in some Dx catalogs (Popular folder)
    const _rootClasses = cx('dataScrollerItemRootHighlight', highlightRowForDxParentCodes ? 'dataScrollerItemRoot' : '');
    const codeId = `${elementIDs.catalogsDataScrollerCode}_${data.value.replaceAll(removeAllSpecialCharacters, '')}`;
    const descriprionId = `${elementIDs.catalogsDataScrollerDescriprion}_${data.value.replaceAll(removeAllSpecialCharacters, '')}`;

    if (dataScrollerItemTemplate) {
      return (
        <div className={_rootClasses} onClick={() => handleCodeSelect(data)}>
          {dataScrollerItemTemplate({ data, catalogsState })}
        </div>
      );
    }

    if (data.isCategory) {
      return (
        <div
          id={`${elementIDs.catalogsTableCategory}_${data.value
            ?.toUpperCase()
            .replaceAll('\\\\', '')
            .replaceAll(' ', '')
            .replaceAll(removeAllSpecialCharacters, '')}`}
          className={cx('px-2 py-3', data.isCategory ? 'font-bold text-lg' : '')}
        >
          {data.value?.toUpperCase()}
        </div>
      );
    }

    return (
      <div className={_rootClasses} onClick={() => handleCodeSelect(data)}>
        <div className="col-12 dataScrollerCodeColumn">
          <span id={codeId} className={cx(highlightRowForDxParentCodes ? 'font-bold' : '')}>
            <HighlightSearchQuery query={catalogsState.searchValue} text={data?.value} />
          </span>
        </div>

        <div id={descriprionId} className={cx('dataScrollerLableColumn', highlightRowForDxParentCodes ? 'font-bold' : '')}>
          <HighlightSearchQuery query={catalogsState.searchValue} text={data?.text} />
        </div>
      </div>
    );
  };

  const getDataScrollerValue = () => {
    const defaultList = catalogsState.tableData?.codeList || [];
    if (catalogsTableValues) {
      if (typeof catalogsTableValues === 'function') return catalogsTableValues(catalogsState);
      if (Array.isArray(catalogsTableValues)) return catalogsTableValues;
      return defaultList;
    }

    return defaultList;
  };

  const getCodesList = async ({ catalogsState, page, totalPages } = {}) => {
    const response = await getCatalogCodesList({ catalogsState, catalog: catalogsState.categoryValue, page });

    const codeList = response?.codeList || [];
    setCatalogsState((prevState) => {
      const prevList = prevState.tableData?.codeList || [];
      return {
        ...prevState,
        isLoadingTableData: false,
        isLoadingDataScrollerTable: false,
        tableData: {
          ...response,
          codeList: [...prevList, ...codeList],
          page: page ? page : prevState.tableData.page,
          totalPages: totalPages ? totalPages : prevState.tableData.totalPages
        }
      };
    });
  };

  const onLazyLoad = async ({ requestParams, page, totalPages }) => {
    await getCodesList({ catalogsState: requestParams, page, totalPages });
  };

  const codesList = getDataScrollerValue();

  return (
    <InfinitScrollPanel
      style={{ height: `${props.scrollableTableHeight}px` }}
      requestParams={catalogsState}
      page={catalogsState.tableData?.page}
      totalPages={catalogsState.tableData?.totalPages}
      onScroll={onLazyLoad}
    >
      {codesList?.length ? (
        <div className="px-1">
          {codesList?.map((i) => {
            return dataScrollerItemTemplateView(i);
          })}
        </div>
      ) : (
        <div className="p-2">{catalogsState.emptyMessage}</div>
      )}
    </InfinitScrollPanel>
  );
};

export default CatalogsDataScroller;
