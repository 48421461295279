import React, { useMemo } from 'react';
import { formatZipCode } from '../../../modules/utils/formatZipCode';
import { formatPhoneNumber } from '../../../modules/utils/formatPhoneNumber';
import { getCountryByCode, getRegionByCode } from '../../../modules/utils/getAddressData';
import { defaultCountryCode } from '../../../modules/config/defaultValuesConfig';

const AddressLayout = ({ data = {}, showAddressLine = true, showCityProvinceZip = true, showEmail = true, showPhone = true }) => {
  const { AddressLine, City, Province, Zip, Country = defaultCountryCode, Email, Phone } = data || {};
  // Determine if country should be displayed (if not default)
  const shouldShowCountry = Country && Country !== defaultCountryCode;
  const country = shouldShowCountry ? getCountryByCode(Country)?.label || Country : '';

  // Get the region label or code based on country
  const regionLabel = useMemo(() => {
    const region = getRegionByCode(Province, Country);
    return Country === defaultCountryCode || Country === 'US' ? region?.value : region?.label;
  }, [Province, Country]);

  // Memoized formatted city/province/zip for ALL countries
  const formattedCityProvinceZip = useMemo(() => {
    const zipCode = Zip ? formatZipCode(Zip, Country) : '';

    // Apply universal format: City, Province ZIP
    const cityProvince = [City, regionLabel].filter(Boolean).join(', ');
    return [cityProvince, zipCode].filter(Boolean).join(' ');
  }, [City, regionLabel, Zip, Country]);

  return (
    <div className="flex flex-column line-height-3">
      {showAddressLine && AddressLine && <div className="m-0">{AddressLine}</div>}
      {showCityProvinceZip && formattedCityProvinceZip && <div className="m-0">{formattedCityProvinceZip}</div>}
      {country && <div className="m-0">{country}</div>}
      {showEmail && Email && <div className="m-0">{Email}</div>}
      {showPhone && Phone && <div className="m-0">{formatPhoneNumber(Phone)}</div>}
    </div>
  );
};

export default AddressLayout;
