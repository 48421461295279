import React, { useState, useRef } from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { bureauTypes } from '../helpers/defaultValues';
import { bureauTypeValidation } from '../helpers/validation';

const BureauType = () => {
  const name = 'bureauType';
  const ref = useRef(null);
  const {
    control,
    formState: { errors },
    trigger
  } = useFormContext();
  const errorMessage = errors[name]?.message;

  const bureauNames = () => bureauTypes.map((item) => item.label);
  const defaultBureauType = bureauNames();
  const [suggestions, setSuggestions] = useState([]);

  const onBureauTypeSearch = (event) => {
    const query = event.query;
    if (query.trim() === '') {
      setSuggestions(bureauNames);
    } else {
      const filteredTypes = defaultBureauType.filter((type) => type.toLowerCase().includes(query.toLowerCase()));
      setSuggestions(filteredTypes);
    }
  };

  return (
    <>
      <InputWrapper name={name} label="Billing Bureau Organizational type" required errorMessage={errorMessage}>
        <Controller
          name={name}
          control={control}
          rules={{ validate: bureauTypeValidation }}
          render={({ field }) => (
            <AutoComplete
              {...field}
              data-testid="bureauType"
              ref={ref}
              value={field.value}
              suggestions={suggestions}
              dropdown
              // forceSelection
              completeMethod={onBureauTypeSearch}
              onFocus={(e) => ref.current?.search(e, '', 'dropdown')}
              onBlur={() => trigger(name)}
              className={classNames({ 'p-invalid': errors[name] })}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default BureauType;
