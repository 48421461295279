import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InputSwitch } from 'primereact/inputswitch';
import { sessionStorageKeys } from '../../config/localStorageKeysConfig';
import { t } from '../../../service/localization/i18n';

const LockClaimFilters = () => {
  const filters = useSelector((state) => state.claims.filters);
  const sortby = useSelector((state) => state.claims.sortby);
  const page = useSelector((state) => state.claims.page);
  const pageSize = useSelector((state) => state.claims.pageSize);
  const storedValue = JSON.parse(sessionStorage.getItem(sessionStorageKeys.lockClaimFilters));
  const [checked, setChecked] = useState(() => storedValue);

  useEffect(() => {
    // Sync session storage on initial render
    sessionStorage.setItem(sessionStorageKeys.lockClaimFilters, checked);
  }, [checked]);

  useEffect(() => {
    setChecked(storedValue);
  }, [storedValue]);

  const handleChange = (value) => {
    setChecked(value);
    sessionStorage.setItem(sessionStorageKeys.lockClaimFilters, value); // Update session storage
    if (value) {
      sessionStorage.setItem(sessionStorageKeys.claimsListFilters, JSON.stringify({ filters, page, pageSize, sortby })); // Set claims table filters to sesstion storage
    } else {
      sessionStorage.removeItem(sessionStorageKeys.claimsListFilters); // Clear claims table filters from sesstion storage
    }
  };

  return (
    <InputSwitch
      tooltip={t('lockClaimsFiltersTooltip')}
      tooltipOptions={{ position: 'left', className: 'w-16rem', showDelay: 500 }}
      inputId="lockFilters"
      checked={checked}
      onChange={(e) => handleChange(e.value)}
    />
  );
};

export default LockClaimFilters;
