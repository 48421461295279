import React from 'react';
import { useSelector } from 'react-redux';
import PrivacyAccept from '../../../../components/Privacy/PrivacyAccept';

const AuthPrivacyAccept = () => {
  const user = useSelector((state) => state.user);
  const firstName = user.details.FirstName;
  const lastName = user.details.LastName;
  const userName = `${firstName} ${lastName}`;

  return <PrivacyAccept userName={userName} />;
};

export default AuthPrivacyAccept;
