import React, { useState, useEffect, useContext } from 'react';
import { sendErrorReport } from '../src/service/ClinicService';
import { RouteTrackerContext } from './RouteTrackerContext';
import { useSelector } from 'react-redux';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const { previousUrl } = useContext(RouteTrackerContext);

  // Using redux selector to get the necessary information.
  const user = useSelector((state) => state.user.details);
  const DCNGuid = user?.details?.DCNGuid || '00000000-0000-0000-0000-000000000000';
  const Route = window.location.href;

  const handleError = (error, errorInfo) => {
    const errInfo = {
      DCNGuid,
      Route,
      Details: JSON.stringify({
        message: error.message || 'Unknown error',
        stack: error.stack || 'No stack trace available',
        componentStack: errorInfo.componentStack || 'No component stack available'
      }),
      PreviousUrl: previousUrl || 'No previous URL'
    };

    console.error('ErrorBoundary Error:', errInfo);
    sendErrorReport(errInfo);
  };

  // Using useEffect to catch the error and set state
  useEffect(() => {
    const errorHandler = (error, errorInfo) => {
      setHasError(true);
      handleError(error, errorInfo);
    };

    // Simulate error catch (In real case, we would catch the error using try-catch)
    window.onerror = (error) => {
      errorHandler(error, { componentStack: '' });
    };

    return () => {
      window.onerror = null; // Cleanup on unmount
    };
  }, []); // Only run once when the component is mounted

  // Make sure the hook is always called, regardless of conditions
  if (hasError) {
    return (
    <div style={{margin:'4em'}}>
      <h1>Oops! Something went wrong.</h1>
      <p>We're sorry, but an unexpected error occurred.</p>
      <p>Please try the following steps:</p>
      <ul>
        <li>Clear your browser's cache and cookies for this site.</li>
        <li>Refresh the page or try again in a few minutes.</li>
      </ul>
      <p>If the issue continues, please <a href="mailto:support@claimmanager.ca">contact our support team</a> for assistance.</p>
    </div>);
  }

  return children;
};

export default ErrorBoundary;
