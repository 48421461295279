import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';

import MessagesDialog from './MessagesDialog';
import TablePaginator from '../../../components/Paginators/TablePaginator';
import CircularProgress from '../../../components/Misc/Loader/CircularProgress/CircularProgress';

import { updateMessageStatus } from '../actions/claims.action.creators';
import { formatDatewithMinutes } from '../../utils/formatDate';
import { getMessages } from '../../../service/ClinicService';
import { initStateForPagination } from '../../config/defaultValuesConfig';

const MessagesTable = () => {
  const { DCNGuid } = useSelector((state) => state.user.details);
  const [data, setData] = useState({ ...initStateForPagination, records: [] });
  const [loading, setLoading] = useState(false);
  const [messageDialogVisible, setMessagesDialogVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // Track the selected row
  const first = (data.page - 1) * data.pageSize;
  const records = data.records;

  const fetchData = async ({ page = data.page, pageSize = data.pageSize, showLoading = true } = {}) => {
    showLoading && setLoading(true);

    try {
      const response = await getMessages(DCNGuid, page, pageSize);
      if (response) {
        setData(response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onPageChange = (e) => {
    const newPage = e.page + 1;
    const newPageSize = e.rows;
    fetchData({ page: newPage, pageSize: newPageSize });
  };

  const onRowSelect = async (record) => {
    try {
      setSelectedRow(record);
      setMessagesDialogVisible(true);

      if (Number(record.Status) !== 1) {
        const response = await updateMessageStatus({ ...record, Status: 1 });
        // Assuming `response` has a success indicator (e.g., `response.success`)
        if (response) {
          // Call fetchData only if the status update was successful
          await fetchData({ showLoading: false });
        } else {
          console.error('!!!Failed to update status');
        }
      }
    } catch (error) {
      console.error('!!!Error updating note status:', error);
    }
  };

  const rowClassName = (rowData) => {
    return {
      'font-bold': rowData.Status === '0',
      'cursor-pointer': true,
      'row-selected': rowData === selectedRow, // Apply the selected class if this row is the selected one
      backgroundColor: rowData === selectedRow ? '#e0e0e0' : 'transparent' // Inline style for selected row
    };
  };

  const dateBodyTemplate = (record) => {
    return (
      <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
        {formatDatewithMinutes(record?.MessageDate)}
      </div>
    );
  };

  const messageBodyTemplate = (record) => {
    // Determine the end position for the substring
    const endPos = record?.MessageLine?.indexOf('\r') !== -1 ? record.MessageLine.indexOf('\r') : 80;

    // Get the message substring
    const message = record?.MessageLine?.substring(0, endPos);

    // Append ellipsis if the message length exceeds 65 characters
    const displayMessage = message.length > 65 ? `${message.substring(0, 80)}...` : message;

    return (
      <div className="w-full p-0 flex align-items-center px-0 py-3" style={{ height: '55px' }}>
        {displayMessage}
      </div>
    );
  };

  const viewBodyTemplate = (record) => {
    return (
      <Button
        icon="pi pi-external-link"
        className="p-button-rounded"
        tooltip="View full message"
        tooltipOptions={{ position: 'top' }}
        onClick={() => onRowSelect(record)}
      />
    );
  };

  return (
    <div className="memos-table-wrap">
      {loading && <CircularProgress />}
      <DataTable
        lazy
        value={records}
        tableStyle={{ minWidth: '50rem' }}
        size="small"
        scrollable
        scrollHeight="flex"
        page={data.page}
        rows={data.pageSize}
        first={first}
        filterDisplay="menu"
        selectionMode="single"
        selection={selectedRow}
        onSelectionChange={(e) => onRowSelect(e.value)}
        rowClassName={rowClassName}
        emptyMessage={!records.length && loading ? 'Loading...' : 'No messages found'}
        footer={<TablePaginator first={first || 0} rows={data.pageSize || 0} totalRecords={data.totalRecords || 0} onPageChange={onPageChange} />}
      >
        <Column
          className="memos-column-content"
          field="Date"
          header="Date"
          style={{ minWidth: '120px', maxWidth: '120px' }}
          body={(record) => dateBodyTemplate(record)}
        />
        <Column
          className="memos-column-content"
          field="Message"
          style={{ minWidth: '400px', maxWidth: '400px' }}
          body={(record) => messageBodyTemplate(record)}
          header="Message"
        />
        <Column
          className="memos-column-content"
          field="View"
          style={{ minWidth: '40px', maxWidth: '40px' }}
          body={(record) => viewBodyTemplate(record)}
          header="View"
        />
      </DataTable>

      <MessagesDialog visible={messageDialogVisible} onHide={() => setMessagesDialogVisible(false)} record={selectedRow} />
    </div>
  );
};

export default MessagesTable;
