import React from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { useFormContext } from 'react-hook-form';
import ControlledZip from '../../../../components/Inputs/Zip/ControlledZip';

const PostalCode = ({ requiredStatus }) => {
  const {
    formState: { errors }
  } = useFormContext();

  return (
    <InputWrapper name="Zip" label="Postal Code" required={requiredStatus} errorMessage={errors.Zip?.message}>
      <ControlledZip data-testid="postalCode-testid" required={requiredStatus} />
    </InputWrapper>
  );
};

export default PostalCode;
