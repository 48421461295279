import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import TablePaginator from '../../../components/Paginators/TablePaginator';
import DaysheetUploadButton from '../../../components/Widgets/DaysheetUploadButton';
import CircularProgress from '../../../components/Misc/Loader/CircularProgress/CircularProgress';

import { calculateLast } from '../../utils/calculate';
import { getDaysheetFiles, updateStatus } from '../actions/daysheet.action.creators';
import { initStateForPagination, rowsPerPageDefaultOptions } from '../../config/defaultValuesConfig';

import './Daysheet.scss';

const Daysheet = () => {
  const clinic = useSelector((state) => state.clinic.details);
  const user = useSelector((state) => state.user.details);
  const readOnly = user.PractitionerNumber?.length > 1;
  const DCNGuid = clinic.DCNGuid;
  const [selectedRow, setSelectedRow] = useState(null);
  const [state, setState] = useState({
    ...initStateForPagination,
    fileList: [],
    loading: false
  });
  const last = calculateLast({ page: state.page, pageSize: state.pageSize, totalRecords: state.totalRecords });

  const fetchData = async (params) => {
    const defaultParams = { DCNGuid, pageSize: state.pageSize, pageNumber: state.page };
    setState((prevState) => ({ ...prevState, loading: true }));
    const results = await getDaysheetFiles(params || defaultParams);
    if (results) {
      setState((prevState) => ({
        ...prevState,
        ...results,
        loading: false
      }));
    } else {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPage = (e) => {
    const params = { DCNGuid, pageSize: e.rows, pageNumber: e.page + 1 };
    fetchData(params);
  };

  const setStatus = async (e, rowGuid, status) => {
    let newStatus = 1;
    switch (status) {
      case 2:
        newStatus = e.checked ? 2 : 1;
        break;
      case 3:
        newStatus = e.checked ? 3 : 2;
        break;
      default:
        break;
    }

    await updateStatus({ DCNGuid, RowGuid: rowGuid, Status: newStatus });
    fetchData();
  };

  const fileNameBodyTemplate = (item) => {
    return <div className="file-name-cell">{item.FileName}</div>;
  };

  const linkBodyTemplate = (item) => {
    return (
      <a href={item.FileUrl} target="_blank" rel="noreferrer">
        Download
      </a>
    );
  };

  const directionTemplate = (item) => {
    if (item.Status === 10) {
      return <span>Shared</span>;
    } else {
      return <span>Uploaded</span>;
    }
  };

  const downloadedBodyTemplate = (item) => {
    if (item.Status === 10) {
      return <div></div>;
    }
    const checked = item.Downloaded === 1;
    const fieldId = `downloadedChkBox_${item.RowGuid}`;
    return (
      <div className="flex justify-content-center w-full">
        <Checkbox type="checkbox" inputId={fieldId} checked={checked} onChange={(e) => setStatus(e, item.RowGuid, 2)} disabled={readOnly} />{' '}
      </div>
    );
  };

  const processedBodyTemplate = (item) => {
    if (item.Status === 10) {
      return <div></div>;
    }
    const checked = item.Processed === 1;
    const fieldId = `processedChkBox_${item.RowGuid}`;
    return (
      <div className="flex justify-content-center w-full">
        <Checkbox type="checkbox" inputId={fieldId} checked={checked} onChange={(e) => setStatus(e, item.RowGuid, 3)} disabled={readOnly} />
      </div>
    );
  };

  const header = (
    <div className="flex justify-content-between align-items-center gap-3">
      <div className="text-lg md:text-2xl font-bold">Daysheets</div>
      <DaysheetUploadButton onUpload={fetchData} />
    </div>
  );

  return (
    <>
      {state.loading && <CircularProgress />}
      <div className="inbox_data_table_scroll_wrap">
        <DataTable
          first={state.first}
          value={state.fileList}
          rows={state.pageSize}
          totalRecords={state.totalRecords}
          lazy
          scrollable
          scrollHeight="flex"
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          currentPageReportTemplate={`${state.first} to ${last} of ${state.totalRecords}`}
          rowsPerPageOptions={rowsPerPageDefaultOptions}
          onPage={onPage}
          header={header}
          footer={<TablePaginator first={state.first || 0} rows={state.pageSize || 0} totalRecords={state.totalRecords || 0} onPageChange={onPage} />}
        >
          <Column style={{ minWidth: '190px', maxWidth: '190px', width: '190px' }} field="UserName" header="Uploaded By" />
          <Column style={{ minWidth: '320px', maxWidth: '320px', width: '320px' }} field="FileName" header="File Name" body={fileNameBodyTemplate} />
          <Column style={{ minWidth: '100px', maxWidth: '100px', width: '100px' }} field="Status" header="Type" body={directionTemplate} />
          <Column style={{ minWidth: '100px', maxWidth: '100px', width: '100px' }} field="FileUrl" header="Link" body={linkBodyTemplate} />
          <Column
            style={{ minWidth: '100px', maxWidth: '100px', width: '100px' }}
            field="Downloaded"
            header="Downloaded"
            body={downloadedBodyTemplate}
          />
          <Column
            style={{ minWidth: '100px', maxWidth: '100px', width: '100px' }}
            field="Processed"
            header="Processed"
            body={processedBodyTemplate}
          />
        </DataTable>
      </div>
    </>
  );
};

export default Daysheet;
