import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import Context from '../../../../../Context';
import BillToForm from '../../../../../components/Widgets/BillToForm';

import { onBillToUpdate } from '../claimDetailsHelpers/onClaimUpdate';
import { updatePrivateRecord } from '../../../actions/claims.action.creators';
import { formatPatientInfoForClaims } from '../../../../patients/helpers/formatPatientInfoForClaims';

const EditBillTo = ({ onClose }) => {
  const dispatch = useDispatch();
  const { setRepresentative, gettingRepresentative, privateRecord, representative } = useContext(Context);
  const patient = Array.isArray(privateRecord.Patients) ? privateRecord.Patients[0] : privateRecord.Patients;

  const onSubmitPatient = (patientData) => {
    const updatedPatientInfo = formatPatientInfoForClaims(patientData);
    onBillToUpdate([patientData]);
    dispatch(updatePrivateRecord({ Patients: [updatedPatientInfo] }));
  };

  const onSubmitRepresentative = (representative) => {
    onBillToUpdate([representative]);
    setRepresentative(representative);
  };

  const onPayerChange = (payer) => {
    onBillToUpdate([payer]);
  };

  return (
    <BillToForm
      invoice={privateRecord}
      patient={patient}
      representative={representative}
      loading={gettingRepresentative}
      onClose={onClose}
      onPayerChange={onPayerChange}
      onSubmitPatient={onSubmitPatient}
      onSubmitRepresentative={onSubmitRepresentative}
    />
  );
};

export default EditBillTo;
