import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { rowActionsMenuItems } from './ClaimNotesTable/helpers/rowActionsMenuItems';
import RemTable from './RemittancesIndex';
import { getRemittances } from '../../../service/ClinicService';

const RemittancesTable = () => {
  const { DCNGuid } = useSelector((state) => state.user.details);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [remittancesData, setRemittancesData] = useState({ remittances: [], totalRecords: 0 });
  const [loading, setLoading] = useState(false);

  const fetchData = async (pageNum, pageSizeNum) => {
    setLoading(true);
    try {
      const data = await getRemittances(DCNGuid, pageNum, pageSizeNum);

      setRemittancesData({
        remittances: data.remittances,
        totalRecords: data.totalRecords
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      await fetchData(page, pageSize);
    };
    fetchDataAsync();
  }, []);

  const onPage = (newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
    fetchData(newPage, newPageSize);
  };

  return (
    <RemTable
      remittances={remittancesData.remittances}
      page={page}
      pageSize={pageSize}
      totalRecords={remittancesData.totalRecords}
      onPageChange={(e) => onPage(e.page + 1, e.rows)}
      loading={loading}
      rowActionsMenuItems={rowActionsMenuItems}
      fetchData={fetchData}
    />
  );
};

export default RemittancesTable;
