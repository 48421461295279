import React, { useState } from 'react';
import { InputWrapper } from '../../../../components/Inputs';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';

import { roles } from '../helpers/defaultValues';
import { roleInClinicValidation } from '../helpers/validation';

const RoleInClinic = () => {
  const name = 'clinicRole';
  const ref = React.createRef();
  const [rolesList, setRolesList] = useState([]);
  const {
    control,
    formState: { errors },
    setError,
    clearErrors
  } = useFormContext();
  const errorMessage = errors[name]?.message;

  const onRolesSearch = (e) => {
    let _filteredRoles;

    if (!e.query.trim().length) {
      _filteredRoles = [...roles];
    } else {
      _filteredRoles = roles.filter((role) => {
        return role.toLowerCase().startsWith(e.query.toLowerCase());
      });
      if (_filteredRoles.length) {
        errorMessage && clearErrors(name);
      } else {
        setError(name, { type: 'custom', message: 'No results found' });
      }
    }
    setRolesList(_filteredRoles);
  };

  return (
    <>
      <InputWrapper
        name={name}
        label="Your Role in Clinic"
        required
        errorMessage={errorMessage}
        style={{ width: '100%', height: 'auto', paddingBottom: '25px' }}
      >
        <Controller
          name={name}
          control={control}
          rules={{ validate: roleInClinicValidation }}
          render={({ field }) => (
            <AutoComplete
              {...field}
              data-testid="roleInClinic"
              inputRef={field.ref}
              ref={ref}
              suggestions={rolesList}
              dropdown
              autoHighlight
              // forceSelection // Brocken in current vertion of primeReact (v9.2.0)
              multiple
              completeMethod={onRolesSearch}
              className={classNames({ 'p-invalid': errors[name] })}
              onFocus={(e) => {
                ref?.current?.search(e, '', 'dropdown');
              }}
              onClear={() => {
                if (field.value.length) {
                  clearErrors(name);
                } else {
                  setError(name, { type: 'custom', message: 'Role in clinic is required' });
                }
              }}
            />
          )}
        />
      </InputWrapper>
    </>
  );
};

export default RoleInClinic;
