import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';

import Context from '../../../../Context';
import Success from './Success';
import PreviewPanel from './PreviewPanel';
import EditPanel from './EditPanel';
import CommonConfirmDialog from '../../../common/components/CommonConfirmDialog';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';

import { routes } from '../../../../routes/routes';
import { getItemFromArray, checkMandatoryFields } from '../../../../Helper';
import { requiredFields } from './claimDetailsHelpers/claimDetailsRequiredFields';
import {
  dueDateInit,
  invoiceDateInit,
  patientsInit,
  privateBlankRecord,
  termsInit,
  updatePreferencesForPrivateRecord
} from './claimDetailsHelpers/newClaimBlankRecord';
import { codesLocalStateInitValues } from '../../helpers/codesLocalStateInitValues';
import { setClean, setDirty, setToastMessage } from '../../../core/actions/core.action.creators';
import { isEmpty, isEqual } from 'lodash';
import { prefsCodes } from '../../../config/prefsCodesConfig';
import { defaultGuid, multipleDoctorGuid, NA_DoctorGuid } from '../../../config/defaultValuesConfig';
import { getPreferences, setCurrentPractitionerPrefs } from '../../../preferences/actions/preferences.action.creators';
import {
  setPrivateRecord,
  setCreateClaimPanelCollapsed,
  getPrivateInvoicesCatalogCategories,
  getClaimInfo,
  updateClaimList,
  getEClaimInfo
} from '../../actions/claims.action.creators';
import { updateLocationValues } from './claimDetailsHelpers/updateLocationValues';
import { formatServices, formattedService } from './claimDetailsHelpers/formatServices';
import { elementIDs } from '../../../config/elementIDsConfig';
import { invoicesCatalogsDefaultPageSize } from '../../../config/defaultValuesConfig';
import { t } from '../../../../service/localization/i18n';
import { getPatient } from '../../../patients/actions/patients.action.creators';
import { formatPatientInfoForClaims } from '../../../patients/helpers/formatPatientInfoForClaims';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { getPractitionerByGuid } from '../../../utils/getPractitioner';
import { getDefaultPayeeOption, getPayeeInfoInitValues, getPayeeOptions } from '../../../utils/getPayeeInfo';
import { v4 as uuidv4 } from 'uuid';
import { getPromiseAll } from '../../../utils/getPromiseAll';
import { getEClaimLocations } from '../../../../service/Lookup';
import { updatePractitionerValues } from './claimDetailsHelpers/updatePractitionerValues';

const PrivateDetails = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const user = useSelector((state) => state.user) || {};
  const clinic = useSelector((state) => state.clinic);
  const { currentUserPrefs, savingPreferences, isGettingPrefs } = useSelector((state) => state.preferences);
  const { dirty } = useSelector((state) => state.core);
  const { isMobile, isMobileOnly, clientWidth } = useSelector((state) => state.core.window);
  const { showDataScroller } = useSelector((state) => state.core.showDataScroller);
  const {
    claim_list,
    complete_list,
    privateRecord,
    isSavingClaims,
    shouldResetNewInvoiceForm,
    catalogMostRecentCodes,
    createClaimPanelCollapsed,
    isGettingPatientsMostReacentClaim
  } = useSelector((state) => state.claims);

  const itemsNumber = { serviceList: Array.from({ length: invoicesCatalogsDefaultPageSize }, () => 'item') };
  const [catalogsTableData, setCatalogsTableData] = useState(itemsNumber);
  const [step, setStep] = useState(0);
  const [records, setInitialRecords] = useState([]);
  const [menuModel, setMenuModel] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [editableDropdownErrors, setEditableDropdownErrors] = useState({});
  const [practitionerIsRequired, setPractitionerIsRequired] = useState(false);
  const [showSidebarWithCatalogs, setShowSidebarWithCatalogs] = useState(false);
  const [isMaxCodeEntries, setIsMaxCodeEntries] = useState({ isMaxEntries: false, maxEntries: 999 });
  const [codesLocalState, setCodesLocalState] = useState(codesLocalStateInitValues);
  const [showCatalogsSidebar, setShowCatalogsSidebar] = useState(false);
  const [isPractitionerIsEmpty, setIsPractitionerIsEmpty] = useState(false);
  const [isReviewDirty, setIsReviewDirty] = useState(false);
  const [claimIsDeleted, setClaimIsDeleted] = useState(false);
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [focusFieldParams, setFocusFieldParams] = useState({});
  const [catalogIndex, setCatalogIndex] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [mobileContent, setMobileContent] = useState('menu');
  const [categoryValue, setCategoryValue] = useState('');
  const [representative, setRepresentative] = useState(null);
  const [gettingRepresentative, setGettingRepresentative] = useState(null);
  const [locationsList, setLocationsList] = useState(clinic.locations);
  const [loading, setLoading] = useState(false);

  const isDirty = dirty;
  const users = clinic.members;
  const isInvoicePatientForBalance = location.pathname.includes(routes.invoicePatientForBalance.path);
  const isNew = isInvoicePatientForBalance || props.match.url.indexOf('new') > -1;
  const invoiceGuid = params.id;
  const hashPath = window.location.hash.split('/');
  const claimGuid = hashPath[hashPath.length - 1]; // if your app is using HashRouter (/#/...), React Router does NOT correctly parse paths after the hash (#). Instead, it treats the entire segment after #/ as a single string.
  const mobileView = isMobile && clientWidth < 1024;

  const currentUserDefaultPrefs = currentUserPrefs?.prefs?.find((i) => i.label === 'default');

  const fetchPatientInfo = async (patientGuid) => {
    const patientInfo = await getPatient(patientGuid);
    return formatPatientInfoForClaims(patientInfo);
  };

  // Get catalog tabs
  useEffect(() => {
    if (privateRecord?.DoctorGuid && (privateRecord?.DoctorGuid !== NA_DoctorGuid || privateRecord?.DoctorGuid !== defaultGuid)) {
      dispatch(getPrivateInvoicesCatalogCategories(privateRecord?.DoctorGuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateRecord?.DoctorGuid]);

  // set initial record for new claim
  useEffect(() => {
    if (!isNew) {
      return getDataForEditInvoice();
    }

    if (isNew) {
      if (isInvoicePatientForBalance) {
        return getDataForInvoicePatientForBalance();
      }

      if (users?.length > 0) {
        if (isEmpty(privateRecord)) {
          setNewClaim();
        } else {
          if (!shouldResetNewInvoiceForm) {
            if (isEmpty(privateRecord?.CurrentUserPrefs)) {
              // add new invoice from patient profile
              setNewClaim(true);
            } else {
              // update invoice if user add new patient using "Add new patient" button
              dispatch(setPrivateRecord(privateRecord));
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataForEditInvoice = () => {
    dispatch(
      getClaimInfo(
        invoiceGuid,
        async (responseData) => {
          const patient = await fetchPatientInfo(responseData.PatientDetails.PatientGuid);
          const updatedPrivateRecord = { ...responseData, PatientDetails: patient };
          const initRecord = privateBlankRecord({ privateRecord: updatedPrivateRecord });
          dispatch(setPrivateRecord(updatePreferencesForPrivateRecord(initRecord, currentUserDefaultPrefs)));
        },
        'P'
      )
    );
  };

  const getDataForInvoicePatientForBalance = async () => {
    try {
      setLoading(true);
      const eClaim = await getEClaimInfo(claimGuid);
      const practitionerGuid = eClaim.DoctorGuid;

      const requests = {
        patient: fetchPatientInfo(eClaim.PatientGuid),
        tecLocations: getEClaimLocations(practitionerGuid, 'tec'),
        pbcLocations: getEClaimLocations(practitionerGuid, 'pbc')
      };

      const results = await getPromiseAll(requests);

      const patient = results.patient;
      const formattedPatient = formatPatientInfoForClaims(patient);
      const practitioner = getPractitionerByGuid(practitionerGuid);
      const treatedBy = practitioner.FullName;
      const licenceNumber = practitioner.LicennceNumber;
      const servicesList = eClaim.Services?.map((i) => ({
        ...formattedService(i),
        treatedBy,
        licenceNumber,
        id: uuidv4(),
        note: '',
        amount: eClaim.FeeTotal - eClaim.FeePaid
      }));
      const serviceGuids = servicesList?.map((i) => i.id);
      const pbcLocationsList = results.pbcLocations || [];
      const tecLocationsList = results.tecLocations || [];
      const locationsList = [...pbcLocationsList, ...tecLocationsList];
      const location = locationsList?.find((i) => i.RowGuid === eClaim.Location);
      const defaultPayee = getDefaultPayeeOption(prefsCodes.defaultPayTo);

      const getPayToDropdownOption = () => {
        const payeeOptions = getPayeeOptions();
        if (eClaim.Payee === 'PROV') return payeeOptions.find((i) => i.id === practitioner.DoctorGuid);
        if (eClaim.Payee === 'PROVORG') return payeeOptions.find((i) => i.id === clinic.DCNGuid);
        if (eClaim.Payee === 'POLHOLD') return defaultPayee;
        return defaultPayee;
      };

      const payToOption = getPayToDropdownOption();
      const defaultPayeeInfo = getPayeeInfoInitValues();
      const payeeInfo = defaultPayeeInfo?.map((i) => {
        if (i.id === 'payee') {
          return { id: 'payee', value: payToOption.id, label: payToOption.name };
        }
        return i;
      });

      const initRecord = {
        DCNGuid: eClaim.DCNGuid,
        ClaimGuid: eClaim.ClaimGuid,
        InvoiceGuid: eClaim.InvoiceGuid || defaultGuid,
        ServiceDate: moment(eClaim.ServiceDate[0]).toDate(),
        RecordNo: '',
        ClaimNumber: '',
        Patients: patientsInit({ PatientDetails: formattedPatient }),
        PatientDetails: formattedPatient,
        InvoiceType: 'P',
        Status: 0,
        StatusText: 'Outstanding',
        Speciality: eClaim.Speciality,
        PractitionerNumber: practitioner.PractitionerNumber,
        DateCreated: eClaim.DateCreated,
        DateUpdated: eClaim.DateUpdated,
        SDate: eClaim.SDate,
        FeePaid: eClaim.FeePaid,
        FeeTotal: eClaim.FeeTotal,
        FeeAmount: eClaim.FeeTotal - eClaim.FeePaid,
        PstTotal: 0,
        GstTotal: 0,
        DiscountTotal: 0,
        NoOfServiceUnits: 0,
        LocationName: location?.LocationName,
        PayTo: payToOption?.data,
        PayeeInfo: payeeInfo,
        ServiceGuid: serviceGuids,
        ServicesList: servicesList,
        BillTo: [formattedPatient],
        Terms: termsInit()?.net,
        InvoiceDate: invoiceDateInit(),
        DueDate: dueDateInit(),
        InvoiceNumber: '',
        Comment: '',
        TreatedBy: treatedBy,
        DoctorGuid: practitioner.DoctorGuid,
        LicenceNumber: practitioner.LicennceNumber
      };

      setLocationsList([location]);
      dispatch(setDirty());
      dispatch(setPrivateRecord(updatePreferencesForPrivateRecord(initRecord, currentUserDefaultPrefs)));
    } catch (error) {
      console.log('!!!getDataForInvoicePatientForBalance', error);
    } finally {
      setLoading(false);
    }
  };

  const setNewClaim = (updateClaim = false) => {
    const getPractitionerId = currentUserDefaultPrefs?.content?.find((i) => i.label === prefsCodes.defaultPractitioner)?.value;
    const practitionerId =
      getPractitionerId === NA_DoctorGuid || getPractitionerId === multipleDoctorGuid ? user?.details?.UserGuid : getPractitionerId;

    dispatch(
      getPreferences(user.details.DCNGuid, practitionerId, (responceData) => {
        const defaultPrefs = responceData?.find((i) => i.label === 'default');
        newClaimRecord(updateClaim, practitionerId, defaultPrefs);
        dispatch(setCurrentPractitionerPrefs(defaultPrefs));
      })
    );
  };

  const newClaimRecord = async (updateClaim, practitionerId, defaultPrefs) => {
    const currentUser = getItemFromArray(users, practitionerId, 'DoctorGuid');
    const blankRecord = privateBlankRecord({
      user: currentUser,
      currentUserDefaultPrefs,
      currentPractitionerPrefs: defaultPrefs,
      clinic
    });

    if (!updateClaim) dispatch(setPrivateRecord(blankRecord));

    if (updateClaim) {
      const patient = await fetchPatientInfo(privateRecord?.Patients?.PatientGuid);

      // if no last private invoice
      if (!privateRecord?.Patients?.LastPrivateGuid || privateRecord?.Patients?.LastPrivateGuid === defaultGuid) {
        const updatedClaim = {
          ...blankRecord,
          Patients: [patient],
          BillTo: [patient]
        };

        dispatch(setPrivateRecord(updatePreferencesForPrivateRecord(updatedClaim, defaultPrefs)));
      }

      // if last private invoice is exist
      if (privateRecord?.Patients?.LastPrivateGuid !== defaultGuid) {
        dispatch(
          getClaimInfo(
            privateRecord?.Patients?.LastPrivateGuid,
            (responceData) => {
              const formattedResponceData = {
                ...responceData,
                FullName: `${responceData.DLastName}, ${responceData.DFirstName}`
              };
              const currentLocation =
                clinic.locations.find((i) => i.DCNGuid.toUpperCase() === formattedResponceData.DCNGuid.toUpperCase()) || clinic.locations[0];

              const updatedClaimWithLastInvoice = {
                ...blankRecord,
                ...updatePractitionerValues(formattedResponceData), // Update practitioner
                ...updateLocationValues(currentLocation), // Update location
                Patients: [patient],
                BillTo: [patient],
                PayTo: responceData?.PayTo,
                ServiceGuid: responceData?.ServicesList?.map((i) => i.value),
                ServicesList: formatServices(responceData)
              };

              dispatch(setPrivateRecord(updatePreferencesForPrivateRecord(updatedClaimWithLastInvoice, defaultPrefs)));
            },
            'P'
          )
        );
      }
    }
  };

  //component unmount
  useEffect(() => {
    return () => {
      //collapse HeaderPanel
      dispatch(setCreateClaimPanelCollapsed(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteInvoiceCallback = () => {
    // update claim list for ViewPanel
    const listOfClaims = isMobileOnly && showDataScroller ? complete_list : claim_list;
    const filteredListOfClaims = listOfClaims?.filter((i) => i.InvoiceGuid !== privateRecord?.InvoiceGuid);
    dispatch(updateClaimList(filteredListOfClaims));

    setClaimIsDeleted(true);
    dispatch(setClean());
    setStep(3);
  };

  const validateForm = (callback) => {
    const errors = Object.keys(editableDropdownErrors).some((i) => editableDropdownErrors[i] === true);
    const required = checkMandatoryFields('claim', privateRecord, requiredFields());

    //CMO-1320 - Create/Edit invoice - autoscroll to the mandatory field
    //Scroll to the invalid input
    const invalidInput = document.getElementsByClassName('p-invalid')[0]?.getElementsByTagName('input')[0];
    invalidInput?.focus();
    invalidInput?.scrollIntoView({ block: 'center', behavior: 'smooth' });

    // [KS]  Practitioner input validation
    if (privateRecord?.DoctorGuid === NA_DoctorGuid || privateRecord?.DoctorGuid === defaultGuid) {
      setPractitionerIsRequired(true);
      dispatch(setToastMessage({ type: 'warn', message: t('Please_complete_the_mandatory_fields'), lifeTime: 4000 }));
      return;
    }

    if (Object.keys(required).length !== 0) {
      setValidationErrors(required);
      dispatch(setToastMessage({ type: 'warn', message: t('Please_complete_the_mandatory_fields'), lifeTime: 4000 }));
      return;
    }

    if (Object.keys(required).length === 0 && errors) {
      dispatch(setToastMessage({ type: 'warn', message: t('Your_value_is_not_present_in_the_catalog') }));
      return;
    }

    if (!privateRecord?.ServicesList?.length) {
      dispatch(setToastMessage({ type: 'warn', message: t('Please_add_service_or_product_to_create_the_invoice') }));
      return;
    }

    // multiple practitioners
    if (
      (privateRecord?.DoctorGuid === multipleDoctorGuid || privateRecord?.DoctorGuid === user.details.DCNGuid) &&
      privateRecord?.ServicesList?.length
    ) {
      if (privateRecord?.ServicesList.some((i) => !i.doctorGuid || i.doctorGuid === NA_DoctorGuid)) {
        setIsPractitionerIsEmpty(true);
        dispatch(setToastMessage({ type: 'warn', message: t('Please_complete_the_mandatory_fields'), lifeTime: 4000 }));
        return;
      }
    }

    if (Object.keys(required).length === 0 && !errors) {
      // dispatch(updatePrivateRecord({
      //   ...privateRecord,
      //   [inputs().privateService.descriptionName]: formatServices(privateRecord)
      // }));
      // setStep(1);

      callback();
    }
  };

  const promptMessage = (location) => {
    //Will be called with the next location and action the user is attempting to navigate to. Return a string to show a prompt to the user or true to allow the transition.
    return location.pathname.startsWith(`${routes.createPatient.path}/new`)
      ? true
      : JSON.stringify({
          isDirty,
          name: 'privateClaim' //add 'name' to check which form should be nulled (see customPrompt for nulling)
        });
  };

  const contextValue = {
    isInvoicePatientForBalance,
    mobileView,
    representative,
    setRepresentative,
    gettingRepresentative,
    setGettingRepresentative,
    menuModel,
    setMenuModel,
    deleteInvoiceCallback,
    categoryValue,
    setCategoryValue,
    mobileContent,
    setMobileContent,
    searchValue,
    setSearchValue,
    isLoadingTableData,
    setIsLoadingTableData,
    itemsNumber,
    catalogsTableData,
    setCatalogsTableData,
    claimIsDeleted,
    setClaimIsDeleted,
    isPractitionerIsEmpty,
    setIsPractitionerIsEmpty,
    isReviewDirty,
    setIsReviewDirty,
    records,
    setInitialRecords,
    setIsMaxCodeEntries,
    setNewClaim,
    setStep,
    showCatalogsSidebar,
    setShowCatalogsSidebar,
    focusFieldParams,
    setFocusFieldParams,
    catalogIndex,
    setCatalogIndex,
    setShowSidebarWithCatalogs,
    showSidebarWithCatalogs,
    privateRecord,
    dropdownErrors: editableDropdownErrors,
    setDropdownErrors: setEditableDropdownErrors,
    codesLocalState,
    setCodesLocalState,
    validateForm,
    isMobile,
    isDirty,
    isNew,
    user,
    users: clinic.members,
    clinic: clinic.details,
    locations: locationsList,
    shouldResetNewInvoiceForm,
    errors: validationErrors,
    setValidationErrors,
    currentUserDefaultPrefs,
    savingPreferences,
    catalogMostRecentCodes,
    createClaimPanelCollapsed,
    practitionerIsRequired,
    setPractitionerIsRequired
  };

  const showEditPanel = step === 0;
  const showPreviewPanel = step === 2;
  const showSuccessPage = step === 3;
  const showSpinner = savingPreferences || isGettingPrefs || isSavingClaims || isGettingPatientsMostReacentClaim || loading;

  return (
    <Context.Provider value={contextValue}>
      <div id={elementIDs.privateInvoiceFormContainer} className="px-0 md:px-3">
        <Prompt when={isDirty} message={promptMessage} />

        <CommonConfirmDialog
          header={t('Warning')}
          visible={isMaxCodeEntries.isMaxEntries}
          multipleButtons={false}
          bodyText={`${t('Maximum_number_of_entries_is_X')} ${isMaxCodeEntries.maxEntries}`}
          accept={() => setIsMaxCodeEntries({ isMaxEntries: false, maxEntries: 999 })}
          reject={() => setIsMaxCodeEntries({ isMaxEntries: false, maxEntries: 999 })}
        />

        {showSpinner && <CircularProgress />}

        {showEditPanel && <EditPanel />}

        {showPreviewPanel && <PreviewPanel />}

        {showSuccessPage && <Success />}
      </div>
    </Context.Provider>
  );
};

export default memo(PrivateDetails, isEqual);
