import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import CustomButton from './CustomButton';

import EligibilityMessage from './NewEligibilityMessage';
import EligibilityTable from './NewEligibilityTable';
import SuccessPage from '../../../../components/Layouts/SuccessPage/SuccessPage';
import SuccessActionButtons from '../../../../components/Layouts/SuccessPage/SuccessActionButtons';

import { setEligibility, setPatientIdForHighlightInTable } from '../../actions/patients.action.creators';
import { get, isEmpty } from 'lodash';
import { t } from '../../../../service/localization/i18n';
import { editPatientAction } from '../../actions/patients.action.creators';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Success.scss';
import BillSamePatientSplitButton from '../../../../components/Layouts/SuccessPage/BillSamePatientSplitButton';

import { setClaimFilters } from '../../../claims/actions/claims.action.creators';
import { claimsDefaultFilters } from '../../../config/defaultValuesConfig';
import { routes } from '../../../../routes/routes';
import { Message } from 'primereact/message';
import cx from 'classnames';
import classes from './Success.module.scss';

import { setErrorMessageAutofill } from '../../actions/patients.action.creators';
import { setEligibilityError } from '../../../core/actions/core.action.creators';

import { getCodesByType } from '../../../claims/actions/claims.action.creators';
import { commonInputs } from '../../../config/commonInputsConfig';
import { InputText } from 'primereact/inputtext';
import GlobalSearch from '../../../dashboard/components/GlobalSearch';
import { getGroupTemplateDefaultValues } from '../../../claims/components/TeleplanInvoice/helpers/groupTemplateDefaultValues';
import { getDefaultValues } from '../../../claims/components/TeleplanInvoice/helpers/defaultValues'; 
import { ButtonWithBadge } from '../../../../components/Buttons';
import moment from 'moment';
import { formatPHN } from '../../../utils/formatPHN';
import RadioButton from '../../../../components/PrimeReactComponents/RadioButton';

const Success = ({
  patientDetails,
  setStep,
  eligibility,
  showNewPatientSuccessPage,
  addNewPatientForNewClaim,
  image,
  nameFromEligibility,
  nameFromPicture,
  setShowForm,
  onAutofillClick,
  setShowImage,
  setImage,
  isNew,
  preferredName,
  setPreferredName,
  formik,
  imageIndex,
  selectedPatients,
  setSelectedPatients,
  setUploadFileFromSuccessPage,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility
  const user = useSelector((state) => state.user.details);
  const errorToastMessage = useSelector((state) => state?.patients?.autofillError[imageIndex]?.message);
  const isMobile = useSelector((state) => state.core.window.isMobile);
  const [showGroupDialog, setShowGroupDialog] = useState(false);
  const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
  const [groupName, setGroupName] = useState(today); // State for the group name
  const [description, setDescription] = useState('');
  const currentPractitionerPrefs = useSelector((state) => state.preferences.currentUserPrefs.prefs);
  const defaultPreference = currentPractitionerPrefs?.find((pref) => pref.label === 'default');
  

  const newPatient = () => {
    if (isNew) {
      dispatch(setEligibility({}));
      setStep(0);
      setPreferredName(null);
      setShowImage(false);
      setImage(null);
      dispatch(setEligibilityError({ error: null }));
      dispatch(setErrorMessageAutofill({ index: null, type: null, message: null, originalMSPErrorMessage: null, }));
      setShowForm(true);
    } else {
      history.push(`${routes.createPatient.path}/new`);
    }
  };

  useEffect(() => {
    console.log('Name from picture:', nameFromPicture);
  }, [nameFromPicture]); // Correctly close the dependency array here

  const newLabel = () => {
    if (isNew) {
      dispatch(setEligibility({}));
      setStep(0);
      setShowForm(false);
      setUploadFileFromSuccessPage(true);
      // onAutofillClick();
      setPreferredName(null);
      setShowImage(false);
      setImage(null);
      dispatch(setEligibilityError({ error: null }));
      dispatch(setErrorMessageAutofill({ index: null, type: null, message: null, originalMSPErrorMessage: null }));
    } else {
      history.push({
        pathname: `${routes.createPatient.path}/new`,
        state: { activateAutofill: true }
      });
    }
  };

  const onAddNewPatientToNewClaim = () => {
    addNewPatientForNewClaim(patientDetails);
    history.goBack();
  };

  useEffect(() => {
    if (preferredName === null) {
      setPreferredName(nameFromPicture ? nameFromPicture : null);
    }
    console.log('nameFromEligibility', nameFromEligibility);
    console.log('nameFromPicture:', nameFromPicture);
    console.log('prefered name  :', preferredName);
    console.log('patientDetails', patientDetails);
    console.log('CHECK', nameFromEligibility === nameFromPicture);
  }, [patientDetails]);

  const handleAddPatient = (patient) => {
    setSelectedPatients((prev) => [...prev, patient]); // Add the selected patient to the list
  }


  // useEffect(() => {
  //   handleAddPatient(patientDetails);
  // }
  // , []);

  const renderAddToGroupDialog = () => {
    // Default group name as today's date


    const handleSaveGroup = () => {
      // Create the firstStep object with patient details included
      const firstStep = {
        ...getDefaultValues({
          currentPractitionerPrefs: defaultPreference,
          isNew: true,
          isGroup: true,
        }),
        patientDetails: selectedPatients, // Add selectedPatients directly here
      };
    
      // Create the groupData object with the initialized firstStep
      const groupData = {
        ...getGroupTemplateDefaultValues(),
        DCNGuid: user.DCNGuid,
        dateCreated: today,
        Name: groupName,
        Records: selectedPatients, // Pass original array for Records
        firstStep: firstStep, // Use the updated firstStep
      };
    
      console.log('groupData', groupData);
      // Close the dialog (if needed)
      // setShowGroupDialog(false);
    };  
    return (
      <div className="p-fluid flex flex-column gap-3" style={{ padding: '1rem' }}>
        {/* Group Name */}
        <div className="field">
          <label htmlFor="groupName">Group Name</label>
          <InputText
            id="groupName"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Enter group name"
          />
        </div>
  
        {/* Add New Patient Options */}
        <div className="field">
          <h4>Add New Patient</h4>
          <div className="flex gap-3 align-items-start">
            <Button
              label="Add New Patient"
              icon="pi pi-plus"
              className="p-button-primary"
              onClick={newPatient}
            />
            <Button
              label="Add with Label"
              icon="pi pi-camera"
              onClick={newLabel}
            />
      <GlobalSearch
        name="patient"
        placeholder="Search patient"
        onSelectPatient={handleAddPatient}
      />
          </div>
        </div>
  
        {/* Current Patient List */}
        <div className="field">
          <h4>Patients in Group</h4>
          {selectedPatients.length > 0 ? (
            selectedPatients.map((patient, index) => (
              <div
                key={index}
                className="flex justify-content-between align-items-center"
                style={{
                  border: '1px solid #ccc',
                  padding: '0.5rem',
                  borderRadius: '8px',
                  marginBottom: '0.5rem',
                }}
              >
                <div>
                  <p>
                    <strong>Name:</strong> {patient?.FullName || "N/A"}
                  </p>
                  <p>
                    <strong>PHN:</strong> {patient?.PHN || "N/A"}
                  </p>
                  <p>
                    <strong>Date of Birth:</strong>{" "}
                    {patient?.BirthDay
                      ? new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        }).format(new Date(patient.BirthDay))
                      : "N/A"}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p>No patients added to the group yet.</p>
          )}
        </div>
  
        {/* Save and Cancel Buttons */}
        <div className="field flex justify-content-end gap-3">
          <Button
            label="Save"
            className="p-button-primary"
            onClick={handleSaveGroup}
          />

          <Button
            label="Cancel"
            outlined
            onClick={() => setShowGroupDialog(false)}
          />
        </div>
      </div>
    );
  };  
  const renderModalContent = () => (
    <>
      {/* Display uploaded image */}
      <div
        className="mx-4 mb-4"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', // Vertically center the content
          alignItems: 'center' // Horizontally center the content
        }}
      >
        {image && (
          <div className="pb-2 w-full">
            <div
              style={{
                textAlign: 'center',
                marginBottom: '20px',
                width: '100%' // Ensure the container aligns with the buttons
              }}
            >
              {(errorToastMessage || (nameFromEligibility && nameFromPicture && nameFromEligibility !== nameFromPicture)) && (
                <div>
                  <div
                    style={{
                      width: '100%', // Full width to align with other content
                      padding: '1rem', // Add padding around the error messages
                      marginBottom: '1rem' // Add spacing below the messages
                    }}
                  >
                    {errorToastMessage &&
                      errorToastMessage === 'First name or last name not found in the image. Please verify the autofill data.' && (
                        <Message severity="warn" content={<span>{errorToastMessage}</span>} />
                      )}
                    {nameFromEligibility && nameFromPicture && nameFromEligibility !== nameFromPicture && (
                      <Message severity="warn" content={<span>Label and Eligibility Report names differ. Please confirm correct spelling.</span>} />
                    )}
                  </div>
                  <div className="pb-4">
                    Name from eligibility report: <strong>{nameFromEligibility}</strong>
                  </div>
                </div>
              )}

              <img
                src={image}
                alt="Uploaded"
                style={{
                  maxWidth: '100%',
                  width: '100%', // Match the width of the buttons
                  maxHeight: '300px',
                  objectFit: 'contain',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  padding: '10px',
                  backgroundColor: '#f9f9f9'
                }}
              />
            </div>
          </div>
        )}

        {/* Display name options */}
        {nameFromEligibility !== nameFromPicture && nameFromEligibility && nameFromPicture && (
          <div
            className="name-options-container w-full"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px'
            }}
          >
            {/* Button for Name from Label */}
            <CustomButton
              label="Name from Label"
              name={nameFromPicture} // Pass the actual name
              isSelected={preferredName === nameFromPicture} // Highlight if selected
              onClick={() => handleNameChange(nameFromPicture)} // Handle click
            />

            {/* Button for Name from Eligibility */}
            <CustomButton
              label="Name from Eligibility"
              name={nameFromEligibility} // Pass the actual name
              isSelected={preferredName === nameFromEligibility} // Highlight if selected
              onClick={() => handleNameChange(nameFromEligibility)} // Handle click
            />

            {/* Button for Edit Manually */}
            <CustomButton
              label="Edit Manually"
              name="" // No additional name for this button
              onClick={onEditPatientName} // Handle click
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            />
          </div>
        )}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
          width: '100%',
          gap: '10px'
        }}
      >
        {!(nameFromEligibility !== nameFromPicture && nameFromEligibility && nameFromPicture) && (
          <Button label="Edit Patient" className="p-button-primary" onClick={onEditPatientName} />
        )}
        {/* Close Button */}
        <Button
          label={'Close'} // Change label based on preferredName
          className={preferredName ? 'p-button-primary' : 'p-button-text p-button-primary'} // Change style based on preferredName
          onClick={() => setIsModalVisible(false)}
        />
      </div>
    </>
  );

  const handleNameChange = (selectedName) => {
    // Split the name by the comma
    const [lastName, firstName] = selectedName.split(',').map((part) => part.trim());

    // Prepare the updated patient details
    const updatedPatientDetails = {
      ...patientDetails,
      FirstName: firstName, // Extracted first name
      LastName: lastName, // Extracted last name
      FullName: selectedName // Full name in the correct format
    };

    // Dispatch the action to update the patient
    dispatch(
      editPatientAction(updatedPatientDetails, () => {
        dispatch(setToastMessage({ type: 'success', message: 'Name updated successfully!' }));
        setPreferredName(selectedName); // Update the preferred name in the UI
      })
    );
  };

  const handleNewPatient = () => {
    if (newPatient) return newPatient();
    return history.replace(`${routes.createPatient.path}/new`);
  };

  const handleNewLabel = () => {
    if (newLabel) return newLabel();
  };

  const onAddNewPatientToGroup = () => {
    setShowGroupDialog(true);
  };
    

  const successMessage = () => {
    return (
      <div className="flex align-items-center justify-content-between gap-1">
        {/* Message on the left */}
        <div className="flex flex-column gap-1">
          <span style={{ fontSize: '16px' }}>
            <strong>{'Patient Successfully Created!'}</strong>
          </span>
          {!isMobile && (
            <div>
              {errorToastMessage && errorToastMessage === 'First name or last name not found in the image. Please verify the autofill data.' && (
                <Message severity="warn" content={<span>{errorToastMessage}</span>} />
              )}
              {nameFromEligibility && nameFromPicture && nameFromEligibility !== nameFromPicture && (
                <Message severity="warn" content={<span>Label and Eligibility Report names differ. Please confirm correct spelling</span>} />
              )}
            </div>
          )}
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '16px' }}>
            {/* <span>
              The new patient profile for <strong>{preferredName ? preferredName : patientDetails?.FullName}</strong> has been added successfully
              <Button
                icon="pi pi-pencil"
                className="p-button-rounded p-button-text"
                onClick={onEditPatientName} // Call the handler on click
                aria-label={'Edit Patient'} // For accessibility
                tooltip={'Edit patient'}
                tooltipOptions={{ position: 'top' }} // Tooltip position
                style={{
                  padding: '0', // Compact the button
                  fontSize: '0.8rem', // Reduce the icon size
                  height: '1.5rem', // Adjust button height
                  width: '1.5rem', // Adjust button width to match height
                  marginLeft: '0.5rem', // Add space between the text and icon
                }}
              />
            </span> */}
          </div>
        </div>

        {/* {!isMobile && (
          
          <div className='flex gap-2'>
            <Button
              icon="pi pi-plus"
              label='New Patient'
              outlined
              text
              onClick={handleNewPatient} // Wrap in an arrow function
              tooltip={'Add new patient'}
              tooltipOptions={{ position: 'top' }} // Tooltip position
            />
            <Button
              icon="pi pi-upload"
              label= 'Upload Label'
              
              outlined
              onClick={handleNewLabel} // Wrap in an arrow function
              tooltip={'Upload new label'}
              tooltipOptions={{ position: 'top' }} // Tooltip position
            />
            <BillSamePatientSplitButton
              history={history}
              patientsList={[patientDetails]}
              user={user}
              showEClaimButton={true}
            />
          </div>
        )} */}
      </div>
    );
  };

  const onEditPatientName = async () => {
    if (isNew) {
      dispatch(setPatientIdForHighlightInTable(patientDetails));
      history.replace({
        pathname: `${routes.editProfile.path}/${patientDetails?.PatientGuid}`,
        state: { fromSuccessPage: true, nameFromPicture: nameFromPicture, preferredName: preferredName }
      });
    } else {
      const fetchMRPDetails = async () => {
        if (patientDetails.MRPNumber) {
          const result = await getCodesByType({
            codeType: commonInputs.referral.codeType,
            query: patientDetails.MRPNumber
          });
          return result || [];
        }
        return [];
      };

      const MRP = await fetchMRPDetails(); // Fetch MRP details asynchronously

      if (preferredName) {
        const [lastName, firstName] = preferredName.split(',').map((part) => part.trim());

        // Update form fields using Formik
        formik.setFieldValue('LastName', lastName);
        formik.setFieldValue('FirstName', firstName);

        formik.resetForm({
          values: {
            ...patientDetails,
            LastName: lastName,
            FirstName: firstName,
            MRP,
            DateDeceased: patientDetails?.DateDeceased ? new Date(patientDetails?.DateDeceased) : null
          }
        });
      } else {
        formik.resetForm({
          values: {
            ...patientDetails,
            MRP,
            DateDeceased: patientDetails?.DateDeceased ? new Date(patientDetails?.DateDeceased) : null
          }
        });
      }

      // Move to step 0
      setStep(0);
    }
  };

  const eligibilityLayout = () => {
    return (
      <div className="flex flex-column align-items-center w-full mb-5">
        <div className="mt-5 " style={{ width: '100%' }}>
          {/* <EligibilityMessage eligibility={eligibility} /> */}
          {eligibility && eligibility !== null && (
            <EligibilityTable
              patient={patientDetails}
              eligibility={eligibility}
              nameFromEligibility={nameFromEligibility}
              nameFromPicture={nameFromPicture}
              preferredName={preferredName}
              handleNameChange={handleNameChange}
            />
          )}
        </div>
      </div>
    );
  };

  const patientData = [
    {
      label: errorToastMessage === 'First name or last name not found in the image. Please verify the autofill data.' ? 'Name from Report' : 'Name',
      value: nameFromPicture || patientDetails?.FullName
    },
    {
      label: 'PHN',
      value: patientDetails.PHN !== 'N/A' ? `${patientDetails?.PHNProvince} ${formatPHN(patientDetails.PHN)}` : null
    },
    {
      label: 'Date of Birth',
      value: patientDetails?.BirthDay ? moment(patientDetails.BirthDay).format('MMM DD, YYYY') : 'N/A'
    },
    ...(patientDetails?.NewbornBD
      ? [
          {
            label: 'Newborn Date of Birth',
            value: moment(patientDetails.NewbornBD).format('MMM DD, YYYY')
          }
        ]
      : []),
    {
      label: 'Gender',
      value: patientDetails.Sex === 'M' ? 'Male' : patientDetails.Sex === 'F' ? 'Female' : 'Other'
    }
  ].filter((item) => item.value); // Remove null/empty values

  const tableHeader = (
    <div className="flex justify-content-between align-items-center">
      <div className="flex align-items-center gap-3">
        <span>Patient Details</span>
        {/* {!isMobile && (
          <>
        {errorToastMessage && errorToastMessage === 'First name or last name not found in the image. Please verify the autofill data.' && (
            <Message severity="warn"   content={<span>{errorToastMessage}</span>}
            />
          )}
          {nameFromEligibility && nameFromPicture && nameFromEligibility !== nameFromPicture && (
            <Message severity="warn" content={<span>Label and Eligibility Report names differ. Please confirm correct spelling</span>} />    
          )}
          </>
        )} */}
      </div>
      <div className="flex gap-3">
        {/* <Button
          label = 'Add to group'
          outlined
          icon = 'pi pi-plus'
          onClick={onAddNewPatientToGroup}
        /> */}
        <Button
          icon="pi pi-pencil"
          rounded
          text
          onClick={onEditPatientName} // Call the handler on click
          aria-label={'Edit Patient'} // For accessibility
          tooltip={'Edit patient'}
          tooltipOptions={{ position: 'top' }} // Tooltip position
        />
        {image && (
          <Button
            rounded
            icon="pi pi-image"
            className={` ${nameFromEligibility && nameFromPicture && nameFromEligibility !== nameFromPicture ? 'p-button-warning' : 'p-button-text'}`}
            onClick={() => setIsModalVisible(true)}
            tooltip={!isMobile ? 'Review uploaded image and select a name.' : null} // Disable tooltip on mobile
            tooltipOptions={{ position: 'top' }} // Tooltip position
          />
        )}
      </div>
    </div>
  );

  const handleOpenPatientProfile = (patientGuid) => {
    dispatch(setClaimFilters(claimsDefaultFilters));
    return history.replace(`${routes.patientDetails.path}/${patientGuid}`);
  };

  const [padding, setPadding] = useState(getPadding(window.innerWidth));

  // Function to determine padding based on screen width
  function getPadding(width) {
    if (width < 960) {
      return 0;
    } else if (width < 1120) {
      return 50;
    } else if (width < 1320) {
      return 100;
    } else if (width < 1530) {
      return 200;
    } else if (width < 1720) {
      return 300;
    } else {
      return 400;
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setPadding(getPadding(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const showLabelreader = true;
  return (
    <div className="px-3">
      <div
        className="flex flex-column flex-wrap col-12 align-items-center justify-content-start pb-6"
        style={{
          paddingLeft: `${padding}px`, // Use calculated padding
          paddingRight: `${padding}px` // Use calculated padding
        }}
      >
        <div className="flex flex-column align-items-center justify-content-start my-4 w-full">
          <div className="flex align-items-center justify-content-start w-full pb-2">
            {/* Icon on the left */}
            <i
              id="successIcon"
              className={cx('pi pi-check-circle', classes.successIcon)} // Retaining color via class
              style={{
                fontSize: '2rem',
                marginRight: '1rem', // Space between icon and message
                display: 'flex',
                alignItems: 'center' // Ensures the icon is vertically centered within its container
              }}
            />
            {/* Message on the right */}
            <div className="flex flex-column justify-content-center text-left">{successMessage()}</div>
          </div>

          {isMobile && (
            <div className="flex flex-column w-full">
              <div className="pb-3">
                {errorToastMessage && errorToastMessage === 'First name or last name not found in the image. Please verify the autofill data.' && (
                  <Message severity="warn" content={<span>{errorToastMessage}</span>} />
                )}
                {nameFromEligibility && nameFromPicture && nameFromEligibility !== nameFromPicture && (
                  <Message severity="warn" content={<span>Label and Eligibility Report names differ. Please confirm correct spelling</span>} />
                )}
              </div>

              <div className="flex justify-content-between items-center w-full pr-3 gap-1">
                <div
                  className="flex justify-content gap-2"
                  style={{
                    minWidth: '250px'
                  }}
                >
                  <Button
                    label={window.innerWidth < 360 ? 'Patient' : 'New Patient'}
                    icon="pi pi-plus"
                    outlined
                    text
                    onClick={handleNewPatient} // Wrap in an arrow function
                    style={{ paddingRight: '20px', noWrap: 'true' }}
                    tooltip={'Add new patient'}
                    tooltipOptions={{ position: 'top' }} // Tooltip position
                  />

                  <BillSamePatientSplitButton history={history} patientsList={[patientDetails]} user={user} showEClaimButton={true} />
                </div>
                <ButtonWithBadge value="Faster">
                  <Button
                    icon="pi pi-camera"
                    rounded
                    onClick={handleNewLabel} // Wrap in an arrow function
                    tooltip={'Scan new label'}
                    tooltipOptions={{ position: 'top' }} // Tooltip position
                  />
                </ButtonWithBadge>
              </div>
            </div>
          )}

          <div className="patient-details-table mt-3" style={{ width: '100%' }}>
            {!isMobile && (
              <div className="flex gap-2 mb-3">
                <BillSamePatientSplitButton history={history} patientsList={[patientDetails]} user={user} showEClaimButton={true} />
                <Button
                  icon="pi pi-plus"
                  label="New Patient"
                  outlined
                  text
                  onClick={handleNewPatient} // Wrap in an arrow function
                />
                {showLabelreader && (
                  <ButtonWithBadge value="Faster">
                    <Button
                      icon="pi pi-upload"
                      label="Upload Label"
                      outlined
                      onClick={handleNewLabel} // Wrap in an arrow function
                    />
                  </ButtonWithBadge>
                )}
              </div>
            )}

            <DataTable key={preferredName} value={patientData} header={tableHeader} showHeaders={false}>
              <Column field="label" style={{ minWidth: '90px', width: window.innerWidth <= 590 ? '90px' : '200px' }} />
              <Column
                field="value"
                style={{ minWidth: '152px' }}
                body={(rowData) => {
                  if (rowData.label === 'Name' || rowData.label === 'Name from Report') {
                    if (!preferredName || rowData.value === preferredName) {
                      return (
                        <Button
                          className="p-button-link"
                          label={rowData.value}
                          onClick={() => handleOpenPatientProfile(patientDetails.PatientGuid)}
                          tooltip="Open Patient Profile"
                          style={{
                            textAlign: 'left',
                            padding: '0',
                            margin: '0'
                          }}
                        />
                      );
                    }
                    return <span>{rowData.value}</span>; // Render plain text for non-preferred names
                  }
                  return <span>{rowData.value}</span>; // Default rendering for other fields
                }}
              />

              {nameFromEligibility && nameFromPicture && nameFromEligibility !== nameFromPicture && (
                <Column
                  header="Action"
                  body={(rowData) =>
                    rowData.label === 'Name' || rowData.label === 'Name from Report' ? (
                      <>
                        {/* <Button
                        label={isMobile ? "Select" : "Select Spelling"}
                        className="p-button-rounded p-button-text"
                        tooltip="Click to select this spelling" 
                        onClick={() => handleNameChange(rowData.value)}
                      /> */}

                        <RadioButton
                          inputId="nameFromPicture"
                          value={nameFromPicture}
                          checked={preferredName === nameFromPicture}
                          onChange={(e) => handleNameChange(e.value)}
                          style={{ marginRight: '10px' }}
                          tooltip={preferredName === nameFromPicture ? 'Selected spelling' : 'Select this spelling'}
                          tooltipOptions={{ position: 'top' }}
                        />
                      </>
                    ) : null
                  }
                  style={{
                    // position: 'sticky',
                    right: 0,
                    backgroundColor: '#fff', // Prevent overlapping content
                    zIndex: 1, // Ensure the sticky column is on top
                    maxWidth: '50px',
                    minWidth: '50px',
                    flexShrink: 0,
                    width: '50px',
                    textAlign: 'center'
                  }}
                />
              )}
              {/* {nameFromEligibility &&
              nameFromPicture &&
              nameFromEligibility !== nameFromPicture && preferredName === nameFromPicture && (
                <Column
                  header="Action"
                  body={(rowData) =>
                    rowData.label === 'Name' ? (
                      <Button
                        icon="pi pi-check"
                        className="p-button-rounded"
                        disabled
                        style={{ pointerEvents: 'none' }}
                        />
                    ) : null
                  }
                  style={{
                    position: 'sticky',
                    right: 5,
                    backgroundColor: '#fff', // Prevent overlapping content
                    zIndex: 1, // Ensure the sticky column is on top
                    maxWidth: '50px',
                    minWidth: '50px',
                    width: '50px',
                    textAlign: 'center',
                }}                  />
              )} */}
            </DataTable>
          </div>

          {eligibilityLayout()}

          <Button text label="Go to previous page" onClick={() => history.goBack()} />

          {/* {!showNewPatientSuccessPage.from && <SuccessActionButtons patientsList={[patientDetails]} newPatient={newPatient} />} */}
        </div>
      </div>

      {/* Modal */}
      <Dialog
        header={`Confirm Details for PHN ${patientDetails.PHN.replace(/\s+/g, '-')}`}
        headerStyle={{ textAlign: 'center' }}
        visible={isModalVisible}
        breakpoints={{
          '1366px': '50vw',
          '1280px': '70vw',
          '860px': '90vw',
          '480px': '100vw'
        }}
        style={{ width: '50vw' }} // Default width for larger screens
        onHide={() => setIsModalVisible(false)}
      >
        {renderModalContent()}
      </Dialog>

      <Dialog
      header="Create Group"
      headerStyle={{ textAlign: 'center' }}
      visible={showGroupDialog}
      breakpoints={{
        '1366px': '50vw',
        '1280px': '70vw',
        '860px': '90vw',
        '480px': '100vw'
      }}
      style={{ width: '50vw' }} // Default width for larger screens
      onHide={() => setShowGroupDialog(false)}
      >
        {renderAddToGroupDialog()}
      </Dialog>
    </div>
  );
};

export default Success;
