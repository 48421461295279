import { t } from '../../../../service/localization/i18n';

export const useValidation = () => {
  const firstNameValidation = (value) => {
    return value ? true : t('Mandatory_field.1');
  };

  const lastNameValidation = (value) => {
    return value ? true : t('Mandatory_field.1');
  };

  return {
    firstNameValidation,
    lastNameValidation
  };
};
