import React from 'react';
import { useForm } from 'react-hook-form';
import PhoneNumber from '../../../registration/components/inputs/PhoneNumber';
import { Button } from 'primereact/button';
import { changePhoneAuth, resendPhoneCodeAuth } from '../../../../service/Lookup';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../actions/auth.actions.creators';

const ChangePhoneActivation = ({ setVisibleChangePhone }) => {
  const user = useSelector((state) => state.user.details);
  const dispatch = useDispatch();

  const autoFocusStatus = true;
  const requiredStatus = true;
  const { control, handleSubmit, formState } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      phoneNumber: ''
    }
  });

  const onSubmit = async (data) => {
    const formatedPhoneNumber = data.phoneNumber?.replace(/[^0-9]/g, '');

    const changePhonePayload = {
      DCNGuid: user.DCNGuid,
      DoctorGuid: user.DoctorGuid,
      Phone: formatedPhoneNumber
    };

    const result = await changePhoneAuth(changePhonePayload); // update phone Number
    dispatch(updateUser({ ...user, Phone: result.data.Phone }));

    const resendPhoneCodePayload = {
      DCNGuid: user.DCNGuid,
      DoctorGuid: user.DoctorGuid,
      SendText: 1,
      SendEmail: 0
    };
    resendPhoneCodeAuth(resendPhoneCodePayload); // send verification code to new phone number

    setVisibleChangePhone(true);
  };

  return (
    <>
      <div className="text-lg line-height-3">Update your phone number and send a verification code to the new phone</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PhoneNumber control={control} autoFocusStatus={autoFocusStatus} requiredStatus={requiredStatus} />

        <div className="flex gap-3 mt-2 mb-6">
          <Button label="Send a code" className="w-6 h-3rem" loading={formState.isSubmitting} />
          <Button
            label="Cancel"
            className="p-button-outlined w-6 h-3rem"
            onClick={() => {
              setVisibleChangePhone(true);
            }}
          />
        </div>
      </form>
    </>
  );
};

export default ChangePhoneActivation;
